<template>
    <div class="q-checkbox-options">
        <draggable v-if="configurable" :list="configurableOptions" :animation="300" group="options" handle=".hamburger-icon" @change="handleOrderChanged">
            <transition-group name="list" class="options-container">
                <label 
                    v-for="(option, index) in configurableOptions" 
                    :key="option.id"
                    class="option configurable"
                    :class="{ 'no-resize': true, disabled: option.disabled }">
                    <q-icon type="hamburger-menu" class="hamburger-icon" color="#ADB5BD"></q-icon>
                    <q-radio class="radio" disabled></q-radio>
                    <q-clear-input
                        v-model="option.label" 
                        class="clear-input"
                        size="small"
                        :disabled="option.disabled"
                        :placeholder="`Beoordelingswaarde ${index+1}`" 
                        @input="handleOptionsUpdated"
                    />
                    <q-icon class="remove-button" @click="handleRemoveOption(option)" type="close" width="18" height="18"></q-icon>
                </label>
                <label 
                    class="option add-new"
                    :class="{ 'no-resize': true }"
                    key="add_new"
                    @click="addNewOption">
                    <span class="plus">+</span>
                    <p>Beoordelingswaarde toevoegen</p>
                </label>
            </transition-group>
        </draggable>
        <div v-else class="options-container" :class="{ 'single-row': singleRow }">
            <label 
                v-for="(option, index) in options" 
                :key="option.value" 
                class="option"
                :class="{ selected: option.value === value, disabled, 'no-resize': disabled }">
                <q-radio
                    :label="option.value"
                    :value="value" 
                    :disabled="disabled"
                    @input="handleRadioInput(option.value)"
                >{{ option.label }}</q-radio>
            </label>
        </div>
    </div>
</template>

<script>
const { v4: uuidv4 } = require('uuid');
import draggable from 'vuedraggable';

export default {
    name: 'q-radio-options',
    components: {
        draggable
    },
    data() {
        return {
            configurableOptions: [],
            validOptions: true
        }
    },
    props: {
        // v-model
        value: {
            type: [Number, String],
            default: ''
        },
        /*  
        /*  [Option]
        /*  label: String
        /*  value: String
        */
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        configurable: {
            type: Boolean,
            default: false
        },
        // if true, the options will be forced to a vertical array. By default it grows 2 wide
        singleRow: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleRadioInput(value) {
            this.$emit('input', value);
        },
        handleOrderChanged() {
            this.$emit('optionsUpdated', this.configurableOptions);
        },
        addNewOption() {
            this.configurableOptions.push({
                label: '',
                value: '',
                id: `${Math.random() * 10000}`
            });
            this.handleOptionsUpdated();
        },
        handleOptionsUpdated() {
            const options = [...this.configurableOptions]
            const parsedOptions = options.map(option => {
                if(!option.value) option.value = uuidv4();
                return option
            })
            this.$emit('optionsUpdated', parsedOptions);
            this.checkValidOptions();
        },
        checkValidOptions() {
            const answerValues = this.configurableOptions;
            const labels = answerValues.map(value => value.label);
            const allEmptyValues = answerValues.filter(value => value.label === '').length === answerValues.length;
            if(allEmptyValues) return this.validOptions = false
            const hasDoubleLabels = answerValues.filter((value, index) => labels.indexOf(value.label) !== index && value.label !== '').length > 0;
            if(hasDoubleLabels) return this.validOptions = false
            const hasEmptyValue = answerValues.filter(value => !value.label).length > 0;
            if(hasEmptyValue && this.displayType !== 'score') return this.validOptions = false
            return this.validOptions = true
        },
        parseOptions() {
            const parsedOptions = this.options.map(option => {
                option.id = option.value;
                return option
            })
            this.configurableOptions = parsedOptions;
        },
        handleRemoveOption(option) {
            const index = this.configurableOptions.findIndex(configurableOption => configurableOption.id === option.id);
            this.configurableOptions.splice(index, 1);
            this.handleOptionsUpdated();
        }
    },
    created() {
        this.parseOptions();
        this.checkValidOptions();
    },
    watch: {
        validOptions: function() {
            this.$emit('validationUpdated', this.validOptions);
        },
        options: function() {
            this.parseOptions();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.options-container {
    --gap: 10px;
    --option-width: 50%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    width: 100%;

    @media only screen and (max-width: 600px) {
        --gap: 0px;
        --option-width: 100%;
        gap: 10px;
    }
    @media print {
        --gap: 0px !important;
        --option-width: 100% !important;
        gap: 10px !important;
    }

    &.single-row {
        --gap: 0px;
        --option-width: 100%;
        gap: 10px;
    }

    .option {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: #F5F5F5;
        border-radius: 3px;
        width: calc(var(--option-width) - (var(--gap) / 2) - 32px);
        padding: 14px 16px;
        cursor: pointer;
        transition: all .2s ease, scale .2s cubic-bezier(.51,1.7,.45,1.08);

        .hamburger-icon {
            cursor: grab;
            scale: 0.8;
            margin-right: 6px;
        }

        &.no-resize {
            scale: 1 !important;
            cursor: default;
        }

        &.disabled {
            opacity: .8;
        }

        &:hover {
            background-color: #e0eeee;
            scale: 1.01;
        }

        &:active, &:focus {
            scale: 0.99;
            background-color: #D2EEF0;
        }

        &.selected {
            background-color: #D2EEF0;
            color: $color-blue;
        }

        &.configurable {
            overflow: hidden;

            label {
                width: 24px !important;
                margin: 0;
            }

            .radio {
                cursor: default;
            }

            .clear-input {
                background-color: transparent; 
                flex-grow: 1;
            }

            .remove-button {
                color: red;
                cursor: pointer;
                margin-top: 3px;
                margin-left: 3px;
                margin-right: -36px;
                transition: margin .2s ease;
            }

            &:hover:not(.disabled) {
                .remove-button {
                    margin-right: -8px;
                    transition-delay: .2s;
                }
            }
        }

        &.add-new {
            cursor: pointer;
            gap: 12px;

            .plus {
                color: $color-primary;
                font-size: 28px;
                line-height: 24px;
            }
        }
    }
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

</style>
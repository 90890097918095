<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.327,10c0,5.151 -4.176,9.327 -9.327,9.327c-5.151,0 -9.327,-4.176 -9.327,-9.327c0,-5.151 4.176,-9.327 9.327,-9.327c5.151,0 9.327,4.176 9.327,9.327Z" style="fill:none;stroke-width:1.24px;" :stroke="color"/>
    <path d="M10,10l0,1.865" style="fill:none;stroke-width:1.24px;stroke-linecap:round;" :stroke="color"/>
    <path d="M11.244,14.975c0,0.686 -0.558,1.243 -1.244,1.243c-0.686,0 -1.244,-0.557 -1.244,-1.243c0,-0.687 0.558,-1.244 1.244,-1.244c0.686,0 1.244,0.557 1.244,1.244Z" style="fill-rule:nonzero;" :fill="color"/>
    <path d="M10,10c1.374,0 2.487,-1.113 2.487,-2.487c0,-1.375 -1.113,-2.488 -2.487,-2.488c-1.374,0 -2.487,1.113 -2.487,2.488" style="fill:none;stroke-width:1.24px;stroke-linecap:round;stroke-linejoin:round;" :stroke="color"/>
  </svg>
</template>

<script>
export default {
  name: "QuestionCircle",
  props: {
    color: {
      type: String,
      default: "#212529",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>

<style></style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM4.5 11L4.5 1L3.5 1L3.5 11L4.5 11Z" :fill="color"/>
        <path d="M9.64645 11.3536C9.84171 11.5488 10.1583 11.5488 10.3536 11.3536L13.5355 8.17157C13.7308 7.97631 13.7308 7.65973 13.5355 7.46447C13.3403 7.2692 13.0237 7.2692 12.8284 7.46447L10 10.2929L7.17157 7.46447C6.97631 7.2692 6.65973 7.2692 6.46447 7.46447C6.2692 7.65973 6.2692 7.97631 6.46447 8.17157L9.64645 11.3536ZM9.5 1L9.5 11L10.5 11L10.5 1L9.5 1Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'SortArrows',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 14,
        },
        height: {
            type: [Number, String],
            default: 12,
        },
    },
};
</script>

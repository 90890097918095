<template>
    <div class="modal">
        <div class="vertical-center">
            <h3>Deel alsnog je project met de opdrachtgever</h3>
        </div>

        <div class="modal-content">
            <p>Als je Better Performance wilt meten kies je ervoor om alsnog je project te delen met de opdrachtgever. Zonder je gegevens te delen kun je geen Better Performance meten.</p>
        </div>
        <div class="footer">
            <q-button variation="blank" class="red-hover" @click="closePopup">Annuleren</q-button>
            <q-button @click="handleConfirm">Akkoord</q-button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'confirm-share-modal',
    methods: {
        handleConfirm() {
            return this.$emit('confirm')
        },
        closePopup() {
            return this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 600px !important;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 5px 5px 5px;
        border-top: 1px solid #dee2e6;
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}

.modal-content {
    margin-block: 32px;
}

.input {
    margin: 0 0 8px 0;
}

.required-tag {
    transition: 0.4s ease;

    &.hide {
        transform: translateY(30px);
    }
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.14737 32.0529L10.759 11L26 25.7728C26 25.7728 9.16225 35.067 6.82313 35.7368C4.14986 36.7415 2.47904 34.7321 3.14737 32.0529Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M25.9012 25.1766C25.7891 25.2901 25.5212 25.395 24.9454 25.2877C24.3902 25.1842 23.6709 24.9024 22.8293 24.4425C21.1522 23.5259 19.0915 21.9587 17.058 19.9524C15.0244 17.946 13.4296 15.9066 12.4906 14.242C12.0193 13.4067 11.7279 12.6913 11.617 12.1375C11.5019 11.5631 11.6032 11.2938 11.7152 11.1803C11.8273 11.0667 12.0952 10.9618 12.671 11.0691C13.2263 11.1726 13.9455 11.4544 14.7872 11.9144C16.4642 12.8309 18.5249 14.3981 20.5584 16.4045C22.592 18.4108 24.1868 20.4502 25.1258 22.1148C25.5971 22.9502 25.8885 23.6656 25.9994 24.2193C26.1145 24.7937 26.0132 25.063 25.9012 25.1766Z"
            fill="url(#paint1_linear)"
            stroke="url(#paint2_linear)"
        />
        <path
            d="M24 24C33.1 22.5456 37 16 37 16"
            stroke="url(#paint3_linear)"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
        <ellipse cx="19" cy="8.5" rx="1" ry="1.5" fill="#00A1AE" />
        <circle cx="9.5" cy="6.5" r="1.5" fill="#EE870E" />
        <circle cx="21.1317" cy="1.8672" r="1.33828" transform="rotate(-54.3837 21.1317 1.8672)" fill="#C634AE" />
        <circle cx="13.6544" cy="8.85743" r="1.33828" transform="rotate(-54.3837 13.6544 8.85743)" fill="#006B94" />
        <circle cx="25" cy="30" r="1" fill="#EE870E" />
        <ellipse cx="28.5" cy="27" rx="1.5" ry="2" fill="#C634AE" />
        <path
            d="M3.90478 24C3.90478 24 2.06618 19.5968 6.96913 18.9194C11.8721 18.2419 6.96913 15.871 2.06618 15.1935C-2.83678 14.5161 10.9528 12.1452 7.582 11.129C4.21122 10.1129 2.00489 6.86129 2.06618 3"
            stroke="url(#paint4_linear)"
            stroke-linecap="square"
        />
        <path
            d="M17.3145 16.6941C17.3145 16.6941 20.1801 12.8554 23.3212 17.2414C26.4623 21.6274 25.9679 15.8053 24.003 10.7811C22.0382 5.75679 31.2769 17.8731 30.404 14.1596C29.531 10.4461 31.2053 6.79702 34.5896 5.03683"
            stroke="url(#paint5_linear)"
            stroke-linecap="square"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.6366 29.5785C10.9015 29.4202 10.2041 29.0612 9.63111 28.4998C7.98666 26.8883 7.98901 24.2192 9.63635 22.5381C11.2837 20.857 13.9522 20.8004 15.5967 22.4118C17.2411 24.0233 17.2388 26.6924 15.5914 28.3735C14.9081 29.0708 14.0491 29.4886 13.1604 29.6238L13.1611 29.7004C13.1679 30.367 12.633 30.9129 11.9664 30.9196C11.7918 30.9214 11.6488 30.7813 11.6471 30.6067L11.6366 29.5785ZM13.1414 27.7601C13.5539 27.6429 13.9471 27.4164 14.2743 27.0826C15.2268 26.1106 15.258 24.5966 14.3441 23.7011C13.4302 22.8055 11.9171 22.8675 10.9647 23.8395C10.0122 24.8115 9.98097 26.3254 10.8949 27.221C11.1081 27.4299 11.3538 27.5867 11.6175 27.692L11.6136 27.3085C11.6068 26.6419 12.1417 26.096 12.8083 26.0892C12.9829 26.0874 13.1259 26.2275 13.1277 26.4021L13.1414 27.7601Z"
            fill="white"
            fill-opacity="0.5"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-6.69065"
                y1="17.6522"
                x2="16.838"
                y2="37.6898"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#662D91" />
                <stop offset="0.3125" stop-color="#006B94" />
                <stop offset="0.5477" stop-color="#00A1AE" />
                <stop offset="0.770833" stop-color="#006B94" />
                <stop offset="1" stop-color="#662D91" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="9.25789"
                y1="11.7104"
                x2="32.2203"
                y2="31.7069"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#662D91" />
                <stop offset="0.3125" stop-color="#006B94" />
                <stop offset="0.5477" stop-color="#00A1AE" />
                <stop offset="0.770833" stop-color="#006B94" />
                <stop offset="1" stop-color="#662D91" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="8.35533"
                y1="12.4707"
                x2="28.8544"
                y2="23.4829"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#662D91" />
                <stop offset="0.5477" stop-color="#006C92" />
                <stop offset="1" stop-color="#55A89C" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="38.95"
                y1="19.6364"
                x2="26.7593"
                y2="25.084"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.546875" stop-color="#C634AE" />
                <stop offset="1" stop-color="#841572" />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="1"
                y1="16.5484"
                x2="9.93341"
                y2="15.1549"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#662483" />
                <stop offset="1" stop-color="#3E0B55" />
            </linearGradient>
            <linearGradient
                id="paint5_linear"
                x1="22.2716"
                y1="10.3961"
                x2="28.3692"
                y2="18.1264"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.588542" stop-color="#EE870E" />
                <stop offset="1" stop-color="#C67D28" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'Confetti',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 39
        },
        height: {
            type: [Number, String],
            default: 36
        }
    }
};
</script>

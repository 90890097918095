<template>
    <div class="page-container" :style="`--style-color: ${styleColor}`" :class="{ gradient: styleColor }">
        <div v-if="!loadingForm && form" class="top-container">
            <div class="logo-container">
                <img class="logo" :src="logoHref" alt="Qfact logo">
            </div>
            <p>Dit formulier is beschikbaar van {{ getNumericDate(form.settings.survey.availability.from) }} t/m {{ getNumericDate(form.settings.survey.availability.to) }}</p>
        </div>
        <div v-if="!loadingForm && form" class="enquete">
            <div class="head">
                <h1 class="title">{{ form.name }}</h1>
                <p class="subtitle">{{ form.project.name }}</p>
            </div>
            <div v-if="!notOpenYet && !expiredEndDate && form.settings.survey.startMessageEnabled && !surveySent" class="about">
                <p class="white-space" v-html="form.settings.survey.startMessage"></p>
            </div>

            <div v-if="!surveySent && !notOpenYet && !expiredEndDate" class="content">
                <div 
                    v-for="category in questionCategories"
                    :key="category.id"
                    class="question-category">
                    <div v-if="category.name" class="category-header">
                        <span class="title">{{ category.name }}</span>
                    </div>
                    <div class="questions-container" :class="[{ active: category.active && category.name }]">
                        <enquete-question
                            v-for="question in category.questions"
                            :key="question.componentId"
                            :index="question.index"
                            :indicator="question"
                            :value="question.answer"
                            :customLabel="category.id === 'empty' ? 'Standaard' : null"
                            @input="handleResponse"
                            @error="E => handleError(question.componentId, E)"
                            class="question-component"
                        >
                        {{ question.error }}
                    </enquete-question>
                    </div>
                </div>
                <div class="accept-terms">
                    <p class="name">Ik ga akkoord met het privacybeleid</p>
                    <div class="check">
                        <q-checkbox v-model="acceptTerms" class="checkbox" id="accept-terms">Met het versturen van dit formulier ga ik ermee akkoord dat Qfact mijn persoonsgegevens verwerkt, zoals beschreven in het <a href="https://enquetes.prestatiedatabank.nl/privacy" target=”_blank”>privacybeleid</a></q-checkbox>
                    </div>
                </div>
            </div>
            
            <div v-else-if="closed" class="message">
                <p>Dit formulier kan niet meer worden ingevuld, omdat hij is afgerond. Heb je hier vragen over neem dan contact op met de afzender van het formulier.</p>
            </div>
            <div v-else-if="notOpenYet" class="message">
                <p>Dit formulier kun je invullen vanaf <b>{{ getDate(form.settings.survey.availability.from) }}</b>. Heb je hier vragen over neem dan contact op met de afzender van het formulier.</p>
            </div>
            <div v-else-if="expiredEndDate" class="message">
                <p>Dit formulier kan niet meer worden ingevuld, omdat de einddatum is verlopen. Heb je hier vragen over neem dan contact op met de afzender van het formulier.</p>
            </div>
            <div v-else-if="surveySent" class="message white-space">
                <p v-html="form.settings.survey.completionMessage"></p>
            </div>

            <div v-if="!notOpenYet && !surveySent" class="navigation">
                <q-button class="send-button" :disabled="questionsToAnswer > 0" :loading="loadingSend" @click="saveSurvey">{{ computedButtonLabel }}</q-button>
            </div>
        </div>
        <div v-if="loadingForm" class="center">
            <div class="loader"></div>
            <p>Enquete wordt opgehaald</p>
        </div>

        <div v-if="!loadingForm && !form" class="not-found center">
            <q-icon class="icon" type="InfoCircle" color="rgb(227, 104, 2)"></q-icon>
            <p>We hebben dit formulier niet op kunnen halen</p>
        </div>

        <notifications></notifications>
    </div>
</template>

<script>
import EnqueteQuestion from './EnqueteQuestion.vue';
import Notifications from '@/components/Notifications.vue';
import { getDate, isValidAnswer } from '@/assets/js/utils.js';
import { SAVE_SURVEY } from '@/graphql/mutations.js';
import gql from 'graphql-tag'

export default {
    name: 'enquete-page',
    components: {
        EnqueteQuestion,
        Notifications
    },
    data() {
        return {
            acceptTerms: false,
            form: null,
            loadingForm: true,
            notOpenYet: false,
            expiredEndDate: false,
            closed: false,
            response: {
                user: {
                    userName: '',
                    email: '',
                    function: ''
                },
                values: []
            },
            surveySent: false,
            loadingSend: false,
            styleColor: null,
            logoHref: '/statics/img/Qfact-logo.png'
        }
    },
    methods: {
        parseComponent(component) {
            const response = this.response

            let answer = null;
            let comment = '';
            if(response) {
                const componentIndex = response.values.map(value => value.componentId).indexOf(component.id);
                if(componentIndex !== -1) {
                    answer = response.values[componentIndex].value;
                    comment = response.values[componentIndex].improvement;
                } else if(component.indicator.isDefaultQuestion) {
                    switch(component.id) {
                        case'default-name-question':
                            answer = this.response.user.userName;
                            break;
                        case'default-email-question':
                            answer = this.response.user.email;
                            break;
                        case'default-function-question':
                            answer = this.response.user.function;
                            break;
                    }
                } else {
                    answer = this.getEmptyValue(component.indicator.answerType);
                }
            }
            else answer = this.getEmptyValue(component.indicator.answerType);

            const answerValues = component.indicator.activeAnswerValues || [];

            return {
                componentId: component.id,
                ...component.indicator,
                answerValues,
                enabled: component.enabled,
                answer,
                description: component.description,
                showScore: component.indicator.showScore ?? true,
                comment,
                error: component.error
            }
        },
        getEmptyValue(answerType) {
            if(answerType === 'checkbox') return []
            else if(answerType === 'text') return ''
            else if(['radio','number'].includes(answerType)) return null
        },
        handleResponse(question) {
            if(question.isDefaultQuestion) {
                switch(question.componentId) {
                    case'default-name-question':
                        this.response.user.userName = question.answer;
                        break;
                    case'default-email-question':
                        this.response.user.email = question.answer;
                        break;
                    case'default-function-question':
                        this.response.user.function = question.answer;
                        break;
                }

                return
            }
            
            const responseValueIndex = this.response.values.findIndex(responseValue => responseValue.componentId === question.componentId);
            if(responseValueIndex !== -1) {
                this.response.values[responseValueIndex].value = question.answer;
                this.response.values[responseValueIndex].improvement = question.comment;
            }
            else this.response.values.push({ componentId: question.componentId, indicatorId: question.id, value: question.answer, improvement: question.comment })
        },
        handleTermsNotAccepted() {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            this.$store.commit('notify', { type: 'danger', message: 'Je moet eerst akkoord gaan met de voorwaarden' });
        },
        saveSurvey() {
            if(!this.acceptTerms) return this.handleTermsNotAccepted()
            this.loadingSend = true;

            const parsedValues = this.response.values.map(value => {
                return {
                    valueIdentity: {
                        componentId: value.componentId,
                        indicatorId: value.indicatorId,
                        formId: this.form.id
                    },
                    value: value.value,
                    improvement: value.improvement
                }
            })

            this.$apollo.mutate({
                mutation: SAVE_SURVEY,
                variables: {
                    values: parsedValues,
                    user: {
                        ...this.response.user,
                        agreedToTerms: this.acceptTerms
                    }
                }
            })
            .then(result => {
                this.loadingSend = false;
                this.surveySent = true;

                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch(error => {
                this.loadingSend = false;
                console.log(error)
            })
        },
        getNumericDate(unix) {
            if(!unix) return ''
            const date = new Date(unix);
            return date.toLocaleString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric' })
        },
        getDate(unix) {
            if(!unix) return ''
            return getDate(unix)
        },
        async getForm() {
            const ONE_DAY = 1000 * 3600 * 24;
            const TWO_DAYS = 1000 * 3600 * 24 * 1;

            this.$apollo.query({
                query: gql`
                    query survey($shareToken: String!) {
                        survey(shareToken: $shareToken) {
                            id
                            name
                            status
                            description
                            project {
                                id
                                name
                            }
                            settings {
                                shareToken
                                note
                                survey {
                                    sendDate
                                    availability {
                                        from
                                        to
                                    }
                                    completionMessageEnabled
                                    completionMessage
                                    startMessageEnabled
                                    startMessage
                                    enableNameQuestion
                                    enableEmailQuestion
                                    enableFunctionQuestion
                                }
                            }
                            components {
                                id
                                type
                                enabled
                                required
                                description
                                indicator {
                                    id
                                    name
                                    mandatory
                                    canFillComment
                                    canFillNotApplicable
                                    showScore
                                    answerType
                                    displayType
                                    required
                                    description
                                    activeAnswerValues {
                                        value
                                        label
                                        status
                                    }
                                    labelIds
                                    context {
                                        id
                                        name
                                    }
                                    unit
                                }
                            }
                            configuration {
                                color {
                                    colorCode
                                }
                                logo
                            }
                            labels {
                                id
                                name
                            }
                        }
                    }
                `,
                variables: {
                    shareToken: this.$route.params.id
                }
            })
            .then(result => {
                this.form = result.data.survey;
                this.loadingForm = false;
                if(!this.form.settings) 
                    this.form.settings = {
                        shareToken: '2394o-8235',
                        note: '',
                        attendees: [],
                        survey: {
                            sendDate: null,
                            availability: {
                                from: null,
                                to: null
                            },
                            completionMessageEnabled: false,
                            completionMessage: '',
                            startMessageEnabled: false,
                            startMessage: '',
                            enableNameQuestion: true,
                            enableEmailQuestion: true,
                            enableFunctionQuestion: true,
                        }
                    }
                
                if(!this.form.settings.survey.completionMessage) this.form.settings.survey.completionMessage = 'Bedankt voor het invullen';

                const defaultNameComponent = {
                    id: 'default-name-question',
                    indicator: {
                        name: 'Wat is je naam?',
                        answerType: 'text',
                        displayType: 'text',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableNameQuestion,
                    required: true
                }
    
                const defaultEmailComponent = {
                    id: 'default-email-question',
                    indicator: {
                        name: 'Wat is je e-mailadres?',
                        answerType: 'text',
                        displayType: 'email',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableEmailQuestion,
                    required: true
                }
    
                const defaultFunctionComponent = {
                    id: 'default-function-question',
                    indicator: {
                        name: 'Wat is je functie?',
                        answerType: 'text',
                        displayType: 'text',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableFunctionQuestion,
                    required: true
                }
    
                this.form.components = [defaultNameComponent, defaultEmailComponent, defaultFunctionComponent, ...this.form.components];

                if (this.form.settings.survey.availability.to < (Date.now() - ONE_DAY)) this.expiredEndDate = true;
                else if(this.form.status === 'finished') this.closed = true;
                
                if((this.form.settings.survey.availability.from > Date.now() || this.form.status !== 'sent') && this.expiredEndDate !== true) this.notOpenYet = true;

                if(this.form.configuration) {
                    this.styleColor = this.form.configuration.color.colorCode;
                    this.logoHref = this.form.configuration.logo;
                }
            })
            .catch(error => {
                this.loadingForm = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van deze enquete' });
            })
        },
        handleError(id, hasError) {
            const component = this.form.components.find(component => component.id === id);
            component.error = hasError;
        }
    },
    computed: {
        questionCategories: function() {
            if(!this.form) return []
            const components = this.form.components;
            const enabledComponents = components.filter(component => component.enabled);

            let categories = [{
                name: '',
                id: 'empty'
            }];

            categories = categories.concat(this.form.labels)

            let questionIndex = 0;

            categories = categories.map(category => {
                const categoryComponents = enabledComponents.filter(question => {
                    if(question.indicator.labelIds?.length > 0) return question.indicator.labelIds.includes(category.id)
                    return category.id === 'empty'
                });
                category.questions = categoryComponents.map(component => {
                    component = this.parseComponent(component);
                    if(category.id !== 'empty') questionIndex++;
                    component.index = questionIndex;
                    return component
                })
                return category
            })

            categories = categories.filter(category => category.questions.length > 0)

            return categories
        },
        questionsToAnswer: function() {
            let activeQuestions = 0;
            this.questionCategories.forEach(category => {
                activeQuestions += category.questions.filter(question => (!isValidAnswer(question.answer) && question.required) || question.error).length;
            })
            return activeQuestions
        },
        computedButtonLabel: function() {
            if(this.questionsToAnswer === 0) return 'Formulier verzenden'
            let suffix = this.questionsToAnswer === 1 ? 'vraag' : 'vragen';
            return `Nog ${this.questionsToAnswer} ${suffix}`
        }
    },
    created() {
        this.getForm();
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

@media only screen and (max-width: 475px) {
    .accept-terms {
        width: calc(100% - 10vw) !important;
    }
}

.page-container {
    position: relative;
    padding-block: 12vh;
    min-height: calc(100vh - 24vh);
    background: rgba(0, 161, 174,.2);

    &.gradient {
        background: none;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline: 0;
            height: 150vh;
            background: linear-gradient(#FFFFFF 0%, #FFFFFF 10%, var(--style-color) 100%);
            z-index: -1;
        }
        &:after {
            content: "";
            position: absolute;
            top: 150vh;
            bottom: 0;
            inset-inline: 0;
            background: var(--style-color);
            z-index: -1;
        }
    }


    .top-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding-block: 12px;
        width: 100%;
        
        .logo-container {
            display: grid;
            place-items: center;
            min-height: 5vh;
            min-width: 8vh;
            border-radius: 6px;
            overflow: hidden;

            .logo {
                max-width: 30vh;
                max-height: 12vh;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        p {
            color: $color-grey-7;
            margin-inline: 10vw;
        }
    }

    .enquete {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1000px;
        margin-inline: auto;
        padding-block: 120px;
        background-color: white;
        border-radius: 12px;

        .head .subtitle {
            font-weight: 500;
            margin-top: 4px;
        }

        .head,
        .about {
            width: calc(100% - 160px);
            max-width: calc(800px - 80px);
            margin-inline: auto;
        }
        .about {
            margin-top: 42px;
        }
    
        .content {
            padding-block: 140px 80px;
            flex-grow: 1;
    
            .question-category {
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .category-header {
                    padding-block: 40px 60px;
                    max-width: calc(800px - 80px);
                    text-align: center;
    
                    span {
                        font-size: 24px;
                        line-height: 28px;
                        font-weight: 500;

                        background-image: linear-gradient(var(--style-color), var(--style-color));
                        background-position: left bottom;
                        background-size: 100% 1px;
                        background-repeat: no-repeat;
                    }
                }
    
                .questions-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 30px;
                    width: 100%;
    
                    .enquete-question {
                        width: calc(100% - 160px);
                        max-width: calc(800px - 80px);
                        margin-inline: auto;
                    }
                }
            }

            .accept-terms {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: calc(100% - 160px);
                max-width: calc(800px - 80px);
                margin-inline: auto;
                margin-top: 40px;

                .name {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    color: #212529;
                    white-space: pre-wrap;
                    width: fit-content;
                }

                .check {
                    display: flex;
    
                    .checkbox {
                        font-weight: 400;
                        a {
                            color: $color-primary;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .message {
            width: calc(100% - 160px);
            max-width: calc(800px - 80px);
            margin-inline: auto;
            margin-top: 80px;

            b {
                font-weight: 500;
            }
        }

        .navigation {
            position: fixed;
            bottom: 30px;
            right: 100px;

            .send-button {
                box-shadow: 0 0 16px #101e3070;
            }
        }
    }

}

.white-space {
    white-space: pre-wrap;
}

.not-found {
    .icon {
        scale: 2.5;
    }
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    p {
        color: #212529;
        font-weight: 500;
        font-size: 20px;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>
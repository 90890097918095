<template>
    <q-step
        :number="index"
        :id="index"
        :name="step.name"
        :about="step.about"
        :value="step.value"
        showStepImage
        class="step"
        width="90%"
    >
        <draggable-table 
            v-if="columns && columns.length"
            class="table"
            :columns="columns"
            :data="data"
            :loading="loading"
            @input="updateColumnOrder"
            >
        </draggable-table>
        <div v-else-if="data.length === 0">
            <p>Er is geen data gevonden.</p>
        </div>


    </q-step>
</template>

<script>
import { GET_REPORT_RESULTSET } from '../../../../graphql/queries';
import { REPORT_REBUILD_RESULTSET } from '../../../../graphql/mutations';
import { parseResultsetField } from '../utils';
import DraggableTable from '../DragableTable.vue';

export default {
    name: 'report-step-view',
    components: {
        DraggableTable
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        report: {
            type: Object,
            required: true
        },
        // the index of the step the report flow is currently in
        activeStep: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            data: [],
            tableMaxRows: 6,
            columnConditions: [],
            filterMenuConditions: [],
            loading: true,
            reportObject: this.report,
            reportHasUpdated: true
        };
    },
    methods: {
        async getData() {
            const { id } = this.$route.params;

            this.reportObject = this.report;

            this.$apollo
                .query({
                    query: GET_REPORT_RESULTSET,
                    variables: {
                        reportId: this.report.id,
                        conditions: {
                            columnConditions: [],
                            filterMenuConditions: [],
                        },
                        offset: 0,
                        limit: 6,
                        orderBy: [],
                        options: {
                            groupOnLabel: false,
                            labelColumnId: ''
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then((result) => {
                    this.data = result.data.resultset;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log('%cNo resultset could be queried:', 'background: #222; color: red');
                    console.log(error);
                })
        },
        parseResultsetField(row, fieldName) {
            return parseResultsetField(row, fieldName);
        },
        rebuildResultset() {
            this.reportHasUpdated = false;
            this.loading = true;

            this.$apollo
                .mutate({
                    mutation: REPORT_REBUILD_RESULTSET,
                    variables: {
                        id: this.report.id
                    },
                })
                .then(result => {
                    const updatedReport = { ...this.report };
                    updatedReport.configuration.projection = result.data.report_rebuildResultset.configuration.projection;
                    this.$emit('reportUpdatedWithoutSaving', updatedReport);
                    this.getData();
                })
                .catch(error => {
                    this.loading = false;
                    console.log('%cCurrent provided configuration could not be built:', 'background: #222; color: red');
                    console.log(error);
                })
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getColumnLabel(projection) {
            switch (projection.type) {
                case 'indicator':
                    return projection.indicator.name;
                case 'indicatorBetterPerformance':
                    return projection.indicator.name;
                case 'average-form-over-formtype-project':
                    return projection.formName;
                case 'average-forms-over-formtype-project':
                    return 'Prestatie';
                case 'total-average-forms-over-formtype-project':
                case 'average-form-over-indicators':
                    return 'Gemiddelde score';
                case 'average-indicators-over-form':
                    return projection.labelName;
                default:
                    return projection.type;
            }
        },
        buildConfigurationProjections(columns) {
            return columns.map(column => column.projection)
        },
        updateColumnOrder(columns) {
            if(this.loading) return

            const projection = this.buildConfigurationProjections(columns);

            const updatedReport = { ...this.report };
            updatedReport.configuration.projection = projection;

            this.$emit('reportUpdated', updatedReport);
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        columns: function () {
            return this.reportObject.configuration.projection
                .filter((projection) => projection.visible)
                .map((projection) => {
                    switch (projection.type) {
                        case 'indicator':
                            return {
                                label: this.$t(this.getColumnLabel(projection)),
                                field: `display-${projection.indicator.id}`,
                                width: '250px',
                                projection
                            };                     
                        default:
                            return {
                                label: this.$t(this.getColumnLabel(projection)),
                                field: `display-${projection.type}`,
                                width: '250px',
                                projection
                            };
                    }
                });
        },
    },
    watch: {
        report: async function () {
            this.reportHasUpdated = true;
        },
        activeStep: function() {
            if(this.activeStep === this.index-1 && this.reportHasUpdated) this.rebuildResultset();
        }
    },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
    overflow-x: auto;

    // th {
    //     max-height: 100%;
    //     overflow-y: hidden;
    //     display: -webkit-box;
    //     max-width: 100%;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    // }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;

    &:hover {
        background: rgb(130, 130, 130);
    }
}
.table {
    width: 100%;
    overflow-x: auto;
}
</style>

<template>
  <div class="q-option option" @click="select">
      {{label}}
  </div>
</template>

<script>
export default {
    name: 'q-option',
    props: {
        label: String,
        value: [String, Number, Object]
    },
    methods: {
        select() {
            
            this.$parent.select({
                label: this.label,
                value: this.value
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div v-if="form" class="enquete-wrapper">
        <q-pageflow v-model="activeStepIndex" :title="form.project.name" :back="`/projects/${form.project.id}`" paddingLeft="40px">
            <template v-slot:items>
                <q-pageitem
                    v-for="step, index in pageFlowItems"
                    :value="index"
                    :key="step.label"
                    :type="{ status: step.status }"
                    class="page-item"
                    :class="{ active: index == activeStepIndex }"
                    :style="`--item-width: ${90 / pageFlowItems.length}%; ${index <= highestVisitedStepIndex ? 'cursor: pointer;' : ''}`"
                    @click.native="navigateToStep(index)"
                >
                    <span class="label">{{ step.label }}</span>
                </q-pageitem>
            </template>
        </q-pageflow>
        <div class="steps-view">
            <div class="steps-wrapper" :style="`--total-steps: ${pageFlowItems.length};--step-index: ${activeStepIndex};`">
                <div class="step-viewport">
                    <div class="step-questions page-padding">
                        <div class="questions-wrapper">
                            <q-step
                                id="step-1"
                                class="main-step"
                                :number="1"
                                :name="`Controleer ${form.name}`"
                                about="In deze stap vind je alle vragen die zijn toegevoegd aan het gekozen formuliertemplate. Controleer je formulier en zet vragen aan of uit waar dat nodig is. Alle vragen met een sterretje zijn verplicht om in te vullen. Vragen die vereist zijn kun je niet uit zetten. Antwoorden en instellingen van vragen kun je wijzigen in beheer."
                                width="100%"
                            ></q-step>
                            <div class="show-message-option custom-question">
                                <div class="header">
                                    <q-switch v-model="form.settings.survey.startMessageEnabled" @input="handleSettingsUpdated"></q-switch>
                                    <p class="title">Bericht of instructietekst weergeven voor het invullen van de vragenlijst</p>
                                </div>
                                <div class="input-wrapper" :class="form.settings.survey.startMessageEnabled ? 'show' : 'hide'">
                                    <div class="line">
                                        <q-dropdown
                                            :value="selectedIntroConfiguration"
                                            :options="introConfigurationOptions" 
                                            @input="handleIntroConfigurationSelected"
                                            size="small" 
                                            placeholder="Begin met een tekst opzet"
                                            autocomplete
                                        ></q-dropdown>
                                        <q-tooltip v-if="introConfigurationOptions.length === 0" position="top">
                                            <template v-slot:tooltip>
                                                <p>Hier kun je tekst templates selecteren om in het tekstveld te gebruiken, je kunt deze tekst ook nog aanpassen</p>
                                            </template>
                                            <q-icon width="20" height="20" type="QuestionCircle"></q-icon>
                                        </q-tooltip>
                                    </div>
                                    <q-text 
                                        :value="form.settings.survey.startMessage"
                                        @input="handleIntroTextInput"
                                        class="text-area" 
                                        maxlength="1500">
                                    </q-text>
                                </div>
                            </div>
                            <div 
                                v-for="category in questionCategories"
                                :key="category.id"
                                class="question-category"
                                :class="{ default: category.id === 'default' }">
                                <div 
                                    v-if="category.id !== 'default'" 
                                    class="category-header" 
                                    :class="openedCategoryIds.includes(category.id) ? 'opened' : 'closed'" 
                                    @click="handleToggleVisibility(category)">
                                    <div class="left">
                                        <div class="switch-container">
                                            <q-tooltip position="top" :disabled="!category.hasMandatory">
                                                <template v-slot:tooltip>
                                                    <p v-if="category.disabled">Deze prestatie kan niet gedeactiveerd worden</p>
                                                    <p v-else>Let op, niet alle vragen worden uitgezet! <br>Deze prestatie bevat vereiste vragen</p>
                                                </template>
                                                <q-switch v-model="category.enabled" :disabled="category.disabled" @input="handleToggleCategory($event, category)"></q-switch>
                                            </q-tooltip>
                                        </div>
                                        <div class="text">
                                            <p class="title">{{ category.name }}</p>
                                            <q-tag :variation="category.activeQuestions > 0 ? 'success' : 'default'" size="small">{{ category.activeQuestions }}/{{ category.questions.length }} {{ category.questions.length === 1 ? 'vraag' : 'vragen' }} in categorie actief</q-tag>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <q-icon type="chevronDown"></q-icon>
                                    </div>
                                </div>
                                <div class="questions-container" :class="openedCategoryIds.includes(category.id) || category.id === 'default' ? 'show' : 'hide'">
                                    <enquete-question
                                        v-for="question in category.questions"
                                        :key="question.componentId"
                                        :index="question.index"
                                        :indicator="question"
                                        :editing="true"
                                        :value="question.answer"
                                        :customLabel="category.id === 'default' ? 'Standaard' : null"
                                        @descriptionInput="handleDescriptionInput"
                                        @toggleActive="toggleActive"
                                        @waitingForSave="handleWaitingForSave"
                                        class="question-component"
                                    ></enquete-question>
                                </div>
                            </div>
                            <div class="accept-terms custom-question">
                                <div class="header">
                                    <q-tooltip position="top">
                                        <template v-slot:tooltip>
                                            <p>Deze vraag is vereist voor het afronden van<br>het formulier en kan je dus niet uitzetten</p>
                                        </template>
                                        <q-switch :value="true" disabled></q-switch>
                                    </q-tooltip>
                                    <p class="title">Ik ga akkoord met het privacybeleid<span class="required">*</span></p>
                                </div>
                                <div class="check">
                                    <q-checkbox class="checkbox" id="accept-terms" disabled></q-checkbox>
                                    <label class="label" for="accept-terms">Met het versturen van dit formulier ga ik ermee akkoord dat Qfact mijn persoonsgegevens verwerkt, zoals beschreven in het <a href="https://enquetes.prestatiedatabank.nl/privacy">privacybeleid</a></label>
                                </div>
                            </div>
                            <div class="show-message-option custom-question">
                                <div class="header">
                                    <q-switch v-model="form.settings.survey.completionMessageEnabled" @input="handleSettingsUpdated"></q-switch>
                                    <p class="title">Bericht weergeven na invullen formulier</p>
                                </div>
                                <div class="input-wrapper" :class="form.settings.survey.completionMessageEnabled ? 'show' : 'hide'" >
                                    <div class="line">
                                        <q-dropdown 
                                            :value="selectedClosingConfiguration"
                                            :options="closingConfigurationOptions" 
                                            @input="handleClosingConfigurationSelected"
                                            size="small" 
                                            placeholder="Begin met een tekst opzet"
                                            autocomplete
                                        ></q-dropdown>
                                        <q-tooltip v-if="closingConfigurationOptions.length === 0" position="top">
                                            <template v-slot:tooltip>
                                                <p>Hier kun je tekst templates selecteren om in het tekstveld te gebruiken, je kunt deze tekst ook nog aanpassen</p>
                                            </template>
                                            <q-icon width="20" height="20" type="QuestionCircle"></q-icon>
                                        </q-tooltip>
                                    </div>
                                    <q-text 
                                        :value="form.settings.survey.completionMessage"
                                        @input="handleClosingTextInput"
                                        class="text-area" 
                                        maxlength="1500">
                                    </q-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-viewport">
                    <div class="step-settings page-padding">
                        <div class="settings-container">
                            <q-step
                                id="step-2"
                                class="main-step"
                                :number="2"
                                name="Formulier instellingen"
                                width="100%"
                            ></q-step>
                            <div class="select-dates section">
                                <div class="header">
                                    <p>Invulperiode bepalen</p>
                                </div>
                                <div class="dates">
                                    <div class="left">
                                        <span>Startdatum</span>
                                        <el-date-picker
                                            class="datepicker"
                                            :value="form.settings.survey.availability.from"
                                            @input="handleAvailabilityUpdated('from', $event)"
                                            type="date"
                                            size="large"
                                            :default-value="new Date()"
                                            value-format="timestamp"
                                            format="dd-MM-yyyy"
                                            placeholder="dd/mm/jj"
                                            ref="focus"
                                        >
                                        </el-date-picker>
                                        <p class="about">Vanaf deze datum kan het formulier ingevuld worden</p>
                                    </div>
                                    <div class="right">
                                        <span>Einddatum</span>
                                        <el-date-picker
                                            class="datepicker"
                                            :value="form.settings.survey.availability.to"
                                            @input="handleAvailabilityUpdated('to', $event)"
                                            type="date"
                                            size="large"
                                            :default-value="new Date()"
                                            value-format="timestamp"
                                            format="dd-MM-yyyy"
                                            placeholder="dd/mm/jj"
                                            ref="focus"
                                        >
                                        </el-date-picker>
                                        <p v-if="!form.settings.survey.availability.to">Tot en met deze datum is het formulier in te vullen</p>
                                        <p v-else class="about">Het is tot <span class="time">{{ getTimeLabel(form.settings.survey.availability.to) }}</span> mogelijk om dit formulier in te vullen.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="section">
                                <div class="header">
                                    <p>Formulierstijl</p>
                                </div>
                                <div class="about">
                                    <p>Kies hier welke stijl je wilt gebruiken voor de opmaak van je formuiler. Je kunt hiermee een eigen logo en kleur toevoegen aan je formulier.</p>
                                </div>
                                <q-dropdown 
                                    class="options" 
                                    :value="form.settings.configurationId" 
                                    :options="styleConfigurationOptions"
                                    :loading="loadingConfigurations"
                                    avatars
                                    @input="handleStyleUpdated"
                                    size="small"
                                    placeholder="Formulierstijl kiezen"
                                    autocomplete
                                >
                                </q-dropdown>
                            </div>
                            <div class="select-attendees section">
                                <div class="header">
                                    <p>Berichtgeving van formulieren</p>
                                </div>
                                <div class="about">
                                    <p>Kies hieronder welke collega’s e-mailberichten ontvangen over dit formulier. Denk hierbij aan de verzendherinnering, nieuwe reacties op het formulier en een bericht wanneer de einddatum is verstreken. </p>
                                </div>
                                <q-dropdown 
                                    class="options" 
                                    v-model="selectedAttendees" 
                                    :options="attendeesOptions" 
                                    @input="handleAttendeesUpdated"
                                    placeholder="Collega’s kiezen" 
                                    size="small"
                                    multiple
                                    autocomplete>
                                </q-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="navigation-buttons">
            <div v-if="activeStepIndex === 0" class="active-questions" :class="{ show: activeStepIndex === 0 }">
                <q-icon v-if="activeQuestions" type="check"></q-icon>
                <p>{{ activeQuestions || 'Geen' }} {{ activeQuestions === 1 ? 'vraag' : 'vragen' }} actief</p>
            </div>
            <q-tooltip v-else-if="form.settings.survey.availability.to" position="top">
                <template v-slot:tooltip>
                    <p>Kopieer de deelbare link naar klembord</p>
                </template>
                <div @click="copyShareLink" class="share-link">
                    <span>{{ shareLink }}</span>
                    <q-icon type="survey" width="16" height="16"></q-icon>
                </div>
            </q-tooltip>
            <q-button 
                :disabled="
                    activeQuestions === 0 || 
                    (activeStepIndex === 1 && !form.settings.survey.availability.to) || 
                    waitingForSave
                " 
                :loading="loading"
                @click="clickNavigationButton"
            >{{ buttonLabel }}</q-button>
        </div>
    </div>
</template>

<script>
import EnqueteQuestion from './EnqueteQuestion.vue';
import _ from 'lodash';
import { CONFIGURATIONS, GET_TOKENS } from '@/graphql/queries.js';

export default {
    name: 'enquete-configuration',
    props: {
        form: {
            tyoe: Object,
            required: true
        },
        questionCategories: {
            type: Array,
            required: true
        },
        onlyShowSettings: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        EnqueteQuestion
    },
    data() {
        return {
            activeStepIndex: 0,
            highestVisitedStepIndex: 0,
            buttonLabel: 'Volgende stap',
            attendeesOptions: [],
            selectedAttendees: [],
            introConfigurationOptions: [],
            closingConfigurationOptions: [],
            styleConfigurationOptions: [],
            shareLink: '',
            openedCategoryIds: [],
            initialisedCategories: false,
            waitingForSave: false,
            tokens: [],
            selectedIntroConfiguration: '',
            selectedClosingConfiguration: '',
            loadingConfigurations: false
        }
    },
    methods: {
        toggleActive(question) {
            if(question.isDefaultQuestion) {
                switch(question.componentId) {
                    case'default-name-question':
                        this.form.settings.survey.enableNameQuestion = question.enabled;
                        break;
                    case'default-email-question':
                        this.form.settings.survey.enableEmailQuestion = question.enabled;
                        break;
                    case'default-function-question':
                        this.form.settings.survey.enableFunctionQuestion = question.enabled;
                        break;
                }
            }

            this.form.components = this.form.components.map(component => {
                if(component.id === question.componentId) component.enabled = question.enabled;
                return component
            })

            if(question.isDefaultQuestion) this.$emit('settingsUpdated', this.form)
            else this.$emit('componentsUpdated', this.form)
        },
        handleToggleVisibility(category) {
            const index = this.openedCategoryIds.indexOf(category.id);
            if(index !== -1) return this.closeCategory(category.id)
            this.openCategory(category.id);
        },
        openCategory(categoryId) {
            if(!this.openedCategoryIds.includes(categoryId));
            this.openedCategoryIds.push(categoryId);
        },
        closeCategory(categoryId) {
            const index = this.openedCategoryIds.indexOf(categoryId);
            this.openedCategoryIds.splice(index, 1);
        },
        handleToggleCategory(active, category) {
            if(category.disabled) return

            this.form.components = this.form.components.map(component => {
                if(component.indicator.labelIds?.includes(category.id) && !component.indicator.mandatory) component.enabled = active;
                return component
            });

            if(active) this.openCategory(category.id);
            else this.closeCategory(category.id);
            
            this.$emit('componentsUpdated', this.form)
        },
        handleDescriptionInput(indicator) {
            this.form.components = this.form.components.map(component => {
                if(component.id === indicator.componentId) component.description = indicator.description;
                return component
            })
            this.$emit('componentsUpdated', this.form)
        },
        handleIntroTextInput(text) {
            this.form.settings.survey.startMessage = text;
            this.checkSelectedIntroConfiguration();
            this.handleTextInput();
        },
        checkSelectedIntroConfiguration() {
            const text = this.form.settings.survey.startMessage;
            const matchingConfiguration = this.introConfigurationOptions.find(option => option.text === text);
            if(matchingConfiguration) this.selectedIntroConfiguration = matchingConfiguration.value;
            else this.selectedIntroConfiguration = '';
        },
        handleClosingTextInput(text) {
            this.form.settings.survey.completionMessage = text;
            this.checkSelectedClosingConfiguration();
            this.handleTextInput();
        },
        checkSelectedClosingConfiguration() {
            const text = this.form.settings.survey.completionMessage;
            const matchingConfiguration = this.closingConfigurationOptions.find(option => option.text === text);
            if(matchingConfiguration) this.selectedClosingConfiguration = matchingConfiguration.value;
            else this.selectedClosingConfiguration = '';
        },
        handleTextInput() {
            this.waitingForSave = true;
            this._handleSettingsUpdated();
        },
        _handleSettingsUpdated: _.debounce(function() {
            this.handleSettingsUpdated();
            this.waitingForSave = false;
        }, 500),
        handleSettingsUpdated() {
            this.$emit('settingsUpdated', this.form)
        },
        handleAvailabilityUpdated(key, value) {
            const oppositeKey = key === 'from' ? 'to' : 'from';
            const oppositeValue = this.form.settings.survey.availability[oppositeKey];
            if((key === 'from' && value > oppositeValue || key === 'to' && value < oppositeValue) && oppositeValue) 
                return this.$store.commit('notify', { type: 'danger', message: 'De combinatie van datums is niet mogelijk' })

            this.form.settings.survey.availability[key] = parseInt(value);
            this.handleSettingsUpdated();
        },
        handleAttendeesUpdated() {
            this.form.settings.attendees = this.form.organisation.users
                .filter(orgUser => orgUser.user && this.selectedAttendees.includes(orgUser.user.id))
                .map(orgUser => orgUser.user);
            this._handleSettingsUpdated();
        },
        handleStyleUpdated(id) {
            this.form.settings.configurationId = id;
            this._handleSettingsUpdated();
        },
        getTimeLabel(unix) {
            const date = new Date(unix);
            return date.toLocaleString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric' }) + ' 23:59'
        },
        clickNavigationButton() {
            if(this.activeStepIndex === 0) {
                this.activeStepIndex = 1;
                document.getElementById('content-overflow').scrollTo(0,0);
                return
            }
            if(this.onlyShowSettings) return this.$emit('insight')
            this.$emit('markSurveySent');
        },
        navigateToStep(index) {
            if(this.onlyShowSettings && index === 0) return
            this.activeStepIndex = index;
            if(this.activeStepIndex === 1) document.getElementById('content-overflow').scrollTo(0,0);
        },
        async copyShareLink() {
            navigator.clipboard.writeText(this.shareLink)
            .then(result => {
                this.$store.commit('notify', { type: 'success', message: 'Deel link gekopieerd naar klembord' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'error', message: 'Deel link niet kunnen kopiëren naar klembord, je kunt ook hem handmatig selecteren' })
            })
        },
        getFullName(user) {
            return `${user.firstName} ${user.lastName}`
        },
        setData() {
            if (this.form.settings.survey.hasCheckedFormQuestionsStep === true || this.form.settings.survey.errorExpiredFormWithoutRespondents === true) this.activeStepIndex = 1;
            const href = window.location.href;
            const re = new RegExp("^http.+\/\/[^\/]+");
            const environment = re.exec(href);
            this.shareLink = environment + '/fill/' + this.form.settings.shareToken;

            this.attendeesOptions = this.form.organisation.users
                .filter(orgUser => Boolean(orgUser.user) && orgUser.status === 'active')
                .map(orgUser => {
                    return {
                        label: this.getFullName(orgUser.user),
                        value: orgUser.user.id
                    }
                })

            const { attendees } = this.form.settings;
            this.selectedAttendees = Array.isArray(attendees) ?attendees.map(attendee => attendee.id) : [];

            if (this.form.settings.survey.errorExpiredFormWithoutRespondents === true) this.$store.commit('notify', { noTimer: true, type: 'info', message: 'Omdat de einddatum is verlopen en er nog geen respondenten zijn zul je opnieuw een einddatum moeten bepalen, zodat het formulier alsnog ingevuld kan worden.' });

            if(this.onlyShowSettings || this.form.settings.survey.availability.to) this.activeStepIndex = 1;
        },
        handleWaitingForSave(waiting) {
            this.waitingForSave = waiting;
        },
        setConfigurations() {
            this.loadingConfigurations = true;

            this.$apollo.query({
                query: CONFIGURATIONS,
                variables: {
                    where: {
                        OR: [
                            { type: 'style' }
                        ]
                    }
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const introConfigurations = result.data.org_configurations.filter(configuration => configuration.type === 'surveyintrotext')
                this.introConfigurationOptions = introConfigurations.map(configuration => {
                    return {
                        label: configuration.name,
                        value: configuration.id,
                        text: configuration.text
                    }
                });
                const closingConfigurations = result.data.org_configurations.filter(configuration => configuration.type === 'surveyclosingtext')
                this.closingConfigurationOptions = closingConfigurations.map(configuration => {
                    return {
                        label: configuration.name,
                        value: configuration.id,
                        text: configuration.text
                    }
                });
                const styleConfigurations = result.data.org_configurations.filter(configuration => configuration.type === 'style');
                const defaultOption = {
                    label: 'Qfact huisstijl',
                    value: null,
                    avatar: '/statics/img/Qfact-logo.png'
                }
                this.styleConfigurationOptions = [ defaultOption, ...styleConfigurations.map(configuration => {
                    const option = {
                        label: configuration.name,
                        value: configuration.id,
                        avatar: configuration.logo
                    }

                    const mainConfigurationId = this.$store.getters.getCurrentOrganisation.mainConfigurationId;
                    const isMainConfiguration = configuration.id === mainConfigurationId;
                    if(isMainConfiguration) option['tag'] = 'Standaard';

                    return option
                }) ];

                this.checkSelectedIntroConfiguration();

                this.loadingConfigurations = false;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de stijlen' })
                this.loadingConfigurations = false;
            })
        },
        setTokens() {
            this.$apollo.query({
                query: GET_TOKENS,
                variables: {
                    projectId: this.form.project.id
                }
            })
            .then(result => {
                const tokens = result.data.tokensWithValue;
                this.tokens = tokens;
            })
            .catch(error => {
                console.log(error)
            })
        },
        handleIntroConfigurationSelected(value) {
            const selectedOption = this.introConfigurationOptions.find(option => option.value === value);
            if(!selectedOption) return
            this.selectedIntroConfiguration = selectedOption.value;
            this.form.settings.survey.startMessage = selectedOption.text;
            this.handleTextInput();
        },
        handleClosingConfigurationSelected(value) {
            const selectedOption = this.closingConfigurationOptions.find(option => option.value === value);
            if(!selectedOption) return
            this.form.settings.survey.completionMessage = selectedOption.text;
            this.handleTextInput();
        }
    },
    computed: {
        pageFlowItems: function() {
            const questionsCompleted = this.activeStepIndex > 0 || this.form.status === 'active' || this.form.settings.survey.errorExpiredFormWithoutRespondents === true;
            const questionsLabel = `Controleer vragen${questionsCompleted ? ` (${this.activeQuestions} vragen actief)` : ''}`;

            const settingsCompleted = Boolean(this.form.settings.survey.availability.to) || this.form.status === 'active';

            if (questionsCompleted) this.form.settings.survey.hasCheckedFormQuestionsStep = true;

            const items = [
                {
                    label: questionsLabel,
                    status: questionsCompleted ? 'succes' : 'not_done'
                },
                {
                    label: 'Formulier instellingen',
                    status: settingsCompleted ? 'succes' : 'not_done'
                }
            ]

            return items
        },
        activeQuestions: function() {
            let activeQuestions = 0;
            this.questionCategories.forEach(category => {
                if(category.id === 'default') return
                activeQuestions += category.questions.filter(question => question.enabled).length;
            })
            return activeQuestions
        }
    },
    created() {
        this.setData();
        this.setConfigurations();
        this.setTokens();
    },
    beforeDestroy() {
        this.$store.commit('resetNotifs')
    },
    watch: {
        activeStepIndex: function() {
            if(this.activeStepIndex > this.highestVisitedStepIndex) this.highestVisitedStepIndex = this.activeStepIndex;

            if(this.activeStepIndex === 0) this.buttonLabel = 'Volgende stap'
            else if(this.onlyShowSettings) this.buttonLabel = 'Terug naar overzicht'
            else this.buttonLabel = 'Markeer als verzonden'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.enquete-wrapper {
    display: flex;
    flex-direction: column;
}

.page-item {
    padding: 0 !important;
    width: var(--item-width);

    &.active {
        width: calc(var(--item-width) + 10%) !important;
    }
}

.steps-view {
    width: 100%;

    .steps-wrapper {
        display: flex;
        width: calc(var(--total-steps) * 100%);
        margin-left: calc(var(--step-index) * -100%);
        transition: margin-left .3s ease;

        .step-viewport {
            width: calc(100% / var(--total-steps));
        }
    }
}


.step-questions {
    position: relative;
    padding-block: 80px;
    min-height: unset;

    .questions-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 80%;
        margin-inline: auto;
        --padding-inline: 24px;

        .main-step {
            margin-left: -70px;
        }

        .custom-question {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-left: var(--padding-inline);
            max-width: 800px;

            .header {
                display: flex;
                gap: 20px;

                .title {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    color: $color-grey-9;
                }
            }
        }
        .show-message-option {
            .input-wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-left: 55px;
                overflow: hidden;

                .line {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                &.hide {
                    max-height: 0;
                    opacity: 0;
                    display: none;
                }

                &.show {
                    --max-height: 280px;
                    animation: maxHeightShow .3s ease;
                    max-height: unset;
                    opacity: 1;
                    display: flex;
                }
            }

        }

        .question-category {
            display: flex;
            flex-direction: column;

            &.default {
                padding-top: 25px;
                
                > * {
                    padding-block: 0 !important;
                    border: none !important;
                }
                .questions-container {
                    animation: unset !important;
                }
            }

            .category-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px calc(var(--padding-inline) - 1px);
                border: 1px solid $color-grey-3;
                border-radius: 2px;
                box-shadow: 0px 2px 12px -6px #8d8d8d;
                user-select: none;
                cursor: pointer;

                &.opened {
                    .right .icon {
                        rotate: 180deg;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;

                    .switch-container {
                        display: flex;
                        align-items: center;
                        width: 126px;
                    }
                    .text {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .title {
                            font-size: 16px;
                            font-weight: 500;
                            color: $color-grey-9;
                            white-space: pre-wrap;
                            width: fit-content;
                        }
                    }
                }

                .right {
                    .icon {
                        transition: .3s ease;
                    }
                }
            }
            .questions-container {
                display: flex;
                flex-direction: column;
                gap: 50px;
                overflow: hidden;
                margin-top: -1px;
                border: 1px solid $color-grey-3;
                padding-block: 40px;
                transition: .3s ease;

                &.hide {
                    max-height: 0;
                    padding: 0;
                    opacity: 0;
                }

                &.show {
                    --max-height: 600px;
                    animation: maxHeightShow .3s ease;
                    max-height: unset;
                    opacity: 1;
                }

                .question-component {
                    margin-left: calc(124px + var(--padding-inline));
                    margin-right: var(--padding-inline);
                }
            }
        }

        .accept-terms {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-left: var(--padding-inline);
            max-width: 800px;

            .header {
                .required {
                    color: $color-blue;
                }
            }


            .check {
                display: flex;
                margin-left: 55px;

                .label {
                    a {
                        color: $color-primary;
                        text-decoration: none;
                    }
                }
            }
        }
    }

}
.step-settings {
    padding-block: 80px;
    min-height: unset;

    .settings-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-inline: auto;
        padding-left: 93px;

        .main-step {
            margin-left: -93px;
        }

        .section {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding-block: 30px;

            .header p {
                font-weight: 500;
                color: #212529;
            }

            .options {
                max-width: 50%;
            }
        }


        .select-dates {
            .dates {
                display: flex;
                font-size: 14px;

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    width: 50%;
                }

                .about {
                    .time {
                        font-weight: 500;
                        color: #212529;
                    }
                }
            }
        }
    }
}

.navigation-buttons {
    position: fixed;
    right: 100px;
    bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 10;

    .saved-state {
        margin-top: 6px;
        display: grid;
        place-items: center;
        width: 130px;
    }

    .active-questions {
        display: flex;
        align-items: center;
        gap: 8px;
        background: white;
        padding: 8px;
        padding-right: 28px;
        margin-right: -20px;
        border-radius: 12px 0 0 12px;
        user-select: none;
        pointer-events: none;
        cursor: default;

        p {
            font-weight: 500;
        }
    }

    .share-link {
        display: flex;
        align-items: center;
        gap: 40px;
        background-color: #F6F6F6;
        color: #757575;
        border-radius: 4px;
        padding: 12px 18px;
        font-size: 14px;
        cursor: pointer;
    }
}

.loader {
    $loader-color: lighten($color-grey-5, 10%);
    $loader-size: 6px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1s;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader12;

    margin-left: 40px;
    margin-bottom: 4px;
}

@keyframes maxHeightShow {
    0% {
        max-height: 0px;
        opacity: 0;
    } 100% {
        max-height: var(--max-height);
        opacity: 1;
    }
}

</style>
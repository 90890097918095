const { AbilityBuilder, Ability } = require('@casl/ability');
const utils = require('./utils');

const createAbility = (tokenPayload, permittedOrganisations) => {
    const { rules, can, cannot } = new AbilityBuilder(); // eslint-disable-line no-unused-vars

    if (
        !tokenPayload ||
        !tokenPayload.data.authMeta ||
        (tokenPayload.data.authMeta.context !== 'global' && tokenPayload.data.authMeta.context !== 'organisation')
    )
        return new Ability(rules);
    const backofficeAdmin =
        tokenPayload.data.authMeta &&
        tokenPayload.data.authMeta.context === 'organisation' &&
        tokenPayload.data.authMeta.organisationId === 'master-organisation' &&
        permittedOrganisations.length === 1;

    const { permissions } = tokenPayload.data;
    if (!permissions) return new Ability(rules);
    permissions.forEach(permission => {
        // User owner subPermissions
        if (permission.type === 'user' && permission.subContext && permission.subContext === 'owner') {
            can(permission.permissions, utils.capitalize(permission.type), { id: tokenPayload.data.userId });
        } else if (permission.type === 'organisation') {
            // Organisation mutations
            can(permission.permissions, utils.capitalize(permission.type));
            if (!backofficeAdmin)
                cannot(permission.permissions, utils.capitalize(permission.type), {
                    id: { $exists: true, $nin: permittedOrganisations }
                });
        } else {
            // General permissions
            can(permission.permissions, utils.capitalize(permission.type));
            if (!backofficeAdmin)
                cannot(permission.permissions, utils.capitalize(permission.type), {
                    organisationId: { $exists: true, $nin: permittedOrganisations }
                });
        }
    });
    return new Ability(rules);
};

const getPermittedOrganisations = tokenPayload => {
    if (
        !tokenPayload ||
        !tokenPayload.data ||
        !tokenPayload.data.authMeta ||
        !('organisationId' in tokenPayload.data.authMeta)
    )
        return [];

    const { organisationId, childOrganisationIds } = tokenPayload.data.authMeta;
    return [organisationId, ...childOrganisationIds];
};

class AbilityObject {
    set(tokenPayload) {
        const permittedOrganisations = getPermittedOrganisations(tokenPayload);
        this.ability = createAbility(tokenPayload, permittedOrganisations);
    }

    get() {
        return this.ability;
    }
}

module.exports = AbilityObject;

<template>
    <div class="page-padding">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.push('/settings/templates')">Overzicht formulierteksten</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>{{ selectedType.name }}</h1>
                </div>
                <p>{{ selectedType.about }}</p>
            </div>
        </div>

        <div class="container">
            <div class="left">
                <div class="row header">
                    <h3>Varianten</h3>
                    <q-button size="small" @click="handleAddTemplate" :loading="updatingConfiguration">+ Variant toevoegen</q-button>
                </div>
                <div 
                    v-for="template in textTemplates" 
                    :key="template.id"
                    class="row template" 
                    :class="{ selected: selectedTemplate.id === template.id }"
                    @click="handleSelectTemplate(template)">
                    <div class="content">
                        <q-avatar class="avatar" size="small">{{ getUserInitials(template.createdUser) }}</q-avatar>
                        <div class="about">
                            <p>{{ template.name || `Nieuwe ${selectedType.name.toLowerCase()}` }}</p>
                            <p>Laatst gewijzigd: {{ parseDate(template.lastEdited) }}</p>
                        </div>
                    </div>
                    <q-icon class="icon" type="chevronRight"></q-icon>
                </div>
            </div>
            <div class="right" v-if="selectedTemplate.id">
                <div class="header">
                    <div class="left">
                        <h3 v-if="!isCreating">{{ selectedTemplate.name }}</h3>
                        <input 
                            v-else 
                            id="template-name-input"
                            class="q-clear-input" 
                            type="text" 
                            :placeholder="`Typ hier de naam van je ${selectedType.name.toLowerCase()}`" 
                            v-model="selectedTemplate.name"
                            @input="_handleTemplateInput">
                        <p>Laatst gewijzigd: {{ parseDate(selectedTemplate.lastEdited) }}</p>
                    </div>
                    <div class="right">
                        <p>Aangemaakt door:<br>{{ getFullName(selectedTemplate.createdUser) }}</p>
                        <q-avatar size="small">{{ getUserInitials(selectedTemplate.createdUser) }}</q-avatar>
                        <q-options :options="templateOptions" color="#C4C4C4" @input="handleOptionSelected"></q-options>
                    </div>
                </div>
                <q-token-text
                    v-if="!disableTokens"
                    class="text-field"
                    :tokens="tokens"
                    v-model="selectedTemplate.text"
                    @input="_handleTemplateInput"
                >
                </q-token-text>
                <q-text
                    v-else
                    class="text-field"
                    v-model="selectedTemplate.text"
                    maxlength="0"
                    @input="_handleTemplateInput"
                >
                </q-text>
            </div>
            <div class="right empty" v-else-if="textTemplates.length === 0">
                <div class="center">
                    <p>Je hebt nog geen teksten aangemaakt. Klik op de knop ‘Variant toevoegen’ om je eerste tekst voor de {{ selectedType.form.toLowerCase() }} toe te voegen.</p>
                    <q-button @click="handleAddTemplate">Variant toevoegen</q-button>
                </div>
            </div>
            <div class="right" v-else></div>
        </div>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import { getDate, userInitials } from '@/assets/js/utils';
import _ from 'lodash';

import { CONFIGURATIONS, GET_TOKENS } from '@/graphql/queries.js';
import { UPDATE_CONFIGURATION, CREATE_CONFIGURATION, DELETE_CONFIGURATION } from '@/graphql/mutations.js';

export default {
    name: 'templates',
    components: {
        ArrowButton
    },
    data() {
        return {
            columns: [
                {
                    label: 'Soort tekst',
                    field: 'type'
                },
                {
                    label: 'Formulier',
                    field: 'form'
                },
                {
                    label: 'Aantal varianten',
                    field: 'templates'
                }
            ],
            tableMaxRows: 10,
            templateOptions: [
                {
                    name: 'Tekst verwijderen',
                    color: 'red'
                }
            ],
            tokens: [],
            textTemplates: [],
            selectedTemplate: {},
            isCreating: false,
            templateType: '',
            selectedType: {},
            templateTypes: [
                {
                    name: 'Tekst brief',
                    type: 'introtext',
                    form: 'Referentiebrief',
                    about: 'Bekijk en beheer hier de varianten van de ‘Tekst brief’ die gebruikt worden om standaard teksten te plaatsen in de referentiebrief.'
                },
                {
                    name: 'Afsluiting',
                    type: 'closingtext',
                    form: 'Referentiebrief',
                    about: 'Bekijk en beheer hier de varianten van de ‘Afsluiting’ die gebruikt worden om standaard afsluitingen te plaatsen in de referentiebrief.'
                },
                {
                    name: 'Intro of instructie',
                    type: 'surveyintrotext',
                    form: 'Enquete',
                    about: 'Bekijk en beheer hier de varianten van de ‘Tekst brief’ die gebruikt worden om standaard teksten te plaatsen in de enquete.'
                },
                {
                    name: 'Afsluiting',
                    type: 'surveyclosingtext',
                    form: 'Enquete',
                    about: 'Bekijk en beheer hier de varianten van de ‘Afsluiting’ die gebruikt worden om standaard afsluitingen te plaatsen in de enquete.'
                }
            ],
            updatingConfiguration: false,
            disableTokens: false
        }
    },
    methods: {
        setConfigurations() {
            this.$apollo.query({
                query: CONFIGURATIONS,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const templates = result.data.org_configurations.filter(configuration => configuration.type === this.templateType)
                this.textTemplates = templates;
                if(templates.length > 0) this.selectedTemplate = templates[0];
            })
            .catch(error => {

            })
        },
        async handleSelectTemplate(template) {
            if(template.id === this.selectedTemplate?.id) return
            if(this.selectedTemplate.id) {
                if(!this.selectedTemplate.name && !this.selectedTemplate.text) return this.handleDeleteTemplate(this.selectedTemplate.id)
                else if(!this.selectedTemplate.name || !this.selectedTemplate.text)
                    return this.$store.commit('notify', { type: 'info', message: 'Je moet de huidige template eerst afmaken of verwijderen.' })
            }
            if(this.selectedTemplate.id !== '12345') this.isCreating = false;
            this.selectedTemplate = template;
        },
        handleAddTemplate() {
            if(this.selectedTemplate.id && (!this.selectedTemplate.name || !this.selectedTemplate.text)) 
                return this.$store.commit('notify', { type: 'info', message: 'Je moet de huidige template afmaken of verwijderen om een nieuwe toe te voegen.' })

            const newTemplate = {
                id: '12345',
                type: this.templateType,
                name: '',
                text: '',
                createdUser: this.$store.getters.getUser,
                creationDate: Date.now(),
                lastEdited: Date.now()
            }
            this.textTemplates.push(newTemplate)
            this.handleSelectTemplate(newTemplate)
            this.isCreating = true;

            this.focusNameInput()
        },
        handleOptionSelected(option) {
            if(option.name === 'Tekst verwijderen') {
                return this.handleDeleteTemplate(this.selectedTemplate.id)
            }
        },
        _handleTemplateInput: _.debounce(function() {
            this.handleUpdateTemplate(this.selectedTemplate)
        }, 500),
        handleCreateTemplate(template) {
            if(!template.name || !template.text) return

            this.updatingConfiguration = true;

            const configuration = {
                name: template.name,
                text: template.text,
                type: this.templateType
            }

            this.$apollo.mutate({
                mutation: CREATE_CONFIGURATION,
                variables: {
                    configuration
                }
            })
            .then(result => {
                this.updatingConfiguration = false;
                const createdConfiguration = result.data.org_configurationCreated;
                this.textTemplates = this.textTemplates.map(_template => {
                    if(_template.id === template.id) _template.id = createdConfiguration.id
                    return _template
                })
            })
            .catch(error => {
                this.updatingConfiguration = false;
                console.log(error)
            })
        },
        handleUpdateTemplate(template) {
            if(template.id === '12345') return this.handleCreateTemplate(template)
            this.updatingConfiguration = true;

            const configuration = {
                name: template.name,
                text: template.text,
                type: this.templateType
            }

            this.$apollo.mutate({
                mutation: UPDATE_CONFIGURATION,
                variables: {
                    id: template.id,
                    configuration
                }
            })
            .then(result => {
                this.updatingConfiguration = false;
            })
            .catch(error => {
                this.updatingConfiguration = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er is iets fout gegaan tijdens het opslaan van de tekst, probeer dit later opnieuw' })
                console.log(error)
            })
        },
        handleDeleteTemplate(templateId) {
            if(templateId === '12345') {
                const templateIndex = this.textTemplates.findIndex(template => template.id === templateId)
                this.textTemplates.splice(templateIndex, 1)
                this.selectedTemplate = {};
                this.isCreating = false;
                return
            }
            this.$apollo.mutate({
                mutation: DELETE_CONFIGURATION,
                variables: {
                    id: templateId
                }
            })
            .then(result => {
                const index = this.textTemplates.findIndex(template => template.id === templateId);
                if(this.textTemplates.length === 0) this.textTemplates = []
                this.textTemplates.splice(index, 1);
                this.selectedTemplate = {}
            })
        },
        parseDate(unix) {
            return getDate(unix)
        },
        getFullName(user) {
            if(!user) return ''
            return `${user.firstName} ${user.lastName}`
        },
        getUserInitials(user) {
            if(!user) return ''
            return userInitials(user.firstName, user.lastName)
        },
        async focusNameInput() {
            await new Promise(r => setTimeout(r, 1));
            const input = document.getElementById('template-name-input')
            input.focus()
        },
        setTokens() {
            this.$apollo.query({
                query: GET_TOKENS,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.tokens = result.data.tokensWithValue;
            })
            .catch(error => {
                console.log(error)
            })
        },
    },
    created() {
        this.templateType = this.$route.params.id.toLowerCase();
        this.selectedType = this.templateTypes.find(type => type.type === this.templateType);
        this.setConfigurations();

        if(this.templateType.includes('survey')) this.disableTokens = true;
        else this.setTokens();
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.page-padding {
    padding-bottom: 0;
}

.container {
    display: flex;
    height: 100%;
    width: 100%;

    > .left {
        display: flex;
        flex-direction: column;
        width: 25%;
        min-width: 300px;

        .row {
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #DEE2E6;
        }

        .header {
            justify-content: space-between;
            padding: 12px;
            padding-left: 0;
        }

        .template {
            align-items: center;
            justify-content: space-between;
            padding: 12px 8px;
            cursor: pointer;
            user-select: none;

            &:hover,
            &.selected {
                background-color: #F5F5F5;
                .icon {
                    translate: 4px 0;
                }
            }
            &.selected {
                .icon {
                    color: $color-grey-7;
                }
            }

            &:active {
                .icon {
                    translate: 0px 0;
                }
            }

            .content {
                display: flex;
                align-items: center;
                gap: 12px;
                width: 90%;
                
                .about {
                    display: inline-block;
                    width: 80%;
    
                    :nth-child(1) {
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 500;
                        color: $color-grey-7;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    :nth-child(2) {
                        font-size: 12px;
                        line-height: 15px;
                        color: #A1A1A1;
                    }
                }

            }
            .icon {
                color: $color-grey-5;
                transition: .2s ease;
            }
        }

    }

    > .right {
        padding: 8px 36px;
        border-left: 1px solid #DEE2E6;
        width: 100%;
        min-height: 500px;

        .header {
            display: flex;
            justify-content: space-between;
            gap: 10%;
            width: 100%;

            .left {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .q-clear-input {
                    font-size: 15px;
                    padding: 2px 4px;
                }

                p {
                    font-size: 13px;
                    color: #A1A1A1;
                }
            }
            .right {
                display: flex;
                align-items: center;
                gap: 12px;
                text-align: right;

                p {
                    font-size: 13px;
                    line-height: 16px;
                }

            }

        }

        .text-field {
            margin-top: 20px;
        }

        &.empty {
            display: flex;
            justify-content: center;
            align-items: center;

            .center {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 50%;
            }
        }

    }
}

</style>
<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>Sleutel {{ apiKey.name }} wijzigen</h2>
        </div>

        <p class="mt-s">Je kunt de naam van de sleutel wijzigen of de sleutel helemaal verwijderen.</p>
        <div class="modal-content">
            <br />
            <q-input labelPosition="inside" v-model="newName" name="newName" id="newName">Naam API key</q-input>
        </div>

        <div class="footer space-between">
            <q-button variation="blank" @click="remove()">Verwijderen</q-button>
            <q-button @click="changeName()">Opslaan</q-button>
        </div>
    </div>
</template>

<script>
import { ORGANISATION_UPDATE_API_KEY } from '@/graphql/mutations';

export default {
    props: ['apiKey'],
    data() {
        return {
            newName: this.apiKey.name
        };
    },
    methods: {
        remove() {
            this.$emit('removeKey');
        },
        changeName() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .query({
                    query: ORGANISATION_UPDATE_API_KEY,
                    variables: {
                        organisationId,
                        id: this.apiKey.id,
                        name: this.newName
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.$emit('close', result.data.org_updateApiKey);
                    this.$store.commit('notify', { type: 'success', message: 'Sleutel is succesvol aangepast' });
                })
                .catch(err => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan' });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;

    .modal-content {
        margin-bottom: 80px;

        input {
            width: 100%;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}

.profile-right {
    margin-left: 16px;

    .name {
        font-weight: 500;
    }
    .role {
        margin-left: -2px;
    }
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.permission-wrapper {
    display: flex;
    margin: 40px 0px 90px;
    min-height: 100px;

    .subhead {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .left {
        width: 35%;
        min-width: 200px;
        border-right: 1px solid rgb(222, 226, 230);

        .category-item {
            padding: 4px 8px;
            border: 1px solid white;
            border-radius: 4px;
            margin-right: 24px;
            margin-left: -8px;
            // margin-bottom: 8px;

            &:hover {
                border-color: rgb(222, 226, 230);
                cursor: pointer;
            }

            .active {
                color: $color-primary;
                font-weight: 500;
            }

            .count {
                color: $color-grey-5;
            }
        }
    }

    .right {
        padding: 0px 40px;
        width: calc(100% - 35%);
        max-height: 250px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $color-white;
            border-radius: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-grey-3;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-grey-5;
        }
    }
}
</style>

<template>
    <div class="modal">
        <div>
            <h2>Key verwijderen</h2>
        </div>

        <p class="mb-m mt-s">
            Weet je zeker dat je "<strong>{{ apiKey.name }}</strong>" wilt verwijderen?
        </p>
        <div class="modal-content"></div>

        <div class="footer space-between flex-between">
            <q-button variation="blank" @click="$emit('close')">Annuleren</q-button>
            <q-button variation="danger" @click="removeToken()">Verwijderen</q-button>
        </div>
    </div>
</template>

<script>
import { ORGANISATION_REMOVE_API_KEY } from '@/graphql/mutations';

export default {
    props: ['apiKey'],
    data() {
        return {
            err: null
        };
    },
    methods: {
        removeToken() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .query({
                    query: ORGANISATION_REMOVE_API_KEY,
                    variables: { organisationId, id: this.apiKey.id },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.$store.commit('notify', { type: 'success', message: 'Sleutel is succesvol verwijderd' })
                    this.$emit('close', result.data.org_removeApiKey);
                })
                .catch(err => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van de sleutel' })
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    width: 400px;
    display: flex;
    flex-direction: column;

    .modal-content {
        margin-bottom: 30px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}

.flex-between {
    display: flex;
    justify-content: space-between;
}
</style>

<template>
    <auth-base>
        <div class="q-card-small">
            <q-section vPadding="40" hPadding="40">
                <h3>Wachtwoord vergeten?</h3>
                <p class="py-m">
                    Het overkomt de beste. Vul hieronder je e-mailadres in en klik op de knop ‘Versturen’. Je ontvangt
                    van ons een e-mail met daarin een link om een je nieuwe wachtwoord in te stellen.
                </p>
                <div class="text-align-center">
                    <q-input labelPosition="inside" inputType="email" placeholder="E-mailadres" v-model="email">E-mailadres</q-input>
                </div>
            </q-section>
            <q-section vPadding="24" hPadding="40" borderTop class="space-between">
                <q-button go="-1" variation="blank" size="medium">Terug naar inlogscherm</q-button>
                <q-button variation="primary" size="medium" :loading="awaitLogin" @click="handleInput" :disabled="appDisabled"
                    >Versturen</q-button
                >
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { REQUEST_RESETPASSWORD } from '@/graphql/mutations';
import Notifications from '@/components/Notifications';
import { extractError } from '@/assets/js/utils';

export default {
    name: 'ResetPassword',
    components: {
        AuthBase,
        Notifications,
    },
    data() {
        return {
            email: '',
            awaitLogin: false,
            appDisabled: process.env.BLOCK_LOGIN || false,
        };
    },

    methods: {
        handleInput() {
            this.awaitLogin = true;
            this.$apollo
                .mutate({
                    mutation: REQUEST_RESETPASSWORD,
                    variables: { email: this.email },
                })
                .then((response) => {
                    this.awaitLogin = false;
                    this.$router.push('/ResetPasswordConfirm');
                })
                .catch((err) => {
                    this.awaitLogin = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.87158 17.5181L20.8002 12.8574V24.3485L10.4341 29.8128L9.87158 17.5181Z" fill="#662483" />
        <path d="M9.87158 17.5176L19.5145 23.6783V35.9997L10.4585 29.839L9.87158 17.5176Z" fill="#006B94" />
        <path d="M20.8002 12.8574L30.443 18.3217L29.8561 30.3485L20.8002 24.1074V12.8574Z" fill="#662483" />
        <path
            d="M20.8002 12.8574L30.443 18.3217L29.8561 30.3485L20.8002 24.1074V12.8574Z"
            fill="black"
            fill-opacity="0.25"
        />
        <path d="M19.5144 23.6794L30.443 18.3223L30.0227 30.5402L19.5144 36.0009V23.6794Z" fill="#00A1AE" />
        <path
            d="M19.5468 23.1323L10.9367 17.6075L20.7688 13.4144L29.3743 18.2908L19.5468 23.1323Z"
            stroke="url(#paint0_radial)"
        />
        <path
            opacity="0.75"
            d="M29.391 3.74023L27.404 19.0519L24.4819 20.4545L25.1832 7.71426L29.391 3.74023Z"
            fill="url(#paint1_radial)"
            fill-opacity="0.5"
        />
        <path
            opacity="0.75"
            d="M4.26111 3.27344L11.391 17.6501L19.5728 22.9098L19.952 8.476L4.26111 3.27344Z"
            fill="url(#paint2_radial)"
        />
        <path
            opacity="0.75"
            d="M19.5729 6.31192V22.9093L28.9235 18.3509L34.0664 2.33789L19.5729 6.31192Z"
            fill="url(#paint3_radial)"
        />
        <path
            opacity="0.75"
            d="M19.5729 22.9095L16.5339 20.9225L14.3131 3.15625L25.3001 3.85755L22.2612 21.6238L19.5729 22.9095Z"
            fill="url(#paint4_radial)"
            fill-opacity="0.5"
        />
        <path
            opacity="0.75"
            d="M19.5729 22.9087L15.8326 20.5711L12.443 2.10352L23.43 2.80482L21.5599 21.9737L19.5729 22.9087Z"
            fill="url(#paint5_radial)"
        />
        <path
            opacity="0.75"
            d="M16.7677 15.4286L11.2742 17.6494L4.84558 1.87013L20.8586 0L20.5079 13.7922L16.7677 15.4286Z"
            fill="url(#paint6_radial)"
        />
        <path
            opacity="0.75"
            d="M28.8066 18.3513L20.391 22.4422L19.9235 0.234375L34.417 3.27334L28.8066 18.3513Z"
            fill="url(#paint7_radial)"
        />
        <path
            opacity="0.75"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5469 19.5194L19.5729 22.909L30.2093 17.7662L30.9337 17.0418C30.9631 16.5704 30.9142 16.1065 30.7807 15.6566C29.6762 11.9341 23.1981 10.5728 16.3115 12.6161C13.7146 13.3865 11.4305 14.5311 9.65173 15.8577L14.5469 19.5194Z"
            fill="url(#paint8_radial)"
        />
        <path
            d="M25.4898 19.8107C30.7885 14.9611 30.7279 9.4949 30.7279 9.4949"
            stroke="url(#paint9_linear)"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
        <ellipse
            rx="0.958801"
            ry="0.862034"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 16.3966 12.6862)"
            fill="#00A1AE"
        />
        <ellipse
            rx="0.958801"
            ry="0.957816"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 9.83514 15.6675)"
            fill="#EE870E"
        />
        <ellipse
            rx="0.664287"
            ry="0.395957"
            transform="matrix(0.977568 -0.21062 0.213225 0.977003 8.73516 28.579)"
            fill="#EE870E"
        />
        <ellipse
            rx="0.998361"
            ry="1.00121"
            transform="matrix(0.0202456 -0.999795 0.999797 0.0201304 14.8891 7.63137)"
            fill="#C634AE"
        />
        <ellipse
            rx="0.998361"
            ry="1.00121"
            transform="matrix(0.0202456 -0.999795 0.999797 0.0201304 13.2358 15.0865)"
            fill="#006B94"
        />
        <ellipse
            rx="0.958801"
            ry="0.957816"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 31.581 17.4995)"
            fill="#662483"
        />
        <ellipse
            rx="0.958801"
            ry="0.436609"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 31.9493 29.6541)"
            fill="#662483"
        />
        <ellipse
            rx="1.05468"
            ry="0.957816"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 29.1838 23.3184)"
            fill="#EE870E"
        />
        <ellipse
            rx="0.86292"
            ry="0.957816"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 34.5645 19.6357)"
            fill="#C634AE"
        />
        <ellipse
            rx="0.61896"
            ry="0.687028"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 8.20036 30.6031)"
            fill="#C634AE"
        />
        <ellipse
            rx="0.61896"
            ry="0.687028"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 30.8758 28.2652)"
            fill="#C634AE"
        />
        <ellipse
            rx="0.958801"
            ry="1.0536"
            transform="matrix(0.825278 -0.564726 0.566923 0.82377 30.1209 19.7757)"
            fill="#C634AE"
        />
        <path
            d="M13.9002 28.4372C13.9002 28.4372 10.7827 26.6281 13.8936 23.8929C17.0045 21.1577 12.6167 22.0374 8.93825 23.948C5.25984 25.8586 13.8141 17.8823 11.0546 18.8608C8.29517 19.8393 5.4055 18.9054 3.83047 16.5261"
            stroke="url(#paint10_linear)"
            stroke-linecap="square"
        />
        <path
            d="M18.8113 18.3631C18.8113 18.3631 18.954 14.7923 22.7483 16.1643C26.5426 17.5362 23.773 14.1638 20.4341 11.9028C17.0953 9.64172 27.9232 13.1947 25.8128 11.279C23.7023 9.36335 23.1904 6.41209 24.5329 3.90086"
            stroke="url(#paint11_linear)"
            stroke-linecap="square"
        />
        <defs>
            <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(20.1573 18.2815) rotate(90) scale(5.42411 10.2857)"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#00A1AE" />
            </radialGradient>
            <radialGradient
                id="paint1_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25.7676 19.987) rotate(8.12998) scale(0.826489 9.59464)"
            >
                <stop stop-color="#C634AE" stop-opacity="0.5" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint2_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(15.1313 19.754) rotate(177.879) scale(3.15801 13.1166)"
            >
                <stop stop-color="#C634AE" stop-opacity="0.5" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint3_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(22.9625 23.26) rotate(-166.866) scale(3.60069 13.033)"
            >
                <stop stop-color="#C634AE" stop-opacity="0.5" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint4_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(22.3781 21.0394) rotate(-173.517) scale(5.17596 16.9506)"
            >
                <stop stop-color="#C634AE" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint5_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(21.7937 22.3243) rotate(-93.3501) scale(24.0021 29.0166)"
            >
                <stop stop-color="#C634AE" stop-opacity="0.9" />
                <stop offset="0.541667" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint6_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17.3521 15.8961) rotate(-120.626) scale(16.9787 11.8459)"
            >
                <stop stop-color="#C634AE" stop-opacity="0.5" />
                <stop offset="0.880208" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint7_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.5728 18.585) rotate(173.387) scale(8.11897 14.8093)"
            >
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.0001" stop-color="#C634AE" stop-opacity="0.5" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <radialGradient
                id="paint8_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(19.9304 19.6737) rotate(62.8058) scale(4.19268 8.66534)"
            >
                <stop stop-color="#C634AE" />
                <stop offset="1" stop-color="#C634AE" stop-opacity="0" />
            </radialGradient>
            <linearGradient
                id="paint9_linear"
                x1="29.3911"
                y1="17.0641"
                x2="32.8547"
                y2="10.496"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.546875" stop-color="#C634AE" />
                <stop offset="1" stop-color="#841572" />
            </linearGradient>
            <linearGradient
                id="paint10_linear"
                x1="8.7676"
                y1="25.2778"
                x2="14.1499"
                y2="19.9747"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#662483" />
                <stop offset="1" stop-color="#3E0B55" />
            </linearGradient>
            <linearGradient
                id="paint11_linear"
                x1="19.2031"
                y1="12.3968"
                x2="26.2333"
                y2="14.5892"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.588542" stop-color="#EE870E" />
                <stop offset="1" stop-color="#C67D28" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'Present',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 36
        },
        height: {
            type: [Number, String],
            default: 36
        }
    }
};
</script>

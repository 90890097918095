<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>Project delen met</h2>
        </div>

        <p class="mb-m mt-s" v-html="introText"></p>
        <div v-if="projectRole || projectRole === 0" class="modal-content">
            <div v-if="projectUser" class="space-between vertical-center">
                <div class="vertical-center">
                    <q-avatar :img="projectUser.user.avatar"> {{ userInitials() }}</q-avatar>
                    <div class="profile-right">
                        <p class="name">{{ nameOrEmail }}</p>
                        <q-tag size="small" class="mini-tag role truncate-text" variation="default"
                            >{{ $t(`projectRoles.${currentRole}`) }} - {{ $t(projectUser.organisation.name) }}</q-tag
                        >
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="addUserToOrganisation && users.length > 0">
                    <q-dropdown
                        avatars
                        size="small"
                        v-model="userToAdd"
                        :options="users"
                        :disabled="!addUserToOrganisation || !users"
                        placeholder="Kies een gebruiker"
                        autocomplete
                        >Gebruiker</q-dropdown
                    >
                    <p v-if="!project.hybridmode" class="mt-s">Of nodig een gebruiker uit per e-mail</p>
                    <p v-else class="mt-s">Wil je een nieuw projectlid toevoegen dat nog geen account heeft in de software? Dit doe je voorlopig nog via de groslijsten, bekijk <a target="_blank" href="https://kennisbank.qfact.nl/nl/article/een-collega-uitnodigen-in-de-emvi-omgeving">hier</a> hoe je dit doet.</p>
                </div>
                <div v-else-if="!project.hybridmode">
                    <p class="mt-s">
                        Geen gebruikers gevonden om toe te voegen. U kunt een gebruiker toevoegen per e-mail.
                    </p>
                </div>
                <div v-else>
                    <p class="mt-s">
                        Geen gebruikers gevonden om toe te voegen. Wil je een nieuw projectlid toevoegen dat nog geen account heeft in de software? Dit doe je voorlopig nog via de groslijsten, bekijk <a target="_blank" href="https://kennisbank.qfact.nl/nl/article/een-collega-uitnodigen-in-de-emvi-omgeving">hier</a> hoe je dit doet.
                    </p>
                </div>
                <div v-if="!project.hybridmode">
                    <q-input labelPosition="inside"
                        v-model="userToAddEmail"
                        placeholder="Voer hier een e-mailadres in..."
                        size="small"
                        >E-mailadres</q-input
                    >
                </div>
            </div>

            <div v-if="!selectedAllAdmins && !selectedInvitedUser">
                <p class="vertical-center mt-m">
                    <span style="margin-right: 8px; font-weight: 500;">Projectrol selecteren</span>
                    <q-tooltip position="top">
                        <template v-slot:tooltip>
                            <p>
                                Sleep het rondje naar links en rechts (klikken en vasthouden) om de projectrol te
                                veranderen.
                            </p>
                        </template>
                        <div class="question-circle">
                            <q-icon type="QuestionCircle"></q-icon>
                        </div>
                    </q-tooltip>
                </p>

                <q-slider
                    style="margin: 16px -8px 24px -8px;"
                    :min="0"
                    :max="projectRoles.length - 1"
                    :steps="1"
                    :stepLabels="sliderSteps"
                    v-model="projectRole"
                >
                </q-slider>
                <h4 class="mb-xs">
                    {{ $t(`projectRoles.${projectRoles[projectRole].name}`) }}
                </h4>
                <p>{{ projectRoles[projectRole].description }}</p>
            </div>

            <div v-if="!isNewUser && !selectedAllAdmins" class="mt-m">
                <q-button
                    variation="danger"
                    size="small"
                    icon="close"
                    iconSize="12"
                    @click="handleRemoveUser()"
                    >Ontkoppelen van project</q-button
                >
            </div>
        </div>

        <div class="footer space-between">
            <q-button @click="close" size="medium" variation="blank">Sluiten</q-button>
            <div>
                <q-button
                    v-if="!selectedAllAdmins && !selectedInvitedUser && (userToAdd || userToAddEmail || projectUser)"
                    @click="addOrUpdateUser"
                    :disabled="false"
                    size="medium"
                    variation="primary"
                    >{{ isNewUser ? 'Gebruiker uitnodigen' : 'Gebruiker bijwerken' }}</q-button
                >
            </div>
        </div>
        <q-popup v-if="confirmRemoveUser">
            <confirm-delete-modal :deleteMessage="`Weet u zeker dat u ${projectUser.user.firstName} wilt <b>verwijderen</b>?`" cancelButtonText="Annuleren" confirmButtonText="Gebruiker verwijderen" @close="closePopup()" @confirm="removeUser()"></confirm-delete-modal>
        </q-popup>
    </div>
</template>

<script>
import _ from 'lodash';
import { validateEmail, extractError, userInitials } from '@/assets/js/utils';
import { PROJECT_ADD_MEMBER, PROJECT_CHANGE_MEMBER, PROJECT_REMOVE_MEMBER } from '@/graphql/mutations';
import { GET_ORGANISATION_USERS } from '@/graphql/queries';
import confirmDeleteModal from '../../settings/confirmDeleteModal'

const PROJECT_ROLES = [
    {
        name: 'view',
        description: 'Deze rol mag projecten en formulieren bekijken, maar niet aanpassen.'
    },
    {
        name: 'fill',
        description: 'Beoordelaars kunnen projectgegevens zien, formulieren bekijken, aanmaken en invullen.'
    },
    {
        name: 'admin',
        description:
            'Beheerders kunnen projectgegevens aanpassen, formulieren beheren en project instellingen wijzigen.'
    }
];

export default {
    props: ['project', 'projectUser', 'organisations', 'selectedOrganisationId'],
    components: { confirmDeleteModal },
    data() {
        return {
            editLoading: false,
            deleteLoading: false,
            rolesLoading: false,
            twofactorStatus: false,
            projectRole: null,
            projectRoles: PROJECT_ROLES || [],
            addUserToOrganisation: null,
            users: [],
            userToAdd: null,
            userToAddEmail: '',
            confirmRemoveUser: false
        };
    },
    methods: {
        userInitials() {
            return userInitials(this.projectUser.user.firstName, this.projectUser.user.lastName);
        },
        close() {
            this.$emit('close');
        },
        addOrUpdateUser() {
            const id = this.project.id;
            const userId = this.userToAdd
                ? this.userToAdd.user.id
                : this.projectUser
                ? this.projectUser.user.id
                : undefined;
            const email = this.userToAddEmail;
            const roles = [PROJECT_ROLES[this.projectRole].name];
            const organisationId = this.userToAdd
                ? this.addUserToOrganisation
                : this.projectUser
                ? this.projectUser.organisationId
                : this.addUserToOrganisation;
            const mutation = this.isNewUser ? PROJECT_ADD_MEMBER : PROJECT_CHANGE_MEMBER;

            if (!userId && email == '' || userId && email)
                return this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Kies een gebruiker of voer een e-mail adres in'
                });

            if (email && !validateEmail(email))
                return this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Voer een correct email adres in'
                });

            this.$apollo
                .mutate({
                    mutation,
                    variables: {
                        id,
                        userId,
                        email: userId ? undefined : email,
                        roles,
                        organisationId
                    }
                })
                .then(() => {
                    this.$store.commit('notify', {
                        type: 'success',
                        message: this.isNewUser ? 'Gebruiker is toegevoegd aan het project' : 'Gebruiker is bijgewerkt'
                    });
                    this.$emit('success');
                })
                .catch(err => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er is iets misgegaan' });
                });
        },
        closePopup() {
            this.confirmRemoveUser = false
        },
        handleRemoveUser() {
            this.confirmRemoveUser = true
        },
        removeUser() {

            let variables = {
                id: this.project.id,
                organisationId: this.projectUser.organisationId
            }

            if (this.selectedInvitedUser) variables.index = this.projectUser.index
            else variables.userId = this.projectUser.user.id

            this.$apollo
                .mutate({
                    mutation: PROJECT_REMOVE_MEMBER,
                    variables
                })
                .then(() => {
                    this.$store.commit('notify', {
                        type: 'success',
                        message: `Gebruiker ${this.nameOrEmail} is verwijderd van het project`
                    });
                    this.$emit('success');
                })
                .catch(err => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er is iets misgegaan' });
                });
        }
    },
    computed: {
        nameOrEmail() {
            return this.projectUser.user.name ? `${this.projectUser.user.name}` : `${this.projectUser.user.email}`;
        },
        sliderSteps() {
            return this.projectRoles.map(role => this.$t(`projectRoles.${role.name}`));
        },
        activateSwitchLabel() {
            return this.newStatus ? 'deactiveren' : 'activeren';
        },
        isNewUser() {
            return !this.projectUser;
        },
        selectedAllAdmins() {
            return this.projectUser && !this.projectUser.user.id && this.projectUser.roles.includes("admin");
        },
        currentRole() {
            const filteredRoles = this.projectUser
                ? this.projectUser.roles.filter(role => role !== 'contactperson')
                : [];
            return filteredRoles.length > 0 ? filteredRoles[0] : this.projectUser ? this.projectUser.roles[0] : null;
        },
        introText() {
            const inviteByEmail = this.project?.hybridmode ? '' : ' of nodig deze uit op basis van een e-mailadres';
            const newUserText = `Kies de gewenste organisatie en selecteer daarna de gebruiker${inviteByEmail}. Bepaal wat de gebruiker mag via de projectrol en klik op de knop ‘gebruiker uitnodigen’`;
            const existingUserText = `Hier kan je de rol van onderstaande gebruiker wijzigen in het project of de toegang ontzeggen.`;
            const adminsUserText = `Beheerders van een organisatie hebben altijd<br /> toegang tot het project.`
            
            return this.isNewUser ? newUserText : this.selectedAllAdmins ? adminsUserText : existingUserText;
        },
        selectedInvitedUser() {
            return this.projectUser && !this.projectUser.user.id && this.projectUser.roles.includes("invited");
        }
    },
    watch: {
        addUserToOrganisation: function(id) {
            if (!id) return;

            this.$apollo
                .query({
                    query: GET_ORGANISATION_USERS,
                    variables: {
                        organisationId: id
                    }
                })
                .then(result => {
                    this.users = result.data.organisation.users
                        .filter(user => {
                            if (!user.user || !user.status || user.status.indexOf('invited') > -1 ) return false;
                            if (!this.project.members) return true;

                            const filteredMembers =
                                this.project.members
                                    .filter( member => member.status !== 'invited' 
                                            && member.status !== 'remote_added'
                                            && user.user
                                            && member.user
                                            && member.user.id === user.user.id
                                            && member.organisationId === id );
                            const alreadyRelatedToProjectWithOrganisation = (Array.isArray(filteredMembers) && filteredMembers.length > 0);                             

                            return !alreadyRelatedToProjectWithOrganisation;
                        })
                        .map(user => {
                            return {
                                value: user,
                                label: user.user.firstName
                                    ? `${user.user.firstName} ${user.user.lastName}`
                                    : user.user.email,
                                avatar: user.user.avatar,
                                avatarFallback: userInitials(user.user.firstName, user.user.lastName)
                            };
                        });
                });
        }
    },
    mounted() {
        this.addUserToOrganisation = this.selectedOrganisationId;
        const currentRoleIndex = PROJECT_ROLES.findIndex(role => role.name === this.currentRole);
        this.projectRole = currentRoleIndex < 0 ? 2 : currentRoleIndex;
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 400px !important;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    min-height: 350px;
    max-height: 550px;

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}

.profile-right {
    margin-left: 16px;

    .name {
        font-weight: 500;
    }
    .role {
        margin-left: -2px;
        max-width: 300px;
    }
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.permission-wrapper {
    display: flex;
    margin: 40px 0px 90px;
    min-height: 100px;

    .subhead {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .left {
        width: 35%;
        min-width: 200px;
        border-right: 1px solid rgb(222, 226, 230);

        .category-item {
            padding: 4px 8px;
            border: 1px solid white;
            border-radius: 4px;
            margin-right: 24px;
            margin-left: -8px;
            // margin-bottom: 8px;

            &:hover {
                border-color: rgb(222, 226, 230);
                cursor: pointer;
            }

            .active {
                color: $color-primary;
                font-weight: 500;
            }

            .count {
                color: $color-grey-5;
            }
        }
    }

    .right {
        padding: 0px 40px;
        width: calc(100% - 35%);
        max-height: 250px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $color-white;
            border-radius: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-grey-3;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-grey-5;
        }
    }
}
</style>

<template>
    <div
        class="slider-wrapper"
        @mouseenter="showCurrentTooltip = true"
        @mouseleave="showCurrentTooltip = false"
        ref="wrapper">

        <div class="header">
            <div class="top">
                <p v-if="label" class="label">{{ label }}</p>
            </div>
            <div class="bottom">
                <div v-if="numberInput" class="inputs-wrapper">
                    <q-input :value="start" :style="`--chars: ${inputCharacterWidth}`" inputType="number" size="small" :min="min" :max="current" :placeholder="`${min}`" selectOnFocus @input="handleNumberInput($event, 'start')" @blur="_handleInputBlur"></q-input>
                    <span>-</span>
                    <q-input :value="current" :style="`--chars: ${inputCharacterWidth}`" direction="rtl" inputType="number" size="small" :min="start" :max="max" :placeholder="`${max}`" selectOnFocus @input="handleNumberInput($event, 'current')" @blur="_handleInputBlur"></q-input>
                </div>
            </div>
        </div>


        <div id="q-slider" class="q-slider" ref="slider">
            <div class="start back pointer" :class="range ? 'dot' : 'no-dot'" @click="moveCurrentTo(-9999)" ref="begin"></div>

            <div class="end dot back pointer" @click="moveCurrentTo(9999)" data-testid="slider-end"></div>

            <div class="bar pointer clickable"></div>

            <div class="slide" :style="calculateStyle()" :class="{ 'no-transition': grabbingStart || grabbingCurrent }" data-testid="slider">
                <div :class="['start', 'dot', 'front', 'left', range ? 'grab' : 'no-dot', hasDoubleRangeValue ? 'master-card' : '']" ref="start">
                    <q-tooltip
                        :permanent="showCurrentTooltip"
                        text="bottom"
                        position="top"
                        v-if="range && !hasDoubleRangeValue"
                        style="margin-top: -3px"
                    >
                        <div class="fill"></div>
                        <template v-slot:tooltip>
                            <span>{{ euroSign + start }}</span>
                        </template>
                    </q-tooltip>
                </div>

                <div class="end dot front grab right" :class="{ 'master-card': hasDoubleRangeValue }" ref="current">
                    <q-tooltip :permanent="showCurrentTooltip" text="bottom" position="top" style="margin-top: -3px">
                        <div class="fill"></div>
                        <template v-slot:tooltip>
                            <span v-if="stepLabels && current < stepLabels.length">{{ stepLabels[current] }}</span>
                            <span v-else>{{ euroSign + current }}</span>
                        </template>
                    </q-tooltip>
                </div>

                <div class="front-bar pointer clickable"></div>
            </div>
        </div>

        <div v-if="stepLabels.length > 0" class="indicators" :id="`q-slider-${id}-labels`">
            <!-- labels are set in mounted hook -->
        </div>
        <div v-else class="indicators">
            <span>{{ euroSign + min }}</span>
            <span>{{ euroSign + max }}</span>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
/**
 * Slider can select a value or range of values with constraints and steps
 */
export default {
    name: 'q-slider',
    props: {
        /**
         * Maximum value possible, the end of the slider
         */
        max: {
            type: Number,
            required: true
        },
        /**
         * Minimum value possible, start of the slider
         */
        min: {
            type: Number,
            required: true
        },
        /**
         * Value has to be an array when in range mode,
         * else its a number.
         */
        value: {
            type: [Number, Array]
        },
        /**
         * The amount of decimals the v-model is rounded to.
         */
        decimals: {
            type: Number,
            default: 0
        },
        /**
         * Boolean if is range
         */
        range: {
            type: Boolean
        },
        /**
         * Steps by which the value is rouned up or down to.
         * if steps is 5, the v-model is rounded up to 0, 5, 10, 15, etc
         */
        steps: {
            type: Number
        },
        /**
         * Label shown above the slider
         */
        label: {
            type: String,
            default: null
        },
        /**
         * Step labels shown below the slider
         */
        stepLabels: {
            type: Array,
            default: function() {
                return []
            }
        },
        /**
         * Whether euro sign should be displayed in labels
         */
        euro: {
            type: Boolean,
            default: false
        },
        /**
         * Show custom (number)input for range values
         */
        numberInput: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: uuidv4(),
            current: 0,
            start: 0,
            grabbingStart: false,
            grabbingCurrent: false,
            startOffset: 0,
            absoluteSteps: this.steps,
            showCurrentTooltip: false,
            euroSign: this.euro ? '€' : ''
        };
    },
    methods: {
        returnValue() {
            if (this.range) this.$emit('input', [this.start, this.current]);
            else this.$emit('input', this.current);
        },
        grabStart() {
            this.grabbingStart = true;
            this.setStartOffset();
        },
        grabCurrent() {
            this.grabbingCurrent = true;
            this.setStartOffset();
        },
        lose() {
            this.grabbingCurrent = false;
            this.grabbingStart = false;
        },
        moveCurrent(x) {
            if (!this.grabbingCurrent) return;

            this.moveCurrentTo(x);
        },
        moveCurrentTo(x) {
            let factor = this.getScreenPixelsFactor(x);

            // Apply factor to the properties
            this.current = (this.max - this.min) * factor + this.min;

            // Handle specified decimals
            this.current = parseFloat(this.current.toFixed(this.decimals));

            this.current = this.constrictBySteps(this.current);

            // Constraint value to given bounds
            if (this.current > this.max) this.current = this.max;
            else if (this.current < this.min) this.current = this.min;
            if (this.range && this.current < this.start) this.current = this.start;
        },
        moveStart(x) {
            if (!this.grabbingStart) return;

            this.moveStartTo(x);
        },
        moveStartTo(x) {
            let factor = this.getScreenPixelsFactor(x);
            
            // Apply factor to the properties
            this.start = (this.max - this.min) * factor + this.min;
            
            // Handle specified decimals
            this.start = parseFloat(this.start.toFixed(this.decimals));
            
            this.start = this.constrictBySteps(this.start);

            // Constraint value to given bounds
            if (this.start > this.current) this.start = this.current;
            else if (this.start < this.min) this.start = this.min;
        },
        /**
         * Calculates the factor of total screen pixels difference compared to x
         */
        getScreenPixelsFactor(x) {
            // Get total width of the slider
            let totalWidth = this.$refs.slider.getBoundingClientRect().width;

            // Get the distance from the start of the slider to the mouse
            let diff = x - this.startOffset;

            // Get factor from difference in pixels
            let factor = diff / totalWidth;

            return factor;
        },
        constrictBySteps(val) {
            if (!this.absoluteSteps) return val;

            let span = this.max - this.min;

            let totalSteps = span / (span / this.absoluteSteps);

            let round = Math.round(val / totalSteps * totalSteps);

            return round;
        },
        clickBar(x) {
            this.moveCurrentTo(x - 10);
        },
        calculateStyle() {
            // Get relative totalwidth
            let totalSpan = this.max - this.min;

            // Get relative current width
            let currentSpan = this.current - this.min;
            if (this.range) currentSpan = this.current - this.start;

            // Set factor
            let factor = currentSpan / totalSpan;

            // Get percentage for css style
            let percent = factor * 99;

            // Calcalate offset caused by first node with range
            let startOffsetSpan = this.start - this.min;

            // Set offset factor
            let offsetFactor = startOffsetSpan / totalSpan;

            // Get offset percentage
            let offsetPercent = offsetFactor * 99;

            // Put style together
            let style = 'width: calc(' + percent + '%);';

            if (this.range) style += 'margin-left: calc(' + offsetPercent + '%);';

            return style;
        },
        /**
         * If the value in the v-model does not fit within the bounds or other necessary attributes are missing,
         * this method corrects it immediatly and shows an error
         */
        validateInput() {

            // alle emits uit de validateInput halen

            if (typeof this.max === 'undefined') {
                console.error('no maximum value given');
            }

            if (typeof this.min === 'undefined') {
                console.error('no minimum value given');
            }

            if (this.max < this.min) {
                console.error("given 'max' should be greater than 'min'");
            }

            if (this.range) {
                if(!this.value) {
                    this.start = this.min;
                    this.current = this.max;
                } else {
                    this.start = this.value[0];
                    this.current = this.value[1];
                }

                if (this.start < this.min) this.start = this.min;
                if (this.current < this.min) this.current = this.min;

                if (this.start > this.current) this.start = this.current;
            } else {
                this.current = this.value;
                this.start = this.min;

                if (this.current > this.max || this.current < this.min) {
                    this.current = this.min;
                }
            }

            if (this.range && typeof this.value === 'number') {
                console.error(
                    "slider is range type but v-model contains number. v-model has been corrected to range format: ['min','max']"
                );
                this.current = this.max;
                this.start = this.min;
            }

            if (!this.range && typeof this.value === 'object') {
                console.error(
                    "slider is single value type but v-model contains array. v-model has been corrected to: 'min'"
                );
                this.current = this.min;
            }
        },
        setSliderState() {
            let { width, left } = this.$refs.slider.getBoundingClientRect();
            const difference = (this.max - this.min) / this.absoluteSteps;
            const step = width / difference;
            const deltaStart = ((this.start - this.min) / this.absoluteSteps * step);
            const deltaCurrent = ((this.current - this.min) / this.absoluteSteps * step);

            if(this.range) this.moveStartTo(left + deltaStart - 10);
            this.moveCurrentTo(left + deltaCurrent - 10);
        },
        handleNumberInput(number, key) {
            if(isNaN(number)) return

            this[key] = this.constrictBySteps(number);
            this._handleInputBlur();
        },
        _handleInputBlur: _.debounce(function() {
            this.returnValue();
        }, 200),
        setAbsoluteSteps() {
            if(this.steps) this.absoluteSteps = this.steps;
            else this.absoluteSteps = this.max / 50;
        },
        setStartOffset() {
            /**
             * During tests the object boundingclientrect doesnt have an x attribute so it can be set to 0
             */
            this.startOffset = this.$refs.begin.getBoundingClientRect().x
                ? this.$refs.begin.getBoundingClientRect().x - 10
                : 0;
        }
    },
    computed: {
        hasDoubleRangeValue: function() {
            if(!this.range || this.grabbingStart || this.grabbingCurrent) return false
            return this.start == this.current
        },
        inputCharacterWidth: function() {
            return JSON.stringify(this.max).length;
        }
    },
    mounted() {
        init(this);
        this.$nextTick(() => this.setSliderState());

        this.setStartOffset();

        // set styling for label spacing
        if (this.stepLabels.length > 0) {
            const steps = 100/(this.stepLabels.length-1)
            for(let i=0; i < this.stepLabels.length; i++) {
                const transform = i !== 0 ? i !== this.stepLabels.length-1 ? 'transform:translateX(-50%);' : 'transform:translateX(-100%);' : ''
                document.getElementById(`q-slider-${this.id}-labels`).innerHTML += `<span class="stepLabel" style="position:absolute;left:${steps*i}%;${transform}white-space:nowrap">${this.stepLabels[i]}</span>`
            }
        }
    },
    created() {
        if(!this.value) {
            this.start = this.min;
            this.current = this.max;
        }
        this.validateInput();
        this.setAbsoluteSteps();
    },
    watch: {
        value: {
            handler() {
                this.$nextTick(() => this.setSliderState());
            }
        },
        deep: true,
        steps: function() {
            this.setAbsoluteSteps();
        }
    }
};

/**
 * Initialize javascript eventlisteners for mouse up/down and move
 */
const init = vue => {
    vue.$refs.wrapper.addEventListener('mousemove', e => {
        const x = e.clientX;
        const y = e.clientY;
        const sliderRect = vue.$refs.slider.getBoundingClientRect();
        const pixelOffset = 10;
        
        const mouseIsInX = x > sliderRect.x - pixelOffset && x < sliderRect.x + sliderRect.width + pixelOffset;
        const mouseIsInY = y > sliderRect.y - pixelOffset && y < sliderRect.y + sliderRect.height + pixelOffset;

        if(mouseIsInX && mouseIsInY) {
            vue.moveStart(e.clientX - 10);
            vue.moveCurrent(e.clientX - 10);
        }
        else {
            if(vue.grabbingStart || vue.grabbingCurrent) vue.returnValue();
            vue.lose();
        }
    });

    vue.$refs.start.addEventListener('mousedown', e => {
        e.preventDefault();
        vue.grabStart(e);
    });

    vue.$refs.current.addEventListener('mousedown', e => {
        e.preventDefault();
        vue.grabCurrent(e);
    });

    vue.$refs.slider.addEventListener('mouseup', e => {
        vue.lose();
        vue.returnValue();
    });

    let bars = vue.$refs.slider.getElementsByClassName('clickable');

    for (let i = 0; i < bars.length; i++) {
        bars[i].addEventListener('mousedown', e => {
            vue.clickBar(e.clientX);
        });
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

$gradient: linear-gradient(270deg, rgba(0, 161, 173, 1) 0%, rgba(0, 106, 148, 1) 46%, rgba(102, 36, 130, 1) 100%);

.fill {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(0, 10px);
}

.slide {
    position: absolute;
    height: 20px;
    left: 0;
    top: -4px;
    transition: 200ms cubic-bezier(.15,.75,.2,1);
    
    &.no-transition {
        transition: none !important;
    }
}

.front-bar {
    background: $gradient;
    height: 5px;
    border-radius: 2.5px;
    top: 7px;
    left: 2px;
    right: 2px;
    position: absolute;
}

.bar {
    background-color: $color-grey-3;
    height: 5px;
    border-radius: 2.5px;
    top: 2.5px;
    left: 2px;
    right: 2px;
}
.dot {
    border-radius: 50%;
    transition: left .3s ease, right .3s ease;
}

.back {
    width: 11px;
    height: 11px;
    background-color: $color-grey-3;
}

.front {
    width: 20px;
    height: 20px;
    z-index: 5;

    &.left {
        background-color: $color-purple-dark;
        left: -10px;
    }

    &.right {
        background-color: $color-blue-dark;
        right: -10px;
    }
}

.start,
.end,
.bar {
    position: absolute;
}

.start {
    left: 0;
}

.end {
    right: 0;
}

.q-slider {
    height: 16px;
    margin: 4px 0 -4px 10px;
    position: relative;
}

.slider-wrapper {
    padding-bottom: 4px;

    .header {
        display: flex;
        flex-direction: column;

        .label {
            padding: 0;
        }

        .inputs-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
            gap: 8px;

            > div {
                flex-grow: 1;
            }

            span {
                font-weight: 300;
                color: $color-grey-5;
            }
        }
    }
}

@keyframes rotate {
    from {
        rotate: 0deg;
    } to {
        rotate: 360deg;
    }
}

.grab {
    cursor: grab;
}

.no-dot {
    width: unset;
    height: unset;
}

.pointer {
    cursor: pointer;
}

.label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0 10px 10px;
    color: $color-black;
}

.indicators {
    display: flex;
    justify-content: space-between;
    margin-left: 4px;
    width: 100%;
    position: relative;

    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: $color-grey-5;
    line-height: 16px;
    padding: 13px 0 0 0;
}

.right.master-card {
    right: -16px !important;
}
.left.master-card {
    left: -16px !important;
}
</style>

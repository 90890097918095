<template>
    <slide-transition>
        <div :class="['dropDown']" id="profile-dropdown">
            <slot />
            <q-section hPadding="25" vPadding="20" borderTop class="space-between">
                <div class="logout-button" @click="logout">
                    <div class="logout-icon">
                        <q-icon type="power" width="18" height="18" />
                    </div>
                    <p>Uitloggen</p>
                </div>
            </q-section>
        </div>
    </slide-transition>
</template>

<script>
import QIcon from '../QIcon.vue';
// import { log } from 'util';

export default {
    name: 'q-profile-dropdown',
    components: {
        QIcon
    },

    /**
     * This profile info template is used in the top right corner of the page.
     *
     */

    props: {
        /**
         * Adds an image to the avatar. Without image the avatar displays the initials of the user. Defaults to null.
         *
         */
        image: {
            type: String,
            default: null
        },

        /**
         * Specifies first name of user.
         *
         */
        firstName: {
            type: String
        },

        /**
         * Specifies last name of user.
         */
        lastName: {
            type: String
        },

        /**
         * Specifies role of user. Only displayed in size medium|large|xlarge.
         */
        role: {
            type: String
        },
        /**
         * Specifies the organisations the user can select. Comes in an array.
         * Can be done by hand, most likely will be specified by an API-call.
         */
        organisations: {
            type: Array
        }
    },

    methods: {
        handleClick(event) {
            this.$emit('click', event);
            this.close();
        },
        getAddress(address) {
            return getAddress(address);
        },
        logout() {
            this.$emit('logout');
        },
        close() {
            this.$parent.opened = false;
        }
    },
    mounted() {
        let v = this;
        this.$store.commit('addBodyListener', [
            'profile-dropdown',
            () => {
                v.close();
            }
        ]);
    }
};

const getAddress = address => {
    if (!address) return '';

    if (!address.city) return `${address.line1 ? address.line1 : ''} ${address.line2 ? address.line2 : ''}`;

    if (!address.line1) return `${address.line2 ? address.line2 : ''} in ${address.city ? address.city : ''}`;

    if (!address.line2) return `${address.line1 ? address.line1 : ''} in ${address.city ? address.city : ''}`;

    return `${address.line1 ? address.line1 : ''} ${address.line2 ? address.line2 : ''} in ${
        address.city ? address.city : ''
    }`;
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/style.scss';
@import '../../assets/style/fonts/fonts.css';

#profile-dropdown {
    z-index: 1010;
}

.dropDown {
    position: fixed;
    top: 80px;
    right: 0;
    line-height: 24px;
    width: 350px;
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.15);
    border-radius: 4px;
    background-color: white;
    margin: 10px;

    .profileSection {
        text-align: center;
    }

    .logout-button {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        & .logout-icon {
            padding-top: 3px;
            padding-right: 16px;
            color: inherit;
        }

        &:hover {
            color: $color-red-dark;
            transition: color 0.5s;
        }
    }
}
</style>

<docs>
  ```jsx
  <div>
    <q-profile-dropdown firstName="Tayla" lastName="van Ingen" role= "Opperbaas" :organisations=" [{name:`Heijmans Waterbouw`, adress:`Scheemdastraat 53, Nieuw Beertha`}, {name:`Heijmans Waterbouw`, adress:`Beerthaweg 12, Scheemda`} ]"></q-profile-dropdown>

    
    
  </div>
  ```
</docs>

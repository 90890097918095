<template>
    <div class="wrapper">
        <div v-if="organization.qlubb" class="qlubb-info" @click="handleClick">
            <div class="flex">
                <div class="left">
                    <img class="qlubb-logo" src="/statics/img/favicon.png" alt="" />
                    <div class="column">
                        <p class="organisation-name">{{ organization.name }}</p>
                        <p class="address">Project Communicatie</p>
                    </div>
                </div>
            </div>
            <q-button
                class="hover-show"
                variation="primary"
                size="xsmall"
                :loading="loadingOrganisation"
                @click="handleClick"
                >Dashboard</q-button
            >
        </div>
        <div v-else class="organization-info" :class="{ pointer: !disabled }" @click="handleClick">
            <div class="flex">
                <q-avatar size="small" :img="image" class="avatar">{{ initials }}</q-avatar>

                <div class="text">
                    <p class="organization" id="fade" :class="[fadeTitle]" :style="orgTitleWidth">
                        {{ organization.name }}
                    </p>
                    <p v-if="!label" class="adress" id="fade" :class="[fadeTitle]" :style="orgTitleWidth">
                        {{ address }}
                    </p>
                    <q-tooltip v-else :disabled="!label.tooltipText">
                        <template #tooltip>
                            <p v-html="label.tooltipText"></p>
                        </template>
                        <p class="adress" :class="{ offset5: label }">
                            <q-tag :variation="label.type" size="small">{{ label.message }}</q-tag>
                        </p>
                    </q-tooltip>
                </div>
            </div>
            <q-button
                v-if="!active && buttonText"
                variation="primary"
                size="xsmall"
                :loading="loadingOrganisation"
                @click="handleClick"
                >{{ buttonText }}</q-button
            >
            
            <q-tag v-if="active" size="small" variation="success">Ingelogd</q-tag>

            <div v-else-if="!buttonText && !active && icon" class="action">
                <q-tag v-if="role" size="small" variation="default">{{ role }}</q-tag>
                <q-icon class="row-icon" :type="icon" color="#ADB5BD" />
            </div>
        </div>
    </div>
</template>

<script>
import QAvatar from '../QAvatar.vue';
import QTag from '../QTag';
import { companyInitials } from '@/assets/js/utils';

export default {
    name: 'q-organization-info',
    components: {
        QAvatar,
        QTag,
    },

    /**
     * This profile info template is used in the top right corner of the page.
     *
     */

    props: {
        /**
         * The size of the profile block. Defaults to medium.
         * `small, medium, large, xlarge`
         */

        active: {
            type: Boolean,
            default: false,
        },
        /**
         * Adds an image to the avatar. Without image the avatar displays the initials of the user. Defaults to null.
         *
         */
        image: {
            type: String,
            default: null,
        },

        /**
         * Specifies organisation object
         *
         */

        organization: {
            type: Object,
            default: null,
        },

        /**
         * Specifies address of organization. Only displayed in size medium|large|xlarge.
         */
        address: {
            type: String,
        },

        /**
         * Label to show after organisation name
         */
        label: {
            type: Object,
        },
        /**
         * Label to show after organisation name
         */
        buttonText: {
            type: String,
            default: null,
        },
        /**
         * Label to show after organisation name
         */
        icon: {
            type: String,
            default: null,
        },
        /**
         * Label to show after organisation name
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        loadingOrganisation: {
            type: Boolean,
            default: false,
        },
        role: {
            type: String,
            default: null,
        }
    },
    methods: {
        handleClick(event) {
            if (!this.disabled) {
                this.$emit('click', event);
            }
        },
        getAddress(address) {
            return address;
        },
    },
    computed: {
        initials() {
            return companyInitials(this.organization.name, 1);
        },
        orgTitleWidth() {
            return this.buttonText || this.active ? 'width: 170px' : 'width: 250px';
        },
        fadeTitle() {
            return this.buttonText || this.active ? 'header-left-with-button' : 'header-left';
        },
        // iconType() {
        //     return this.locked ? 'lockClosed' : 'arrowDown';
        // }
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/style.scss';
@import '../../assets/style/fonts/fonts.css';

.qlubb-info {
    display: flex;
    line-height: 24px;
    color: $color-grey-9;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: relative;

    .flex {
        padding: 3px 0;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            gap: 10px;

            .column {
                .organisation-name {
                    font-weight: $weight-semi-bold;
                    line-height: 19px;
                }
                .sub-title {
                    line-height: 19px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

        .address {
            font-size: 12px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            width: 250px;
        }

        .qlubb-logo {
            height: 40px;
        }

        .button {
            position: absolute;
            right: 0;
            top: 50%;
            translate: 0 calc(-50% - 1.5px);
            font-size: 10px;
            font-weight: 500;
            font-family: 'Gotham', sans-serif;
            background-color: rgb(240, 110, 169);
            color: white;
            padding: 5px 10px;
            border-radius: 4px;
            box-shadow: 0px 1px 0 0px rgb(196, 62, 122), 0px 2px 0 -0.5px rgb(196, 62, 122),
                0px 3px 0 -1px rgb(196, 62, 122), 0px 4px 0 -1.5px rgb(196, 62, 122), 0px 4px 0 -2px rgb(196, 62, 122),
                0px 5px 0 -2.5px rgb(196, 62, 122);
            cursor: pointer;

            &:hover {
                box-shadow: 0px 1px 0 0px rgb(196, 62, 122);
                translate: 0 -50%;
            }
        }
    }
    &:hover .hover-show {
        visibility: visible;
    }
    .hover-show {
        position: absolute;
        right: 0px;
        visibility: hidden;
    }
}

.organization-info {
    display: flex;
    line-height: 24px;
    color: $color-grey-9;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.text {
        text-align: left;
        padding-left: 16px;
        padding-top: 3px;
    }

    .organization {
        font-weight: $weight-semi-bold;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        // word-break: break-word;
    }

    .adress {
        font-size: 12px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        width: 250px;

        & .tag {
            height: 18px;
            padding: 0px 10px;
        }
    }

    .avatar,
    .text {
        // cursor: pointer;
        margin-top: 3px;
    }

    .text > p {
        line-height: 19px;
    }

    .avatar {
        margin: 6px 12px 8px 0;
    }

    .transform {
        transform: rotate(-90deg);
        transform: translate(10px, -3px) rotate(-90deg);
    }
}

.pointer {
    cursor: pointer;
}

.offset5 {
    margin-left: -5px;
}

@mixin fade-in-ellipsis($box-width, $right-width, $bg-color) {
    position: relative;
    max-width: calc(100% - #{$right-width});
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(#{$box-width} - #{$right-width} - 30px);
        height: 100%;
        width: 30px;
        background-image: -webkit-linear-gradient(left, rgba($bg-color, 0), $bg-color);
        background-image: linear-gradient(left, rgba($bg-color, 0), $bg-color);
    }
}

$box-color: white;
$box-width: 250px;
$box-width-button: 170px;
$header-right-width: 0px;

[class^='header-'] {
    display: inline-block;
    vertical-align: top;
    height: 25px;
    min-height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
}

.header-left {
    @include fade-in-ellipsis($box-width, $header-right-width, $box-color);
}
.header-left-with-button {
    @include fade-in-ellipsis($box-width-button, $header-right-width, $box-color);
}

.header-right {
    width: $header-right-width;
    padding-right: 5px;

    &:before {
        content: '-\0020';
    }
}

.action {
    display: flex;

    .row-icon {
        margin-left: 20px;
    }
}

</style>

<docs>
  ```jsx
  <div>
    <q-organization-info organization="Heijmans Waterbouw" address="Scheemdastraat 53, Nieuw Beertha"></q-organization-info>
    
    
    
  </div>
  ```
</docs>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['tooltip'],attrs:{"id":`tooltip-${_vm.elementId}`,"tabindex":_vm.disabled ? -1 : 0},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"mousedown":function($event){_vm.mouseDown = true},"mouseup":function($event){_vm.mouseDown = false},"focus":_vm.handleFocus,"blur":_vm.handleBlur}},[_vm._t("default"),_vm._v(" "),(_vm.portalEnabled)?_c('Portal',{attrs:{"to":"tooltip"}},[_c('div',{staticClass:"tooltip-wrapper",class:[_vm.showTooltip ? 'show' : 'hide', _vm.position, { 'cancel-transition': _vm.coordinates.correctionX !== 0 }],style:(`
                --position-left: ${_vm.coordinates.left}px; 
                --position-right: ${_vm.coordinates.right}px;
                --position-top: ${_vm.coordinates.top}px;
                --position-bottom: ${_vm.coordinates.bottom}px;
                --element-width: ${_vm.coordinates.width}px;
                --element-height: ${_vm.coordinates.height}px;
                --max-width: ${_vm.coordinates.viewportWidth}px;
                --max-height: ${_vm.coordinates.viewportHeight}px;
                --correction-x: ${_vm.coordinates.correctionX}px;
            `)},[_c('span',{class:['tooltiptext', _vm.position, 'tooltip-' + _vm.position, _vm.showTooltip ? 'show' : 'hide'],attrs:{"id":`tooltiptext-${_vm.elementId}`}},[_vm._t("tooltip")],2)])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="question-text">
      <q-text v-model="answer" @blur="returnAnswer" :disabled="disabled"></q-text>
  </div>
</template>

<script>

import QText from '../QText';

export default {
    name: 'TextQuestion',
    /**
     * This component needs to be imported and registered here because it is not globally defined in tests.
     */
    components: {
        'q-text': QText
    },
    props: {
        value: [String],
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {
            answer: ''
        }
    },
    methods: {
        returnAnswer(val) {
            this.$emit('input', val);
        }
    },
    created() {
        this.answer = this.value;
    },
    watch: {
        value: function() {
            this.answer = this.value;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
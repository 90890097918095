<template>
    <auth-base>
        <div>
            <registration-navbar :step="2" :steps="steps"></registration-navbar>
            <div class="row middle-xs center-xs">
                <div class="q-card-medium">
                    <q-section vPadding="40" hPadding="40">
                        <h2>E-mailadres valideren</h2>
                        <p class="py-s">We hebben je zojuist een e-mail gestuurd waarmee je kunt bevestigen dat jij de eigenaar van het ingevoerde e-mailadres bent. Klik op 'e-mailadres valideren' in de mail om je e-mailadres te valideren. Let op! De link vervalt na 24 uur.</p>

                        <p>Het kan zijn dat de mail in je spambox is beland, vergeet deze niet te checken als je de mail na 5 minuten nog niet hebt ontvangen in je inbox.</p>
                        <div class="image">
                            <img src="statics/img/waiting_email.png" alt="waiting-for-email">
                        </div>
                        <p>Je kunt geen organisaties aan je account koppelen of gekoppeld worden aan organisaties zolang je je e-mailadres niet gevalideerd hebt.</p>
                    </q-section>
                    <q-section vPadding="24" hPadding="40" borderTop class="space-between">
                        <div></div>
                        <q-button @click="next" variation="primary" size="medium" :disabled="status !== 'active'" >E-mailadres gevalideerd</q-button>
                    </q-section>
                </div>
            </div>
            <div class="row middle-xs center-xs"><p v-if="status !== 'active'" class="resend">Mail niet ontvangen? <span class="send" @click="resendEmail">Stuur opnieuw</span></p></div>
            
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import RegisterNavbar from './RegistrationNavbar.vue'
import gql from 'graphql-tag';

export default {
    name: 'EmailValidation',
    data() {
        return {
            status: 'not_set',
            interval: undefined
        };
    },
    methods: {
        next() {
            this.$router.push('/access');
        },
        resendEmail() {
            const userId = this.$store.getters.getUser.id;

            this.$apollo.mutate({
                mutation: gql`
                    mutation ResendEmailValidation($id: String!) {
                        user_resendEmailValidation(id: $id)
                    }
                `,
                variables: {
                    id: userId
                }
            })
            .then(() => {
                this.$store.commit('notify', {
                    type: 'success',
                    message: 'E-mail is opnieuw verstuurd'  
                })
            })
            .catch(() => {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'E-mail kan maar 1 keer per minuut opnieuw verstuurd worden.'
                })
            })
        },
        checkValidation() {
            const this_ = this;
            const userId = this.$store.getters.getUser.id;

            this_.$apollo.query({
                query: gql`
                    query($id: String!) {
                        user(id: $id) {
                            id
                            status
                        }
                    }
                `,
                variables: {
                    id: userId
                },
                fetchPolicy: 'no-cache'
            })
            .then((result) => {
                const { status } = result.data.user;

                this_.status = status;

                if (status === 'active') {
                    clearInterval(this.interval);
                    this.$store.commit('setUserStatus', status);
                }
            })
            .catch(() => {
                this.$store.commit('resetState')
                this.$router.push('/login');
            })
        }
    },
    components: {
        'auth-base': AuthBase,
        'registration-navbar': RegisterNavbar,
    },
    computed: {
        steps: function() {
            const steps = [
                { id: 1, name: 'Account aanmaken' },
                { id: 2, name: 'E-mailadres valideren' }
            ]

            if (!this.$route.query.invited)
                steps.push({ id: 3, name: 'Organisatie koppelen' });

            return steps;
        }
    },
    created() {
        const this_ = this;
        
        this_.checkValidation();

        this.interval = setInterval(() => {

            this_.checkValidation();

        }, 3000);
    }
};
</script>

<style lang="scss" scoped>
p {
    font-size: 14px;
}

.image {
    display: flex;
    justify-content: center;

    img {
        width: 157px;
    }
}

.resend {
    padding: 10px;
    color: grey;
    background-color:white;
    user-select: none;
    text-align: center;
    border: 1px solid rgb(224, 227, 229);
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.15);
    box-sizing: border-box;
    margin-top:10px;
    width: fit-content;

    .send {
        text-decoration: underline;
        cursor: pointer;

        &:active {
            color: rgb(68, 68, 68);
        }
    }
}

</style>
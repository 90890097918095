<template>
    <q-step :number="index" :id="index" :name="step.name" :about="step.about" :value="step.value" class="step" width="90%">
        <div class="colors-container">
            <q-color-card
                v-for="color, index in computedColors"
                :key="index"
                :name="color.name"
                :color="color.colorCode"
                :options="getColorCardOptions(color)"
                interactive
                :selected="selectedColorIndex === index"
                @click.native="handleSelectColor(color, index)"
                @optionSelected="handleOptionSelected(color, $event)"
            ></q-color-card>
            <div v-if="(createNewColor || !createdColor) && !isEditing" class="add-color-card" @click="handleNewColor">
                <q-icon width="100px" height="100px" class="plus-icon" type="plus-icon" color="#b6bdc1"></q-icon>
            </div>
        </div>

        <q-popup v-if="createNewColor" width="25%">
            <div class="popup-color">
                <div class="popup-header">
                    <h2 style="margin-bottom: 15px;">Stijlkleur {{ isEditing ? 'aanpassen' : 'aanmaken' }}</h2>
                </div>
                <div class="popup-body">
                    <p>Selecteer hier een kleur om een nieuwe stijlkleur toe te voegen aan ‘Qfact stijl document’. Klik met je muis op de kleur hier onder om deze aan te passen.</p>
                    <div class="color-card-preview">
                        <div class="color" :style="`--color: ${createdColor.colorCode}`">
                            <input style="margin:15px 0 15px 0;" class="color-input" id="color-input" type="color" :value="parseToHex(createdColor.colorCode)" @input="handleColorChange">
                        </div>
                        <div class="card-content">
                            <span class="name">{{ createdColor.name || 'Kleur naam' }}</span>
                            <span class="color-code">{{ createdColor.colorCode }}</span>
                        </div>
                    </div>
                    <q-input style="margin-bottom:15px;" labelPosition="inside" v-model="createdColor.name" placeholder="Naam stijlkleur" size="small">Naam stijlkleur</q-input>
                </div>
                <div 
                    
                    class="popup-buttons">
                    <q-button variation="blank" @click="handleCancelColor">Annuleren</q-button>
                    <q-button @click="handleCreateColor">Stijlkleur {{ isEditing ? 'aanpassen' : 'aanmaken' }}</q-button>
                </div>
            </div>
        </q-popup>
    </q-step>
</template>

<script>
export default {
    name: 'style-step-color',
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        selectedStyle: {
            type: Object,
            required: true
        },
        availableColors: {
            type: Array,
            default() { return [] }
        }
    },
    data() {
        return {
            selectedColorIndex: null,
            createNewColor: false,
            createdColor: null,
            colorDeleted: false,
            isEditing: false,
            cachedColor: null
        }
    },
    methods: {
        handleSelectColor(color, index) {
            if(this.colorDeleted) return this.colorDeleted = false
            this.selectedColorIndex = index;
            this.selectedStyle.color = color;
        },
        handleNewColor() {
            this.createNewColor = true;

            this.createdColor = {
                name: '',
                colorCode: 'rgb(0,0,0)'
            }
        },
        handleCancelColor() {
            if(this.isEditing) this.createdColor = { ...this.cachedColor };
            else this.createdColor = null;
            this.createNewColor = false;
            this.isEditing = false;
        },
        handleCreateColor() {
            if(!this.createdColor.name || !this.createdColor.colorCode) return this.$store.commit('notify', { type: 'info', message: 'Je moet een naam en een kleur instellen.' })

            this.selectedStyle.color = this.createdColor;
            this.selectedColorIndex = this.availableColors.length;

            this.createNewColor = false;
            this.isEditing = false;
        },
        toggleColorInput() {
            const colorInput = document.getElementById('color-input');
            colorInput.click();
        },
        handleColorChange(event) {
            const color = event.target.value.toString();
            const newColor = this.hexToRgb(color);
            this.createdColor.colorCode = newColor;
        },
        parseToHex(rgb) {
            let numbers = rgb.split('(').pop().split(')')[0];
            numbers = numbers.split(',');
            return this.rgbToHex(numbers[0],numbers[1],numbers[2])
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : null;
        },
        rgbToHex(r, g, b) {
            return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
        },
        handleOptionSelected(color, option) {
            if(option.name === 'Stijlkleur verwijderen') {
                // mutation
                this.colorDeleted = true;
                this.createdColor = null;
                this.selectedStyle.colorCode = null;
            }
            else if(option.name === 'Stijlkleur aanpassen') {
                this.cachedColor = { ...this.createdColor };
                this.createNewColor = true;
                this.isEditing = true;
            }
        },
        getColorCardOptions(color) {
            if(!this.createdColor) return []
            if(color.styleId !== this.createdColor.id) return []
            return [
                {
                    name: 'Stijlkleur aanpassen'
                },
                {
                    name: 'Stijlkleur verwijderen',
                    color: 'red'
                }
            ]
        },
        updateSelection() {
            if(this.selectedStyle.color) {
                const hasEmpty = Boolean(this.availableColors.find(option => option.name === 'empty'));
                if(this.selectedStyle.color.colorCode !== 'empty') {
                    this.createdColor = this.selectedStyle.color;
                    const selectedIndex = hasEmpty ? this.availableColors.length - 1 : this.availableColors.length;
                    this.selectedColorIndex = selectedIndex;
                }
                else this.selectedColorIndex = this.availableColors.length;
            }
        }
    },
    computed: {
        computedColors: function() {
            const emptyOption = {
                name: 'empty',
                colorCode: 'empty'
            }
            let options = [];

            let availableColors = this.availableColors;
            if(this.$route.params.id === 'create') {
                const colors = []
                availableColors = availableColors.filter(color => {
                    if(colors.includes(color.colorCode)) return false
                    colors.push(color.colorCode)
                    return true
                });
            }

            if(this.createdColor && !this.createNewColor || this.isEditing) options = [ ...availableColors, this.createdColor ]
            else options = availableColors

            if(!options.find(option => option.name === 'empty')) options.push(emptyOption);

            return options
        }
    },
    created() {
        this.updateSelection();
    },
    watch: {
        availableColors: function() {
            this.updateSelection();
        }
    }
}
</script>

<style lang="scss" scoped>

.step {
    justify-content: center;

    .colors-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
}

.popup-color {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 32px;

        .color-card-preview {
            position: relative;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 3px 2px rgba(128, 128, 128, 0.15);
            border-radius: 6px;
            transition: scale .2s cubic-bezier(.45,1.8,.58,1.21), outline .15s ease;
            width: 180px;
            height: 120px;
            margin-inline: auto;

            .color {
                position: relative;
                height: 100%;
                width: 100%;
                background-color: var(--color, #ffffff);
                border-radius: 6px 6px 0 0;

                .color-input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .card-content {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                border-radius: 0 0 6px 6px;
                padding: 8px 10px;

                .name {
                    color: #484f56;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                }
                .color-code {
                    color: #C4C4C4;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }

    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

.add-color-card {
    display: grid;
    place-items: center;
    height: 100px;
    width: 150px;
    outline: 1px dashed #b6bdc1;
    border-radius: 6px;
    cursor: pointer;

    transition: scale .2s cubic-bezier(.45,1.8,.58,1.21);

    &:active {
        scale: 0.97 !important;
    }

    &:hover {
        scale: 1.02;
    }
}



</style>
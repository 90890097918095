<template>
  <div class="custom-content">
      <div :class="['wrapper', component.styling ? component.styling.alignment : '']" :style="getWrapperStyle()" data-testId="content-wrapper">
          <div v-for="(item, key) in component.items" :key="key" :class="['item', item.type]">

              <div v-if="item.type == 'text'" class="content-text">
                  <span v-html="getItemText(item)"></span>
              </div>

              <div v-else-if="item.type == 'image'">
                  <img :src="item.source" :alt="item.type"
                  :style="getImageStyle(item)"
                  data-testid="content-image">
              </div>

              <div v-else-if="item.type == 'video'">
                  <video :src="item.source"
                  :style="getImageStyle(item)"></video>
              </div>
              <div v-else-if="item.type == 'badge'">
                <q-tag :emoji="getAverageSmiley(item)"></q-tag>
            </div>

          </div>
      </div>
  </div>
</template>

<script>


/**
 * content can have 3 types: text, image, video and 'badge'
 */
export default {
    name: 'Content',
    /**
     * Component object with structure
     * - styling as json properties:
     *      - alignment 'row' or 'column'
     *      - justifycontent: 'space-between', 'space-evenly', 'center'
     * - items an array of all content items
     *      - type 'text', 'image', 'video' or 'badge'
     *      - source for types 'video' and 'image'
     *      - width for types 'video' and 'image'
     *      - height for types 'video' and 'image'
     *      - averageIds for type 'text' to fill {{average}} and for type 'badge' to set type (happy, neutral, sad)
     */
    props: {
        component: {
            type: Object,
            required: true
        },
        /**
         * Contentblock needs the form to calculate averages
         */
        form: {
            type: Object,
            required: true
        },
    },
    methods: {
        getWrapperStyle() {
            if (this.component.styling && this.component.styling.justifyContent) 
                return `justify-content: ${this.component.styling.justifyContent}`;
            else return `justify-content: center`
        },
        getImageStyle(item) {
            return 'height:' + (item.height ? item.height : 'auto') + ';width:' + (item.width ? item.width : '780px');
        },
        /**
         * Changes the {{average}} into the propper variables
         */
        getItemText(item) {

            if (item.text.indexOf('{{average}}') > -1){

                const average = this.getAverageFromIds(item.averageIds);

                return item.text.replace('{{average}}', average);
            }

            return item.text;
        },
        getAverageSmiley(item) {
            const average = this.getAverageFromIds(item.averageIds)

            return average === 0 ? 'unknown' : average < 5.5 ? 'sad' : average < 7 ? 'neutral' : 'happy'
        },
        getAverageFromIds(ids) {
            let grade = 0;
            let amount = 0;

            this.form.components
                .filter(component => component.type === 'question')
                .filter(component => ids.includes(component.id))
                .forEach(component => {
                    if (!component.indicator.answer || isNaN(component.indicator.answer) || component.indicator.answer === 0) return;

                    amount++;
                    grade += component.indicator.answer;
                })

            if (amount === 0) return 0;

            const average = grade / amount;

            return average;
        }
    }
}
</script>

<style lang="scss" scoped>

// This element encapsulates all content of type text
// content of type text can have HTML elemnts in them which can be further styles within this class
.content-text {

    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
}

.wrapper {
    display: flex;
    padding: 20px 10px 20px 10px;

    &.row {
        flex-direction: row;
        justify-content: space-between;
    }

    &.column {
        flex-direction: column;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0 5px;

        &.text {

        }
    }
}

</style>
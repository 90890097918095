<template>
    <transition-group 
        name="test" 
        appear 
        :class="{'no-stagger': disableStagger }"
        @after-enter="$emit('after-enter', $event)"
    >
        <slot></slot>
    </transition-group>
</template>

<script>
export default {
    name: 'slide-transition-group',
    props: {
        disableStagger: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">

.test-enter-active {
    position: relative;
    --delay: calc(var(--index, 0) * 20ms);
    opacity: 0;
    scale: 0.95;
    margin-top: -5%;
    animation: slideIn 125ms ease forwards;
    animation-delay: var(--delay);
}
.test-leave-active {
    position: relative;
    --delay: calc((var(--length, 0) - var(--index, 0)) * 15ms);
    opacity: 1;
    scale: 1;
    margin-top: 0;
    animation: slideOut 75ms ease forwards;
    animation-delay: var(--delay);
}

.no-stagger {
    .test-enter-active,
    .test-leave-active {
        --delay: 0ms !important;
        animation-delay: none;
    }
}

@keyframes slideIn {
    to {
        opacity: 1;
        scale: 1;
        margin-top: 0;
    }
}
@keyframes slideOut {
    to {
        opacity: 0;
        scale: 0.95;
        margin-top: -5%;
        z-index: -1;
    }
}
    
</style>
<template>
    <Transition name="jump" :appear="appear" @after-enter="$emit('after-enter', $event)">
        <slot></slot>
    </Transition>
</template>

<script>
export default {
    name: 'jump-transition',
    props: {
        appear: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>

.jump-enter-active {
    animation: jumpIn .25s cubic-bezier(.51,1.6,.45,1.08) forwards;
}
.jump-leave-active {
    animation: jumpOut .15s ease-in forwards;
}

@keyframes jumpIn {
    from {
        opacity: 0;
        scale: 0.5;
    } to {
        opacity: 1;
        scale: 1;
    }
}
@keyframes jumpOut {
    0% {
        scale: 1;
        opacity: 1;
    } 100% {
        opacity: 0;
        scale: 0.2;
    }
}
    
</style>
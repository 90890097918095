<template>
    <auth-base>
        <div class="q-card-small">
            <q-section vPadding="40" hPadding="40">
                <h2>Wachtwoord instellen</h2>
                <p class="py-m">Er is een e-mail naar je onderweg met de uitleg om je nieuwe wachtwoord in te stellen. Je kunt dit scherm nu afsluiten.</p>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { REQUEST_SET_PASSWORD } from '@/graphql/mutations';
import { extractError } from '../../assets/js/utils'

export default {
    name: 'ResetPasswordConfirm',
    components: {
        AuthBase
    },
    methods: {
        requestResetPassword() {
            const { email } = this.$route.params;
                         
            this.$apollo
                .mutate({
                    mutation: REQUEST_SET_PASSWORD,
                    variables: { email }
                })
                .then(response => {
                    console.log(response);
                })
                .catch(err => {

                });
        }
    },
    created() {
        this.requestResetPassword();
    }
};
</script>

<style lang="scss" scoped></style>

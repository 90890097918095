<template>
    <svg :width="width" :height="height" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="7.84229"
            y="17.4219"
            width="3.87047"
            height="9.67618"
            rx="1"
            transform="rotate(45 7.84229 17.4219)"
            :stroke="color"
        />
        <rect
            x="11.9473"
            y="14.6836"
            width="1.93524"
            height="4.83809"
            transform="rotate(45 11.9473 14.6836)"
            :stroke="color"
        />
        <ellipse
            cx="17.4209"
            cy="10.5789"
            rx="6.77333"
            ry="6.77333"
            transform="rotate(45 17.4209 10.5789)"
            :stroke="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'Search',
    props: {
        color: {
            type: String,
            default: '#ADB5BD',
        },
        width: {
            type: [Number, String],
            default: 28,
        },
        height: {
            type: [Number, String],
            default: 28,
        },
    },
};
</script>

<style></style>

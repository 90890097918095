const getIndicatorCondition = (item) => {
    let filterType ='EXISTS';
    let value = item.filters.value;
    let values = item.filters.values || [value];
    let rangeFrom = 0;
    let rangeTo = 0;
    let checked = item.checked;
    let filters = item.filters;
    let templateId = item.templateId;
    let valueIsSet = (value !== null && value !== undefined) || (values[0] !== null && values[0] !== undefined); 
    let gtValue = null;
    let ltValue = null;

    const includesType = ['INCLUDES-AND','INCLUDES-OR'].includes(item.filters.type) ? item.filters.type : 'INCLUDES-OR';
    
    if(item.checked && valueIsSet)
      switch(item.answerType) {
        case 'dropdown':
        case 'radio':
        case 'dropdown-multiple':
        case 'checkbox':
          filterType = includesType;
          break;
        case 'number':
          if(!value)
            break;

          filterType='RANGE';
          value=parseFloat(value);
          if (typeof values === Array) values=values.map(value => parseFloat(value));

          switch(item.filters.type) {
            case 'lt':
              rangeFrom=-9999999999; //Number.POSITIVE_INFINITY
              rangeTo=value - 1;
              break;
            case 'gt':
              rangeFrom=value + 1;
              rangeTo=9999999999; //Number.NEGATIVE_INFINITY
              break;
            case 'eq':
              filterType='EQUALS';
              break;
            default:
              throw new Error('could not build filter type '+item.filters.type);
          }
          break;
        case 'score':
          if(!value)
            break;

          filterType='RANGE';
          value=parseFloat(value);

          gtValue = value + 0.05;
          gtValue.toFixed(1);

          ltValue = value - 0.05;
          ltValue.toFixed(1); 

          if (values && values === typeof Array && values.length > 0) {
            values=values.map(value => parseFloat(value));
          }

          switch(item.filters.type) {
            case 'lt':
              rangeFrom=-9999999999; //Number.POSITIVE_INFINITY
              rangeTo=ltValue;
              break;
            case 'gt':
              rangeFrom=gtValue;
              rangeTo=9999999999; //Number.NEGATIVE_INFINITY
              break;
            case 'eq':
              filterType='EQUALS';
              break;
            default:
              throw new Error('could not build filter type '+item.filters.type);
          }
          break;
        case 'organisation':
          filterType='INCLUDES-OR';
          break;
        case 'date':
          if (!(values instanceof Array) || (isNaN(values[0]) && isNaN(values[1])))
            break;
          
          filterType='RANGE';
          rangeFrom = -9999999999999; //Number.POSITIVE_INFINITY
          rangeTo = 9999999999999; //Number.NEGATIVE_INFINITY

          if (values[0] && !isNaN(values[0]))
            rangeFrom = values[0]
          
          if (values[1] && !isNaN(values[1]))
            rangeTo = values[1]
          break;
        case 'date-range-to':
          if (!(values instanceof Array) || (isNaN(values[0])))
          break;
          
          filterType='RANGE';
          rangeFrom = -9999999999999; //Number.POSITIVE_INFINITY
          rangeTo = 9999999999999; //Number.NEGATIVE_INFINITY

          if (values[0] && !isNaN(values[0]))
            rangeTo = values[0]

          break;
        case 'date-range-from':
          if (!(values instanceof Array) || (isNaN(values[0])))
          break; 
          
          filterType='RANGE';
          rangeFrom = -9999999999999; //Number.POSITIVE_INFINITY
          rangeTo = 9999999999999; //Number.NEGATIVE_INFINITY

          if (values[0] && !isNaN(values[0]))
            rangeFrom = values[0]

          break;
        case 'text':
          if (!value || value === undefined || value === null || value === '')
            break;
          filterType='FUZZY_STRING';
          break;
        case 'boolean':
          filterType='EQUALS';
          break;
        default:
          throw new Error('could not build answerType '+item.answerType);
      }

    const condition={
      ...item,
      indicatorId: item.id,
      masterIndicatorId: item.masterIndicatorId,
      type: item.type,
      filterType,
      value,
      values,
      rangeFrom,
      rangeTo,
      filters,
      checked, 
      templateId
    };

    return condition;
  }

module.exports = {
    getIndicatorCondition
}
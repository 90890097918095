<template>
    <q-popup v-if="deleteProjectModal">
        <div class="modal">
            <h2 class="delete-color pb-s">Project verwijderen</h2>
            <p>
                Weet u zeker dat u <strong>{{ project.name }}</strong> wilt verwijderen?
            </p>
            <p class="mt-s">Type <span class="confirm-delete">verwijderen</span> om te bevestigen.</p>
            <q-input labelPosition="inside"
                size="small"
                variation="blank"
                placeholder="Type 'verwijderen' om te bevestigen"
                v-model="confirmDelete"
            ></q-input>
            <div class="footer flex">
                <q-button
                    v-if="isInitiator"
                    variation="blank"
                    @click="closePopup()"
                    style="margin-right: 24px"
                    >Annuleren</q-button
                >
                <q-button
                    v-if="isInitiator"
                    @click="handleDeleteProject"
                    :disabled="confirmDelete !== 'verwijderen' || project.status === 'finished'"
                    size="medium"
                    variation="danger"
                    style="cursor: pointer"
                    >Project verwijderen</q-button
                >
            </div>
        </div>
    </q-popup>
</template>

<script>
export default {
    name: 'project-delete-modal', 
    props: {
        deleteProjectModal: {
            type: Boolean, 
            required: false
        }, 
        project: {
            type: Object, 
            required: true
        }, 
    }, 
    data() {
        return {
            confirmDelete: '',
        }
    }, 
    methods: {
        handleDeleteProject() {
            this.deleteProject();
            this.closePopup();
        },
        closePopup() {
            return this.$emit('close');
        }, 
        deleteProject() {
            return this.$emit('project');
        }
    }, 
    computed: {
        isInitiator: function() {
            return this.$store?.getters.getCurrentOrganisation.id === this.project.organisationId;
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    .modal-content {
        // height: 260px
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
        justify-content: space-between;
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}
</style>

<template>
    <slide-transition-group
        ref="list" 
        class="list-wrapper" 
        :class="{ show: show }"
        :disableStagger="filter.search !== ''"
    >
        <div 
            v-for="item, index in computedValues" 
            :key="item.id" 
            class="indicator-wrapper" 
            :style="`--index: ${index}; --length: ${computedValues.length};`"
        >
            <checkbox-bar 
                :reportPageFilterBar="reportPageFilterBar" 
                :disabled="disabled"
                :item="item" 
                :markedName="item.markedName"
                :checkable="checkable" 
                :answerValue="answerValue"
                @checkboxInput="onCheckboxInput($event, item)" 
                @input="onInput" 
            ></checkbox-bar>
        </div>
    </slide-transition-group>
</template>

<script>

import CheckboxBar from "./CheckboxBar.vue";

export default {
    name: 'checkbox-list',
    components: {
        CheckboxBar
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        checkable: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        }, 
        answerValue: {
            type: Boolean, 
            default: false
        }, 
        reportPageFilterBar: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean, 
            default: false
        },
        filter: {
            type: Object,
            default: {}
        }, 
    },
    methods: {
        onCheckboxInput(value, item) {
            if (item.id === 'intermediateMeasurementSkippedFilter') {
                item.filters.value = value;
                this.onInput(item)
            } else {
                this.onInput(item)
            }   
        },
        onInput(item) {
            const itemIndex = this.value.findIndex(item_ => item_.id === item.id);

            this.value[itemIndex] = item;

            this.$emit('input', this.value);
        }
    },
    computed: {
        computedValues: function() {
            if(!this.show) return []
            return this.value
        }
    }
}

</script>

<style lang="scss" scoped>

.list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: padding-bottom .2s ease;
    
    &.show {
        padding-bottom: 10px;
    }
}

</style>
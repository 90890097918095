<template>
    <svg :width="width" :height="height" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.5" y1="3.5" x2="7.5" y2="3.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="2.5" y1="6.5" x2="6.5" y2="6.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="0.5" y1="0.5" x2="8.5" y2="0.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'Funnel',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 9,
        },
        height: {
            type: [Number, String],
            default: 7,
        },
    },
};
</script>

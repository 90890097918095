<template>
  <div class="q-select-organisation detail-page-padding">
    <div class="head">
        <div class="left">
            <arrow-button color="black" direction="left" @click="back"></arrow-button>
            <h2>{{ project.name }}</h2>
            <q-tag class="status" size="small" variation="warning">{{ $t(`projectStatus.${project.status}`) }}</q-tag>
        </div>
      </div>
      <div class="search">
          <div class="panel">
            <div class="title">
                <div>
                    <span class="label">Stap 1</span>
                </div>
                <h3>{{title}}</h3>
            </div>
            <div class="input">
                <div class="input-label">
                    Opdrachtnemer
                </div>
                <q-input labelPosition="inside"
                    v-model="input"
                    placeholder="Zoek op naam, adres of KvK-nummer"
                    @input="_search"
                />
            </div>
          </div>
      </div>
      <div class="results" v-if="input !== ''">
          <q-table :columns="getColumns" :data="branches" width="800px">
              <template v-slot:row="{ column, row }">
                  <div v-if="column === 'action'">
                    <q-button class="edit" 
                        variation="ghost" 
                        size="small" 
                        @click="select(row)">Kies organisatie</q-button>
                  </div>
                  <div v-else>
                      {{row[column]}}
                  </div>
              </template>
          </q-table>
      </div>
  </div>
</template>

<script>

import _ from "lodash";

import { KVK_COMPANIES, GET_ORGANISATION_BRANCHES } from '../../../graphql/queries'

import ArrowButton from '@/components/ArrowButton';

export default {
    name: 'q-select-organisation',
    components: {
        'arrow-button': ArrowButton
    },
    props: {
        /**
         * Type of the organisation select:
         * main: Only queries main organisations
         * branch: Only queries branch organisations for the given organisationId in 'Opdrachtnemer'
         */
        type: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: 'Kies een organisatie'
        }
    },
    data() {
        return {
            project: this.$parent.$parent.project,
            input: '',
            mainColumns: [
                {
                    field: 'name',
                    label: 'Organisatie',
                    width: '260px',
                },
                {
                    field: 'address',
                    label: 'Adres',
                    width: '200px',
                },
                {
                    field: 'code',
                    label: 'Postcode'
                },
                {
                    field: 'city',
                    label: 'Plaats'
                },
                {
                    field: 'action',
                    label: '',
                    width: '120px',
                    align: 'right'
                }
            ],
            branchColumns: [
                {
                    field: 'name',
                    label: 'Organisatie',
                    width: '260px',
                },
                {
                    field: 'address',
                    label: 'Adres',
                    width: '200px',
                },
                {
                    field: 'city',
                    label: 'Plaats'
                },
                {
                    field: 'action',
                    label: '',
                    width: '120px',
                    align: 'right'
                }
            ],
            loading: false,
            branches: []
        }
    },
    methods: {
        _search: _.debounce(function() {
            return this.search();
        }, 500),
        search() {
            switch (this.type) {
                case 'main':
                    this.getMainOrganisations();
                    break;
                case 'contractor-branch':
                    this.getBranchOrganisations('contractor-branch');
                    break;
                default:
                    console.error(`type '${this.type}' not found`)
                    break;
            }
        },
        select(row) {
            this.$emit('input', row.id);
        },
        setMainBranches(data) {
            const items = data.kvk_companies;
            const mainBranches = items.filter(item => item.isMainBranch == true);

            this.branches = mainBranches.map(branch => {
                return {
                    name: branch.tradeNames.businessName,
                    address: `${branch.addresses[0].street} ${branch.addresses[0].houseNumber}`,
                    code: branch.addresses[0].postalCode,
                    city: branch.addresses[0].city,
                    id: branch.mainExists.id
                }
            });

        },
        getMainOrganisations() {
            
            this.$apollo
                .query({
                    query: KVK_COMPANIES,
                    variables: {
                        term: this.input
                    },
                })
                .then(response => {
                    this.setMainBranches(response.data);
                })
                .catch(err => {
                    console.error(err);
                });
        },
        setBranchOrganisations(organisation) {
            this.branches = organisation.childs.map(branch => {
                return {
                    id: branch.id,
                    name: branch.name,
                    address: `${branch.address.line1} ${branch.address.line2}`,
                    city: branch.address.city
                }
            })
        },
        getBranchOrganisations(masterId) {
            const contractorId = masterId === 'contractor-branch' ? this.$parent.$parent.contractorReference.answer : null;
            const clientId = masterId === 'client-branch' ? this.$parent.$parent.clientReference.answer : null;
    
            if (!clientId && !contractorId) return console.error('no contractor or client id set')

            const organisationId = contractorId ? contractorId : clientId
            
            this.$apollo
                .query({
                    query: GET_ORGANISATION_BRANCHES,
                    variables: { organisationId },
                })
                .then(response => {
                    this.setBranchOrganisations(response.data.organisation);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        back() {
            this.$parent.cancel();
        }
    },
    computed: {
        getColumns: function() {
            switch (this.type) {
                case 'main':
                    return this.mainColumns
                    break;
                case 'contractor-branch':
                    return this.branchColumns
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";


.head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .status {
            margin-left: 10px;
        }

        h2 {
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
        }
    }
}

.results {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    & .edit {
        justify-content: right !important;
    }
}

.input-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    margin-top: 15px;
}

.search {
    display: flex;
    justify-content: center;

    margin-top: 150px;

    .panel {
        width: 460px;

        .title {
            display: flex;
            align-items: center;
            margin-left: -64px;

            h3 {
                margin-left: 24px;
            }
        }
    }
}

.label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    background: #F2F2F2;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    color: $color-grey-7;
    padding: 4px;
}



.q-select-organisation {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 1px;

    z-index: 100;
    background-color: white;
}

h3 {
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
}

</style>
<template>
    <div class="notify" @mouseenter="hovered = true" @mouseleave="hovered = false" @click="emitClick">
        <q-icon type="notification-bubble" width="50" height="50" :color="computedColor"> </q-icon>
        <q-icon :class="[type]" width="32" height="32" :type="type"></q-icon>
    </div>
</template>

<script>
export default {
    name: 'NavbarNotificationBadge',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        type: {
            type: String,
            default: 'present' // 'present' or 'confetti'
        }
    },
    data() {
        return {
            hovered: false
        };
    },
    methods: {
        emitClick() {
            this.$emit('click');
        }
    },
    computed: {
        computedColor() {
            return this.hovered ? '#c8cbcf' : this.color;
        }
    }
};
</script>

<style lang="scss" scoped>
.notify {
    position: relative;

    .present {
        position: absolute;
        top: 8px;
        left: 8.5px;
    }
    .confetti {
        position: absolute;
        top: 8px;
        left: 12px;
    }

    &:hover {
        cursor: pointer;
    }
}
</style>

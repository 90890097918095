<template>
    <auth-base>
        <div>
            <registration-navbar :step="1" :steps="steps"></registration-navbar>
            <div class="row middle-xs center-xs">
                <div class="q-card-medium">
                    <q-section paddingTop="40" hPadding="40">
                        <h2>E-mailadres valideren</h2>
                        <p class="pt-m">
                            Vul onderstaande gegevens in om je account aan te maken. In de volgende stap kun je je e-mailadres valideren om daarna toegang te vragen tot een organisatie.
                        </p>
                    </q-section>

                    <q-section vPadding="24" hPadding="40">
                        <div class="name-form row between-xs">
                            <q-input labelPosition="inside" v-model="user.firstName" class="col-xs-6 gutter" placeholder="Voornaam" size="medium"
                                >Voornaam</q-input
                            >
                            <q-input labelPosition="inside" v-model="user.lastName" class="col-xs-6 gutter" placeholder="Achternaam"
                                >Achternaam</q-input
                            >
                        </div>
                        <q-input labelPosition="inside"
                            v-model="user.phone"
                            placeholder="Telefoonnummer"
                            :errorMessage="phoneErrorMessage"
                            :state="phoneState"
                            @input="checkPhone"
                            @blur="validatePhone"
                            >Telefoonnummer</q-input
                        >
                        <q-input labelPosition="inside"
                            v-model="user.email"
                            inputType="email"
                            placeholder="E-mailadres"
                            :errorMessage="emailErrorMessage"
                            :state="emailState"
                            @input="_checkEmail"
                            @blur="checkEmail"
                            >E-mailadres</q-input
                        >
                        <div class="password-form row between-xs">
                            <q-input labelPosition="inside"
                                id="e2ePassword"
                                v-model="user.password"
                                class="col-xs-6 password custom-gutter"
                                inputType="password"
                                placeholder="Wachtwoord"
                                :errorMessage="passwordOneErrorMessage"
                                :state="passwordOneState"
                                @input="checkPasswordPartials"
                                @blur="validatePassword"
                                >Wachtwoord
                            </q-input>
                            <div class="tooltip">
                                <p>Wachtwoord moet bevatten:</p>
                                <p class="frmValidation" :class="{ 'frmValidation--passed': user.password.length > 7 }">
                                    <q-icon type="check" width="14" height="14" /> Acht karakters of meer
                                </p>
                                <p class="frmValidation" :class="{ 'frmValidation--passed': has_uppercase }">
                                    <q-icon type="check" width="14" height="14"  /> Heeft een hoofdletter
                                </p>
                                <p class="frmValidation" :class="{ 'frmValidation--passed': has_lowercase }">
                                    <q-icon type="check" width="14" height="14"  /> Heeft een kleine letter
                                </p>
                                <p class="frmValidation" :class="{ 'frmValidation--passed': has_number }">
                                    <q-icon type="check" width="14" height="14"  /> Heeft een nummer
                                </p>
                                <p class="frmValidation" :class="{ 'frmValidation--passed': has_special }">
                                    <q-icon type="check" width="14" height="14"  /> Heeft een speciaal karakter
                                </p>
                            </div>

                            <q-input labelPosition="inside"
                                id="e2eConfirmPassword"
                                v-model="user.confirmPassword"
                                class="col-xs-6 custom-gutter"
                                inputType="password"
                                placeholder="Herhaal wachtwoord"
                                :state="passwordTwoState"
                                :errorMessage="passwordTwoErrorMessage"
                                @input="doublecheckPassword"
                                @blur="validateConfirmPassword"
                                >Herhaal wachtwoord</q-input
                            >
                        </div>
                    </q-section>

                    <q-section vPadding="24" hPadding="40" borderTop class="space-between">
                        <q-button to="/" variation="blank" size="medium">Terug</q-button>
                        <q-button @click="handleRegistration" variation="primary" size="medium" :loading="awaitLogin"
                            >Account aanmaken</q-button
                        >
                    </q-section>
                </div>
            </div>
        </div>
    </auth-base>
</template>

<script>
import _ from 'lodash';
import AuthBase from '@/pages/auth/AuthBase';
import { REGISTER, CHECK_EMAIL_AVAILABLE } from '@/graphql/mutations';
import { extractError, validateEmail, validatePhoneNumber } from '@/assets/js/utils';
import RegistrationNavbar from './RegistrationNavbar';

export default {
    name: 'Register',
    components: {
        AuthBase,
        'registration-navbar': RegistrationNavbar
    },
    data() {
        return {
            user: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                password: '',
                confirmPassword: ''
            },
            passwordOneState: '',
            passwordTwoState: '',
            passwordOneErrorMessage: 'Wachtwoord voldoet niet',
            passwordTwoErrorMessage: '',
            awaitLogin: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            emailState: '',
            emailErrorMessage: '',
            phoneState: '',
            phoneErrorMessage: 'Ongeldig telefoonnummer'
        };
    },

    methods: {
        checkPasswordPartials() {
            if (this.user.password === '') {
                this.passwordOneState = '';
                this.has_number = false;
                this.has_lowercase = false;
                this.has_uppercase = false;
                this.has_special = false;
                return false;
            }
            this.has_number = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            this.has_special = /[\W_]/.test(this.user.password);

            if (this.passwordOneState !== 'error') return;
            this.checkPassword();
        },
        checkPassword: _.debounce(function() {
            this.validatePassword();
        }, 100),
        validatePassword() {
            const valid = this.has_number && this.has_lowercase && this.has_uppercase && this.has_special;
            this.passwordOneState = !valid && this.user.password !== '' ? 'error' : '';
            if (valid) this.validateConfirmPassword();

            return valid;
        },

        doublecheckPassword: _.debounce(function() {
            if (this.passwordTwoState !== 'error') return;
            this.validateConfirmPassword();
        }, 100),

        validateConfirmPassword() {
            if (this.user.password === '' || this.user.confirmPassword === '') {
                this.passwordTwoState = '';

                return false;
            }
            if (this.user.password === this.user.confirmPassword) {
                this.passwordTwoState = 'success';
            } else {
                this.passwordTwoState = 'error';
                this.passwordTwoErrorMessage = 'De wachtwoorden zijn niet hetzelfde';
                return false;
            }
            return true;
        },

        _checkEmail: _.debounce(function() {
            if (this.emailState !== 'error') return;
            this.checkEmail();
        }, 200),
        checkEmail() {
            if (this.user.email === '') {
                this.emailState = null;
                return;
            }

            if (validateEmail(this.user.email)) {
                this.$apollo
                    .mutate({
                        mutation: CHECK_EMAIL_AVAILABLE,
                        variables: { email: this.user.email }
                    })
                    .then(response => {
                        this.emailState = response.data.user_checkEmailAvailable.value ? 'success' : 'error';
                        if (response.data.user_checkEmailAvailable.value === false) {
                            this.emailErrorMessage = 'Dit e-mailadres is al geregistreerd';
                            return false;
                        }
                        return true;
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            } else {
                this.emailState = 'error';
                this.emailErrorMessage = 'Opgegeven e-mailadres is ongeldig';
                return false;
            }
        },
        handleRegistration() {
            this.awaitLogin = true;

            const inputValid =
                this.validatePhone() &&
                validateEmail(this.user.email) &&
                this.validatePassword() &&
                this.validateConfirmPassword();

            if (!inputValid) {
                this.awaitLogin = false;
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Niet alle velden zijn correct ingevuld'
                });
            } else {
                this.$apollo
                    .mutate({
                        mutation: REGISTER,
                        variables: { ...this.user, phone: validatePhoneNumber(this.user.phone) }
                    })
                    .then(response => {
                        this.awaitLogin = false;

                        this.$store.commit('setUser', response.data.user_register.user);
                        this.$store.commit('setJwt', response.data.user_register.jwt);

                        let url = '/emailvalidation';
                        if (this.$route.query.invited)
                            url += `?invited=1`;

                        this.$router.push(url);
                    })
                    .catch(error => {
                        this.awaitLogin = false;

                        if (error?.graphQLErrors[0]?.extensions?.response?.body?.message === 'Email is already in use')
                            this.$store.commit('notify', {
                                type: 'danger',
                                message: 'Dit e-mailadres is al in gebruik. Het is niet mogelijk om een nieuw account aan te maken met dit e-mailadres.'
                            });
                        else 
                            this.$store.commit('notify', extractError(error));
                    });
            }
        },
        checkPhone: _.debounce(function() {
            if (this.phoneState !== 'error') return;
            return this.validatePhone();
        }, 100),
        validatePhone() {
            const phoneValid =
                this.user.phone === '' || (this.user.phone !== '' && validatePhoneNumber(this.user.phone));
            this.phoneState = !phoneValid && this.user.phone !== '' ? 'error' : '';
            return phoneValid;
        }
    },
    computed: {
        steps: function() {
            const steps = [
                { id: 1, name: 'Account aanmaken' },
                { id: 2, name: 'E-mailadres valideren' }
            ]

            if (!this.$route.query.invited) {
                steps.push({ id: 3, name: 'Organisatie koppelen' });
            }

            return steps;
        }
    }
};
</script>

<style lang="scss" scoped>
.password:focus-within + div {
    display: block;
}

.password-form {
    position: relative;
}

.tooltip {
    display: none;
    position: absolute;
    bottom: 66px;
    border: 1px solid rgb(222, 226, 230);
    border-radius: 4px;
    background: white;
    height: auto;
    width: auto;
    padding: 15px;
    margin-left: -10px;
}

.frmValidation .icon {
    display: inline-flex;
    position: relative;
    bottom: -4px;
    padding-right: 4px;
}
.frmValidation {
    font-size: 14px;
    color: rgb(174, 181, 188);
}

.frmValidation--passed {
    font-size: 14px;
    color: rgb(54, 176, 76);
}
.notifs {
    position: absolute;
    padding: 20px;
    right: 10px;
}

.custom-gutter {
    flex: 0 0 calc(100% / 2 - 1.2em);
}

.pt-m {
    font-size: 14px;
}

</style>

<template>
    <div class="indicator-add-modal">
        <div class="body">
            <h2 class="title">Kenmerk toevoegen</h2>
            <div class="about">
                <p v-if="filteredIndicators.length > 0">
                    Selecteer hier de kenmerken uit de categorie <strong>{{ context.name }}</strong> die je wilt
                    toevoegen en momenteel nog niet in je project staan.
                </p>
                <p v-if="filteredIndicators.length === 0">
                    Voor de categorie <strong>{{ context.name }}</strong> zijn alle kenmerken zichtbaar in het project
                    en kunnen geen extra kenmerken worden toegevoegd.
                </p>
            </div>

            <q-dropdown
                v-if="filteredIndicators.length > 0"
                placeholder="Selecteer kenmerken"
                v-model="selectedIndicatorIds"
                :options="filteredIndicators"
                multiple
                autocomplete
            ></q-dropdown>
        </div>
        <div class="footer">
            <q-button variation="blank" @click="closePopup">Annuleren</q-button>
            <q-button
                variation="primary"
                @click="handleAddIndicatorToProject"
                :loading="loadingAddIndicator"
                :disabled="selectedIndicatorIds.length === 0"
            >Toevoegen aan project</q-button>
        </div>
    </div>
</template>

<script>
import { GET_INDICATORS } from '../../../graphql/queries';
import { ADD_ALL_INDICATORS } from '../../../graphql/mutations';

import { extractError } from '@/assets/js/utils';
import gql from 'graphql-tag';

export default {
    name: 'indicator-add-modal',
    props: {
        project: {
            type: Object,
            required: true,
        },
        context: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            indicatorIdsInCategory: [],
            projectIndicators: [],
            loadingAddIndicator: false,
            filteredIndicators: [],
            selectedIndicatorIds: [],
            indicators: [],
            labels: [],
        };
    },
    methods: {
        closePopup() {
            return this.$emit('close');
        },
        handleAddIndicatorToProject() {
            this.loadingAddIndicator = true;

            const projectId = this.$route.params.id;

            try {
                this.$apollo
                    .mutate({
                        mutation: ADD_ALL_INDICATORS,
                        variables: {
                            id: projectId,
                            indicatorIds: this.selectedIndicatorIds,
                        },
                    })
                    .then((result) => {
                        this.loadingAddIndicator = false;
                        this.$emit('indicatorsAdded', result.data.project_addAllIndicators);
                        this.closePopup();
                    })
                    .catch((error) => {
                        this.$store.commit('notify', extractError(error));
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getProjectIndicators() {
            const indicatorsToAdd = this.project.indicators.filter(indicator => indicator.disabled && indicator.context.id === this.context.id);
            const indicatorOptions = indicatorsToAdd.map(indicator => {
                return {
                    label: indicator.name,
                    value: indicator.id,
                }
            });
            this.filteredIndicators = indicatorOptions;

            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectIndicatorIds = this.project.indicators.map(indicator => indicator.id);
            const projectTemplateIds = this.project.templates.map(template => template.id)
            try {
                this.$apollo
                    .query({
                        query: GET_INDICATORS,
                        variables: {
                            where: {
                                AND: [
                                    { type: 'reference' },
                                    { organisationId: organisationId },
                                    { labelIds__contains: this.context.id },
                                    { id__nin: projectIndicatorIds },
                                    { status: 'active' }
                                ],
                            },
                        },
                    })
                    .then((result) => {
                        const newIndicatorsInContext = result.data.indicators.map((indicator) => {
                            return {
                                label: indicator.name,
                                value: indicator.id,
                            }
                        });
                        this.filteredIndicators = [ ...indicatorOptions, ...newIndicatorsInContext ];
                    })
                    .catch((error) => {
                        this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van nieuwe kenmerken' });
                    });
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
        this.getProjectIndicators();
    }
};
</script>

<style lang="scss" scoped>
.indicator-add-modal {
    .body {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-bottom: 80px;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="10" height="10" rx="1.5" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Box',
    props: {
        color: {
            type: String,
            default: '#ADB5BD',
        },
        width: {
            type: [Number, String],
            default: 11,
        },
        height: {
            type: [Number, String],
            default: 11,
        },
    },
};
</script>

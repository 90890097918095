<template>
    <div class="page-wrapper">
        <div class="dashboard page-padding">
            <div class="top">
                <h2>{{ getDaypart() }} {{ user.firstName }}!</h2>
                <q-button
                    v-can="'Project__create_crow'"
                    v-if="canCreateNewProject"
                    :iconStyle="iconStyle"
                    iconSize="22"
                    icon="helmet"
                    @click="createProject"
                    >Nieuw project</q-button
                >
            </div>

            <div class="body" v-if="eventsLoading || events.length > 0">
                <div class="right">
                    <div class="options">
                        <span :class="option === 'forms' ? 'active' : ''" @click="option = 'forms'">Formulieren</span>
                        <span :class="option === 'projects' ? 'active' : ''" @click="option = 'projects'">Projecten</span>
                    </div>
                    <p>Meest recente wijzigingen</p>
                    <div class="content">
                        <ul class="timeline">
                            <li v-for="(event, key) in events" :key="key" @click="select(event)">
                                <div class="start">
                                    <q-tooltip
                                        v-if="event.triggerTitle && event.avatar && event.avatarFallback"
                                        position="top"
                                    >
                                        <template v-slot:tooltip>
                                            {{ event.triggerTitle }}
                                        </template>
                                        <q-avatar :img="event.avatar" size="small">{{ event.avatarFallback }}</q-avatar>
                                    </q-tooltip>
                                    <q-avatar
                                        v-else-if="event.avatar && event.avatarFallback"
                                        :img="event.avatar"
                                        size="small"
                                        >{{ event.avatarFallback }}</q-avatar
                                    >
                                    <q-tag :variation="event.tagVariation" size="small">{{ $t(event.tagText) }}</q-tag>
                                    <span class="small">{{ event.date }}</span>
                                    <span class="bold big">{{ event.text }}</span>
                                </div>
                                <div class="end on-hover">
                                    <q-button variation="light" size="xsmall">Bekijk</q-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                <q-zerostate
                    class="zerostate"
                    img="/statics/img/project_gradient.svg"
                    title="Projecten"
                    :description="zerostateDescription"
                >
                    <q-button v-if="canCreateNewProject" v-can="'Project__create_crow'" @click="createProject"
                        >Nieuw project maken</q-button
                    >
                </q-zerostate>
            </div>

        </div>
        <div class="playground-container">
            <div class="sand-pile-container">
                <svg id="sand-pile" class="zand-berg" width="398" height="111" viewBox="0 0 398 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M397 92C396.732 63.56 360.216 52.6621 332.843 32.8085C305.47 12.9549 268.495 2.00509 230.053 2.36791C191.61 2.73073 154.849 14.3765 127.856 34.7432C100.862 55.1099 85.8485 82.5292 86.117 110.969L231.065 109.601L397 92Z" fill="#F9D99F"/>
                    <path d="M186.575 7.78061C166.015 7.97466 145.679 10.666 126.73 15.7011C107.78 20.7361 90.5881 28.0162 76.1344 37.1257C61.6806 46.2352 50.2487 56.9957 42.4912 68.7928C34.7337 80.5899 1.38024 85.8113 1.49999 98.5L187.487 104.404L186.575 7.78061Z" fill="#E1AF5A"/>
                    <ellipse cx="136" cy="64.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="136" cy="64.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="136" cy="64.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="118" cy="59.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="118" cy="59.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="118" cy="59.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="85" cy="75.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="85" cy="75.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="85" cy="75.5" rx="3" ry="2.5" fill="#F9D99F"/>
                    <ellipse cx="165.5" cy="59" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="165.5" cy="59" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="165.5" cy="59" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="172.5" cy="30" rx="3.5" ry="3" fill="#F9D99F"/>
                    <ellipse cx="172.5" cy="30" rx="3.5" ry="3" fill="#F9D99F"/>
                    <ellipse cx="172.5" cy="30" rx="3.5" ry="3" fill="#F9D99F"/>
                    <ellipse cx="148.5" cy="40" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse cx="148.5" cy="40" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse cx="148.5" cy="40" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse cx="112.5" cy="78" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="112.5" cy="78" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="112.5" cy="78" rx="5.5" ry="5" fill="#F9D99F"/>
                    <ellipse cx="100.5" cy="61" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse cx="100.5" cy="61" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse cx="100.5" cy="61" rx="4.5" ry="4" fill="#F9D99F"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 232 53.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 232 53.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 232 53.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 217 77.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 217 77.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 217 77.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 250 48.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 250 48.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 250 48.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 301 75.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 301 75.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 301 75.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 283 64.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 283 64.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 283 64.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 291 45.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 291 45.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 291 45.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 271 74.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 271 74.5)" fill="#E1AF5A"/>
                    <ellipse rx="3" ry="2.5" transform="matrix(-1 0 0 1 271 74.5)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 202.5 48)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 202.5 48)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 202.5 48)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 201.5 77)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 201.5 77)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 201.5 77)" fill="#E1AF5A"/>
                    <ellipse rx="3.5" ry="3" transform="matrix(-1 0 0 1 195.5 24)" fill="#E1AF5A"/>
                    <ellipse rx="3.5" ry="3" transform="matrix(-1 0 0 1 195.5 24)" fill="#E1AF5A"/>
                    <ellipse rx="3.5" ry="3" transform="matrix(-1 0 0 1 195.5 24)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 219.5 29)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 219.5 29)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 219.5 29)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 210.5 64)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 210.5 64)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 210.5 64)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 255.5 67)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 255.5 67)" fill="#E1AF5A"/>
                    <ellipse rx="5.5" ry="5" transform="matrix(-1 0 0 1 255.5 67)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 267.5 50)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 267.5 50)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 267.5 50)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 246.5 78)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 246.5 78)" fill="#E1AF5A"/>
                    <ellipse rx="4.5" ry="4" transform="matrix(-1 0 0 1 246.5 78)" fill="#E1AF5A"/>
                </svg>
            </div>

            
            <svg id="excavator" class="excavator-svg" viewBox="0 0 603.183 603.183" style="enable-background:new 0 0 603.183 603.183;">
                <g id="upper-excavator" class="upper-excavator">

                    <!-- roterende midden stam -->
                    <rect x="120.408" y="420.379" :style="`fill:${organisationColor}; -webkit-filter: brightness(91%); filter: brightness(91%)`" width="95.273" height="90.588"/>
                    
                    <g id="arm" class="arm">
                        <polygon id="upper-arm" class="upper-arm" :style="`fill:${organisationColor};`" points="463.131,89.874 473.717,122.955 280.525,246.016 271.262,301.591 224.949,301.591 
                            224.949,219.551 	"/>
                        <g id="lower-plus-bak">
                            <polygon class="lower-arm" :style="`fill:${organisationColor}; -webkit-filter: brightness(90%); filter: brightness(90%)`" points="513.414,80.612 546.058,244.666 522.676,252.632 473.717,122.955 463.131,89.874 467.101,39.591 	"/>
                            <g style="height: 50px" id="bak">
                                <g id="zand">
                                    <path d="M528.517 250.834C521.219 253.973 515.905 260.902 513.745 270.096C511.584 279.291 512.754 289.998 516.996 299.863C521.239 309.727 528.207 317.941 536.367 322.697C544.527 327.453 553.211 328.362 560.509 325.223L544.513 288.029L528.517 250.834Z" fill="#F9D99F"/>
                                    <path d="M523.083 310.48C525.352 315.756 528.24 320.704 531.583 325.043C534.925 329.381 538.656 333.024 542.563 335.765C546.47 338.505 550.476 340.289 554.352 341.015C558.229 341.74 561.9 341.392 565.156 339.992L547.877 299.816L523.083 310.48Z" fill="#E1AF5A"/>
                                    <ellipse cx="537.368" cy="309.073" rx="1.91178" ry="1.59315" transform="rotate(-112.73 537.368 309.073)" fill="#F9D99F"/>
                                    <ellipse cx="537.368" cy="309.073" rx="1.91178" ry="1.59315" transform="rotate(-112.73 537.368 309.073)" fill="#F9D99F"/>
                                    <ellipse cx="537.368" cy="309.073" rx="1.91178" ry="1.59315" transform="rotate(-112.73 537.368 309.073)" fill="#F9D99F"/>
                                    <ellipse cx="538.861" cy="320.884" rx="1.91178" ry="1.59315" transform="rotate(-112.73 538.861 320.884)" fill="#F9D99F"/>
                                    <ellipse cx="538.861" cy="320.884" rx="1.91178" ry="1.59315" transform="rotate(-112.73 538.861 320.884)" fill="#F9D99F"/>
                                    <ellipse cx="538.861" cy="320.884" rx="1.91178" ry="1.59315" transform="rotate(-112.73 538.861 320.884)" fill="#F9D99F"/>
                                    <ellipse cx="547.356" cy="315.598" rx="3.50492" ry="3.18629" transform="rotate(-112.73 547.356 315.598)" fill="#F9D99F"/>
                                    <ellipse cx="547.356" cy="315.598" rx="3.50492" ry="3.18629" transform="rotate(-112.73 547.356 315.598)" fill="#F9D99F"/>
                                    <ellipse cx="547.356" cy="315.598" rx="3.50492" ry="3.18629" transform="rotate(-112.73 547.356 315.598)" fill="#F9D99F"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 528.138 301.885)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 528.138 301.885)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 528.138 301.885)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 531.482 281.829)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 531.482 281.829)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 531.482 281.829)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 522.157 276.063)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 522.157 276.063)" fill="#E1AF5A"/>
                                    <ellipse rx="1.91178" ry="1.59315" transform="matrix(0.386395 0.922334 0.922334 -0.386395 522.157 276.063)" fill="#E1AF5A"/>
                                    <ellipse rx="3.50492" ry="3.18629" transform="matrix(0.386395 0.922334 0.922334 -0.386395 537.657 294.097)" fill="#E1AF5A"/>
                                    <ellipse rx="3.50492" ry="3.18629" transform="matrix(0.386395 0.922334 0.922334 -0.386395 537.657 294.097)" fill="#E1AF5A"/>
                                    <ellipse rx="3.50492" ry="3.18629" transform="matrix(0.386395 0.922334 0.922334 -0.386395 537.657 294.097)" fill="#E1AF5A"/>
                                    <ellipse rx="2.86767" ry="2.54904" transform="matrix(0.386395 0.922334 0.922334 -0.386395 524.711 291.229)" fill="#E1AF5A"/>
                                    <ellipse rx="2.86767" ry="2.54904" transform="matrix(0.386395 0.922334 0.922334 -0.386395 524.711 291.229)" fill="#E1AF5A"/>
                                    <ellipse rx="2.86767" ry="2.54904" transform="matrix(0.386395 0.922334 0.922334 -0.386395 524.711 291.229)" fill="#E1AF5A"/>
                                </g>
                                <path class="bak" :style="`fill:${organisationColor};`" d="M596.023,252.526c-9.038-11.803-24.599-16.514-38.678-11.71l-11.287,3.85l-23.381,7.966
                                    l32.377,66.777l-7.581,10.997c-6.218,9.02-3.932,21.375,5.101,27.574l44.522-64.765c4.062-5.928,6.087-12.769,6.087-19.61
                                    C603.182,266.155,600.787,258.732,596.023,252.526z"/>
                            </g>
                        </g>
                    </g>
                    <g class="window">
                        <!-- base -->
                        <path style="fill:#93CBCC;" d="M310.404,428.515h-0.013l-77.594-18.486c-19.346-4.618-32.988-21.9-32.988-41.787v-66.651h93.579
                            l14.489,108.068L310.404,428.515z"/>
                        <!-- reflection -->
                        <polygon style="fill:#ADDEDC;" points="307.876,409.659 199.808,301.591 293.387,301.591 	"/>
                    </g>

                    <!-- frame -->
                    <path :style="`fill:${organisationColor};`" d="M310.404,428.516l3.202,23.924H0l9.752-72.778h151.682l10.758-78.071h27.616v66.651
                        c0,19.888,13.643,37.17,32.988,41.788l77.594,18.486H310.404z"/>
                    <!-- schaduwe achter -->
                    <rect x="36.516" y="406.789" :style="`fill:${organisationColor}; -webkit-filter: brightness(85%); filter: brightness(85%)`" width="39.697" height="19.848"/>

                </g>

                <g class="tyres">
                    <path  class="outer-tyre" style="fill:#979797;" d="M60.201,563.592c-26.631,0-48.298-21.667-48.298-48.298c0-26.631,21.667-48.298,48.298-48.298
                        h299.05c26.631,0,48.298,21.667,48.298,48.298c0,26.631-21.667,48.298-48.298,48.298H60.201z"/>
                    <path class="tyre-frame" style="fill:#666666;" d="M359.252,478.906H60.201c-20.097,0-36.389,16.292-36.389,36.389l0,0
                        c0,20.097,16.292,36.389,36.389,36.389h299.05c20.097,0,36.389-16.292,36.389-36.389l0,0
                        C395.64,495.197,379.349,478.906,359.252,478.906z"/>
                    <path style="fill:#979797;" d="M326.832,525.219H92.62c-5.482,0-9.924-4.443-9.924-9.924s4.443-9.924,9.924-9.924h234.212
                        c5.482,0,9.924,4.443,9.924,9.924C336.756,520.775,332.313,525.219,326.832,525.219z"/>
                    <path style="fill:#979797;" d="M62.839,525.217c-0.634,0-1.296-0.067-1.932-0.198c-0.634-0.12-1.256-0.318-1.852-0.556
                        c-0.609-0.251-1.179-0.556-1.72-0.913c-0.543-0.37-1.045-0.781-1.509-1.244c-0.45-0.45-0.874-0.966-1.23-1.509
                        c-0.358-0.529-0.662-1.111-0.914-1.706c-0.251-0.596-0.437-1.231-0.569-1.853c-0.132-0.648-0.199-1.297-0.199-1.945
                        c0-0.649,0.067-1.297,0.199-1.932s0.318-1.257,0.569-1.866c0.252-0.596,0.556-1.178,0.914-1.707
                        c0.357-0.543,0.781-1.058,1.23-1.509c0.464-0.463,0.967-0.873,1.509-1.23c0.541-0.357,1.111-0.675,1.72-0.913
                        c0.596-0.252,1.217-0.45,1.852-0.569c3.229-0.649,6.656,0.41,8.959,2.712c0.45,0.45,0.874,0.966,1.23,1.509
                        c0.358,0.529,0.662,1.111,0.914,1.707c0.251,0.609,0.437,1.231,0.569,1.866c0.133,0.635,0.199,1.283,0.199,1.932
                        c0,0.648-0.066,1.297-0.199,1.945c-0.132,0.622-0.318,1.257-0.569,1.853c-0.252,0.595-0.556,1.177-0.914,1.706
                        c-0.357,0.543-0.781,1.059-1.23,1.509C68.013,524.158,65.46,525.217,62.839,525.217z"/>
                    <path style="fill:#979797;" d="M356.611,525.217c-2.621,0-5.174-1.059-7.027-2.898c-0.45-0.463-0.874-0.966-1.23-1.522
                        c-0.358-0.529-0.662-1.111-0.914-1.706c-0.251-0.596-0.437-1.231-0.569-1.853c-0.132-0.648-0.199-1.297-0.199-1.945
                        c0-0.649,0.067-1.297,0.199-1.932s0.318-1.27,0.569-1.866c0.252-0.596,0.556-1.178,0.914-1.707c0.357-0.543,0.78-1.058,1.23-1.509
                        c2.303-2.302,5.73-3.361,8.959-2.712c0.634,0.119,1.257,0.317,1.852,0.569c0.609,0.238,1.178,0.556,1.721,0.913
                        c0.541,0.357,1.045,0.768,1.508,1.23c0.45,0.45,0.874,0.966,1.23,1.509c0.358,0.529,0.662,1.111,0.914,1.707
                        c0.251,0.596,0.437,1.231,0.569,1.866c0.133,0.635,0.199,1.283,0.199,1.932c0,0.648-0.066,1.297-0.199,1.945
                        c-0.132,0.622-0.318,1.257-0.569,1.853c-0.252,0.595-0.556,1.177-0.914,1.706c-0.357,0.543-0.78,1.059-1.23,1.522
                        c-0.463,0.45-0.967,0.86-1.508,1.23c-0.543,0.357-1.113,0.662-1.721,0.913c-0.594,0.238-1.217,0.437-1.852,0.556
                        C357.907,525.151,357.245,525.217,356.611,525.217z"/>
                </g>
            </svg>

            <!-- dump truck -->
            <svg id="dump-truck" class="dump-truck-svg" viewBox="0 0 512 420">
                <polygon :style="`fill:${organisationColor}; -webkit-filter: brightness(90%); filter: brightness(90%)`" points="62.973,203.705 62.973,159.897 139.636,159.897 205.349,203.705 369.626,203.705 
                    369.626,357.031 62.973,357.031 "/>
                <rect x="251.893" y="203.705" :style="`fill:${organisationColor}; -webkit-filter: brightness(90%); filter: brightness(90%)`" width="117.733" height="153.326"/>

                <!-- bak -->
                <g id="dump-truck-bak" class="bak">
                    <g id="dump-truck-sand-pile" class="zand">
                        <path style="fill:#E1AF5A;" d="M493.782,180.252c-13.078-12.086-13.677-6.454-30.603-19.737
                            c-8.254-6.479-15.093-17.242-25.957-24.736c-9.435-6.51-22.366-9.531-33.112-15.817c-10.355-6.055-18.865-15.61-29.884-20.617
                            c-11.329-5.147-24.586-5.46-36.029-8.616c-12.362-3.41-23.942-9.686-35.718-9.686c-9.076,0-16.499,5.83-24.077,8.63
                            c-7.998,2.955-17.619,3.548-24.066,8.684c-6.44,5.131-8.672,14.058-13.678,20.404c-5.131,6.504-13.565,11.106-17.315,17.867
                            c-4.225,7.621-3.282,16.84-5.919,23.392c-5.262,13.072-9.303,9.775-12.076,21.306"/>
                        <path style="fill:#F9D99F;" d="M320.044,98.832c-6.439,5.131-8.669,14.058-13.677,20.404c-5.132,6.504-13.566,11.106-17.316,17.867
                            c-4.224,7.621-3.281,16.841-5.918,23.392c-5.223,12.976-9.242,9.83-12.013,21.06l-65.773,0.245
                            c2.773-11.531,6.813-8.234,12.076-21.306c2.637-6.551,1.693-15.771,5.919-23.392c3.75-6.761,12.184-11.363,17.315-17.867
                            c5.007-6.347,7.238-15.273,13.678-20.404c6.446-5.135,16.067-5.728,24.066-8.684c7.579-2.8,15.001-8.63,24.077-8.63
                            c11.778,0,23.356,6.275,35.717,9.686c0.467,0.128,0.939,0.245,1.409,0.366C332.712,93.47,325.318,94.633,320.044,98.832z"/>
                    </g>

                    <polygon :style="`fill:${organisationColor};`" points="183.939,307.834 170.594,192.753 501.048,192.753 501.048,252.567 303.914,313.224 "/>
                    <polygon :style="`fill:${organisationColor}; -webkit-filter: brightness(90%); filter: brightness(90%)`" points="251.893,192.753 251.893,310.886 303.914,313.224 501.048,252.567 501.048,192.753 "/>

                    <g>
                        <path :style="`fill:${organisationColor};`" d="M52.021,137.994h98.567l32.856,32.856h317.604c6.049,0,10.952,4.903,10.952,10.952v10.952
                            c0,6.049-4.903,10.952-10.952,10.952H172.492l-32.856-32.856H52.021c-6.049,0-10.952-4.903-10.952-10.952v-10.952
                            C41.07,142.897,45.973,137.994,52.021,137.994z"/>
                        <path :style="`fill:${organisationColor};`" d="M501.048,170.849H251.893v32.856h249.155c6.049,0,10.952-4.903,10.952-10.952v-10.952
                            C512,175.752,507.097,170.849,501.048,170.849z"/>
                    </g>


                    <g class="lijn-zijkant">
                        <path style="fill:#7d7b83;" d="M232.727,239.298H364.15c4.537,0,8.214,3.678,8.214,8.214c0,4.536-3.677,8.214-8.214,8.214H232.727
                            c-4.536,0-8.214-3.678-8.214-8.214C224.513,242.976,228.191,239.298,232.727,239.298z"/>
                        <path style="fill:#616066;" d="M364.15,239.298H251.893v16.428H364.15c4.537,0,8.214-3.678,8.214-8.214
                            C372.364,242.976,368.687,239.298,364.15,239.298z"/>
                    </g>
                </g>

                <circle style="fill:#616066;" cx="369.626" cy="357.031" r="65.711"/>
                <circle style="fill:#DBDBDB;" cx="369.626" cy="357.031" r="21.904"/>
                <circle style="fill:#616066;" cx="139.636" cy="357.031" r="65.711"/>
                <circle style="fill:#DBDBDB;" cx="139.636" cy="357.031" r="21.904"/>
                <rect x="19.166" y="170.849" style="fill:#D1E5F5;" width="98.567" height="43.807"/>
                <path :style="`fill:${organisationColor}; filter: brightness(106%); -webkit-filter: brightness(106%);`" d="M19.166,203.705h120.471l10.952,87.615H73.925v65.711H8.214V214.657
                    C8.214,208.608,13.117,203.705,19.166,203.705z"/>
                <polygon style="fill:#F8F8F9;" points="146.481,258.464 8.214,258.464 8.214,225.609 142.374,225.609 "/>
                <g>
                    <path style="fill:#88888F;" d="M43.807,203.705v-24.642H27.38v24.642c0,4.536-3.678,8.214-8.214,8.214s-8.214-3.678-8.214-8.214
                        v-32.856c0-4.536,3.678-8.214,8.214-8.214h98.567c4.536,0,8.214,3.678,8.214,8.214v32.856c0,4.536-3.678,8.214-8.214,8.214
                        s-8.214-3.678-8.214-8.214v-24.642H93.091v24.642c0,4.536-3.678,8.214-8.214,8.214s-8.214-3.678-8.214-8.214v-24.642H60.235v24.642
                        c0,4.536-3.678,8.214-8.214,8.214S43.807,208.241,43.807,203.705z"/>
                    <path style="fill:#88888F;" d="M0,258.464v98.567c0,4.536,3.678,8.214,8.214,8.214H41.07c4.536,0,8.214-3.678,8.214-8.214v-98.567
                        c0-4.536-3.678-8.214-8.214-8.214H8.214C3.678,250.25,0,253.928,0,258.464z M32.856,315.962H16.428v-16.428h16.428V315.962z
                        M32.856,266.678v16.428H16.428v-16.428H32.856z M16.428,348.817v-16.428h16.428v16.428H16.428z"/>
                </g>
                <g>
                    <path style="fill:#7d7b83;" d="M216.299,302.272h76.663c6.049,0,10.952,4.903,10.952,10.952v43.807
                        c0,6.049-4.903,10.952-10.952,10.952h-76.663c-6.049,0-10.952-4.903-10.952-10.952v-43.807
                        C205.348,307.175,210.251,302.272,216.299,302.272z"/>
                    <path style="fill:#616066;" d="M292.963,302.272h-41.07v65.711h41.07c6.049,0,10.952-4.903,10.952-10.952v-43.807
                        C303.914,307.175,299.011,302.272,292.963,302.272z"/>
                </g>
            </svg>


            <svg class="hekje-svg" width="134" height="87" viewBox="0 0 134 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="111" width="11" height="49" fill="#D9D9D9"/>
                <rect x="6" width="11" height="87" fill="#D9D9D9"/>
                <rect x="12" width="10" height="87" fill="#ADB5BD"/>
                <rect x="117" width="10" height="87" fill="#ADB5BD"/>
                <path d="M117 44V52L15.5 48L17 44H117Z" fill="#D9D9D9"/>
                <rect x="117" y="48" width="8" height="100" transform="rotate(90 117 48)" fill="#ADB5BD"/>
                <path d="M111 56L117 52V87H111V56Z" fill="#D9D9D9"/>
                <path d="M0.5 8C0.5 7.17157 1.17157 6.5 2 6.5H132C132.828 6.5 133.5 7.17157 133.5 8V31C133.5 31.8284 132.828 32.5 132 32.5H2C1.17157 32.5 0.5 31.8284 0.5 31V8Z" fill="white" stroke="#D9D9D9"/>
                <path d="M19.4186 7H41L30.5814 32H9L19.4186 7Z" fill="#F03E3E"/>
                <path d="M62.4186 7H84L73.5814 32H52L62.4186 7Z" fill="#F03E3E"/>
                <path d="M41.4186 7H63L52.5814 32H31L41.4186 7Z" fill="white"/>
                <path d="M104.419 7H126L115.581 32H94L104.419 7Z" fill="#F03E3E"/>
                <path d="M83.4186 7H105L94.5814 32H73L83.4186 7Z" fill="white"/>
            </svg>


            <svg class="weg-svg" width="100vw" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100%" height="16" fill="#705E5F"/>
                <rect x="16" y="13" width="768" height="15" rx="9.5" fill="#AA9B91"/>
                <rect x="1213" y="11" width="219" height="14" rx="9.5" fill="#AA9B91"/>
                <rect x="828" y="13" width="369" height="18" rx="9.5" fill="#F9D99F"/>
                <rect x="795" y="22" width="146" height="14" rx="9.5" fill="#E1AF5A"/>
                <rect x="956" y="23" width="63" height="14" rx="9.5" fill="#E1AF5A"/>
            </svg>

        </div>
    </div>
</template>

<script>
import { GET_PROJECT_EVENTS, GET_FORM_EVENTS, GET_INDICATORS } from '../../graphql/queries';
import { extractError, getDate, userInitials, canCreateNewProject } from '../../assets/js/utils';
import { gsap } from "gsap";

export default {
    name: 'Dashboard',
    data() {
        return {
            eventsLoading: true,
            user: this.$store.getters.getUser,
            option: 'projects',
            events: [],
            zerostateDescription:
                this.organisationType !== 'client'
                    ? 'Je bent nog niet uitgenodigd om aan een project deel te nemen.'
                    : this.ability.get().can('create_crow', 'Project')
                    ? 'Je hebt nog geen project aangemaakt, of je bent nog niet uitgenodigd om aan een project deel te nemen. Ga vandaag aan de slag met je eerste project!'
                    : 'Er zijn nog geen project waar u lid van bent. Vraag een projectbeheerder om uitgenodigd te worden voor een project.',
            iconStyle: {
                marginLeft: '-10px',
                marginTop: '-2px'
            },
            organisationColor: '#EBCD5C'
        };
    },
    methods: {
        select(event) {
            this.$router.push(event.to);
        },
        getInitials(user) {
            return userInitials(user.firstName, user.lastName);
        },
        getEvents() {
            this.eventsLoading = true;
            if (this.option === 'projects')
                this.$apollo
                    .query({
                        query: GET_PROJECT_EVENTS,
                        fetchPolicy: 'no-cache'
                    })
                    .then(result => {
                        this.events = this.setProjectEvents(result.data.projectActivityFeed);
                        this.eventsLoading = false;
                    })
                    .catch(error => {
                        this.$store.commit('notify', extractError(error));
                        this.eventsLoading = false;
                    });
            else if (this.option === 'forms')
                this.$apollo
                    .query({
                        query: GET_FORM_EVENTS,
                        fetchPolicy: 'no-cache'
                    })
                    .then(result => {
                        this.events = this.setFormEvents(result.data.formActivityFeed);
                        this.eventsLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$store.commit('notify', extractError(error));
                        this.eventsLoading = false;
                    });
        },
        setFormEvents(events) {
            const tagVariationMap = {
                created: 'success',
                form_ready: 'success',
                form_completed: 'success',
                form_disagree: 'warning'
            };

            return events.map(event => {
                return {
                    tagVariation: tagVariationMap[event.eventType] || 'default',
                    tagText: event.object.name,
                    date: getDate(event.timestamp),
                    text: `${event.object.projectName} - ${this.$t(event.object.name)}`,
                    to: '/forms/' + event.object.id
                };
            });
        },
        setProjectEvents(events) {
            const tagVariationMap = {
                created: 'success',
                finished: 'success',
                crow_project_ready: 'success',
                contractor_set: 'success',
                form_added: 'success',
                member_added: 'success'
            };

            return events.map(event => {
                const hasCausationUser =
                    event.metadata &&
                    event.metadata.causation &&
                    event.metadata.causation.user &&
                    event.metadata.causation.user.avatar;
                return {
                    tagVariation: tagVariationMap[event.eventType] || 'default',
                    tagText: event.eventType,
                    date: getDate(event.timestamp),
                    text: this.$t(event.object.name),
                    to: '/projects/' + event.object.id,
                    avatarFallback: hasCausationUser ? this.getInitials(event.metadata.causation.user) : '',
                    triggerTitle: hasCausationUser
                        ? `${event.metadata.causation.user.firstName} ${event.metadata.causation.user.lastName}`
                        : null,
                    avatar: hasCausationUser ? event.metadata.causation.user.avatar : null
                };
            });
        },
        getDaypart() {
            const hour = new Date().getHours();

            if (hour < 12) return 'Goedemorgen';
            else if (hour < 18) return 'Goedemiddag';
            else return 'Goedenavond';
        },
        createProject() {
            this.$router.push('/projects/create');
        }
    },
    computed: {
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        },
        canCreateNewProject: function() {
            return canCreateNewProject()
        }
    },
    watch: {
        option: function() {
            this.getEvents();
        }
    },
    created() {
        this.getEvents();
    },
    mounted() {
        const animationDelay = 8;

        // excavator timeline
        const digTimeLine = gsap.timeline({
            paused: true 
        })
        .to('#upper-excavator', {
            rotate: -3
        })
        .from('#excavator', {
            right: -200,
            duration: 1.4,
            ease: 'power1'
        })
        .to('#upper-excavator', {
            rotate: 0,
            duration: .6,
            ease: 'back.out(5)'
        }, '-=.4')
        .to('#arm', {
            rotate: 34,
            transformOrigin: '10px 90%',
            duration: 1.4,
            ease: 'ease'
        }, '-=1.7')
        .to('#lower-plus-bak', {
            rotate: -68,
            transformOrigin: '40px 60px',
            duration: .9,
            ease: 'power1'
        }, '-=1.4')
        .fromTo('#bak', {
            rotate: 50,
            transformOrigin: '20px 20px'
        },
        {
            rotate: 4,
            transformOrigin: '20px 20px',
            duration: 1,
            ease: 'sine'
        }, '-=1.1')
        .to('#sand-pile', {
            y: '+=30',
            duration: 0.5,
            ease: 'sine.out'
        }, '-=.1')
        .fromTo('#zand', {
            opacity: 0
        },{
            opacity: 1,
            duration: .3
        }, '-=.4')
        .to('#arm', {
            rotate: 24,
            transformOrigin: '10px 90%',
            duration: 1,
            ease: 'back.out(2.5)'
        }, '<')
        .to('#lower-plus-bak', {
            rotate: 42,
            transformOrigin: '20px 50px',
            duration: .8
        }, '-=1.1')
        .to('#bak', {
            rotate: 38,
            transformOrigin: '10px 20px',
            duration: .2,
            ease: 'power3'
        }, '-=.9')
        .to('#upper-excavator', {
            scaleX: -1,
            transformOrigin: '25% center',
            duration: .5
        })
        .to('#upper-excavator', {
            rotate: 3,
            duration: .3,
            ease: 'power3'
        })
        .to('#excavator', {
            right: -200,
            duration: 1.2,
            ease: 'sine'
        }, '-=.3')

        // dump truck timeline
        const fillTimeLine = gsap.timeline({
            delay: digTimeLine.duration() + (animationDelay / 2),
            paused: true 
        })
        .from('#dump-truck', {
            right: -200,
            duration: 2,
            ease: 'power1'
        })
        .to('#dump-truck-bak', {
            rotate: 50,
            duration: 2.2,
            ease: 'back.out(1)'
        }, '-=0.2')
        .to('#dump-truck-sand-pile', {
            translateY: 75,
            duration: 1.7,
            ease: 'sine.in.out'
        }, '-=2')
        .to('#sand-pile', {
            y: 0,
            duration: 1.7,
            ease: 'sine.in.out'
        }, '<')
        .to('#dump-truck-bak', {
            rotate: 0,
            duration: .8,
            ease: 'power1'
        }, '-=.8')
        .to('#dump-truck', {
            right: -250,
            duration: 2.4,
            ease: 'ease'
        }, '-=0.6')

        digTimeLine.timeScale(1.2).play().then(() => {
            fillTimeLine.delay(2)
            fillTimeLine.restart(true).then(() => playDigTimeline())
        })

        function playFillTimeline() {
            let randomDelay = 2 + Math.round(Math.random() * 8);

            fillTimeLine.delay(randomDelay)
            fillTimeLine.restart(true)
            .then(() => playDigTimeline())
        }

        function playDigTimeline() {
            const randomDelay = Math.round(Math.random() * 8)

            if(randomDelay < 2) digTimeLine.timeScale(1.4)
            else digTimeLine.timeScale(1)
            
            digTimeLine.delay(randomDelay)
            digTimeLine.restart(true)
            .then(() => playFillTimeline())
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/components/qds/assets/style/style.scss';

@media screen and (max-height: 750px) {
    .playground-container {
        display: none;
    }
}

.dashboard {
    overflow-x: hidden;
}

.big {
    font-size: 14px;
}

.small {
    font-size: 12px;
}

.bold {
    font-weight: 500;
}

.options {
    margin-bottom: 30px;

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 23px;
        color: #1d2443;
        opacity: 0.5;
        cursor: pointer;

        &.active {
            opacity: 1;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
}

.timeline li .start {
    display: flex;
    align-items: center;

    .avatar.small {
        margin-right: 12px;
    }
    .tag {
        margin-right: 12px;
    }
}

.body {
    display: flex;
    margin: 60px 0px 0 4px;

    .right {
        flex: 1 0;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-left: 10px;
        }
    }
}

.zerostate {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
}

.top {
    display: flex;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        font-size: 31px;
        line-height: 33px;
    }
}

* {
    font-family: Gotham;
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 40px;
}

.playground-container {
    position: absolute;
    bottom: 0px;
    inset-inline: 0;
    pointer-events: none;
    
    .excavator-svg {
        position: absolute;
        right: 12.5em;
        bottom: 29px;
        // bottom: 1.85em;
        // width: 180px;
        // height: 180px;
        width: 11.25em;
        height: 11.25em;
        overflow: visible;
        transform: scale(-1, 1);

        .upper-excavator {
            transform-origin: 25% bottom;
        }
    }

    .dump-truck-svg {
        position: absolute;
        bottom: 27px;
        // bottom: 1.75em;
        right: 200px;
        // width: 160px;
        // height: 160px;
        width: 10em;
        height: 10em;
        overflow: visible;
        transform: scale(-1, 1);

        .bak {
            transform-origin: 70% 50%;
        }
    }

    .hekje-svg {
        position: absolute;
        // bottom: 30px;
        bottom: 1.8em;
        // right: 740px;
        right: 50em;
        // width: 100px;
        width: 6.25em;
    }


    .sand-pile-container {
        position: absolute;
        bottom: 2.6em;
        // bottom: 2em;
        // right: 300px;
        right: 18.75em;
        overflow: hidden;
        // height: 80px;
        height: 5em;

        .zand-berg {
            z-index: 1;
            // margin-bottom: -10px;
        }
    }


    .weg-svg {
        position: relative;
        z-index: 1;
        width: 100vw;
        // height: 2.5em;
        // bottom: 0.7em;
    }
}
</style>

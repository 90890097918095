<template>
    <div class="detail-page-padding">
        <div class="head">
            <div class="left title">
                <q-clear-input
                    id="projectName"
                    v-model="projectTitle"
                    type="text"
                    size="extralarge"
                    placeholder="Vul een projectnaam in"
                    class="input"
                    @input="saveProjectTitle"
                    @enter="createProject"
                    autofocus
                >
                </q-clear-input>
            </div>
            <div class="right">
                <q-button variation="blank" class="mr-m" size="medium" @click="back">Terug</q-button>
                <q-button variation="primary" size="medium" @click="createProject" :loading="loadingCreateProject"
                    >Project aanmaken</q-button
                >
            </div>
        </div>
        <div class="project">
            <div class="left">
                <div class="panel q-card-small default">
                    <h4>Projectkenmerken</h4>
                    <div class="indicator" v-for="n in 4" :key="n">
                        <div class="name blank-row"></div>
                        <div class="answer blank-row"></div>
                    </div>
                </div>

                <div class="options">
                    <div>
                        <div class="title">Projectkenmerken</div>
                    </div>

                    <div class="search">
                        <q-icon type="Search"></q-icon>
                        <div class="not-searching">
                            <span>Zoek een kenmerk</span>
                        </div>
                    </div>
                </div>

                <div class="panel q-card-small default">
                    <div class="blank-title"></div>
                    <div class="indicator" v-for="n in 3" :key="n">
                        <div class="name blank-row"></div>
                        <div class="answer blank-row"></div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="mt-xxl">
                    <q-zerostate style="filter: opacity(0.4);" img="/statics/img/building_gradient.svg">
                        <q-button disabled>Opdrachtnemer selecteren</q-button>
                    </q-zerostate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormTimeline from './FormTimeline';
import IndicatorAnswer from './IndicatorAnswer';

import { CREATE_CROW_PROJECT } from '../../../graphql/mutations';
import { extractError } from '@/assets/js/utils';

export default {
    components: {
        formtimeline: FormTimeline,
        'indicator-answer': IndicatorAnswer
    },
    props: {
        testProject: Object
    },
    data() {
        return {
            loadingCreateProject: false,
            projectTitle: this.$store.getters.getCreateProjectTitle || ''
        };
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        createProject() {
            if(this.loadingCreateProject) return
            
            if (this.projectTitle == '') {
                document.getElementById('projectName').focus();
                document.getElementById('projectName').placeholder = 'Vul eerst een projectnaam in';
                this.$store.commit('notify', {
                    type: 'warning',
                    message: 'Vul eerst de naam van uw nieuwe project in'
                });
                return;
            }

            this.loadingCreateProject = true;

            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const organisationType = this.$store.getters.getOrganisationType;

            this.$apollo
                .mutate({
                    mutation: CREATE_CROW_PROJECT,
                    variables: {
                        title: this.projectTitle,
                        organisationId,
                        organisationType
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`
                        }
                    }
                })
                .then(response => {
                    this.loadingCreateProject = false;
                    this.$store.commit('setCreateProjectTitle', '');
                    if (response.data.project_createCrow)
                        this.$router.push({
                            path: `/projects/${response.data.project_createCrow.id}`,
                            query: { title: response.data.project_createCrow.id.name }
                        });
                })
                .catch(err => {
                    this.loadingCreateProject = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        saveProjectTitle(event) {
            this.$store.commit('setCreateProjectTitle', this.projectTitle);
        }
    },
    computed: {
        /**
         * Option will be rendered based on the project template chosen for this (not yet) created project
         */
        options: function() {
            let categories = this.project.settings.categories;

            categories.forEach(category => {
                const id = category.id;

                const forms = [];

                category.forms = forms;
            });

            return {
                categories
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

// .project {
//     display: flex;

//     .left, .right {

//     }

//     .left {
//         margin-right: 40px;
//     }
// }

.project {
    display: flex;
    justify-content: space-between;

    .left,
    .right {
    }

    .left {
        margin-right: 30px;
    }

    .right {
        width: 100%;
        margin-left: 30px;
        display: flex;
        justify-content: center;
    }
}

.panel {
    padding: 24px 24px 16px 24px;
    width: 500px;
    margin-bottom: 20px;

    h4 {
        margin-bottom: 24px;
        color: $color-grey-3;
    }

    .blank-row,
    .blank-title {
        background-color: lighten($color-grey-3, 5%);
        border-radius: 4px;
    }

    .blank-title {
        width: 100%;
        height: 24px;
        width: 60%;
        margin-bottom: 20px;
    }

    .blank-row {
        width: 50%;
        height: 20px;
        margin: 10px 0;
    }

    .blank-row:first-child {
        margin-right: 10%;
        width: 100px;
    }

    .blank-row:last-child {
        margin: 0;
    }

    .indicator {
        display: flex;
        align-items: center;
        // transition: 200ms;
        height: 35px;
        color: $color-grey-9;
        border-radius: 4px;
        margin: -4px -12px;
        padding: 4px 12px;

        &.selected {
            background-color: lighten($color-grey-3, 5%);
        }

        .name {
            flex: 0 0 35%;
        }
        .answer {
            flex: 0 0 55%;
        }

        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .answer {
            font-weight: normal;
            font-size: 13px;
            line-height: 22px;

            span {
            }

            .default {
                display: flex;
                justify-content: space-between;

                .edit {
                    display: none;
                }
            }
        }
    }
}

.options {
    width: 500px;
    padding: 16px 0 16px 0;
    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 14px;

        .title {
            line-height: 24px;
            margin: 0 16px 0 24px;
            color: $color-grey-3;
        }

        &.search {
            color: $color-grey-3;
            padding-right: 24px;
            transition: all 200ms ease;

            &:hover {
                color: $color-grey-3;
            }

            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                cursor: default;
            }

            .input {
                width: 108px;
                border: none;
                outline: none;
                padding: 0;
                color: $color-grey-5;
                font-size: $size-s;

                &:focus {
                    color: $color-grey-7;
                }

                &::placeholder {
                    color: $color-grey-5;
                }
            }

            .not-searching {
                cursor: pointer;
            }
        }

        .icon {
            transform: translate(-3px, 2px);
        }
    }
}

.head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        display: flex;
        align-items: center;

        .project-name {
            width: 560px;
            padding: 0;
        }

        &.title {
            color: $color-grey-9;
            padding-right: 24px;
            transition: all 200ms ease;

            // &:hover {
            //     color: $color-grey-7;
            // }

            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
            }

            .input {
                width: 560px;
            }
        }
    }

    .right {
        display: flex;

        .share {
            display: flex;
            align-items: center;
            margin-right: 20px;

            span {
                margin-right: 8px;
            }
        }
    }
}

* {
    font-family: Gotham;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.48861V9C1 9.55229 1.44772 10 2 10H10C10.5523 10 11 9.55228 11 9V5.48861M6 1V6.38861M6 6.38861L3.82051 3.88228M6 6.38861L8.17949 3.88228M2.75824 8.51899H9.24176" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'Download',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 12
        },
        height: {
            type: [Number, String],
            default: 11
        }
    }
};
</script>
 <template>
    <div :class="['textGroup']">
        <label :for="name">
            <slot />
        </label>
        <textarea
            ref="textarea"
            :placeholder="placeholder"
            :disabled="disabled"
            :name="name"
            :maxlength="maxLengthValue"
            :rows="rows"
            :cols="cols"
            :class="['textArea', state, size]"
            :style="`--rows: ${rows}`"
            @input="returnAnswer"
            @blur="blur"
            v-model="answer"
            data-testId="textarea"
            @keydown.enter="handleEnter"
            @keyup="setHeight"
        />
        <q-tag v-if="showUnsavedMessage" class="not-saved-tag" :class="answer !== returnedAnswer ? 'show' : ''" size="small" variation="default">Nog niet opgeslagen</q-tag>

        <div v-if="maxlength !== '0' && !disabled" class="max-characters-container">
            <div v-for="(number, index) in offsets" :key="index" class="numbers" :style="`--digit-offset: ${offsets[index]}`">
                <span class="number" v-for="key in 10" :key="key">{{key-1}}</span>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * textarea are generally used for interface actions.
 * Defaults to appearance that has white background with grey border.
 *
 */
export default {
    name: 'q-text',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         * Give name to textarea.
         */
        name: {
            type: String,
            default: null,
        },
        /**
         * Value.
         */
        value: {
            type: String,
            default: '',
        },

        /**
         * Give max length to textarea.
         */
        maxlength: {
            type: String,
            default: '300',
        },
        /**
         * Specify rows in textarea.
         */
        rows: {
            type: String,
            default: '4',
        },
        /**
         * Specify columns in textarea.
         */
        cols: {
            type: String,
            default: '30',
        },
        /**
         * Disables inputfield
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Change placeholder for input
         *
         */
        placeholder: {
            type: String,
            default: 'Vul hier iets in...',
        },
        /**
         * Manually trigger various states of the input.
         * `hover, focus, error, success`
         */
        state: {
            type: String,
            default: null,
            validator: (value) => {
                return value.match(/(hover|focus|error|success)/);
            },
        },
        /**
         * Size of the input
         * `small, medium`
         */
        size: {
            type: String,
            default: 'medium',
            validator: (value) => {
                return value.match(/(small|medium)/);
            },
        },
        /**
         * Show a Q-Tag when the inputted value hasn't been saved yet (before blur)
         */
        showUnsavedMessage: {
          type: Boolean,
          default: false
        },
        /**
         * If true, manually entering a new line is not possible
         */
        disableEnterKey: {
          type: Boolean,
          default: false
        },
        /**
         * If true, the input will be focused on mount
         */
        autofocus: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            answer: '',
            returnedAnswer: ''
        }
    },
    methods: {
        returnAnswer(val) {
            this.$emit('input', val.target.value);
        },
        blur(val) {
            this.$emit('blur', val.target.value);
            this.returnedAnswer = this.answer
        },
        getOffset(number) {
            if(number == 0) return '0px'
            return `-${number * 24}px`
        },
        handleEnter(event) {
            if(this.disableEnterKey) event.preventDefault();
        },
        setHeight() {
            const element = this.$refs.textarea;
            
            element.style.height = 'auto';
            element.style.height = element.scrollHeight+3+'px';
        }
    },
    created() {
        this.answer = this.value || ''
        this.returnedAnswer = this.value;
    },
    mounted() {
        this.setHeight();

        const element = this.$refs.textarea;
        if(this.autofocus) element.focus();
    },
    watch: {
        value: function() {
            this.answer = this.value;
        }
    },
    computed: {
        offsets: function() {
            const answer = this.answer || '';
            const availableChars = this.maxlength - answer.length;
            const maxCharsLength = this.maxlength.toString().length;
            const stringLength = availableChars.toString().length;

            const array = [];
            array.length = maxCharsLength;

            for(let i = 0; i < maxCharsLength; i++) {
                const digitExists = maxCharsLength - i <= stringLength;
                if(!digitExists) {
                    array[i] = '24px';
                    continue
                }

                const newDigit = parseInt(availableChars.toString().substr(i - (maxCharsLength - stringLength), 1));
                array[i] = this.getOffset(newDigit)
            }

            return array
        },
        maxLengthValue: function() {
            if(!parseInt(this.maxlength)) return 10000000
            return parseInt(this.maxlength)
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.textArea {
    @include reset;
    will-change: transform;
    transition: all 0.2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-weight: $weight-normal;

    font-family: $font-text;
    line-height: $line-height-m;
    text-decoration: none;
    font-size: $size-m;

    position: relative;
    color: $color-grey-9;
    align-items: center;
    justify-content: center;

    border-radius: $radius-default;
    background: white;
    cursor:text;
    border: 1px solid $color-grey-5;

    &::placeholder,
    &.placeholder {
        color: $color-grey-7;
        font-size: $size-m;
    }

    &:focus,
    &.focus {
        background: white;
        border: 1px solid $color-primary;
        box-shadow: none;
        outline: 0;
    }

    &:hover,
    &.hover {
        border: 1px solid $color-primary;
        transition: border 0.3 ease-in;
    }

    &.error {
        border: 1px solid $color-red;
    }
    &.blank {
        border: none;
        color: $color-grey-5;
    }
}

textarea {
    position: relative;
    border: 1px solid #e5e5e5;
    background: none;
    border-radius: 4px;
    resize: none;
    overflow: hidden;
    width: 100%;

    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    
    &.small {
        padding: 4px $space-xs;
        min-height: calc(var(--rows) * 24px + (4px * 2));
    }
    &.medium {
        padding: 9px 10px;
        min-height: calc(var(--rows) * 24px + 18px);
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-primary;
        border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-blue-dark;
    }
}

.textGroup {
    font-size: 13px;
    line-height: 24px;
    color: $color-grey-7;
    position: relative;
    isolation: isolate;

    label {
        font-family: $font-text;
        font-size: $size-xs;
        font-weight: $weight-semi-bold;

        &:empty {
            display: none;
        }
    }

    .not-saved-tag {
        position: absolute;
        right: 0;
        top: 0;

        &.show {
            top: -30px;
        }
    }

    .max-characters-container {
        position: absolute;
        display: flex;
        height: 24px;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        font-weight: 400;
        color: #8a8a8a;
        user-select: none;
        pointer-events: none;
        overflow: hidden;

        .numbers {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: var(--digit-offset);
            transition: .15s ease;

            .number {
                min-height: 24px;
                max-height: 24px;
            }
        }

    }
}
</style>

<docs>
  ```jsx
  <div>
  
    <q-text placeholder="Schrijf een korte motivatie..." name="motivatie">Motivatie</q-text>
    <q-text placeholder="Disabled" disabled>Disabled</q-text>
    
    <q-text placeholder="Hover..." state="hover">Hover</q-text>
    <q-text placeholder="Focus..." state="focus">Focus</q-text>
    
  </div>
  ``` 
</docs>

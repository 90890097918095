<template>
    <div class="indicator-answer">

        <div class="name">
            Projecttemplates
        </div>

        <div v-if="isEditing" class="answer">
            <div class="input">
                <span>
                    <q-dropdown
                        v-model="answer"
                        multiple
                        :options="templateOptions"
                        class="dropdown-input"
                        size="small"
                        :disabled="loadingTemplates || templatesSet"
                        @blur="saveDropdown"
                        @input="handleDropdownInput"
                        data-testid="test-dropdown"
                        ref="dropdown"
                        autocomplete
                        autofocus
                    ></q-dropdown>
                </span>
            </div>
        </div>
        <div v-else class="value" :class="{ loading: loading }">
            <div>
                <span
                    v-if="displayAnswer"
                    :class="['display', 'disabled']">
                    {{ displayAnswer }}
                </span>
                <q-button
                    v-else-if="canEditIndicator"
                    variation="primary"
                    size="small"
                    @click="edit"
                >
                    Projecttemplates selecteren
                </q-button>
                <span v-else class="no-value">Niet ingevuld</span>
            </div>
            <q-tooltip v-if="displayAnswer" position="top">
                <template #tooltip>
                    <p>U mag dit veld niet aanpassen</p>
                </template>
                <q-icon class="info" type="lock-closed" color="grey" width="10px"></q-icon
            ></q-tooltip>
        </div>
    </div>
</template>

<script>
import { extractError, getMoney, getDate, isValidAnswer } from '@/assets/js/utils';
import _ from 'lodash';
import gql from 'graphql-tag';

export default {
    name: 'IndicatorAnswer',
    props: {
        isEditing: {
            type: Boolean
        },
        project: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            answer: null,
            duplicateCheckbox: false,
            indicator: { 
                protectionLevel: 'private_shared',
                id: 'project-template',
                componentId: 'project-template'
            },
            loadingTemplates: false,
            templates: [],
            templateOptions: [],
            allLabelsOption: { 
                value: 'all_labels', 
                label: 'Alle prestaties' 
            },
            templatesSet: false
        };
    },
    methods: {
        async handleDropdownInput() {
            const allLabelsSelected = this.answer.includes(this.allLabelsOption.value);
            if(allLabelsSelected) this.answer = [this.allLabelsOption.value];
            
            this.templateOptions = this.templateOptions.map(templateOption => {
                if(templateOption.value === this.allLabelsOption.value) return templateOption
                else templateOption.disabled = allLabelsSelected;
                return templateOption
            });
        },
        saveDropdown() {
            if (!this.answer || !this.answer.length) return this.$emit('edit', null);

            this.$emit('startLoading', this.indicator);

            this.templatesSet = true;
            this.$apollo.mutate({
                mutation: gql`
                    mutation($id: String!, $templateIds: [String], $categories: [JSON]) {
                        project_setTemplates(id: $id, templateIds: $templateIds, categories: $categories) {
                            id
                            name
                            description
                            templates {
                                id
                                name
                            }
                        }
                    }
                `,
                variables: {
                    id: this.project.id,
                    templateIds: this.answer, 
                    categories: this.project.settings.categories
                }
            })
            .then(result => {
                this.$emit('stopLoading', this.indicator);
                const chosenTemplates = result.data.project_setTemplates.templates;

                this.$emit('projectTemplateChosen', chosenTemplates)
            })
            .catch(error => {
                this.$emit('stopLoading', this.indicator);
                extractError(error)
            })
        },
        edit() {
            this.$emit('edit', this.indicator);
        },
        getTemplates() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            this.loadingTemplates = true;

            this.$apollo.query({
                query: gql`
                    query GetTemplates($where: JSON!) {
                        projecttemplates(where: $where) {
                            id
                            name
                        }
                    }
                `,
                variables: {
                    where: {
                        AND: [
                            { organisationId },
                            { name__nin: ['CROW_default_template'] },
                            { status: 'active' }
                        ]
                    }
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.loadingTemplates = false;
                this.templates = result.data.projecttemplates;

                const templateOptions = result.data.projecttemplates
                    .map(template => {
                        return {
                            value: template.id,
                            label: template.name
                        }
                    })

                this.templateOptions = [ this.allLabelsOption, ...templateOptions ];
            })
        }
    },
    computed: {
        canEditIndicator: function() {
            return this.$parent.canEditIndicator(this.indicator);
        },
        displayAnswer: function() {
            if (!this.project.templates || this.project.templates.length === 0) return null

            return this.project.templates
                .filter(template => template.name !== 'CROW_default_template')
                .map(template => template.name).join(', ');
        }
    },
    created() {
        this.getTemplates();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.el-date-table td.current span {
    background-color: #ff4040 !important;
}

.datepicker {
    width: 100%;
}

.indicator-answer:hover {
    background-color: lighten($color-grey-3, 5%);
    position: relative;

    .value {
        .edit {
            min-width: 62px;
            display: inherit;
            position: absolute;
            right: 12px;
            background-color: lighten($color-grey-3, 5%);
        }
        .tooltip {
            display: inherit;
        }
    }
}

.indicator-answer {
    display: flex;

    margin-left: -8px;
    width: 100%;

    align-items: center;

    min-height: 35px;

    border-radius: 4px;
    margin: -4px -12px;
    padding: 4px 12px;

    .duplicate-checkbox {
        margin-left: calc(-26px);
        transition: margin .2s var(--animation-stagger);

        &.show {
            margin: 0;
        }
    }

    .name {
        min-width: 45%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .answer {
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        font-size: $size-s;

        width: 100%;

        display: flex;
    }

    .value {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex: 0 0 55%;
        width: 100%;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        .edit {
            display: none;
        }

        .tooltip {
            display: none;
        }

        .disabled {
            color: lighten($color-grey-7, 20%);
        }

        .no-value {
            color: $color-grey-5;
        }
    }

    .input {
        width: 100%;
    }

    .answer-chip-wrapper {
        margin-left: -6px;
        padding: 4px 0px;
        .answer-chip {
            display: inline-block;
            border-radius: 10px;
            padding: 0px 8px;
            margin-left: 4px;
            margin-bottom: 2px;
            margin-top: 2px;
            font-size: 0.8rem;
            color: $color-primary;
            background-color: #cef1f4;
        }
    }
}

.answer-input {
    width: calc(100% - 16px);
    height: 28px;
    border: 1px solid #00a1ae;
    border-radius: 4px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: white;
    outline: none;
}

.dropdown-input {
    width: 100%;
}
</style>

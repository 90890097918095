<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section paddingBottom="10" paddingTop="40" hPadding="40" borderBottom>
                <h2>E-mail</h2>
                <p class="py-s">
                    Uw e-mail is gevalideerd 
                </p>
            </q-section>
            <q-section class="center flex-end" vPadding="24" hPadding="40">
                <q-button to="/">Inloggen</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { VALIDATE_USER_EMAIL } from '@/graphql/mutations';
import { extractError } from '@/assets/js/utils'

export default {
    name: 'RegisterValidate',
    components: {
        AuthBase
    },

    data() {
        return {
            token: this.$route.params.token,
        };
    },
    mounted() {
        if (!this.token) {
            this.$router.push('/login');
            return;
        }
        this.$apollo
            .mutate({
                mutation: VALIDATE_USER_EMAIL,
                variables: {
                    token: this.token
                }
            })
            .then(response => {
            
                this.success = true;
            })
            .catch(err => {

                this.$store.commit('notify', extractError(err))
            });
    }
};
</script>

<template>
  <div class="inputContainer">
    <input
      v-for="n in Number(amount)"

      :id="n"
      :key="n"
      class="input"
      type="text"
      :placeholder="placeholder"
      @input="handleInput($event, n - 1)"
      @keyup="onkey($event)"
    />
  </div>
</template>

<script>

const allowedInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export default {
  name: "q-2fa",
  status: "prototype",
  release: "0.0.1",
  props: {
    /**
     *Specifies amount of inputfields to be used.
     */
    amount: {
      type: String,
      default: "5",
    },
    /**
     *Adds placeholder in input field (not used normally)
     */
    placeholder: {
      type: String,
      default: null,
    },

    /**
     *Max length of input field characters to use autotab, always 1.
     */
    maxlength: {
      type: String,
      default: "1",
    },
  },

  methods: {
    handleInput(e, index) {
      if(!allowedInput.includes(e.data)) {
        e.target.value = '';
        return;
      }

      if (e.target.value.length > 1) e.target.value = e.target.value.charAt(e.target.value.length -1)


      this.result[index] = e.target.value;
      const completeInput = this.result.join("");

      this.$emit("input", completeInput);

      if (e.target.nextElementSibling) e.target.nextElementSibling.focus()

      if (index == parseInt(this.amount) - 1) this.$emit('onLast', e.target.value);
    },
    onkey(e) {

      if (e.code == 'Backspace') {

        if (e.target.previousElementSibling) {
          e.target.value = '';
          e.target.previousElementSibling.focus()
        }
      }
    }
  },

  data() {
    return {
      result: [],
    };
  },
  mounted() {

    document.getElementById('1').focus();


  }
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";

@import "../assets/style/fonts/fonts.css";

.inputContainer {
  font-family: $font-text;
  font-size: 34px;
  color: $color-grey-9;

  input[type="text"] {
    height: 60px;
    width: 44px;
    border: 1px solid $color-grey-5;
    border-radius: 4px;
    margin: 5px;
    font-size: 34px;
    text-align: center;
    font-family: $font-text;
    color: $color-grey-9;
    &:hover {
      border-color: $color-primary;
    }

     color: transparent;
    text-shadow: 0 0 0 $color-grey-9;
  }

  .input:focus {
    outline-width: 0;
    border: 2px solid $color-primary;

  }
}
</style>

<docs>
  ```jsx
  <div>
  <q-2fa  amount=8 > </q-2fa>
    
  </div>
  ```
</docs>

<template>
    <svg :width="width" :height="height" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.97949 1L12.1716 11M1.97949 11L12.1716 1" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'Cancel',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: [Number, String],
            default: 8,
        },
        height: {
            type: [Number, String],
            default: 9,
        },
    },
};
</script>

<template>
  <div class="q-pill" :class="{ interactive }">
        <q-tooltip 
            v-for="(item, key) in values" 
            :key="key" 
            :class="['item', size, key == 0 ? 'left' : key < values.length-1 ? 'center' : 'right', { interactive }]"
            position="top"
            :style="'--background-color:'+item.color"
            :disabled="!item.tooltip"
        >
            <span class="text">{{item.text}}</span>

            <template v-slot:tooltip>
                {{item.tooltip}}
            </template>
        </q-tooltip>
  </div>
</template>

<script>
export default {
    name: 'q-pill',
    props: {
        /**
         * array of every part of the pill. structure:
         *  - text: text as shown in the pill
         *  - color: color of that part of the pill
         */
        values: {
            type: Array,
            required: true
        },
        /**
         * The size of the pill. Defaults to medium.
         * `small, medium, large`
         */
        size: {
            type: String,
            default: 'medium',
            validator: (value) => value.match(/(small|medium|large|)/),
        },
        tooltip: {
            type: String,
            default: null,
        },
        /**
         * If true, the pills scale up on hover
         */
        interactive: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/style.scss";

.text {
    font-size: 9px;
    font-weight: 500;
    transition: .5s ease;
}

.item {
    min-width: 15px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    background-color: var(--background-color);
    transition: .5s ease;

    &:first-of-type {
        transform-origin: 65% center;
    }
    &:last-of-type {
        transform-origin: 35% center;
    }

    &.interactive:hover {
        scale: 1.3;
        z-index: 1;
        transition: .4s cubic-bezier(.51,2,.45,1.08);

        .text {
            scale: 1.1;
            transition: .3s cubic-bezier(.51,2.5,.45,1.08) .1s;
        }
    }
}

.item:not(:first-child) {
    margin-left: 2px;
}

.q-pill {
    display: flex;
    border-radius: 10px;

    &.interactive:hover .item {
        border-radius: 10px;
        padding-inline: calc(var(--padding-inline) + 1.5px) !important;
    }

    .small {
        --padding-inline: 5px;
        padding: 0px var(--padding-inline);
        font-size: 9px;
    }
    .medium {
        --padding-inline: 5px;
        padding: 0px var(--padding-inline);
        font-size: 9px;

        &:first-child {
            padding-left: 8px;
            margin-right: 0.5px;
        }
        &:last-child {
            padding-right: 8px;
            margin-left: 0.5px;
        }

        &:not(:first-child):not(:last-child) {
            margin: 0px 0.5px;
        }
    }
    .large {

    }
}

.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

* {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $color-grey-9;
}

</style>
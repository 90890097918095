<template>
  <select class="select" @change="handleSelect">
      <option
      
      :placeholder="placeholder"
      selected>
          {{placeholder}}
      </option>
      <option 
      name="select"
      v-for="choice in choices" 
      v-bind:value="choice.value"
      :key="choice.value"
      
      >
      <div>
        <span>{{choice.name}}</span>
        <span>{{choice.value}}</span>
        <span>{{choice.address}}</span>
      </div>
      </option>
  </select>
</template>

<script>



export default {
  name: "q-select",
  status: "prototype",
  
  release: "0.0.1",
  props: {
    /**
     * Value prop, used for v-model
     */
    value: {
      type: String,
    },
   
    /**
     * Disables inputfield
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    choices:{
        type: Array,
    },
    
    placeholder:{
        type:String,
        default: "Maak uw keuze"
    },

    /**
     * Manually trigger various states of the input.
     * `hover, focus, error, success`
     */
    state: {
      type: String,
      default: null,
      
    },
    
  },
  methods: {
    handleSelect(event) {

        
        
      this.$emit("input", event.target.value);
    },
  },

  // computed: {
  //   iconType: function(){
  //     let result = this.icon;
  //     if (this.state === `error`){
  //       result = "danger"
  //     }
  //     else if(this.state === `success`){
  //       result = "check"
  //     }

  //     return result
  //   }
  // }
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

select {
  @include reset;
  //   @include stack-space($space-m);
  //   @include inline-space($space-xs);
  will-change: transform;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance:none;
  font-family: $font-text;
  line-height: $line-height-m;
  text-decoration: none;
  position: relative;
  color: $color-grey-7;
  align-items: center;
  justify-content: center;

  border-radius: $radius-default;
  background: white;
  cursor: text;
  border: 1px solid $color-grey-5;
  width: 100%;
  padding: 12px;
  padding-left: 8px;
  font-size: $size-m;

 &:hover{
   border: 1px solid $color-primary;
  }

 &:active{
   border: 1px solid $color-primary;
  }
   &:focus{
   border: 1px solid $color-primary;
  }
}

 

select-selected{
  background-color: $color-primary;
}
</style>

<docs>
  ```jsx
  <div>
  
<q-select :choices="[{value: `A`}, {value: `B`}]">
</q-select>

  </div>
  ``` 
</docs>

/**
 * This file contains project-related helper methods
 */

const PROJECT_PERMISSIONS = ['view', 'fill', 'admin'];

class ProjectAbility {
    constructor() {
        this.id = undefined;
        this.roles = [];
    }

    set(id, member, ability, isGeustOrganisation) {
        console.log(`%cinitialized new project specific ability object ${id}: ${member ? member.roles.join(', ') : ''}`, 'color:#84baec');

        this.id = id;
        this.roles = member ? member.roles : []
        this.ability = ability;
        this.isGeustOrganisation = isGeustOrganisation;
    }

    remove() {
        this.id = undefined;
        this.roles = [];
    }

    can(permission) {
        if (!this.validatePermission(permission)) return false;
        const { entity, action, projectPermission } = this.extractPermission(permission);

        if (!this.isGeustOrganisation && this.ability.get().can(action, entity)) return true;

        if (projectPermission === 'view') {
            return this.roles.includes('view') || this.roles.includes('fill') || this.roles.includes('admin');
        } else if (projectPermission === 'fill') {
            return this.roles.includes('fill') || this.roles.includes('admin');
        } else if (projectPermission === 'admin') {
            return this.roles.includes('admin');
        }
    }

    canView() {
        return this.roles.includes('view') || this.roles.includes('fill') || this.roles.includes('admin');
    }

    canFill() {
        return this.roles.includes('fill') || this.roles.includes('admin');
    }

    isAdmin() {
        return this.roles.includes('admin');
    }

    isGeust() {
        return this.isGeustOrganisation && this.roles.length === 0;
    }

    validatePermission(permission) {
        const fields = permission.split('__');

        if (fields.length !== 3) {
            console.error(
                `project-specific permission value '${permission}' is not valid. Should have pattern {projectPermission}__{entity}__{action}`
            );
            return false;
        }

        const projectPermission = fields[0];

        if (!PROJECT_PERMISSIONS.includes(projectPermission)) {
            console.error(
                `project-specific permission value '${projectPermission}' (extracted from '${permission}') is not valid, only ${PROJECT_PERMISSIONS.join(
                    ', '
                )} allowed`
            );
            return false;
        }

        return true;
    }

    extractPermission(permission) {
        const fields = permission.split('__');

        const projectPermission = fields[0];
        const entity = fields[1];
        const action = fields[2];

        return {
            projectPermission,
            entity,
            action
        };
    }
}

module.exports = {
    ProjectAbility
};

import Projects from '@/pages/app/Projects';
import Forms from '@/pages/app/Forms';
// Settings pages
import UserSettings from '@/pages/app/settings/UserSettings';
import integrationSettings from '@/pages/app/settings/integrationSettings';
import SecuritySettings from '@/pages/app/settings/SecuritySettings';
import SsoSettings from '@/pages/app/settings/SsoSettings';
import OrgSettings from '@/pages/app/OrgSettings';
import FormPage from '@/pages/app/form/Form';
import EnquetePage from '@/pages/app/enquete/EnquetePage';
import Project from '@/pages/app/project/Project.vue';
import ProjectSettings from '@/pages/app/project/projectmanagement/ProjectSettings';
import LabelManagement from '@/pages/app/management/LabelManagement';
import FormTemplates from '@/pages/app/management/FormTemplates';
import FormTemplateManagement from '@/pages/app/management/FormTemplateManagement';
import ProjectTemplates from '@/pages/app/management/ProjectTemplates';
import ProjectTemplateManagement from '@/pages/app/management/ProjectTemplateManagement';
// import ProjectUserSettings from '@/pages/app/project/ProjectUserSettings';
import ProjectReferenceLetter from '@/pages/app/project/referenceletter/ProjectReferenceLetter';
import Styles from '@/pages/app/templates/Styles';
import StyleFlow from '@/pages/app/templates/style/StyleFlow';
import TemplateTypes from '@/pages/app/templates/TemplateTypes';
import Templates from '@/pages/app/templates/Templates';
// Profile settings
import Profile from '@/pages/access/Profile';
import ProfileSettings from '@/pages/access/ProfileSettings';
import OrganisationSettings from '@/pages/access/OrganisationSettings';
import CreateProject from '@/pages/app/project/Create';
import AnalysisPage from '@/pages/app/AnalysisPage/AnalysisPage.vue';
import ReportDashboard from '@/pages/app/ReportPage/ReportDashboard.vue';
import ReportFlow from '@/pages/app/ReportPage/ReportFlow.vue';
import ReportPage from '@/pages/app/ReportPage/ReportPage.vue';
import Companies from '@/pages/app/Companies';
import Company from '@/pages/app/Company';
import Dashboard from '@/pages/app/Dashboard';

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects/mine',
        name: 'ProjectsMine',
        component: Projects,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects/finished',
        name: 'ProjectsFinished',
        component: Projects,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/forms',
        name: 'Forms',
        component: Forms,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: OrgSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/sso',
        name: 'SsoSettings',
        component: SsoSettings,
        meta: {
            requiresAuth: true,
            module: 'sso',
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/users',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/security',
        name: 'SecuritySettings',
        component: SecuritySettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/integrations',
        name: 'Integrations',
        component: integrationSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/styles',
        name: 'Styles',
        component: Styles,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/styles/:id',
        name: 'StyleFlow',
        component: StyleFlow,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/templates',
        name: 'TemplateTypes',
        component: TemplateTypes,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/settings/templates/:id',
        name: 'Templates',
        component: Templates,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/manage/labels',
        name: 'LabelManagement',
        component: LabelManagement,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manage/formtemplates',
        name: 'FormTemplates',
        component: FormTemplates,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manage/formtemplates/:id',
        name: 'FormTemplateManagement',
        component: FormTemplateManagement,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manage/projecttemplates',
        name: 'ProjectTemplates',
        component: ProjectTemplates,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manage/projecttemplates/:id',
        name: 'ProjectTemplateManagement',
        component: ProjectTemplateManagement,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/create',
        name: 'CreateProject',
        component: CreateProject
    },
    {
        path: '/analysis',
        name: 'Analysis',
        component: AnalysisPage
    },
    {   
        path: '/reports', 
        name: 'ReportsDashboard',
        component: ReportDashboard
    },
    {   
        path: '/reports/create',
        redirect: '/reports'
    },
    {   
        path: '/reports/:id/:state', 
        name: 'CreateReport',
        component: ReportFlow
    },
    {   
        path: '/reports/:id', 
        name: 'CreateReport',
        component: ReportPage
    },
    {
        path: '/projects/settings',
        name: 'ProjectsSettings',
        component: Projects,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/profile/settings',
        name: 'ProfileSettings',
        component: ProfileSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/profile/organisations',
        name: 'OrganisationSettings',
        component: OrganisationSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/forms/:id',
        name: 'FormPage',
        component: FormPage,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/enquetes/:id',
        name: 'EnquetePage',
        component: EnquetePage,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects/:id',
        name: 'Project',
        component: Project,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects/:id/settings',
        name: 'ProjectSettings',
        component: ProjectSettings,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/projects/:id/letter/:referenceLetterId',
        name: 'ProjectReferenceLetter',
        component: ProjectReferenceLetter,
        meta: {
            requiresAuth: true,
            requiresQfactProduct: true
        }
    },
    {
        path: '/companies',
        name: 'Companies',
        component: Companies
    },
    {
        path: '/companies/:id',
        name: 'Company',
        component: Company
    }
];

export default routes;

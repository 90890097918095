<template>
    <div class="options-menu" :class="label ? 'with-label' : ''" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <p v-if="label" class="label" :style="`color: ${color}`">{{ label }}</p>
        <q-icon v-if="iconWidth || iconHeight" class="menu-icon" :type="icon" :color="color" :width="iconWidth" :height="iconHeight"></q-icon>
        <q-icon v-else class="menu-icon" :type="icon" :color="color"></q-icon>
        <div :id="`dropdown-menu-${elementId}`" class="options-wrapper" :class="[showOptions ? 'show' : 'hide', moveUp ? 'move-up' : '', position]" :style="showOptions ? `max-height: ${getMaxHeight}px` : ''">
            <div 
                v-for="(option, index) in options" 
                :key="index" 
                class="option" 
                :class="[option.lastOfCategory ? 'underline' : '', moveUp ? 'move-up' : '']" 
                :style="`transition: transform .4s ease ${index*0.07}s, opacity .4s ease ${index*0.07}s, background-color .3s ease; ${option.color ? 'color: '+option.color : ''}`" 
                @click.stop="handleSelectOption(option)">
                <q-icon v-if="option.icon" class="icon" :type="option.icon" width="17" height="17" :color="option.color ? option.color : '#212529'"></q-icon>
                <p>{{ option.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-options',
    props: {
        /**
         *  [Option]
         *    name: String
         *    icon: String
         *    color: String
         *    lastOfCategory: Boolean
         */
        options: {
            type: Array,
            required: true
        },
        /**
         *  v-model, includes full option object
         */
        value: {
            type: Object,
            default: null
        },
        /**
         *  label to show before dropdown icon
         */
        label: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'options'
        },
        iconWidth: {
            type: String,
            default: ''
        },
        iconHeight: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: '#373836'
        },
        position: {
            type: String,
            default: 'right'
        }
    },
    data() {
        return {
            showOptions: false,
            elementId: Math.floor(Math.random() * 1000000000),
            moveUp: false,
            closeTimeout: null
        }
    },
    methods: {
        handleMouseOver() {
            this.showOptions = true;
            if(this.closeTimeout) clearTimeout(this.closeTimeout)
        },
        handleMouseLeave() {
            this.closeTimeout = setTimeout(this.handleClose, 300);
        },
        handleClose() {
            this.showOptions = false;
        },
        handleSelectOption(option) {
            this.$emit('input', option);
            this.showOptions = false;
        }
    },
    computed: {
        getMaxHeight() {
            const options = this.options.length;
            const lines = this.options.filter(option => option.lastOfCategory).length;
            const padding = 6;
            let maxHeight = options*34 + lines + padding;
            return maxHeight
        }
    },
    watch: {
        showOptions: function() {
            if(!this.showOptions) return
            const dropdown = document.getElementById(`dropdown-menu-${this.elementId}`);
            const dropdownPosition = dropdown.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            if(dropdownPosition.left + dropdownPosition.width > viewportWidth) dropdown.style.right = '0px';
            if((dropdownPosition.top + this.getMaxHeight) > viewportHeight) this.moveUp = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.options-menu {
    position: relative;
    display: flex;
    align-items: center;
    cursor: default;

    &:hover .menu-icon {
        transform: scale(1.1);
    }

    .label {
        font-weight: 500;
        margin-right: 5px;
    }
    .icon {
        padding: 2px;
        transition: transform .3s ease;
    }
    
    .options-wrapper {
        --space: 22px;
        z-index: 100;
        position: absolute;
        top: var(--space);
        overflow: hidden;
        width: auto;

        &.show {
            padding: 6px 0;
            border: 1px solid #DEE2E6;
            border-radius: 4px;
            background-color: #ffffff;
            transition: max-height .6s ease;

            .option {
                transform: translateY(0);
                opacity: 1;
            }
        }
        &.hide {
            max-height: 0px;

            .option {
                transform: translateY(-150%);
                opacity: 0;

                &.move-up {
                    transform: translateY(150%);
                }
            }
        }
        &.move-up {
            transform: translateY(calc(-100% - var(--space)));
        }

        &.left {
            translate: -90% 0;
        }

        .option {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 6px 20px 6px 12px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #eff2f5;

                .icon {
                    transform: scale(1.1);
                }
                p {
                    transform: translateX(2px);
                }
            }

            &.underline {
                margin-bottom: 1px;

                &:before {
                    content: '';
                    position: absolute;
                    width: 90%;
                    height: 1px;
                    background-color: #DEE2E6;
                    bottom: -1px;
                }
            }

            .icon {
                transition: .2s ease;
            }

            p {
                font-weight: 500;
                white-space: nowrap;
                transition: transform .2s ease;
            }
        }
        
    }
}
</style>

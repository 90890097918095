<template>
    <svg :width="width" :height="height" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_331_3640" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5712 0.785993H10.2857C10.2854 0.351852 9.9334 0 9.4992 0H6.88176C6.44755 0 6.09552 0.351852 6.09524 0.785993H3C1.34315 0.785993 0 2.12914 0 3.78599V9.1905C0 10.8474 1.34315 12.1905 3 12.1905H13C14.6569 12.1905 16 10.8474 16 9.1905V3.78599C16 2.37522 15.0262 1.19188 13.7141 0.871504V0.393322H12.5712V0.785993Z"/>
        </mask>
        <path d="M10.2857 0.785993L9.28572 0.786646L9.28637 1.78599H10.2857V0.785993ZM12.5712 0.785993V1.78599H13.5712V0.785993H12.5712ZM6.09524 0.785993V1.78599H7.09459L7.09524 0.786646L6.09524 0.785993ZM13.7141 0.871504H12.7141V1.65671L13.4769 1.84296L13.7141 0.871504ZM13.7141 0.393322H14.7141V-0.606678H13.7141V0.393322ZM12.5712 0.393322V-0.606678H11.5712V0.393322H12.5712ZM10.2857 1.78599H12.5712V-0.214007H10.2857V1.78599ZM9.4992 1C9.38134 1 9.28579 0.9045 9.28572 0.786646L11.2857 0.785341C11.2851 -0.200795 10.4855 -1 9.4992 -1V1ZM6.88176 1H9.4992V-1H6.88176V1ZM7.09524 0.786646C7.09516 0.9045 6.99961 1 6.88176 1V-1C5.89548 -1 5.09588 -0.200795 5.09524 0.785341L7.09524 0.786646ZM3 1.78599H6.09524V-0.214007H3V1.78599ZM1 3.78599C1 2.68142 1.89543 1.78599 3 1.78599V-0.214007C0.790862 -0.214007 -1 1.57685 -1 3.78599H1ZM1 9.1905V3.78599H-1V9.1905H1ZM3 11.1905C1.89543 11.1905 1 10.2951 1 9.1905H-1C-1 11.3996 0.790861 13.1905 3 13.1905V11.1905ZM13 11.1905H3V13.1905H13V11.1905ZM15 9.1905C15 10.2951 14.1046 11.1905 13 11.1905V13.1905C15.2091 13.1905 17 11.3996 17 9.1905H15ZM15 3.78599V9.1905H17V3.78599H15ZM13.4769 1.84296C14.3517 2.05656 15 2.8468 15 3.78599H17C17 1.90363 15.7007 0.327204 13.9513 -0.0999558L13.4769 1.84296ZM12.7141 0.393322V0.871504H14.7141V0.393322H12.7141ZM12.5712 1.39332H13.7141V-0.606678H12.5712V1.39332ZM13.5712 0.785993V0.393322H11.5712V0.785993H13.5712Z" :fill="color" mask="url(#path-1-inside-1_331_3640)"/>
        <ellipse cx="8.34779" cy="6.77284" rx="2.78261" ry="2.70912" :fill="color"/>
        <ellipse cx="8.3477" cy="6.7728" rx="2.08696" ry="2.03184" fill="white"/>
        <rect x="11.8262" y="2.70911" width="1.3913" height="1.35456" rx="0.677279" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Camera',
    props: {
        color: {
            type: String,
            default: '#672583',
        },
        width: {
            type: [Number, String],
            default: 16,
        },
        height: {
            type: [Number, String],
            default: 13,
        },
    },
}
</script>

<style>

</style>
<template>
    <span v-if="value" :class="['badge-container',type]">
        <slot />
        <span :class="['badge',type]" >
        {{value}}
        </span>
    </span>
</template>

<script>

/**
 * Badges are used to mark unread messages or other data.
 * Badges automaticaly attached if there are content inside.
 * if badges have no value they are hidden.
 */
export default {
    name: "q-badge",
    status: "prototype",
    release: "0.0.1",
    props: ['value'],
    data() {
        return {
            type: this.$slots.default ? 'attached' : 'standalone'
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.badge-container {
  position: relative;
  display: inline-block;

  &.standalone {
    position: relative;
    display: block;
  }
}
.badge {
    font-family: $font-text;
    font-weight: $weight-bold;
    font-size: $size-xs;
    line-height: $line-height-s;
    align-items: center;
    text-align: center;
    color: $color-white;
    border-radius: $size-ml;
    background: $color-orange;
    padding: 4px 7px;
    min-width: 8px;

    &.attached {
        position:absolute;
        top:-10px;
        right:-12px;
        border: 2px solid $color-white;
    }

  &.standalone {
    position: relative;
    top: 0px;
    right: 0px;
  }
}
</style>

<docs>
```jsx
<div>
    <q-badge value=""></q-badge>

    <br/>
    <q-badge value="12"></q-badge>

    <br/>
    <q-badge value="46">
        <q-button variation="primary" size="medium">Medium</q-button>
    </q-badge>
</div>
```
</docs>

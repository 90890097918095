<template>
    <div class="modal">
        <div class="body">
            <h2 class="pb-s">Prestaties toevoegen</h2>
            <p class="pb-m">
                Hier vindt je een overzicht van alle prestaties die je kunt toevoegen aan je project en die momenteel
                nog niet in je project staan.
            </p>
            <div class="table-wrapper">
                <q-table
                    noInnerBorder
                    :data="sortedotherLabels"
                    :columns="columns"
                    :loading="labelsLoading"
                    :loadingRows="5"
                    :maxRows="4"
                    @filterUpdated="filterUpdated"
                >
                    <template v-slot:row="{ row, column }">
                        <span v-if="column === 'selectItem'">
                            <q-checkbox @input="handleCheckLabel($event, row)"></q-checkbox>
                        </span>
                        <span v-else-if="column === 'label'">
                            <strong>{{ row.name }}</strong>
                        </span>
                        <span v-else-if="column === 'references'">
                            <q-tooltip position="bottom">
                                <template v-if="row.references.length" v-slot:tooltip>
                                    <div>
                                        <p
                                            class="indicator-name"
                                            v-for="indicator in row.references"
                                            :key="indicator.id"
                                        >
                                            {{ indicator.name }}
                                        </p>
                                    </div>
                                </template>
                                <p>{{ row.references.length }}</p>
                            </q-tooltip>
                        </span>
                        <span v-else-if="column === 'questions'">
                            <q-tooltip position="bottom">
                                <template v-if="row.questions.length" v-slot:tooltip>
                                    <div>
                                        <p
                                            class="indicator-name"
                                            v-for="indicator in row.questions"
                                            :key="indicator.id"
                                        >
                                            {{ indicator.name }}
                                        </p>
                                    </div>
                                </template>
                                <p>{{ row.questions.length }}</p>
                            </q-tooltip>
                        </span>
                    </template>
                </q-table>
            </div>
        </div>
        <div class="footer">
            <q-button variation="blank" @click="closePopup">Annuleren</q-button>
            <q-button variation="primary" @click="handleAddIndicators" :loading="addIndicatorsLoading" :disabled="indicatorIdsToBeAdded.length <= 0"
            >Toevoegen aan project</q-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'label-add-modal',
    props: {
        otherLabels: {
            type: Array,
            required: true,
        },
        addIndicatorsLoading: {
            type: Boolean,
            default: false,
        },
        projectTemplates: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            indicatorIdsToBeAdded: [],
            labelsLoading: false,
            indicators: []
        };
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        handleAddIndicators() {
            this.$emit('indicatorIdsToBeAdded', this.indicatorIdsToBeAdded);
        },
        handleCheckLabel(checked, label) {
            const indicatorIds = label.indicators.map(indicator => indicator.id);

            if(checked) this.indicatorIdsToBeAdded = [ ...this.indicatorIdsToBeAdded, ...indicatorIds ];
            else this.indicatorIdsToBeAdded = this.indicatorIdsToBeAdded.filter(indicatorId => !indicatorIds.includes(indicatorId))
        },
        filterUpdated(filter) {
            this.$emit('filterUpdated', filter);
        }
    },
    computed: {
        sortedotherLabels: function () {

            function compareLabelNames(firstLabel, secondLabel) {
                if (firstLabel.name < secondLabel.name) return -1;
                if (firstLabel.name > secondLabel.name) return 1;
                return 0;
            }
            return this.otherLabels.sort(compareLabelNames);
        },
        columns: function() { 
            return [
                {
                    field: 'selectItem',
                    label: '',
                    width: '20px',
                    align: 'center'
                },
                {
                    field: 'label',
                    label: 'Prestatie',
                    width: '150px'
                },
                {
                    field: 'references',
                    label: 'Actieve kenmerken',
                    align: 'center',
                    width: '60px'
                },
                {
                    field: 'questions',
                    label: 'Actieve vragen',
                    align: 'center',
                    width: '50px'
                },
                {
                    field: 'projectTemplateNames',
                    label: 'Projecttemplates',
                    width: '100px',
                    align: 'left',
                    filter: true,
                    showSearchInput: false,
                    filterType: 'checkbox',
                    filterOptions: this.projectTemplates.map(template => { return { label: template.name, value: template.id } })
                }
            ]
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    display: flex;
    flex-direction: column;
    max-width: 1000px;

    .body {
        .title {
            margin-bottom: 30px;
        }
        .about {
            margin-bottom: 30px;
        }
        .table-wrapper {
            padding-top: 20px;
            max-height: calc(50vh - 80px);
            min-height: calc(50vh - 80px);
            width: 100%;
            overflow-y: scroll;
        }
        .items {
            .checkbox {
                width: 16px;
            }
        }
        .indicator-name {
            max-width: 40ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgb(222, 226, 230);
        padding-top: 20px;
    }
}

.projecttemplate {
    font-family: 'Gotham';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}
</style>

<template>
  <div
    class="q-card"
    :class="{
      'empty-card': tile.empty,
      'tile-loading': tile.loading,
      'not-finished': !tile.name && !tile.loading,
      disabled: tile.empty || tile.loading,
    }"
  >
    <div class="card-content" @click="handleReportSelect">
      <div v-if="!tile.empty" class="head">
        <div>
          <p class="label">{{ tile.label }}</p>
          <p
            class="name"
            :class="[
              Boolean(!tile.name) ? 'default' : '',
              tile.loading ? 'loading' : 'show',
            ]"
          >
            {{ tile.name ? tile.name : "Rapportage nog niet afgerond" }}
          </p>
        </div>
        <div class="logo" v-if="hasCrowReport || isFormTemplatesReportDefault">
          <img
            class="folded-img"
            src="/statics/img/Qfact-logo-small.png"
            alt=""
            @click="to('/')"
          />
        </div>
      </div>
      <div v-if="!tile.empty" class="footer">
        <div class="left footer-info" :class="tile.loading ? 'loading' : 'show'">
          <p>Laatst gewijzigd</p>
          <p>
            {{
              new Date(tile.lastUpdated).toLocaleString("nl-NL", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
            }}
          </p>
        </div>
        <div
          v-if="tile.owners && tile.owners.length == 1"
          class="right owner"
          :class="tile.loading ? 'loading' : 'show'"
        >
          <div class="footer-info">
            <p>Eigenaar</p>
            <p class="name">
              {{ tile.owners[0].firstName }} {{ tile.owners[0].lastName }}
            </p>
          </div>
          <div class="avatars">
            <q-avatar
              v-for="(owner, index) in tile.owners"
              :key="index"
              size="small"
              class="avatar"
              :style="`z-index: ${10 - index}`"
              >{{ getUserInitials(owner.firstName, owner.lastName) }}</q-avatar
            >
          </div>
        </div>
        <div v-else-if="tile.owners && tile.owners.length > 1" class="owners-avatars">
          <q-avatar
            v-for="(owner, index) in tile.owners"
            :key="index"
            size="small"
            class="avatar"
            :style="`z-index: ${10 - index + 1}`"
            >{{ getUserInitials(owner.firstName, owner.lastName) }}</q-avatar
          >
        </div>
      </div>
    </div>

    <q-options
      v-if="
        !tile.empty &&
        !tile.loading &&
        canEdit &&
        !hasCrowReport &&
        !isFormTemplatesReportDefault
      "
      class="options"
      :options="options"
      @input="handleSelectOption"
      color="#C4C4C4"
    ></q-options>
  </div>
</template>

<script>
import { userInitials } from "@/assets/js/utils.js";

export default {
  name: "tile",
  props: {
    /**
     *  Tile
     *    label: String
     *    name: String
     *    lastUpdated: Int
     *    owners: [User]
     *    loading: Boolean
     */
    tile: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasCrowReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      organisationId: this.$store.getters.getCurrentOrganisation.id,
      options: [
        // {
        //     name: 'Naam wijzigen',
        //     icon: 'pen',
        // },
        // {
        //     name: 'Eigenaar wijzigen',
        //     icon: 'people',
        //     lastOfCategory: true,
        // },
        // {
        //     name: 'Downloaden',
        //     icon: 'download',
        // },
        // {
        //     name: 'Dupliceren',
        //     icon: 'duplicate',
        //     lastOfCategory: true,
        // },
        {
          name: "Aanpassen",
          icon: "pen",
          lastOfCategory: true,
        },
        {
          name: "Verwijderen",
          icon: "delete",
          color: "red",
        },
      ],
    };
  },
  methods: {
    getUserInitials(firstName, lastName) {
      return userInitials(firstName, lastName);
    },
    handleSelectOption(option) {
      if (option.name == "Verwijderen") this.handleDeleteSelection();
      if (option.name == "Aanpassen") return this.$emit("editReport", this.tile);
    },
    handleDeleteSelection() {
      this.$emit("deleteReport", this.tile);
    },
    handleReportSelect() {
      this.$emit("click");
    },
  },
  computed: {
    canEdit: function () {
      const { id: userId } = this.$store.getters.getUser;

      const thisUser = this.tile.users.find((user) => user.user.id === userId);

      if (!thisUser) return false;

      const hasOnlyViewPermission = thisUser.permissions.indexOf("view") !== -1;

      return !hasOnlyViewPermission;
    },
    isFormTemplatesReportDefault: function () {
      return this.tile.name === "Rapportage formulieren";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/qds/assets/style/_variables.scss";

$base-color: $color-grey-1;
$shine-color: #ececec;
$animation-duration: 1.4s;

.q-card {
  position: relative;
  min-width: 400px;
  min-height: 200px;
  padding: 18px 24px;
  transition: 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  &.tile-loading {
    border: none;
    // box-shadow: none;
    // background-color: $color-grey-1;
  }

  &:hover {
    scale: 1.01;
    z-index: 1;
    box-shadow: 0px 4px 12px -4px rgba(128, 128, 128, 0.3);
  }

  &:active {
    scale: 0.99;
  }

  &.not-finished {
    background-color: $color-grey-1;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .folded-img {
        padding-inline: 13px;
        padding-block: 6px 6px;
        height: 35px;
        cursor: pointer;
      }

      p {
        color: $color-grey-9;
        font-size: 15px;
        font-weight: 500;
      }

      .label {
        color: #00a1ae;
      }

      .name {
        user-select: all;
        &.default {
          color: $color-grey-7;
        }
      }

      .logo {
        margin-right: -13px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        width: 35%;
      }
      .right {
        width: 65%;
      }

      .footer-info {
        display: flex;
        flex-direction: column;

        p {
          font-size: 14px;
        }
        p:first-of-type {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: -4px;
        }
      }
      .owner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 14px;
        text-align: right;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .owners-avatars {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;

        .avatar {
          margin-right: -10px;
          border-style: solid;
          border-width: thin;
          border-color: #00a1ae;
          color: #1d2443;
          font-size: medium;
        }
      }
    }
  }

  .options {
    position: absolute;
    top: 20px;
    right: 16px;
  }
}

.empty-card {
  border: none;
  box-shadow: none !important;
  cursor: default;
}

.loading {
  position: relative;
  border: none;
  box-shadow: none !important;

  &:before {
    content: "";
    z-index: 12;
    position: absolute;
    inset: 0;
    background: linear-gradient(
      90deg,
      $base-color 0%,
      $base-color 30%,
      $shine-color 50%,
      $base-color 60%
    );
    background-size: 200%;
    background-position: -90% 0;
    animation: shine-lines $animation-duration infinite ease-out;
    border-radius: 6px;
  }
}

.show {
  position: relative;

  &:before {
    content: "";
    z-index: 12;
    position: absolute;
    inset: 0;
    background-color: $base-color;
    background-size: 200%;
    animation: reveal 0.25s var(--animation-delay) forwards ease-out;
    border-radius: 6px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -90% 0;
  }
}

@keyframes reveal {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(100% 1%, 100% 0, 100% 100%, 100% 100%);
  }
}
</style>

<template>
  <div class="documents-panel">
        <div class="documents">
            <div :class="['document', canDownload ? 'can-download' : '']" v-for="document, key in documents" :key="document.id">
                <input v-if="editingDocument && document.id === document.id" class="document-name q-clear-input" :value="document.name" placeholder="Documentnaam" />
                <span v-else class="document-name">{{key + 1}}. {{document.name}}</span>
                <q-options v-if="!editingDocument" :options="documentOptions" color="#C4C4C4" position="left" @input="handleSelectOption(document, $event)"></q-options>
                <q-button v-else size="small" variation="blank" @click="handleUpdateDocument(document)">Opslaan</q-button>
            </div>
        </div>
        <div class="download">
            <UploadInput @onSuccess="onSuccess" @onError="onError"></UploadInput>
        </div>

        <q-popup v-if="deletingDocument" :showCloseButton="false" width="40%">
            <div class="popup-delete">
                <div class="popup-header">
                    <h3>Weet je zeker dat je dit document wilt verwijderen?</h3>
                </div>
                <div class="popup-buttons">
                    <q-button variation="blank" @click="deletingDocument = null">Annuleren</q-button>
                    <q-button variation="danger" @click="handleDeleteDocument(deletingDocument)">Document verwijderen</q-button>
                </div>
            </div>
        </q-popup>
  </div>
</template>

<script>
import { GET_DOCUMENT_URL } from '../graphql/queries'
import { extractError } from '../assets/js/utils';

import UploadInput from './UploadInput.vue'

export default {
    components: {
        UploadInput
    },
    props: {
        canUpload: {
            type: Boolean
        },
        canDownload: {
            type: Boolean
        },
        documents: {
            type: Array
        }
    },
    data() {
        return {
            editingDocument: null,
            deletingDocument: null
        }
    },
    computed: {
        documentOptions: function() {
            const downloadOption = {
                name: 'Downloaden',
                icon: 'download'
            }
            
            const options = [
                // {
                //     name: 'Naam wijzigen',
                //     icon: 'pen'
                // },
                {
                    name: 'Verwijderen',
                    icon: 'delete',
                    color: 'red'
                }
            ]

            if(this.canDownload) return [downloadOption, ...options]
            return options
        }
    },
    methods: {
        onSuccess (data) {
            this.$emit('onSuccess', data)
        },
        onError (error) {
            this.$emit('onError', error)
        },
        onDownload(document) {
            if (!this.canDownload) return;
            this.$apollo.mutate({
                mutation: GET_DOCUMENT_URL,
                variables: {
                    documentPath: document.fileName
                }
            })
            .then(result => {
                window.open(result.data.document_getUrl, '_blank')
            })
            .catch(error => {
                extractError(error)
            })
        },
        handleUpdateDocument(document) {

        },
        handleDeleteDocument(document) {
            this.$emit('deleteDocument', document);
            this.deletingDocument = null;
        },
        handleSelectOption(document, option) {
            if(option.name === 'Downloaden') {
                this.onDownload(document)
            }
            else if(option.name === 'Verwijderen') {
                this.deletingDocument = document
            }
            else if(option.name === 'Naam wijzigen') {
                this.editingDocument = document;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.popup-delete {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
    }
}

.download {
    padding-top: 10px;
}

.documents {
    padding-block: 10px;
}

.document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 5px 0;

    .document-name {
        word-break: break-all;
        font-size: 14px;
        flex-grow: 1;
    }
}

</style>
<template>
    <div class="question-overview">
        <div v-if="component.type === 'question'" class="q-card-small card">
            <div class="head">
                <h2>{{component.indicator.name}}</h2>
                <div class="smiley">
                    <q-tag :emoji="getSmiley"></q-tag>
                </div>                        
            </div>
            <q-question
                :id="component.indicator.id"
                :type="component.indicator.answerType"
                :options="customOptions"
                :settings="settings"
                :value="getQuestionValue()"
                width="auto"
                @input="saveValue"
                :disabled="formState !== 'revision' || !canEditForm"
                :useCrowComments="useCrowComments">
            </q-question>

            <!-- Footer is different for different stages | Default meta input -->
            <div v-if="showDefaultMetaInput" class="foot">

                <!-- Revision -->
                <div v-if="formState === 'revision'" class="satisfied-improvement revision">
                    <div class="left">
                        <div class="satisfied revision">

                            <div>
                                <q-tag emoji="happy" size="small"></q-tag>
                            </div>

                            <p :class="component.indicator.satisfied ? '' : 'greyed'">
                                {{component.indicator.satisfied ? component.indicator.satisfied : 'er is niets ingevuld'}}
                            </p>

                            <div v-if="changingSatisfied" class="buttons-container">
                                <div class="button save" @click="saveSatisfied()">
                                    <q-icon type="check"></q-icon>
                                </div>
                                <div class="button cancel" @click="changingSatisfied = false">
                                    <q-icon type="close" width="14" height="14"></q-icon>
                                </div>
                            </div>

                            <div v-else v-project-can="'fill__Value__set_satisfied'">
                                <q-button variation="light" size="xsmall" @click="changingSatisfied = true" >Wijzigen</q-button>
                            </div>

                        </div>

                        <q-text v-if="changingSatisfied" v-model="satisfied"></q-text>

                        <div class="improvement revision">

                            <div>
                                <q-tag emoji="slightly-happy" size="small"></q-tag>
                            </div>

                            <p :class="component.indicator.improvement ? '' : 'greyed'">
                                {{component.indicator.improvement ? component.indicator.improvement : 'er is niets ingevuld'}}
                            </p>

                            <div v-if="changingImprovement" class="buttons-container">
                                <div class="button save" @click="saveImprovement()">
                                    <q-icon type="check"></q-icon>
                                </div>
                                <div class="button cancel" @click="changingImprovement = false">
                                    <q-icon type="close" width="14" height="14"></q-icon>
                                </div>
                            </div>

                            <div v-else v-project-can="'fill__Value__set_improvement'">
                                <q-button variation="light" size="xsmall" @click="changingImprovement = true">Wijzigen</q-button>
                            </div>
                        </div>

                        <q-text v-if="changingImprovement" v-model="improvement"></q-text>

                        <div class="agreement revision">
                            <div>
                                <q-icon class="text-icon" type="shaking-hands" style="margin: 2px;"></q-icon>
                            </div>

                            <p :class="component.indicator.agreement ? '' : 'greyed'">
                                {{component.indicator.agreement ? component.indicator.agreement : 'er is niets ingevuld'}}
                            </p>
                            
                            <div v-if="changingAgreement" class="buttons-container">
                                <div class="button save" @click="saveAgreement()">
                                    <q-icon type="check"></q-icon>
                                </div>
                                <div class="button cancel" @click="changingAgreement = false">
                                    <q-icon type="close" width="14" height="14"></q-icon>
                                </div>
                            </div>

                            <div v-else v-project-can="'fill__Value__set_agreement'">
                                <q-button variation="light" size="xsmall" @click="changingAgreement = true">Wijzigen</q-button>
                            </div>
                        </div>

                        <q-text v-if="changingAgreement" v-model="agreement"></q-text>
                    </div>
                </div>

                <div v-else class="satisfied-improvement discussion">
                    <div class="left">
                        <div class="satisfied discussion">
                            <q-tag class="text-icon" emoji="happy" size="small"></q-tag>

                            <p :class="component.indicator.satisfied ? '' : 'greyed'">
                                {{component.indicator.satisfied ? component.indicator.satisfied : 'er is niets ingevuld'}}
                            </p>

                        </div>

                        <div class="improvement discussion">
                            <q-tag class="text-icon" emoji="slightly-happy" size="small"></q-tag>

                            <p :class="component.indicator.improvement ? '' : 'greyed'">
                                {{component.indicator.improvement ? component.indicator.improvement : 'er is niets ingevuld'}}
                            </p>
                        </div>

                        <div class="agreement discussion">
                            <q-icon class="text-icon" type="shaking-hands" style="margin: 2px;"></q-icon>

                            <p :class="component.indicator.agreement ? '' : 'greyed'">
                                {{component.indicator.agreement ? component.indicator.agreement : 'er is niets ingevuld'}}
                            </p>
                        </div>

                    </div>
                    <div class="right" v-project-can="'fill__Value__set_agreement'">
                        <q-button v-if="formState === 'discussion'" variation="ghost" size="small" @click="toggleSaveAgreement">
                            {{ fillingAgreement ? 'Afspraak opslaan' : component.indicator.agreement ? 'Afspraak aanpassen' : 'Afspraak maken'}}
                        </q-button>
                    </div>
                </div>

                <div class="agreement" v-if="fillingAgreement && formState === 'discussion'">
                    <p>Wat is er afgesproken?</p>
                    <q-text v-model="agreement"></q-text>
                </div>
            </div>
            <!-- CROW Meta input -->
            <div v-else-if="showCrowMetaInput" class="foot">
                <div v-if="formState === 'revision'" class="satisfied-improvement revision">
                    <div>
                        <div class="satisfied revision">
                            <p class="comment">Opmerking:</p>
                            <p :class="component.indicator.satisfied ? '' : 'greyed'">
                                {{component.indicator.satisfied ? component.indicator.satisfied : 'er is niets ingevuld'}}
                            </p>

                            <div v-if="changingSatisfied" class="buttons-container">
                                <div class="button save" @click="saveSatisfied()">
                                    <q-icon type="check"></q-icon>
                                </div>
                                <div class="button cancel" @click="changingSatisfied = false">
                                    <q-icon type="close" width="14" height="14"></q-icon>
                                </div>
                            </div>

                            <div v-else v-project-can="'fill__Value__set_satisfied'">
                                <q-button variation="light" size="xsmall" @click="changingSatisfied = true" >Wijzigen</q-button>
                            </div>

                        </div>

                        <q-text v-if="changingSatisfied" v-model="satisfied"></q-text>
                    </div>
                </div>

                <div v-else class="satisfied-improvement discussion">
                    <div>
                        <div class="satisfied discussion">
                            <p class="comment">Opmerking:</p>

                            <p :class="component.indicator.satisfied ? '' : 'greyed'">
                                {{component.indicator.satisfied ? component.indicator.satisfied : 'er is niets ingevuld'}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-else class="content">
            <content-block :component="component" :form="form"></content-block>
        </div>
    </div>
</template>

<script>

import ContentBlock from './ContentBlock'

import { SAVE_VALUE_AGREEMENT, SAVE_VALUE_SATISFIED, SAVE_VALUE_IMPROVEMENT, SAVE_VALUE_VALUE } from '../../../graphql/mutations'
import { extractError } from '../../../assets/js/utils'

import getSentence from './crowAnswersBoldMapping'

export default {
    components: {
        'content-block': ContentBlock
    },
    props: {
        component: Object,
        form: Object,
        /**
         * Is the status of the form, as set in Form.vue getFormComponent.
         * It determines wether agreement can be answered and satisfied/improvement can be changed.
         * 
         * If formState is 'discussion' you can set an agreement.
         * if formState is 'revision' you can change satisified and improvement fields
         */
        formState: String,
        useCrowComments: {
            type: Boolean,
            default: () => false
        },
        projectJwt: {
            type: String
        }
    },
    data() {
        return {
            settings: {
                satisfied: false,
                improvement: false,
            },
            fillingAgreement: false,
            changingAgreement: false,
            agreement: '',
            changingSatisfied: false,
            satisfied: '',
            changingImprovement: false,
            improvement: ''
        }
    },
    methods: {
        saveValue(val) {
            const { answer } = val;

            console.log('%c saving new answer: ' + answer, 'color: #bada55');

            this.component.indicator.answer = answer;

            const responseId = this.form.responseId
            const indicatorId = this.component.indicator.id;
            const componentId = this.component.id;
            const formId = this.form.id;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_VALUE,
                    variables: {
                        responseId,
                        componentId,
                        indicatorId,
                        formId,
                        value: answer,
                        organisationId,
                        projectJwt: this.projectJwt
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then(result => {
                    this.$emit('componentUpdated', this.component);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });


        },
        saveImprovement() {
            console.log('%c updating improvement to: ' + this.improvement, 'color: #00A1AEf');

            this.component.indicator.improvement = this.improvement;

            this.changingImprovement = false;

            const responseId = this.form.responseId
            const indicatorId = this.component.indicator.id;
            const componentId = this.component.id;
            const formId = this.form.id
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_IMPROVEMENT,
                    variables: {
                        responseId,
                        componentId,
                        indicatorId,
                        formId,
                        improvement: this.improvement,
                        organisationId,
                        projectJwt: this.projectJwt
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then(result => {
                    this.$emit('componentUpdated', this.component);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });
        },
        saveSatisfied() {
            console.log('%c updating satisfied to: ' + this.satisfied, 'color: #00A1AE');

            this.component.indicator.satisfied = this.satisfied;

            this.changingSatisfied = false;
            
            const responseId = this.form.responseId
            const indicatorId = this.component.indicator.id;
            const componentId = this.component.id;
            const formId = this.form.id
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_SATISFIED,
                    variables: {
                        responseId,
                        componentId,
                        indicatorId,
                        formId,
                        satisfied: this.satisfied,
                        organisationId,
                        projectJwt: this.projectJwt
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    this.$emit('componentUpdated', this.component);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });
        },
        toggleSaveAgreement() {
            if(!this.fillingAgreement) return this.fillingAgreement = true
            this.fillingAgreement = false;
            this.saveAgreement();
        },
        saveAgreement() {
            console.log('%c updating agreement to: ' + this.agreement, 'color: #00A1AE');

            this.changingAgreement = false;

            this.component.indicator.agreement = this.agreement;

            const responseId = this.form.responseId
            const indicatorId = this.component.indicator.id;
            const componentId = this.component.id;
            const formId = this.form.id
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_AGREEMENT,
                    variables: {
                        responseId,
                        componentId,
                        indicatorId,
                        formId,
                        agreement: this.agreement,
                        organisationId,
                        projectJwt: this.projectJwt
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then(result => {
                    this.$emit('componentUpdated', this.component);
                    this.$store.commit('notify', { type: 'success', message: 'Afspraak opgeslagen' })
                })
                .catch((err) => {
                    console.log(extractError(err))
                    this.$store.commit('notify', extractError(err))
                });
        },
        getQuestionValue() {
            const question = this.component.indicator;

            return {
                answer: question.answer,
                satisfied: question.satisfied || '',
                improvement: question.improvement || ''
            }
        },
        getNumber(component) {
            const components = this.form.components.filter(component => component.type == 'question')
            const index = components.map(component => component.indicator.id).indexOf(component.indicator.id);

            return index > -1 ? (index + 1) : ''
        },
    },
    computed: {
        customOptions: function() {
            return this.component.indicator.answerValues
                .map(value => {
                    return {
                        ...value,
                        label: getSentence(value.label)
                    }
                })
        },
        getSmiley: function () {
            const answer = this.component.indicator.answer;

            if (!answer || answer === 0 || typeof answer === 'undefined') return 'unknown'

            return answer < 5.5 ? 'sad' : answer < 7 ? 'neutral' : 'happy';
        },
        canEditForm: function() {
            return this.projectAbility.can('fill__value__set_value')
        },
        showDefaultMetaInput: function() {
            return !this.useCrowComments
        },
        showCrowMetaInput: function() {
            return this.useCrowComments
        },
    },
    created() {
        if (this.component.type === 'question') {
            this.agreement = this.component.indicator.agreement;
            this.satisfied = this.component.indicator.satisfied;
            this.improvement = this.component.indicator.improvement
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';

.question-overview {
    display: flex;
    justify-content: center;
}

.buttons-container {
    display: flex;
    gap: 8px;

    .button {
        display: grid;
        place-items: center;
        width: 28px;
        height: 28px;
        border-radius: 4px;
    
        cursor: pointer;

        &.save {
            background-color: #00A1AE;
            border: none;
            color: white;
        }

        &.cancel {
            background-color: $color-grey-5;
            border: none;
            color: white;
        }
    }
}

.greyed {
    color: rgb(165, 165, 165);
}

.card {
    width: 90%;
    margin: 8px 0 28px 0;

    h2 {
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        padding: 25px 25px 0 25px;
    }

    .smiley {
        padding: 25px 25px 0 25px;
    }

    .question {
        padding: 25px;
    }

    .head {
        display: flex;
        justify-content: space-between;
    }

    .foot {
        font-weight: normal;
        font-family: Gotham;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        min-height: 50px;
        border-top: 1px solid #DEE2E6;
        padding: 25px;

        .satisfied-improvement {

            &.discussion {
                display: flex;
                justify-content: space-between;
            }

            .left {
                flex: 1;

                .satisfied, .improvement, .agreement {
                    word-break: break-word;
                    margin: 5px 0;

                    display: flex;
                    align-items: flex-start;

                    .text-icon {
                        margin-top: 2px;
                    }

                    p {
                        display: inline;
                        padding: 0 10px 0 10px;
                        flex: 1;
                    }

                    .tag {
                        height: 22px;
                    }
                }
            }

            .right {
                display: flex;
                align-items: center;
            }

        }

        .agreement {
            margin-top: 15px
        }
    }
}

.comment {
    font-weight: 700;
}

// --------- responsiveness ---------

@media only screen and (max-width: 1380px) {
    .card {
        width: 100%;
    }
}

</style>
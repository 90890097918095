<template>
  <div class="finish-project">
    <q-zerostate
        class="zerostate"
        img="/statics/img/project_gradient.svg"
        title="Project afronden"
        :description="zerostateDescription">
        <div class="inputs">
            <div class="left">
                <span class="label">Gefactureerd bedrag</span>
                <q-input
                    inputType="currency"
                    v-model="sumAnswer"
                    size="medium"
                >
                </q-input>
            </div>
            <div class="right">
                <span class="label" style="margin-bottom:10px;">Werkelijke einddatum</span>
                <el-date-picker
                    class="datepicker"
                    v-model="dateAnswer"
                    placeholder="Kies een datum"
                    type="date"
                    size="large"
                    :default-value="new Date()">
                </el-date-picker>
            </div>
        </div>
        <div class="confirm">
            <q-button :disabled="!canAdminAgree.able" :loading="loading" @click="finishProject">Definitief afronden en publiceren</q-button>
            <div class="info" v-if="!canAdminAgree.able">
                <q-tooltip position="top">
                    <template v-slot:tooltip>
                        <p>{{ canAdminAgree.reason }}</p>
                    </template>
                    <div class="question-circle">
                        <q-icon type="QuestionCircle"></q-icon>
                    </div>
                </q-tooltip>
            </div>
        </div>
    </q-zerostate>
  </div>
</template>

<script>

import { PUBLISH_PROJECT, SAVE_VALUE_VALUE } from '../../../graphql/mutations';
import { extractError } from '@/assets/js/utils';

export default {
    name: 'PublishProject',
    data() {
        return {
            zerostateDescription: 'Vul onderstaande gegevens in om het project definitief af te ronden en te publiceren in de landelijke CROW Past Performance database',
            sumAnswer: null,
            dateAnswer: null,
            loading: false
        }
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        endDateReferenceId: {
            type: String
        },
        invoicedSumReferenceId: {
            type: String
        },
        formId: {
            type: String
        },
        responseId: {
            type: String
        },
        canAdminAgree: {
            type: Object,
            required: true
        }
    },
    methods: {
        finishProject() {
            let dateAnswer = this.dateAnswer;
            let sumAnswer = this.sumAnswer

            if (!sumAnswer || isNaN(sumAnswer) || !sumAnswer) {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Vul beide waarden in'
                })
                return;
            }

            dateAnswer = dateAnswer.getTime()
            sumAnswer = parseFloat(sumAnswer);
            
            const dateComponentId = 'component-project-end-date'
            const sumComponentId = 'component-invoiced-sum'
            
            const dateIndicatorId = this.endDateReferenceId;
            const sumIndicatorId = this.invoicedSumReferenceId;
            
            const responseId = this.responseId
            const formId = this.formId;

            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectJwt = this.getProjectJwt();
            const id = this.project.id;

            new Promise(async (resolve, reject) => {
                console.log('%cfinishing project', 'color: orange');

                this.loading = true;

                await this.$apollo
                    .mutate({
                        mutation: SAVE_VALUE_VALUE,
                        variables: {
                            componentId: dateComponentId,
                            responseId,
                            formId,
                            indicatorId: dateIndicatorId,
                            value: dateAnswer,
                            organisationId,
                            projectJwt
                        },
                        fetchPolicy: 'no-cache'
                    })
                    .catch(err => reject(err))

                await this.$apollo
                    .mutate({
                        mutation: SAVE_VALUE_VALUE,
                        variables: {
                            componentId: sumComponentId,
                            responseId,
                            formId,
                            indicatorId: sumIndicatorId,
                            value: sumAnswer,
                            organisationId,
                            projectJwt
                        },
                        fetchPolicy: 'no-cache'
                    })
                    .catch(err => reject(err))

                await this.$apollo
                    .mutate({
                        mutation: PUBLISH_PROJECT,
                        variables: {
                            id
                        }
                    })
                    .catch(err => reject(err))

                resolve();
            })
            .then(() => {
                this.loading = false;
                this.$emit('close');
                this.$emit('reload');
            })
            .catch(err => {
                this.loading = false;
                this.$store.commit('notify', extractError(err));
            });
        },
        getProjectJwt() {
            const project = JSON.parse(localStorage.getItem('projectJwt'));
            if(!project || project.id !== this.$route.params.id) return null
            return project.jwt
        },
    },
    computed: {
        isCrowAdmin: function() {
            return this.ability.get().can('admin', 'Crow');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';


.finish-project {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 1px;

    z-index: 100;
    background-color: white;

    padding: 100px 0 0 0;
}

.answer-input {
    width: calc(100% - 16px);
    height: 28px;
    border: 1px solid #00a1ae;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    background-color: white;
    outline: none;
}

.label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.inputs {
    display: flex;
    margin: 10px 0;
    
    .left, .right {
        display: flex;
        flex-direction: column;
    }

    .right { 
        margin-left: 10px;
    }
}

.confirm {
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
    
    .info {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    
}

</style>
<template>
    <div class="table-wrapper">
        <indicator-filter 
            v-if="filterBarType === 'version_1' && searchable" 
            v-model="filter"
            class="filter-bar" 
            :report="report" 
            :dropdownOptions="dropdownOptions"
            :showWarningTooltip="showWarningTooltip"
            @filterUpdated="handleFilterUpdated" 
            @searchUpdated="handleSearchUpdated"
        ></indicator-filter>
        <indicator-filter-bar :report="report" v-if="filterBarType === 'version_2' && searchable" class="filter-bar" @filterUpdated="handleFilterUpdated"></indicator-filter-bar>

        <contexts-wrapper 
            v-if="!loading" 
            :class="{ 'inline-padding': filterBarType === 'version_2' }"
            :report="report" 
            :reportPageFilterBar="reportPageFilterBar" 
            :contexts="computedContexts" 
            :disabled="disabled" 
            :checkable="true" 
            :contextLabel="filter.indicator" 
            :filter="filter" 
            :answerValue="answerValue"
            @input="onInput" 
        ></contexts-wrapper>
        <div v-else class="loading-wrapper skeleton-loader">
            <div v-for="index in 6" :key="index" class="skeleton-line-wrapper">
                <q-checkbox class="checkbox" disabled></q-checkbox>
                <div class="skeleton-line" :style="`width: ${Math.random()*100+300}px;`"></div>
            </div>
        </div>
    </div>
</template>

<script>

import IndicatorFilter from './IndicatorFilter';
import ContextsWrapper from './ContextsWrapper';
import IndicatorFilterBar from './IndicatorFilterBar.vue';
import _ from 'lodash';

export default {
    name: 'indicator-table',
    components: {
        IndicatorFilter,
        ContextsWrapper, 
        IndicatorFilterBar
    },
    props: {
        report: {
            type: Object,
            required: false,
        },
        value: {
            type: Array,
            required: true
        },
        filter: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        searchable: {
            type: Boolean,
            default: () => false
        }, 
        answerValue: {
            type: Boolean, 
            default: () => false
        },
        filterBarType: {
            type: String, 
            default: () => "version_1"
        },
        dropdownOptions: {
            type: Array, 
            default() { return [] }
        }, 
        reportPageFilterBar: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean, 
            default: false
        },
        showWarningTooltip: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onInput(contexts) {
            this.$emit('input', contexts)
        },
        handleFilterUpdated(filter) {
            this.$emit('filterUpdated', filter)
        },
        handleSearchUpdated(search) {
            this.$emit('searchUpdated', search);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        marked(string) {
            if(!this.filter.search) return string
            const re = new RegExp(this.filter.search, 'i');
            const match = string.match(re);
            const marked = string.replace(re, `<span style="background-color: #F4B976;border-radius:3px;">${match}</span>`);
            return marked
        }
    },
    computed: {
        computedContexts: function() {
            const mappedContexts = this.value.map(context => {
                const { title, items = [] } = context;
                let markedTitle = this.marked(this.capitalizeFirstLetter(this.$t(title)));
                const contextChecked = items.length > 0 ? items.filter(item => item.checked).length > 0 : context.checked;

                return {
                    ...context,
                    markedTitle,
                    items,
                    checked: contextChecked
                }
                
            });
            
            return mappedContexts
        }, 
    }
}
</script>

<style lang="scss" scoped>

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 1.8s;

.table-wrapper {
    display: flex;
    flex-direction: column;

    .inline-padding {
        padding-inline: 12px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 350px;
    }
}

div {
    width: 100%;
}

hr {
    margin: 20px 0 0 0;
    border: 1px solid rgba(222, 226, 230, 0.2);;
}

.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 16px;

    @mixin background-gradient {
        background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
        background-size: 600px;
    }

    .skeleton-line-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        padding-left: 20px;
        padding-block: 12px;
        height: 24px;
        
        .skeleton-line {
            height: 14px;
            border-radius: 4px;

            @include background-gradient;
            animation: shine-lines $animation-duration infinite ease-out;

            .skeleton-line ~ .skeleton-line {
                background-color: #ddd;
            }
        }
    }
}
</style>
<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>Gebruiker instellingen</h2>
        </div>

        <p class="mb-m mt-s">Pas hier de rol en instellingen van deze collega aan.</p>
        <div class="modal-content">
            <div class="space-between vertical-center">
                <div class="vertical-center">
                    <q-avatar :img="user.avatar"> {{ userInitials() }}</q-avatar>
                    <div class="profile-right">
                        <p class="name"> {{nameOrEmail}}</p>
                        <q-tag size="small" class="mini-tag role" variation="default">{{$t(user.organisation.role.name)}}</q-tag>

                    </div>
                </div>
                <div>
                    <q-tag size="medium" :variation="statusVariation(user.organisation.status)">{{$t(`userOrgStatus.${user.organisation.status}`)}}</q-tag>
                </div>

            </div>
            
            <q-dropdown v-if="roles.length > 0" size="small" v-model="newRoleId" :options="roles" class="mt-m" autocomplete>Rol aanpassen</q-dropdown>

            <div class="vertical-center mt-m">
                <q-switch v-model="newStatus" style="margin-right: 12px; height: 21px"></q-switch>
                <p>{{nameOrEmail}} {{activateSwitchLabel}}</p>
            </div>
            <div v-if="true" class="space-between vertical-center mt-m">
                <div class="vertical-center">
                    <q-switch :disabled="twofactor" v-model="newTwofactor" style="margin-right: 12px; height: 22px"></q-switch>
                    <p>2 Factor Authenticatie {{twofactorSwitchLabel}}</p>
                </div>
                <q-button v-if="this.user.organisation.twofactorEnabled" variation="light" size="xsmall" @click="reset2FA">2FA resetten</q-button>
            </div>
            
        </div>
        
        <div class="footer space-between">
            <q-button variation="blank" class="red-hover" :disabled="onlyUser" @click="handleRemoveUser(user)">Toegang verwijderen</q-button>
            <q-button variation="primary" @click="editUser" :disabled="!changedUserRole && !changedUserStatus && !changed2FA" :loading="editLoading">Gebruiker bijwerken</q-button>
        </div>
        <q-popup v-if="confirmRemoveUser" @close="closePopup">
            <confirm-delete-modal :deleteMessage="'Weet je zeker dat je '+user.firstName+' wilt <b>verwijderen</b>?'" cancelButtonText="Annuleren" confirmButtonText="Toegang verwijderen" @close="closePopup" @confirm="removeUser(user.id)"></confirm-delete-modal>
        </q-popup>
        <q-popup v-if="updatedRoleId">
            <div style="width:480px;">
                <h3 style="margin-bottom:10px">Rol aanpassen</h3>
                
                <span v-if="loggedInUser.id === user.id" style="line-height:21px">Weet je zeker dat je jouw rol wilt aanpassen naar <b>{{roles.filter(role => role.value == newRoleId)[0].label}}</b>? Je moet opnieuw inloggen voordat deze actief wordt.</span>
                <span v-else style="line-height:21px">Weet je zeker dat je deze rol wilt aanpassen naar <b>{{roles.filter(role => role.value == newRoleId)[0].label}}</b>? Je collega moet eerst opnieuw inloggen voordat de nieuwe rol actief wordt.</span>
                <div style="display:flex;justify-content:space-between;margin-top:30px;">
                    <q-button variation="blank" @click="closeRolePopup">Annuleren</q-button>
                    <q-button @click="handleConfirmRoleChange">Ik weet het zeker</q-button>
                </div>
            </div>
        </q-popup>
        
    </div>
</template>

<script>
import _ from 'lodash';
import { validateEmail, extractError, userInitials } from '@/assets/js/utils';
import { ORGANISATION_REMOVE_USER, ORGANISATION_CHANGE_USER_ROLE, ORGANISATION_ACTIVATE_USER, ORGANISATION_DEACTIVATE_USER, ORGANISATION_ENABLE_USER_TWOFACTOR, ORGANISATION_DISABLE_USER_TWOFACTOR, RESET_2FA } from '@/graphql/mutations';
import { ROLES } from '@/graphql/queries';
import confirmDeleteModal from './confirmDeleteModal'

export default {
    // onlyUser: if organisation contains one (this) user
    props: ['user', 'twofactor', 'onlyUser'],
    components: { confirmDeleteModal },
    data() {
        return {
            loggedInUser: this.$store.getters.getUser,
            editLoading: false,
            deleteLoading: false,
            rolesLoading: false,
            twofactorStatus: false,
            roles: [],
            newRoleId: this.user.organisation.roleId,
            updatedRoleId: false,
            newStatus: this.user.organisation.status === 'active' ? true : false,
            newTwofactor: this.user.organisation.twofactorEnabled,
            confirmRemoveUser: false,
            removedUser: {}
        }
    },
    methods: {
        userInitials() {
            return userInitials(this.user.firstName, this.user.lastName)
        },
        statusVariation(status) {
            switch (status) {
                case 'active':
                    return 'success'
                    break;
                case 'inactive':
                    return 'danger'
                    break;
                case 'requested':
                    return 'warning'
                    break;
                case 'invited':
                    return 'info'
                    break;
                default:
                    return 'danger'
                    break;
            }
        },
        getRoles() {
            this.rolesLoading = true;

            this.$apollo
                .query({
                    query: ROLES,
                    fetchPolicy:'no-cache'
                })
                .then(res => {
                    this.rolesLoading = false;

                    this.roles = res.data.roles.map(r => {
                        return {
                            value: r.id,
                            label: this.$t(r.name)
                        }
                    })
                })
                .catch((err) => {
                    this.rolesLoading = false;
                    this.$store.commit('notify', extractError(err));
                });

        },
        editUser() {
            if (this.newRoleId !== this.user.organisation.roleId) this.updateRole(this.newRoleId);
            const oldStatus = this.user.organisation.status === 'active';
            if (this.newStatus !== oldStatus) {
                const updateStatus = this.newStatus ? this.updateStatus('active') : this.updateStatus('inactive');
            }
            if (this.newTwofactor !== this.user.organisation.twofactorEnabled) this.updateTwofactor(this.newTwofactor);
        },
        closePopup() {
            this.confirmRemoveUser = false
            this.removedUser = {}
        },
        handleRemoveUser(user) {
            this.confirmRemoveUser = true
            this.removedUser = { ...user }
        },
        removeUser(userId) {
            this.removeLoading = true;
            const id = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .mutate({
                    mutation: ORGANISATION_REMOVE_USER,
                    variables: {
                        id,
                        userId                    },
                })
                .then(res => {
                    this.removeLoading = false;
                    this.$store.commit('notify', {type:'success', message: `Gebruiker ${this.nameOrEmail} verwijderd.`});
                    this.$emit('close');

                })
                .catch((err) => {
                    this.editLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        updateRole(roleId) {
            this.editLoading = true;
            const id = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .mutate({
                    mutation: ORGANISATION_CHANGE_USER_ROLE,
                    variables: {
                        id,
                        userId: this.user.id,
                        roleId
                    },
                })
                .then(res => {
                    this.editLoading = false;
                    this.$store.commit('notify', {type:'success', message: `Role voor ${this.nameOrEmail} bijgewerkt.`});
                    this.$emit('close');

                })
                .catch((err) => {
                    this.editLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        updateStatus(newStatus) {
            this.editLoading = true;
            const id = this.$store.getters.getCurrentOrganisation.id;
            const mutation = newStatus === 'active' ? ORGANISATION_ACTIVATE_USER : ORGANISATION_DEACTIVATE_USER

            return this.$apollo
                .mutate({
                    mutation,
                    variables: {
                        id,
                        userId: this.user.id                    },
                })
                .then(res => {
                    this.editLoading = false;
                    this.$store.commit('notify', {type:'success', message: `Status voor ${this.nameOrEmail} bijgewerkt.`});
                    this.$emit('close');

                })
                .catch((err) => {
                    this.editLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        updateTwofactor(enable) {
            this.editLoading = true;
            const id = this.$store.getters.getCurrentOrganisation.id;
            const mutation = enable ? ORGANISATION_ENABLE_USER_TWOFACTOR : ORGANISATION_DISABLE_USER_TWOFACTOR

            return this.$apollo
                .mutate({
                    mutation,
                    variables: {
                        id,
                        userId: this.user.id                    },
                })
                .then(res => {
                    this.editLoading = false;
                    this.$store.commit('notify', {type:'success', message: `2FA voor ${this.nameOrEmail} bijgewerkt.`});
                    this.$emit('close');

                })
                .catch((err) => {
                    this.editLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        reset2FA() {
            this.editLoading = true;

            return this.$apollo
                .mutate({
                    mutation: RESET_2FA,
                    variables: {
                        id: this.user.id                    },
                })
                .then(res => {
                    this.editLoading = false;
                    this.$store.commit('notify', {type:'success', message: `2FA voor ${this.nameOrEmail} gereset.`});
                    this.$emit('close');
                })
                .catch((err) => {
                    this.editLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        closeRolePopup() {
            this.updatedRoleId = false;
            this.newRoleId = this.user.organisation.roleId;
        },
        handleConfirmRoleChange() {
            this.updatedRoleId = false;
        }

    },
    computed: {
        nameOrEmail() {
            return this.user.firstName && this.user.lastName ? `${ this.user.firstName} ${ this.user.lastName}` : `${this.user.email}`;
        },
        activateSwitchLabel() {
            return this.newStatus ? 'deactiveren' : 'activeren';
        },
        twofactorSwitchLabel() {
            return this.newTwofactor ? 'uitschakelen' : 'inschakelen';
        },
        changedUserRole() {
            if (this.newRoleId !== this.user.organisation.roleId) return true
            else return false
        },
        changedUserStatus() {
            const oldStatus = this.user.organisation.status === 'active';
            if (this.newStatus !== oldStatus) {
                return true
            } else return false
        },
        changed2FA() {
            if (this.newTwofactor !== this.user.organisation.twofactorEnabled) return true
            else return false
        }
    },
    watch: {
        newRoleId: function() {
            if(this.newRoleId !== this.user.organisation.roleId) this.updatedRoleId = true
        }
    },
    created() {
        this.getRoles();
    }

}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;


    .modal-content {
        // height: 260px
        margin-bottom: 70px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}

.profile-right {
    margin-left: 16px;

    .name {
        font-weight: 500;
    }
    .role {
        margin-left: -2px;
    }
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.permission-wrapper {
    display: flex;
    margin: 40px 0px 90px;
    min-height: 100px;

    .subhead {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .left {
        width: 35%;
        min-width: 200px;
        border-right: 1px solid rgb(222, 226, 230);

        .category-item {
            padding: 4px 8px;
            border: 1px solid white;
            border-radius: 4px;
            margin-right: 24px;
            margin-left: -8px;
            // margin-bottom: 8px;

            &:hover {
                border-color: rgb(222, 226, 230);
                cursor: pointer;
            }
            
            .active {
                color: $color-primary;
                font-weight: 500;
            }

            .count {
                color: $color-grey-5;
            }

        }
    }

    .right {
        padding: 0px 40px;
        width: calc(100% - 35%);
        max-height: 250px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
    }
}

.red-hover :hover {
    color: $color-red;
    transition: color 200ms ease;
}

</style>
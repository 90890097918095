<template>
    <div :style="sectionStyle">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'q-section',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         *Set horizontal padding, both top and bottom.
         */
        hPadding: {
            type: String,
            default: '0'
        },
        /**
         *Set vertical padding, both left and right.
         */
        vPadding: {
            type: String,
            default: '0'
        },

        /**
         *Set top padding.
         */

        paddingTop: {
            type: String,
            default: null
        },

        /**
         *Set bottom padding.
         */
        paddingBottom: {
            type: String,
            default: null
        },
        /**
         *Set left padding.
         */
        paddingLeft: {
            type: String,
            default: null
        },
        /**
         *Set right padding.
         */
        paddingRight: {
            type: String,
            default: null
        },
        /**
         * Set border on top of section
         */
        borderTop: {
            type: Boolean,
            default: false
        },
        /**
         * Set border on bottom of section
         */
        borderBottom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            top: this.paddingTop || this.vPadding,
            bottom: this.paddingBottom || this.vPadding,
            left: this.paddingLeft || this.hPadding,
            right: this.paddingRight || this.hPadding,
            topBorder: this.borderTop ? `1px solid #DEE2E6` : `none`,
            bottomBorder: this.borderBottom ? `1px solid #DEE2E6` : `none`
        };
    },
    computed: {
        sectionStyle() {
            return {
                padding: `${this.top}px ${this.right}px ${this.bottom}px  ${this.left}px`,
                borderTop: this.topBorder,
                borderBottom: this.bottomBorder
            };
        }
    }
};
</script>

<template>
    <svg :width="width" :height="height" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M0.646447 5.30855C0.451184 5.11329 0.451184 4.79671 0.646447 4.60145C0.841709 4.40618 1.15829 4.40618 1.35355 4.60145L0.646447 5.30855ZM4.035 7.99L4.38855 8.34355C4.19329 8.53882 3.87671 8.53882 3.68145 8.34355L4.035 7.99ZM6.71645 4.60145C6.91171 4.40618 7.22829 4.40618 7.42355 4.60145C7.61882 4.79671 7.61882 5.11329 7.42355 5.30855L6.71645 4.60145ZM3.535 1C3.535 0.723858 3.75886 0.5 4.035 0.5C4.31114 0.5 4.535 0.723858 4.535 1H3.535ZM1.35355 4.60145L4.38855 7.63645L3.68145 8.34355L0.646447 5.30855L1.35355 4.60145ZM3.68145 7.63645L6.71645 4.60145L7.42355 5.30855L4.38855 8.34355L3.68145 7.63645ZM4.535 1V7.99H3.535L3.535 1H4.535Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowDown',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 8,
        },
        height: {
            type: [Number, String],
            default: 9,
        },
    },
};
</script>

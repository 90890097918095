<template>
    <svg :width="width" :height="height" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 8H6.28643C5.6341 8 5.15913 7.38593 5.36479 6.76686C5.56642 6.15992 5.82866 5.48628 6.09091 5.14286C6.41034 4.72455 7.10391 4.06128 7.62385 3.58342C7.99208 3.24499 8.55337 3.24499 8.9216 3.58342C9.44155 4.06128 10.1351 4.72455 10.4545 5.14286C10.7513 5.53145 10.8866 6.34285 10.9483 7.00201C10.9998 7.55189 10.5523 8 10 8Z" fill="white" :stroke="color"/>
        <circle cx="8" cy="2" r="1.5" fill="white" :stroke="color"/>
        <path d="M6 9H2.28643C1.6341 9 1.15913 8.38593 1.36479 7.76686C1.56642 7.15992 1.82866 6.48628 2.09091 6.14286C2.41034 5.72455 3.10391 5.06128 3.62385 4.58342C3.99208 4.24499 4.55337 4.24499 4.9216 4.58342C5.44155 5.06128 6.13511 5.72455 6.45455 6.14286C6.75129 6.53145 6.88659 7.34285 6.94829 8.00201C6.99976 8.55189 6.55228 9 6 9Z" fill="white" :stroke="color"/>
        <circle cx="4" cy="3" r="1.5" fill="white" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'People',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 12
        },
        height: {
            type: [Number, String],
            default: 10
        }
    }
};
</script>
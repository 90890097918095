<template>
  <div :class="['tag', sizeClass, iconSizeClass, variation, emoji]" :style="widthStyle">
    
    <div v-if="!emoji" class="truncate">
      <slot />
    </div>
    <div style="min-height: 10px;">
        <div v-if="emoji && emojiValue" :class="{emoji: emoji}">
            {{emojiValue}}
        </div>
    </div>
    <q-icon v-if="emoji" :type="iconType" :width="iconSize" :height="iconSize"></q-icon>
  </div>
</template>

<script>
/**
 * tags are generally used for interface actions. Suitable for all-purpose use.
 *
 * Primary style should be used only once per view for main call-to-action.
 */
export default {
    name: 'q-tag',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         * The size of the tag. Defaults to medium.
         * `small, medium, large`
         */
        size: {
            type: String,
            default: 'large',
            validator: (value) => {
                return value.match(/(small|medium|large|)/);
            },
        },

        /**
         * Style variation to give additional meaning.
         * `alert, warning, info, success`
         */
        variation: {
            type: String,
            default: 'info',
            validator: (value) => {
                return value.match(/(danger|warning|info|default|success)/);
            },
        },
        /**
         * Set width for tag. Used when tag needs to be truncated.
         */
        width: {
            type: String,
            default: null,
        },
        emoji:{
          type: String,
          default: null,
        },
        emojiValue:{
          type: [String, Number],
          default: null,
        }
    },
  data() {
    return {
      widthStyle: {
        maxWidth: this.width ? `${this.width}px` : null,
      },
      
    };
  },
  computed:{
        sizeClass() {
            return this.emoji ? null : this.size;
        },
        iconType() {
        let result = null;
        switch (this.emoji) {
            case "happy":
            result = "happy-emoji";
            break;
            case "slightly-happy":
            result = "slightly-happy-emoji";
            break;
            case "neutral":
            result = "neutral-emoji";
            break;
            case "sad":
            result = "sad-emoji";
            break;
            case "unknown":
            result = "question-circle";
            break;
        }
        return result;
        },
        iconSize() {
            switch (this.size) {
                case "medium":
                return "25"
                break;
                case "small":
                return "16"
                break;
                default:
                    return '25'
            }
        },
        iconSizeClass() {
            return `${this.size}-icon`
        }
  },
  
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/style.scss';
@import '../assets/style/fonts/fonts.css';

.tag {
  @include reset;
  will-change: transform;
  transition: all 0.2s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $weight-semi-bold;
  font-size: $size-m;
  font-family: $font-text;
  line-height: $line-height-m;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-radius: 50px;
  background: transparent;
  color: $color-grey-3;
  padding: 4px;

  &.small-icon {
      padding: 3px;
      font-size: 9px;
  }

  // Various tag sizes
  &.large {
    padding: 5px 20px;
    font-size: $size-m;
  }
  &.medium {
    padding: 4px 16px;
    font-size: $size-s;
  }
  &.small {
    padding: 2px 10px;
    font-size: $size-xs;
    font-family: $font-text;
    line-height: $line-height-m;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50px;
    background: transparent;
    color: $color-grey-3;
    }

    // Various tag sizes
    &.large {
        padding: 5px 20px;
        font-size: $size-m;
    }
    &.medium {
        padding: 4px 16px;
        font-size: $size-s;
    }
    &.small {
        padding: 3px 13px;
        font-size: $size-xs;
    }

    // info tag
    &.info {
        background: $color-purple-light;
        color: $color-purple-dark;
        box-shadow: none;
    }
    
  // default tag
  &.default {
    background: $color-grey-3;
    color: $color-grey-7;
    box-shadow: none
  }

  // danger tag
  &.danger, &.sad {
    background: $color-red-lighter;
    color: $color-red;
    box-shadow: none;
  }

  // warning tag
  &.warning, &.neutral, &.slightly-happy {
    background: $color-orange-lighter;
    color: $color-orange;
    box-shadow: none;
  }

  // success tag
  &.success, &.happy {
    background: $color-green-lighter;
    color: $color-green;
    box-shadow: none;
  }

  // success tag
   &.unknown {
    background: $color-grey-3;
    color: $color-grey-9;
    box-shadow: none;
  }
}

// Truncate tags
.truncate {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emoji {
    margin-left: 6px;
    margin-right: 6px;
}

.small-icon .emoji {
    margin-left: 4px;
    margin-right: 4px;

}



</style>

<docs>
  ```jsx
  <div>
    <q-tag variation="info" size="large">Info tag</q-tag>
    <q-tag variation="info" size="medium">Medium</q-tag>
    <q-tag variation="info" size="small">Small</q-tag>

    <br />
    <q-tag variation="danger" size="large">danger</q-tag>
    <q-tag variation="danger" size="medium">Medium</q-tag>
    <q-tag variation="danger" size="small">Small</q-tag>
    <q-tag variation="danger" size="large" width="300"> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lore</q-tag>
    <br />
    <q-tag variation="warning" size="large">Warning</q-tag>
    <q-tag variation="warning" size="medium">Medium</q-tag>
    <q-tag variation="warning" size="small">Small</q-tag>
    <br />
    <q-tag variation="success" size="large">Success</q-tag>
    <q-tag variation="success" size="medium">Medium</q-tag>
    <q-tag variation="success" size="small">Small</q-tag>

    
  </div>
  ```
</docs>

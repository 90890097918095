<template>
    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="6" r="3.5" :stroke="color"/>
        <path d="M21.5 6C21.5 6.66685 21.1936 7.34183 20.5985 8.00016C20.0028 8.65906 19.1424 9.27191 18.1086 9.79682C16.0395 10.8474 13.367 11.5 11 11.5C8.63301 11.5 5.96047 10.8474 3.8914 9.79682C2.85765 9.27191 1.99722 8.65906 1.40153 8.00016C0.806364 7.34183 0.5 6.66685 0.5 6C0.5 5.33315 0.806364 4.65817 1.40153 3.99984C1.99722 3.34094 2.85765 2.72809 3.8914 2.20318C5.96047 1.15257 8.63301 0.5 11 0.5C13.367 0.5 16.0395 1.15257 18.1086 2.20318C19.1424 2.72809 20.0028 3.34094 20.5985 3.99984C21.1936 4.65817 21.5 5.33315 21.5 6Z" :stroke="color"/>
        <circle cx="12" cy="5" r="1" :fill="color"/>
    </svg>

</template>

<script>
export default {
    name: "AddTextBubble",
    props: {
        color: {
            type: String,
            default: "#495057",
        },
        width: {
            type: [Number, String],
            default: 22,
        },
        height: {
            type: [Number, String],
            default: 12,
        },
    },
};
</script>

<style></style>

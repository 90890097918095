<template>
    <slide-transition-group 
        ref="overflowContainer" 
        :class="[reportPageFilterBar ===  true ? 'wrapper-filter-bar' : 'wrapper']" 
        :style="[reportPageFilterBar === true ? 'overflow-y: auto;'  : '' ]"
        :disableStagger="Boolean(filter.search) || newListData"
        @after-enter="newListData = false"
    >
        <div 
            v-for="(context, index) in computedContexts" 
            :key="context.id" 
            class="bar-wrapper" 
            :class="{ checked: context.checked }"
            :style="`--index: ${index}; --length: ${computedContexts.length};`"
            @click="handleRowClick(index)"
        >
            <div class="bar" @click="toggleContext(context.id)">
                <div class="checkbox-wrapper">
                    <div class="checkbox">
                        <q-checkbox v-if="checkable" :disabled="contextDisabled(context)" v-model="context.checked" @input="onContextChecked($event, context)"></q-checkbox>
                    </div>
                    <span 
                        class="text" 
                        :class="{ 'text-filter-bar': reportPageFilterBar }" 
                        v-html="context.markedTitle"
                    ></span>

                    <q-icon 
                        v-if="context.items"
                        :class="['icon', openedContexts.includes(context.id) ? 'rotated' : '']" 
                        type="SmallArrowDown" 
                        width="10">
                    </q-icon>
                </div>
                <div :class="[reportPageFilterBar === true ? 'result-count-filter-bar' : 'result-count']">
                    <span v-if="getContextLabel && context.items">{{ context.items.length }} {{ getContextLabel(context.items.length) }}</span>
                </div>
            </div>

            <checkbox-list 
                v-if="context.items" 
                v-model="context.items" 
                :reportPageFilterBar="reportPageFilterBar"
                :checkable="checkable" 
                :disabled="disabled"
                :answerValue="answerValue"
                :filter="filter"
                :show="openedContexts.includes(context.id)"
                @input="onInput(context, $event)"
            ></checkbox-list>    
        </div>
        
        <div v-if="computedContexts.length == 0" class="no-contexts" key="null">
            <p>We hebben niks kunnen vinden onder '{{ filter.search }}'</p>
        </div>
    </slide-transition-group>
</template>

<script>
import CheckboxList from './CheckboxList';

export default {
    name: 'contexts-wrapper',
    props: {
        report: {
            type: Object,
            required: false
        },
        contexts: {
            type: Array,
            default: []
        },
        checkable: {
            type: Boolean,
            default: false
        },
        contextLabel: {
            type: String,
            default: null
        },
        filter: {
            type: Object,
            default: {}
        }, 
        answerValue: {
            type: Boolean, 
            default: false
        }, 
        reportPageFilterBar: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean, 
            default: false
        }
    },
    components: {
        CheckboxList
    },
    data() {
        return {
            openedContexts: [],
            cachedOpenedContexts: [],
            show: true,
            newListData: false
        }
    },
    methods: {
        openContext(id) {
            const index = this.openedContexts.indexOf(id);
            if(index === -1) {
                this.openedContexts.push(id)
                this.cachedOpenedContexts.push(id)
            }
        },
        toggleContext(id) {
            const index = this.openedContexts.indexOf(id);

            if (index === -1) {
                this.openedContexts.push(id);
                if(!Boolean(this.filter.search)) this.cachedOpenedContexts.push(id);
            }
            else {
                this.openedContexts.splice(index, 1);
                if(!Boolean(this.filter.search)) this.cachedOpenedContexts.splice(index, 1)
            }
        },
        onContextChecked(checked, context) {
            const thisContextIndex = this.contexts.findIndex(context_ => context_.id === context.id);

            this.contexts[thisContextIndex].checked = checked;
            
            if (this.contexts[thisContextIndex].items) {
                const currentConditionsLength = this.currentConditionsLength;
                const conditionsToAddAmount = 10 - currentConditionsLength;
            

                this.contexts[thisContextIndex].items = this.contexts[thisContextIndex].items
                    .map((item, index) => { 
                        let checked_ = checked;

                        if (checked_ && index >= conditionsToAddAmount) 
                            checked_ = false;

                        return { 
                            ...item, 
                            checked: checked_
                        } 
                    })
                    
            }

            if(checked) this.openContext(context.id)

            this.$emit('input', this.contexts);
        },
        onInput(context, items) {
            const index = this.contexts.findIndex(_context => _context.id === context.id);
            this.contexts[index].items = items;
            this.$emit('input', this.contexts);
        },
        handleRowClick(index) {
            const item = this.contexts[index];
            if(!item) return
            if(!item.items) this.onContextChecked(!item.checked, item)
        },
        getContextLabel: function(itemLength) {
            let label = null;

            const questionLabel = itemLength == 1 ? 'Vraag' : 'Vragen'
            const formLabel = itemLength == 1 ? 'Kenmerk' : 'Kenmerken'
            const referenceLabel = itemLength == 1 ? 'Kenmerk' : 'Kenmerken'

            if(this.contextLabel == 'question') label = questionLabel;
            if(this.contextLabel == 'form') label = formLabel;
            if(this.contextLabel == 'reference') label = referenceLabel;

            return label
        },
        contextDisabled(context) {
            return !context.checked && this.disabled
        },
        marked(string) {
            if(!this.filter.search) return string
            const re = new RegExp(this.filter.search, 'i');
            const match = string.match(re);
            const marked = string.replace(re, `<span style="background-color: #F4B976;border-radius:3px;">${match}</span>`);
            return marked
        }
    },
    computed: {
        currentConditionsLength: function() {
            if (!this.report) return 0;
            
            return [...this.report.configuration.conditions.questions, ...this.report.configuration.conditions.references].length
        },
        computedContexts: function() {
            let filteredContexts = [];
            this.contexts.forEach(context => {
                let newContext = { ...context };
                const filteredItems = newContext.items.filter(item => this.$t(item.name).toLowerCase().includes(this.filter.search));
                let parsedItems = filteredItems.map(item => {
                    const markedName = this.marked(this.$t(item.name));
                    item.markedName = markedName;

                    return item
                });
                newContext.items = parsedItems;

                if(newContext.items.length == 0) return
                filteredContexts.push(newContext)
            })
            return filteredContexts
        }
    },
    watch: {
        contextLabel: function() {
            this.openedContexts = [];
        },
        filter: {
            async handler() {
                if(!Boolean(this.filter.search)) return this.openedContexts = this.cachedOpenedContexts
                this.openedContexts = this.contexts.map(context => context.id)
            }, 
            deep: true
        },
        'filter.indicator': function(oldFilterIndicator, newFilterIndicator) {
            if(oldFilterIndicator !== newFilterIndicator) this.newListData = true;
        },
        contexts: function() {
            if(this.contexts.length == 1) this.openedContexts.push(this.contexts[0].id)
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: $color-grey-5;
    border-radius:10px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 6px;
}

.wrapper-filter-bar {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: calc(100vh - 260px);
    min-height: 400px;
    padding-top: 16px;
    overflow-y: auto;
}

.no-contexts {
    margin-top: 8px;
    text-align: center;

    p {
        color: #a0a0a0;
        font-size: 15px;
    }
}

.bar-wrapper {
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    border-radius: 6px;

    &:hover, &.checked {
        background-color: #f8f8f8;
    }

    .bar {
        display: flex;
        justify-content: space-between;
        gap: 6px;
        padding-block: 12px;

        cursor: pointer;
        display: flex;
        align-items: center;

        .result-count {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            margin: 0 23px 0 0;
            color: #C4C4C4;
            text-wrap: nowrap;
            user-select: none;
        }

        .result-count-filter-bar {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #C4C4C4;
            text-wrap: nowrap;
            user-select: none;
        }
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;

        .checkbox {
            display: flex; 
            flex-direction: column;
            justify-content: center;
            margin: 0 5px 0 2px;
        }

            .text {
                padding: 0 5px;
                font-weight: 500;
                font-size: 15px;
                color: $color-grey-9;
                user-select: none;
            }

            .text-filter-bar {
                max-width: 210px;
            }

        .icon {
            padding: 0 5px;
            transition: 0.2s;
        }

        .rotated {
            transform: rotate(180deg);
        }
    }
    
}
</style>
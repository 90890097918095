<template>
    <div :class="['notifs', align]" v-if="notifications.length">
        <q-alert
            v-for="notif in notifications"
            :key="notif.id"
            :variation="notif.type"
            class="alert slide-in"
            @close="removeNotif(notif.id)"
            >{{ notif.message }}</q-alert
        >
    </div>
</template>

<script>
export default {
    name: 'Notifications',
    props: {
        align: {
            type: String,
            default: null,
        },
        noTimer: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        return {
            timers: {},
        };
    },
    beforeUpdate() {
        const addTimers = this.notifications.filter((notif) => !Object.keys(this.timers).includes(notif.id) && !notif.noTimer);
        addTimers.forEach((notif) => this.addNotificationTimeout(notif.id));
    },
    computed: {
        notifications() {
            return this.$store.getters.getNotifs || [];
        },
    },
    methods: {
        async removeNotif(id) {
            clearTimeout(this.timers[id]);
            this.$store.commit('removeNotif', id);
        },
        addNotificationTimeout(id) {
            this.timers[id] = setTimeout(() => this.removeNotif(id), 6000);
        },
        removeNotification(id) {},
    },
    mounted() {
        const addTimers = this.notifications.filter((notif) => !Object.keys(this.timers).includes(notif.id));
        addTimers.forEach((notif) => this.addNotificationTimeout(notif.id));
    },
};
</script>

<style lang="scss" scoped>
.notifs {
    position: fixed;
    bottom: 16px;
    margin-left: 50px;
    margin-right: 100px;
    width: 400px;
    text-align: left;
    z-index: 2005;

    .alert {
        transition: margin .2s ease;
        &.slide-in {
            animation: 0.25s slide-up cubic-bezier(.51,1.4,.45,1.08);
        }
    }
}

.center {
    margin: auto;
}

@keyframes slide-up {
    from {
        opacity: 0.7;
        margin-bottom: -50px;
    }

    to {
        opacity: 1;
        margin-bottom: 0px;
    }
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.09998 1C6.09998 0.447715 6.54769 0 7.09998 0H10.1C10.6523 0 11.1 0.447715 11.1 1V1.5H12.1L14.6 1.5C15.4284 1.5 16.1 2.17157 16.1 3V6.08203L17.2356 4.49866C17.3966 4.27427 17.709 4.22284 17.9334 4.38378L18.0959 4.50034L19.0882 3.11682C19.2491 2.89243 19.5615 2.841 19.7859 3.00195L20.9229 3.81747C21.1473 3.97842 21.1987 4.29079 21.0378 4.51519L20.0455 5.8987L20.2074 6.01484C20.4318 6.17578 20.4832 6.48816 20.3223 6.71255L16.1 12.5993L16.1 19C16.1 19.8284 15.4284 20.5 14.6 20.5H2.09998C1.27155 20.5 0.599976 19.8284 0.599976 19V3C0.599976 2.17157 1.27155 1.5 2.09998 1.5H5.09998H6.09998V1ZM15.1 13.9565C15.0428 14.0002 14.9764 14.0315 14.905 14.0475L13.5084 14.3595C13.4978 14.3618 13.4871 14.3639 13.4765 14.3655L13.0839 14.9128C13.0071 15.02 12.891 15.0924 12.7609 15.1144L12.2859 15.1947C12.1544 15.2169 12.0195 15.1857 11.9112 15.108C11.8028 15.0303 11.73 14.9125 11.7089 14.7808L11.6326 14.3051C11.6118 14.1749 11.6432 14.0417 11.72 13.9346L12.1305 13.3623L12.1301 13.3569L12.0234 11.9667C12.0144 11.8494 12.0471 11.7326 12.1156 11.637L15.1 7.47624V3C15.1 2.72386 14.8761 2.5 14.6 2.5H13.5146C13.5699 2.65639 13.6 2.82468 13.6 3V5C13.6 5.82843 12.9284 6.5 12.1 6.5H5.09998C4.27155 6.5 3.59998 5.82843 3.59998 5V3C3.59998 2.82468 3.63005 2.65639 3.68533 2.5H2.09998C1.82383 2.5 1.59998 2.72386 1.59998 3L1.59998 19C1.59998 19.2761 1.82383 19.5 2.09998 19.5H14.6C14.8761 19.5 15.1 19.2761 15.1 19L15.1 13.9565ZM5.09998 2.5H12.1C12.3761 2.5 12.6 2.72386 12.6 3V5C12.6 5.27614 12.3761 5.5 12.1 5.5H5.09998C4.82383 5.5 4.59998 5.27614 4.59998 5V3C4.59998 2.72386 4.82383 2.5 5.09998 2.5ZM19.9338 4.33865L19.2329 5.31586L18.9085 5.08317L19.6094 4.10595L19.9338 4.33865ZM13.5089 13.3347L14.5008 13.1131L19.2183 6.53601L17.7568 5.48779L13.0344 12.0718L13.1093 13.0481L13.5089 13.3347Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'FormIcon',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 22,
        },
        height: {
            type: [Number, String],
            default: 21,
        },
    },
};
</script>

<style></style>

<template>
    <div class="searchbox-wrapper darkenBackground">
        <div class="q-card-small searchbox" id="searchbox" :style="'left: '+getSearchboxMarginLeft">
            <q-icon type="search" width="24" height="24" style="margin:1px 0 0 3px"></q-icon>
            <input
                id="inputField"
                ref="inputField"
                type="text"
                @input="handleInput"
                @keyup.enter="goToResult(filteredResults[Object.keys(filteredResults)[0]][0].link)"
                placeholder="Typ om te zoeken"
                v-model="inputValue"
                autocomplete="off"
            />
            <div class="resultWrap" id="results">
                <div v-if="loading" class="loader-wrapper"><div class="loader"></div></div>
                <p class="resultInfo" v-else-if="!hasResults">
                    {{
                        inputValue
                            ? 'Geen resultaten gevonden.'
                            : 'Zoek bijvoorbeeld op projectnaam, gebruiker of bedrijf.'
                    }}
                </p>
                <q-section
                    v-else
                    class="resultSection"
                    v-for="result in Object.keys(filteredResults)"
                    :key="result"
                    hPadding="20"
                    vPadding="10"
                    borderBottom
                >
                    <p class="resultKey">{{ $t(result) }}</p>
                    <div class="results">
                        <router-link 
                            v-for="item in filteredResults[result]" 
                            :key="item.id" class="resultWrapper" 
                            :to="item.link" 
                            @click.native="close"
                        >
                            <div v-if="item.avatar || item.avatarFallback" class="avatar">
                                <q-avatar size="xsmall" :img="item.avatar">{{ item.avatarFallback }}</q-avatar>
                            </div>
                            <span v-html="addHighlights(item.name)" class="result item"></span>
                        </router-link>
                    </div>
                </q-section>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    name: 'q-searchbox',
    status: 'prototype',
    release: '0.0.1',

    props: {
        results: {
            type: Object
        },
        loading: {
            type: Boolean
        }, 
        size: {
            type: String 
        }
    },

    data() {
        return {
            inputValue: ''
        };
    },
    methods: {
        close() {
            this.$emit('close');
            this.inputValue = '';
        },
        handleInput() {
            this.$emit('input', this.inputValue);
        },
        goToResult(to) {
            this.close();
            if (to == this.current) return;
            else this.$router.push(to);
        },
        addHighlights(value) {
            if(!this.inputValue) return value

            var change = new RegExp('\(' + this.inputValue + '\)', 'gim');
            var updatedValue = value.replace(change, '<span style="background-color: #F4B976;border-radius:3px;">$1</span>');
            return updatedValue;
        },
        routerLinkInput(event) {
            console.log(event)
        }
    },
    computed: {
        hasResults() {
            let existingResults = false;
            Object.keys(this.results).forEach(key => {
                existingResults = this.results[key].length || existingResults;
            });

            return existingResults;
        },
        filteredResults() {
            const filteredResults = {};

            Object.keys(this.results).forEach(result => {
                if (this.results[result].length) {
                    filteredResults[result] = this.results[result];
                }
            });
            return filteredResults;
        },
        getSearchboxMarginLeft() {
            const folded = JSON.parse(localStorage.getItem('navbar_folded'))
            const marginLeft = folded ? '59px' : '239px';
            return `calc(${marginLeft} + 40px)`
        }
    },
    mounted() {
        const vm = this;
        this.$refs['inputField'].focus();
        this.$store.commit('addBodyListener', [
            'searchbox',
            () => {
                vm.close();
            }
        ]);
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/fonts/fonts.css';

.icon {
    display: inline-block;
    padding-left: 15px;
    padding-top: 18px;
    color: $color-grey-5;
}

input {
    position: absolute;
    top: 11.5px;
    background-color: none;

    font-family: $font-text;
    color: $color-grey-7;
    padding: 9px 20px 8px 12px;

    margin-bottom: 10px;
    font-size: 16px;
    border: none;
    width: 80%;
}

input:focus {
    outline: none;
}
.results {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.resultWrapper {
    display: flex;
    align-items: center;
    padding-inline: 8px;
    border-radius: 4px;

    text-decoration: none;
    color: inherit;
    font-size: 16px;
    cursor: pointer;

    .result {
        padding-left: 8px;
    }

    &:hover,
    &:focus-visible {
        // font-weight: 500;
        background: $color-grey-1;
    }
}

.item {
    margin: 8px 0px;
    cursor: pointer;
    line-height: 1.2rem;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.resultKey {
    font-weight: 400;
    color: $color-grey-5;
    font-size: 13px;
    min-width: 100px;
    padding-right: 10px;
    padding-top: 6px;
}

.resultSection {
    display: flex;

    &:last-child {
        border-bottom: none !important;
    }
}

.resultWrap {
    max-height: 450px;
    border-top: solid 1px $color-grey-3;
    margin: 25px 0 4px 0;
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
}

.searchbox {
    position: fixed;
    top: 9px;
    border-radius: 8px;
    border-width: 0px;
    font-family: $font-text;
    width: calc(60% - 250px);
    min-width: 500px;
    box-shadow: 0px 4px 18px 5px rgba(0, 0, 0, 0.25);
}

.searchbox-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 109;

    &.darkenBackground {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        animation: fadeIn 200ms forwards;
    }
}
.loader-wrapper {
    width: 100%;
    padding: 18px 60px;
    margin-bottom: -4px !important;
}
.loader {
    $loader-color: $color-primary;
    $loader-size: 8px;
    $loader-height: 16px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1.2s;
    @import '../../assets/loaders/loaders.scss';
    @include loader12;
}
.resultInfo {
    padding: 10px 22px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-out {
    opacity: 1;
    animation: fadeOut 300ms forwards;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 300ms forwards;
}
</style>

<template>
    <div>
        <draggable :list="labels" :animation="300" group="labels" handle=".hamburger-icon" @change="handleOrderChanged">
            <transition-group class="labels-container">
                <collapsable-row
                    v-for="(label, index) in labels" 
                    :key="label.id"
                    :style="`--index: ${index}`"
                    :rowData="label"
                    :questions="label.questions"
                    :references="label.references"
                    :collapsed="collapsedRowId === label.id"
                    :search="searchingValue"
                    :showTag="showTag"
                    :showStatus="showStatus"
                    :loadingComponents="loadingComponents"
                    :dragQuestions="dragQuestions"
                    :dragReferences="dragReferences"
                    :dragLabels="dragLabels"
                    :disableToggleRow="disableToggleRow"
                    :referencesOnly="referencesOnly"
                    :questionsOnly="questionsOnly"
                    :disabled="disabled"
                    :dataName="dataName"
                    :contextName="contextName"
                    :noDisable="noDisable"
                    :disableEdit="disableEdit"
                    :loading="label.loading"
                    @toggleShowRow="toggleShowRow"
                    @optionSelected="handleAction"
                    @indicatorOrderUpdated="indicatorOrderUpdated"
                    @handleAddReference="handleAddReference"
                    @handleAddQuestion="handleAddQuestion"
                    @handleEditQuestion="handleEditQuestion"
                >
                </collapsable-row>
            </transition-group>
        </draggable>

        <question-configuration-modal 
            v-if="showQuestionModal || showReferenceModal" 
            :indicator="configuringIndicator"
            :editing="isEditingIndicator"
            :dataName="dataName"
            :contextName="contextName"
            :disableEdit="disableEdit"
            @cancel="handleCancelEditIndicator"
            @save="handleIndicatorSaved"
            @indicatorsAdded="handleIndicatorsAdded"
            @deleted="handleIndicatorDeleted"
            @create="handleIndicatorCreated">
        </question-configuration-modal>

        <q-popup v-if="showEditLabelModal">
            <div class="modal">
                <div class="header">
                    <h2>{{ capitalizeFirstLetter(dataName) }} wijzigen</h2>
                </div>
                <div class="content">
                    <div class="line">
                        <div class="inline">
                            <p class="name">Naam {{ dataName }}</p>
                        </div>
                        <q-input 
                            labelPosition="inside" 
                            v-model="configuringLabel.name" 
                            :disabled="Boolean(configuringLabel.masterId || disableEdit)" 
                            class="user-input" 
                            size="small" 
                            placeholder="Typ hier een naam"
                        >Naam</q-input>
                    </div>
                    <div class="line">
                        <div class="inline">
                            <p class="name">Omschrijving</p>
                        </div>
                        <q-input 
                            labelPosition="inside"
                            v-model="configuringLabel.description" 
                            class="user-input" 
                            size="small" 
                            placeholder="Typ hier een omschrijving"
                        >Omschrijving</q-input>
                    </div>
                </div>
                <div class="footer">
                    <q-button variation="blank" @click="handleCancelEditLabel">Wijzigingen annuleren</q-button>
                    <q-button variation="primary" :disabled="configuringLabel.name.length === 0" @click="handleUpdateLabel">Wijzigingen opslaan</q-button>
                </div>
            </div>
        </q-popup>

        <q-popup v-if="showDeleteLabelModal">
            <div class="modal">
                <div class="header">
                    <h2>{{ capitalizeFirstLetter(dataName) }} verwijderen</h2>
                </div>
                <div class="content small-padding">
                    <p v-html="deleteMessage"></p>
                </div>
                <div class="footer">
                    <q-button variation="blank" @click="handleCancelDeleteLabel">Annuleren</q-button>
                    <q-button variation="danger" @click="handleDeleteLabel">{{ capitalizeFirstLetter(dataName) }} verwijderen</q-button>
                </div>
            </div>
        </q-popup>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import CollapsableRow from './CollapsableRow.vue';
import QuestionConfigurationModal from './QuestionConfigurationModal.vue';

export default {
    name: 'label-context-row',
    components: {
        draggable,
        QuestionConfigurationModal,
        CollapsableRow
    },
    props: {
        labels: {
            type: Array,
            required: true
        },
        allLabels: {
            type: Array,
            default() { return this.labels }
        },
        collapsedRowId: {
            type: String,
            default: null
        },
        loadingComponents: {
            type: Boolean,
            default: false
        },
        referencesOnly: {
            type: Boolean,
            default: false
        },
        questionsOnly: {
            type: Boolean,
            default: false
        },
        dragLabels: {
            type: Boolean,
            default: false
        },
        dragQuestions: {
            type: Boolean,
            default: false
        },
        dragReferences: {
            type: Boolean,
            default: false
        },
        disableToggleRow: {
            type: Boolean,
            default: false
        },
        showTag: {
            type: Boolean,
            default: false
        },
        showStatus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchingValue: {
            type: String,
            default: ''
        },
        dataName: {
            type: String,
            default: 'prestatie'
        },
        contextName: {
            type: String,
            default: ''
        },
        noDisable: {
            type: Boolean,
            default: false
        },
        disableEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showQuestionModal: false,
            showReferenceModal: false,
            showEditLabelModal: false,
            showDeleteLabelModal: false,
            configuringIndicator: null,
            configuringLabel: null,
            isEditingIndicator: false,
        }
    },
    methods: {
        toggleShowRow(row) {
            if(this.disableToggleRow) return this.$emit('selectedRow', row)
            this.$emit('toggleShowRow', row)
        },
        handleOrderChanged(e) {
            this.$emit('orderUpdated', this.labels)
        },
        indicatorOrderUpdated({ labelId, indicators }) {
            this.$emit('indicatorOrderUpdated', { labelId, indicators });
        },
        handleAddQuestion(label) {
            this.configuringIndicator = {
                labelIds: [label.id],
                type: 'question'
            }
            if(this.contextName === 'formulier' && this.$route.fullPath.includes('formtemplates')) 
                this.configuringIndicator['formtemplateIds'] = [this.$route.params.id]
            if(this.contextName === 'projecttemplate' && this.$route.fullPath.includes('projecttemplates')) 
                this.configuringIndicator['projecttemplateIds'] = [this.$route.params.id]

            this.showQuestionModal = true;
        },
        handleAddReference(label) {
            this.configuringIndicator = {
                labelIds: [label.id],
                type: 'reference'
            }
            if(this.contextName === 'formulier' && this.$route.fullPath.includes('formtemplates')) 
                this.configuringIndicator['formtemplateIds'] = [this.$route.params.id]
            if(this.contextName === 'projecttemplate' && this.$route.fullPath.includes('projecttemplates')) 
                this.configuringIndicator['projecttemplateIds'] = [this.$route.params.id]

            this.showReferenceModal = true;
        },
        handleCancelEditIndicator() {
            this.configuringIndicator = null;
            this.isEditingIndicator = false;
            this.showQuestionModal = false;
            this.showReferenceModal = false;
        },
        handleIndicatorSaved(indicator) {
            this.$emit('indicatorUpdated', indicator);
            this.handleCancelEditIndicator();
        },
        handleIndicatorsAdded(indicators) {
            this.$emit('indicatorAdded', indicators);
            this.handleCancelEditIndicator();
        },
        handleIndicatorDeleted(indicator) {
            this.$emit('indicatorDeleted', indicator);
            this.handleCancelEditIndicator();
        },
        handleIndicatorCreated(indicator) {
            this.$emit('indicatorCreated', indicator);
            this.handleCancelEditIndicator();
        },
        handleCancelEditLabel() {
            this.configuringLabel = null;
            this.showEditLabelModal = false;
        },
        handleCancelDeleteLabel() {
            this.configuringLabel = null;
            this.showDeleteLabelModal = false;
        },
        handleEditQuestion(indicator) {
            this.configuringIndicator = { ...indicator };
            this.isEditingIndicator = true;
            this.showQuestionModal = true;
        },
        handleAction(option, label) {
            if(option.name === 'Naam wijzigen' || option.name === 'Omschrijving wijzigen') {
                this.showEditLabelModal = true;
                this.configuringLabel = { ...label };
            } else if (option.name === `Deactiveer ${this.dataName}`) {
                label.status = 'disabled'
                this.$emit('labelUpdated', label)
            } else if (option.name === `Activeer ${this.dataName}`) {
                label.status = 'active'
                this.$emit('labelUpdated', label)
            } else if(option.name === `Verwijder ${this.dataName}`) {
                this.showDeleteLabelModal = true;
                this.configuringLabel = { ...label };
            }
        },
        handleEditLabelDescription(label) {
            this.showEditLabelModal = true; 
            this.configuringLabel = { ...label };
        },
        capitalizeFirstLetter(string) {
            return string.substr(0, 1).toUpperCase() + string.substr(1, string.length-1)
        },
        handleUpdateLabel() {
            if(this.labelNameExists) return this.$store.commit('notify', { type: 'info', message: `Er bestaat al een ${this.dataName} met deze naam` })
            this.$emit('labelUpdated', this.configuringLabel);
            this.showEditLabelModal = false;
        },
        handleDeleteLabel() {
            this.$emit('labelDeleted', this.configuringLabel);
            this.showDeleteLabelModal = false;
        },
    },
    computed: {
        deleteMessage: function() {
            let message = '';
            const labelName = this.configuringLabel.name
            if(this.dataName === 'formulier') message = `Weet je zeker dat je het formulier <span style="font-weight: 500;">${labelName}</span> wilt verwijderen?`
            else if(this.dataName === 'projecttemplate') message = `Weet je zeker dat je het projecttemplate <span style="font-weight: 500;">${labelName}</span> wilt verwijderen?`
            else if(this.contextName === 'prestatie') message = `Weet je zeker dat je de prestatie <span style="font-weight: 500;">${labelName}</span> wilt verwijderen? <br>De prestatie en ingevulde kenmerken en vragen worden niet verwijderd uit bestaande projecten.`
            else if(this.contextName === 'formulier') message = `Weet je zeker dat je de prestatie <span style="font-weight: 500;">${labelName}</span> uit dit formulier wilt verwijderen? <br>De prestatie en ingevulde kenmerken en vragen worden niet verwijderd uit bestaande formulieren.`
            else if(this.contextName === 'projecttemplate') message = `Weet je zeker dat je de prestatie <span style="font-weight: 500;">${labelName}</span> uit dit projecttemplate wilt verwijderen? <br>De prestatie en ingevulde kenmerken en vragen worden niet verwijderd uit bestaande projecten.`

            return message
        },
        labelNameExists: function() {
            if(!this.showEditLabelModal) return false
            return this.allLabels.some(label => label.name.toLowerCase().trim() === this.configuringLabel.name.toLowerCase().trim() && label.id !== this.configuringLabel.id);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.standard-set {
    width: 18px;
    object-fit: contain;
}

.labels-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    &.questions {
        margin-top: 10px;
        margin-inline: 24px;
    }
}

.modal {
    width: 800px;

    .header,
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-block: 48px;

        &.small-padding {
            padding-block: 24px;
        }

        .name {
            font-weight: 500;
        }

        .line {
            display: flex;
            align-items: center;

            .inline {
                display: flex;
                align-items: center;
                gap: 8px;
                min-width: 200px;
                width: 200px;
            }

            .user-input {
                padding: 0;
            }
        }
    }

    .footer {
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

</style>
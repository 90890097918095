<template>
    <svg :color="color" :width="width" :height="height" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9307 12.0858L13.4489 11.952L13.9307 12.0858ZM1.91308 11.6601L3.97369 5.47824L3.02501 5.16201L0.964401 11.3438L1.91308 11.6601ZM4.44803 5.13635H14.6844V4.13635H4.44803V5.13635ZM15.1661 5.77017L13.4489 11.952L14.4125 12.2196L16.1296 6.03782L15.1661 5.77017ZM12.9672 12.3182H2.38743V13.3182H12.9672V12.3182ZM13.4489 11.952C13.3888 12.1684 13.1918 12.3182 12.9672 12.3182V13.3182C13.641 13.3182 14.2321 12.8689 14.4125 12.2196L13.4489 11.952ZM14.6844 5.13635C15.015 5.13635 15.2546 5.45158 15.1661 5.77017L16.1296 6.03782C16.3951 5.08203 15.6763 4.13635 14.6844 4.13635V5.13635ZM3.97369 5.47824C4.04175 5.27407 4.23282 5.13635 4.44803 5.13635V4.13635C3.80239 4.13635 3.22918 4.5495 3.02501 5.16201L3.97369 5.47824ZM0.964401 11.3438C0.640635 12.3151 1.36359 13.3182 2.38743 13.3182V12.3182C2.04615 12.3182 1.80516 11.9838 1.91308 11.6601L0.964401 11.3438Z" fill="currentColor"/>
        <path d="M4.79404 1.91834L4.37802 2.19569L4.79404 1.91834ZM1.5 11.8182V2H0.5V11.8182H1.5ZM2 1.5H3.64663V0.5H2V1.5ZM4.06266 1.72265L4.37802 2.19569L5.21007 1.64099L4.89471 1.16795L4.06266 1.72265ZM5.62609 2.86364H13.6364V1.86364H5.62609V2.86364ZM14.1364 3.36364V4.18182H15.1364V3.36364H14.1364ZM2 13.3182H2.36364V12.3182H2V13.3182ZM13.6364 2.86364C13.9125 2.86364 14.1364 3.0875 14.1364 3.36364H15.1364C15.1364 2.53521 14.4648 1.86364 13.6364 1.86364V2.86364ZM4.37802 2.19569C4.65622 2.61298 5.12456 2.86364 5.62609 2.86364V1.86364C5.45892 1.86364 5.3028 1.78009 5.21007 1.64099L4.37802 2.19569ZM3.64663 1.5C3.81381 1.5 3.96993 1.58355 4.06266 1.72265L4.89471 1.16795C4.61651 0.750651 4.14816 0.5 3.64663 0.5V1.5ZM1.5 2C1.5 1.72386 1.72386 1.5 2 1.5V0.5C1.17157 0.5 0.5 1.17157 0.5 2H1.5ZM0.5 11.8182C0.5 12.6466 1.17157 13.3182 2 13.3182V12.3182C1.72386 12.3182 1.5 12.0943 1.5 11.8182H0.5Z" fill="currentColor"/>
    </svg>

</template>

<script>
export default {
    name: 'Box',
    props: {
        color: {
            type: String,
            default: '#ADB5BD',
        },
        width: {
            type: [Number, String],
            default: 17,
        },
        height: {
            type: [Number, String],
            default: 14,
        },
    },
};
</script>

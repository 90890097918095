<template>
    <q-step
        :number="index"
        :id="index"
        :name="step.name"
        :about="step.about"
        :value="step.value"
        imageHref="/statics/img/ColumnTable.png"
        class="step"
        width="90%"
    >
        <indicator-table
            v-model="referenceContexts"
            :loading="loading"
            :dropdownOptions="dropdownOptions"
            :report="report"
            :disabled="disabled"
            :filter="filter"
            searchable
            answerValue
            showWarningTooltip
            @input="onTableUpdated"
            @filterUpdated="handleFilterUpdated"
            @searchUpdated="handleSearchUpdated"
        ></indicator-table>
    </q-step>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

import IndicatorTable from '../indicatortable/IndicatorTable.vue';

import { getIndicatorCondition } from '../helper';
import { compareReferenceContextNames } from "../utils";

export default {
    name: 'report-step-requirements',
    components: {
        IndicatorTable,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        // the index of the step the report flow is currently in
        activeStep: {
            type: Number,
            default: null
        },
        report: {
            type: Object,
            required: true,
        },
        indicators: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            referenceContexts: [],
            loading: true,
            disabled: false,
            filter: {
                search: '',
                indicator: 'reference',
            },
            initialised: false,
            dropdownOptions: [
                {
                    label: 'Projecten',
                    value: 'project',
                },
                {
                    label: 'Kenmerken',
                    value: 'reference',
                },
                {
                    label: 'Vragen',
                    value: 'question',
                },
            ],
            activeOrganisation: this.$store.getters.getCurrentOrganisation.id,
            activeProducts: this.$store.getters.getActiveProducts,
        };
    },
    methods: {
        onTableUpdated(contexts) {
            this.referenceContexts = contexts;

            const conditions = this.buildConfigurationConditions();
            
            this.disabled = conditions.length >= 10;

            if (this.disabled)
                this.$store.commit('notify', { type: 'warning', message: 'Je kunt maximaal 10 voorwaarden selecteren. Kom je er niet uit? Neem dan contact op met onze helpdesk.' });

            const updatedReport = { ...this.report };
            updatedReport.configuration.conditions[`${this.filter.indicator}s`] = conditions;

            this.$emit('reportUpdated', updatedReport);
        },
        handleFilterUpdated(filter) {
            this.loading = true;
            this.report.configuration.conditions = {
                references: [], 
                questions: [],
                forms: [], 
                users: [],
                projects: [], 
                formTemplates: []
            }
            this.filter = filter;
            this.updateReferenceContexts();
        },
        handleSearchUpdated(search) {
            this.filter.search = search;
        },
        async updateReferenceContexts() {
            if(this.activeStep !== this.index-1 && this.initialised) return
            if (this.indicators.length === 0) return this.loading = true

            if (!this.initialised) this.loading = true;
            const indicatorType = this.filter.indicator;

            if (indicatorType === 'project') {
                this.getReferenceContextsForProjects();
            } else if (indicatorType === 'reference' || indicatorType === 'question') {
                this.getReferenceContextsForIndicatorsOrQuestions();
            }
        },
        async getReferenceContextsForProjects() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            const projectQuery = gql`
                query getProjects($where: JSON) {
                    projects(where: $where) {
                        id
                        name
                    }
                }
            `;
            const projects = await this.$apollo.query({
                query: projectQuery,
                variables: {
                    where: {
                        organisationId: organisationId,
                    },
                },
            });

            const projectsList = projects.data.projects;
            
            const projectResult =  await new Promise(async (resolve, reject) => {
                let filterable = false;
                let id = 'project';
                let name = 'Projectnaam';
                let type = 'project';
                let checked = false;
                let filters = { value: undefined, type: 'eq' };
                let answerType = 'radio';
                let answerValues = undefined;  
                let value = undefined; 
                let values = undefined; 

                answerValues = projectsList.map((project) => {
                    return {
                        label: project.name, 
                        value: project.id,
                    }
                }); 
                
                const projectProjection = this.report.configuration.conditions.projects.find(projection => projection.id === 'project');
                if (projectProjection) {
                    filters = projectProjection.filters;
                    checked = true;
                    value = projectProjection.filters.value;
                    values = projectProjection.filters.value;
                }

                resolve({
                    id,
                    name,
                    type,
                    checked,
                    filters,
                    answerType,
                    answerValues,
                    filterable,
                    value, 
                    values,
                    singleValue: true
                });
            });

            const formTemplateQuery = gql`
                query FormTemplates($where: JSON) {
                    formtemplates(where: $where) {
                        id
                        name
                    }
                }
            `;

            const crowFormTemplates = ["Project_details", "Works_client_form_template", "Services_client_form_template", "Services_contractor_form_template", "Works_contractor_form_template"]

            const formTemplates = await this.$apollo.query({
                query: formTemplateQuery,
                variables: {
                    where: {
                        organisationId: organisationId,
                        name__nin: crowFormTemplates, 
                        name__not_contains: 'reference'
                    },
                },
            });

            const formTemplatesList = formTemplates.data.formtemplates;

            const formTemplateResult = await new Promise(async (resolve, reject) => {
                let filterable = false;
                let id = 'form'; 
                let formId = 'formType';
                let name = 'Formulier template';
                let type = 'average-forms-over-formtype-project';
                let checked = false;
                let visible = true;   
                let filters = { value: undefined, type: 'eq' };
                let answerType = 'radio';
                let answerValues = undefined; 
                let value = undefined;
                let values = undefined; 

                answerValues = formTemplatesList.map((template) => {
                    return {
                        label: template.name, 
                        value: template.id,
                    }
                });

                const formProjection = this.report.configuration.conditions.projects.find(projection => projection.id === 'form');
                if (formProjection) {
                    filters = formProjection.filters;
                    checked = true;
                    value = formProjection.formTypeId;
                    values = formProjection.formTypeId;
                }

                resolve({
                    id,
                    filterable, 
                    formId,
                    name,
                    type,
                    checked,
                    visible,
                    filters, 
                    answerType,
                    answerValues,
                    value, 
                    values,
                    singleValue: true
                });
            });

            this.referenceContexts = [
                {
                    id: 'default-project',
                    items: [projectResult],
                    title: 'projects', 
                    masterId: 'default-project'
                }, 
                {
                    id: 'default-form', 
                    items: [formTemplateResult], 
                    title: 'forms', 
                    masterId: 'default-form'
                }
            ];
            this.initialised = true;
            this.loading = false;
        },
        async getReferenceContextsForIndicatorsOrQuestions() {
            const indicatorType = this.filter.indicator;

            const relevantIndicators = this.indicators.filter((indicator) => indicator.type === indicatorType);

            const contexts = this.labels.map((label) => {
                return { id: label.id, title: label.name, masterId: label.masterId };
            });

            let referenceConditions = this.report.configuration.conditions.references;
            let questionConditions = this.report.configuration.conditions.questions;

            const parsedContexts = contexts.map(context => {
                const indicators = relevantIndicators.filter(indicator => 
                    indicator.labelIds.includes(context.id) || 
                    indicator.labelIds.includes(context.masterId)
                );

                context.items = indicators.map(indicator => {
                    let checked = false;
                    let filters = { value: undefined, values: null, type: 'eq' };

                    let matchingCondition = 
                        referenceConditions.find(condition => condition.indicatorId === indicator.id) ||
                        questionConditions.find(condition => condition.indicatorId === indicator.id);
                                                    
                    if (matchingCondition) {
                        filters.value = matchingCondition.filters.value;
                        filters.values = matchingCondition.filters.values;
                        filters.type = matchingCondition.filters.type;
                        checked = matchingCondition.checked;
                    }

                    return {
                        id: indicator.id,
                        name: indicator.name,
                        type: indicator.type,
                        answerType: indicator.answerType,
                        displayType: indicator.displayType,
                        unit: indicator.unit,
                        answerValues: indicator.answerValues,
                        filters,
                        checked,
                        masterIndicatorId: indicator.masterId
                    };
                });

                return context
            });

            this.referenceContexts = parsedContexts.filter(context => context.items.length > 0);
            this.initialised = true;
            this.loading = false;

            return this.referenceContexts.sort(compareReferenceContextNames);
        },
        buildConfigurationConditions() {
            const conditions = [];

            this.referenceContexts.forEach((context) => {
                context.items
                    .filter(item => item.checked)
                    .forEach(item => {                   
                        const condition = getIndicatorCondition(item);

                        conditions.push(condition);
                    });
            });

            return conditions;
        },        
        async getOrganisations(organisationType) {
            return new Promise((resolve, reject) => {
                this.$apollo
                    .query({
                        query: gql`
                            query Organisations($first: Int, $skip: Int, $where: JSON, $sort: String, $caseSensitive: Boolean) {
                                organisations(
                                    first: $first
                                    skip: $skip
                                    where: $where
                                    sort: [$sort]
                                    caseSensitive: $caseSensitive
                                ) {
                                    id
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                AND: [
                                    
                                    {
                                        name__includes: this.filter.search || '',
                                    },
                                    organisationType !== 'Branch'
                                        ? {
                                              products__some: {
                                                  slug__contains: organisationType.toLowerCase(),
                                                  enabled: true,
                                              },
                                          }
                                        : {},
                                    organisationType !== 'Branch'
                                        ? {
                                              type: 'main',
                                          }
                                        : {
                                              type: 'branch',
                                          },
                                ],
                            },
                        },
                    })
                    .then((result) => {
                        resolve(result.data.organisations);
                    })
                    .catch((error) => reject(error.message));
            });
        },
        checkValid() {
            if(this.filter.indicator === 'project') {
                const projectsConditions = this.report.configuration.conditions.projects || [];
                const projectAnswerValues = projectsConditions[0] ? projectsConditions[0].filters.value : null;
                const hasProjectAnswer = Boolean(projectAnswerValues);
                const formTemplateValues = projectsConditions[1] ? projectsConditions[1].filters.value : null;
                const hasProjectTemplateAnswer = Boolean(formTemplateValues);
                this.$emit('requirementStepValidationUpdated', (hasProjectAnswer && hasProjectTemplateAnswer));
            } else this.$emit('requirementStepValidationUpdated', true)
        }
    },
    created() {
        const { conditions } = this.report.configuration;
        const keysWithValue = Object.entries(conditions);
        const activeCondition = keysWithValue.find(keyWithValue => keyWithValue[1].length > 0);
        if(!activeCondition) return
        
        const filterType = activeCondition[0];
        this.filter.indicator = filterType.substring(0, filterType.length - 1);
    },
    mounted() {
        this.updateReferenceContexts();
    },
    watch: {
        indicators: {
            handler() {
                this.updateReferenceContexts();
            },
            deep: true
        },
        report: {
            handler() {
                this.updateReferenceContexts();
                this.checkValid();
            },
            deep: true
        },
        filter: {
            handler() {
                this.checkValid()
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="9.61523"
      width="11.8461"
      height="9.33333"
      rx="1"
      :stroke="color"
    />
    <path
      d="M2.85083 6.38461V5.07211C2.85083 2.82315 4.67398 1 6.92294 1V1V1C9.17191 1 10.9951 2.82315 10.9951 5.07211V9.43589"
      :stroke="color"
      stroke-linecap="round"
    />
    <path
      d="M1.35889 12.1284H13.0255M1.35889 14.2823H13.0255M1.35889 16.4361H13.0255"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusCircle",
  props: {
    color: {
      type: String,
      default: "#495057",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>

<style></style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M7.423,3.679c0.195,0.195 0.196,0.512 0,0.707c-0.195,0.196 -0.511,0.196 -0.707,0.001l0.707,-0.708ZM4.033,1l-0.354,-0.353c0.195,-0.196 0.511,-0.196 0.707,-0.001l-0.353,0.354ZM1.353,4.39c-0.195,0.196 -0.511,0.196 -0.707,0.001c-0.195,-0.195 -0.195,-0.512 0,-0.707l0.707,0.706ZM4.537,7.99c0.001,0.276 -0.223,0.5 -0.499,0.5c-0.276,0 -0.5,-0.224 -0.501,-0.5l1,0ZM6.716,4.387l-3.037,-3.033l0.707,-0.708l3.037,3.033l-0.707,0.708ZM4.386,1.353l-3.033,3.037l-0.707,-0.706l3.033,-3.037l0.707,0.706ZM3.537,7.99l-0.004,-6.99l1,0l0.004,6.99l-1,0Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowDown',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 8,
        },
        height: {
            type: [Number, String],
            default: 9,
        },
    },
};
</script>

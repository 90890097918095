<template>
  <label class="radio" :style="'width:'+width" tabindex="0" @keydown.enter="handleInput(label !== value)">
    <div>
      <input
        :id="id"
        type="radio"
        :name="name"
        :value="label"
        :checked="label === value"
        :class="['radio', state, { checked: label === value }]"
        @input="handleInput"
        :disabled="disabled"
        tabindex="-1"
      />
    </div>
    <span>
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: "q-radio",
  status: "prototype",
  release: "0.0.1",
  props: {
    /**
     * Manually trigger various states of the radio.
     * `hover, disabled`
     */
    state: {
      type: String,
      default: null,
      validator: (value) => {
        return value.match(/(hover|checked|disabled)/);
      },
    },
    /**
     * The value of the radio input
     */
    label: {

    },
    /**
		 * Give radio an id
		 */
		id: {
		  type: String
		},
    /**
     * current value of the input
     */
    value: {
      type: [String, Number, Object, Array, Boolean],
    },
    /**
     * Give radiobutton a name
     */
    name: {
      type: [String, Number],
    },
    /**
     * Total width of the element
     */
    width: {
      type: String,
      default: '100%'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput() {
      if (this.disabled) {
        return false;
      }

      this.$emit('input', this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.radio {
  @include reset;
  @include stack-space($space-m);
  @include inline-space($space-m);
  display: flex;

  font-family: $font-text;
  font-weight: $weight-normal;
  font-size: $size-m;
  line-height: 16px;
  cursor: pointer;

  &:has(input[type="radio"]:checked) {
    font-weight: 500;
  }

  &:disabled {
    cursor: default !important;
  }

  input,
  label {
    user-select: none;

    #text {
      padding-left: 10px;
    }
  }

  div {
    margin: 0 12px 0 0;
    input[type="radio"] {
    position: relative;
    border: 1px solid $color-grey-5;
    padding: 0;
    text-align: center;
    width: 16px;
    height: 16px;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 50px;
    color: rgba(white, 0);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:disabled {
      cursor: default !important;
    }

    &:before {
      
    }
    &:focus {
      outline: 0;
      border: 1px solid darken($color-grey-5, 5%);
    }
    &:active {
      outline: none;
    }
    &:hover {
      outline: none;
      cursor: pointer;
    }
    &.hover,
    &:hover {
      outline: none;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='%23ADB5BD'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8px 8px;
    }
    &.checked {
      border: 1px solid $color-primary;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%2322B8CF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 10px;
      transition: background 0.2s;
      color: white;

    }
  }
  }
  &.inverted {
    input[type="radio"] {
      border: 1px solid black;
      &:focus {
        border: 1px solid darken(black, 5%);
      }
      &:checked {
        background: black;
      }
    }
  }
}
</style>

<docs>
  ```jsx
  <div>
    <q-radio name="radio" >Radiobutton</q-radio>
    <br>
    <q-radio name= "radio" state="hover">Hover</q-radio>
    <br>
    <q-radio name= "radio" state= "checked">Checked</q-radio>
  </div>
  ```
</docs>

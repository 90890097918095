<template>
    <div class="detail-page-padding">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)">Instellingen</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Integraties</h1>

                    <div>
                        <q-button variation="secondary" size="medium" @click="createKey = true"
                            >API sleutel aanmaken</q-button
                        >
                    </div>
                </div>
                <p>
                    Hier onder bevinden zich de API sleutels die binnen jouw organisatie aangemaakt zijn.
                    <br>
                    Je kunt ze hier beheren, aanpassen of verwijderen.
                </p>
            </div>
        </div>

        <div class="panel q-card-small shared pb-xs">
            <div class="head space-between vertical-center">
                <h2>API sleutels</h2>
                <!-- <q-input 
                    labelPosition="inside"
                    size="small"
                    style="width: 170px;"
                    variation="blank"
                    placeholder="Zoek een API sleutel"
                ></q-input> -->
            </div>

            <q-table class="padding-left padding-right" :data="keys" :columns="keyColumns" :loading="loading">
                <template v-slot:row="{ row, column }">
                    <span v-if="column == 'name'">
                        {{ row.name }}
                    </span>
                    <span v-else-if="column == 'key'" class="flex align-center key padding-right">
                        <span>{{ row.key }}</span>
                        <div>
                            <q-button
                                variation="light"
                                size="xsmall"
                                @click="copy(row.key, `Sleutel '${row.name}' is gekopiëerd`)"
                                class="ml-s"
                                >Kopiëren</q-button
                            >
                        </div>
                    </span>
                    <span v-else-if="column == 'datum'" class="padding-right">
                        {{ date(row.creationDate) }}
                    </span>
                    <span v-else-if="column == 'action'" class="action">
                        <q-button variation="light" size="xsmall" @click="update(row.id)">Wijzigen</q-button>
                    </span>
                </template>
            </q-table>
        </div>

        <q-popup v-if="createKey" showCloseButton @close="closePopup">
            <createKeyModal @close="closePopup"></createKeyModal>
        </q-popup>

        <q-popup v-if="updateKey" showCloseButton @close="closePopup">
            <updateKeyModal
                :apiKey="clickedKey"
                @close="closePopup"
                @removeKey="removeKey = true"
            ></updateKeyModal>
        </q-popup>

        <q-popup v-if="removeKey" @close="closePopup">
            <removeKeyModal :apiKey="clickedKey" @close="closePopup"></removeKeyModal>
        </q-popup>
    </div>
</template>

<script>
import _ from 'lodash';
import ArrowButton from '@/components/ArrowButton';
import createKeyModal from '@/pages/app/settings/keyCreateModal';
import updateKeyModal from '@/pages/app/settings/keyUpdateModal';
import removeKeyModal from '@/pages/app/settings/keyRemoveModal';
import { getDate } from '@/assets/js/utils.js';

import { ORGANISATION_API_KEYS } from '@/graphql/queries';

export default {
    components: {
        ArrowButton,
        createKeyModal,
        updateKeyModal,
        removeKeyModal
    },
    data() {
        return {
            keyColumns: [
                {
                    field: 'name',
                    label: 'Naam',
                    width: '120px'
                },
                {
                    field: 'key',
                    label: 'Sleutel',
                    width: '220px'
                },
                {
                    field: 'datum',
                    label: 'Aanmaakdatum',
                    width: '90px'
                },
                {
                    field: 'action',
                    label: '',
                    width: '40px',
                    align: 'right'
                }
            ],
            keys: [],
            createKey: false,
            updateKey: false,
            removeKey: false,
            loading: false,
            clickedKey: null,
            copied: false,
            organisationLoading: true
        };
    },
    methods: {
        closePopup(e) {
            if (e) this.keys = e.integration.keys;

            this.createKey = false;
            this.updateKey = false;
            this.removeKey = false;
        },
        create() {
            this.createKey = true;
        },
        update(id) {
            this.clickedKey = this.keys.find(u => u.id === id);
            this.updateKey = true;
        },
        remove(key) {
            this.removeKey = true;
        },
        copy(text, notifyMessage) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.$store.commit('notify', {
                type: 'info',
                message: notifyMessage ? notifyMessage : 'Waarde gekopieerd.'
            });
        },
        getOrganisationApiKeys() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .query({
                    query: ORGANISATION_API_KEYS,
                    variables: {
                        id: organisationId
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    if (!result.data.organisation.integration || !result.data.organisation.integration.keys) return;

                    this.keys = result.data.organisation.integration.keys;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        date(e) {
            return getDate(e);
        }
    },
    mounted() {
        this.getOrganisationApiKeys();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

span input {
    cursor: pointer;
    color: #aeb5bc;
    display: inline-block;
    width: 70%;
    height: 16px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    border: 1px solid #aeb5bc;
    border-radius: 4px;
    transition: 0.3s;
    z-index: 1;

    &:hover {
        color: #00a1ae;
        border: 1px solid #00a1ae;
    }
}

.panel {
    width: 100%;
    // padding-bottom: 10px;
    margin-bottom: 40px;

    &.shared {
        .head {
            padding: 40px;
        }
    }

    & .padding-left {
        padding-left: 40px;
    }

    & .padding-right {
        padding-right: 40px;
    }
}
</style>

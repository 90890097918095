<template>
    <svg :width="width" :height="height" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.19189 4.64548L5.39613 9.33252L12.4032 1" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'Check',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: [Number, String],
            default: 8,
        },
        height: {
            type: [Number, String],
            default: 9,
        },
    },
};
</script>

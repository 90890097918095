<template>
    <div class="full-width">
        <div class="full-width justify-center">
            <div class="welcome-section">
                <div class="justify-center">
                    <q-avatar size="large" :img="user.avatar" class="avatar">{{ initials }}</q-avatar>
                </div>
                <div class="margin-top">
                    <h1>Hallo {{ user.firstName ? user.firstName : '' }} {{ user.lastName ? user.lastName : '' }}</h1>

                    <p>
                        Beheer je gegevens, privacy en beveiliging, of vraag toegang tot bedrijven op basis van je
                        e-mailadres.
                    </p>
                </div>
            </div>
        </div>

        <div class="full-width justify-center mt-l">

            <div class="justify-center settings-options">
                <div class="q-card-small">
                    <div>
                        <h2>Je gegevens</h2>
                        <p>De gegevens in je account bekijken en aanpassen, en personaliseer je account.</p>
                    </div>
                    <q-section hPadding="40" vPadding="16" borderTop>
                        <q-button to="profile/settings" variation="blank" size="medium">Beheer je gegevens</q-button>
                    </q-section>
                </div>

                <div style="width: 35px"></div>

                <div class="q-card-small">
                    <div>
                        <h2>Organisatiebeheer</h2>
                        <p>
                            Bekijk en beheer je toegang tot organisaties. Vraag toegang aan en bekijk de status van je
                            aanvraag.
                        </p>
                    </div>
                    <q-section hPadding="40" vPadding="16" borderTop>
                        <q-button to="profile/organisations" variation="blank" size="medium"
                            >Beheer je organisaties</q-button
                        >
                    </q-section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Profile',
    data() {
        return {
            user: this.$store.getters.getUser,
            initials: ''
        };
    },
    created() {
        this.initials = '';

        if (this.user.firstName) this.initials += this.user.firstName.charAt(0).toUpperCase();
        if (this.user.lastName) {
            const pieces = this.user.lastName.split(' ');
            this.initials += pieces[pieces.length - 1].charAt(0).toUpperCase();
        }
    }
};
</script>

<style lang="scss" scoped>
.full-width {
    width: 100%;
}

.welcome-section {
    text-align: center;
    width: 500px;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
}

.welcome-section > h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.margin-top {
    margin-top: 25px;
}

.welcome-section > .margin-top > p {
    margin-top: 5px;
}

.settings-options > .q-card-small > div {
    padding: 35px;
}

.q-card-small {
    width: 440px;
}

.q-card-medium {
    width: 640px;
}

.settings-options > .q-card-small > div > h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
}

.settings-options > .q-card-small > div > p {
    margin-top: 10px;
}

.show {
    max-height: 250px;
}
.hide {
    max-height: 0px;
}

.account-intro {
    padding: 35px;

    p {
        margin-top: 8px;
    }
}
.request-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 35px 35px 35px;

    .request-buttons {
        display: flex;
        justify-content: flex-end;
    }
}
</style>

<template>
  <div class="q-arrowbox" :style="getWrapperStyle">
        <div class="arrow"></div>
        <div class="content" :style="getContentStyle">
            <div v-if="title" class="title">
                <h4>{{title}}</h4>
            </div>
            <slot/>
        </div>
  </div>
</template>

<script>
export default {
    name: 'q-arrowbox',
    props: {
        /**
         * Title shown above the content
         */
        title: {
            type: String
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 300
        }
    },
    computed: {
        getWrapperStyle: function() {
            return `width:${this.width}px`;
        },
        getContentStyle: function() {
            return `width:${this.width - 48}px;max-height:${this.height}px;`
        }
    }
}
</script>

<style scoped>

.q-arrowbox {
    position: relative;

    border: 1px solid #DEE2E6;
    border-radius: 5px;
    background-color: white;
    z-index: 10;


}

.content {
    height: 100%;
    overflow-y: scroll;

    padding: 24px;
}

.arrow {
    width: 15px;
    height: 15px;
    border-left: 1px solid #DEE2E6;
    border-top: 1px solid #DEE2E6;
    
    border-top-left-radius: 5px;
    background-color: white;
    position: absolute;
    top: -9px;
    left: 56px;

    transform: rotate(45deg);
}

.title {
    margin: 0 0 20px 0;
}

</style>
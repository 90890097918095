<template>
    <div>
        <div class="input-wrapper">
            <div class="search-input">
                <q-icon type="search"></q-icon>
                <input
                    id="searchInput"
                    type="text"
                    placeholder="Typ om te zoeken"
                    v-model="searchValue"
                    @input="handleFilterUpdated"
                />
            </div>
            <q-options
                v-if="filterInput.length == 0 && !report.id === 'crow-report-default'"
                :label="selectedFilterCategory.name"
                v-model="selectedFilterCategory"
                @input="handleFilterUpdated"
                :options="report.id === 'crow-report-default' ? dropdownOptionsCrowReport : dropdownOptions"
                icon="small-arrow-down"
                iconWidth="9"
                iconHeight="6"
                color="#373836"
            ></q-options>
            <!-- <q-icon v-else class="clear-input-icon" @click="resetInput" type="close"></q-icon> -->
        </div>

        <div v-if="filterInput.length == 0 && selectedFilterCategory.name === 'Geen filter'" class="filter-types">
            <div class="recent-filters filter-container">
                <p class="label">Recent gebruikte filters</p>
                <div class="tags-wrapper">
                    <q-tag
                        v-for="(tag, index) in recentTags"
                        :key="index"
                        class="tag"
                        variation="default"
                        size="medium"
                        >{{ getTagLabel(tag) }}</q-tag
                    >
                </div>
            </div>
            <div class="chosen-filters filter-container">
                <p class="label">Veelgekozen filters</p>
                <div class="tags-wrapper">
                    <q-tag
                        v-for="(tag, index) in chosenTags"
                        :key="index"
                        class="tag"
                        variation="default"
                        size="medium"
                        >{{ getTagLabel(tag) }}</q-tag
                    >
                </div>
            </div>
        </div>
        <!-- <div v-else  class="searched-categories">
            <div v-for="(category, index) in searchedCategories" :key="index" class="category">
                <div class="head">
                    <p class="name">{{ category.name }}</p>
                    <p class="results">{{ category.results.length }} resultaten</p>
                </div>
                <div class="results-wrapper">
                    <div v-for="(result, index) in category.results" :key="index" class="result">
                        <p>{{ result.name }}</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { getDate } from '../../../../assets/js/utils';

export default {
    name: 'indicator-filter',
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            filterInput: '',
            searchValue: '',
            selectedFilterCategory: {
                    name: 'Kenmerken',
                    value: 'reference',
            },
            dropdownOptions: [
                {
                    name: 'Kenmerken',
                    value: 'reference',
                },
                {
                    name: 'Vragen',
                    value: 'question',
                }
            ],
            dropdownOptionsCrowReport: [
                {
                    name: 'Kenmerken', 
                    value: 'reference',
                }, 
                {
                    name: 'Overige filters', 
                    value: 'question'
                }
            ],
            recentTags: [
                {
                    type: 'Projectleider',
                    value: 'Leen Valk',
                },
                {
                    type: 'Projectleider',
                    value: 'Paul Kegel',
                },
                {
                    type: 'Einddatum project',
                    value: 1687059824300,
                },
            ],
            chosenTags: [
                {
                    type: 'Projectleider',
                    value: 'Leen Valk',
                },
                {
                    type: 'Projectleider',
                    value: 'Paul Kegel',
                },
                {
                    type: 'Einddatum project',
                    value: 1687059824300,
                },
            ],
            searchedCategories: [
                {
                    name: 'Opdrachtgevers',
                    results: [
                        {
                            name: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                        {
                            name: 'Gemeente Amsterdam',
                            value: '1234-5678-1234-5678',
                        },
                    ],
                },
                {
                    name: 'Projecten',
                    results: [
                        {
                            name: 'Aanleg rondweg Arnhem',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                        {
                            name: 'Herinrichting Waaldijk Arnhem',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                        {
                            name: 'Renovatie kunstwerken Centrum',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                    ],
                },
                {
                    name: 'Projecten',
                    results: [
                        {
                            name: 'Aanleg rondweg Arnhem',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                        {
                            name: 'Herinrichting Waaldijk Arnhem',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                        {
                            name: 'Renovatie kunstwerken Centrum',
                            matchingLabel: 'Gemeente Arnhem',
                            value: '1234-5678-1234-5678',
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        handleFilterUpdated() {
            const filter = {
                search: this.searchValue,
                indicator: this.selectedFilterCategory.value,
            };
            this.$emit('filterUpdated', filter);
        },
        resetInput() {
            this.searchValue = ''
            const filter = {
                search: this.searchValue,
                indicator: this.selectedFilterCategory.value,
            };
            this.$emit('filterUpdated', filter);
        },
        getTagLabel(tag) {
            let value = tag.value;
            if (tag.type.toLowerCase().includes('datum')) value = getDate(value);
            return `${tag.type} ${value}`;
        },
    }
};
</script>

<style lang="scss" scoped>
.filter-box {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(222, 226, 230, 0.5);
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    .dropdown {
        display: flex;
        justify-content: space-between;
        padding-inline: 8px;
        width: 200px;
        border-right: 1px solid rgba(222, 226, 230, 0.5);
    }

    .search-box-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        input {
            width: calc(100% - 60px);
            border: none;
            outline: none;
            padding: 18px 30px;

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                color: #adb5bd;
            }
        }

        .query-results {
            position: absolute;
            transform: translate(0, -50%);
            top: 50%;
            right: 20px;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #c4c4c4;
        }
    }
}
.filter-menu {
    position: fixed;
    right: 0;
    top: 81px;
    width: 480px;
    transform: translateX(105%);
    transition: transform 0.4s ease;
    height: calc(100vh - 81px);
    background-color: white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    &.opened {
        transform: translateX(0);
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 32px;
        border-bottom: 1px solid #dddddd;

        .search-input {
            display: flex;
            align-items: center;
            gap: 4px;
            flex-grow: 1;

            input {
                width: 100%;
                outline: none;
                border: none;
            }
        }

        .clear-input-icon {
            cursor: pointer;
            // transform: scale(0.8);
        }
    }

    .filter-types {
        margin-bottom: 40px;

        .filter-container {
            margin-top: 16px;

            .label {
                font-weight: 500;
                font-size: 13px;
            }

            .tags-wrapper {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px 6px;
                margin: 8px 0;

                .tag {
                    cursor: pointer;
                    transition: transform 0.2s ease;

                    &:hover {
                        transform: scale(1.03);
                    }
                    &:focus,
                    &:active {
                        transform: scale(0.98);
                    }
                }
            }
        }
    }

    .searched-categories {
        display: flex;
        flex-direction: column;

        .category {
            padding: 20px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #adb5bd;
            }

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                    color: #7f7f80;
                    font-weight: 500;
                    font-size: 15px;
                }
                .results {
                    color: #adb5bd;
                }
            }

            .results-wrapper {
                display: flex;
                flex-direction: column;
                gap: 6px;
                padding: 14px 0;

                .result {
                    padding: 6px 0;
                    border-radius: 4px;
                    cursor: pointer;

                    &:hover {
                        background-color: #f8f9fa;
                    }
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

</style>

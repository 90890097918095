<template>
    <div :class="['full-width', avatar ? 'no-scroll' : 'min-height']">
        <div class="justify-center">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)"
                    >Profiel</arrow-button
                >
            </div>
        </div>
        <div class="justify-center">
            <div class="settings-intro">
                <h1>Je gegevens</h1>
                <p>
                    De gegevens in je account bekijken en aanpassen,<br />
                    en personaliseer je account.
                </p>
            </div>
        </div>

        <div class="justify-center">
            <div class="q-card-small">
                <div class="account-intro">
                    <h3>Account</h3>
                    <p>
                        Wijzig je accountgegevens.<br />
                        Let op: je e-mailadres is gekoppeld aan bepaalde organisaties.
                    </p>
                </div>
                <div class="margin-top">
                    <q-section hPadding="40" vPadding="13" class="row">
                        <div class="col-xs-4 label">
                            <p>Naam</p>
                        </div>
                        <div
                            :class="[
                                editing.includes('name') ? 'fixed-width' : 'fixed-width',
                                editing.includes('name') ? 'col-xs-5' : 'col-xs-7',
                            ]"
                        >
                            <div v-if="editing.includes('name')" class="name-input">
                                <q-input labelPosition="inside"
                                    size="small"
                                    v-model="firstName"
                                    variation="blank"
                                    class="no-padding input-margin"
                                    placeholder="Voornaam"
                                ></q-input>
                                <q-input labelPosition="inside"
                                    size="small"
                                    v-model="lastName"
                                    variation="blank"
                                    class="no-padding input-margin"
                                    placeholder="Achternaam"
                                ></q-input>
                            </div>
                            <p v-else>{{ user.firstName }} {{ user.lastName }}</p>
                        </div>
                        <div :class="['end', editing.includes('name') ? 'col-xs-3' : 'col-xs-1']">
                            <div>
                                <div v-if="editing.includes('name')">
                                    <q-button variation="blank" size="xsmall" @click="toggle('name')"
                                        >Annuleren</q-button
                                    >
                                    <q-button variation="ghost" size="xsmall" @click="saveName()">Opslaan</q-button>
                                </div>
                                <q-button v-else variation="light" size="xsmall" @click="toggle('name')"
                                    >Wijzigen</q-button
                                >
                            </div>
                        </div>
                    </q-section>
                </div>

                <q-section hPadding="40" vPadding="13" borderTop class="row">
                    <div class="col-xs-4 label">
                        <p>E-mail</p>
                    </div>
                    <div
                        :class="[
                            editing.includes('email') ? 'fixed-width' : 'fixed-width',
                            editing.includes('email') ? 'col-xs-5' : 'col-xs-7',
                        ]"
                    >
                        <q-input labelPosition="inside"
                            v-if="editing.includes('email')"
                            size="small"
                            v-model="email"
                            variation="blank"
                            class="no-padding input-margin"
                            placeholder="E-mailadres"
                            @input="checkEmail"
                            :state="inputValidation.emailState"
                            :errorMessage="inputValidation.emailErrorMessage"
                        ></q-input>
                        <div v-else :class="{ emailText: user.changeEmail }">
                            {{ user.email }}
                            <p v-if="user.changeEmail" class="email-infotext">
                                Er is een e-mail gestuurd naar {{ user.changeEmail }} ter validatie.
                            </p>
                            <q-icon
                                v-if="user.status === 'active' && !user.changeEmail"
                                type="check-circle"
                                class="icon-padding"
                                color="#37B24D"
                            />
                            <div class="ml-s">
                                <q-tag
                                    v-if="user.status === 'new' && !user.changeEmail"
                                    variation="warning"
                                    size="small"
                                    >Niet gevalideerd</q-tag
                                >
                                <q-tag v-if="user.changeEmail" variation="info" size="small"
                                    >Wijziging aangevraagd</q-tag
                                >
                            </div>
                        </div>
                    </div>
                    <div :class="['end', editing.includes('email') ? 'col-xs-3' : 'col-xs-1']">
                        <div>
                            <div v-if="editing.includes('email')">
                                <q-button variation="blank" size="xsmall" @click="toggle('email')">Annuleren</q-button>
                                <q-button
                                    variation="ghost"
                                    size="xsmall"
                                    @click="saveEmail()"
                                    :disabled="inputValidation.emailState === 'error'"
                                    >Opslaan</q-button
                                >
                            </div>
                            <q-button v-else variation="light" size="xsmall" @click="toggle('email')"
                                >Wijzigen</q-button
                            >
                        </div>
                    </div>
                </q-section>

                <q-section hPadding="40" vPadding="13" borderTop class="row">
                    <div class="col-xs-4 label">
                        <p>Telefoon</p>
                    </div>
                    <div
                        :class="[
                            editing.includes('phone') ? 'fixed-width' : 'fixed-width',
                            editing.includes('phone') ? 'col-xs-5' : 'col-xs-7',
                        ]"
                    >
                        <q-input labelPosition="inside"
                            v-if="editing.includes('phone')"
                            size="small"
                            v-model="phone"
                            variation="blank"
                            class="no-padding input-margin"
                            placeholder="e.g. 0612345678"
                            @input="checkPhone"
                            :state="inputValidation.phoneState"
                            :errorMessage="inputValidation.phoneErrorMessage"
                        ></q-input>
                        <div v-else>
                            {{ user.phone }}
                        </div>
                    </div>
                    <div :class="['end', editing.includes('phone') ? 'col-xs-3' : 'col-xs-1']">
                        <div>
                            <div v-if="editing.includes('phone')">
                                <q-button variation="blank" size="xsmall" @click="toggle('phone')">Annuleren</q-button>
                                <q-button variation="ghost" size="xsmall" @click="savePhone()">Opslaan</q-button>
                            </div>
                            <q-button v-else variation="light" size="xsmall" @click="toggle('phone')"
                                >Wijzigen</q-button
                            >
                        </div>
                    </div>
                </q-section>

                <q-section hPadding="40" vPadding="13" borderTop class="row">
                    <div class="col-xs-4 label">
                        <p>Profielfoto</p>
                    </div>
                    <div
                        :class="[
                            editing.includes('avatar') ? 'fixed-width' : 'fixed-width',
                            editing.includes('avatar') ? 'col-xs-5' : 'col-xs-7',
                        ]"
                    >
                        <div>
                            <q-avatar :img="user.avatar" size="small"></q-avatar>
                        </div>
                    </div>
                    <div :class="['end', editing.includes('avatar') ? 'col-xs-3' : 'col-xs-1']">
                        <div>
                            <div v-if="editing.includes('avatar')">
                                <!-- <q-button
                                    v-if="!avatar"
                                    variation="blank"
                                    size="xsmall"
                                    @click="
                                        toggle('avatar');
                                        avatar = null;
                                        file = null;
                                    "
                                    >Annuleren</q-button
                                > -->
                            </div>
                            <input id="input" @change="saveFile" type="file" class="hidden" />
                            <label for="input">Wijzigen</label>
                            <!-- <q-button v-else variation="light" size="xsmall" @click="toggle('avatar')"
                                >Wijzigen</q-button
                            > -->
                        </div>
                    </div>
                </q-section>
            </div>
        </div>

        <div class="justify-center twofactor-margin">
            <div class="q-card-small">
                <div class="account-intro">
                    <h3>Beveiliging</h3>
                    <p>
                        Pas de beveiliging van je account aan.<br />
                        Heb je al je 2FA ingesteld?
                    </p>
                </div>
                <div class="margin-top">
                    <q-section hPadding="40" vPadding="13" class="row">
                        <div class="col-xs-4 label">
                            <p>Wachtwoord</p>
                        </div>
                        <div
                            :class="[
                                editing.includes('password') ? 'fixed-width' : 'fixed-width',
                                editing.includes('password') ? 'col-xs-5' : 'col-xs-7',
                            ]"
                        >
                            <div v-if="editing.includes('password')" class="name-input">
                                <q-input labelPosition="inside"
                                    size="small"
                                    inputType="password"
                                    placeholder="Wachtwoord"
                                    variation="blank"
                                    class="no-padding input-margin"
                                    v-model="password"
                                    @input="checkPasswordPartials"
                                    :state="inputValidation.passwordOneState"
                                    :errorMessage="inputValidation.passwordOneErrorMessage"
                                ></q-input>
                                <q-input labelPosition="inside"
                                    size="small"
                                    inputType="password"
                                    placeholder="Herhaal"
                                    variation="blank"
                                    class="no-padding input-margin"
                                    v-model="confirmPassword"
                                    @input="doublecheckPassword"
                                    :state="inputValidation.passwordTwoState"
                                    :errorMessage="inputValidation.passwordTwoErrorMessage"
                                ></q-input>
                            </div>
                            <p v-else>•••••••••••</p>
                        </div>
                        <div :class="['end', editing.includes('password') ? 'col-xs-3' : 'col-xs-1']">
                            <div>
                                <div v-if="editing.includes('password')">
                                    <q-button variation="blank" size="xsmall" @click="toggle('password')"
                                        >Annuleren</q-button
                                    >
                                    <q-button variation="ghost" size="xsmall" @click="savePassword()">Opslaan</q-button>
                                </div>
                                <q-button v-else variation="light" size="xsmall" @click="toggle('password')"
                                    >Wijzigen</q-button
                                >
                            </div>
                        </div>
                    </q-section>
                </div>

                <q-section hPadding="40" vPadding="13" borderTop class="row">
                    <div class="col-xs-4 label">
                        <p>Two Factor Authentication</p>
                    </div>
                    <div :class="['col-xs-7', 'fixed-width']">
                        <div class="row">
                            {{ loginSettings.twofactorEnabled ? '2FA ingesteld' : '2FA niet ingesteld' }}
                            <q-icon
                                v-if="loginSettings.twofactorSecretSet && loginSettings.twofactorEnabled"
                                type="check-circle"
                                class="icon-padding"
                                color="#37B24D"
                            />
                            <div v-else-if="loginSettings.twofactorEnabled" @click="logout" class="ml-s">
                                <q-tag class="fa logout-tag" variation="warning" size="small"
                                    >Log opnieuw in om 2FA in te stellen</q-tag
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-1 end">
                        <q-switch v-model="loginSettings.twofactorEnabled" @input="change2FA($event)"></q-switch>
                    </div>
                </q-section>
            </div>
        </div>

        <div class="justify-center twofactor-margin mb-xl">
            <div class="q-card-small">
                <div class="account-intro">
                    <h3>Inloggen</h3>
                    <p>
                        Wil je automatisch inloggen bij je laatst actieve organisatie? <br>
                        Je kan altijd wisselen van organisatie door rechtsbovenen op je profielfoto te klikken.
                    </p>
                </div>
                <div class="margin-top">
                    <q-section hPadding="40" vPadding="13" class="row">
                        <div class="col-xs-4 label">
                        <p>Automatisch inloggen</p>
                        </div>
                        <div :class="['col-xs-7', 'fixed-width']">
                            <div class="row">
                                {{ loginSettings.autoLogin ? `Ingesteld naar ${loginSettings.lastLoginOrganisationName}` : 'Niet ingesteld' }}
                            </div>
                        </div>
                        <div class="col-xs-1 end">
                            <q-switch v-model="loginSettings.autoLogin" @input="toggleAutoLogin($event)"></q-switch>
                        </div>
                    </q-section>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    UPDATE_USER_NAMES,
    UPDATE_USER_EMAIL,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PHONE,
    UPDATE_USER_AVATAR,
    UPDATE_USER_2FA,
    UPDATE_AUTO_LOGIN,
    RESET_2FA,
    CHECK_EMAIL_AVAILABLE,
} from '@/graphql/mutations';

import { USER, GET_USER_LOGIN_SETTINGS, ORGANISATION } from '@/graphql/queries';
import { extractError, validateEmail, validatePhoneNumber } from '@/assets/js/utils';
import ArrowButton from '@/components/ArrowButton';

export default {
    name: 'ProfileSettings',
    components: {
        ArrowButton,
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            twoFactor: true,
            avatar: null,
            editing: [],
            user: this.$store.getters.getUser,
            loginSettings: {
                twofactorEnabled: false,
                twofactorSecretSet: false,
                autoLogin: false,
                lastLoginOrganisation: '',
                lastLoginOrganisationName: ''
            },
            uploadEndpoint: `${process.env.UPLOAD_URL}/avatars`,
            loadingAvatar: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            inputValidation: {
                emailState: '',
                emailErrorMessage: '',
                phoneState: '',
                phoneErrorMessage: 'Ongeldig telefoonnummer',
                passwordOneState: '',
                passwordTwoState: '',
                passwordOneErrorMessage: 'Wachtwoord voldoet niet',
                passwordTwoErrorMessage: '',
            }
        };
    },
    methods: {
        toggle(type) {
            let v = this;
            if (this.editing.includes(type))
                this.editing.forEach((item, i) => {
                    if (item == type) v.editing.splice(i, 1);
                });
            else this.editing.push(type);
        },
        saveName() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_USER_NAMES,
                    variables: {
                        id: this.user.id,
                        firstName: this.firstName,
                        lastName: this.lastName,
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.user_updateProfile) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Naam is opgeslagen',
                        });

                        this.user.firstName = this.firstName;
                        this.user.lastName = this.lastName;

                        this.toggle('name');
                        this.updateLocalstorage();
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        checkEmail: _.debounce(function () {
            if (this.email === '') {
                this.emailState = null;
                return;
            }

            if (validateEmail(this.email)) {
                this.$apollo
                    .mutate({
                        mutation: CHECK_EMAIL_AVAILABLE,
                        variables: { email: this.email },
                    })
                    .then((response) => {
                        this.inputValidation.emailState = response.data.user_checkEmailAvailable.value
                            ? 'success'
                            : 'error';
                        if (response.data.user_checkEmailAvailable.value === false) {
                            this.inputValidation.emailErrorMessage = 'Dit e-mailadres is al geregistreerd';
                            return false;
                        }
                        return true;
                    })
                    .catch((err) => {
                        this.$store.commit('notify', extractError(err));
                    });
            } else {
                this.inputValidation.emailState = 'error';
                this.inputValidation.emailErrorMessage = 'Opgegeven e-mailadres is ongeldig';
                return false;
            }
        }, 1000),
        saveEmail() {
            if (this.email === this.user.email) {
                this.$store.commit('notify', {
                    type: 'warning',
                    message: 'Opgegeven e-mail is hetzelfde als huidige e-mail',
                });
                return;
            }

            let v = this;

            this.$apollo
                .mutate({
                    mutation: UPDATE_USER_EMAIL,
                    variables: {
                        id: this.user.id,
                        email: this.email,
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    this.$store.commit('notify', {
                        type: 'success',
                        message: 'E-mail is opgeslagen',
                    });

                    if (response.data.user_updateEmail) {
                        this.user.changeEmail = response.data.user_updateEmail.changeEmail;
                        this.toggle('email');
                        this.updateLocalstorage();
                    } else {
                        response.errors.forEach((error) => {
                            console.log('error notification', error.message);
                        });
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        savePassword() {
            if (this.password === '') {
                this.$store.commit('notify', { type: 'warning', message: 'Vul een wachtwoord in' });
                return false;
            } else if (!this.validatePassword()) {
                this.$store.commit('notify', { type: 'danger', message: 'Wachtwoord voldoet niet' });
                return false;
            } else if (!this.validateConfirmPassowrd()) {
                this.$store.commit('notify', { type: 'danger', message: 'Wachtwoorden zijn niet hetzelfde' });
                return false;
            }

            let v = this;

            this.$apollo
                .mutate({
                    mutation: UPDATE_USER_PASSWORD,
                    variables: {
                        id: this.user.id,
                        password: this.password,
                        confirmPassword: this.confirmPassword,
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.user_updatePassword) {
                        this.toggle('password');
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Wachtwoord is opgeslagen',
                        });
                    } else {
                        response.errors.forEach((error) => {
                            console.log('error notification', error.message);
                        });
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        checkPhone: _.debounce(function () {
            return this.validatePhone();
        }, 1000),
        validatePhone() {
            const phoneValid = this.user.phone === '' || (this.phone !== '' && validatePhoneNumber(this.phone));
            this.inputValidation.phoneState = !phoneValid && this.phone !== '' ? 'error' : '';
            return phoneValid;
        },
        savePhone() {
            let v = this;

            this.$apollo
                .mutate({
                    mutation: UPDATE_USER_PHONE,
                    variables: {
                        id: this.user.id,
                        phone: validatePhoneNumber(this.phone),
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.user_updateProfile) {
                        this.toggle('phone');
                        this.user.phone = this.phone;
                        this.updateLocalstorage();
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Telefoon is opgeslagen',
                        });
                    } else {
                        response.errors.forEach((error) => {
                            console.log('error notification', error.message);
                        });
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        checkPasswordPartials() {
            if (this.password === '') {
                this.inputValidation.passwordOneState = '';
                this.has_number = false;
                this.has_lowercase = false;
                this.has_uppercase = false;
                this.has_special = false;
                return false;
            }
            this.has_number = /\d/.test(this.password);
            this.has_lowercase = /[a-z]/.test(this.password);
            this.has_uppercase = /[A-Z]/.test(this.password);
            this.has_special = /[\W_]/.test(this.password);

            this.checkPassword();
        },
        checkPassword: _.debounce(function () {
            this.validatePassword();
        }, 1000),
        validatePassword() {
            const valid = this.has_number && this.has_lowercase && this.has_uppercase && this.has_special;
            this.inputValidation.passwordOneState = !valid && this.password !== '' ? 'error' : '';
            if (valid) this.validateConfirmPassowrd();

            return valid;
        },
        doublecheckPassword: _.debounce(function () {
            this.validateConfirmPassowrd();
        }, 1000),

        validateConfirmPassowrd() {
            if (this.password === '' || this.confirmPassword === '') {
                this.inputValidation.passwordTwoState = '';

                return false;
            }
            if (this.password === this.confirmPassword) {
                this.inputValidation.passwordTwoState = 'success';

                if (this.inputValidation.passwordOneState === 'error') {
                    this.inputValidation.passwordTwoState = 'error';
                    this.inputValidation.passwordTwoErrorMessage = 'Wachtwoord voldoet niet';
                }
            } else {
                this.inputValidation.passwordTwoState = 'error';
                this.inputValidation.passwordTwoErrorMessage = 'De wachtwoorden zijn niet hetzelfde';
                return false;
            }
            return true;
        },
        saveFile(f) {
            let v = this;

            const whitelistedExtensions = ['image/png', 'image/jpeg'];

            const file = f.target.files[0];
            if (!whitelistedExtensions.includes(file.type))
                return this.$store.commit('notify', {
                    message: 'Dit bestandstype is niet valiede: ' + file.type,
                    type: 'warning',
                });

            this.avatar = new FormData();
            this.avatar.append('file', file);

            this.loadingAvatar = true;

            this.$http
                .post(this.uploadEndpoint, v.avatar, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${this.$store.getters.getJwt}`,
                    },
                })
                .then((response) => {
                    v.user.avatar = response.data.filePath;
                    // v.$emit('updateNavbar', v.user.avatar);

                    this.$apollo
                        .query({
                            query: UPDATE_USER_AVATAR,
                            variables: {
                                id: this.user.id,
                                avatar: v.user.avatar,
                            },
                            context: {
                                headers: {
                                    Authorization: `Bearer ${this.$store.getters.getJwt}`,
                                },
                            },
                            fetchPolicy: 'no-cache',
                        })
                        .then((response) => {
                            if (response.data.user_updateAvatar) {
                                v.loadingAvatar = false;
                                v.avatar = null;
                                v.updateLocalstorage();
                                v.toggle('avatar');
                            }
                        })
                        .catch((err) => {
                            this.$store.commit('notify', extractError(err));
                        });
                })
                .catch((err) => {
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets verkeerd gegaan bij het uploaden van uw foto',
                    });
                    v.loadingAvatar = false;
                    v.avatar = null;
                });
        },
        updateLocalstorage() {
            //Localstorage still holds an old version of the user (set before mutations)
            //This object needs to be updated

            this.$store.commit('setUser', this.user);
        },
        getUserSettings() {
            let v = this;

            this.$apollo
                .query({
                    query: GET_USER_LOGIN_SETTINGS,
                    variables: {
                        id: this.user.id,
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    const settings = response.data.user.settings.login;

                    this.firstName = response.data.user.firstName;
                    this.lastName = response.data.user.lastName;
                    this.email = response.data.user.email;
                    this.phone = response.data.user.phone;

                    this.user = response.data.user;

                    v.loginSettings = { 
                        ...v.loginSettings,
                        ...settings,
                        twofactorSecretSet: typeof settings.twofactorSecret === 'string' && settings.twofactorSecret.length > 0
                    }

                    if (settings.lastLoginOrganisation) this.lastLoginOrganisation(settings.lastLoginOrganisation);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });
        },
        change2FA(e) {
            let v = this;

            this.$apollo
                .mutate({
                    mutation: UPDATE_USER_2FA,
                    variables: {
                        id: this.user.id,
                        enabled: e,
                    }
                })
                .then((response) => {
                    if (response.data.user_updateSettings) {
                        if (e) {
                            this.$store.commit('notify', {
                                type: 'success',
                                message: 'Two Factor Authentication is aangezet',
                            });
                        } else {
                            v.reset2FA(v.user.id);
                        }
                    } else {
                        response.errors.forEach((error) => {
                            console.log('error notification', error.message);
                        });
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        reset2FA(userId) {
            this.$apollo
                .mutate({
                    mutation: RESET_2FA,
                    variables: {
                        id: userId,
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    this.$store.commit('notify', {
                        type: 'success',
                        message: 'Two Factor Authentication is uitgezet',
                    });
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });

        },
        toggleAutoLogin() {
            const vm = this;

            this.$apollo
                .mutate({
                    mutation: UPDATE_AUTO_LOGIN,
                    variables: {
                        id: this.user.id,
                        enabled: this.loginSettings.autoLogin,
                    }
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.user_updateSettings) {
                        if (response.data.user_updateSettings.settings.login.autoLogin) {
                            this.$store.commit('notify', {
                                type: 'success',
                                message: 'Automatisch inloggen ingesteld.',
                            });
                        } else {
                            this.$store.commit('notify', {
                                type: 'success',
                                message: 'Automatisch inloggen gedeactiveerd.',
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err))
                });
        },
        lastLoginOrganisation(organisationId) {
            const vm = this;
            this.$apollo
                .query({
                    query: ORGANISATION,
                    variables: { organisationId },
                    fetchPolicy: 'no-cache'
                })
                .then(res => {
                    vm.loginSettings.lastLoginOrganisationName = res.data.organisation.name
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err))
                })
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        },
        scrollIntoView(documentId) {
            const element = document.getElementById(documentId)
            element.scrollIntoView({ behavior: 'smooth' })
        }
    },
    created() {
        this.getUserSettings();
    }
};
</script>

<style lang="scss" scoped>
label[for='input'] {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    will-change: transform;
    transition: all 0.2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    font-family: 'Gotham', sans-serif;
    line-height: 1.6;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    background: transparent;
    // color: #484f56;
    // border: 1px solid #484f56;
    color: #aeb5bc;
    border: 1px solid #aeb5bc;
    box-shadow: none;
    cursor: pointer;
    padding: 1px 8px;
    font-size: 10px;
}

label[for='input']:hover {
    // color: #22b7ce;
    // border-color: #22b7ce;
    color: #484f56;
    border-color: #484f56;
}

.label-top {
    margin-top: 5px;
    margin-bottom: 9px;
}

.hidden {
    display: none;
}

.label {
    padding-left: 0;
}

.email {
    padding: 2px 5px;
}

.emailText {
    position: relative;
    margin-top: -10px;
}

.email-infotext {
    top: 20px;
    position: absolute;
    font-size: 0.6rem;
    color: #6a737c;
    width: 370px;
}

button[class='button ripple xsmall blank'] {
    margin-right: 5px;
}

.col-xs,
.col-xs-5,
.col-xs-7 {
    display: flex;
    align-items: center;
}

.col-xs-7 > div {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.name-input {
    display: flex;
}

.name-input > div {
    margin-right: 10px;
}

.icon-padding {
    padding: 3px 10px;
}

.no-padding {
    padding: 0 !important;
}

.row > .label > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.fixed-width {
    padding-left: 40px;
}

.fixed-width > div {
    display: flex;
    align-items: center;
}

.end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.twofactor-margin {
    margin-top: 16px;
}

.account-intro {
    padding: 40px 40px 0px 40px;
}

.account-intro > p {
    margin-top: 15px;
}

.q-card-small {
    width: 800px;
    margin-top: 36px;
    padding-bottom: 10px;
}

.q-card-small > h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
}

.q-card-small > .margin-top {
    margin-top: 12px;
}

.full-width {
    width: 100%;
}

.min-height {
    min-height: 100vh;
    padding-bottom: 40px;
}

.logout-tag {
    cursor: pointer;
}

.settings-intro {
    width: 800px;
    text-align: left;

    h1 {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 31px;
        line-height: 33px;
    }

    p {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-top: 11px;
        width: 50%;
    }
}

.settings-back {
    width: 800px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 5px;
}

.input-margin {
    margin: -1px 0px 0px -7px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 150ms;
}

// .fade {
// }

@keyframes bounce {
    0% {
        transform: translateY(3px);
    } 50% {
        transform: translateY(-3px);
    } 100% {
        transform: translateY(3px);
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>

<template>
    <q-popup>
        <div class="modal">
            <div class="header">
                <h2>Prestatie toevoegen</h2>
                <q-label-switch v-if="!onlyNewLabel" v-model="selectedLabelOption" @input="handleLabelOptionUpdated" :options="labelTypeOptions" optionWidth="180px"></q-label-switch>
            </div>
            <div v-if="selectedLabelOption !== 'new'" class="header-info">
                <p>Selecteer hier welke bestaande kenmerken en vragen je uit deze prestatie wilt toevoegen aan dit {{ templateName }}. <br>
                    Een nieuw kenmerk toevoegen kan door de prestatie te openen en op ‘kenmerk/vraag toevoegen’ te klikken.
                </p>
            </div>
            <div v-if="selectedLabelOption === 'new'" class="content padding-bottom">
                <div class="line">
                    <div class="inline">
                        <p class="name">Naam prestatie</p>
                    </div>
                    <q-input labelPosition="inside" v-model="label.name" class="user-input" size="small" placeholder="Typ hier een naam" autofocus>Naam</q-input>
                </div>
                <div class="line">
                    <div class="inline">
                        <p class="name">Omschrijving</p>
                    </div>
                    <q-input labelPosition="inside" v-model="label.description" class="user-input" size="small" placeholder="Typ hier een omschrijving">Omschrijving</q-input>
                </div>
            </div>
            <div v-else class="content">
                <div class="line">
                    <div class="inline">
                        <p class="name">Naam prestatie</p>
                    </div>
                    <q-dropdown 
                        v-model="selectedLabelIds" 
                        :options="labelOptions" 
                        @input="handleOptionSelected"
                        size="small" 
                        placeholder="Selecteer een of meer prestaties" 
                        autocomplete
                        multiple
                        :loading="loadingLabelOptions">
                    </q-dropdown>
                </div>
                <div class="indicator-suggestions-wrapper" :class="{ 'has-selected-labels': selectedLabelIds.length > 0 }">
                    <div v-if="contextName === 'projecttemplate' && selectedLabelIds.length > 0 && referenceIndicators.length" class="indicators-suggestion">
                        <p class="text">Selecteer hier de <span class="marked">kenmerken</span> die je wilt toevoegen</p>
                        <div v-for="label in labelsWithReferences" class="context-wrapper">
                            <p>{{ label.name }}</p>
                            <div class="indicators-wrapper">
                                <div v-for="reference in label.references" :key="reference.id" class="line">
                                    <q-checkbox v-model="reference.checked">{{ reference.name }}</q-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedLabelIds.length > 0 && questionIndicators.length" class="indicators-suggestion">
                        <p class="text">Selecteer hier de <span class="marked">vragen</span> die je wilt toevoegen</p>
                        <div v-for="label in labelsWithQuestions" class="context-wrapper">
                            <p>{{ label.name }}</p>
                            <div class="indicators-wrapper">
                                <div v-for="question in label.questions" :key="question.id" class="line">
                                    <q-checkbox v-model="question.checked">{{ question.name }}</q-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="selectedLabelIds.length === 0" class="message">Hier komen kenmerken en vragen te staan die onder de geselecteerde prestaties vallen</p> 
                    <div v-else-if="loadingIndicators && selectedLabelIds.length > 0" class="center">
                        <div class="loader"></div>
                    </div>
                    <div v-else-if="selectedLabelIds.length > 0 && (referenceIndicators.length === 0 && questionIndicators.length === 0)" class="center">
                        <q-zerostate 
                            class="zerostate"
                            img="/statics/img/survey_gradient.svg"
                            title="Niks om toe te voegen"
                            description="Deze prestatie bevat geen kenmerken of vragen om toe te voegen"
                        ></q-zerostate>
                    </div>
                </div>
            </div>
            <div class="footer">
                <q-button variation="blank" @click="handleCancel">Annuleren</q-button>
                <q-tooltip position="top">
                    <template v-if="labelNameExists" #tooltip>
                        <p>Er bestaat al een prestatie met deze naam, kies een andere naam om door te gaan</p>
                    </template>
                    <q-button variation="primary" :disabled="disabledButton || loadingIndicators" @click="handleCreateLabel" :loading="loadingButton">{{ buttonLabel }}</q-button>
                </q-tooltip>
            </div>
        </div>
    </q-popup>
</template>

<script>
import { GET_LABELS, GET_INDICATORS_WITH_TEMPLATES } from '@/graphql/queries';
import { CREATE_LABEL, ADD_LABEL_TO_FORMTEMPLATE, ADD_LABEL_TO_PROJECTTEMPLATE } from '@/graphql/mutations';
import { sortIndicators } from '@/assets/js/utils.js';

export default {
    name: 'create-label-modal',
    props: {
        onlyNewLabel: {
            type: Boolean,
            default: false
        },
        contextName: {
            type: String,
            default: ''
        },
        // these are the labels that are already in a template, so these will be filtered out
        disabledLabels: {
            type: Array,
            default() { return [] }
        }
    },
    data() {
        return {
            label: {
                name: '',
                description: ''
            },
            indicators: [],
            labelTypeOptions: [
                {
                    label: 'Nieuwe prestatie',
                    value: 'new'
                },
                {
                    label: 'Bestaande prestatie',
                    value: 'existing'
                }
            ],
            labelOptions: [],
            allLabels: [],
            selectedLabelOption: 'existing',
            selectedLabelId: '', 
            referenceIndicators: [], 
            questionIndicators: [], 
            selectedLabelIds: [],
            labelsWithReferences: [],
            labelsWithQuestions: [],
            loadingButton: false,
            loadingIndicators: false,
            loadingLabelOptions: false
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel');
        },
        async handleCreateLabel() {
            this.loadingButton = true;
            const { id } = this.$route.params;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
            const variables = {
                organisationId,
                name: this.label.name,
                description: this.label.description,
                status: 'active',
                type: 'context'
            }
            if(this.contextName) variables[`${this.contextName}Id`] = id;
            
            if(this.selectedLabelOption === 'new') {
                this.$apollo.mutate({
                    mutation: CREATE_LABEL,
                    variables
                })
                .then(result => {
                    this.loadingButton = false;
                    this.$store.commit('notify', { type: 'success', message: 'Prestatie is aangemaakt' });
                    this.$emit('created', result.data.label_create);
                })
                .catch(error => {
                    this.loadingButton = false;
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanmaken van de prestatie' });
                    this.$emit('cancel');
                })
            } else if(this.contextName === 'formtemplate') {
                for(let i = 0; i < this.selectedLabelIds.length; i++) {
                    let indicatorIds = []
                    const filteredReferenceIndicators = this.referenceIndicators
                        .filter(indicator => indicator.checked && indicator.labelIds[0] === this.selectedLabelIds[i])
                        .map(indicator => indicator.id)
                    const filteredQuestionIndicators = this.questionIndicators
                        .filter(indicator => indicator.checked && indicator.labelIds[0] === this.selectedLabelIds[i])
                        .map(indicator => indicator.id)
                    indicatorIds = [...filteredReferenceIndicators, ...filteredQuestionIndicators];

                    try {
                        const result = await this.$apollo.mutate({
                            mutation: ADD_LABEL_TO_FORMTEMPLATE,
                            variables: {
                                id: this.$route.params.id,
                                labelId: this.selectedLabelIds[i],
                                indicatorIds
                            }
                        })
                        let indicators = [];
                        const filteredReferenceIndicators = this.referenceIndicators.filter(indicator => indicatorIds.includes(indicator.id));
                        const filteredQuestionIndicators = this.questionIndicators.filter(indicator => indicatorIds.includes(indicator.id));
                        indicators = [...filteredReferenceIndicators, ...filteredQuestionIndicators];
                        
                        const newLabel = result.data.formtemplate_addLabel.labels.find(label => label.id === this.selectedLabelIds[i])
                        newLabel.questions = indicators;
                        this.$emit('added', newLabel, indicators);
                        this.$store.commit('notify', { type: 'success', message: `Prestatie ${newLabel.name} is toegevoegd` });
                    } catch(error) {
                        this.loadingButton = false;
                        this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout' });
                        this.$emit('cancel');
                        break
                    }
                }
                this.loadingButton = false;
                this.$emit('cancel');
            } else if(this.contextName === 'projecttemplate') {
                for(let i = 0; i < this.selectedLabelIds.length; i++) {
                    let indicatorIds = []
                    const filteredReferenceIndicatorIds = this.referenceIndicators
                        .filter(indicator => indicator.checked && indicator.labelIds[0] === this.selectedLabelIds[i])
                        .map(indicator => indicator.id)
                    const filteredQuestionIndicatorIds = this.questionIndicators
                        .filter(indicator => indicator.checked && indicator.labelIds[0] === this.selectedLabelIds[i])
                        .map(indicator => indicator.id)
    
                    indicatorIds = [...filteredReferenceIndicatorIds, ...filteredQuestionIndicatorIds];

                    try {
                        const result = await this.$apollo.mutate({
                            mutation: ADD_LABEL_TO_PROJECTTEMPLATE,
                            variables: {
                                id: this.$route.params.id,
                                labelId: this.selectedLabelIds[i],
                                indicatorIds
                            }
                        })
                        
                        let indicators = [];
                        const filteredReferenceIndicators = this.referenceIndicators.filter(indicator => indicatorIds.includes(indicator.id));
                        const filteredQuestionIndicators = this.questionIndicators.filter(indicator => indicatorIds.includes(indicator.id));
                        indicators = [...filteredReferenceIndicators, ...filteredQuestionIndicators];
                        const selectedLabel = this.allLabels.find(label => label.id === this.selectedLabelIds[i]);
                        this.$emit('added', selectedLabel, indicators);
                        this.$store.commit('notify', { type: 'success', message: `Prestatie ${selectedLabel.name} is toegevoegd` });
                    } catch(error) {
                        this.loadingButton = false;
                        this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het toevoegen van de prestatie' });
                        this.$emit('cancel');
                    }
                }
                this.loadingButton = false;
                this.$emit('cancel');
            }
        },
        getLabelOptions() {
            this.loadingLabelOptions = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
            const disabledLabelIds = this.disabledLabels.map(label => label.id);

            this.$apollo.query({
                query: GET_LABELS,
                variables: {
                    where: {
                        AND: [
                            { organisationId },
                            { id__nin: disabledLabelIds },
                            { status__in: ["active", "disabled"] },
                            { name__nin: ["Project_details","Crow_project_details"] },
                            { masterId__not_contains: "crow" }
                        ]
                    },
                    sort: ["order__ASC"]
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.allLabels = result.data.labels;
                this.labelOptions = this.allLabels
                    .map(label => {
                        return {
                            label: label.name,
                            value: label.id,
                            ...label
                        }
                    });
                this.loadingLabelOptions = false;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de prestaties' });
                this.loadingLabelOptions = false;
            })
        },
        handleOptionSelected() {
            if(this.selectedLabelIds.length === 0) return
            this.loadingIndicators = true;

            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.referenceIndicators = [];
            this.questionIndicators = [];
            this.labelsWithIndicators = [];

            this.$apollo.query({
                query: GET_INDICATORS_WITH_TEMPLATES,
                variables: {
                    where: {
                        AND: [
                            {
                                labelIds__in: this.selectedLabelIds
                            },
                            {
                                status: 'active'
                            }
                        ]
                    },
                    organisationId
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const mappedReferenceIndicators = result.data.indicators_with_templates 
                    .filter(indicator => indicator.type === 'reference')
                    .map(indicator => {
                        return {
                            ...indicator, 
                            checked: this.contextName === 'projecttemplate' ? true : false
                        }
                    });
                this.referenceIndicators = sortIndicators(mappedReferenceIndicators)

                const mappedQuestionIndicators = result.data.indicators_with_templates
                    .filter(indicator => indicator.type === 'question')
                    .map(indicator => {
                        return {
                            ...indicator, 
                            checked: true
                        }
                    })
                this.questionIndicators = sortIndicators(mappedQuestionIndicators)
                
                const indicatorLabelIds = result.data.indicators_with_templates.map(indicator => indicator.labelIds[0]);
                const labelIds = [ ... new Set(indicatorLabelIds) ];
                console.log(labelIds)

                let labelsWithReferences = [];
                labelIds.forEach(labelId => {
                    const labelIndicators = this.referenceIndicators.filter(indicator => indicator.labelIds[0] === labelId);
                    if(labelIndicators.length === 0) return
                    const label = labelIndicators[0].context;

                    labelsWithReferences.push({
                        ...label,
                        references: labelIndicators
                    })
                });
                console.log(labelsWithReferences)
                this.labelsWithReferences = labelsWithReferences;

                let labelsWithQuestions = [];
                labelIds.forEach(labelId => {
                    const labelIndicators = this.questionIndicators.filter(indicator => indicator.labelIds[0] === labelId);
                    if(labelIndicators.length === 0) return
                    const label = labelIndicators[0].context;

                    labelsWithQuestions.push({
                        ...label,
                        questions: labelIndicators
                    })
                });
                this.labelsWithQuestions = labelsWithQuestions;
                
                this.loadingIndicators = false;
            })
            .catch(error => {
                console.log(error)
                this.loadingIndicators = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de prestatie' })
            })
        },
        handleLabelOptionUpdated() {
            if(this.selectedLabelOption === 'new') {
                this.selectedLabelIds = [];
                this.referenceIndicators = [];
                this.questionIndicators = [];
            } else {
                this.label.name = '';
                this.label.description = '';
            }
        }
    },
    created() {
        this.getLabelOptions();
        if(this.contextName === 'prestatie') this.selectedLabelOption = 'new';
    },
    computed: {
        buttonLabel: function() {
            const name = this.selectedLabelOption === 'existing' && this.selectedLabelIds.length !== 1 ? 'Prestaties' : 'Prestatie';
            if(this.selectedLabelIds.length <= 1) return `${name} toevoegen`
            return `${this.selectedLabelIds.length} ${name} toevoegen`
        },
        labelNameExists: function() {
            return this.allLabels.filter(label => this.label.name === label.name).length > 0;
        },
        disabledButton: function() {
            if((this.label.name.length === 0 || this.labelNameExists) && this.selectedLabelIds.length === 0) return true
        },
        templateName: function() {
            let name = ''
            if (this.contextName === 'formtemplate') name = 'formuliertemplate'
            if (this.contextName === 'projecttemplate') name = 'projecttemplate';
            return name;
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.modal {
    display: flex;
    flex-direction: column;
    min-width: 800px;
    max-width: 900px;

    .header,
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-info {
        padding-top: 25px;
        margin-bottom: -15px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 48px;
        padding-bottom: 24px;
        flex-grow: 1;

        &.padding-bottom {
            padding-bottom: 48px;
        }

        .name {
            font-weight: 500;
        }

        .line {
            display: flex;
            align-items: center;

            .checkbox {
                font-weight: 400;
            }

            .inline {
                display: flex;
                align-items: center;
                gap: 8px;
                min-width: 200px;
                width: 200px;
            }

            .user-input {
                padding: 0;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .indicator-suggestions-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
            min-height: 60px;
            max-height: 60px;
            overflow-y: auto;
            transition: .2s ease;

            &.has-selected-labels {
                min-height: 40vh;
                max-height: 40vh;
            }

            .indicators-suggestion {
                .text {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 12px;

                    .marked {
                        color: $color-primary;
                    }
                }
                .context-wrapper {
                    
                    p {
                        margin: 12px 0;
                        font-size: 15px;
                        font-weight: 500;
                    }

                    .indicators-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 14px;
                    }
                }
            }

            .message {
                color: $color-grey-7;
                text-align: center;
            }
        }
    }

    .footer {
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;

    .loader {
        $loader-color: #8a8c8f;
        $loader-size: 80px;
        $loader-height: 14px;
        $loader-border-size: 4px;
        $loader-gap: 20px;
        $loader-animation-duration: 700ms;
        @import '../../../components/qds/assets/loaders/loaders.scss';
        @include loader02;
    }
}
    

</style>
<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section vPadding="40" hPadding="40">
                <h2>2 Factor Authenticatie</h2>
                <p class="py-m">Scan de QR-code om je 2 factor authentication in te stellen.</p>
                <div class="text-align-center">
                    <q-2fa
                        :amount="amount"
                        v-model="twofactorCode"
                        @keyup.enter.native="handleVerification"
                        @onLast="handleVerification"
                    ></q-2fa>
                </div>
            </q-section>
            <q-section vPadding="24" hPadding="40" borderTop class="space-between">
                <q-button go="-1" variation="blank" size="medium">Terug</q-button>
                <q-button @click="handleVerification" variation="primary" size="medium" :loading="awaitLogin"
                    >Verifieer</q-button
                >
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { TWOFACTOR } from '@/graphql/mutations';
import { handleLoginSuccess, extractError } from '@/assets/js/utils';

export default {
    name: 'TwoFactor',
    components: {
        AuthBase,
    },
    data() {
        return {
            twofactorCode: '',
            awaitLogin: false,
            amount: '6',
        };
    },
    methods: {
        handleVerification() {
            const vm = this;
            this.awaitLogin = true;
            this.$apollo
                .mutate({
                    mutation: TWOFACTOR,
                    variables: { token: this.twofactorCode },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getTwofactorJwt}`,
                        },
                    },
                })
                .then((response) => {
                    vm.awaitLogin = false;

                    if (response.errors) {
                        const logicError = response.errors[0].extensions.response;

                        const dangerNotif = {
                            type: 'danger',
                            message: logicError.body,
                        };

                        vm.$store.commit('notify', dangerNotif);
                    }

                    return handleLoginSuccess(vm, response.data.user_twofactorLogin);
                })
                .catch((err) => {
                    this.awaitLogin = false;

                    if (err.graphQLErrors.length > 0)
                        this.$store.commit('notify', {
                            type: 'warning',
                            message: 'Token is incorrect',
                        });
                    else {
                        this.$store.commit('resetState');
                        this.$router.push('/login');
                    }
                });
        },
    },
};
</script>

<style></style>

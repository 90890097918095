<template>
    <div class="page-padding">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.push('/settings')">Instellingen</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Standaard formulierteksten</h1>
                </div>
                <p>Bekijk en beheer hier de standaard teksten die je kunt gebruiken in je formulieren. Wil je een nieuwe tekst aanmaken? Open dan het soort tekst dat je wilt toevoegen en voeg een nieuwe variant toe. </p>
            </div>
        </div>
        <q-table-with-pagination
            :data="templates"
            :columns="columns"
            :maxRows="tableMaxRows"
            :dataLength="templates.length"
            clickable
            @click="handleRowClick"
        >
            <template v-slot:row="{ column, row }">
                <span v-if="column === 'templates'">
                    {{ row[column].length }}
                </span>
            </template>
        </q-table-with-pagination>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';

import { CONFIGURATIONS } from '@/graphql/queries.js';

export default {
    name: 'template-types',
    components: {
        ArrowButton
    },
    data() {
        return {
            templates: [],
            columns: [
                {
                    label: 'Soort tekst',
                    field: 'name'
                },
                {
                    label: 'Formulier',
                    field: 'form'
                },
                {
                    label: 'Aantal varianten',
                    field: 'templates'
                }
            ],
            tableMaxRows: 10
        }
    },
    methods: {
        handleRowClick(template) {
            this.$router.push(`/settings/templates/${template.type}`)
        },
        setTemplates() {
            this.$apollo.query({
                query: CONFIGURATIONS,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const configurations = result.data.org_configurations;

                this.templates = [
                    {
                        name: 'Tekst brief',
                        type: 'introtext',
                        form: 'Referentiebrief',
                        templates: configurations.filter(configuration => configuration.type === 'introtext')
                    },
                    {
                        name: 'Afsluiting',
                        type: 'closingtext',
                        form: 'Referentiebrief',
                        templates: configurations.filter(configuration => configuration.type === 'closingtext')
                    },
                    {
                        name: 'Intro of instructie',
                        type: 'surveyintrotext',
                        form: 'Enquete',
                        templates: configurations.filter(configuration => configuration.type === 'surveyintrotext')
                    },
                    {
                        name: 'Afsluiting',
                        type: 'surveyclosingtext',
                        form: 'Enquete',
                        templates: configurations.filter(configuration => configuration.type === 'surveyclosingtext')
                    }
                ]
            })
        }
    },
    created() {
        this.setTemplates()
    }
}
</script>

<style>

</style>
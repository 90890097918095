<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:1.41421;"
  >
    <circle
      cx="16"
      cy="16"
      r="15"
      style="fill:none;stroke-width:2px;"
      :stroke="color"
    />
    <path
      d="M21.804,14.657c0,-0.552 -0.448,-1 -1,-1l-9.786,0c-0.552,0 -1,0.448 -1,1l0,7.286c0,0.552 0.448,1 1,1l9.786,0c0.552,0 1,-0.448 1,-1l0,-7.286Z"
      style="fill:none;stroke-width:2px;"
      :stroke="color"
    />
    <path
      d="M11.859,13.3l0,-1.306c0,-2.238 1.814,-4.051 4.052,-4.051c2.237,0 4.051,1.813 4.051,4.051l0,1.306"
      style="fill:none;stroke-width:2px;"
      :stroke="color"
    />
    <path
      d="M10.375,16.822l11.607,0M10.375,19.907l11.607,0"
      style="fill:none;stroke-width:2px;"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  name: "LockCircle",
  props: {
    color: {
      type: String,
      default: "#495057",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>

<style></style>

<template>
  <div :class="['avatar', size, color, { hasImage: img }]">
    <slot v-if="!img" />
    <img v-else :src="img" :style="imageStyle" :class="['avatar', size, color]" />
  </div>
</template>

<script>
export default {
  name: "q-avatar",
  status: "prototype",
  release: "0.0.1",

  props: {
    /**
     * The size of the avatar. Defaults to medium.
     * `small, medium, large, xlarge`
     */
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return value.match(/(small|medium|large|xlarge)/);
      },
    },
    /**
     * The color of the avatar, default grey
     * `grey, orange, green, blue`
     */
    color: {
      type: String,
      default: "grey",
      validator: (value) => {
        return value.match(/(grey|orange|green|blue)/);
      },
    },

    /**
     * The image used in the avatar. Can be set by hand, most likely implemented from API-call.
     */
    img: {
      Type: String,
      default: null,
    },
  },
  data() {
    return {
      backgroundStyling: {
        objectFit: "contain",
        background: "transparent"
      },
    };
  },
  computed: {
    imageStyle() {
      return this.img ? this.backgroundStyling : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.avatar {
  border-radius: $radius-circle;
  text-align: center;
  font-family: $font-text;

  &.xsmall {
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-size: $size-xs;
    font-weight: $weight-semi-bold;
  }

  &.small {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: $size-xs;
    font-weight: $weight-semi-bold;
  }

  &.medium {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: $size-m;
    font-weight: $weight-bold;
  }

  &.large {
    width: 68px;
    height: 68px;
    line-height: 68px;
    font-size: $size-xl;
    font-weight: $weight-bold;
  }

  &.xlarge {
    width: 84px;
    height: 84px;
    line-height: 84px;
    font-size: $size-xxl;
    font-weight: $weight-bold;
  }

  &.hasImage {
    background: transparent !important;
  }

  &.grey {
    background: $color-grey-3;
    color: $color-grey-5;
  }
  &.orange {
    background: $color-orange-light;
    color: $color-orange-dark;
  }
  &.green {
    background: $color-green-light;
    color: $color-green-dark;
  }
  &.blue {
    background: $color-blue-light;
    color: $color-blue-dark;
  }

  img {
    object-fit: cover;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <q-avatar size="xlarge">MB</q-avatar>
    <q-avatar size="large">MB</q-avatar>
    <q-avatar size="medium">MB</q-avatar>
    <q-avatar size="small">MB</q-avatar>    
    
    <q-avatar img="https://i.pravatar.cc/300" size="large"></q-avatar>
    <q-avatar img="https://i.pravatar.cc/300" size="medium"></q-avatar>
    <q-avatar img="https://i.pravatar.cc/300" size="small"></q-avatar>
  </div>
  ```
</docs>

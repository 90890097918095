const { getDate } = require("../../../assets/js/utils");

exports.parseResultsetField = (row, fieldName, filterMenuConditions) => {
    let value = row[fieldName]
    const indicatorId = fieldName.replace('display-', '');
    const filterOnField = filterMenuConditions.find(filterCondition => filterCondition.indicatorId === indicatorId);
    if(filterOnField && value && filterOnField.answerType === 'text') {
        const filterValue = filterOnField ? filterOnField.filters.value : '';
        value = addHighlights(value, filterValue);
    }

    switch (fieldName) {
        case 'referenceStartDate':
            return getDate(parseInt(value));
        case 'referenceEndDate':
            return getDate(parseInt(value));
        default:
            return value === 0 || value === null ? '-' : value;
    }
}

exports.compareReferenceContextNames = (firstLabel, secondLabel) => {
    if (firstLabel.title === secondLabel.title) return 0;
    if (firstLabel.title === 'Crow_project_details' || firstLabel.title === 'Project_details') return -1;            
    if (secondLabel.title === 'Crow_project_details' || secondLabel.title === 'Project_details') return 1;            
    
    if (firstLabel.title < secondLabel.title) return -1;
    if (firstLabel.title > secondLabel.title) return 1;
    return 0;
}

function addHighlights(text, marking) {
    var change = new RegExp('\(' + marking + '\)', 'gim');
    var updatedValue = text.replace(change, '<span style="background-color: #F4B976;border-radius:3px;">$1</span>');
    return updatedValue;
}
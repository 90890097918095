<template>
    <div></div>
</template>

<script>
export default {
    created() {
        this.$store.commit('resetState');
        this.$router.push('/login');
    },
};
</script>

<style lang="scss" scoped>
</style>

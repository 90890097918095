<template>
    <div class="question-test">
        
        <div style="display:flex;justify-content:center"
            v-for="(question,key) in questions"
            :key="question.id">

            <q-question v-model="question.answer"
                :type="question.type"
                :name="question.name"
                :options="question.options"
                :id="question.id"
                :number="key+1"
                :settings="question.settings"></q-question>
                
        </div>

        <span v-if="questions[0].answer">{{questions[0].answer.satisfied}}</span>
    </div>
</template>

<script>

import QQuestion from '../../QQuestion';
import QRadio from '../RadioQuestion';

export default {
    components: {
        'q-question': QQuestion,
        'q-radio': QRadio
    },
    data() {
        return {
            questions: [
                {
                    id: '1',
                    name: 'Op welke wijze leverde de ON de (project)plannen en/of de planning aan?',
                    type: 'radio',
                    options: [
                        {value:'1',label:'De (project)plannen en/of de planning waren niet beschikbaar'},
                        {value:'4',label:'De (project)plannen en/of de planning waren na de daarvoor gestelde termijn beschikbaar en daarover is pas na aandringenvan de OG op gereageerd.'},
                        {value:'7',label:'De (project)plannen en/of de planning waren binnen de daarvoor gestelde termijn beschikbaar.'},
                        {value:'9',label:'De (project)plannen en/of de planning waren minimaal ruim voor de daarvoor gestelde termijn beschikbaar.'},
                        {value:'0',label:'Niet van toepassing'},
                    ],
                    settings: {
                        satisfied: true,
                        improvement: false
                    },
                    answer: {
                        answer: '',
                        comment: '',
                        agreement: ''
                    }
                }
            ],
        }
    },

}
</script>

<style>

</style>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.54937 3C2.0074 4.0949 1 5.90383 1 7.95C1 11.2913 3.68629 14 7 14C10.3137 14 13 11.2913 13 7.95C13 5.90383 11.9926 4.0949 10.4506 3"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="7"
      y1="1"
      x2="7"
      y2="7"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Power",
  props: {
    color: {
      type: String,
      default: "#495057",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>

<style></style>

<template>
    <auth-base>
        <div class="q-card-small">
            <q-section vPadding="40" hPadding="40">
                <h2>E-mail verzonden</h2>
                <p class="py-m">Volg de instructies die in de e-mail staan om je wachtwoord te herstellen.</p>
            </q-section>
            <q-section vPadding="24" hPadding="40" borderTop class="center-align">
                <q-button to="/" variation="blank" size="medium">Terug naar login</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import {} from '@/graphql/mutations';

export default {
    name: 'ResetPasswordConfirm',
    components: {
        AuthBase
    },
};
</script>

<style lang="scss" scoped></style>

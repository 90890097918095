import { render, staticRenderFns } from "./LetterSettings.vue?vue&type=template&id=a3ff2962&scoped=true"
import script from "./LetterSettings.vue?vue&type=script&lang=js"
export * from "./LetterSettings.vue?vue&type=script&lang=js"
import style0 from "./LetterSettings.vue?vue&type=style&index=0&id=a3ff2962&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3ff2962",
  null
  
)

export default component.exports
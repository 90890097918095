<template>
    <div class="reference-overview-step">
        <div v-if="allComponents > 0" class="content">
            <div class="top-bar">
                <div class="label-switch">
                    <q-label-switch class="label-switch" :options="referenceFilters" v-model="selectedReferenceFilter" @input="setProjectIndicators" optionWidth="190px"></q-label-switch>
                </div>
                <div class="document-filter">
                    <q-dropdown 
                        class="dropdown" 
                        v-model="selectedDocumentId" 
                        :options="documentsFilterOptions" 
                        addNullValue
                        nullLabel="Geen bronfilter"
                        placeholder="Filter op bron"
                        size="small"
                        autocomplete
                    ></q-dropdown>
                </div>
            </div>
            <div class="body">
                <div class="left white-fade-block">
                    <context-overview
                        :project="project"
                        :indicators="indicators"
                        :editing="editing"
                        showFilledConversion
                        showVisibilityOption
                        @editIndicator="edit"
                        @indicatorUpdated="handleIndicatorUpdated"
                        @saveIndicator="handleSaveIndicator"
                        @removeIndicator="handleIndicatorRemoved"
                        @indicatorsAdded="handleIndicatorsAdded"
                        @contextUpdated="handleContextUpdated"
                        @indicatorVisibilityToggled="handleIndicatorVisibilityToggled"
                        @labelVisibilityToggled="handleLabelVisibilityToggled"
                        columnGap="16px"
                    ></context-overview>
                    <q-zerostate
                        v-if="componentsToFill === 0 && selectedReferenceFilter === 'empty'"
                        class="zerostate"
                        img="/statics/img/survey_gradient.svg"
                        title="Brief opstellen"
                        description="Alle kenmerken zijn ingevuld en klaar om opgesteld te worden, zie volgende stap"
                    >
                    </q-zerostate>
                    <q-zerostate
                        v-else-if="hiddenComponents === 0 && selectedReferenceFilter === 'hidden'"
                        class="zerostate"
                        img="/statics/img/survey_gradient.svg"
                        title="Verborgen kenmerken"
                        description="Zowel prestaties als kenmerken zijn te verbergen, deze worden dan niet meer meegenomen in de referentiebrief"
                    >
                    </q-zerostate>
                </div>
                <div class="right">
                    <document-search
                        :documentFilterId="selectedDocumentId"
                    ></document-search>
                </div>
            </div>
        </div>
        <div v-else class="content zero-state">
            <q-zerostate
                class="zerostate"
                img="/statics/img/survey_gradient.svg"
                title="Geen kenmerken"
                description="Dit project bevat nog geen kenmerken, voeg ze toe via het projectbeheer"
            >
                <q-button :to="`/projects/${this.project.id}/settings?title=${this.project.name}`">Ga naar project beheer</q-button>
            </q-zerostate>
        </div>
    </div>
</template>

<script>
import { isValidAnswer, getDate, getMoney, extractError } from '@/assets/js/utils.js';

import ContextOverview from '../ContextOverview.vue';
import DocumentSearch from './DocumentSearch.vue';

export default {
    name: 'reference-overview-step',
    components: {
        ContextOverview,
        DocumentSearch
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        documents: {
            type: Array,
            required: true
        },
        referenceLetter: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            selectedReferenceFilter: 'empty',
            indicators: [],
            componentsToFill: 0,
            allComponents: 0,
            hiddenComponents: 0,
            editing: '',
            selectedDocumentId: ''
        }
    },
    methods: {
        setProjectIndicators() {
            let components = this.project.references.components;

            components = components.map(component => {
                component.indicator.componentId = component.id;
                component.canEdit = this.canEditIndicator(component.indicator);
                return component
            });

            const answeredComponentIds = components
                .filter(component => isValidAnswer(component.indicator.answer))
                .map(component => component.id);
            const hiddenComponentIds = this.referenceLetter.hiddenComponentIds;

            const emptyComponentsOnly = this.selectedReferenceFilter === 'empty';
            const hiddenComponentsOnly = this.selectedReferenceFilter === 'hidden';

            const componentsToShow = components.map(component => {
                component.hidden = hiddenComponentIds.includes(component.id);
                
                if(hiddenComponentsOnly) component.show = component.hidden;
                else if(emptyComponentsOnly) component.show = !isValidAnswer(component.indicator.answer) && !component.hidden && component.canEdit;
                else component.show = true;

                return component
            });

            const unansweredHiddenComponentIds = hiddenComponentIds.filter(componentId => !answeredComponentIds.includes(componentId));

            this.componentsToFill = components.filter(component => 
                component.canEdit && 
                ![ ...answeredComponentIds, ...unansweredHiddenComponentIds ].includes(component.id)
            ).length;
            this.hiddenComponents = components.filter(component => component.hidden).length;
            this.allComponents = components.length;

            this.indicators = componentsToShow.map(component => {
                let required = component.indicator.required;
                if(component.hidden) required = false;

                return {
                    ...component.indicator,
                    componentId: component.id,
                    display: component.display,
                    disabled: component.disabled,
                    show: component.show,
                    hidden: component.hidden,
                    canEdit: component.canEdit,
                    required
                }
            });
        },
        canEditIndicator(indicator) {
            if (indicator.canEdit !== undefined) return indicator.canEdit

            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectOwnerOrganisationId = this.project.organisationId;
            const { protectionLevel } = indicator;
            const hasPermission = this.projectAbility.can('fill__Value__set_value');
            const disabledComponentIds = [
                'component-client',
                'component-contractor',
                'component-contractor-branch',
                'component-invoiced-sum',
                'component-crow-type',
                'component-form-version',
                'component-durability',
                'component-amsterdam-pricing',
                'component-discussed-psu', 
                'component-collaboration-contractor'
            ];

            if(this.project.clientStatus === 'finished' && ['default-context','crow-project-details'].includes(indicator.context.id)) return false
            if(disabledComponentIds.includes(indicator.componentId)) return false

            if (indicator.context?.default && this.project.clientStatus === 'finished') return false
            if (this.project.contractorStatus === 'finished') return false
            
            if (this.project.pdHybridmode) {
                if (this.organisationType === 'client') return hasPermission
                else if(indicator.masterId === 'client') return true
                else return false
            }

            if (
                (protectionLevel === 'private' || protectionLevel === 'private_shared') &&
                organisationId !== projectOwnerOrganisationId
            )
                return false

            if (!hasPermission) return false

            return true;
        },
        edit(indicator) {
            if (!indicator) return (this.editing = '');
            this.editing = indicator.componentId;
        },
        handleSaveIndicator(data) {
            this.$emit('indicatorUpdated', data);
        },
        handleIndicatorUpdated(indicator) {
            this.$emit('saveIndicator', indicator);
        },
        handleContextUpdated(project, context) {
            this.$emit('projectUpdated', project);
        },
        handleIndicatorVisibilityToggled(indicator) {
            this.$emit('indicatorVisibilityToggled', indicator);
        },
        handleLabelVisibilityToggled(label) {
            this.$emit('labelVisibilityToggled', label);
        },
        handleIndicatorRemoved(indicator) {
            this.$emit('indicatorRemoved', indicator);
        },
        handleIndicatorsAdded(project) {
            this.$emit('indicatorsAdded', project);
        }
    },
    computed: {
        referenceFilters: function() {
            return [
                {
                    label: 'Nog invullen',
                    value: 'empty',
                    tagLabel: this.componentsToFill,
                    tagColor: '#FD7E14'
                },
                {
                    label: 'Alle kenmerken',
                    value: 'all',
                    tagLabel: this.allComponents,
                    tagColor: '#00A1AE'
                },
                {
                    label: 'Verborgen',
                    value: 'hidden',
                    tagLabel: this.hiddenComponents,
                    tagColor: '#00A1AE'
                }
            ]
        },
        documentsFilterOptions: function() {
            const options = this.documents.map(document => {
                return {
                    label: document.documentName,
                    value: document.documentId
                }
            })
            return options
        }
    },
    created() {
        this.setProjectIndicators();
    },
    watch: {
        project: {
            handler() {
                this.setProjectIndicators();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.reference-overview-step {
    .content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 50px;
        padding-bottom: 0;
        max-height: calc(100% - 50px);
        overflow: hidden;

        .top-bar {
            display: flex;
            justify-content: space-between;

            .label-switch {
            }

            .document-filter {
                display: grid;
                place-items: center;

                .dropdown {
                    width: 300px;
                }
            }

        }

        .body {
            display: flex;
            gap: 16px;

            .left,
            .right {
                max-height: calc(100vh - 81px - 125px - 50px - 39px - 30px);
                width: 50%;
            }
            
            .left {
                overflow-y: auto;

                .panel {
                    width: 100% !important;
                }

                .context-overview {
                    padding-bottom: 80px;
                }

                .filled {
                    display: grid;
                    place-items: center;
                    gap: 24px;

                    p {
                        color: $color-grey-5;
                    }
                }

            }

            .right {
                min-width: 500px;
            }
        }
    }

    .zero-state {
        display: grid;
        place-items: center;
        height: calc(100% - 100px);
    }
}

.white-fade-block {
    position: relative;
    margin-top: -20px;
    padding-bottom: 20px;
    
    &:before,
    &:after {
        content: "";
        position: sticky;
        display: block;
        inset-inline: 0;
        height: 20px;
        z-index: 1;
    }
    &:before {
        top: 0px;
        background: linear-gradient(180deg, white, transparent);
    }
    &:after {
        bottom: -20px;
        background: linear-gradient(0deg, white, transparent);
    }
}

</style>
<template>
    <div class="pb-xs" style="max-width: 900px">
        <div v-for="orgType in projectOrganisations" :key="orgType">
            <div class="header">
                <h3 v-if="project[orgType]" class="truncate-text">{{ project[orgType].name }}</h3>
                <div v-else class="loading skeleton-line"></div>

                <div class="button" v-if="projectAbilityIsSet && canManageOrganisationUsers(orgType)">
                    <q-button
                        icon="users"
                        iconSize="18"
                        :iconStyle="{ width: '14px', height: '22px', marginLeft: '-9px', marginBottom: '-2px' }"
                        style="margin-left: 24px"
                        variation="primary"
                        size="small"
                        @click="addUser(orgType)"
                        v-project-can="'admin__Project__add_member'"
                        >{{ orgType === loggedInOrganisation ? 'Collega uitnodigen' : 'Projectlid uitnodigen' }}</q-button
                    >
                </div>
            </div>
            <div class="table-header">
                <q-table
                    :data="orgType === 'client' ? clientMembers : contractorMembers"
                    :columns="columns"
                    :loading="membersLoading"
                    :loadingRows="2"
                    paddingInline="42px"
                >
                    <template v-slot:row="{ row, column }">
                        <span v-if="column == 'avatar'">
                            <q-avatar size="small" :img="row.user.avatar">{{
                                initials(row.user.firstName, row.user.lastName)
                            }}</q-avatar>
                        </span>
                        <span style="margin-left: 20px" v-else-if="column == 'user'">
                            <span>{{ row.user.name }}</span>
                        </span>
                        <span v-else-if="column == 'access'">
                            <span>{{ rolesToText(row.roles) }}</span>
                        </span>
                        <span v-else-if="column == 'actions'" class="justify-end">
                            <q-button
                                v-if="projectAbilityIsSet && canManageOrganisationUsers(orgType)"
                                variation="light"
                                size="xsmall"
                                @click="edit(row)"
                                v-project-can="'admin__Project__change_member'"
                                >Wijzigen</q-button
                            >
                        </span>
                        <div v-else>
                            <span>{{ row[column] }}</span>
                        </div>
                    </template>
                </q-table>
            </div>
        </div>
        <div v-if="projectOrganisations.length && !projectOrganisations.includes('contractor')">
            <p><i>Selecteer eerst de opdrachtnemer om hier contactpersonen aan toe te voegen</i></p>
        </div>

        <q-popup v-if="addingUser">
            <project-user-modal
                :project="project"
                :projectUser="editUser"
                @close="close"
                @success="success"
                :organisations="organisationForSharing"
                :selectedOrganisationId="addUserToOrganisationId"
            ></project-user-modal>
        </q-popup>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import ProjectUserModal from '@/pages/app/project/projectmanagement/ProjectUserModal';

import { GET_PROJECT } from '../../../../graphql/queries';

import { extractError, userInitials, capitalize } from '@/assets/js/utils';

export default {
    name: 'project-user-settings',
    components: {
        ArrowButton,
        ProjectUserModal,
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        projectJwt: {
            type: String
        }
    },
    data() {
        return {
            columns: [
                {
                    field: 'avatar',
                    label: 'Wie',
                    width: '35px',
                    align: 'left',
                    loadingStyle: 'avatar',
                },
                {
                    field: 'user',
                    label: '',
                    width: '200px',
                },
                {
                    field: 'access',
                    label: 'Toestemming',
                    width: '200px',
                },
                {
                    field: 'branche',
                    label: 'Vestiging of afdeling',
                    width: '200px',
                },
                {
                    field: 'actions',
                    label: '',
                    width: '100px',
                    align: 'center',
                },
            ],
            
            editing: '',
            editUser: null,
            searching: false,
            search: '',
            forms: [],
            deleteFormModal: null,
            deleteProjectModal: false,
            confirmDelete: '',
            members: [],
            membersLoading: true,
            addingUser: false,
            addingUserOrganisation: null,
            addingUserUser: null,
            usersForSharing: [],
            projectOrganisations: ['client', 'contractor'],
            addUserToOrganisationId: null,
            projectLoading: false,
            projectAbilityIsSet: false,
            title: '',
            loggedInOrganisation: this.$store.getters.getOrganisationType,
        };
    },
    methods: {
        initials(firstName, lastName) {
            return userInitials(firstName, lastName);
        },
        close() {
            this.editUser = null;
            this.addingUser = false;
        },
        success() {
            this.editUser = null;
            this.addingUser = false;
            this.$router.go();
        },
        edit(user) {
            this.editUser = user;
            this.addingUser = true;
        },
        addUser(orgType) {
            this.addUserToOrganisationId = this.project[orgType].id;
            this.addingUser = true;
        },
        getRolesString(row) {
            const conversion = {
                read: 'bekijken',
                create: 'invullen',
                share: 'delen',
                delete: 'verwijderen',
                update: 'bijwerken',
            };

            if (!row.roles || row.roles.length == 0) return '';
            if (!conversion[row.roles[0]]) return row.roles[0];

            let string = 'Mag ';

            row.roles.forEach((role, key) => {
                string += conversion[role];
                if (key === row.roles.length - 2) string += ' en ';
                else string += ', ';
            });

            return string;
        },
        back() {
            this.$router.push('/projects/' + this.$route.params.id);
        },
        onProjectFetched() {
            const { invite } = this.$route.query;

            switch (invite) {
                case 'contractor':
                    this.addUserToOrganisationId = this.project.contractor.id;
                    this.addingUser = true;
                    break;
                default:
                    break;
            }
        },
        updateMembers() {
            let items = [];

            if (this.project.contractor) {
                items.push({
                    user: {
                        avatar: this.project.contractor.logo,
                        name: 'Alle beheerders',
                    },
                    organisationId: this.project.contractor.id,
                    organisation: {
                        avatar: this.project.contractor.logo,
                        name: this.project.contractor.name,
                    },
                    roles: ['admin'],
                    organisationAdmins: true,
                });
            }

            if (this.project.client) {
                items.push({
                    user: {
                        avatar: this.project.client.logo,
                        name: 'Alle beheerders',
                    },
                    organisationId: this.project.client.id,
                    organisation: {
                        avatar: this.project.client.logo,
                        name: this.project.client.name,
                    },
                    roles: ['admin'],
                    organisationAdmins: true,
                });
            }

            if (this.project.members)
                this.project.members.forEach((member, index) => {
                    let organisation = member.organisation || {
                        name: 'Onbekend',
                        avatar: undefined,
                    };
                    if (member.status !== 'invited' && member.status !== 'remote_added') {
                        items.push({
                            user: {
                                name:
                                    (member.user.firstName ? member.user.firstName : '') +
                                    ' ' +
                                    (member.user.lastName ? member.user.lastName : ''),
                                firstName: member.user.firstName,
                                lastName: member.user.lastName,
                                email: member.user.email,
                                avatar: member.user.avatar,
                                id: member.user.id,
                            },
                            organisation: {
                                id: organisation.id,
                                name: organisation.name,
                                avatar: organisation.logo,
                            },
                            organisationId: organisation.id,
                            roles: member.roles,
                            index,
                        });
                    } else {
                        items.push({
                            user: {
                                name: member.email,
                                avatar: null,
                            },
                            organisation: {
                                id: organisation.id,
                                name: organisation.name,
                                avatar: organisation.logo,
                            },
                            organisationId: organisation.id,
                            roles: ['invited'],
                            index,
                        });
                    }
                });

            this.membersLoading = false;
            this.members = items;
        },
        updateUserRoles() {
            const members = this.project.members || [];
            const user = this.$store.getters.getUser;
            const organisation = this.$store.getters.getCurrentOrganisation;
            const member = members.find(
                (member) => member.userId === user.id && member.organisationId === organisation.id
            );
            const isGeustOrganisation =
                organisation.id !== this.project.client?.id && organisation.id !== this.project.contractor?.id;

            this.projectAbility.set(this.project.id, member, this.ability, isGeustOrganisation);
            this.projectAbilityIsSet = true;
        },
        capitalize(string) {
            return capitalize(string);
        },
        currentRole(roles) {
            const filteredRoles = roles.filter((role) => role !== 'contactperson');
            return filteredRoles.length > 0 ? filteredRoles[0] : roles[0];
        },
        rolesToText(roles) {
            return `${this.$t(`projectRoles.${this.currentRole(roles)}`)}`;
        },
        canManageOrganisationUsers(organisationType) {
            if(this.$store.getters.getCurrentOrganisation.id === this.project.organisationId) return true
            return this.$store.getters.getOrganisationType === organisationType;
        }
    },
    computed: {
        isContractor() {
            return this.$store.getters.getOrganisationType === 'contractor';
        },
        organisationForSharing: function () {
            const { client, contractor } = this.project;
            let options = [];

            if (client)
                options.push({
                    value: client.id,
                    label: client.name,
                    avatar: client.logo,
                    avatarFallback: client.name.charAt(0),
                });

            if (contractor)
                options.push({
                    value: contractor.id,
                    label: contractor.name,
                    avatar: contractor.logo,
                    avatarFallback: contractor.name.charAt(0),
                });

            return options;
        },
        contractorMembers() {
            return this.members.filter((m) => m.organisationId === this.project.contractor.id);
        },
        clientMembers() {
            return this.members.filter((m) => m.organisationId === this.project.client.id);
        },
    },
    watch: {
        project() {
            this.projectOrganisations = [];
            const organisationType = this.$store.getters.getOrganisationType;
            const companionType = organisationType === 'client' ? 'contractor' : 'client';
            const canViewCompanion = this.project.usesCrowFlow;

            this.projectOrganisations.push(organisationType);
            if(canViewCompanion && this.project[companionType]) this.projectOrganisations.push(companionType);

            this.updateMembers();
            this.updateUserRoles();
            this.onProjectFetched();

            this.projectLoading = false;

            this.title = this.project.name;
        },
    },
  
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.header {
    display: flex;
    flex-direction: row;
    margin: 20px 25px 50px 3px;
    justify-content: space-between;

    .button {
        margin-top: 13px;
    }
}

.add-member {
    .field {
        // width: 300px;
    }

    .foot {
        display: flex;
        justify-content: flex-end;
    }
}

.confirm-delete {
    background-color: $color-grey-3;
    padding: 3px 6px;
    border-radius: 4px;
    // font-weight: 500;
}

.bold {
    font-weight: 700;
}

.delete {
    padding: 0 0 40px 40px;
}

.delete-color {
    color: $color-red;
}

.items {
    margin-top: 10px;

    .row {
        height: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 40px;

        &:not(:last-child) {
            border-bottom: 1px solid #dee2e6;
        }

        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            width: 200px;
        }

        .content {
            font-size: 13px;
            line-height: 22px;
            width: 440px;
        }

        .action {
            width: 60px;
        }
    }
}

.add-user {
    margin: 20px 0 18px 90px;
}

span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.role {
    font-weight: 700;
}

.divider {
    margin-left: -3px;
}

.settings {
    display: flex;
    align-items: center;
    flex-direction: column;

    .panel {
        max-width: 900px;
        width: 100%;
        // padding-bottom: 10px;
        margin-bottom: 40px;

        h3 {
            padding: 32px 40px 0 40px;
        }

        p {
            padding: 20px 40px 0 40px;
        }

        &.shared {
            h3 {
                padding: 40px 0 0 40px;
            }
        }
    }
}

.head {
    // margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .status {
            margin-left: 10px;
        }

        h3 {
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
        }
    }

    .loading {
        $base-color: #eef0f1;
        $shine-color: #e3e7e9;
        $animation-duration: 2.6s;

        @mixin background-gradient {
            background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
            background-size: 600px;
        }

        @keyframes shine-lines {
            0% {
                background-position: -150px;
            }

            100% {
                background-position: 350px;
            }
        }
        background-color: lighten($color-grey-3, 5%);
        border-radius: 4px;
        width: 300px;
        height: 30px;
        margin: 0 0;

        @include background-gradient;
        animation: shine-lines $animation-duration infinite ease-out;
        .skeleton-line ~ .skeleton-line {
            background-color: #ddd;
        }
    }
}

.avatar-row {
    display: flex;
    align-items: center;

    height: 43px;

    position: relative;

    .user {
        padding: 0 0 0 16px;

        .invited {
            position: absolute;
            top: 0;

            font-family: Gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 9px;
            line-height: 15px;
        }

        .name {
        }
    }
}
.table-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 310px;
    overflow-y: auto;
}

.table-panel {
    max-width: 900px;
    width: 100%;
    // padding-bottom: 10px;
    margin-bottom: 40px;

    &.shared {
        .head {
            padding: 40px;
        }
    }

    .avatar-row {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: -3px;
    }
}

* {
    font-family: Gotham;
}
</style>

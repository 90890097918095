const ICONS = {
    chevronDown: 'M1.562,5.281l7.438,7.438l7.438,-7.438',
    chevronUp: 'M16.441,12.714l-7.444,-7.433l-7.433,7.444',
    chevronLeft: 'M12.717,1.56l-7.436,7.441l7.441,7.436',
    chevronRight: 'M5.283,16.44l7.436,-7.441l-7.441,-7.436',
    // arrowDownFixed: 'M1.886,5.441l7.109,7.118l7.119,-7.109',
    check: 'M1.555,8.349l5.584,5.862l9.306,-10.422',
    warning: 'M8 1L1 11H15L8 1Z',
    dash: 'M1.5 1H8.5'
};

export default ICONS;

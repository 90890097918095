import SelectOrg from '@/pages/access/SelectOrg';
import Profile from '@/pages/access/Profile';
import RegisterOrg from '@/pages/access/RegisterOrg';
import ProfileSettings from '@/pages/access/ProfileSettings';
import OrganisationSettings from '@/pages/access/OrganisationSettings';
import Confirm from '@/pages/access/Confirm';

const routes = [
    {
        path: '/',
        name: 'SelectOrg',
        component: SelectOrg,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'register/organisation',
        name: 'registerOrganisation',
        component: RegisterOrg,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'profile/settings',
        name: 'ProfileSettings',
        component: ProfileSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'profile/organisations',
        name: 'OrganisationSettings',
        component: OrganisationSettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'organisations/confirm',
        name: 'Confirm',
        component: Confirm,
        meta: {
            requiresAuth: true
        }
    }
];

export default routes;

<template>
    <svg :width="width" :height="height" :viewBox="`0 0 17 12`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.08027" cy="3.0102" r="3.0102" :fill="color"/>
        <circle cx="12.8231" cy="3.25108" r="2.16734" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8121 9.50129H15.6822C16.4548 9.50129 16.9141 8.67229 16.418 8.07993C16.0394 7.62783 15.5922 7.18398 15.1457 6.94718C14.3246 6.51173 13.7729 6.43636 12.8643 6.43636C11.9557 6.43636 11.3778 6.45465 10.5829 6.94718C10.3864 7.06888 10.1966 7.24158 10.0185 7.4405C10.6465 7.897 11.2718 8.68551 11.8121 9.50129Z" :fill="color"/>
        <path d="M10.5727 11.8H2.11123C1.41127 11.8 0.939337 11.1037 1.25332 10.4781C1.70169 9.5848 2.36402 8.47861 3.09537 7.96887C4.15535 7.23008 4.9258 7.20264 6.13726 7.20264C7.34871 7.20264 8.0843 7.3157 9.17914 7.96887C9.98696 8.4508 10.7963 9.50447 11.3768 10.385C11.79 11.0117 11.3233 11.8 10.5727 11.8Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'users-filled',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 17,
        },
        height: {
            type: [Number, String],
            default: 12,
        },
    },
};
</script>

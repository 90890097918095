<template>
    <svg :width="width" :height="height" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.35111" y="0.612372" width="5.50187" height="10.5815" rx="0.5" transform="rotate(15 3.35111 0.612372)" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="23.3343" y="3.2891" width="5.50187" height="10.5815" rx="0.5" transform="rotate(-15 23.3343 3.2891)" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.4649 12.4277L24.449 13.1389" :stroke="color"/>
        <path d="M6.46729 11.311C6.46729 11.311 12.3596 17.3049 14.1883 17.9144C16.0169 18.524 17.236 17.4065 17.236 17.4065M15.4074 15.5778C15.4074 15.5778 17.3376 17.5081 18.2519 18.4224C19.1663 19.3367 21.0175 17.7745 19.8774 16.6344M16.6265 13.546C16.6265 13.546 18.8615 15.5778 20.1822 16.8985C21.5029 18.2192 23.3315 16.289 22.1124 15.0699M22.1124 15.0699C20.6048 13.6019 18.2519 11.311 18.2519 11.311M22.1124 15.0699C23.6769 16.6344 25.8713 14.5619 24.2458 12.9364C22.8236 11.5141 18.8615 6.63774 17.236 6.63774C15.6105 6.63774 13.5787 9.07594 12.0548 8.26321C10.531 7.45048 13 4.5 14 3.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.60083 3.08076C8.60083 3.08076 12.9843 3.60129 14.0002 3.49963C15.1178 2.48366 15.3748 1.2963 17.0002 1.49948C18.6257 1.70266 23.3316 4.60464 23.3316 4.60464" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="4.43554" cy="9.58422" r="0.812734" :stroke="color"/>
        <circle cx="27.3953" cy="11.2092" r="0.812734" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'ShakingHands',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 32,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
    },
};
</script>

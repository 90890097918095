<template>
    <div class="branch-card-wrapper" :class="[size, { selected: selected, interactive: interactive }]">
        <div v-if="name !== 'empty'" class="content">
            <span>{{ sender }}</span>
            <span class="name">{{ name }}</span>
            <span>{{ line1 }}</span>
            <span>{{ line2 }}</span>
        </div>
        <div v-else class="empty">
            <span>Geen adres</span>
        </div>
        <q-icon class="check-icon" type="check" color="#00A1AE"></q-icon>
        <q-options v-if="options.length > 0 && name !== 'empty'" :options="options" color="#C4C4C4" class="branch-options" @input="handleOptionSelected"></q-options>
    </div>
</template>

<script>
export default {
    name: 'q-branch-card',
    props: {
        sender: {
            type: String, 
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        line1: {
            type: String,
            default: ''
        },
        line2: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'medium',
            validator: value => {
                return value.match(/(small|medium)/);
            }
        },
        interactive: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default() { return [] }
        }
    },
    methods: {
        handleOptionSelected(option) {
            this.$emit('optionSelected', option)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.branch-card-wrapper {
    position: relative;
    border-radius: 6px;
    outline: 1px solid #DEE2E6;
    background-color: #ffffff;
    width: 100%;
    transition: scale .2s cubic-bezier(.45,1.8,.58,1.21), outline .15s ease;
    user-select: none;

    &.small {
        height: 64px;
        width: 150px;

        .content {
            padding-inline: 10px;

            span {
                font-size: 11px;
                line-height: 13px;
            }
        }

        .branch-options {
            right: 2px;
            bottom: 6px;
        }
    }

    &.medium {
        height: 80px;
        width: 200px;

        .content {
            padding-inline: 16px;

            span {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .branch-options {
            right: 4px;
            bottom: 10px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        span {
            font-size: 11px;
            line-height: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #484f56;
        }

        .name {
            font-weight: 500;
        }

    }

    .empty {
        display: grid;
        place-items: center;
        height: 100%;

        span {
            font-size: 14px;
            font-weight: 500;
            color: $color-grey-5;
        }
    }

    // interactions
    &.interactive {
        cursor: pointer;

        &:active {
            scale: 0.97 !important;
            z-index: 1;
        }

        &:hover {
            scale: 1.02;
            z-index: 1;
        }
    }

    &.selected {
        outline: 2px solid #00A1AE;

        .check-icon {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }

    .check-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        transition: clip-path .3s ease;
    }

    .branch-options {
        position: absolute;
    }
}
</style>
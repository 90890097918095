<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 1H8L8 10.5L1 10.5L1 1ZM0 0.999999C0 0.447715 0.447715 0 1 0H8C8.55228 0 9 0.447715 9 1V10.5C9 11.0523 8.55228 11.5 8 11.5H1C0.447715 11.5 0 11.0523 0 10.5V0.999999ZM12 1H19V6.75L12 6.75V1ZM11 1C11 0.447715 11.4477 0 12 0H19C19.5523 0 20 0.447715 20 1V6.75C20 7.30228 19.5523 7.75 19 7.75H12C11.4477 7.75 11 7.30228 11 6.75V1ZM19 10.75H12V19H19V10.75ZM12 9.75C11.4477 9.75 11 10.1977 11 10.75V19C11 19.5523 11.4477 20 12 20H19C19.5523 20 20 19.5523 20 19V10.75C20 10.1977 19.5523 9.75 19 9.75H12ZM1 14.5H8L8 19L1 19L1 14.5ZM0 14.5C0 13.9477 0.447715 13.5 1 13.5H8C8.55228 13.5 9 13.9477 9 14.5V19C9 19.5523 8.55228 20 8 20H1C0.447715 20 0 19.5523 0 19V14.5Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'Dashboard',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
    },
};
</script>

<style></style>

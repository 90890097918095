<template>
    <div class="page-padding">
        <div class="header">
            <arrow-button color="#aeb5bc" @click="$router.push('/settings')" direction="left">Instellingen</arrow-button>
            <div class="space-between">
                <h2>Formulieren</h2>
                <q-button size="medium" variation="primary" @click="handleNewFormTemplate">Formulier toevoegen</q-button>
            </div>
            <p>In dit overzicht vind je alle formuliertemplates die beschikbaar zijn voor je organisatie. Ook kun je een nieuw formuliertemplate aanmaken of bestaande formuliertemplates wijzigen.</p>
        </div>

        <div class="template-group">
            <h3>Formuliertemplates</h3>
            <div class="show-loader" :class="loadingTemplates ? 'show' : 'hide'">
                <div class="center">
                    <div class="loader"></div>
                </div>
            </div>
            <context-rows
                v-if="formTemplates.length > 0"
                class="template-rows"
                :labels="formTemplates"
                dataName="formulier"
                showTag
                showStatus
                @toggleShowRow="handleSelectRow"
                @indicatorDeleted="handleIndicatorDeleted"
                @labelUpdated="handleLabelUpdated"
                @labelDeleted="handleLabelDeleted">
            </context-rows>
            <p v-else-if="!loadingTemplates" class="template-rows">Er zijn geen actieve formuliertemplates gevonden</p>
        </div>

        <div v-if="bpFormTemplates.length > 0" class="template-group">
            <h3>Better Performance</h3>
            <context-rows
                class="template-rows"
                :labels="bpFormTemplates"
                disableEdit
                dataName="formulier"
                showTag
                showStatus
                @toggleShowRow="handleSelectRow"
                @labelUpdated="handleLabelUpdated"
                @labelDeleted="handleLabelDeleted"
            ></context-rows>
        </div>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue';
import gql from 'graphql-tag';
import ContextRows from './ContextRows.vue';
import { UPDATE_FORMTEMPLATE, REMOVE_FORMTEMPLATE } from '@/graphql/mutations';
import { GET_FORM_TEMPLATES } from '@/graphql/queries';

export default {
    name: 'form-templates',
    components: {
        'arrow-button': ArrowButton,
        ContextRows
    },
    data() {
        return {
            formTemplates: [],
            bpFormTemplates: [],
            loadingTemplates: false
        }
    },
    methods: {
        getFormTemplates() {
            this.loadingTemplates = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
            this.$apollo.query({
                query: GET_FORM_TEMPLATES,
                variables: {
                    where: { AND: [
                        { organisationId },
                        { type: "form" },
                        { status__in: ["active", "disabled"] }
                    ]}
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const hasCrowProduct = this.$store.getters.hasActiveQfactProducts;
                const activeTemplates = result.data.formtemplates;
                const parsedTemplates = activeTemplates
                    .filter(template => !template.masterId)
                    .map(template => {
                        template.components = template.components.filter(component => component.indicatorId)
                        template.tagLabel = `${template.components.length} ${template.components.length === 1 ? 'vraag' : 'vragen'}`;
                        return template
                    })
                const organisationType = this.$store.getters.getOrganisationType;
                const latestVersion = '2023'
                const parsedBPTemplates = activeTemplates
                    .filter(template => template.masterId && template.masterId.includes(organisationType) && template.masterId.includes(latestVersion))
                    .map(template => {
                        const name = template.name.includes('form_template') ? this.$t(`formtemplates.${template.name}`) : template.name;
                        template.tagLabel = `${template.components.length} ${template.components.length === 1 ? 'vraag' : 'vragen'}`;
                        template.locked = true;
                        template.name = name;
                        return template
                    })
                this.formTemplates = parsedTemplates;
                this.bpFormTemplates = hasCrowProduct ? parsedBPTemplates : [];
                this.loadingTemplates = false;
            })
            .catch(error => {
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formuliertemplates' })
                this.loadingTemplates = false;
            })
        },
        handleSelectRow(row) {
            this.$router.push(`/manage/formtemplates/${row.id}?name=${row.name}`)
        },
        handleNewFormTemplate() {
            this.$router.push(`/manage/formtemplates/create`);
        },
        handleIndicatorDeleted(indicator) {
            this.formTemplates = this.formTemplates.map(label => {
                if(label.id !== indicator.labelIds[0]) return label
                const key = indicator.type === 'question' ? 'questions' : 'references';
                const index = label[key].findIndex(_indicator => _indicator.id === indicator.id);
                if(index === -1) return label
                label[key].splice(index, 1);
                return label
            })
        },
        handleLabelUpdated(label) {
            this.$apollo.mutate({
                mutation: UPDATE_FORMTEMPLATE,
                variables: {
                    id: label.id,
                    name: label.name,
                    description: label.description,
                    status: label.status
                }
            })
            .then(result => {
                this.formTemplates = this.formTemplates.map(_label => {
                    if(_label.id === label.id) return label
                    return _label
                })
                this.bpFormTemplates = this.bpFormTemplates.map(_label => {
                    if(_label.id === label.id) return label
                    return _label
                })
                this.$store.commit('notify', { type: 'success', message: 'De wijzigingen zijn doorgevoerd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de wijzigingen' })
            })
        },
        handleLabelDeleted(label) {
            this.$apollo.mutate({
                mutation: REMOVE_FORMTEMPLATE,
                variables: {
                    id: label.id
                }
            })
            .then(result => {
                this.formTemplates = this.formTemplates.filter(_label => _label.id !== label.id);
                this.bpFormTemplates = this.bpFormTemplates.filter(_label => _label.id !== label.id);
                this.$store.commit('notify', { type: 'success', message: 'Formulier is succesvol verwijderd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van het formulier' })
            })
        },
    },
    created() {
        this.getFormTemplates();
    }
}
</script>

<style lang="scss" scoped>

.page-padding {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        min-width: 300px;
    }

    .right {
        display: flex;
        gap: 30px;
    }
}

.template-group {
    padding-top: 20px;
    .template-rows {
        margin-top: 20px;
    }
}

.show-loader {
    overflow: hidden;
    transition: max-height .2s ease;

    &.show {
        max-height: 120px;
    }
    &.hide {
        max-height: 0;
        .center .loader {
            transition: .2s ease;
            scale: 0.5;
            opacity: 0;
        }
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 40px;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 40px;
    $loader-border-size: 3px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>
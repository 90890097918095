<template>
  <form class="tabber">
    <div v-for="choice in choices" :key="choice.label">
      <input
        :id="choice.label"
        name="toggle"
        :value="choice.label"
        :checked="choice.label === value"
        type="radio"
        @input="handleInput"
      />
      <label :for="choice.label">{{ choice.text }}</label>
    </div>
  </form>
</template>

<script>
export default {
  name: "q-toggle",
  status: "prototype",
  release: "0.0.1",
  props: {
    name: {
      type: String,
      default: "toggle",
    },

    state: {
      type: String,
      default: null,
    },

    /**
     * Give toggle a label
     */

    label: {
      type: String,
      default: null,
    },
    /**
     * Give toggle a value
     */
    value: {
      type: String,
      default: null,
    },

    choices: {
      type: Array,
    },
  },
  methods: {
    handleInput(event) {
      console.log(event.target.value);
      if (this.disabled) {
        return false;
      }
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.tabber {
  color: $color-grey-5;
  font-family: $font-text;
  font-weight: $weight-semi-bold;
  font-size: $size-m;
  line-height: 24px;
  margin: 1px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  position: relative;

  label {
    display: block;
    min-width: 83px;

    user-select: none;
    padding: 8px 18px;
    cursor: pointer;
    border: 1px solid $color-grey-5;
    text-align: center;
    margin: -0.5px;
    position: relative;
    z-index: 1;

    &:hover {
      border: 1px solid $color-primary;
      color: $color-primary;
    }
  }

  div:first-child {
    label {
      border-radius: 5px 0 0 5px;
    }
  }

  div:last-child {
    label {
      border-radius: 0 5px 5px 0;
    }
  }

  input[type="radio"] {
    display: none;
    width: 100%;
  }

  input:checked + label {
    border: 1px solid $color-primary;
    color: $color-primary;
    background: $color-blue-toggle;
    position: relative;
    z-index: 2;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <q-toggle name="toggle" :choices="[{text:`Ja`, label: `ja`}, { text: `Nee`, label: `Nee`}, { text: `Anders`, label: `anders`}]"></q-toggle>
   

  </div>
  ```
</docs>

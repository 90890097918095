<template>
    <div class="style-wrapper" v-if="selectedStyle">
        <q-pageflow
            v-model="activePageIndex"
            :title="selectedStyle.name ? selectedStyle.name : 'Nieuwe formulierstijl'"
            back="/settings/styles"
            paddingLeft="30px"
        >

            <template v-slot:items>
                <q-pageitem
                    v-for="(step, index) in pageFlowItems"
                    :value="index"
                    :key="step.label"
                    :type="{ status: step.status }"
                    class="page-item"
                    :class="{ active: index == activePageIndex }"
                    :style="`--item-width: ${90 / pageFlowItems.length}%; ${
                        index <= highestVisitedStepIndex ? 'cursor: pointer;' : ''
                    }`"
                    @click.native="navigateToStep(index)"
                >
                    <span class="label">{{ step.label }}</span>
                </q-pageitem>
            </template>
        </q-pageflow>

        <div id="steps-view" class="steps-view">
            <div id="steps-wrapper" class="steps-wrapper">
                <div :id="`step-${index}`" v-for="(step, index) in visitedSteps" :key="index" class="step-container">
                    <component
                        :is="`style-step-${step.component}`"
                        :index="index + 1"
                        :step="step"
                        :selectedStyle="selectedStyle"
                        :availableColors="combinedFormStyles.colors"
                        :availableLogos="combinedFormStyles.logos"
                        :availableAddresses="combinedFormStyles.addresses"
                        @navigateToStep="navigateToStep"
                    />
                </div>
            </div>
        </div>

        <div class="navigation-buttons">
            <q-button
                class="button previous-step"
                :class="activePageIndex > 0 ? 'show' : 'hide'"
                variation="blank"
                @click="previousStep"
            >
                Vorige stap
            </q-button>
            <q-button
                class="button"
                variation="primary"
                @click="nextStep"
                :disabled="!pageFlowItems[activePageIndex].filled"
                :loading="loading"
            >
                {{ nextStepLabel }}
            </q-button>
        </div>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import StyleStepColor from './StyleStepColor.vue';
import StyleStepLogo from './StyleStepLogo.vue';
import StyleStepBranch from './StyleStepBranch.vue';
import StyleStepName from './StyleStepName.vue';

import { CONFIGURATION, CONFIGURATIONS } from '@/graphql/queries.js';
import { CREATE_CONFIGURATION } from '@/graphql/mutations.js';
import { UPDATE_CONFIGURATION } from '../../../../graphql/mutations';

export default {
    name: 'style-flow',
    components: {
        ArrowButton,
        StyleStepColor,
        StyleStepLogo,
        StyleStepBranch,
        StyleStepName,
    },
    data() {
        return {
            isEditing: false,
            activePageIndex: 0,
            highestVisitedStepIndex: 0,
            lastActivePageIndex: 0,
            steps: [
                {
                    name: 'Kies je <span style="color: #00A1AE;">stijlkleur</span>',
                    about: 'Kies hier een stijlkleur die op je formulieren wilt gebruiken. Met deze kleur worden accenten in je brief, zoals onderlijnde tekst en titels, weergegeven.',
                    component: 'color',
                },
                {
                    name: 'Kies een <span style="color: #00A1AE;">logo</span>',
                    about: `Kies hier een logo dat op je formulieren mag weergegeven worden op de export van je formulieren.`,
                    component: 'logo',
                },
                {
                    name: 'Kies een <span style="color: #00A1AE;">adres</span>',
                    about: 'Kies hier een adres dat op je formulieren mag weergegeven worden bij ‘Adres afzender’. Het is niet mogelijk om meerdere adressen te koppelen aan één formulierstijl.',
                    component: 'branch',
                },
                {
                    name: 'Geef je formulierstijl een <span style="color: #00A1AE;">naam</span>',
                    placeholder: 'Naam formulierstijl',
                    component: 'name',
                },
            ],
            formStyles: [],
            selectedStyle: null,
            loading: false
        };
    },
    methods: {
        navigateToStep(stepIndex) {
            if (stepIndex > this.highestVisitedStepIndex) return;
            this.lastActivePageIndex = this.activePageIndex;

            const stepElement = document.getElementById(`step-${stepIndex}`)
            if(stepElement) stepElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        nextStep() {
            const isLastStep = this.activePageIndex == this.steps.length - 1;
            if (isLastStep) return this.saveFormStyle();
            this.lastActivePageIndex = this.activePageIndex;
            if (this.activePageIndex > this.highestVisitedStepIndex)
                this.highestVisitedStepIndex = this.activePageIndex;

            document
                .getElementById(`step-${this.activePageIndex + 1}`)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        previousStep() {
            this.lastActivePageIndex = this.activePageIndex;

            document
                .getElementById(`step-${this.activePageIndex - 1}`)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        saveFormStyle() {
            this.loading = true;

            const configuration = {
                name: this.selectedStyle.name,
                color: {
                    name: this.selectedStyle.color.name,
                    colorCode: this.selectedStyle.color.colorCode,
                },
                address: {
                    name: this.selectedStyle.address.name,
                    sender: this.selectedStyle.address.sender,
                    line1: this.selectedStyle.address.line1,
                    line2: this.selectedStyle.address.line2,
                },
                logo: this.selectedStyle.logo,
                type: 'style',
            };
            if (this.isEditing) {
                this.$apollo
                    .mutate({
                        mutation: UPDATE_CONFIGURATION,
                        variables: {
                            id: this.selectedStyle.id,
                            configuration,
                        },
                    })
                    .then((result) => {
                        this.loading = false;
                        this.$store.commit('notify', { type: 'success', message: 'Formulierstijl is succesvol gewijzigd' })
                        this.$router.push('/settings/styles');
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het wijzigen van dit formulierstijl' })
                        console.log(error)
                    });
            } else {
                this.$apollo
                    .mutate({
                        mutation: CREATE_CONFIGURATION,
                        variables: {
                            configuration,
                        },
                    })
                    .then((result) => {
                        this.loading = false;
                        this.$store.commit('notify', { type: 'success', message: 'Formulierstijl is succesvol gewijzigd' })
                        this.$router.push('/settings/styles');
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het wijzigen van dit formulierstijl' })
                        console.log(error)
                    });
            }
        },
        async initializeScroll() {
            await new Promise((r) => setTimeout(r, 100));
            const stepsView = document.getElementById('steps-view');
            const stepsWrapper = document.getElementById('steps-wrapper');
            const steps = stepsWrapper.children;
            const vm = this;

            stepsView.addEventListener(
                'scroll',
                function () {
                    this.scrolling = true;
                    window.clearTimeout(this.isScrolling);

                    let highestActivePageIndex = 0;

                    for (let i = 0; i < steps.length; i++) {
                        const step = steps[i];
                        const stepTop = step.getBoundingClientRect().top;
                        const stepInViewportTop = window.innerHeight / 2 + 100;

                        const stepIsInView = stepTop < stepInViewportTop;
                        if (!stepIsInView) break;

                        if (i > highestActivePageIndex) highestActivePageIndex = i;
                    }

                    vm.activePageIndex = highestActivePageIndex;

                    this.isScrolling = setTimeout(function () {
                        vm.scrolling = false;

                        if (vm.activePageIndex > vm.highestVisitedStepIndex)
                            vm.highestVisitedStepIndex = vm.activePageIndex;
                    }, 50);
                },
                false
            );
        },
        async autoScrollToStep() {
            await new Promise((r) => setTimeout(r, 250));
            const step = this.$route.query.step;
            if (step !== undefined) this.navigateToStep(parseInt(step));
        },
        setAllStyles() {
            this.$apollo
                .query({
                    query: CONFIGURATIONS,
                })
                .then((result) => {
                    this.formStyles = result.data.org_configurations.filter((configuration) => {
                        return configuration.type === 'style';
                    });
                });
        },
    },
    computed: {
        pageFlowItems: function () {
            const colorFilled = Boolean(this.selectedStyle.color);
            const logoFilled = Boolean(this.selectedStyle.logo);
            const addressFilled = Boolean(this.selectedStyle.address);
            const nameFilled = Boolean(this.selectedStyle.name);

            let items = [
                {
                    label: 'Stijlkleur',
                    filled: colorFilled,
                },
                {
                    label: 'Logo',
                    filled: logoFilled,
                },
                {
                    label: 'Adres',
                    filled: addressFilled,
                },
                {
                    label: 'Naam',
                    filled: nameFilled,
                },
            ];

            items = items.map((item, index) => {
                if (item.filled && this.highestVisitedStepIndex === index) this.highestVisitedStepIndex = index + 1;
                item['status'] = 'not_done';
                // if(this.highestVisitedStepIndex < index) item['status'] = 'not_done';
                if (item.filled) item['status'] = 'succes';
                return item;
            });

            return items;
        },
        visitedSteps() {
            let firstIncompleteIndex;
            this.pageFlowItems.forEach((item, index) => {
                if (item.status !== 'succes' && firstIncompleteIndex === undefined) firstIncompleteIndex = index;
            });
            if (firstIncompleteIndex === undefined) return this.steps;
            this.highestVisitedStepIndex = firstIncompleteIndex;
            return this.steps.filter((step, index) => index <= firstIncompleteIndex);
        },
        nextStepLabel() {
            const lastButtonLabel = `Formulierstijl ${this.isEditing ? 'opslaan' : 'aanmaken'}`;
            return this.activePageIndex == this.steps.length - 1 ? lastButtonLabel : 'Volgende stap';
        },
        combinedFormStyles() {
            let combinedFormStyles = {
                colors: [],
                logos: [],
                addresses: [],
            };

            this.formStyles.forEach((style) => {
                if (style.color && style.id !== this.selectedStyle.id && style.color.name !== 'empty')
                    combinedFormStyles.colors.push({ ...style.color, styleId: style.id });
                if (style.logo && style.id !== this.selectedStyle.id && style.logo !== 'empty')
                    combinedFormStyles.logos.push({ src: style.logo, styleId: style.id });
                if (style.address && style.id !== this.selectedStyle.id && style.address.name !== 'empty')
                    combinedFormStyles.addresses.push({ ...style.address, styleId: style.id });
            });

            return combinedFormStyles;
        },
    },
    created() {
        const formStyleId = this.$route.params.id;

        this.setAllStyles();

        if (formStyleId === 'create') {
            this.selectedStyle = {
                name: '',
                color: null,
                logo: '',
                address: null,
                created: Date.now(),
                lastEdited: Date.now(),
            };
            this.initializeScroll();
            return;
        }

        this.$apollo
            .query({
                query: CONFIGURATION,
                variables: {
                    configurationId: formStyleId,
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                this.selectedStyle = result.data.org_configuration;
                this.isEditing = true;
                this.highestVisitedStepIndex = this.pageFlowItems.length - 1;
                this.initializeScroll();
            });
    },
    mounted() {
        this.autoScrollToStep();
    },
};
</script>

<style lang="scss" scoped>
.page-item {
    padding: 0 !important;
    width: var(--item-width);

    &.active {
        width: calc(var(--item-width) + 10%) !important;
    }
}

.page-padding {
    min-height: unset;
    padding-bottom: 0;
}

.style-wrapper {
    .steps-view {
        --search-bar-height: 81px;
        --page-flow-height: 124px;
        --step-height: calc(100vh - var(--search-bar-height) - var(--page-flow-height));
        height: var(--step-height);
        overflow-y: scroll;
        scroll-snap-type: y mandatory;

        .steps-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step-container {
                position: relative;
                scroll-snap-align: start;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: var(--step-height);
                max-height: var(--step-height);
                width: 100%;
            }
        }
    }

    .navigation-buttons {
        position: absolute;
        bottom: 24px;
        right: 120px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        margin: 0 auto;
        width: 664px;
        user-select: none;
        pointer-events: none;
        background: none;

        .button {
            pointer-events: all;
        }

        .previous-step {
            z-index: 0;
            transition: margin-right 0.4s ease, opacity 0.4s ease;

            &.show {
                margin-right: 0;
                opacity: 1;
            }
            &.hide {
                opacity: 0;
                margin-right: -110px;
            }
        }
    }
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;

    &:hover {
        background: rgb(130, 130, 130);
    }
}
</style>

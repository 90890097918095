<template>
    <auth-base>
        <div class="q-card-small">
            <q-section vPadding="40" hPadding="40">
                <h2>Configureer 2 FA</h2>
                <p class="pt-m pb-m">U of de organisatie waar u bij wilt inloggen heeft aangegeven Twee Factor Authenticatie te willen gebruiken.</p>
                <p class="pb-m">Scan de QR-code om je 2 factor authentication in te stellen.</p>
                <div class="text-align-center">
                    <img :src="qrCode" alt="2fa-qrcode" />
                </div>
            </q-section>
            <q-section vPadding="24" hPadding="40" borderTop class="space-between">
                <q-button go="-1" variation="blank" size="medium">Terug</q-button>
                <q-button to="/twofactor" variation="primary" size="medium">Volgende</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';

export default {
    name: 'TwoFactorConfig',
    components: {
        AuthBase
    },
    data() {
        return {
            qrCode: this.$store.getters.getTwofactorQrCode
        };
    },
    mounted() {
        console.log(this.qrCode);
    }
};
</script>

<style lang="scss" scoped></style>

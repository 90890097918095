<template>
    <div class="context-overview">
        <div class="rows-container">
            <div v-for="row in rows" :key="row" class="context-row" :style="`--gap: ${columnGap}; --rows: ${rows}`">
                <context-card
                    v-for="context in contexts.filter((c, i) => rows === 1 || i % rows === row - 1)"
                    :key="context.id"
                    :context="context"
                    :indicators="context.indicators"
                    :allIndicators="context.allIndicators"
                    :editing="editing"
                    :projectProp="project"
                    :search="search"
                    :showFilledConversion="showFilledConversion"
                    :showVisibilityOption="showVisibilityOption"
                    :duplicatingEnabled="duplicatingEnabled && !['default-context','crow-project-details'].includes(context.id)"
                    @removeIndicator="handleIndicatorRemoved"
                    @contextUpdated="handleContextUpdated"
                    @editIndicator="edit"
                    @saveIndicator="onSaveIndicator"
                    @startLoading="onStartLoading"
                    @stopLoading="onStopLoading"
                    @setDuplicating="handleSetDuplicating"
                    @indicatorsAdded="handleIndicatorsAdded"
                    @toggleIndicatorVisibility="handleToggleIndicatorVisibility"
                    @toggleLabelVisibility="handleToggleLabelVisibility"
                ></context-card>
            </div>
        </div>
    </div>
</template>

<script>
import ContextCard from "./ContextCard.vue"

export default {
    name: 'context-overview',
    components: { 
        ContextCard 
    },
    props: {
        rows: {
            type: Number,
            default: 1
        },
        /*
        *   indicators must contain 'context' object
        *   (optional) indicator can contain 'show', if false this indicator will not be shown
        */
        indicators: {
            type: Array,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        editing: {
            type: String,
            default: ''
        },
        search: {
            type: String,
            default: ''
        },
        columnGap: {
            type: String,
            default: '40px'
        },
        showFilledConversion: {
            type: Boolean,
            default: false
        },
        duplicatingEnabled: {
            type: Boolean,
            default: true
        },
        // boolean to show the option to hide the context
        showVisibilityOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contexts: []
        }
    },
    methods: {
        setContexts() {
            let labels = [];
            this.indicators.forEach(indicator => {
                const labelIndex = labels.findIndex(label => label.id === indicator.context.id)
                if(labelIndex === -1) labels.push(indicator.context);
            });

            labels = labels.map(label => {
                const allLabelIndicators = this.indicators.filter(indicator => indicator.context.id === label.id);
                const shownLabelIndicators = allLabelIndicators.filter(indicator => !(Object.keys(indicator).includes('show') && !indicator.show));
                label.indicators = shownLabelIndicators;
                label.allIndicators = allLabelIndicators;

                return label
            })

            labels = labels.sort((a, b) => {
                if(a.order > b.order) return 1
                if(b.order > a.order) return -1
                return 0
            })

            this.contexts = labels.filter(label => {
                const visibleIndicators = label.indicators.filter(indicator => (!Object.keys(indicator).includes('show') || indicator.show) && !indicator.disabled);
                return visibleIndicators.length > 0
            });
        },
        handleIndicatorRemoved(indicator) {
            this.$emit('removeIndicator', indicator);
        },
        handleContextUpdated(project, context) {
            this.$emit('contextUpdated', project, context);
        },
        edit(indicator) {
            this.$emit('editIndicator', indicator)
        },
        onSaveIndicator(data) {
            this.$emit('saveIndicator', data);
        },
        onStartLoading(componentId) {
            this.$emit('startLoading', componentId);
        },
        onStopLoading() {
            this.$emit('stopLoading');
        },
        handleSetDuplicating(duplicating) {
            this.$emit('setDuplicating', duplicating)
        },
        handleIndicatorsAdded(project, context) {
            this.$emit('indicatorsAdded', project, context);
        },
        handleToggleIndicatorVisibility(indicator) {
            this.$emit('indicatorVisibilityToggled', indicator);
        },
        handleToggleLabelVisibility(label) {
            this.$emit('labelVisibilityToggled', label);
        },
        canEditIndicator(data) {
            return this.$parent.canEditIndicator(data);
        }
    },
    created() {
        this.setContexts();
    },
    watch: {
        indicators: {
            handler() {
                this.setContexts();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.context-overview {
    .rows-container {
        display: flex;
        gap: 40px;

        .context-row {
            display: flex;
            flex-direction: column;
            gap: var(--gap);
            width: 100%;
            max-width: calc(100% / var(--rows));

            &:empty {
                display: none;
            }
        }
    }
}

</style>
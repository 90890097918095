<template>
    <div>
        <div class="body">
            <span v-html="deleteMessage"></span>
        </div>
        <div class="footer">
            <q-button variation="blank" @click="$emit('close')">{{ cancelButtonText }}</q-button>
            <q-button variation="danger" @click="$emit('confirm')">{{ confirmButtonText }}</q-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user','deleteMessage','cancelButtonText','confirmButtonText']

}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.body {
    height: 100px;
    margin-top: 20px;
}

.footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    margin: 0 -40px;
    padding: 20px 40px;
    border-top: 1px solid rgb(222, 226, 230);
    justify-content: space-between;
}

</style>
<template>
    <svg :width="width" :height="height" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H8" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="3.5" y1="0.5" x2="5.5" y2="0.5" :stroke="color" stroke-linecap="round"/>
        <path d="M0.650203 3.06756C0.609265 2.76734 0.842625 2.5 1.14562 2.5H7.85438C8.15737 2.5 8.39074 2.76734 8.3498 3.06756L7.12252 12.0676C7.08874 12.3153 6.87714 12.5 6.62711 12.5H2.37289C2.12286 12.5 1.91126 12.3153 1.87748 12.0676L0.650203 3.06756Z" fill="white" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Delete',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 9
        },
        height: {
            type: [Number, String],
            default: 13
        }
    }
};
</script>
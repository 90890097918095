<template>
    <div class="score-text-wrapper" :style="`--size: ${size}; color: ${color};`">
        <div class="main-score-view single" :class="{ hide: mainScore != 10 }" style="overflow: hidden !important">
            <div class="main-score-wrapper">
                <span class="score" :class="{ 'hide-on-print': mainScore != 10 }">1</span>
            </div>
        </div>
        <div class="main-score-view" :style="`--translate-y: -${(10 - mainScore) / 11 * 100}%; overflow: hidden !important;`">
            <div class="main-score-wrapper">
                <span class="score" :class="{ 'hide-on-print': mainScore != 10 }">0</span>
                <span v-for="i in 10" class="score" :class="{ 'hide-on-print': 10-i != mainScore }">{{ 10-i }}</span>
            </div>
        </div>
        <span class="dot">.</span>
        <div class="decimal-score-view" :style="`--translate-y: -${(10 - decimalScore) / 11 * 100}%; overflow: hidden !important;`">
            <div class="decimal-score-wrapper">
                <span class="score" :class="{ 'hide-on-print': decimalScore != 10 }">0</span>
                <span v-for="i in 10" class="score" :class="{ 'hide-on-print': 10-i != decimalScore }">{{ 10-i }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-score-text',
    props: {
        // font size
        size: {
            type: String|Number,
            default: 16
        },
        // score to render
        score: {
            type: String|Number,
            required: true
        },
        color: {
            type: String,
            default: '#212529'
        }
    },
    data() {
        return {
        }
    },
    computed: {
        mainScore: function() {
            if(!this.score) return 0

            const scoreFloat = parseFloat(this.score);
            const roundedScore = scoreFloat.toFixed(1);
            const score = roundedScore;

            const dotIndex = score.indexOf('.');
            if(dotIndex !== -1) return score.substring(0, dotIndex);
            return score
        },
        decimalScore: function() {
            if(!this.score) return 0

            const scoreFloat = parseFloat(this.score);
            const roundedScore = scoreFloat.toFixed(1);
            const score = roundedScore;

            const dotIndex = score.indexOf('.');
            if(dotIndex !== -1) return score.substring(dotIndex+1, score.length);
            return '0'
        }
    }
}
</script>

<style lang="scss" scoped>

.score-text-wrapper {
    --font-size-big: calc(var(--size) * 1px);
    --font-size-small: calc(var(--size) * 0.95px);

    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 600;
    letter-spacing: calc((var(--font-size-big) - 16px) / 16 * -1.5);

    > * {
        margin-left: -.5px;
    }

    .main-score-view,
    .decimal-score-view {
        overflow: hidden !important;

        > div {
            display: flex;
            flex-direction: column;
            transform: translateY(var(--translate-y, 0));
            transition: transform .6s cubic-bezier(.9,.1,.1,.9);
        }
    }

    .main-score-view {
        min-height: calc(var(--font-size-big));
        max-height: calc(var(--font-size-big));
        max-width: calc(var(--font-size-big) * .81);
        transition: max-width .3s ease-in-out .2s;
        overflow: hidden !important;

        &.single.hide {
            max-width: 0;
            transition: max-width .3s ease-in-out .1s;

            > div {
                transform: translateY(100%);
            }
        }
        
        .main-score-wrapper {
            span {
                font-size: var(--font-size-big);
                line-height: calc(var(--font-size-big)) !important;
                text-align: center;
            }
        }
    }
    .dot {
        font-size: var(--font-size-big);
        line-height: calc(var(--font-size-big)) !important;
    }

    .decimal-score-view {
        min-height: calc(var(--font-size-big));
        max-height: calc(var(--font-size-big));
        max-width: calc(var(--font-size-big) * .81);
        overflow: hidden !important;
        
        .decimal-score-wrapper {
            transition-delay: 50ms;

            span {
                font-size: var(--font-size-big);
                line-height: calc(var(--font-size-big)) !important;
                text-align: center;
                scale: 0.95;
                transform-origin: center 85%;
            }
        }
    }
}

@media print {
    .main-score-wrapper,
    .decimal-score-wrapper {
        transition: none !important;
        transform: none !important;
    }
    .hide-on-print {
        display: none !important;
    }
}

</style>
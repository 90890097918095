<template>
    <div :id="'question'+number" class="question" :style="'width:' + width" v-if="validate">
        <div class="left" v-if="number">
            <div class="number">
                <span class="label">Vraag {{ number }}</span>
                <div v-if="previousAgreements.length > 0" class="previousAgreements">
                    <q-icon @click="openAgreements" class="shaking-hands" type="ShakingHands" :width="30" :height="30" color="black" />
                    <q-arrowbox v-if="showAgreements" class="arrowbox" :width="360" :height="310" title="Afspraken" id="previousAgreements">
                        <q-timeline :items="previousAgreements">
                            <template v-slot:row="{ item }">
                                <div class="timeline-top">
                                    <div>
                                        <q-avatar class="avatar" :img="item.userAvatar" size="small">{{getInitials(item.userName)}}</q-avatar>
                                    </div>
                                    <p class="name">
                                        {{item.userName}}
                                        <span v-if="item.timestamp"> {{formatDate(item.timestamp)}}</span>
                                    </p>
                                </div>
                                <div class="timeline-content">
                                    <p>{{item.message}}</p>
                                </div>
                            </template>
                        </q-timeline>
                    </q-arrowbox>
                </div>
            </div>
        </div>
        <div class="right" :style="'width: calc( ' + width + ' - 93px )'">
            <div v-if="name" class="head">
                <div class="title">
                    <h3 v-html="name"></h3>
                </div>
            </div>
            <div class="body">
                <!-- Questiontype-specific component is responsible for filling the answer field -->
                <component
                    :is="type + '-question'"
                    :options="options"
                    :value="answer"
                    @input="returnAnswer"
                    :id="id"
                    :disabled="disabled"
                    :previousOption="previousOption"
                />
            </div>
            <div class="other" v-if="showDefaultMetaInput">
                <!-- Optional fields -->
                <div class="fields right">
                    <div class="field" v-if="settings.satisfied">
                        <q-tag size="small" emoji="happy" style="margin-left:-30px;margin-right:4px"></q-tag>
                        <span>Wat ging er goed?</span>
                        <q-tag class="required-tag" :class="(satisfied && satisfied.length !== 0) || answer === 0 ? 'hide' : 'show'" size="small">verplicht</q-tag>
                        <q-text 
                            v-model="satisfied" 
                            rows="3" 
                            cols="65" 
                            :maxlength="settingsMaxChars"
                            @input="handleSatisfiedInput" 
                            @blur="returnSatisfied"
                            data-test-id="textarea"
                            :disabled="disabled"
                            placeholder="Waar werd je blij van?"
                            style="z-index:10"
                        ></q-text>
                    </div>

                    <div class="field" v-if="settings.improvement">
                        <q-tag size="small" emoji="slightly-happy" style="margin-left:-30px;margin-right:4px"></q-tag>
                        <span>Wat kan er beter?</span>
                        <q-tag class="required-tag" :class="(improvement && improvement.length !== 0) || answer === 0 ? 'hide' : 'show'" size="small">verplicht</q-tag>
                        <q-text 
                            v-model="improvement" 
                            rows="3" 
                            cols="65" 
                            :maxlength="settingsMaxChars"
                            @input="handleImprovementInput" 
                            @blur="returnImprovement"
                            data-test-id="textarea"
                            :disabled="disabled"
                            placeholder="Beschrijf eventuele verbeteringen"
                            style="z-index:10"
                        ></q-text>
                    </div>
                </div>
            </div>
            <div class="other" v-else-if="showCrowMetaInput">
                <div class="field" v-if="settings.satisfied">
                    <p class="comment-label">Opmerking:</p>
                    <q-text 
                        v-model="satisfied" 
                        rows="3" 
                        cols="65" 
                        :maxlength="settingsMaxChars"
                        @input="handleSatisfiedInput" 
                        data-test-id="textarea"
                        :disabled="disabled"
                        placeholder=""
                        style="z-index:10"
                    ></q-text>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Registering a new questiontype component:
 *
 * - Import module
 * - add to const 'types'
 * - add to components with extension '-question'
 */

import QRadio from './questions/RadioQuestion';
import QTextQuestion from './questions/TextQuestion';
import QText from '../components/QText';

import _ from 'lodash';
import { getDate } from '../../../assets/js/utils';

const types = ['radio', 'text'];

export default {
    name: 'q-question',
    props: {
        /**
         * Width of the component eg. '500px' or '80%'
         */
        width: {
            type: String,
            default: '664px'
        },
        /**
         * number shown in count label on the left side
         */
        number: {
            type: [String, Number]
        },
        /**
         * Name listed at the top of the question
         */
        name: {
            type: String
        },
        /**
         * question type. Needs to exist in 'types' constant
         */
        type: {
            type: String,
            required: true
        },
        /**
         * Options which are neccesary for certain questiontypes e.g. choises for radio or checkbox
         */
        options: {
            type: Array
        },
        /**
         * v-model
         */
        value: {
            type: [Array, Object, String, Number]
        },
        /**
         * Settings about the question. Structure:
         *  - satisfied (boolean: should show satisfied textfield)
         *  - improvement (boolean: should show improvement textfield)
         */
        settings: {
            type: Object,
            default: () => {
                return {
                    satisfied: false,
                    improvement: false
                };
            }
        },
        /**
         * Amount of characters allowed in settings' textfields
         */
        settingsMaxChars: {
            type: String,
            default: '300'
        },
        /**
         * Unique id, of the question. Some questiontypes require it
         */
        id: {
            type: [String, Number],
            required: true
        },
        /**
         * Wether the question can be answered or not
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * List of previously made agreements for the question
         */
        previousAgreements: {
            type: Array,
            default: () => []
        },
        /**
         * List of previously given values to this component
         */
        previousValues: {
            type: Array,
            default: () => []
        },
        /**
         * Wether the question should use
         */
        useCrowComments: {
            type: Boolean,
            default: () => false
        }
    },
    components: {
        'radio-question': QRadio,
        'text-question': QTextQuestion,
        'q-text': QText
    },
    data() {
        return {
            answer: null,
            satisfied: null,
            improvement: null,
            scrollEventListener: true,
            inputBlurred: false,
            lastSavedValue: '',
            showAgreements: false,
            inputBlurred: false,
            improvementBlurEmitted: true,
            satisfiedBlurEmitted: true
        };
    },
    methods: {
        /**
         * It is the responsibility of the childcomponents (questiontype specific) to return the answer (this.$parent.returnAnswer())
         */
        returnAnswer(label) {
            this.answer = label;
            this.$emit('answerInput', this.answer);
            this.$emit('input', { answer: this.answer });
        },
        handleSatisfiedInput() {
            this.satisfiedBlurEmitted = false;
            this._returnSatisfied();
        },
        returnSatisfied() {
            if(this.satisfiedBlurEmitted) return

            this.satisfiedBlurEmitted = true;
            this.$emit('satisfiedInput', this.satisfied);
            this.$emit('input', { satisfied: this.satisfied });
        },
        _returnSatisfied: _.debounce(function() {
            if(this.satisfiedBlurEmitted) return
            this.$emit('satisfiedInput', this.satisfied);
            this.$emit('input', { satisfied: this.satisfied });
            this.satisfiedBlurEmitted = true;
        }, 1500),
        handleImprovementInput() {
            this.improvementBlurEmitted = false;
            this._returnImprovement();
        },
        returnImprovement() {
            if(this.improvementBlurEmitted) return

            this.improvementBlurEmitted = true;
            this.$emit('improvementInput', this.improvement);
            this.$emit('input', { improvement: this.improvement });
        },
        _returnImprovement: _.debounce(function() {
            if(this.improvementBlurEmitted) return
            this.$emit('improvementInput', this.improvement);
            this.$emit('input', { improvement: this.improvement });
            this.satisfiedBlurEmitted = true;
        }, 1500),
        validate() {
            let errors = [];

            if (!types.includes(this.type))
                errors.push(
                    "questiontype not found. Did you register it propperly? Did you forget to add it to 'types'?"
                );

            errors.forEach(err => console.error(err));

            return errors.length == 0;
        },
        openAgreements() {
            this.$store.commit('resetBodyListeners');

            this.showAgreements = !this.showAgreements;
            
            if (this.showAgreements)
                this.$store.commit('addBodyListener', ['previousAgreements', () => {
                    this.showAgreements = false;
                }])
        },
        getInitials(name) {
            return name.split(' ').map(subText => subText? subText[0] : '').join('');
        },
        formatDate(timestamp) {
            return `op ${getDate(timestamp)}`
        }
    },
    computed: {
        showComment: function() {
            return this.settings && this.settings.comment;
        },
        showAgreement: function() {
            return this.settings && this.settings.agreement;
        },
        showDefaultMetaInput: function() {
            return this.settings && (this.settings.satisfied || this.settings.improvement) && !this.useCrowComments
        },
        showCrowMetaInput: function() {
            return this.settings && this.settings.satisfied && this.useCrowComments
        },
        previousOption: function() {
            if (!this.options) return null;
            if (!this.previousValues) return null;
            if (this.previousValues.every(value => !value.timestamp)) return null;
    
            let highest = 0;
            let value = null;
            this.previousValues.forEach(value_ => {
                if (value_.timestamp > highest) {
                    highest = value_.timestamp;
                    value = value_;
                }
            })

            return value;
        }
    },
    created() {
        this.validate();

        this.answer = this.value.answer;
        this.satisfied = this.value.satisfied;
        this.improvement = this.value.improvement;
    },
    watch: {
        value: function() {
            this.answer = this.value.answer;
            this.satisfied = this.value.satisfied;
            this.improvement = this.value.improvement;
        }
    }
};
</script>

<style scoped lang="scss">
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

$total: 664px;
$left: 93px;
$right: calc($total - $left);

* {
    color: $color-grey-9;
    font-family: Gotham;
    font-style: normal;
}

.left {
    width: $left;
    flex: 0 0 $left;
}

.question {
    display: flex;
    flex-direction: row;
}

.number {
    span.label {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        background: #f2f2f2;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        color: $color-grey-7;
        padding: 4px;
    }
}

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    .title > h3 {
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        color: $color-grey-9;
    }
}

.body {
    display: flex;
}

.other {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;

    width: 100%;

    .fields {
        width: 100%;

        .field {
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            width: 100%;
            color: $color-grey-7;

            .required-tag {
                float: right;
                transition: .4s ease;
                margin-right: 1px;

                &.hide {
                    transform: translateY(28px);
                }
            }
        }

        & div:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.comment-label {
    margin: 0 0 10px 0;
}

.previousAgreements {
    position: relative;
    margin: 10px 0 0 8px;
    z-index: 20;
}

.arrowbox {
    position: absolute;
    top: 14px;
    left: -50px;
}

.timeline-top {
    display: flex;


    .avatar {
        margin: -5px 20px 0 0
    }

    .name {
        
    }
}

.timeline-content {
    width: 100%;
    margin: 5px 0 0 4px;
    word-wrap: break-word;
}

.shaking-hands {
    cursor: pointer;
}

</style>

<template>
    <div class="center">
        <p v-if="accessing">Proberen toegang te verkrijgen...</p>
        <p>{{ errorMessage }}</p>
        <p class="logout" @click="logout">Log uit</p>
    </div>
</template>

<script>
import { ACCESS_USER_ACCOUNT } from '@/graphql/queries.js';
import { handleLoginSuccess } from '@/assets/js/utils.js';

export default {
    name: 'AccessWithRequest',
    data() {
        return {
            errorMessage: '',
            user: this.$store.getters.getUser,
            accessing: true
        }
    },
    methods: {
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        }
    },
    created() {
        const userId = this.$route.params.id;
        const localStorageJwt = localStorage.getItem('jwt')

        const accessingAccountId = localStorage.getItem('accessingAccountId') || userId
        if(!accessingAccountId) localStorage.setItem('accessingAccountId', userId)

        if(!userId) return
        if(!localStorageJwt) return
        
        if(this.user.id === accessingAccountId) return this.$router.push('/access')

        this.$apollo.query({
            query: ACCESS_USER_ACCOUNT,
            variables: {
                id: userId
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            const authPayload = result.data.user_accessAccount;
            this.$store.commit('setJwt', authPayload.jwt);
            this.$store.commit('setRefreshToken', authPayload.refreshToken);
            this.$store.commit('setUser', authPayload.user);
            this.$store.commit('resetNotifs');
            this.$router.push('/access');
            this.accessing = false;
        })
        .catch(error => {
            this.accessing = false;
            this.errorMessage = `Er ging iets fout, waarschijnlijk heeft het account van ${this.user.firstName} ${this.user.lastName} hier geen rechten voor.`
        })
    }

}
</script>

<style lang="scss" scoped>

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    gap: 20px;
}

.logout {
    color: lightseagreen;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

</style>
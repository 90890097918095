<template>
    <q-step :number="index" :id="index" :name="step.name" :about="step.about" :value="step.value" showStepImage class="step" width="90%">
        <q-input labelPosition="inside" class="report-name-input" :placeholder="step.placeholder" v-model="report.name" @input="handleInput">Rapportage naam</q-input>
    </q-step>
</template>

<script>
import _ from 'lodash';

import { REPORT_UPDATE_NAME } from '@/graphql/mutations';

export default {
    name: 'report-step-name',
    components: {
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        report: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            debouncingInput: false
        }
    },
    methods: {
        handleInput() {
            this.debouncingInput = true;
            this.emitInput();
            this.$emit('loadingStarted');
        },
        emitInput: _.debounce(function() {
            if(this.debouncingInput == false) return
            this.debouncingInput = false;
            this.$apollo.mutate({
                mutation: REPORT_UPDATE_NAME,
                variables: {
                    id: this.report.id,
                    name: this.report.name
                }
            })
            .then(this.$emit('reportUpdated', this.report))
            .catch(error => {
                console.log(error)
                this.$emit('loadingEnded');
            })
        }, 500)
    }
}
</script>

<style lang="scss" scoped>

.report-name-input {
    margin-top: -24px;
}

</style>
<template>
    <div class="enquete-wrapper page-padding">
        <div class="head">
            <div class="left">
                <div class="title">
                    <div class="back">
                        <arrow-button color="black" direction="left" :href="`/projects/${form.project.id}`"></arrow-button>
                    </div>
                    <h2>{{ form.name }}</h2>
                    <q-tooltip :disabled="!form.statusTooltip" position="top">
                        <template v-slot:tooltip>
                            <p v-html="form.statusTooltip"></p>
                        </template>
                        <q-tag size="medium" :variation="form.statusVariation" icon="warning">
                            {{ $t(`formStatus.${form.status}`) }}
                        </q-tag>
                    </q-tooltip>
                </div>
                <p class="subtitle">{{ form.project.name }}</p>
            </div>
            <div v-if="form.status !== 'finished'" class="right">
                <q-button 
                    variation="ghost" 
                    size="small" 
                    icon="settings"
                    @click="handleNavigateSettings" 
                >Formulier instellingen</q-button>
                <q-button 
                    v-if="form.settings.survey.availability.from < Date.now()" 
                    size="small" 
                    icon="check" 
                    iconSize="18"
                    @click="handleManualFinish" 
                    :loading="loadingFinishButton"
                >Handmatig afronden</q-button>
            </div>
        </div>
        <div class="cards">
            <div class="card">
                <div v-if="getFullName(form.createdBy)" class="line">
                    <p class="left">Aangemaakt door</p>
                    <p class="right">{{ getFullName(form.createdBy) }}</p>
                </div>
                <div class="line">
                    <p class="left">Aangemaakt op</p>
                    <p class="right">{{ getDate(form.creationDate) }}</p>
                </div>
                <div class="line">
                    <p class="left">Ontvangt berichtgeving</p>
                    <p class="right">{{ getAttendeeNames(form) || `Geen collega's uitgekozen` }}</p>
                </div>
                <div class="line">
                    <p class="left">Totaal aantal reacties</p>
                    <p class="right">{{ form.responses.length }}</p>
                </div>
                <div class="line">
                    <p class="left">Notitie</p>
                    <div class="right">
                        <p v-if="editNote === false" class="note">{{ form.description }}</p>
                        <q-text v-if="editNote === true" v-model="form.description"></q-text>
                        <q-button variation="ghost" size="xsmall" @click="handleDescriptionUpdated">{{ !form.description && !editNote ? 'Invullen' : !editNote ? 'Aanpassen' : 'Opslaan' }}</q-button>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="line">
                    <p class="left">Link naar formulier</p>
                </div>
                <div class="line">
                    <div class="share-link">
                        <span>{{ shareLink }}</span>
                        <div class="eye-icon" @click="openNewTab(shareLink)">
                            <q-icon type="eye" width="22" height="12"></q-icon>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <p>Formulier is beschikbaar van <span class="date">{{ getDate(form.settings.survey.availability.from) }}</span> t/m <span class="date">{{ getDate(form.settings.survey.availability.to) }}</span></p>
                </div>
                <div class="line">
                    <p>Formulier is verstuurd op <span class="date">{{ getDate(form.settings.survey.sendDate) }}</span></p>
                </div>
            </div>
        </div>
        <div class="options-row">
            <div class="left">
                <q-label-switch class="label-switch" v-model="resultView" :options="resultOptions" @input="handleViewChange"></q-label-switch>
                <jump-transition>
                    <q-dropdown v-if="resultView === 'individual'" v-model="selectedResponseId" :options="responseUserOptions" size="small" placeholder="Kies een response" autocomplete></q-dropdown>
                </jump-transition>
            </div>
            <div class="right">
                <q-button @click="downloadPdf" icon="download" variation="ghost" size="small" :loading="downloadLoading">Download PDF</q-button>
            </div>
        </div>
        <div class="card enquete-results">
            <div class="head">
                <div class="left">
                    <div class="title">
                        <h2>{{ form.name }}</h2>
                    </div>
                    <p class="subtitle">{{ form.project.name }}</p>
                    <p class="subtitle">Aantal respondenten: <span class="highlight">{{ form.responses.length }}</span></p>
                    <p class="subtitle">Beschikbaar van <span class="highlight">{{ getDate(form.settings.survey.availability.from) }}</span> t/m <span class="highlight">{{ getDate(form.settings.survey.availability.to) }}</span></p>
                </div>
                <div v-if="form.scoreData && currentScoreData" class="right">
                    <q-tooltip v-if="currentScoreData.average" position="top">
                        <template v-slot:tooltip>
                            <div v-for="item in currentScoreData.tooltipItems" v-html="item" style="display: flex; gap: 12px; justify-content: space-between; white-space: nowrap; overflow: hidden; text-overflow:ellipsis;"></div>
                        </template>
                        <q-circle-diagram class="score-diagram" :size="70" :width="6" :score="currentScoreData.average"></q-circle-diagram>
                    </q-tooltip>
                </div>
            </div>
            <div class="results-wrapper">
                <div v-if="userResponse" class="response-information">
                    <div v-if="userResponse.emetMeta.userName" class="line">
                        <p class="left">Naam</p>
                        <p class="right">{{ userResponse.emetMeta.userName }}</p>
                    </div>
                    <div v-if="userResponse.emetMeta.email" class="line">
                        <p class="left">Email</p>
                        <p class="right">{{ userResponse.emetMeta.email }}</p>
                    </div>
                    <div v-if="userResponse.emetMeta.function" class="line">
                        <p class="left">Functie</p>
                        <p class="right">{{ userResponse.emetMeta.function }}</p>
                    </div>
                    <div v-if="userResponse.timestamp" class="line">
                        <p class="left">Ingevuld op</p>
                        <p class="right">{{ userResponse.timestamp }}</p>
                    </div>
                </div>

                <div 
                    v-for="category in filteredQuestionCategories"
                    :key="category.id"
                    class="question-category">
                    <div v-if="category.name" class="header-container">
                        <q-tooltip 
                            class="category-header"
                            :disabled="category.average === undefined" 
                            position="top"
                        >
                            <template v-slot:tooltip>
                                <div v-for="item in category.tooltipItems" v-html="item" style="display: flex; gap: 12px; justify-content: space-between; white-space: nowrap; overflow: hidden; text-overflow:ellipsis;"></div>
                            </template>
                            <div class="title-container">
                                <span class="title">{{ category.name }}</span>
                            </div>
                            <div v-if="category.average !== undefined" class="score">
                                <q-score-text size="22" :score="category.average"></q-score-text>
                            </div>
                        </q-tooltip>
                    </div>
                    <div class="questions-container" :class="[{ active: category.active && category.name }]">
                        <enquete-answer 
                            v-for="question in category.questions" 
                            :key="question.componentId"
                            :indicator="question"
                            :index="question.index"
                            :selectedResponseId="selectedResponseId"
                        ></enquete-answer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue';
import EnqueteAnswer from './EnqueteAnswer.vue';
import { getDate, extractError, getSurveyExportName, isValidAnswer } from '@/assets/js/utils';
import { getSurveyHtml } from '@/assets/js/downloadPdf.js';

import _ from 'lodash';
import Axios from 'axios';
import download from 'downloadjs';

export default {
    name: 'enquete-insight',
    components: {
        ArrowButton,
        EnqueteAnswer
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        loadingFinishButton: {
            type: Boolean,
            default: false
        },
        questionCategories: {
            type: Array,
        },
    },
    data() {
        return {
            questions: [],
            editNote: false,
            shareLink: '',
            responseUserOptions: [],
            selectedResponseId: '',
            resultOptions: [
                {
                    label: 'Totaal resultaat',
                    value: 'total',
                },
                {
                    label: 'Individuele reacties',
                    value: 'individual',
                }
            ],
            resultView: 'total',
            downloadUrl: process.env.DOWNLOAD_URL || 'http://localhost:5050/download',
            downloadLoading: false,
            filteredQuestionCategories: []
        }
    },
    methods: {
        setData() {
            const responses = this.form.responses.map((response, index) => {
                let firstName = '';
                let lastName = '';
                const name = response.emetMeta?.userName;
                const email = response.emetMeta?.email;
                const userFunction = response.emetMeta?.function;
                if(response.timestamp) response.timestamp = new Date(response.timestamp).toLocaleTimeString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' });

                if(name) {
                    const firstSpace = name.indexOf(' ');
                    const first = name.substring(0, firstSpace);
                    const last = name.substring(firstSpace+1, name.length);

                    firstName = first;
                    lastName = last;
                } else {
                    firstName = `${index+1}e`;
                    lastName = 'Respondent';
                }

                response.user = {
                    firstName,
                    lastName,
                    email,
                    function: userFunction,
                    avatar: null
                }

                return response
            });
            this.form.responses = responses;

            this.responseUserOptions = responses.map(response => {
                return {
                    label: `${response.user.firstName} ${response.user.lastName}`,
                    value: response.id
                }
            });

            const href = window.location.href;
            const re = new RegExp("^http.+\/\/[^\/]+");
            const environment = re.exec(href);
            this.shareLink = environment + '/fill/' + this.form.settings.shareToken;
        },
        setQuestions() {
            const questionCategories = _.cloneDeep(this.questionCategories)
                .map(category => {
                    let activeQuestions = category.questions.filter(question => question.enabled);
                    let categoryQuestions = activeQuestions.map(question => this.parseQuestion(question));

                    let scoreData = this.form.scoreData;
                    if(this.selectedResponseId) scoreData = this.userResponse.scoreData;
                    if(!scoreData) scoreData = {
                        indicatorScores: [],
                        labelScores: [],
                        average: null
                    }

                    const score = scoreData?.labelScores.find(score => score.labelId === category.id);
                    if(score) {
                        category.average = score.average;

                        categoryQuestions = categoryQuestions.map(question => {
                            const indicatorScore = scoreData.indicatorScores.find(indicatorScore => indicatorScore.indicatorId === question.id);
                            if(indicatorScore) {
                                question.average = indicatorScore.average;
                            }
                            return question
                        })

                        const tooltipItems = [];
                        categoryQuestions.forEach(question => {
                            const indicatorScore = scoreData.indicatorScores.find(indicatorScore => question.id === indicatorScore.indicatorId);
                            if(!indicatorScore) return
                            tooltipItems.push(`<span>Vraag ${question.index}</span><b>${indicatorScore.average.toFixed(1)}</b>`);
                        });
                        category.tooltipItems = tooltipItems;
                    }

                    category.questions = categoryQuestions;
                    
                    return category
                })
                .filter(category => category.id !== 'default' && category.activeQuestions > 0)
            this.filteredQuestionCategories = questionCategories;
        },
        valueMatch(value1, value2) {
            const int1 = parseInt(value1, 10);
            const int2 = parseInt(value2, 10);
            if (int1 > 0 && int1 === int2) return true;
            return value1 === value2;
        },
        parseQuestion(question) {
            let responses = this.form.responses || [];
            if(this.selectedResponseId) responses = responses.filter(response => response.id === this.selectedResponseId);
            
            const componentResponses = [];
            const nvtUsers = [];

            responses.forEach(response => {
                const user = response.user;

                response.values.forEach(value => {
                    if(value.componentId === question.componentId && isValidAnswer(value.value)) {
                        if([-1, 'n.v.t.'].includes(value.value)) nvtUsers.push(user);

                        componentResponses.push({
                            user,
                            scoreData: response.scoreData,
                            ...value
                        });
                    }
                })
            });

            if(!question.isDefaultQuestion) question.answerValues.push({
                label: 'Niet van toepassing',
                value: -1
            });

            if(!question.isDefaultQuestion) question.answerValues = question.answerValues.map(answerValue => {
                let percentage = 0;
                let answerValueResponses = [];
                let totalResponsesOnQuestion = responses.filter(response => response.values.some(value => value.componentId === question.componentId)).length;
                if(question.answerType === 'checkbox') answerValueResponses = componentResponses.filter(response => response.value !== -1 && response.value.includes(answerValue.value));
                else answerValueResponses = componentResponses.filter(response => this.valueMatch(response.value, answerValue.value));
                if(answerValueResponses.length !== 0) {
                    percentage = Math.round(answerValueResponses.length/totalResponsesOnQuestion * 100);
                }

                answerValue.percentage = percentage;
                answerValue.responses = answerValueResponses;
                
                return answerValue
            })

            return {
                ...question,
                responses: componentResponses,
                relevantResponses: componentResponses.filter(response => ![-1, 'n.v.t.'].includes(response.value)),
                nvtUsers
            }
        },
        getEmptyValue(answerType) {
            if(answerType === 'checkbox') return []
            else if(answerType === 'text') return''
            else if(['radio','number'].includes(answerType)) return null
        },
        handleNavigateSettings() {
            this.$emit('configuration');
        },
        handleManualFinish() {
            this.$emit('manualFinish');
        },
        getDate(unix) {
            if(!unix) return 'Geen data'
            return getDate(unix)
        },
        getFullName(user) {
            if(!user.firstName && !user.lastName) return null
            return `${user.firstName} ${user.lastName}`
        },
        getAttendeeNames(form) {
            const users = form?.settings?.attendees;
            if (!Array.isArray(users)) return null;
            const names = users.map(user => this.getFullName(user));
            return names.join(', ')
        },
        openNewTab(link) {
            window.open(link,'_blank');
        },
        handleViewChange() {
            if(this.resultView === 'individual' && this.responseUserOptions.length > 0) this.selectedResponseId = this.responseUserOptions[0].value;
            else this.selectedResponseId = ''
        },
        handleDescriptionUpdated() {
            this.editNote = !this.editNote;
            this.$emit('formUpdated', this.form);
        },
        async downloadPdf() {
            this.downloadLoading = true;

            const html = await getSurveyHtml();

            const headers = {
                responseType: 'blob',
                headers: {
                    'application-context': 'qfact',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                }
            };

            Axios
                .post(`${this.downloadUrl}/export/html`, { html }, headers)
                .then((response) => {
                    const content = response.headers['content-type'];
                    const exportName = getSurveyExportName(this.form);
                    download(response.data, exportName, content);
                    this.downloadLoading = false;
                })
                .catch((error) => {
                    this.$store.commit('notify', extractError(error));
                    this.downloadLoading = false;
                });
        }
    },
    computed: {
        userResponse: function() {
            let responses = this.form.responses || [];
            let response = null;
            if(this.selectedResponseId) response = responses.find(response => response.id === this.selectedResponseId);
            return response
        },
        currentScoreData: function() {
            let scoreData = null;
            if(!this.selectedResponseId) scoreData = this.form.scoreData
            else scoreData = this.userResponse.scoreData;

            if(!scoreData) return null

            let tooltipItems = [];

            tooltipItems.push(`<b>Prestatie</b><b>Score</b>`);
            tooltipItems.push(`<div style="width:100%;height:4px;"></div>`);

            this.questionCategories.forEach(category => {
                const labelScore = scoreData.labelScores.find(labelScore => category.id === labelScore.labelId);
                if(!labelScore) return
                tooltipItems.push(`<span>${category.name}</span><b>${Math.round(labelScore.average * 10) / 10}</b>`);
            })
            tooltipItems.push(`<div style="width:100%;height:4px;"></div>`);
            scoreData.tooltipItems = tooltipItems;

            return scoreData
        }
    },
    created() {
        this.setData();
        this.setQuestions();
    },
    watch: {
        selectedResponseId: function() {
            this.setQuestions();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.enquete-wrapper {
    --back-icon-width: 24px;
    padding-left: calc(50px + var(--back-icon-width));
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .back {
            margin-left: calc(-1 * var(--back-icon-width));
        }

        .title {
            display: flex;
            align-items: center;

            h2 {
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
            }

            .tag {
                margin-left: 8px;

                .icon {
                    scale: 0.95;
                    translate: 0 3px;
                }
            }
        }
        .subtitle {
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;

            .highlight {
                color: $color-blue-darker;
            }
        }
    }

    .right {
        display: flex;
        gap: 10px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #DEE2E6;
    box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.15);
}

.cards {
    display: flex;
    gap: 20px;
    margin-top: 50px;

    .card {
        gap: 12px;
        padding: 24px;
    }
}

.options-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 20px;

    .left {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-grow: 1;

        .label-switch {
            width: 400px;
        }
        .q-dropdown {
            flex-grow: 1;
            max-width: 400px;
        }
    }

}

.enquete-results {
    padding: 50px 40px;
    width: calc(100% - 80px);

    .head {
        align-items: center;

        .left {
            gap: 2px;

            .title {
                margin-bottom: 4px;
            }

            .subtitle {
                font-size: 14px;
            }
        }
    }

    .results-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-block: 50px;

        .response-information {
            display: flex;
            flex-direction: column;
            gap: 6px;
            max-width: 400px;

            &:empty {
                display: none;
            }
        }

        .question-category {

            .header-container {
                position: relative;
                display: flex;
                justify-content: center;
                padding-block: 20px 60px;

                .category-header {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 10px 28px;
                    background: $color-grey-1;
                    border-radius: 16px;
                    cursor: default;
                    
                    .title-container {
                        text-align: center;

                        .title {
                            font-size: 24px;
                            line-height: 28px;
                            color: $color-grey-9;
                            font-weight: 500;
    
                            background-image: linear-gradient($color-primary, $color-primary);
                            background-position: left bottom;
                            background-size: 100% 1px;
                            background-repeat: no-repeat;
                        }
                    }
    
    
                    .score {
                        margin-left: 16px;

                        span {

                        }
                    }
    
                }
            }
            

            .questions-container {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }
        }
    }
}

.share-link {
    display: flex;
    align-items: center;
    gap: 40px;
    border: 1px solid #ADB5BD;
    color: #757575;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    width: 100%;

    span {
        flex-grow: 1;
        margin-left: 8px;
        margin-block: 12px;
    }

    .eye-icon {
        display: grid;
        place-items: center;
        width: 39px;
        height: 39px;
        border-radius: 3px;
        background-color: $color-primary;
        cursor: pointer;

        .icon {
            color: white;
        }
    }
}

.line {
    display: flex;

    .left {
        width: 40%;
        font-weight: 500;
    }
    .right {
        width: 60%;
        white-space: pre-line;

        .note {
            margin-bottom: 4px;
        }
    }

    .date {
        font-weight: 500;
        color: #212529;
    }
}

</style>
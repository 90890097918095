<template>
    <div class="page-wrapper">
        <div v-if="form" class="page">
            <enquete-configuration 
                v-if="form.status === 'concept' || changeSettings"
                :form="form"
                :questionCategories="questionCategories"
                :onlyShowSettings="changeSettings"
                :loading="loading"
                @insight="changeSettings = false"
                @componentsUpdated="handleComponentsUpdated"
                @settingsUpdated="handleSettingsUpdated"
                @markSurveySent="handleSendSurvey">
            </enquete-configuration>
            <enquete-insight 
                v-else
                :form="form"
                :loadingFinishButton="loadingFinishButton"
                :questionCategories="questionCategories"
                @configuration="changeSettings = true"
                @formUpdated="handleFormUpdated"
                @manualFinish="handleManualFinish">
            </enquete-insight>
        </div>
        <div v-else class="center">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import EnqueteConfiguration from './EnqueteConfiguration.vue';
import EnqueteInsight from './EnqueteInsight.vue';
import { GET_FORM_SURVEY } from '@/graphql/queries.js';
import { UPDATE_FORM, MARK_FORM_SENT, FINISH_FORM } from '@/graphql/mutations.js';
import { getFullName, getDate } from '@/assets/js/utils';

import { v4 as uuidv4 } from 'uuid';

export default {
    components: {
        EnqueteConfiguration,
        EnqueteInsight
    },
    data() {
        return {
            form: null,
            changeSettings: false,
            loading: false,
            loadingFinishButton: false
        }
    },
    methods: {
        async getSurvey() {
            this.$apollo.query({
                query: GET_FORM_SURVEY,
                variables: {
                    id: this.$route.params.id
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.form = result.data.form;
                if(!this.form.settings) this.form.settings = {
                    shareToken: '2394o-8235',
                    note: '',
                    attendees: [],
                    survey: {
                        sendDate: null,
                        availability: {
                            from: null,
                            to: null
                        },
                        completionMessageEnabled: false,
                        completionMessage: '',
                        startMessageEnabled: false,
                        startMessage: '',
                        enableNameQuestion: true,
                        enableEmailQuestion: true,
                        enableFunctionQuestion: true,
                    }
                }

                const defaultNameComponent = {
                    id: 'default-name-question',
                    indicator: {
                        name: 'Wat is je naam?',
                        answerType: 'text',
                        displayType: 'text',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableNameQuestion
                }
    
                const defaultEmailComponent = {
                    id: 'default-email-question',
                    indicator: {
                        name: 'Wat is je e-mailadres?',
                        answerType: 'text',
                        displayType: 'text',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableEmailQuestion
                }
    
                const defaultFunctionComponent = {
                    id: 'default-function-question',
                    indicator: {
                        name: 'Wat is je functie?',
                        answerType: 'text',
                        displayType: 'text',
                        required: true,
                        isDefaultQuestion: true
                    },
                    enabled: this.form.settings.survey.enableFunctionQuestion
                }

                const repairedComponents = this.form.components.map((component, index) => {
                    const id = uuidv4();
                    component.indicator.answerValues = component.indicator.activeAnswerValues;

                    return {
                        ...component,
                        id: component.id ?? id,
                        description: component.description ?? ''
                    }
                });

                if (this.form.settings.survey.availability.to !== null) this.form.settings.survey.errorExpiredFormWithoutRespondents = false;
    
                this.form.components = [defaultNameComponent, defaultEmailComponent, defaultFunctionComponent, ...repairedComponents];
    
                this.setFormStatus();
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van deze enquete' })
                console.log(error)
            })
        },
        setFormStatus() {
            switch(this.form.status) {
                case'sent': 
                    this.form.statusVariation = 'warning';
                    break;
                case'finished': 
                    this.form.statusVariation = 'success';
                    break;
                case'concept': 
                default:
                    this.form.statusVariation = 'default';
                    break;
            }

            if(this.form.status === 'finished') {
                const finishedDate = getDate(this.form.finishedDate);
                const finishText = finishedDate ? ` op ${getDate(this.form.finishedDate)}` : '';
                if(this.form.finishedBy && this.form.finishedBy.id) this.form.statusTooltip = `${getFullName(this.form.finishedBy.firstName, this.form.finishedBy.lastName)} heeft${finishText} dit formulier afgerond`;
                else this.form.statusTooltip = `Dit formulier is automatisch afgerond${finishText}`;
            }
        },
        handleComponentsUpdated(form) {
            this.loading = true;

            const defaultComponentIds = ['default-name-question','default-email-question','default-function-question'];
            const filteredComponents = form.components.filter(component => !defaultComponentIds.includes(component.id))
            const mappedComponents = filteredComponents.map(component => {
                return {
                    id: component.id,
                    indicatorId: component.indicator.id,
                    enabled: component.enabled,
                    type: component.type,
                    required: component.indicator.required,
                    mandatory: component.indicator.mandatory,
                    description: component.description,
                    items: component.items,
                    styling: component.styling
                }
            })

            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: form.id,
                    components: mappedComponents
                }
            })
            .then(result => {
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan' })
                console.log(error)
            })
        },
        handleSettingsUpdated(form) {
            this.loading = true;

            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: form.id,
                    settings: form.settings
                }
            })
            .then(result => {
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan' })
                console.log(error)
            })
        },
        handleFormUpdated(form) {
            this.loading = true;

            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: form.id,
                    description: form.description
                }
            })
            .then(result => {
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan' })
                console.log(error)
            })
        },
        handleSendSurvey() {
            this.loading = true;

            this.$apollo.mutate({
                mutation: MARK_FORM_SENT,
                variables: {
                    formId: this.form.id
                }
            })
            .then(result => {
                this.loading = false;
                this.form.status = 'sent';
                this.form.settings.survey.sendDate = Date.now();
                if(!this.form.settings.survey.availability.from) this.form.settings.survey.availability.from = Date.now();
                this.setFormStatus();
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er is iets fout gegaan, probeer dit later opnieuw' })
            })
        },
        handleManualFinish() {
            this.loadingFinishButton = true;
            this.$apollo.mutate({
                mutation: FINISH_FORM,
                variables: {
                    id: this.form.id
                }
            })
            .then(result => {
                const { status, finishedDate, finishedBy, settings } = result.data.form_finish;
                this.form.status = status;
                this.form.finishedDate = finishedDate;
                this.form.finishedBy = finishedBy;
                this.form.settings = settings;
                this.loadingFinishButton = false;
                this.setFormStatus();
                this.$store.commit('notify', { type: 'success', message: 'Het formulier is succesvol afgerond' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het afronden van dit formulier' })
                this.loadingFinishButton = false;
            })
        },
        getEmptyValue(answerType) {
            if(answerType === 'checkbox') return []
            else if(answerType === 'text') return''
            else if(['radio','number'].includes(answerType)) return null
        },
        parseComponent(component) {
            const isEnabled = component.enabled ?? true;
            const answer = this.getEmptyValue(component.indicator.answerType);

            return {
                componentId: component.id,
                ...component.indicator,
                showScore: component.indicator.showScore ?? true,
                enabled: isEnabled,
                description: component.description,
                answer
            }
        },
        reload() {
            this.form = null;
            this.getSurvey();
        }
    },
    computed: {
        questionCategories: function() {
            if(!this.form) return []

            const components = this.form.components;
            let categories = [{ name: '', id: 'default' }]
            categories = categories.concat(this.form.labels);

            let questionIndex = 0;

            categories = categories.map(category => {
                const categoryComponents = components.filter(question => {
                    if(question.indicator.labelIds?.length > 0) return question.indicator.labelIds.includes(category.id)
                    return category.id === 'default'
                });
                category.questions = categoryComponents.map(component => {
                    component = this.parseComponent(component);

                    if(category.id !== 'default' && component.enabled) questionIndex++;
                    component.index = questionIndex;
                    
                    return component
                })
                category.activeQuestions = category.questions.filter(question => question.enabled).length;
                category.enabled = category.activeQuestions > 0;

                const mandatoryQuestions = category.questions.filter(question => question.mandatory);
                category.hasMandatory = mandatoryQuestions.length > 0;
                category.disabled = mandatoryQuestions.length === category.questions.length;

                return category
            })

            categories = categories.filter(category => category.questions.length > 0)

            if(!this.initialisedCategories) {
                const activeCategories = categories.filter(category => category.activeQuestions > 0)
                this.openedCategoryIds = activeCategories.map(category => category.id);
                this.initialisedCategories = true;
            }

            return categories
        }
    },
    created() {
        this.getSurvey();
    },
    watch: {
        '$route.path': function() {
            this.loading = true;
            this.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.center {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:1.41421;"
  >
    <circle cx="12" cy="12" r="11" :stroke="color" fill="transparent" stroke-width="2"/>
    <path d="M6.75673 15.081C6.38726 15.4915 6.42051 16.1238 6.83101 16.4933C7.24151 16.8627 7.8738 16.8295 8.24327 16.419L6.75673 15.081ZM15.7567 16.4188C16.1262 16.8293 16.7585 16.8626 17.169 16.4931C17.5795 16.1236 17.6127 15.4913 17.2433 15.0808L15.7567 16.4188ZM8.24327 16.419C8.53435 16.0956 9.02941 15.7958 9.71508 15.5782C10.3907 15.3637 11.1869 15.25 12 15.25C12.8132 15.25 13.6093 15.3636 14.285 15.578C14.9706 15.7957 15.4657 16.0954 15.7567 16.4188L17.2433 15.0808C16.6343 14.4043 15.7794 13.9541 14.89 13.6718C13.9907 13.3863 12.9868 13.25 12 13.25C11.0131 13.25 10.0093 13.3864 9.10992 13.6719C8.22059 13.9543 7.36565 14.4045 6.75673 15.081L8.24327 16.419Z" :fill="color"/>
    <path d="M6.75 9.375C8.625 9.375 9.75 8.25 9.75 8.25" :stroke="color" stroke-width="2" stroke-linecap="round"/>
    <path d="M14.2499 8.2955C14.2499 8.2955 15.3749 9.62132 17.1667 9.62132" :stroke="color" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "SadEmoji",
  props: {
    color: {
      type: String,
      default: "#F03E3E",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>

<style></style>

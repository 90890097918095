<template>
    <q-popup v-if="deleteFormModal">
        <div class="modal">
            <h2 class="pb-s">Formulier verwijderen</h2>
            <p>
                Weet u zeker dat u het formulier <strong>{{ deleteFormModal.name }}</strong> wilt verwijderen?
            </p>
            <div class="footer space-between">
                <q-button
                    v-if="isAuthorized"
                    variation="blank"
                    class="red-hover"
                    @click="closePopup"
                    >Annuleren</q-button
                >
                <q-button
                    v-if="isAuthorized"
                    @click="deleteForm"
                    size="medium"
                    variation="danger"
                    :disabled="project.status === 'finished'"
                    >Formulier verwijderen</q-button
                >
            </div>
        </div>
    </q-popup>
</template>

<script>
export default {
    name: 'form-delete-modal',
    props: {
        deleteFormModal: {
            type: Object,
            required: false,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        deleteForm() {
            this.$emit('form', this.deleteFormModal);
            this.closePopup();
        },
    },
    computed: {
        isAuthorized() {
            return this.$parent.authorizedForms.findIndex(form => this.deleteFormModal.id === form.id) !== -1
        }
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    .modal-content {
        // height: 260px
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}
</style>

const mapping = {
    "De planning wordt ruim op tijd ingediend en tijdig geactualiseerd. De opdrachtgever wordt goed en tijdig geïnformeerd over de voortgang. De opdrachtgever hoeft daar niet of nauwelijks om te vragen.": "De planning wordt <span style=\"font-weight:500\">ruim op tijd</span> ingediend en <span style=\"font-weight:500\">tijdig</span> geactualiseerd. De opdrachtgever wordt <span style=\"font-weight:500\">goed en tijdig</span> geïnformeerd over de voortgang. De opdrachtgever hoeft daar <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De planning wordt op tijd ingediend en meestal tijdig geactualiseerd. De opdrachtgever wordt meestal goed en tijdig geïnformeerd over de voortgang. De opdrachtgever moet hier af en toe om vragen.": "De planning wordt <span style=\"font-weight:500\">op tijd</span> ingediend en <span style=\"font-weight:500\">meestal tijdig</span> geactualiseerd. De opdrachtgever wordt <span style=\"font-weight:500\">meestal goed en tijdig</span> geïnformeerd over de voortgang. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De planning wordt te laat aangeleverd en/of regelmatig niet tijdig geactualiseerd. De opdrachtgever wordt regelmatig niet goed en/of niet tijdig geïnformeerd over de voortgang. De opdrachtgever moet hier regelmatig om vragen.": "De planning wordt <span style=\"font-weight:500\">te laat</span> aangeleverd en/of <span style=\"font-weight:500\">regelmatig niet tijdig</span> geactualiseerd. De opdrachtgever wordt <span style=\"font-weight:500\">regelmatig niet goed en/of niet tijdig</span> geïnformeerd over de voortgang. De opdrachtgever moet hier regelmatig om vragen.",
    "De planning wordt niet of veel te laat aangeleverd en/of vaak niet tijdig geactualiseerd. De opdrachtgever wordt vaak niet goed en/of niet tijdig geïnformeerd over de voortgang. De opdrachtgever moet hier vaak om vragen.": "De planning wordt <span style=\"font-weight:500\">niet of veel te laat</span> aangeleverd en/of <span style=\"font-weight:500\">vaak niet tijdig</span> geactualiseerd. De opdrachtgever wordt <span style=\"font-weight:500\">vaak niet goed en/of niet tijdig</span> geïnformeerd over de voortgang. De opdrachtgever moet hier <span style=\"font-weight:500\">vaak</span> om vragen.",

    "De werkzaamheden worden tijdig en volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer ruim voldoende maatregelen. De opdrachtgever hoeft daar niet of nauwelijks om te vragen.": "De werkzaamheden worden <span style=\"font-weight:500\">tijdig en volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">ruim voldoende</span> maatregelen. De opdrachtgever hoeft daar <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De werkzaamheden worden meestal tijdig en volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer voldoende maatregelen. De opdrachtgever moet hier af en toe om vragen.": "De werkzaamheden worden <span style=\"font-weight:500\">meestal tijdig en volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">voldoende</span> maatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De werkzaamheden worden regelmatig niet tijdig en/of niet volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer regelmatig onvoldoende maatregelen. De opdrachtgever moet hier regelmatig om vragen.": "De werkzaamheden worden <span style=\"font-weight:500\">regelmatig niet tijdig en/of niet volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">regelmatig onvoldoende</span> maatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig</span> om vragen.",
    "De werkzaamheden worden vaak niet tijdig en/of niet volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer veelal onvoldoende maatregelen. De opdrachtgever moet hier vaak om vragen.": "De werkzaamheden worden <span style=\"font-weight:500\">vaak niet tijdig en/of niet volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">veelal onvoldoende</span> maatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">vaak</span> om vragen.",

    "De door de opdrachtnemer ingezette medewerkers beschikken ruim voldoende over de benodigde kennis en ervaring. Er zijn geen problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.": "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">ruim voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">geen</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",
    "De door de opdrachtnemer ingezette medewerkers beschikken voldoende over de benodigde kennis en ervaring. Er zijn incidenteel problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.": "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">incidenteel</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",
    "De door de opdrachtnemer ingezette medewerkers beschikken onvoldoende over de benodigde kennis en ervaring. Er zijn regelmatig problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.": "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">onvoldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",
    "De door de opdrachtnemer ingezette medewerkers beschikken nauwelijks over de benodigde kennis en ervaring. Er zijn regelmatig tot vaak problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.": "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">nauwelijks</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig tot vaak</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",

    "De opdrachtnemer geeft ruim voldoende invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever hoeft hier niet of nauwelijks om te vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">ruim voldoende</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever hoeft hier <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De opdrachtnemer geeft voldoende invulling aan het inventariseren en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier af en toe om vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">voldoende</span> invulling aan het inventariseren en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De opdrachtnemer geeft onvoldoende invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier regelmatig om vragen.":"De opdrachtnemer geeft <span style=\"font-weight:500\">onvoldoende</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig</span> om vragen.",
    "De opdrachtnemer geeft niet of nauwelijks invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier regelmatig tot vaak om vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">niet of nauwelijks</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig tot vaak</span> om vragen.",

    "De opdrachtnemer zorgt voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen niet of nauwelijks voor. ": "De opdrachtnemer zorgt voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">niet of nauwelijks</span> voor.",
    "De opdrachtnemer zorgt grotendeels voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen af en toe voor.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">grotendeels</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">af en toe</span> voor.",
    "De opdrachtnemer zorgt beperkt voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen regelmatig voor.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">beperkt</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">regelmatig</span> voor.",
    "De opdrachtnemer zorgt niet of nauwelijks voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen vaak voor.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">niet of nauwelijks</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">vaak</span> voor.",

    "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer voortdurend aandacht voor veiligheid en veiligheidsbewustzijn. Er is volledige transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.": "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer <span style=\"font-weight:500\">voortdurend</span> aandacht voor veiligheid en veiligheidsbewustzijn. Er is <span style=\"font-weight:500\">volledige</span> transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.",
    "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer regelmatig aandacht voor veiligheid en veiligheidsbewustzijn. Er is voldoende transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.": "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer <span style=\"font-weight:500\">regelmatig</span> aandacht voor veiligheid en veiligheidsbewustzijn. Er is <span style=\"font-weight:500\">voldoende</span> transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.",
    "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer onvoldoende aandacht voor veiligheid en veiligheidsbewustzijn. Er is een beperkte mate van transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.": "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer <span style=\"font-weight:500\">onvoldoende</span> aandacht voor veiligheid en veiligheidsbewustzijn. Er is een <span style=\"font-weight:500\">beperkte mate van</span> transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.",
    "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer niet of nauwelijks aandacht voor veiligheid en veiligheidsbewustzijn. Er is een zeer beperkte mate van transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.": "Tijdens de uitvoering van de werkzaamheden heeft de opdrachtnemer <span style=\"font-weight:500\">niet of nauwelijks</span> aandacht voor veiligheid en veiligheidsbewustzijn. Er is een <span style=\"font-weight:500\">zeer beperkte mate van</span> transparantie over onveilige situaties, (bijna) ongevallen en het voorkomen daarvan.",

    "De opdrachtnemer onderzoekt de mogelijke overlast goed. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast voorkomen wordt.":"De opdrachtnemer onderzoekt de mogelijke overlast <span style=\"font-weight:500\">goed</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast <span style=\"font-weight:500\">voorkomen</span> wordt.",
    "De opdrachtnemer onderzoekt de mogelijke overlast voldoende. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast meestal voorkomen wordt.": "De opdrachtnemer onderzoekt de mogelijke overlast <span style=\"font-weight:500\">voldoende</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast <span style=\"font-weight:500\">meestal voorkomen</span> wordt.",
    "De opdrachtnemer onderzoekt de mogelijke overlast onvoldoende. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast regelmatig niet voorkomen wordt.": "De opdrachtnemer onderzoekt de mogelijke overlast <span style=\"font-weight:500\">onvoldoende</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast <span style=\"font-weight:500\">regelmatig niet voorkomen</span> wordt.",
    "De opdrachtnemer onderzoekt de mogelijke overlast niet of nauwelijks. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast vaak niet voorkomen wordt.":  "De opdrachtnemer onderzoekt de mogelijke overlast <span style=\"font-weight:500\">niet of nauwelijks</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige overlast <span style=\"font-weight:500\">vaak niet voorkomen</span> wordt.",

    "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming goed. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming voorkomen worden.":"De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">goed</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">voorkomen</span> worden.",
    "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming voldoende. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming meestal voorkomen worden." : "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">voldoende</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">meestal voorkomen</span> worden." , 
    "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming onvoldoende. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming regelmatig niet voorkomen worden." :"De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">onvoldoende</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">regelmatig niet voorkomen</span> worden." ,
    "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming niet of nauwelijks. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming vaak niet voorkomen worden.": "De opdrachtnemer onderzoekt de mogelijke beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">niet of nauwelijks</span>. De opdrachtnemer voert de werkzaamheden zodanig uit dat onnodige beperkingen voor bereikbaarheid en doorstroming <span style=\"font-weight:500\">vaak niet voorkomen</span> worden.", 

    "De opdrachtnemer denkt goed mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is effectief.":"De opdrachtnemer denkt <span style=\"font-weight:500\">goed</span> mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is <span style=\"font-weight:500\">effectief</span>.",
    "De opdrachtnemer denkt meestal goed mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is meestal effectief.": "De opdrachtnemer denkt <span style=\"font-weight:500\">meestal goed</span> mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is <span style=\"font-weight:500\">meestal effectief</span>.",
    "De opdrachtnemer denkt onvoldoende mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is regelmatig niet effectief.": "De opdrachtnemer denkt <span style=\"font-weight:500\">onvoldoende</span> mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is <span style=\"font-weight:500\">regelmatig niet effectief</span>.",
    "De opdrachtnemer denkt vaak niet goed mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is vaak niet effectief.": "De opdrachtnemer denkt <span style=\"font-weight:500\">vaak niet goed</span> mee met de opdrachtgever over het zo goed mogelijk informeren van de omgeving. De wijze van communiceren van de opdrachtnemer richting de omgeving is <span style=\"font-weight:500\">vaak niet effectief</span>.",

    "De opdrachtnemer zorgt voor duidelijke verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten goed en tijdig in. De opdrachtgever hoeft hier niet of nauwelijks om te vragen.":"De opdrachtnemer zorgt voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten <span style=\"font-weight:500\">goed en tijdig</span> in. De opdrachtgever hoeft hier <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De opdrachtnemer zorgt grotendeels voor duidelijke verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten meestal goed en tijdig in. De opdrachtgever moet hier af en toe om vragen. ": "De opdrachtnemer zorgt <span style=\"font-weight:500\">grotendeels</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten <span style=\"font-weight:500\">meestal goed en tijdig</span> in. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen. ",
    "De opdrachtnemer zorgt beperkt voor duidelijke verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten regelmatig niet goed en/of tijdig in. De opdrachtgever moet hier regelmatig om vragen.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">beperkt</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten <span style=\"font-weight:500\">regelmatig niet goed en/of tijdig</span> in. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig</span> om vragen.",
    "De opdrachtnemer zorgt niet of nauwelijks voor duidelijke verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten regelmatig tot vaak niet goed en/of tijdig in. De opdrachtgever moet hier regelmatig tot vaak om vragen.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">niet of nauwelijks</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten <span style=\"font-weight:500\">regelmatig tot vaak niet goed en/of tijdig</span> in. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig tot vaak</span> om vragen.",

    "De opdrachtnemer is transparant over mogelijke wijzigingen, bespreekt die tijdig met de opdrachtgever en denkt constructief mee.": "De opdrachtnemer is <span style=\"font-weight:500\">transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">tijdig</span> met de opdrachtgever en denkt constructief mee.",
    "De opdrachtnemer is grotendeels transparant over mogelijke wijzigingen, bespreekt die meestal tijdig met de opdrachtgever en denkt voldoende constructief mee.":"De opdrachtnemer is <span style=\"font-weight:500\">grotendeels transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">meestal tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">voldoende</span> constructief mee.",
    "De opdrachtnemer is beperkt transparant over mogelijke wijzigingen, bespreekt die regelmatig niet tijdig met de opdrachtgever en denkt onvoldoende constructief mee.": "De opdrachtnemer is <span style=\"font-weight:500\">beperkt transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">regelmatig niet tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">onvoldoende</span> constructief mee.",
    "De opdrachtnemer is niet transparant over mogelijke wijzigingen, bespreekt die vaak niet tijdig met de opdrachtgever en denkt niet of nauwelijks constructief mee.":"De opdrachtnemer is <span style=\"font-weight:500\">niet transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">vaak niet tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">niet of nauwelijks</span> constructief mee.",

    "De opdrachtnemer zet zich goed in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een plezierige en effectieve manier. De opdrachtnemer is open over zijn belangen en mogelijke problemen.":"De opdrachtnemer zet zich <span style=\"font-weight:500\">goed</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is open over zijn belangen en mogelijke problemen.",
    "De opdrachtnemer zet zich voldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert meestal op een plezierige en effectieve manier. De opdrachtnemer is meestal open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">voldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">meestal</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is <span style=\"font-weight:500\">meestal</span> open over zijn belangen.",
    "De opdrachtnemer zet zich onvoldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert regelmatig niet op een plezierige en effectieve manier. De opdrachtnemer is regelmatig niet open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">onvoldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">regelmatig niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is <span style=\"font-weight:500\">regelmatig niet</span> open over zijn belangen.",
    "De opdrachtnemer zet zich niet of nauwelijks in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert vaak niet op een plezierige en effectieve manier. De opdrachtnemer was vaak niet open over zijn belangen.":"De opdrachtnemer zet zich <span style=\"font-weight:500\">niet of nauwelijks</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">vaak niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer was <span style=\"font-weight:500\">vaak niet</span> open over zijn belangen.",  

    "De opdrachtnemer doet tijdig en op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet concrete voorstellen en houdt ruim voldoende rekening met de belangen van de opdrachtgever.":"De opdrachtnemer doet <span style=\"font-weight:500\">tijdig en op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">concrete</span> voorstellen en houdt <span style=\"font-weight:500\">ruim voldoende</span> rekening met de belangen van de opdrachtgever.",
    "De opdrachtnemer doet meestal tijdig en op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet voldoende concrete voorstellen en houdt voldoende rekening met de belangen van de opdrachtgever.": "De opdrachtnemer doet <span style=\"font-weight:500\">meestal tijdig en op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">voldoende concrete</span> voorstellen en houdt <span style=\"font-weight:500\">voldoende</span> rekening met de belangen van de opdrachtgever.",
    "De opdrachtnemer doet regelmatig niet tijdig en/of niet op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet matig concrete voorstellen en/of houdt matig rekening met de belangen van de opdrachtgever.":"De opdrachtnemer doet <span style=\"font-weight:500\">regelmatig niet tijdig en/of niet op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">matig concrete</span> voorstellen en/of houdt <span style=\"font-weight:500\">matig</span> rekening met de belangen van de opdrachtgever.",
    "De opdrachtnemer doet vaak niet tijdig en/of niet op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet geen of onvoldoende concrete voorstellen en/of houdt onvoldoende rekening met de belangen van de opdrachtgever.": "De opdrachtnemer doet <span style=\"font-weight:500\">vaak niet tijdig en/of niet op eigen initiatief </span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">geen of onvoldoende concrete voorstellen en/of houdt onvoldoende</span> rekening met de belangen van de opdrachtgever.",

    "De werkzaamheden worden tijdig en volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer ruim voldoende maatregelen.":"De werkzaamheden worden <span style=\"font-weight:500\">tijdig en volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">ruim voldoende</span> maatregelen.",
    "De werkzaamheden worden meestal tijdig en volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer voldoende maatregelen.":"De werkzaamheden worden <span style=\"font-weight:500\">meestal tijdig en volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">voldoende</span> maatregelen.",
    "De werkzaamheden worden regelmatig niet tijdig en/of niet volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer matige maatregelen.":"De werkzaamheden worden <span style=\"font-weight:500\">regelmatig niet tijdig en/of niet volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">matige</span> maatregelen.",
    "De werkzaamheden worden vaak niet tijdig en/of niet volgens afspraak uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer onvoldoende maatregelen.":"De werkzaamheden worden <span style=\"font-weight:500\">vaak niet tijdig en/of niet volgens afspraak</span> uitgevoerd. Bij mogelijke uitloop neemt de opdrachtnemer <span style=\"font-weight:500\">onvoldoende</span> maatregelen.",

    "De opdrachtnemer brengt de opdrachtgever goed en tijdig op de hoogte.  De opdrachtnemer denkt constructief mee in oplossingen. De opdrachtgever hoeft hier niet of nauwelijks om te vragen.":"De opdrachtnemer brengt de opdrachtgever <span style=\"font-weight:500\">goed en tijdig</span> op de hoogte.  De opdrachtnemer denkt <span style=\"font-weight:500\">constructief</span> mee in oplossingen. De opdrachtgever hoeft hier <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De opdrachtnemer brengt de opdrachtgever meestal goed en tijdig op de hoogte. De opdrachtnemer denkt meestal constructief mee in oplossingen. De opdrachtgever moet hier af en toe om vragen.": "De opdrachtnemer brengt de opdrachtgever <span style=\"font-weight:500\">meestal goed en tijdig</span> op de hoogte. De opdrachtnemer denkt <span style=\"font-weight:500\">meestal constructief</span> mee in oplossingen. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De opdrachtnemer brengt de opdrachtgever regelmatig niet goed en/of niet tijdig op de hoogte. De opdrachtnemer denkt regelmatig niet constructief mee in oplossingen. De opdrachtgever moet hier regelmatig om vragen.": "De opdrachtnemer brengt de opdrachtgever <span style=\"font-weight:500\">regelmatig niet goed en/of niet tijdig</span> op de hoogte. De opdrachtnemer denkt <span style=\"font-weight:500\">regelmatig niet constructief</span> mee in oplossingen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig</span> om vragen.",
    "De opdrachtnemer brengt de opdrachtgever vaak niet goed en/of niet tijdig op de hoogte. De opdrachtnemer denkt vaak niet constructief mee in oplossingen. De opdrachtgever moet hier vaak om vragen.": "De opdrachtnemer brengt de opdrachtgever <span style=\"font-weight:500\">vaak niet goed en/of niet tijdig</span> op de hoogte. De opdrachtnemer denkt <span style=\"font-weight:500\">vaak</span> niet constructief mee in oplossingen. De opdrachtgever moet hier <span style=\"font-weight:500\">vaak</span> om vragen.",

    "De door de opdrachtnemer ingezette medewerkers beschikken ruim voldoende over de benodigde kennis en ervaring. Er zijn geen problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.":"De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">ruim voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">geen</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",
    "De door de opdrachtnemer ingezette medewerkers beschikken voldoende over de benodigde kennis en ervaring. Er zijn incidenteel problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." : "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">incidenteel</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." ,
    "De door de opdrachtnemer ingezette medewerkers beschikken onvoldoende over de benodigde kennis en ervaring. Er zijn regelmatig problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." : "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">onvoldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." ,
    "De door de opdrachtnemer ingezette medewerkers beschikken nauwelijks over de benodigde kennis en ervaring. Er zijn regelmatig tot vaak problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.": "De door de opdrachtnemer ingezette medewerkers beschikken <span style=\"font-weight:500\">nauwelijks</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig tot vaak</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",

    "De opdrachtnemer geeft ruim voldoende invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever hoeft hier niet of nauwelijks om te vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">ruim voldoende</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever hoeft hier <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De opdrachtnemer geeft voldoende invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier af en toe om vragen.":"De opdrachtnemer geeft <span style=\"font-weight:500\">voldoende</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De opdrachtnemer geeft onvoldoende invulling aan het inventariseren en analyseren van risico’s en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier regelmatig om vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">onvoldoende</span> invulling aan het inventariseren en analyseren van risico’s en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig</span> om vragen.",
    "De opdrachtnemer geeft niet of nauwelijks invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier regelmatig tot vaak om vragen.": "De opdrachtnemer geeft <span style=\"font-weight:500\">niet of nauwelijks</span> invulling aan het inventariseren en analyseren van risico's en aan het voorstellen van passende beheersmaatregelen. De opdrachtgever moet hier <span style=\"font-weight:500\">regelmatig tot vaak</span> om vragen.",

    "De opdrachtnemer zorgt voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen niet of nauwelijks voor.":  "De opdrachtnemer zorgt voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen niet of nauwelijks voor.",
    "De opdrachtnemer zorgt grotendeels voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen af en toe voor." :"De opdrachtnemer zorgt <span style=\"font-weight:500\">grotendeels</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">af en toe</span> voor." ,
    "De opdrachtnemer zorgt beperkt voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen regelmatig voor." :"De opdrachtnemer zorgt <span style=\"font-weight:500\">beperkt</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">regelmatig</span> voor." ,
    "De opdrachtnemer zorgt niet of nauwelijks voor duidelijke verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen vaak voor.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">niet of nauwelijks</span> voor <span style=\"font-weight:500\">duidelijke</span> verwachtingen over de wijze van uitvoering en het borgen en aantonen van kwaliteit. Kwaliteitsafwijkingen komen <span style=\"font-weight:500\">vaak</span> voor.",

    "De opdrachtnemer doet tijdig en op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet concrete voorstellen en houdt ruim voldoende rekening met de belangen van de opdrachtgever.":"De opdrachtnemer doet <span style=\"font-weight:500\">tijdig en op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">concrete voorstellen </span> en houdt <span style=\"font-weight:500\">ruim voldoende</span> rekening met de belangen van de opdrachtgever.",
    "De opdrachtnemer doet meestal tijdig en op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet voldoende concrete voorstellen en houdt voldoende rekening met de belangen van de opdrachtgever.":"De opdrachtnemer doet <span style=\"font-weight:500\">meestal tijdig en op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">voldoende concrete voorstellen</span> en houdt <span style=\"font-weight:500\">voldoende</span> rekening met de belangen van de opdrachtgever.",
    "De opdrachtnemer doet regelmatig niet tijdig en/of niet op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet matig concrete voorstellen en/of houdt matig rekening met de belangen van de opdrachtgever.": "De opdrachtnemer doet <span style=\"font-weight:500\">regelmatig niet tijdig en/of niet op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">matig concrete voorstellen en/of houdt matig</span> rekening met de belangen van de opdrachtgever.",
    "Opdrachtnemer doet vaak niet tijdig en/of niet op eigen initiatief een verkenning met de opdrachtgever. De opdrachtnemer doet geen of onvoldoende concrete voorstellen en/of houdt onvoldoende rekening met de belangen van de opdrachtgever.": "Opdrachtnemer doet <span style=\"font-weight:500\">vaak niet tijdig en/of niet op eigen initiatief</span> een verkenning met de opdrachtgever. De opdrachtnemer doet <span style=\"font-weight:500\">geen of onvoldoende concrete voorstellen en/of houdt onvoldoende</span> rekening met de belangen van de opdrachtgever.",

    "De opdrachtnemer is transparant over mogelijke wijzigingen, bespreekt die tijdig met de opdrachtgever en denkt constructief mee.": "De opdrachtnemer is <span style=\"font-weight:500\">transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">tijdig</span> met de opdrachtgever en denkt constructief mee.",
    "De opdrachtnemer is grotendeels transparant over mogelijke wijzigingen, bespreekt die meestal tijdig met de opdrachtgever en denkt voldoende constructief mee.": "De opdrachtnemer is <span style=\"font-weight:500\">grotendeels transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">meestal tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">voldoende</span> constructief mee.",
    "De opdrachtnemer is beperkt transparant over mogelijke wijzigingen, bespreekt die regelmatig niet tijdig met de opdrachtgever en denkt onvoldoende constructief mee.":  "De opdrachtnemer is <span style=\"font-weight:500\">beperkt transparant </span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">regelmatig niet tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">onvoldoende</span> constructief mee.",
    "De opdrachtnemer is niet transparant over mogelijke wijzigingen, bespreekt die vaak niet tijdig met de opdrachtgever en denkt niet of nauwelijks constructief mee.": "De opdrachtnemer is <span style=\"font-weight:500\">niet transparant</span> over mogelijke wijzigingen, bespreekt die <span style=\"font-weight:500\">vaak niet tijdig</span> met de opdrachtgever en denkt <span style=\"font-weight:500\">niet of nauwelijks</span> constructief mee.",

    "De opdrachtnemer zet zich goed in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een plezierige en effectieve manier. De opdrachtnemer is open over zijn belangen en mogelijke problemen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">goed</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is open over zijn belangen en mogelijke problemen.",
    "De opdrachtnemer zet zich voldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert meestal op een plezierige en effectieve manier. De opdrachtnemer is meestal open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">voldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">meestal</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is <span style=\"font-weight:500\">meestal</span> open over zijn belangen.",
    "De opdrachtnemer zet zich onvoldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert regelmatig niet op een plezierige en effectieve manier. De opdrachtnemer is regelmatig niet open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">onvoldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">regelmatig niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is <span style=\"font-weight:500\">regelmatig niet</span> open over zijn belangen.",
    "De opdrachtnemer zet zich niet of nauwelijks in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert vaak niet op een plezierige en effectieve manier. De opdrachtnemer was vaak niet open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">niet of nauwelijks</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">vaak niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer was <span style=\"font-weight:500\">vaak niet</span> open over zijn belangen.",

    "De taken worden tijdig en volgens afspraak uitgevoerd. De opdrachtgever houdt de opdrachtnemer goed op de hoogte.": "De taken worden <span style=\"font-weight:500\">tijdig en volgens afspraak</span> uitgevoerd. De opdrachtgever houdt de opdrachtnemer <span style=\"font-weight:500\">goed</span> op de hoogte.",
    "De taken worden meestal tijdig en volgens afspraak uitgevoerd. De opdrachtgever houdt de opdrachtnemer voldoende op de hoogte.": "De taken worden <span style=\"font-weight:500\">meestal tijdig en volgens afspraak</span> uitgevoerd. De opdrachtgever houdt de opdrachtnemer <span style=\"font-weight:500\">voldoende</span> op de hoogte.",
    "De taken worden regelmatig niet tijdig en/of niet volgens afspraak uitgevoerd. De opdrachtgever houdt de opdrachtnemer onvoldoende op de hoogte.": "De taken worden <span style=\"font-weight:500\">regelmatig niet tijdig en/of niet volgens afspraak</span> uitgevoerd. De opdrachtgever houdt de opdrachtnemer <span style=\"font-weight:500\">onvoldoende</span> op de hoogte.",
    "De taken worden vaak niet tijdig en/of niet volgens afspraak uitgevoerd. De opdrachtgever houdt de opdrachtnemer niet of nauwelijks op de hoogte.":"De taken worden vaak <span style=\"font-weight:500\">niet tijdig en/of niet volgens afspraak</span> uitgevoerd. De opdrachtgever houdt de opdrachtnemer <span style=\"font-weight:500\">niet of nauwelijks</span> op de hoogte.",

    "De door de opdrachtgever ingezette personen beschikken ruim voldoende over de benodigde kennis en ervaring. Er zijn geen problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.":  "De door de opdrachtgever ingezette personen beschikken <span style=\"font-weight:500\">ruim voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">geen</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",
    "De door de opdrachtgever ingezette personen beschikken voldoende over de benodigde kennis en ervaring. Er zijn incidenteel problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." :"De door de opdrachtgever ingezette personen beschikken <span style=\"font-weight:500\">voldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">incidenteel</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." ,
    "De door de opdrachtgever ingezette personen beschikken onvoldoende over de benodigde kennis en ervaring. Er zijn regelmatig problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." : "De door de opdrachtgever ingezette personen beschikken <span style=\"font-weight:500\">onvoldoende</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring." ,
    "De door de opdrachtgever ingezette personen beschikken nauwelijks over de benodigde kennis en ervaring. Er zijn regelmatig tot vaak problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.":"De door de opdrachtgever ingezette personen beschikken <span style=\"font-weight:500\">nauwelijks</span> over de benodigde kennis en ervaring. Er zijn <span style=\"font-weight:500\">regelmatig tot vaak</span> problemen die te wijten zijn aan het ontbreken van de benodigde kennis en ervaring.",

    "De opdrachtgever heeft voortdurend aandacht voor veiligheid en veiligheidsbewustzijn.":"De opdrachtgever heeft <span style=\"font-weight:500\">voortdurend</span> aandacht voor veiligheid en veiligheidsbewustzijn.",
    "De opdrachtgever heeft regelmatig aandacht voor veiligheid en veiligheidsbewustzijn.":"De opdrachtgever heeft <span style=\"font-weight:500\">regelmatig</span> aandacht voor veiligheid en veiligheidsbewustzijn. ",
    "De opdrachtgever heeft onvoldoende aandacht voor veiligheid en veiligheidsbewustzijn.":"De opdrachtgever heeft <span style=\"font-weight:500\">onvoldoende</span> aandacht voor veiligheid en veiligheidsbewustzijn. ",
    "De opdrachtgever heeft niet of nauwelijks aandacht voor veiligheid en veiligheidsbewustzijn.":"De opdrachtgever heeft <span style=\"font-weight:500\">niet of nauwelijks</span> aandacht voor veiligheid en veiligheidsbewustzijn.",

    "De opdrachtgever beoordeelt voorstellen met een open houding en denkt constructief mee.":"De opdrachtgever beoordeelt voorstellen met een <span style=\"font-weight:500\">open houding</span> en denkt <span style=\"font-weight:500\">constructief</span> mee.",
    "De opdrachtgever beoordeelt voorstellen meestal met een open houding en denkt voldoende constructief mee.":"De opdrachtgever beoordeelt voorstellen <span style=\"font-weight:500\">meestal</span> met een <span style=\"font-weight:500\">open houding</span> en denkt <span style=\"font-weight:500\">voldoende constructief</span> mee.",
    "De opdrachtgever beoordeelt voorstellen niet altijd met een open houding en denkt onvoldoende constructief mee.":"De opdrachtgever beoordeelt voorstellen <span style=\"font-weight:500\">niet altijd</span> met een <span style=\"font-weight:500\">open houding</span> en denkt <span style=\"font-weight:500\">onvoldoende constructief</span> mee.",
    "De opdrachtgever beoordeelt voorstellen niet of nauwelijks met een open houding en denkt niet of nauwelijks constructief mee.": "De opdrachtgever beoordeelt voorstellen <span style=\"font-weight:500\">niet of nauwelijks</span> met een <span style=\"font-weight:500\">open houding</span> en denkt <span style=\"font-weight:500\">niet of nauwelijks constructief</span> mee.",

    "De opdrachtgever houdt goed rekening met de financiële belangen van de opdrachtnemer.":  "De opdrachtgever houdt <span style=\"font-weight:500\">goed</span> rekening met de financiële belangen van de opdrachtnemer.",
    "De opdrachtgever houdt voldoende rekening met de financiële belangen van de opdrachtnemer.":"De opdrachtgever houdt <span style=\"font-weight:500\">voldoende</span> rekening met de financiële belangen van de opdrachtnemer.",
    "De opdrachtgever houdt onvoldoende rekening met de financiële belangen van de opdrachtnemer.": "De opdrachtgever houdt <span style=\"font-weight:500\">onvoldoende</span> rekening met de financiële belangen van de opdrachtnemer.",
    "De opdrachtgever houdt niet of nauwelijks rekening met de financiële belangen van de opdrachtnemer.":"De opdrachtgever houdt <span style=\"font-weight:500\">niet of nauwelijks</span> rekening met de financiële belangen van de opdrachtnemer.",

    "De opdrachtgever zet zich goed in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert op een plezierige en effectieve manier. De opdrachtgever is open over zijn belangen en mogelijke problemen.":"De opdrachtgever zet zich <span style=\"font-weight:500\">goed</span> in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtgever is open over zijn belangen en mogelijke problemen.",
    "De opdrachtgever zet zich voldoende in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert meestal op een plezierige en effectieve manier. De opdrachtgever is meestal open over zijn belangen.": "De opdrachtgever zet zich <span style=\"font-weight:500\">voldoende</span> in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert <span style=\"font-weight:500\">meestal</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtgever is <span style=\"font-weight:500\">meestal</span> open over zijn belangen.",
    "De opdrachtgever zet zich onvoldoende in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert regelmatig niet op een plezierige en effectieve manier. De opdrachtgever is regelmatig niet open over zijn belangen.": "De opdrachtgever zet zich <span style=\"font-weight:500\">onvoldoende</span> in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert <span style=\"font-weight:500\">regelmatig niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtgever is <span style=\"font-weight:500\">regelmatig niet</span> open over zijn belangen.",
    "De opdrachtgever zet zich niet of nauwelijks in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert vaak niet op een plezierige en effectieve manier. De opdrachtgever was vaak niet open over zijn belangen.":"De opdrachtgever zet zich <span style=\"font-weight:500\">niet of nauwelijks</span> in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert <span style=\"font-weight:500\">vaak niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtgever was <span style=\"font-weight:500\">vaak niet</span> open over zijn belangen.",

    "De opdrachtgever past CROW Better Performance goed toe.":"De opdrachtgever past CROW Better Performance <span style=\"font-weight:500\">goed</span> toe.",
    "De opdrachtgever past CROW Better Performance voldoende toe.":"De opdrachtgever past CROW Better Performance <span style=\"font-weight:500\">voldoende</span> toe.",
    "De opdrachtgever past CROW Better Performance onvoldoende toe.":"De opdrachtgever past CROW Better Performance <span style=\"font-weight:500\">onvoldoende</span> toe.",
    "De opdrachtgever past CROW Better Performance niet of nauwelijks toe.":"De opdrachtgever past CROW Better Performance <span style=\"font-weight:500\">niet of nauwelijks</span> toe.",

    "De opdrachtnemer zorgt grotendeels voor duidelijke verwachtingen richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten meestal goed en tijdig in. De opdrachtgever moet hier af en toe om vragen.": "De opdrachtnemer zorgt <span style=\"font-weight:500\">grotendeels</span> voor <span style=\"font-weight:500\">duidelijke verwachtingen</span>richting de opdrachtgever over het aanleveren van de documentatie. De opdrachtnemer dient de documenten <span style=\"font-weight:500\">meestal goed en tijdig</span> in. De opdrachtgever moet hier <span style=\"font-weight:500\">af en toe</span> om vragen.",
    "De opdrachtgever zet zich voldoende in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert meestal op een plezierige en effectieve manier.  De opdrachtgever is meestal open over zijn belangen.": "De opdrachtgever zet zich <span style=\"font-weight:500\">voldoende</span> in om de opdrachtnemer te leren kennen en zijn belangen te begrijpen. De opdrachtgever communiceert <span style=\"font-weight:500\">meestal</span> op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtgever is <span style=\"font-weight:500\">meestal</span> open over zijn belangen.",
    "De opdrachtnemer levert een transparante, onderbouwde en marktconforme begroting. In de begroting zijn alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze opgenomen.": "De opdrachtnemer levert een <span style=\"font-weight:500\">transparante, onderbouwde en marktconforme begroting</span>. In de begroting zijn <span style=\"font-weight:500\">alleen de voor de opdracht noodzakelijke werkzaamheden</span> op de <span style=\"font-weight:500\">meest kostenefficiënte</span> wijze opgenomen.",
    "De opdrachtnemer levert een grotendeels transparante, onderbouwde en marktconforme begroting. In de begroting zijn op kleine uitzonderingen na alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze opgenomen.": "De opdrachtnemer levert een <span style=\"font-weight:500\">grotendeels transparante, onderbouwde en marktconforme begroting</span>. In de begroting zijn <span style=\"font-weight:500\">op kleine uitzonderingen na</span> alleen de voor de opdracht noodzakelijke</span>  werkzaamheden op de <span style=\"font-weight:500\">meest kostenefficiënte wijze</span> opgenomen.",
    "De opdrachtnemer levert een beperkt transparante, onderbouwde en marktconforme begroting. In de begroting zijn regelmatig niet alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze opgenomen.": "De opdrachtnemer levert een <span style=\"font-weight:500\">beperkt transparante, onderbouwde en marktconforme begroting</span>. In de begroting zijn <span style=\"font-weight:500\">regelmatig niet alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze</span> opgenomen.",
    "De opdrachtnemer levert een niet transparante, onderbouwde en marktconforme begroting. In de begroting zijn vaak niet alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze opgenomen.": "De opdrachtnemer levert een <span style=\"font-weight:500\">niet transparante, onderbouwde en marktconforme begroting</span>. In de begroting zijn <span style=\"font-weight:500\">vaak niet alleen de voor de opdracht noodzakelijke werkzaamheden op de meest kostenefficiënte wijze</span> opgenomen.",
    "De opdrachtnemer brengt de opdrachtgever goed en tijdig op de hoogte. De opdrachtnemer denkt constructief mee in oplossingen. De opdrachtgever hoeft hier niet of nauwelijks om te vragen.": "De opdrachtnemer brengt de opdrachtgever <span style=\"font-weight:500\">goed en tijdig</span> op de hoogte. De opdrachtnemer denkt<span style=\"font-weight:500\"> constructief</span> mee in oplossingen. De opdrachtgever hoeft hier <span style=\"font-weight:500\">niet of nauwelijks</span> om te vragen.",
    "De opdrachtnemer zet zich goed in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een plezierige en effectieve manier. De opdrachtnemer is open over zijn belangen en mogelijke problemen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">goed</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert op een <span style=\"font-weight:500\">plezierige en effectieve</span> manier. De opdrachtnemer is <span style=\"font-weight:500\">open</span> over zijn belangen en mogelijke problemen.",
    "De opdrachtnemer zet zich voldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert meestal op een plezierige en effectieve manier. De opdrachtnemer is meestal open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">voldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">meestal</span>. op een <span style=\"font-weight:500\">plezierige en effectieve<span style=\"font-weight:500\"> manier. De opdrachtnemer is <span style=\"font-weight:500\">meestal</span> open over zijn belangen.",
    "De opdrachtnemer zet zich onvoldoende in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert regelmatig niet op een plezierige en effectieve manier. De opdrachtnemer is regelmatig niet open over zijn belangen.": "De opdrachtnemer zet zich <span style=\"font-weight:500\">onvoldoende</span> in om de opdrachtgever te leren kennen en zijn belangen te begrijpen. De opdrachtnemer communiceert <span style=\"font-weight:500\">regelmatig niet</span> op een <span style=\"font-weight:500\">plezierige en effectieve </span>manier. De opdrachtnemer is <span style=\"font-weight:500\">regelmatig niet open</span> over zijn belangen."
}

const getSentence = (sentence) => {
    const cleanSentence = sentence.replace(/<[^>]*>?/gm, '').replace('\n', '').trim();

    return mapping[cleanSentence] || cleanSentence;
}

export default getSentence;
<template>
    <div 
        :id="`tooltip-${elementId}`" 
        :class="['tooltip']" 
        :tabindex="disabled ? -1 : 0" 
        @mouseenter="handleMouseEnter" 
        @mouseleave="handleMouseLeave" 
        @mousedown="mouseDown = true"
        @mouseup="mouseDown = false"
        @focus="handleFocus" 
        @blur="handleBlur"
    >
        <slot />
        <Portal to="tooltip" v-if="portalEnabled">
            <div 
                class="tooltip-wrapper" 
                :class="[showTooltip ? 'show' : 'hide', position, { 'cancel-transition': coordinates.correctionX !== 0 }]" 
                :style="`
                    --position-left: ${coordinates.left}px; 
                    --position-right: ${coordinates.right}px;
                    --position-top: ${coordinates.top}px;
                    --position-bottom: ${coordinates.bottom}px;
                    --element-width: ${coordinates.width}px;
                    --element-height: ${coordinates.height}px;
                    --max-width: ${coordinates.viewportWidth}px;
                    --max-height: ${coordinates.viewportHeight}px;
                    --correction-x: ${coordinates.correctionX}px;
                `">
                <span :id="`tooltiptext-${elementId}`" :class="['tooltiptext', position, 'tooltip-' + position, showTooltip ? 'show' : 'hide']">
                    <slot name="tooltip"></slot>
                </span>
            </div>
        </Portal>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'q-tooltip',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         *Specifies position. Comes in `top, bottom, left, right`.
         */
        position: {
            type: String,
            default: 'top',
            validator: (value) => {
                return value.match(/(left|top|right|bottom)/);
            },
        },
        /**
         * If the tooltip is disabled, it doesnt show the message on hover
         */
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {
            elementId: uuidv4(),
            showTooltip: false,
            portalEnabled: false,
            coordinates: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: 0,
                height: 0,
                viewportWidth: window.innerWidth,
                viewportHeight: window.innerHeight,
                correctionX: 0
            },
            closeTimer: null,
            openTimer: null,
            mouseDown: false,
            element: null
        }
    },
    methods: {
        async handleMouseEnter(event) {
            if(this.disabled) return

            this.openTimer = setTimeout(() => {
                this.handleShowTooltip(event.target);
            }, 75);
        },
        handleFocus(event) {
            if(this.mouseDown) return
            this.handleShowTooltip(event.target);
        },
        handleBlur() {
            this.handleMouseLeave();
        },
        handleShowTooltip(element) {
            clearTimeout(this.closeTimer);
            
            this.portalEnabled = true;
            this.showTooltip = true;
            this.element = element;

            this.setCoordinates(element);
        },
        async handleMouseLeave() {
            if(this.disabled) return

            clearTimeout(this.openTimer);

            this.showTooltip = false;
            this.closeTimer = setTimeout(() => {
                this.portalEnabled = false;
                this.element = null;
            }, 200)
        },
        _setCoordinates: _.debounce(function() {
            this.setCoordinates(this.element);
        }, 1),
        async setCoordinates(element) {
            if(!element) return;
            const elementPosition = element.getBoundingClientRect();

            this.coordinates.left = elementPosition.left;
            this.coordinates.top = elementPosition.top;
            this.coordinates.bottom = elementPosition.bottom;
            this.coordinates.width = elementPosition.width;
            this.coordinates.height = elementPosition.height;
            this.coordinates.correctionX = 0;

            this.$nextTick(() => this.repositionCoordinates());
        },
        repositionCoordinates() {
            const tooltip = document.getElementById(`tooltiptext-${this.elementId}`);
            if(!tooltip) return

            const pixelsFromEdge = 8;

            let left = this.coordinates.left;
            const width = this.coordinates.width;
            const center = left + width / 2;

            // calculate absolute inline position with offsetWidth 
            // (offsetWidth is not influenced by css property 'scale')
            const absoluteLeft = center - tooltip.offsetWidth / 2;
            const absoluteRight = center + tooltip.offsetWidth / 2;

            let offsetLeft = absoluteLeft;
            let offsetRight = window.innerWidth - absoluteRight;

            if(offsetLeft - pixelsFromEdge < 0) {
                left -= offsetLeft - pixelsFromEdge;
                this.coordinates.correctionX = -offsetLeft - pixelsFromEdge;
            }
            if(offsetRight + pixelsFromEdge < 0) {
                left += offsetRight - pixelsFromEdge;
                this.coordinates.correctionX = offsetRight - pixelsFromEdge;
            }
            
            this.coordinates.left = left;
        },
        handleScroll() {
            this.handleMouseLeave();
            document.removeEventListener('scroll', this.handleScroll);
        },
        handleMouseMove() {
            if(!this.showTooltip) return
            this._setCoordinates();
        }
    },
    watch: {
        portalEnabled: function() {
            if(this.portalEnabled) document.addEventListener('scroll', this.handleScroll);
            else document.removeEventListener('scroll', this.handleScroll);
        }
    },
    created() {
        this.$root.$on('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        this.$root.$off('mousemove', this.handleMouseMove);
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.tooltip {
    position: relative;
    display: flex;
    pointer-events: all;

    &:empty {
        display: none;
    }
}

@keyframes jumpIn {
    0% {
        opacity: 0;
        scale: 1;
        animation-timing-function: step-end;
    } 1% {
        opacity: 0;
        scale: .7;
    } 100% {
        opacity: 1;
        scale: 1;
    }
}
@keyframes jumpOut {
    0% {
        opacity: 1;
        scale: 1;
    } 100% {
        opacity: 0;
        scale: .8;
    }
}

.tooltip-wrapper {
    position: absolute;
    transition: left .05s ease;

    &.cancel-transition {
        transition: unset;
    }

    &.top {
        left: calc(var(--position-left) + var(--element-width) / 2);
        top: calc(var(--position-top) - 10px);

        .tooltiptext {
            translate: -50% -100%;
            transform-origin: calc(50% - var(--correction-x)) bottom;
        }
    }
    &.right {
        left: calc(var(--position-right) + 10px);
        top: calc(var(--position-top) + var(--element-height) / 2);

        .tooltiptext {
            translate: 0% -50%;
            transform-origin: left center;
        }
    }
    &.bottom {
        left: calc(var(--position-left) + var(--element-width) / 2);
        top: calc(var(--position-bottom) + 10px);

        .tooltiptext {
            translate: -50% 0%;
            transform-origin: calc(50% - var(--correction-x)) top;
        }
    }
    &.left {
        left: calc(var(--position-left) - 10px);
        top: calc(var(--position-top) + var(--element-height) / 2);

        .tooltiptext {
            translate: -100% -50%;
            transform-origin: right center;
        }
    }
}

.tooltiptext {
    background: $color-black;
    font-family: $font-text;
    font-weight: $weight-normal;
    font-size: $size-ml;
    line-height: $line-height-s;
    color: $color-white;
    text-align: left;
    border-radius: 4px;
    padding: 5px 10px;
    position: fixed;
    white-space: nowrap;

    &:empty {
        display: none;
    }

    &.show {
        animation: jumpIn 250ms cubic-bezier(.51,1.6,.45,1.08) forwards;
    }
    &.hide {
        animation: jumpOut 150ms ease forwards;
    }
}
.tooltiptext::after {
    content: ' ';
    position: absolute;
    border-style: solid;
    border-width: 5px;
}
.tooltip-top::after {
    display: block;
    height: 12px;
    width: 12px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 6px - var(--correction-x));
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 0.2em;
}
.tooltip-left::after {
    display: block;
    height: 12px;
    width: 12px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    right: -5px;
    top: calc(50% - 6px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-135deg);
    border-radius: 0 0 0 0.2em;
}
.tooltip-right::after {
    display: block;
    height: 12px;
    width: 12px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    left: -5px;
    top: calc(50% - 6px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-315deg);
    border-radius: 0 0 0 0.2em;
}
.tooltip-bottom::after {
    display: block;
    height: 12px;
    width: 12px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -5px;
    left: calc(50% - 6px - var(--correction-x));
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-225deg);
    border-radius: 0 0 0 0.2em;
}

</style>

<docs>
```jsx
<div>
    <q-tooltip> <template #tooltip>
    <p>
        - 9 tekens <br />
        - 1 hoofdletter <br />
        - 1 speciaal teken <br />
        - 1 kleine letter
    </p>
</template> <q-avatar size="large">MB</q-avatar></q-tooltip>
    <br/>
    <q-tooltip text="left" position="left"><q-avatar size="large">MB</q-avatar></q-tooltip><br/>
    <q-tooltip text="right" position="right"><q-avatar size="large">MB</q-avatar></q-tooltip><br/>
    <q-tooltip text="bottom" position="bottom"><q-avatar size="large">MB</q-avatar></q-tooltip><br/><br/>

    <q-tooltip text="top"><q-button variation="primary" size="medium">Medium</q-button></q-tooltip><br/>
    <q-tooltip text="left" position="left"><q-button variation="primary" size="medium">Medium</q-button></q-tooltip><br/>
    <q-tooltip text="right" position="right"><q-button variation="primary" size="medium">Medium</q-button></q-tooltip><br/>
    <q-tooltip text="bottom" position="bottom"><q-button variation="primary" size="medium">Medium</q-button></q-tooltip>
</div>
```
</docs>

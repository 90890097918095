<template>
    <div class="q-select-organisation detail-page-padding">
        <div class="head">
            <div class="left">
                <arrow-button color="black" direction="left" @click="back"></arrow-button>
                <h2>{{ project.name }}</h2>
                <q-tag class="status" size="small" variation="warning">{{ $t(`projectStatus.${project.status}`) }}</q-tag>
            </div>
        </div>
        <!-- STEP 1 - SELECT ORGANISATION -->
        <div v-if="step === 1">
            <div class="search">
                <div class="panel">
                    <div class="title">
                        <div>
                            <span class="label">Stap 1</span>
                        </div>
                        <h3>Bepaal de {{ $t(organisationType.toLowerCase()) }}</h3>
                    </div>
                    <p v-if="type === 'branch'" class="mt-s">
                        Kies hieronder de vestiging die dit project uitvoert. Je kan ook de opdrachtnemer vragen dit in te vullen.
                    </p>
                    <p v-else class="mt-s">
                        Kies in deze stap welke {{ $t(organisationType.toLowerCase()) }} je aan je project wilt koppelen. Na het koppelen van een {{ $t(organisationType.toLowerCase()) }} kun je in je project bij de algemene projectgegevens nog een specifieke vestiging selecteren.
                    </p>
                    <div v-if="type === 'main'" class="">
                        <div class="input-label">
                            {{ $t(organisationType) }}
                        </div>
                        <q-input labelPosition="inside" v-model="input" placeholder="Zoek op naam, adres of KvK-nummer" @input="_search">
                            Naam, adres of KvK-nummer
                        </q-input>
                    </div>
                </div>
            </div>
            <div class="results" v-if="input !== '' || type === 'branch'">
                <q-table :columns="getColumns" :data="organisations" width="800px" :loading="loading" :loadingRows="5">
                    <template v-slot:row="{ column, row }">
                        <span class="vertical-center" v-if="column == 'name'">
                            <div class="avatar-container">
                                <q-avatar size="small" :img="row.logo">{{ row.name.charAt(0) }}</q-avatar>
                            </div>
                            <p class="bold">{{ row.name }}</p>
                        </span>
                        <div v-else-if="column === 'action'">
                            <q-button class="edit" variation="ghost" size="small" @click="select(row)"
                                >Kies {{ type === 'branch' ? 'vestiging' : 'organisatie' }}</q-button
                            >
                        </div>
                        <div v-else-if="column === 'source'">
                            <img
                                v-if="row.source === 'kvk'"
                                src="/statics/img/kvk.svg"
                                width="30px"
                            />
                        </div>
                        <div v-else>
                            {{ row[column] }}
                        </div>
                    </template>
                </q-table>
            </div>
            <p v-if="organisations.length > 0 && input !== ''" class="not-found-text mt-m pb-m">
                Kan u een bedrijf niet vinden? Verfijn dan uw zoekterm of zoek op KvK nummer.
            </p>
        </div>

        <!-- STEP 2a ALTERNATIVE: PD MIGRATED PROJECT ON  -->
        <div v-else-if="step === '2a'">
            <div class="search-pd">
                <div class="panel">
                    <div class="title">
                        <div>
                            <span class="label">Stap 2</span>
                        </div>
                        <h3>Hoe wil je de opdrachtgever koppelen?</h3>
                    </div>
                    <div class="chosen mb-m">
                        <h2>{{ chosenOrganisation.name }}</h2>
                    </div>
                    <div class="input">
                        <label for="silent" class="option-panel" style="margin-bottom:10px">
                            <q-radio 
                                id="silent"
                                name="pd-on" 
                                key="silent"
                                label="silent"
                                width="5px"
                                :value="pdInviteClientType"
                                @input="selectInviteClientType('silent')">
                            </q-radio>
                            <p><strong>Alleen opdrachtgever selecteren</strong><br>Selecteer de opdrachtgever. Je hoeft geen contactpersoon te selecteren en deelt geen gegevens. Je kunt later op ieder moment nog je opdrachtgever toevoegen via de projectinstellingen. </p>
                        </label>
                        <q-tooltip :disabled="!selectOnly" position="top" v-project-can="'admin__Value__set_value'">
                            <template #tooltip>
                                <p>Alleen beschikbaar in Better Performance projecten</p>
                            </template>
                            
                            <label for="invite" class="option-panel" :class="{ disabled: selectOnly }">
                                <q-radio 
                                    id="invite"
                                    name="pd-on" 
                                    key="invite"
                                    label="invite"
                                    width="5px"
                                    :value="pdInviteClientType"
                                    :disabled="selectOnly"
                                    @input="selectInviteClientType('invite')">
                                </q-radio>
                                <div>
                                    <p><strong>Nodig opdrachtgever uit</strong><br>Nodig de opdrachtgever uit en selecteer direct je contactpersoon. Hierdoor geef je de opdrachtgever toegang tot beperkte gegevens binnen je project.</p>
                                    <q-dropdown
                                        v-if="searchContactPersonBy === 'registered-users'"
                                        v-model="chosenContactPerson"
                                        :options="organisationUsers"
                                        :disabled="selectOnly"
                                        avatars
                                        placeholder="Selecteer gebruiker"
                                        class="choose-contact-person"
                                        style="width: 280px;"
                                        autocomplete
                                    ></q-dropdown>
                                    <q-input
                                        v-if="searchContactPersonBy === 'email'"
                                        v-model="contactPersonEmail"
                                        placeholder="Voer een e-mailadres in"
                                        class="choose-contact-person"
                                        style="width: 280px;"
                                        >E-mailadres</q-input
                                    >
                                    <p class="search-other" v-if="searchContactPersonBy === 'registered-users'" @click="searchContactPersonBy = 'email'">Of nodig contactpersoon uit via e-mail</p>
                                    <p class="search-other" v-if="searchContactPersonBy === 'email'" @click="searchContactPersonBy = 'registered-users'">Of zoek geregistreerde gebruikers</p>
                                </div> 
                            </label>
                        </q-tooltip>
                    </div>

                    <div class="invite-pd-client-button">
                        <q-button :loading="loadingSave" @click="save">Opdrachtgever {{ pdInviteClientType === 'invite' ? 'uitnodigen' : 'selecteren' }}</q-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- STEP 2 - CHOOSE CONTACTPERSON -->
        <div v-else-if="step === 2">
            <div class="search">
                <div class="panel">
                    <div class="title">
                        <div>
                            <span class="label">Stap 2</span>
                        </div>
                        <h3>Gekozen {{ $t(organisationType.toLowerCase()) }}</h3>
                    </div>
                    <div class="chosen mb-m">
                        <h2>{{ chosenOrganisation.name }}</h2>
                    </div>
                    <div class="input">
                        <div class="input-label">
                            Kies contactpersoon
                        </div>
                        <div v-if="searchContactPersonBy === 'registered-users'">
                            <q-dropdown
                                v-model="chosenContactPerson"
                                :options="organisationUsers"
                                avatars
                                placeholder="Selecteer gebruiker..."
                                class="choose-contact-person"
                                style="width: 280px;"
                                autocomplete
                            ></q-dropdown>

                            <p class="search-other" @click="searchContactPersonBy = 'email'">
                                Of nodig contactpersoon uit via e-mail
                            </p>
                        </div>
                        <div v-else-if="searchContactPersonBy === 'email'">
                            <q-input labelPosition="inside"
                                v-model="contactPersonEmail"
                                placeholder="Voer een e-mailadres in"
                                class="choose-contact-person"
                                style="width: 280px;"
                                >E-mailadres</q-input
                            >

                            <p
                                class="search-other"
                                @click="searchContactPersonBy = 'registered-users'"
                                v-if="canSearchByRegisteredUsers"
                            >
                                Of zoek geregistreerde gebruikers
                            </p>
                        </div>

                        <div class="select mt-m">
                            <p class="mb-m" style="width: 130%;">
                                Als dit de correcte opdrachtnemer is, klik hieronder op Opdrachtnemer koppelen om de
                                opdrachtnemer te informeren. Let op, de contactpersoon krijgt hier van bericht en het
                                project is direct inzichtelijk voor {{ chosenOrganisation.name }}.
                            </p>

                            <div class="buttons">
                                <q-button :loading="loadingSave" @click="save">{{ $t(organisationType) }} koppelen</q-button>
                                <q-button @click="changeOrganisation" variation="blank" class="cancel"
                                    >Ik heb me vergist, opnieuw opzoeken</q-button
                                >
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

import {
    KVK_COMPANIES,
    ORGANISATIONS_MINIMAL,
    GET_ORGANISATION_USERS,
    GET_ORGANISATION_BRANCHES
} from '../../../graphql/queries';
import { validateEmail } from '../../../assets/js/utils';



import ArrowButton from '@/components/ArrowButton';
import gql from 'graphql-tag';

export default {
    name: 'q-select-organisation-crow',
    components: {
        'arrow-button': ArrowButton
    },
    props: {
        indicator: {
            type: Object,
            required: true
        },
        isPdProject: {
            type: Boolean,
            required: false,
            default: () => false
        },
        type: {
            type: String,
            required: true
        },
        organisationType: {
            type: String,
            required: true
        },
        indicator: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            project: this.$parent.$parent.project,
            input: '',
            branches: [],
            kvkOrganisations: [],
            qfactOrganisations: [],
            mainColumns: [
                {
                    field: 'name',
                    label: 'Organisatie',
                    width: '100%'
                },
                {
                    field: 'kvk',
                    label: 'KvK',
                    width: '140px'
                },
                {
                    field: 'source',
                    label: '',
                    width: '60px'
                },
                {
                    field: 'action',
                    label: '',
                    width: '120px',
                    align: 'right'
                }
            ],
            branchColumns: [
                {
                    field: 'name',
                    label: 'Vestiging',
                    width: '260px'
                },
                {
                    field: 'address',
                    label: 'Adres',
                    width: '160px'
                },
                {
                    field: 'zip',
                    label: 'Postcode',
                    width: '120px'
                },
                {
                    field: 'city',
                    label: 'Plaats'
                },
                {
                    field: 'action',
                    label: '',
                    width: '120px',
                    align: 'right'
                }
            ],
            loading: true,
            chosenOrganisation: null,
            chosenContactPerson: null,
            organisationUsers: [],
            searchContactPersonBy: 'registered-users',
            contactPersonEmail: '',
            canSearchByRegisteredUsers: false,
            pdInviteClientType: 'silent',
            loadingSave: false,
            selectOnly: false
        };
    },
    methods: {
        selectInviteClientType(type) {
            this.pdInviteClientType = type;
        },
        save() {
            const isFromKvk = this.chosenOrganisation && this.chosenOrganisation.kvk && !this.chosenOrganisation.id;

            if (this.isPdProject) {
                const silentInvite = this.pdInviteClientType === 'silent';

                if (!silentInvite) {
                    if (this.searchContactPersonBy === 'email' && !validateEmail(this.contactPersonEmail)) {
                        this.$store.commit('notify', { type: 'danger', message: 'E-mail is niet valide' });
                        return;
                    } else if (this.searchContactPersonBy === 'registered-users' && !this.chosenContactPerson) {
                        this.$store.commit('notify', { type: 'danger', message: 'Kies een contact persoon' });
                        return;
                    }
                }

                if (isFromKvk) {

                    let email = this.contactPersonEmail;

                    if (silentInvite)
                        email = 'helpdesk@qfact.nl'

                    this.loadingSave = true;
                    this.$emit('saveFromKvk', this.chosenOrganisation, email);
                } else {
                    if (!silentInvite) {
                        if (this.searchContactPersonBy === 'registered-users')
                            this.$emit('saveContactPerson', this.chosenContactPerson, undefined, this.chosenOrganisation.id);
                        else if (this.searchContactPersonBy === 'email')
                            this.$emit('saveContactPerson', undefined, this.contactPersonEmail, this.chosenOrganisation.id);
                    }

                    this.loadingSave = true;
                    const isPrivate = this.isPdProject ? this.pdInviteClientType === 'silent' : false;
                    this.$emit('saveOrganisation', this.chosenOrganisation.id, isPrivate);
                }

            } else {
                if (this.searchContactPersonBy === 'email' && !validateEmail(this.contactPersonEmail)) {
                    this.$store.commit('notify', { type: 'danger', message: 'E-mail is niet valide' });
                    return;
                } else if (this.searchContactPersonBy === 'registered-users' && !this.chosenContactPerson) {
                    this.$store.commit('notify', { type: 'danger', message: 'Kies een contact persoon' });
                    return;
                }
                
                if (isFromKvk) {
                    this.loadingSave = true;
                    this.$emit('saveFromKvk', this.chosenOrganisation, this.contactPersonEmail);
                } else {
                    if (this.searchContactPersonBy === 'registered-users')
                        this.$emit('saveContactPerson', this.chosenContactPerson, undefined, this.chosenOrganisation.id);
                    else if (this.searchContactPersonBy === 'email')
                        this.$emit('saveContactPerson', undefined, this.contactPersonEmail, this.chosenOrganisation.id);

                    this.loadingSave = true;
                    this.$emit('saveOrganisation', this.chosenOrganisation.id);
                }
            }
        },
        _search: _.debounce(function() {
            return this.search();
        }, 500),
        search() {
            switch (this.type) {
                case 'main':
                    this.getMainOrganisations();
                    break;
                case 'branch':
                    this.getBranchOrganisations();
            }
        },
        select(organisation) {
            if (this.type === 'branch') {
                this.$emit('saveOrganisation', organisation.id);
            } else {
                this.chosenOrganisation = organisation;
                this.getOrganisationUsers(organisation.id);
            }
        },
        formatKvkOrganisations(items) {
            const mainBranches = items.filter(item => item.isMainBranch == true);

            return mainBranches.map(branch => {
                return {
                    id: branch.mainExists.id,
                    name: branch.tradeNames.businessName,
                    kvk: branch.kvkNumber,
                    address: `${branch.addresses[0].street} ${branch.addresses[0].houseNumber}`,
                    code: branch.addresses[0].postalCode,
                    city: branch.addresses[0].city,
                    source: 'kvk'
                };
            });
        },
        setQfactOrganisations(organisations) {
            this.qfactOrganisations = organisations.map(org => {
                const { city } = org.childs[0] && org.childs[0].address ? org.childs[0].address : { city: '' };
                return {
                    id: org.id,
                    logo: org.logo,
                    name: org.name,
                    kvk: org.legal ? org.legal.registrationId : '',
                    city: org.childs[0] ? org.childs[0].address.city : '',
                    source: 'qfact'
                };
            });
        },
        async fetchKvkCompanies(term) {
            try {
                return await this.$apollo.query({
                query: KVK_COMPANIES,
                variables: {
                    term,
                    includeBranches: false
                }, 
                fetchPolicy: 'no-cache'
                })
                .then(response => {
                    return response.data.kvk_companies;
                });
            } catch (error) {
                console.log(error)
            }
        },
        async getMainOrganisations() {
            this.loading = true;
            const notMyOrganisationType = this.indicator.masterId
            try {
                const qfactOrganisations = await this.$apollo
                    .query({
                        query: ORGANISATIONS_MINIMAL,
                        variables: {
                            where: {
                                AND: [
                                    {
                                        OR: [{ name__contains: this.input }, { legal_registrationId__contains: this.input }]
                                    },
                                    { type: 'main' },
                                    { products__some: { slug__contains: notMyOrganisationType } }
                                ]
                            },
                            caseSensitive: false
                        }, 
                        fetchPolicy: 'no-cache'
                    })
                    .then(response => response.data.organisations);

                this.setQfactOrganisations(qfactOrganisations);
                const qfactKvkNumbers = qfactOrganisations.map(org => org.legal ? org.legal.registrationId : null).filter(org => org !== null);

                const kvkCompanies = await this.fetchKvkCompanies(this.input);
                const uniqueKvkCompanies = this.removeDuplicates(kvkCompanies);
                const filteredCompanies = uniqueKvkCompanies.filter(company => !qfactKvkNumbers.includes(company.kvkNumber));
                
                this.kvkOrganisations = this.formatKvkOrganisations(filteredCompanies);

                this.loading = false;
            } catch(error) {
                console.error(error);
                this.loading = false;
            }
        },
        getBranchOrganisations() {
            this.loading = true;
            const masterId = 'contractor-branch';
            const contractorId = masterId === 'contractor-branch' ? this.$parent.$parent.contractorReference.answer : null;
            const clientId = masterId === 'client-branch' ? this.$parent.$parent.clientReference.answer : null;

            if (!clientId && !contractorId) return console.error('no contractor or client id set');

            const organisationId = contractorId ? contractorId : clientId;

            this.$apollo
                .query({
                    query: GET_ORGANISATION_BRANCHES,
                    variables: { organisationId }, 
                    fetchPolicy: 'no-cache'
                })
                .then(response => {
                    this.setBranchOrganisations(response.data.organisation);
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        setBranchOrganisations(organisation) {
            this.branches = organisation.childs.map(branch => {
                return {
                    id: branch.id,
                    logo: organisation.logo,
                    name: branch.name,
                    address: branch.address.line1 ? branch.address.line1.replace(/null/g, "") : branch.address.line1,
                    zip: branch.address.zip ? branch.address.zip.replace(/null/g, "") : branch.address.zip,
                    city: branch.address.city ? branch.address.city.replace(/null/g, "") : branch.address.city,
                    source: 'qfact'
                };
            });
        },
        back() {
            this.$parent.cancel();
        },
        getOrganisationUsers(organisationId) {
            if (!organisationId) {
                this.searchContactPersonBy = 'email';
                this.canSearchByRegisteredUsers = false;
                return;
            } else {
                this.canSearchByRegisteredUsers = true;
                this.searchContactPersonBy = 'registered-users';
            }
            this.$apollo
                .query({
                    query: GET_ORGANISATION_USERS,
                    variables: { organisationId }
                })
                .then(result => {
                    if (!result.data.organisation) return;

                    this.organisationUsers = result.data.organisation.users
                        .filter(user => user.status === 'active')
                        .map(user => {
                            return {
                                value: user.user.id,
                                label: `${user.user.firstName} ${user.user.lastName}`,
                                avatar: user.user.avatar,
                                avatarFallback:
                                    user.user.firstName && user.user.lastName
                                        ? `${user.user.firstName.charAt(0)}${user.user.lastName.charAt(0)}`
                                        : '?'
                            };
                        });
                })
                .catch(err => {});
        },
        changeOrganisation() {
            this.chosenOrganisation = null;
            this.chosenContactPerson = null;
            this.contactPersonEmail = '';
            this.searchContactPersonBy = 'registered-users';
        },
        removeDuplicates(organisations) {
            let uniqueOrganisations = [];
            
            
            if (typeof organisations === 'object' && organisations.length > 0) {
                organisations.forEach(organisation => {
                    if (!uniqueOrganisations.find(org => org.kvkNumber === organisation.kvkNumber)) {
                        uniqueOrganisations.push(organisation);
                    }
                });
            }

            return uniqueOrganisations
        }
    },
    computed: {
        step: function() {
            const isInitiator = this.project.organisationId === this.$store.getters.getCurrentOrganisation.id;
            this.selectOnly = isInitiator && (!this.project.usesCrowFlow || !this.$store.getters.hasActiveQfactProducts);
            if (this.chosenOrganisation && this.isPdProject) return '2a';
            else if (this.chosenOrganisation) return 2;
            else return 1;
        },
        getColumns: function() {
            switch (this.type) {
                case 'main':
                    return this.mainColumns;
                    break;
                case 'branch':
                    return this.branchColumns;
                    break;
                default:
                    break;
            }
        },
        organisations() {
            return this.type === 'main' ? [...this.qfactOrganisations, ...this.kvkOrganisations] : this.branches;
        }
    },
    created() {
        if (this.type === 'branch') this.search();
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.radio {
    margin-top: 4px;
}

.select {
    width: 115%;

    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cancel {
        margin-left: 24px;
    }
}

.search-other {
    color: $color-grey-7;
    cursor: pointer;
    text-decoration: underline;
}

.choose-contact-person {
    width: 60%;
    padding: 10px 0;
}

.chosen {
    h2 {
        font-weight: 500;
        font-size: 23px;
        line-height: 23px;
        margin: 20px 0;
    }
}

.head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .status {
            margin-left: 10px;
        }

        h2 {
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
        }
    }
}

.results {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    & .edit {
        justify-content: right !important;
    }
}

.input-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
}

.search {
    display: flex;
    justify-content: center;

    margin-top: 150px;

    .panel {
        width: 460px;

        .title {
            display: flex;
            align-items: center;
            margin-left: -64px;

            h3 {
                margin-left: 24px;
            }
        }
    }
}

.search-pd {
    display: flex;
    justify-content: center;
    align-items: center;

    .panel {
        width: 460px;

        .title {
            display: flex;
            align-items: center;
            margin-left: -64px;

            h3 {
                margin-left: 24px;
            }
        }
    }
}

.label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    background: #f2f2f2;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: $color-grey-7;
    padding: 4px;
}

.q-select-organisation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 1px;

    z-index: 20;
    background-color: white;
}

h3 {
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
}

.not-found-text {
    text-align: center;
    font-weight: 500;
    color: #aeb5bb;
}

.option-panel {
    background: #F5F5F5;
    border-radius: 3px;
    padding: 15px;
    display: flex;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}
.invite-pd-client-button {
    width: 100%;
    margin: 93px 0 0 0;

    display: flex;
    justify-content: flex-end;
}
</style>

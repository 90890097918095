<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:1.41421;"
  >
    <circle cx="12" cy="12" r="11" :stroke="color" fill="transparent" stroke-width="2"/>
    <path d="M17.7255 16.9607C18.2566 17.1123 18.81 16.8047 18.9616 16.2736C19.1132 15.7425 18.8056 15.1891 18.2745 15.0375L17.7255 16.9607ZM7.05473 15.1674C6.59521 14.861 5.97434 14.9852 5.66797 15.4447C5.3616 15.9042 5.48575 16.5251 5.94527 16.8315L7.05473 15.1674ZM18.2745 15.0375C17.1804 14.7252 16.124 14.8166 15.1533 15.021C14.2415 15.2131 13.1971 15.5622 12.3673 15.7835C10.6093 16.2523 8.95563 16.4348 7.05473 15.1674L5.94527 16.8315C8.54437 18.5643 10.8907 18.2471 12.8827 17.7159C13.9279 17.4372 14.696 17.1612 15.5655 16.9781C16.376 16.8074 17.0696 16.7735 17.7255 16.9607L18.2745 15.0375Z" :fill="color"/>
    <circle cx="8.625" cy="9.375" r="1" :fill="color" stroke="#FD7E14" stroke-width="1.75"/>
    <circle cx="15.375" cy="9.375" r="1" :fill="color" stroke="#FD7E14" stroke-width="1.75"/>
  </svg>
</template>

<script>
export default {
  name: "NeutralEmoji",
  props: {
    color: {
      type: String,
      default: "#FD7E14",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>

<style></style>

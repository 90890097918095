<template>
    <svg :width="width" :height="height" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 1C6 0.447715 6.44772 0 7 0H10C10.5523 0 11 0.447715 11 1V1.5H12L14.5 1.5C15.3284 1.5 16 2.17157 16 3V6.08203L17.1357 4.49866C17.2966 4.27427 17.609 4.22284 17.8334 4.38378L17.9959 4.50034L18.9882 3.11682C19.1492 2.89243 19.4615 2.841 19.6859 3.00195L20.8229 3.81747C21.0473 3.97842 21.0988 4.29079 20.9378 4.51519L19.9455 5.8987L20.1074 6.01484C20.3318 6.17578 20.3832 6.48816 20.2223 6.71255L16 12.5993L16 19C16 19.8284 15.3284 20.5 14.5 20.5H2C1.17157 20.5 0.5 19.8284 0.5 19V3C0.5 2.17157 1.17157 1.5 2 1.5H5H6V1ZM15 13.9565C14.9428 14.0002 14.8764 14.0315 14.805 14.0475L13.4084 14.3595C13.3978 14.3618 13.3872 14.3639 13.3765 14.3655L12.9839 14.9128C12.9071 15.02 12.791 15.0924 12.661 15.1144L12.1859 15.1947C12.0545 15.2169 11.9196 15.1857 11.8112 15.108C11.7029 15.0303 11.63 14.9125 11.6089 14.7808L11.5327 14.3051C11.5118 14.1749 11.5432 14.0417 11.6201 13.9346L12.0305 13.3623L12.0301 13.3569L11.9234 11.9667C11.9144 11.8494 11.9471 11.7326 12.0157 11.637L15 7.47624V3C15 2.72386 14.7761 2.5 14.5 2.5H13.4146C13.4699 2.65639 13.5 2.82468 13.5 3V5C13.5 5.82843 12.8284 6.5 12 6.5H5C4.17157 6.5 3.5 5.82843 3.5 5V3C3.5 2.82468 3.53008 2.65639 3.58535 2.5H2C1.72386 2.5 1.5 2.72386 1.5 3L1.5 19C1.5 19.2761 1.72386 19.5 2 19.5H14.5C14.7761 19.5 15 19.2761 15 19L15 13.9565ZM5 2.5H12C12.2761 2.5 12.5 2.72386 12.5 3V5C12.5 5.27614 12.2761 5.5 12 5.5H5C4.72386 5.5 4.5 5.27614 4.5 5V3C4.5 2.72386 4.72386 2.5 5 2.5ZM19.8338 4.33865L19.1329 5.31586L18.8085 5.08317L19.5094 4.10595L19.8338 4.33865ZM13.4089 13.3347L14.4009 13.1131L19.1183 6.53601L17.6568 5.48779L12.9344 12.0718L13.0094 13.0481L13.4089 13.3347Z"
            fill="url(#paint0_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="0.5"
                y1="20.6005"
                x2="21.032"
                y2="20.6005"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#672583" />
                <stop offset="0.505208" stop-color="#006B96" />
                <stop offset="1" stop-color="#00A1AE" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'FormGradient',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 22,
        },
        height: {
            type: [Number, String],
            default: 21,
        },
    },
};
</script>

<style></style>

<template>
  <label :class="['switch']">
    <input
      :id="id"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :checked="value"
      @input="handleInput"
      :class="['switch2', state, variation, checked]"
    />
    <i></i>
    <slot />
  </label>
</template>

<script>
export default {
  name: "q-switch",
  status: "prototype",
  release: "0.0.1",
  props: {
    /**
     * Manually trigger various states of switch.
     * `hover, disabled`
     */
    state: {
      type: String,
      default: null,
      validator: (value) => {
        return value.match(/(hover|disabled)/);
      },
    },

    value: {
      type: [String, Number, Boolean],
      default: false,
    },
    name: {
      type: String,
    },
    /**
     * Manually check the switch.
     */
    checked: {
      type: String,
      default: null,
    },
    /**
     * Style variation to give additional meaning.
     * `primary, secondary, ghost`
     */
    variation: {
      type: String,
      default: "primary",
      validator: (value) => {
        return value.match(/(primary|secondary|ghost)/);
      },
    },
    /**
     * Manually disable switch. Boolean, defaults to false.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput(event) {
      if (this.disabled) {
        return false;
      }

      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.switch i {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 21px;
  background-color: $color-grey-5;
  border-radius: 12px;
  transition: all 0.3s linear;

  &:hover,
  &.hover {
    background: $color-grey-5;
  }
}

.switch i::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  width: 34px;
  height: 19px;
  background-color: #fff;
  border-radius: 10px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.switch i::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 17px;
  height: 17px;
  background-color: $color-grey-3;
  border-radius: 10px;
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;

  &:hover,
  &.hover {
    color: $color-grey-5;
  }
}
.switch:active i::after {
  width: 19px;
  transform: translate3d(2px, 2px, 2px);
}

.switch:active input:checked + i::after {
  transform: translate3d(15px, 2px, 2px);
}

.switch2 {
  display: none;
}

.switch2:checked + i {
  background-color: $color-primary;
  
}

.switch2:disabled + i, .disabled {
      background-color: #79c5cb;
  }

.switch2:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.switch2:checked + i::after {
  background-color: #fff;
  transform: translate3d(17px, 2px, 1px);
}
</style>

<docs>
  ```jsx
  <div>
    <q-switch> </q-switch> default
    
  </div>
  ```
</docs>

<template>
  <div :class="['q-datepicker', variation]">
      <input ref="input" class="input" type="date" v-model="date" @input="input">
  </div>
</template>

<script>
export default {
    name: 'q-datepicker',
    data() {
        return {
            date: null
        }
    },
    props: {
        /**
         * v-model is a timestamp
         */
        value: {
            required: true
        },
        /**
         * Style variation to give additional meaning.
         * `primary, blank`
         */
        variation: {
            type: String,
            default: 'primary',
            validator: value => {
                return value.match(/(primary|blank)/);
            }
        }
    },
    methods: {
        /**
         * The HTML returns the date as a string: "01-01-2020".
         * This string is converted into a timestamp and returned.
         */
        input(val) {
            let date = new Date(val.target.value);
            let timestamp = date.getTime();
            
            this.$emit('input', timestamp)
        }
    },
    mounted() {
        let date = formatDate(this.value);
        this.$refs.input.value = date
    }
}

const formatDate = (date) => {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.input {
    @include reset;
    will-change: transform;
    transition: all 0.2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-text;
    line-height: $line-height-m;
    text-decoration: none;

    position: relative;
    color: $color-grey-7;
    align-items: center;
    justify-content: center;

    border-radius: $radius-default;
    background: white;
    cursor: text;
    border: 1px solid $color-grey-5;
    width: 100%;

    &.medium {
        padding: 12px;
        padding-left: 8px;
        font-size: $size-m;
    }
    &.small {
        padding: 9px 12px;
        padding-left: 8px;
        &::placeholder {
            font-size: $size-s;
        }
    }

    &::placeholder,
    &.placeholder {
        color: $color-grey-7;
        font-size: $size-m;
    }

    &:focus,
    &.focus {
        background: inherit;
        border: 1px solid $color-primary;
        box-shadow: none;
        outline: 0;
    }

    &:hover,
    &.hover {
        border: 1px solid $color-primary;
        transition: border 0.3 ease-in;
    }

    &.error {
        border: 2px solid $color-red;
    }

    &.blank {
        border: none;
        color: $color-grey-7;
        background-color: lighten($color-grey-3, 7%);
        padding: 4px 12px;
        padding-left: 8px;
        &:not(:placeholder-shown) {
            &.medium {
                padding: 12px;
                padding-left: 8px;
            }

            &.small {
                padding: 4px 12px;
                padding-left: 8px;
                &::placeholder {
                    font-size: $size-s;
                }
            }
        }
        &.error {
            background-color: #ffe0e0 !important;
        }
    }
}

.blank > .icon_divmedium {
    right: 8px !important;
    margin-top: 1px;
}

input {
    padding: 0 10px;
    position: relative;
    border: 1px solid #e5e5e5;
    background: none;
    border-radius: 4px;
    font-family: $font-text;

    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.q-datepicker {
    font-weight: $weight-normal;
    font-size: 11px;
    font-family: $font-text;
    position: relative;
    padding: 10px 0;
    width: 100%;
    &.small {
        font-size: 9px;
    }

    input:not(:placeholder-shown) + label {
        position: absolute;
        top: 12px;
        opacity: 0.5;
        left: 9px;
        padding-top: 5px;
        padding-bottom: 7px;
        transition: top 0.2s ease-in-out;
    }

    input:not(:placeholder-shown) {
        &.medium {
            padding-top: 18px;
            padding-bottom: 6px;
        }

        &.small {
            padding-top: 16px;
            padding-bottom: 2px;
            bottom: 0px;
        }
    }

    input:placeholder-shown + label {
        position: absolute;
        left: -9999px;
        top: 20px;
        opacity: 0;
        padding: 0;
    }

    .icon_divmedium {
        position: absolute;
        top: calc(-8px + 50%);
        right: 15px;
    }

    .errorIcon {
        color: $color-red;
    }

    .successIcon {
        margin-top: 2px;
        color: $color-green;
    }
}

.tooltip .tooltiptext{
  background-color: red;
}

</style>
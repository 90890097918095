<template>
    <div :class="['q-dropdown', variation, disabled ? 'disabled' : '']" @keydown.tab.prevent>
        <label>
            <p><slot /></p>
        </label>

        <div :class="['dropdown']" :id="id">
            <input
                type="select"
                :disabled="disabled || options.length === 0"
                :class="[
                    'input',   
                    size, 
                    panelOpened ? 'with-panel' : '', 
                    avatars ? 'avatar-input' : '', 
                    noBorder ? 'no-border' : '',
                    dropUp ? 'drop-up' : 'drop-down',
                    { loading, 'show-placeholder': !getValueLabel }
                ]"
                @click="togglePanel"
                :placeholder="computedPlaceholder"
                readonly
                data-testid="q-dropdown"
            />
            <div v-if="!loading && currentOption && !multiple && !autocompleteQuery" class="input-value">
                <p>{{  currentOption.label }}</p>
                <q-tag v-if="currentOption.tag" class="option-tag" size="small" variation="default">{{ currentOption.tag }}</q-tag>
            </div>
            <div v-else-if="!loading && value && multiple && !autocompleteQuery" class="input-value">
                <p>{{  getValueLabel }}</p>
            </div>
            <div class="loader-wrapper" v-if="loading"><div class="loader"></div></div>

            <input
                :id="`autocomplete-${id}`"
                v-if="autocomplete && panelOpened"
                type="text"
                v-model="autocompleteQuery"
                :class="[
                    'input', 
                    'autocomplete', size,
                    avatars ? 'avatar-input' : ''
                ]"
                :placeholder="computedPlaceholder"
                @keyup.enter="autocompleteSelect"
            />

            <div v-if="avatars && !multiple" class="selected-avatar">
                <q-avatar :size="size === 'small' ? 'xsmall' : 'small'" :img="currentOption.avatar">{{
                    currentOption.avatarFallback
                }}</q-avatar>
            </div>

            <Portal to="dropdown" v-if="panelOpened">
                <div
                    :class="['dropdown-panel', 'panel-' + size, dropUp ? 'drop-up' : 'drop-down', noBorder ? 'no-border' : '']"
                    :id="`q-dropdown-panel-${this.id}`"
                    :style="`
                        --position-left: ${coordinates.left}px; 
                        --position-top: ${coordinates.top}px;
                        --element-width: ${coordinates.width}px;
                        --element-height: ${coordinates.height}px;
                        --panel-height: ${panelHeight}px;
                    `"
                >
                <div class="dropdown-options-wrapper">
                    <div
                        v-for="(option, key) in dropdownOptions"
                        :key="key"
                        :class="[
                            'q-option',
                            'dropdown-option',
                            option.selected ? 'selected' : '',
                            option.isNullValue ? 'null-value' : '',
                            { disabled: option.disabled }
                        ]"
                        @click="select(option)"
                        :id="`dropdown-option-${option.value}`"
                        tabindex="0"
                        @keydown.tab.prevent
                        @keydown.enter="select(option, true)"
                    >
                        <q-tooltip 
                            class="option-wrapper" 
                            :class="[
                                'option-' + size,
                                { 'has-avatar': avatars }
                            ]" 
                            :disabled="!option.tooltip" 
                            position="top"
                        >
                            <template v-slot:tooltip>
                                <p v-html="option.tooltip"></p>
                            </template>
                            <div class="vertical-center">
                                <div class="avatar-wrapper">
                                    <q-avatar
                                        v-if="avatars"
                                        :size="size === 'small' ? 'xsmall' : 'small'"
                                        class="avatar"
                                        :img="option.avatar"
                                        >{{ option.avatarFallback }}</q-avatar
                                    >
                                </div>
                                <span>{{ option.label }}</span>
                                <q-tag v-if="option.tag" class="option-tag" size="small" variation="default">{{ option.tag }}</q-tag>
                            </div>
                        </q-tooltip>
                    </div>
                </div>
                </div>
            </Portal>


            <div :class="['arrow', 'icon-' + size, panelOpened ? 'up' : '']">
                <q-icon
                    type="chevronDown"
                    :color="iconColor"
                    style="width: 14px; height: 14px"
                    :width="iconSize"
                    :height="iconSize"
                />
            </div>
        </div>
    </div>
</template>

<script>
/*
 *      Dropdown
 * 
 *      If the dropdown is inside a scrollable container and doesn't update
 *      on scroll, update the panel drop direction (up or down) with the 
 *      updateDropdownPanels(this) function, accessable in utils.js,
 *      when this container is being scrolled (add an eventListener 
 *      for scroll on this container, no debounce needed)
 */

import _ from 'lodash';
import QuestionRadioTestWithSatisfied from './questions/tests/QuestionRadioTestWithSatisfied.vue';

export default {
    name: 'q-dropdown',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         * Value prop, used for v-model
         */
        value: {},
        /**
         * Options returns 'value' property into v-model. display 'label' property
         */
        options: {
            type: Array,
            required: true,
        },
        /**
         * Options returns 'value' property into v-model. display 'label' property
         */
        avatars: {
            type: Boolean,
            required: false,
        },
        /**
         * Disables inputfield
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Style variation to give additional meaning.
         * `primary, blank`
         */
        variation: {
            type: String,
            default: 'primary',
            validator: (value) => {
                return value.match(/(primary|blank)/);
            },
        },
        /**
         * The size of the input. Defaults to medium.
         * `small, medium`
         */
        size: {
            type: String,
            default: 'medium',
            validator: (value) => {
                return value.match(/(small|medium)/);
            },
        },
        /**
         * Placeholder shown in inputfield when no value has been selected
         */
        placeholder: {
            type: String,
        },
        /**
         * Boolean for if autocomplete should be enabled
         */
        autocomplete: {
            type: Boolean,
            default: false,
        },
        /**
         * Boolean for if there should be a 'null' option added to the optionsarrray
         */
        addNullValue: {
            type: Boolean,
            default: false,
        },
        /**
         * Label for null value
         */
        nullLabel: {
            type: String,
            default: 'None',
        },
        /**
         * Boolean for if you can select multiple options
         */
        multiple: {
            type: Boolean,
        },
        /**
         * Boolean for if you want a dropdown with no border
         */
        noBorder: {
            type: Boolean,
            default: false,
        },
        /**
         * Boolean if the dropdown should be shown already opened
         */
        autofocus: {
            type: Boolean,
            default: false,
        },
        /**
         * Boolean if the dropdown options are loading
         */
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            panelOpened: false,
            autocompleteQuery: '',
            answers: [],
            dropUp: false,
            dropdownId: '',
            coordinates: {
                left: 0,
                top: 0,
                width: 0
            },
            panelHeight: 0,
            focusedOptionIndex: -1
        };
    },
    methods: {
        /**
         * If the value (v-model) given on initialize does NOT exist in the options: Set it to null.
         */
        validateInput() {
            if(!this.value) return
            
            if (this.multiple) {
                this.answers = this.value;

                if (!this.answers) {
                    this.answers = [];
                }
            } else if(this.options.length > 0) {
                const valid = this.options.map((option) => option.value).includes(this.value);

                if (!valid) this.$emit('input', null);
            }
        },
        select(val, autofocus = false) {
            if(val.disabled) return 

            if (this.multiple && val.value !== null) {
                if (this.answers.includes(val.value)) this.answers.splice(this.answers.indexOf(val.value), 1);
                else this.answers.push(val.value);
                if (this.autocomplete && !autofocus) {
                    setTimeout(() => {
                        const input = document.getElementById(`autocomplete-${this.id}`);
                        input.focus();
                    }, 200);
                }
                this.$emit('input', this.answers);
                this.autocompleteQuery = '';
            } else {
                this.answers = [];
                this.$emit('input', val.value);
                this.closePanel();
            }

            const selectedIndex = this.dropdownOptions.findIndex(option => option.value === val.value);
            this.focusedOptionIndex = selectedIndex;
            this.autocompleteQuery = '';
        },
        autocompleteSelect() {
            const validOptions = this.dropdownOptions.filter((option) => option.value !== null);
            if (validOptions.length === 0) return;
            this.select(validOptions[0]);
        },
        togglePanel() {
            if (this.loading || this.disabled || this.options.length === 0) return;
            if (this.panelOpened) this.closePanel();
            else this.openPanel();
        },
        async openPanel() {
            if (this.disabled || this.options.length === 0) return

            this.panelOpened = true;
            this.setPanelHeight();

            const element = document.getElementById(this.id);
            this.setCoordinates(element);

            let v = this;
            if (this.$store) {
                this.$nextTick(() => {
                    this.$store.commit('addBodyListener', [
                        `q-dropdown-panel-${this.id}`,
                        () => {
                            v.closePanel();
                        },
                    ]);
                })
            }
            if (this.autocomplete) {
                this.autocompleteQuery = '';
                this.$nextTick(() => {
                    const input = document.getElementById(`autocomplete-${this.id}`);
                    input?.focus();
                });
            }

            this.addEventListener();
            this.$nextTick(() => {
                this.recalculatePanelPosition(true);
            })
        },
        optionSelected(option) {
            if (this.multiple) return this.answers && this.answers.includes(option.value);
            else return this.value === option.value;
        },
        closePanel() {
            this.panelOpened = false;
            this.$emit('blur');
            this.$store.commit('resetBodyListeners');
            this.removeEventListener();
        },
        _recalculatePanelPosition: _.debounce(function() {
            this.recalculatePanelPosition();
        }, 100),
        recalculatePanelPosition(forceRecalculate = false) {
            if(!this.panelOpened && !forceRecalculate) return

            const element = document.getElementById(this.id);
            this.setCoordinates(element);
            
            const windowHeight = window.innerHeight;
            const instOffsetWithMenu = this.panelHeight + this.coordinates.top;
            this.dropUp = windowHeight < instOffsetWithMenu;
        },
        _setCoordinates: _.debounce(function(element) {
            this.setCoordinates(element);
        }, 50),
        setCoordinates(element) {
            if(!element) return

            const { width, height, left, top } = element.getBoundingClientRect();
            this.coordinates.width = width;
            this.coordinates.height = height;
            this.coordinates.left = left;
            this.coordinates.top = top + height;
        },
        setPanelHeight() {
            this.$nextTick(() => {
                const element = document.getElementById(`q-dropdown-panel-${this.id}`);
                if(!element) return
                this.panelHeight = element.getBoundingClientRect().height;
            })
        },
        focusOnIndex(index) {
            this.$nextTick(() => {
                const option = document.getElementById(`dropdown-option-${this.dropdownOptions[index].value}`);
                option.focus();
                this.focusedOptionIndex = index;
            });
        },
        handleKeydown(event) {
            if(event.key === 'Tab') {
                if(this.focusedOptionIndex < this.dropdownOptions.length - 1) this.focusOnIndex(this.focusedOptionIndex + 1);
                else this.focusOnIndex(0);
            } else if(['Escape'].includes(event.key)) {
                this.closePanel();
            }
        },
        addEventListener() {
            this.focusedOptionIndex = -1;
            window.addEventListener('keydown', this.handleKeydown);
        },
        removeEventListener() {
            window.removeEventListener('keydown', this.handleKeydown);
        },
        DOMChangeObserver() {
            var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

            return function( callback ) {
                if( MutationObserver ){
                    // define a new observer
                    var mutationObserver = new MutationObserver(callback)

                    // have the observer observe for changes in children
                    mutationObserver.observe( document, { childList:true, subtree:true })
                    return mutationObserver
                }
                
                // browser support fallback
                else if( document.addEventListener ){
                    document.addEventListener('DOMNodeInserted', callback, false)
                    document.addEventListener('DOMNodeRemoved', callback, false)
                }
            }
        }
    },
    computed: {
        iconWidth() {
            return this.variation === 'blank' ? 16 : 20;
        },
        iconHeight() {
            return this.variation === 'blank' ? 16 : 20;
        },
        iconColor() {
            return this.panelOpened ? '#00a1ae' : '#495057';
        },
        /**
         * Get the active label for the current value
         */
        getValueLabel() {
            if (this.options.length === 0) {
                return;
            } else if (this.multiple) {
                if (!this.answers || this.answers.length === 0) return '';
                const answered = this.answers.map((value) => {
                    return this.options.find((option) => option.value === value).label;
                });

                return answered.join(', ');
            } else {
                const index = this.options.map((option) => option.value).indexOf(this.value);
                if (index > -1) return this.options[index].label;
                else return '';
            }
        },
        currentOption() {
            if (this.multiple || this.options.length === 0) {
                return {};
            } else {
                const index = this.options.map((option) => option.value).indexOf(this.value);
                if (index > -1) return this.options[index];
                else return {};
            }
        },
        iconSize() {
            return this.size === 'small' ? '18' : '18';
        },
        dropdownOptions() {
            let options = this.options;
            if (this.autocomplete && this.autocompleteQuery.length > 0) {
                options = this.options.filter((option) =>
                    option.label.toLowerCase().includes(this.autocompleteQuery.toLowerCase())
                );
            }
            if(this.addNullValue && this.autocompleteQuery.length === 0) options = [{ label: this.nullLabel, value: null, isNullValue: true }, ...options];

            options = options.map(option => {
                option.selected = this.optionSelected(option);
                return option
            })

            return options
        },
        id() {
            return `q-dropdown-${this.dropdownId}`;
        },
        computedPlaceholder: function() {
            if(this.loading) return ''
            if(this.options.length === 0) return 'Geen opties beschikbaar'
            if(this.autocomplete && this.multiple && this.answers.length > 0) return this.getValueLabel
            return this.placeholder
        }
    },
    created() {
        this.dropdownId = Math.floor(Math.random() * 1000000000);
    },
    async mounted() {
        this.validateInput();

        const element = document.getElementById(this.id);
        this.$root.$on('scroll', () => {
            if(!this.panelOpened) return
            this.setCoordinates(element);
        });

        window.addEventListener('transitionend', this._recalculatePanelPosition);

        this.recalculatePanelPosition(true);
        this.$root.$on('recalculatePanelPosition', forceRecalculate => {
            this.recalculatePanelPosition(forceRecalculate)
        })

        if(this.autofocus) this.openPanel();

        const observer = this.DOMChangeObserver();

        observer(() => {
            this._setCoordinates(element);
        });
    },
    beforeDestroy() {
        window.removeEventListener('transitionend', this._recalculatePanelPosition);
    },
    watch: {
        value: function() {
            this.validateInput();
        },
        dropdownOptions: function() {
            this.$nextTick(() => {
                const element = document.getElementById(this.id);
                this.setCoordinates(element);
                this.setPanelHeight();
            })
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

label p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
}

.with-panel {
    &.drop-up {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    &.drop-down {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}

.arrow {
    position: absolute;
    right: 12px;
    transform: translate(2px, 2px);
    transition: 0.3s ease;
    pointer-events: none;
    z-index: 2;

    &.icon-small {
        top: 8px;
        right: 14px;
    }

    &.icon-medium {
        top: 16px;
        right: 20px;
    }

    &.up {
        transform: translate(2px, 2px) rotate(180deg);
    }
}

.selected-avatar {
    position: absolute;
    left: 11px;
    top: 50%;
    translate: 0 -50%;
    z-index: 2;

    &.xsmall {
        top: 6px;
        right: 14px;
    }

    &.small {
        // top: 16px;
        right: 20px;
    }
}

.dropdown-panel {
    position: absolute;

    width: calc(var(--element-width) - 2px);
    left: var(--position-left);
    top: var(--position-top);

    border-radius: $radius-default;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: white;
    border: 1px solid $color-primary;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    user-select: none;
    pointer-events: all;
    -webkit-user-select: none;
    overscroll-behavior: none;

    &.no-border {
        border: none !important;
        overflow: hidden;
    }

    &.drop-up {
        animation: openUp .3s;
        border-top: 1px solid $color-primary;
        border-bottom: none;
        border-top-left-radius: $radius-default;
        border-top-right-radius: $radius-default;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px -3px 6px rgba(128, 128, 128, 0.2);
        translate: 0 calc(-1 * (var(--panel-height) + var(--element-height) - 1px));
    }

    &.drop-down {
        animation: openDown .3s;
        border-bottom: 1px solid $color-primary;
        border-top: none;
        border-top-left-radius: $radius-default;
        border-top-right-radius: $radius-default;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0px 3px 6px rgba(128, 128, 128, 0.2);
        translate: 0 -1px;
    }

    .dropdown-options-wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .dropdown-option {
            display: flex;
            align-items: center;
            flex-grow: 1;
    
            font-family: $font-text;
            line-height: $line-height-m;
    
            font-size: $size-m;
            cursor: pointer;
            word-break: break-word;
            border-top: 1px solid $color-grey-3;
    
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                cursor: default;
            }
    
            &.selected {
                background: rgba(0, 161, 174, 0.1);
                &:hover {
                    background: rgba(0, 161, 174, 0.17);
                }
            }
    
            &:hover,
            &:focus {
                color: $color-grey-7;
                background-color: lighten($color-grey-3, 7%);
    
                &.selected {
                    color: $color-primary;
                }
    
                &:active {
                    box-shadow: none;
                }
            }
            &:focus-visible {
                outline: none !important;
            }
    
            
            .option-wrapper {
                flex-grow: 1;

                &.has-avatar {
                    padding-left: 0px !important;
                    
                    .avatar-wrapper {
                        width: 40px;
                        padding-right: var(--padding-left);

                        .avatar {
                            margin: auto;
                        }
                    }
                }

    
                &.option-small {
                    --padding-left: 8px;
                    padding: 4px 8px;
        
                    .avatar-wrapper .avatar {
                        width: 20px;
                        height: 20px;
                    }
                }
        
                &.option-medium {
                    --padding-left: 1px;
                    padding: 10px;
                    margin-left: 7px;
        
                    .avatar-wrapper .avatar {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
        .dropdown-option:first-child.null-value {
            color: $color-grey-5;
        }
    }

}

.option-tag {
    margin-left: 8px;
    margin-block: -2.5px;
}

.selected {
    color: $color-primary;
    font-weight: 500;
}

@keyframes openDown {
    0% {
        transform: translateY(-20%);
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes openUp {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}



.input {
    @include reset;
    will-change: transform;
    transition: border-color 0.3s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-text;
    line-height: $line-height-m;
    text-decoration: none;

    position: relative;
    color: $color-grey-7;
    align-items: center;
    justify-content: center;

    border-radius: $radius-default;
    background: white;
    cursor: pointer;
    border: 1px solid $color-grey-5;
    width: 100%;
    user-select: none !important;
    -webkit-user-select: none !important;
    z-index: 2;

    padding-right: var(--padding-right);

    transition: color .1s ease;

    &.loading {
        pointer-events: none;
        color: transparent;

        &::placeholder {
            opacity: 0;
        }
    }

    &.no-border {
        border: 0px;
        &:focus, 
        &.focus {
            border: none;
        }
        &:hover,
        &.hover {
            border:none;
        }
    }

    //SIZE

    &.medium {
        padding: 12px;
        padding-left: 10px;
        font-size: $size-m;

        & + .input-value {
            --padding-left: 10px;
            padding: 12px;
            padding-left: 10px;
        }

        + .loader-wrapper .loader {
            $loader-color: $color-grey-5;
            $loader-size: 8px;
            $loader-height: 16px;
            $loader-border-size: 4px;
            $loader-gap: 16px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }
    &.small {
        padding: 6px 6px 6px 8px;
        line-height: 17px;
        font-size: $size-s;

        & + .input-value {
            --padding-left: 8px;
            padding: 6px 6px 6px 8px;
            font-size: $size-s;
        }

        + .loader-wrapper .loader {
            $loader-color: $color-grey-5;
            $loader-size: 6px;
            $loader-height: 10px;
            $loader-border-size: 3px;
            $loader-gap: 14px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }

    //STATE

    &:focus,
    &.focus,
    &.with-panel {
        background: inherit;
        border-color: $color-primary;
        box-shadow: none;
        outline: 0;

        &.no-border {
            border: none;
        }
    }

    &:hover,
    &.hover {
        border-color: $color-primary;
        transition: border 0.3 ease-in;
    }

    &.error {
        border: 2px solid $color-red;
    }
    //VARIATION
    &.blank {
        border: none;
        color: $color-grey-7;
        background-color: lighten($color-grey-3, 7%);
        padding: 4px 12px;
        padding-left: 8px;

        &:not(:placeholder-shown) {
            &.medium {
                padding: 12px;
                padding-left: 8px;
            }

            &.small {
                padding: 4px 12px;
                padding-left: 8px;
                &::placeholder {
                    font-size: $size-s;
                }
            }
        }
        &.error {
            background-color: #ffe0e0 !important;
        }
    }

    &.avatar-input {
        --padding-left: calc(var(--padding-left, 8px) + 40px) !important;
        padding-left: calc(var(--padding-left, 8px) + 40px) !important;

        &.autocomplete {
            --padding-left: calc(var(--padding-left, 8px) + 40px) !important;
            padding-left: calc(var(--padding-left, 8px) + 40px) !important;
        }
        
        & + .input-value {
            --padding-left: calc(var(--padding-left, 8px) + 40px) !important;
            padding-left: calc(var(--padding-left, 8px) + 40px) !important;
        }
    }

    &.with-panel {
        background: white;

        &.drop-up {
            border-top-color: transparent;
        }
        &.drop-down {
            border-bottom-color: transparent;
        }
    }
}

.blank > .icon_divmedium {
    right: 8px !important;
    margin-top: 10px;
}

input {
    padding: 0 10px;
    position: relative;
    border: 1px solid #e5e5e5;
    background: none;
    border-radius: 4px;
    font-family: $font-text;
}

.q-dropdown {
    font-weight: $weight-normal;
    font-size: 11px;
    font-family: $font-text;

    width: 100%;

    color: $color-grey-9;

    --padding-right: 35px;
    --padding-left: 0px;

    .dropdown {
        position: relative;

        .loader-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            z-index: 2;
        }

        .input-value {
            position: absolute;
            left: 0;
            top: 0;
            padding-block: 0;
            padding-right: var(--padding-right);
            margin-inline: 1px;
            display: flex;
            align-items: center;
            height: 100%;
            z-index: 10;
            pointer-events: none;
            width: calc(100% - var(--padding-left) - var(--padding-right));
            font-size: $size-m;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $color-grey-7;
                font-weight: $weight-normal;
            }

        }

        & .autocomplete {
            position: absolute;
            left: 0px;
            top: 0px;
            width: calc(100% - var(--padding-left) - var(--padding-right));
            margin: auto;
            background-color: white;
            border: 1px solid transparent !important;
            background-clip: padding-box;
            z-index: 2;
            outline-width: 0;
            color: $color-grey-9;
            font-weight: $weight-normal;
        }
    }

    &.small {
        font-size: 9px;
    }

    input::placeholder {
        opacity: 0;
    }

    input.show-placeholder::placeholder {
        opacity: 1;
    }

    input:placeholder-shown + label {
        position: absolute;
        left: -9999px;
        top: 20px;
        opacity: 0;
        padding: 0;
    }

    .icon_divmedium {
        position: absolute;
        top: calc(-8px + 50%);
        right: 15px;
    }

    .errorIcon {
        color: $color-red;
    }

    .successIcon {
        margin-top: 2px;
        color: $color-green;
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
        cursor: default;
    }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #afafaf; 
  border-radius: 10px;

  &:hover {
    background: rgb(130, 130, 130);
  }
}

</style>

<docs>
  ```jsx
  <div >
    <q-dropdown v-model="val" :options="[
        {
            value: 10,
            label: 'Zeer goed'
        },
        {
            value: 8,
            label: 'Goed'
        }
    ]">
    </q-dropdown>
    
  </div>
  ```
</docs>

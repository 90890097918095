<template>
    <div class="registration-navbar">
      <div v-for="(stepItem, index) in steps" :key="index" class="item">
        <div :class="[step === stepItem.id ? 'number-active' : '', 'number']">
          <span v-if="step <= stepItem.id">{{ stepItem.id }}</span>
          <q-icon v-else class="check" type="check" color="#00A1AE"></q-icon>
        </div>
        <span :class="[step === stepItem.id ? 'active' : '']">{{ stepItem.name }}</span>
        <div v-if="index < steps.length - 1" class="line"></div>
      </div>
    </div>
  </template>
  
<script>
  export default {
    name: 'RegistrationNavbar',
    props: {
      step: {
        type: Number,
        required: true,
      },
      steps: {
        type: Array,
        required: true,
        validator: (steps) => {
          return steps.every(step => 'id' in step && 'name' in step);
        }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .registration-navbar {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
    pointer-events: none;
    user-select: none;
  }
  
  .line {
    height: 1px;
    width: 50px;
    margin: 0 10px;
    background-color: #C4C4C4;
  }
  
  .item {
    display: flex;
    align-items: center;
  }
  
  .number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #C4C4C4;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    
    span {
      color: white;
    }
  
    .check {
      transform: scale(0.7);
      margin: 2px 0 0 0;
    }
  }
  
  .number-active {
    background-color: #00A1AE;
  }
  
  span {
    font-size: 14px;
    color: #C4C4C4;
  }
  
  span.active {
    color: black;   
  }
  </style>
  
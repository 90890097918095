<template>
    <div :class="['q-pageitem', isActive() ? 'active' : '']" @click="select" :style="'width:'+width+';background-color:'+getColor()">
        <q-icon v-if="Boolean(getType())" :type="getType()" class="check" :class="getType()" :height="getType() === 'box' ? '14': '18'" :width="getType() === 'box' ? '14': '18' "  :color="getIconColor()"></q-icon>
        <slot></slot>

        <div class="arrow">
            <svg width="18" height="64" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <rect fill="none" id="canvas_background" height="66" width="20" y="-1" x="-1"/>
                    <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                        <rect fill="url(#gridpattern)" stroke-width="0" y="0" x="0" height="100%" width="100%"/>
                    </g>
                </g>
                <g>
                    <path :stroke="noArrow ? getColor() : '#DEE2E6'" transform="rotate(90.14307403564453 8.650063514709476,32.33805465698242) " id="svg_4" d="m-24.08949,41.10216l32.73956,-18.03049l32.74537,18.53276l-65.49076,0z" fill-opacity="null" stroke-opacity="null" :fill="getColor()"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-pageitem',
    props: {
        /**
         * Value which is v-modeled in the parent q-pageflow
         */
        value: {
            type: [Number, String, Object, Array],
            required: true
        },
        /**
         * total width of the item
         */
        width: {
            type: String
        },
        /**
         * type of the item, decides the color and icon shown
         */
        type: {
            type: Object
        },
        noArrow: {
            type: Boolean
        }
    },
    methods: {
        select() {
            try {
                this.$parent.select(this.value)
            } catch(error) {
                console.log('q-pageflow has no select function')
            }
        },
        isActive() {
            return this.value === this.$parent.value
        },
        getType() {
            return this.type.status === 'succes' ? 'check' : this.type.status === 'not_done' ? '' : 'Bell'
        },
        getColor() {
            const green = '#f0f9f1';
            const orange = '#fff5ee';

            return this.type.status === 'succes' ? green : this.type.status === 'not_done' ? 'white' : orange
        },
        getIconColor() {
            return this.type.status === 'succes' ? 'green' : this.type.status === 'not_done' ? '#DEE2E6' : '#FD7E14'
        },
    }
}
</script>

<style lang="scss" scoped>

.q-pageitem {
    height: 64px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 8px;

    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    padding: 0 5px 0 36px;

    user-select: none;

    .arrow {
        position: absolute;
        right: -18px;
        height: 100%;
        z-index: 95;
    }
}
.check {
    width: 18px;
    height: 18px;

    &.box {
        margin-inline: 2px;
    }
}
.active {
    font-weight: 500;
    background-color: white;
}

</style>
<template>
    <svg :width="width" :height="height" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 3C13 4.10457 12.1046 5 11 5C9.89543 5 9 4.10457 9 3C9 1.89543 9.89543 1 11 1C12.1046 1 13 1.89543 13 3ZM7 3C7 4.10457 6.10457 5 5 5C3.89543 5 3 4.10457 3 3C3 1.89543 3.89543 1 5 1C6.10457 1 7 1.89543 7 3ZM14.8153 8.37473L14.8007 8.33338C14.3214 6.97732 13.0298 6.00381 11.5089 6.00001C13.0439 6.00385 14.3477 6.99619 14.8153 8.37473ZM8.98638 9H1.03544C1.27806 7.30385 2.73676 6 4.5 6H5H5.5C6.32819 6 7.08797 6.28703 7.68726 6.76744L8 7.01814L8.00837 7.01143L8.30033 7.40004C8.57147 7.76095 8.77417 8.17551 8.88976 8.62463L8.98638 9ZM8.39981 6.69993L8.38391 6.71186C8.97167 6.26498 9.70426 6 10.5 6C9.71269 6 8.9848 6.26055 8.39981 6.69993Z" :stroke="color"/>
        <defs>
        <linearGradient id="paint0_linear" x1="0.5" y1="9.54413" x2="15.5003" y2="9.54413" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color"/>
        <stop offset="0.505208" :stop-color="color"/>
        <stop offset="1" :stop-color="color"/>
        </linearGradient>
        </defs>    
    </svg>
</template>

<script>
export default {
    name: 'Users',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 8,
        },
        height: {
            type: [Number, String],
            default: 9,
        },
    },
};
</script>

<template>
  <div :icon="icon" :class="['alert', size, variation, truncate]">
    <q-icon class="alert_icon" :type="iconType" />

    <slot />
    <div class="close" @click="handleClose">
      <q-icon class="close_icon" type="close" width="14" height="14" />
    </div>
  </div>
</template>

<script>
import QIcon from "./QIcon.vue";

const POSSIBLE_VARIATIONS = ['danger', 'warning', 'info', 'success']

/**
 * alerts are generally used for interface actions. Suitable for all-purpose use.
 * Defaults to appearance that has white background with grey border.
 * Primary style should be used only once per view for main call-to-action.
 */
export default {
  name: "q-alert",
  status: "prototype",
  components: { QIcon },
  release: "0.0.1",
  props: {
    /**
     * The size of the alert. Defaults to large
     */
    size: {
      type: String,
      default: "large",
      validator: value => {
        return value.match(/(small|medium|large|)/);
      }
    },

    /**
     * Style variation to give additional meaning.
     * `danger, warning, info, success`
     */
    variation: {
      type: String,
      default: "info",
      validator: value => {
        return value.match(/(danger|warning|info|success)/);
      }
    },
    /**
     *Truncate string if it gets too long.
     */
    truncate: {
      type: String,
      default: null,
      validator: value => {
        return value.match(/(truncate|null)/);
      }
    },
    /**
     * Change icon according to variation. Not manually changeable.
     * `bell, warning, danger, success`
     */
    icon: {
      type: String,
      default: "bell"
    }
  },
  computed: {
    iconType() {
      let result = this.icon;
      switch (this.variation) {
        case "warning":
          result = "warning";
          break;
        case "danger":
          result = "danger";
          break;
        case "success":
          result = "check";
          break;
        default:
          return this.icon || "bell";
      }
      return result;
    }
  },
  methods: {
    handleClose(event) {
      this.$emit("close", event);
    },
    validate() {
      if (!POSSIBLE_VARIATIONS.includes(this.variation)) {
        console.error(`q-alert variation '${this.variation}' not valid. possible variations: ${POSSIBLE_VARIATIONS.join(', ')}`);
      }
    }
  },
  created() {
    this.validate();
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

$color-notification-shadow: #1c1c1c20;

.alert {
  @include reset;
  @include stack-space($space-xs);
  @include inline-space($space-xs);

  //   position: absolute;
  //   bottom: 20px;

  will-change: transform;
  transition: all 0.2s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $weight-semi-bold;
  font-size: $size-s;
  font-family: $font-text;
  line-height: $line-height-m;
  text-decoration: none;
  padding: 9px 40px;
  padding-left: 48px;

  width: 400px;
  display: inline-flex;

  border: 0;
  border-radius: $radius-default;
  background: transparent;
  color: $color-grey-3;

  // info alert
  &.info {
    background: $color-purple-light;
    color: $color-purple-dark;
    box-shadow: 0 5px 15px $color-notification-shadow;
  }

  // alert alert
  &.danger {
    background: $color-red-lighter;
    color: $color-red;
    box-shadow: 0 5px 15px $color-notification-shadow;
  }

  // warning alert
  &.warning {
    background: $color-orange-lighter;
    color: $color-orange;
    box-shadow: 0 5px 15px $color-notification-shadow;
    }

  // success alert
  &.success {
    background: $color-green-lighter;
    color: $color-green;
    box-shadow: 0 5px 15px $color-notification-shadow;
    }
}
// icon position
.alert .alert_icon {
  position: absolute;
  left: 17px;
  top: 12px;
  //   padding-right: $space-m;

  path {
    position: absolute;
    left: 4px;
    top: 4px;
  }
}

.close {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  margin: auto;
  cursor: pointer;
}

.close .close_icon {
  margin: auto;
  position: absolute;
  top: 13px;
  right: 12px;
}
</style>

<docs>
  ```jsx
  <div>
    <q-alert variation="info" size="large">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis aliquet quam. Pellentesque molestie sodales magna, vel ultricies nisl sodales sit amet. </q-alert>
    <br />
    <q-alert variation="danger" size="large">Fout bij het laden van projecten</q-alert>
    <br />
    <q-alert variation="warning" size="large">Selectie kan niet worden gestart</q-alert>
    <br />
    <q-alert variation="success" size="large">Mail succesvol verzonden</q-alert>
    
  </div>
  ```
</docs>

<template>
  <svg :width="width" :height="height" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50001 1.5C3.7386 1.5 1.50002 3.73857 1.50002 6.5C1.50002 9.26143 3.7386 11.5 6.50001 11.5C9.26143 11.5 11.5 9.26143 11.5 6.5C11.5 3.73857 9.26143 1.5 6.50001 1.5ZM6.50001 1.90735e-06C2.91017 1.90735e-06 2.38419e-05 2.91015 2.38419e-05 6.5C2.38419e-05 10.0899 2.91017 13 6.50001 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 1.90735e-06 6.50001 1.90735e-06ZM6.48969 10.0938C6.07548 10.0938 5.73969 9.75796 5.73969 9.34375V5.68749C5.73969 5.27328 6.07548 4.93749 6.48969 4.93749C6.9039 4.93749 7.23969 5.27328 7.23969 5.68749V9.34375C7.23969 9.75796 6.9039 10.0938 6.48969 10.0938ZM6.48969 2.84375C6.04096 2.84375 5.67719 3.20752 5.67719 3.65625C5.67719 4.10498 6.04096 4.46875 6.48969 4.46875C6.93842 4.46875 7.30219 4.10498 7.30219 3.65625C7.30219 3.20752 6.93842 2.84375 6.48969 2.84375Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "InfoCircle",
  props: {
    color: {
      type: String,
      default: "#212529",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>

<style></style>

<template>
    <div ref="hitBox" class="input-wrapper" :class="size">
        <input
            :id="elementId"
            :value="value"
            :type="type" 
            class="clear-input" 
            :placeholder="placeholder" 
            :disabled="disabled"
            @input="handleInput" 
            @blur="handleBlur"
            @keydown.enter="handleEmitEnter"
        >
        <div class="underline" :style="`--percentage-x: ${this.percentageX}%`"></div>
    </div>
</template>

<script>
export default {
    name: 'q-clear-input',
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: [String, Number],
            default: null
        },
        placeholder: {
            type: String,
            default: 'Vul hier wat in'
        },
        type: {
            type: String,
            default: 'text'
        },
        size: {
            type: String,
            default: 'small'
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            percentageX: 50,
            elementId: this.id
        }
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.$emit('input', value);
        },
        handleBlur(event) {
            const value = event.target.value;
            this.$emit('blur', value);
        },
        handleEmitEnter() {
            this.$emit('enter', this.value);
        },
        handleMouseMove(e) {
            const element = document.getElementById(this.elementId);
            const { x, width } = element.getBoundingClientRect();
            const pixelsFromLeft = e.pageX - x - window.scrollX > 0 ? e.pageX - x - window.scrollX : 0;
            let percentageX = pixelsFromLeft / width * 100;
            if(percentageX < 0) percentageX = 0;
            if(percentageX > 100) percentageX = 100;
            this.percentageX = percentageX;
        }
    },
    created() {
        if(!this.id) this.elementId = Math.round(Math.random() * 100000);
    },
    mounted() {
        const element = document.getElementById(this.elementId);
        if(this.autofocus) element.focus();
    },
    mounted() {
        const element = document.getElementById(this.elementId);
        if(this.autofocus) element.focus();

        this.$root.$on('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        this.$root.$off("mousemove", this.handleMouseMove);
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    --line-height: 1px;

    &.small {
        input {
            font-size: 14px;
        }
    }

    &.medium {
        input {
            font-size: 16px;
        }
    }

    &.extralarge {
        --line-height: 2px;

        input {
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 4px;

            &::placeholder {
                color: $color-grey-3;
            }
        }
    }

    .interactive {
        position: absolute;
        inset: -40px;
        pointer-events: none;
    }

    input {
        position: relative;
        border: none;
        outline: none;
        flex-grow: 1;
        background: transparent !important;
        padding: 0;
        color: $color-grey-9;
        line-height: 22px;

        &::placeholder {
            color: $color-grey-5;
        }

        &:focus, &:active, &:hover, &:not(:placeholder-shown) + .underline {
            background: $color-blue-dark;
        }

        &:disabled + .underline {
            background: #DEE2E6;
        }
    }

    &:hover {
        .underline {
            &:before {
                width: 50%;
                opacity: 1;
                transition: width .25s ease, opacity .25s ease;
            }
        }
    }

    &:focus-within {
        .underline {
            &:before {
                width: 200%;
                opacity: 1;
                transition: width .6s ease, opacity .2s ease;
            }
        }
    }

    .underline {
        position: relative;
        height: var(--line-height);
        flex-grow: 1;
        border-radius: 1px;
        overflow: hidden;
        background: #DEE2E6;
        
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: var(--line-height);
            border-radius: var(--line-height);
            translate: -50% 0;
            opacity: .7;
            background-color: $color-blue-dark;
            left: var(--percentage-x, 0%);
            bottom: 0;
            transition: width .4s ease-in .1s, left .2s ease, opacity .4s ease .1s;
            pointer-events: none;
        }
    }
}

</style>
<template>
    <div class="page">
        <div class="q-card-medium">
            
            <q-section
                hPadding="39"
                vPadding="37"
                class="intro">
                <h2>Aangevraagd</h2>
                <p>Hallo{{user.firstName ? ' ' + user.firstName : ''}}, je verzoek om toegang tot <span class="bold">{{getRequestedOrganisations()}}</span> wordt in behandeling genomen door de betreffende beheerder<span v-if="orgs.length > 1">s</span>. We houden je op de hoogte! </p>
            </q-section>

            <q-section
                hPadding="40"
                vPadding="24"
                borderTop
                class="space-between">
                <q-button variation="blank" to="/access">Terug</q-button>
            </q-section>

        </div>
    </div>
</template>

<script>

export default {
    name: 'RequestAccess',
    data() {
        return {
            user: this.$store.getters.getUser,
            orgs: this.$store.getters.getRequestedOrganisations
        };
    },
    methods: {
        getRequestedOrganisations() {
            return this.orgs.join(', ')
        }
    },
    created() {
        if (!(typeof this.orgs !== 'array' && this.orgs.length > 0)) this.$router.push('/access')
    }
};
</script>

<style lang="scss" scoped>

.bold {
    font-weight: 600;
}

.page {
    padding-bottom: 50px;
}


.intro > h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.intro > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="7.5" y="0.5" width="13" height="19" :stroke="color" />
        <path
            d="M0.5 7.56155C0.5 6.87325 0.968446 6.27328 1.6362 6.10634L7.5 4.64039V19.5H0.5V7.56155Z"
            :stroke="color"
        />
        <path d="M1 10H3.41935" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 4H12.4194" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 13.0312H3.41935" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 16.0645H3.41935" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.8389 4H18.2582" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 7H12.4194" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.8389 7H18.2582" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 10H12.4194" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.8389 10H18.2582" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="11.5" y="12.5" width="5" height="7" :stroke="color" />
        <path d="M14 12V20" :stroke="color" />
    </svg>
</template>

<script>
export default {
    name: 'Building',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 21,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
    },
};
</script>

<style></style>

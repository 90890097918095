<template>
    <div class="modal">
        <div class="vertical-center space-between">
            <div>
                <div class="vertical-center">
                    <h2>Nieuwe functies beschikbaar!</h2>
                </div>

                <p class="mt-s">
                    Het Qfact team werkt dagelijks aan verbeteringen van de software.<br />
                    Hieronder vind je de recente wijzigingen toegelicht.
                </p>
            </div>
            <q-icon type="present" width="100" height="100"></q-icon>
        </div>

        <div class="modal-content mt-m">
            <ul class="release-timeline">
                <li class="release" v-for="(release, index) in releasenotes" :key="index">
                    <div>
                        <h3 class="vertical-center"> {{ release.title }}
                            <q-tag style="margin-left: 24px;" variation="warning" size="small">Nieuw</q-tag>
                        </h3>
                        <p class="mb-s sub-line"> {{ parseDate(release.date) }}
                            <span v-if="release.version">&nbsp;•&nbsp; {{ release.version }}</span>
                        </p>
                        <div> {{release.description}}
                            <ul>
                                <li v-for="(line, index) in release.lines" :key="index">{{line.text}}</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <h3 class="">Lancering Qfact 🚀</h3>
                        <p class="mb-s sub-line">{{ parseDate(1613380171032) }} &nbsp;•&nbsp; v1.0.0</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="shadow"></div>
        <div class="footer space-between">
            <div class="vertical-center">
                <q-switch v-model="emailUpdates" style="margin-right: 12px; height: 21px"></q-switch>
                <p>Houd me op de hoogte via e-mail</p>
            </div>
            <q-button @click="close" size="medium" variation="blank">Sluiten</q-button>
        </div>
    </div>
</template>

<script>
import { SENT_RELEASENOTES } from '@/graphql/queries';
import { getDate } from '@/assets/js/utils';

export default {
    data() {
        return {
            emailUpdates: true,
            releasenotes: []
        };
    },
    methods: {
        close() {this.$emit('close')},
        parseDate(timestamp) {return getDate(timestamp)},
        getReleasenotes() {
            this.$apollo
                .query({ 
                    query: SENT_RELEASENOTES,
                    fetchPolicy: 'no-cache'
                })
                .then(res => this.releasenotes = res.data.releasenotes) 
                .catch(err => console.log('err', err));
        }
    },
    mounted() {
        this.getReleasenotes()
        localStorage.setItem('releasenotes_badge', true);
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 700px !important;
    max-height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-bottom: 41px;

    .modal-content {
        padding-bottom: 40px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
        height: 40px;
        background-color: white;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .shadow {
        position: absolute;
        bottom: 80px;
        width: calc(100% - 80px);
        // height: 3px;
        box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.25);
    }
}

.profile-right {
    margin-left: 16px;

    .name {
        font-weight: 500;
    }
    .role {
        margin-left: -2px;
        max-width: 300px;
    }
}

ul.release-timeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;

    &::before {
        content: '';
        width: 1px;
        background-color: #adb5bd;
        position: absolute;
        top: 30px;
        bottom: 70px;
        left: 18px;
        z-index: 1;
    }

    li.release {
        padding: 20px 20px 20px 40px;
        border-radius: 4px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::after {
            content: '';
            background: #adb5bd;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;

            top: 26px;
            left: 15px;
        }

        .on-hover {
            display: none;
        }
    }
}

.sub-line {
    color: $color-grey-5;
}

.red-hover :hover {
    color: $color-red;
    transition: color 200ms ease;
}
// .question-circle {
//     margin-left: 8px;
// }
</style>

<template>
    <q-step :number="index" :id="index" :name="step.name" :about="step.about" :value="step.value" class="step" width="90%">
        <div class="flex-column">
            <q-input labelPosition="inside" class="report-name-input" :placeholder="step.placeholder" v-model="selectedStyle.name">{{ step.placeholder }}</q-input>
            <div class="style-preview">
                <q-color-card v-if="selectedStyle.color" :color="selectedStyle.color.colorCode" :name="selectedStyle.color.name" size="small" interactive @click.native="navigateToStep(0)"></q-color-card>
                <q-logo-card v-if="selectedStyle.logo" :src="selectedStyle.logo" :alt="selectedStyle.address.line1" size="small" interactive @click.native="navigateToStep(1)"></q-logo-card>
                <q-branch-card v-if="selectedStyle.address" :sender="selectedStyle.address.sender" :name="selectedStyle.address.name" :line1="selectedStyle.address.line1" :line2="selectedStyle.address.line2" size="small" interactive @click.native="navigateToStep(2)"></q-branch-card>
            </div>
        </div>
    </q-step>
</template>

<script>
export default {
    name: 'style-step-name',
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        selectedStyle: {
            type: Object,
            required: true
        }
    },
    methods: {
        navigateToStep(stepIndex) {
            this.$emit('navigateToStep', stepIndex)
        }
    }
}
</script>

<style lang="scss" scoped>

.step {
    justify-content: center;

    .flex-column {
        width: 100%;
        gap: 12px;

        .style-preview {
            display: flex;
            gap: 20px;
            justify-content: center;
        }
    }
}

.report-name-input {
    margin-top: -24px;
}

</style>
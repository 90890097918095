const referenceletterStyle = `<style>@import url('https://fonts.cdnfonts.com/css/gotham'); *{ font-family: "Gotham", sans-serif !important;} h4, .group-name>p, .left>p{ font-weight: 600 !important;} .navbar, .topbar, .steps-wrapper, .pageflow, .header, .bottom-right-button, .TrengoWidgetLauncher__iframe, .left-content, .pagination, .notifs, .left.opened, .side-menu{ display: none !important;} .upload, .overview { opacity: 0; } .letter { overflow-y: visibble; } #reference-letter { padding: 0 !important; margin-inline: auto; min-width: 100% !important; max-width: unset !important; } .viewport { max-height: unset !important; max-width: unset !important; min-width: 100% !important; overflow: visible; } .step { min-height: unset !important; max-height: unset !important; } .right.opened{ max-width: 100% !important; max-height: unset !important; overflow: unset !important;} .icon.hamburger-icon{ display: none !important;} .content, .right-content{ margin: 0 !important; max-width: 100% !important; width: 100% !important; max-height: unset !important; overflow-y: unset !important;} .indicator-groups{ margin-left: 10px !important;} .indicator-group{ margin: 0 !important; break-inside: avoid-page;} .indicator-wrapper{ break-inside: avoid-page;} .spaced-group, .spaced-item, .text-block{margin-top: unset!important;}</style>`;
const surveyStyle = `@import url('https://fonts.cdnfonts.com/css/gotham'); @font-face { font-family: "Gotham"; font-style: normal; font-weight: 500; src: url('https://fonts.cdnfonts.com/s/14898/GothamMedium.woff') format("truetype"); } @font-face { font-family: "Gotham"; font-style: normal; font-weight: 600; src: url('https://fonts.cdnfonts.com/s/14898/Gotham-Bold.woff') format("truetype"); } * { font-family: "Gotham", sans-serif !important; overflow: visible !important; transition: none !important; animation: none !important; } #content-overflow { max-height: unset !important; overflow-y: visible !important; max-width: unset !important; } .navbar, .steps-wrapper, .pageflow, .header, .bottom-right-button, .left-content, .pagination, .TrengoWidgetLauncher__iframe, .topbar, .notifs, .tag, .app-base-left { display: none !important; } .text-answers { max-height: unset !important; overflow-y: visible !important; } .scroll-overflow-shadow { background: none !important; } .enquete-results { box-shadow: none !important; border: none !important; } .enquete-wrapper { padding: 0 !important; } .enquete-wrapper>.head, .cards, .options-row, .circles-wrapper { display: none !important; } .score-text-wrapper { padding: 16px; } .enquete-answer { break-inside: avoid !important; } .results-wrapper { padding-bottom: 0 !important; } .option { padding: 8px var(--padding-inline) !important; } .amount-container, .category-header { background: transparent !important; padding: 0px !important; } .main-score-view, .decimal-score-view { overflow: hidden !important; }`;

export const getReferenceLetterHtml = async () => {
    
    let html = document.documentElement.innerHTML;
    let styleSheets = document.styleSheets;
    let styles = "";
    
    for (let i = 0; i < styleSheets.length; i++) {
        const sheet = styleSheets[i];
        
        if(sheet.href && !sheet.href.includes('productfruits')) {
            try {
                const response = await fetch(sheet.href);
                const text = await response.text();
                styles += text;
            } catch(error) {}
        }
    }
    
    html = html.split("</style>")
    html[0] += referenceletterStyle
    html = html.join("</style>")
    
    html = html.split("</head>")
    html[1] = `<style>${styles}</style>` + html[1]
    html = html.join("</head>")

    return html
}

export const getSurveyHtml = async () => {

    let html = document.documentElement.innerHTML;
    let styleSheets = document.styleSheets;
    let styles = "";
    
    for (let i = 0; i < styleSheets.length; i++) {
        const sheet = styleSheets[i];
        
        if(sheet.href && !sheet.href.includes('productfruits')) {
            try {
                const response = await fetch(sheet.href);
                const text = await response.text();
                styles += text;
            } catch(error) {}
        }
    }

    html = html.split("<body>")
    html[1] = `<style>${surveyStyle};${styles}</style>` + html[1];
    html = html.join("<body>")

    return html
}
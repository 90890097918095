<template>
    <div :style="iconStyle" class="icon" @click="emitClick">
        <svg
            v-if="isInlineSVG"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
            :viewBox="viewBox"
        >
            <g fill="none">
                <path :d="path" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>
        <div v-else>
            <component :is="type" :color="color" :width="width" :height="height" :variation="variation" />
        </div>
    </div>
</template>

<script>
import ICONS from '../assets/icons/icons.js';
import ArrowDown from '../assets/icons/ArrowDown.vue';
import ArrowUp from '../assets/icons/ArrowUp.vue';
import SortArrows from '../assets/icons/SortArrows.vue';
import SmallArrowDown from '../assets/icons/SmallArrowDown.vue';
import SmallArrowUp from '../assets/icons/SmallArrowUp.vue';
import Bell from '../assets/icons/Bell.vue';
import Box from '../assets/icons/Box.vue';
import Cog from '../assets/icons/Cog.vue';
import Danger from '../assets/icons/Danger.vue';
import Power from '../assets/icons/Power.vue';
import Funnel from '../assets/icons/Funnel.vue';
import LockCircle from '../assets/icons/LockCircle.vue';
import Cancel from '../assets/icons/Cancel.vue';
import PlusIcon from '../assets/icons/Plus.vue';
import PlusCircle from '../assets/icons/PlusCircle.vue';
import LockOpen from '../assets/icons/LockOpen.vue';
import LockClosed from '../assets/icons/LockClosed.vue';
import Check from '../assets/icons/Check.vue';
import CheckCircle from '../assets/icons/CheckCircle.vue';
import QuestionCircle from '../assets/icons/QuestionCircle.vue';
import InfoCircle from '../assets/icons/InfoCircle.vue';
import Pen from '../assets/icons/Pen.vue';
import People from '../assets/icons/People.vue';
import Download from '../assets/icons/Download.vue';
import HamburgerMenu from '../assets/icons/HamburgerMenu.vue';
import Options from '../assets/icons/Options.vue';
import Delete from '../assets/icons/Delete.vue';
import Duplicate from '../assets/icons/Duplicate.vue';
import Camera from '../assets/icons/Camera.vue';
import FilterIcon from '../assets/icons/FilterIcon.vue';
import Folder from '../assets/icons/Folder.vue';
import Eye from '../assets/icons/Eye.vue';
import InitiatorBadge from '../assets/icons/InitiatorBadge.vue';
import Close from '../assets/icons/Close.vue';
import DynamicAlignment from '../assets/icons/DynamicAlignment.vue';

// Navbar icons
import Dashboard from '../assets/icons/Dashboard.vue';
import DashboardGradient from '../assets/icons/DashboardGradient.vue';
import Settings from '../assets/icons/Settings.vue';
import SettingsWheel from '../assets/icons/SettingsWheel.vue';
import SettingsGradient from '../assets/icons/SettingsGradient.vue';
import Helmet from '../assets/icons/Helmet.vue';
import HelmetGradient from '../assets/icons/HelmetGradient.vue';
import Building from '../assets/icons/Building.vue';
import BuildingGradient from '../assets/icons/BuildingGradient.vue';
import Survey from '../assets/icons/Survey.vue';
import SurveyGradient from '../assets/icons/SurveyGradient.vue';
import Chart from '../assets/icons/Chart.vue';
import ChartGradient from '../assets/icons/ChartGradient.vue';
import Search from '../assets/icons/Search.vue';
import ShakingHands from '../assets/icons/ShakingHands.vue';
import AddTextBubble from '../assets/icons/AddTextBubble.vue';
// Big icons/illustrations
import HelmetBig from '../assets/icons/HelmetBig.vue';
import SurveyBig from '../assets/icons/SurveyBig.vue';
import BulldozerBig from '../assets/icons/BulldozerBig.vue';
import BuildingBig from '../assets/icons/BuildingBig.vue';

import Confetti from '../assets/icons/Confetti.vue';
import Present from '../assets/icons/Present.vue';
import NotificationBubble from '../assets/icons/NotificationBubble.vue';

// Emojis
import HappyEmoji from '../assets/icons/HappyEmoji.vue';
import SlightlyHappyEmoji from '../assets/icons/SlightlyHappyEmoji.vue';
import NeutralEmoji from '../assets/icons/NeutralEmoji.vue';
import SadEmoji from '../assets/icons/SadEmoji.vue';

import UsersGradient from '../assets/icons/UsersGradient.vue';
import UsersFilled from '../assets/icons/UsersFilled.vue';
import Users from '../assets/icons/Users.vue';

export default {
    name: 'q-icon',
    components: {
        ArrowDown,
        ArrowUp,
        SortArrows,
        SmallArrowDown,
        SmallArrowUp,
        Bell,
        Box,
        Cog,
        Danger,
        Power,
        LockCircle,
        Cancel,
        PlusIcon, 
        PlusCircle,
        LockOpen,
        LockClosed,
        Check,
        CheckCircle,
        QuestionCircle,
        InfoCircle,
        Dashboard,
        DashboardGradient,
        Funnel,
        Settings,
        SettingsWheel,
        SettingsGradient,
        Helmet,
        HelmetGradient,
        Building,
        BuildingGradient,
        Survey,
        SurveyGradient,
        Chart,
        ChartGradient,
        Search,
        ShakingHands,
        HappyEmoji,
        SlightlyHappyEmoji,
        NeutralEmoji,
        SadEmoji,
        HelmetBig,
        NotificationBubble,
        Confetti,
        Present,
        SurveyBig,
        BulldozerBig,
        BuildingBig,
        UsersGradient,
        UsersFilled,
        Users,
        Download,
        HamburgerMenu,
        Options,
        Delete,
        Pen,
        People,
        Duplicate,
        Camera,
        FilterIcon,
        Folder,
        AddTextBubble,
        Eye,
        InitiatorBadge,
        Close,
        DynamicAlignment
    },
    props: {
        type: {
            type: String,
            default: () => null
        },
        color: {
            type: String,
            default: 'currentColor'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        variation: {
            type: String,
            default: null
        }
    },
    methods: {
        emitClick(event) {
            this.$emit('click', event);
        }
    },
    computed: {
        iconStyle() {
            const { width, height } = this;
            return {
                width: `${width}px`,
                height: `${height}px`
                // stroke: this.color,
                // fill: this.color,
            };
        },
        path() {
            return ICONS[this.type];
        },
        svgUrl() {
            return `/icons/${this.type}.svg`;
        },
        isInlineSVG() {
            return !!this.path;
        },
        viewBox() {
            return `0 0 ${this.width} ${this.height}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.icon {
    line-height: 0;
}
</style>

<docs>
  ```jsx
  <div>
    <q-icon type="check" width="20" height="20" color="black" />
    <q-icon type="danger" width="20" height="20" />
  </div>
  ```
</docs>

<template>
    <div :class="[reportPageFilterBar === true ? 'checkbox-wrapper-result-page-filter' : 'checkbox-wrapper-basic']">
        <div class="checkbox-bar-wrapper" :class="{ checked: item.checked }">
            <label class="checkbox-wrapper">
                <q-checkbox v-model="item.checked" :disabled="!item.checked && disabled" name="checkbox" @input="onCheckboxInput"></q-checkbox>
                <span v-html="markedName"></span>
            </label>
            <div class="filter-wrapper">
                <filter-bar 
                    v-if="answerValue" 
                    :value="item" 
                    :reportPageFilterBar="reportPageFilterBar" 
                    style="width: 100%" 
                    @input="onInput"
                ></filter-bar>
            </div>
        </div>
    </div>
</template>

<script>
import FilterBar from './FilterBar.vue';

export default {
    name: 'checkbox-bar',
    components: { FilterBar },
    props: {
        item: {
            type: Object,
            required: true,
        },
        markedName: {
            type: String,
            default: null
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        answerValue: {
            type: Boolean,
            default: false,
        },
        reportPageFilterBar: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean, 
            default: false
        }
    },
    methods: {
        onCheckboxInput(value) {
            this.$emit('checkboxInput', value);
        },
        onInput(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.checkbox-wrapper-basic {
    .checkbox-bar-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 2 * 18px);
        padding-inline: 18px;
        background: transparent;
        border-radius: 4px;

        &:hover, &.checked {
            background: #dee2e650;
        }

        .checkbox-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            padding-block: 12px;
            width: 45%;
            cursor: pointer;

            .checkbox {
                translate: 0 1px;
            }
        }

        .filter-wrapper {
            width: 55%;
        }
    }
}

.checkbox-wrapper-result-page-filter {
    .checkbox-bar-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: calc(100% - 2 * 18px);
        padding-inline: 18px;
        background: transparent;
        border-radius: 6px;
        padding-block: 4px;

        &:hover, &.checked {
            background: #dee2e650;
        }

        .checkbox-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            padding-block: 12px;
            flex-grow: 1;
            cursor: pointer;
            margin-left: 2px;

            .checkbox {
                translate: 0 1px;
            }
        }

        .result-count {
            width: 15%;
        }
    }
}
</style>

<template>
    <div class="page-padding">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.push('/settings')">Instellingen</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Formulierstijlen</h1>
                    <q-button @click="handleCreateStyle">Formulierstijl toevoegen</q-button>
                </div>
                <p>Bekijk en beheer hier de stijlen van je formulieren.</p>
            </div>
        </div>
        <q-table-with-pagination
            :data="styles"
            :columns="styleColumns"
            :maxRows="tableMaxRows"
            :dataLength="styles.length"
            @click="handleRowClick"
        >
            <template v-slot:row="{ column, row }">
                <div v-if="column === 'name'" class="name-slot">
                    <span class="name">{{ row[column] }}</span>
                    <span class="date">Aangemaakt op: {{ getDate(row.creationDate) }}</span>
                </div>
                <span v-else-if="column === 'lastEdited'">
                    {{ getDate(row.lastEdited) }}
                </span>
                <div v-else-if="column === 'color'" class="styles-slot">
                    <q-color-card :color="row.color.colorCode" :name="row.color.name" size="small" interactive @click.native="navigateToStep(row, 0)"></q-color-card>
                    <q-logo-card :src="row.logo" :alt="row.address.line1" size="small" interactive @click.native="navigateToStep(row, 1)"></q-logo-card>
                    <q-branch-card :sender="row.address.sender" :name="row.address.name" :line1="row.address.line1" :line2="row.address.line2" size="small" interactive @click.native="navigateToStep(row, 2)"></q-branch-card>
                </div>
                <div v-else-if="column === 'default'" class="default-slot">
                    <q-checkbox :value="selectedConfigurationId === row.id" @click.native.stop="handleSelectDefaultConfiguration(row.id)"></q-checkbox>
                </div>
                <div v-else-if="column === 'options'" class="options-slot">
                    <q-options class="options" :options="styleOptions" color="#C4C4C4" @input="handleSelectStyleOption($event, row)"></q-options>
                </div>
            </template>
        </q-table-with-pagination>

        <q-popup v-if="deletingStyle" width="35%">
            <div class="popup-delete">
                <div class="popup-header">
                    <h2>Weet je zeker dat je deze formulierstijl wilt verwijderen?</h2>
                </div>
                <div class="popup-body">
                    <p>Als je deze formulierstijl verwijderd kun je deze niet meer gebruiken in nieuwe formulieren. De stijl wordt niet verwijderd uit reeds aangemaakte formulieren. </p>
                </div>
                <div class="popup-buttons">
                    <q-button variation="blank" @click="handleCancelDelete">Annuleren</q-button>
                    <q-button variation="danger" @click="handleDeleteStyle">Formulierstijl verwijderen</q-button>
                </div>
            </div>
        </q-popup>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';

import { getDate } from '@/assets/js/utils.js';
import { CONFIGURATIONS } from '@/graphql/queries.js';
import { DELETE_CONFIGURATION, ORGANISATION_SET_MAIN_CONFIGURATION } from '@/graphql/mutations';

export default {
    name: 'styles',
    components: {
        ArrowButton
    },
    data() {
        return {
            styles: [],
            styleColumns: [
                {
                    label: 'Naam',
                    field: 'name',
                    width: '400px'
                },
                {
                    label: 'Laatst gewijzigd',
                    field: 'lastEdited',
                    width: '120px'
                },
                {
                    label: 'Stijlelementen',
                    field: 'color',
                    width: '350px',
                    tooltip: true,
                    clickable: false
                },
                {
                    label: 'Standaard',
                    tooltipText: 'Bij formulieren wordt standaard de geselecteerde stijl gekoppeld. Je kunt in je formulier altijd een andere stijl kiezen. Je kunt maximaal 1 standaard stijl selecteren.',
                    field: 'default',
                    width: '100px',
                    align: 'right'
                },
                {
                    label: '',
                    field: 'options',
                    width: '40px',
                    tooltip: true,
                },
            ],
            tableMaxRows: 10,
            styleOptions: [
                {
                    name: 'Stijl verwijderen',
                    color: 'red'
                }
            ],
            deletingStyle: null,
            selectedConfigurationId: null
        }
    },
    methods: {
        getDate(timestamp) {
            return getDate(timestamp)
        },
        handleSelectStyleOption(option, style) {
            if(option.name === 'Stijl verwijderen') {
                this.deletingStyle = style;
            }
        },
        handleSelectDefaultConfiguration(id) {
            if(this.selectedConfigurationId === id) this.selectedConfigurationId = null;
            else this.selectedConfigurationId = id;

            this._saveSelectedConfigurationId();
        },
        _saveSelectedConfigurationId: _.debounce(function() {
            this.$apollo.mutate({
                mutation: ORGANISATION_SET_MAIN_CONFIGURATION,
                variables: {
                    id: this.$store.getters.getCurrentOrganisation.id,
                    mainConfigurationId: this.selectedConfigurationId
                }
            })
            .then(result => {
                const currentOrganisation = this.$store.getters.getCurrentOrganisation;
                currentOrganisation.mainConfigurationId = this.selectedConfigurationId;
                this.$store.commit('setCurrentOrganisation', currentOrganisation);
                this.$store.commit('notify', { type: 'success', message: 'Standaard stijl succesvol aangepast' });
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanpassen van het standaard stijl' });
            })
        }, 1000),
        handleCancelDelete() {
            this.deletingStyle = null;
        },
        handleDeleteStyle() {
            this.$apollo.mutate({
                mutation: DELETE_CONFIGURATION,
                variables: {
                    id: this.deletingStyle.id
                }
            })
            .then(result => {
                const styleIndex = this.styles.findIndex(style_ => style_.id === this.deletingStyle.id)
                this.styles.splice(styleIndex, 1)
                this.deletingStyle = null;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'error', message: 'Er is iets fout gegaan tijdens het verwijderen.' })
            })
        },
        handleRowClick(style) {
            this.$router.push(`/settings/styles/${style.id}`)
        },
        navigateToStep(style, stepIndex) {
            this.$router.push(`/settings/styles/${style.id}?step=${stepIndex}`)
        },
        handleCreateStyle() {
            this.$router.push(`/settings/styles/create`)
        }
    },
    created() {
        this.$apollo.query({
            query: CONFIGURATIONS,
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            this.styles = result.data.org_configurations.filter(configuration => configuration.type === 'style')
        })
        .catch(error => {
            this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de stijlen' });
        })

        this.selectedConfigurationId = this.$store.getters.getCurrentOrganisation.mainConfigurationId;
    }
}
</script>

<style lang="scss" scoped>

.name-slot {
    display: flex;
    flex-direction: column;
    .name {
        color: #484f56;
        font-weight: 500;
    }
    .date {
        color: #9F9F9F;
    }
}

.styles-slot {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-block: 4px;

    > *:not(.options) {
        flex-grow: 1;
        width: calc(33% - 16px) !important;
    }
}

.default-slot {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .checkbox {
        margin-right: -10px;
    }
    
}
.options-slot {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}
.popup-delete {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 32px;
    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

</style>
<template>
    <svg :width="width" :height="height" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.36709 9.58596L8.55606 3.49098C8.94956 3.10346 9.58271 3.1083 9.97023 3.5018L10.5098 4.04971C10.8973 4.44321 10.8925 5.07635 10.499 5.46388L4.32155 11.5475C4.16353 11.7031 3.95826 11.8018 3.73802 11.828L3.00672 11.915C2.71813 11.9493 2.42988 11.8483 2.22596 11.6412C2.01527 11.4273 1.91947 11.1255 1.96815 10.8293L2.082 10.1363C2.11634 9.92731 2.21617 9.73459 2.36709 9.58596Z" fill="white"/>
        <path d="M1.51613 11.933L1.51739 11.9231C1.52556 11.8586 1.55517 11.7988 1.60148 11.7532C1.71542 11.6409 1.89876 11.6423 2.01098 11.7563L2.08932 11.8358C2.19397 11.9421 2.19266 12.1131 2.08639 12.2177C2.03203 12.2713 1.95755 12.2993 1.88137 12.2949L1.8138 12.291C1.62962 12.2804 1.49295 12.1161 1.51613 11.933Z" fill="white"/>
        <path d="M2.36709 9.58596L8.55606 3.49098C8.94956 3.10346 9.58271 3.1083 9.97023 3.5018L10.5098 4.04971C10.8973 4.44321 10.8925 5.07635 10.499 5.46388L4.32155 11.5475C4.16353 11.7031 3.95826 11.8018 3.73802 11.828L3.00672 11.915C2.71813 11.9493 2.42988 11.8483 2.22596 11.6412C2.01527 11.4273 1.91947 11.1255 1.96815 10.8293L2.082 10.1363C2.11634 9.92731 2.21617 9.73459 2.36709 9.58596Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.51613 11.933L1.51739 11.9231C1.52556 11.8586 1.55517 11.7988 1.60148 11.7532C1.71542 11.6409 1.89876 11.6423 2.01098 11.7563L2.08932 11.8358C2.19397 11.9421 2.19266 12.1131 2.08639 12.2177C2.03203 12.2713 1.95755 12.2993 1.88137 12.2949L1.8138 12.291C1.62962 12.2804 1.49295 12.1161 1.51613 11.933Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.59209 3.33326V3.33326C8.32383 3.06086 7.88553 3.05751 7.61314 3.32577L5.14282 5.75857M9.80953 3.22734L11.0497 2.006C11.3221 1.73774 11.7604 1.74109 12.0287 2.01349V2.01349C12.2969 2.28589 12.2936 2.72419 12.0212 2.99245L10.781 4.21378" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'Pen',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 13
        },
        height: {
            type: [Number, String],
            default: 14
        }
    }
};
</script>
import { render, staticRenderFns } from "./QPageItem.vue?vue&type=template&id=c25e8646&scoped=true"
import script from "./QPageItem.vue?vue&type=script&lang=js"
export * from "./QPageItem.vue?vue&type=script&lang=js"
import style0 from "./QPageItem.vue?vue&type=style&index=0&id=c25e8646&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c25e8646",
  null
  
)

export default component.exports
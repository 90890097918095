<template>
    <div class="panel q-card-small">
        <div class="header">
            <div class="title">
                <h3 style="margin-right: 20px">Prestaties in project</h3>
                <q-tag variation="default" size="medium"
                    >{{ projectLabels.length }} prestaties</q-tag
                >
            </div>
            <q-button
                size="small"
                style="width: 180px"
                :loading="loadingAddLabelModal"
                :disabled="otherLabels.length <= 0"
                @click="addLabel"
                >Prestatie toevoegen</q-button
            >
        </div>

        <div class="table-header" :class="showAllLabels ? 'show' : 'hide'">
            <q-table
                :data="projectLabels"
                :columns="columns"
                :loading="projectLabelsLoading"
                :loadingRows="5"
                paddingInline="22px"
                @filterUpdated="projectFilterUpdated"
            >
                <template v-slot:row="{ row, column }">
                    <span v-if="column === 'label'">
                        {{ row.name }}
                    </span>
                    <span v-else-if="column === 'references'">
                        <q-tooltip position="bottom">
                            <template v-if="row.references.length" v-slot:tooltip>
                                <div>
                                    <p
                                        class="indicator-name"
                                        v-for="indicator in row.references"
                                        :key="indicator.id"
                                    >
                                        {{ indicator.name }}
                                    </p>
                                </div>
                            </template>
                            <p>{{ row.references.length }}</p>
                        </q-tooltip>
                    </span>
                    <span v-else-if="column === 'questions'">
                        <q-tooltip position="bottom">
                            <template v-if="row.questions.length" v-slot:tooltip>
                                <div>
                                    <p
                                        class="indicator-name"
                                        v-for="indicator in row.questions"
                                        :key="indicator.id"
                                    >
                                        {{ indicator.name }}
                                    </p>
                                </div>
                            </template>
                            <p>{{ row.questions.length }}</p>
                        </q-tooltip>
                    </span>
                    <div v-else-if="column === 'projectTemplateNames'" class="templates-with-delete" style="position: relative;">
                        <span class="names">{{ row.projectTemplateNames }}</span>
                        <q-button
                            v-if="!['Project_details','Crow_project_details'].includes(row.name)"
                            class="delete-button"
                            variation="light"
                            size="xsmall"
                            @click="deleteLabel($event, row)"
                            >Verwijderen</q-button
                        >
                    </div>
                </template>
            </q-table>
        </div>

        <div v-if="projectLabels.length >= 5" class="toggle-show-labels-container" @click="toggleShowAllLabels">
            <p>{{ showAllLabels ? 'Toon minder' : 'Toon alle' }}</p>
            <q-icon type="chevronUp" color="#ADB5BD" class="show-labels-arrow" :class="showAllLabels ? 'up' : 'down'"></q-icon>
        </div>

        <q-popup v-if="showLabelAddModal">
            <label-add-modal
                :projectTemplates="projectTemplates"
                :otherLabels="otherLabels"
                :addIndicatorsLoading="addIndicatorsToProjectLoading"
                @close="closeLabelAddModal"
                @indicatorIdsToBeAdded="addIndicatorsToProject"
                @filterUpdated="otherFilterUpdated"
            ></label-add-modal>
        </q-popup>

        <q-popup v-if="showLabelDeleteModal">
            <label-delete-modal
                :deleteIndicatorsLoading="deleteIndicatorsFromProjectLoading"
                @close="closeLabelDeleteModal"
                @deleteIndicator="deleteIndicatorsFromProject"
            ></label-delete-modal>
        </q-popup>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import LabelAddModal from './LabelAddModal.vue';
import LabelDeleteModal from './LabelDeleteModal.vue';

import { GET_PROJECT_REFERENCES } from '../../../../graphql/queries';
import { REMOVE_ALL_INDICATORS, ADD_ALL_INDICATORS } from '../../../../graphql/mutations';

import { extractError, sortIndicators } from '@/assets/js/utils';
import gql from 'graphql-tag';

export default {
    name: 'label-settings',
    components: {
        ArrowButton,
        LabelAddModal,
        LabelDeleteModal,
    },
    data() {
        return {
            indicatorIdsInProject: [],
            indicatorsToBeDeleted: [],
            showAllLabels: false,
            loadingAddLabelModal: true,
            projectLabels: [],
            projectIndicators: [],
            otherLabels: [],
            otherIndicators: [],
            projectLabelsLoading: true,
            deleteIndicatorsFromProjectLoading: false,
            addIndicatorsToProjectLoading: false,
            showLabelAddModal: false,
            showLabelDeleteModal: false,
            projectTemplates: [],
            labels: [],
            whereQuery: null,
            projectTemplateIdsFilter: []
        };
    },
    methods: {
        setProjectLabels() {
            let projectLabels = [];
            let indicators = this.projectIndicators;

            indicators.forEach((indicator) => {
                const context = indicator.context;
                const labelIndex = projectLabels.findIndex((label) => label.id === context.id);
                const labelExists = labelIndex !== -1;

                if (labelExists) projectLabels[labelIndex].indicators.push(indicator);
                else {
                    const labelToAdd = { id: context.id, name: context.name, indicators: [ indicator ], order: context.order };
                    projectLabels.push(labelToAdd);
                }
            });

            projectLabels = projectLabels.map(projectLabel => {
                let projectTemplates = [];
                let labelIndicators = projectLabel.indicators;

                if(this.projectTemplateIdsFilter.length > 0) {
                    const labelHasIndicatorsWithFilter = labelIndicators.filter(indicator => this.includesArray(indicator.projecttemplateIds || [], this.projectTemplateIdsFilter)).length > 0;
                    if(!labelHasIndicatorsWithFilter) return null
                }

                labelIndicators.forEach(indicator => {
                    
                    const templatesRelatedToThisIndicator = this.projectTemplates.filter((template) => 
                        template.indicatorIds !== null &&
                        template.indicatorIds.includes(indicator.id)
                    )
                    
                    templatesRelatedToThisIndicator.forEach(template => {
                        if (projectTemplates.map(template_ => template_.id).indexOf(template.id) === -1)
                            projectTemplates.push(template)
                    })
                })

                const questions = labelIndicators.filter(indicator => indicator.type === 'question')
                const references = labelIndicators.filter(indicator => indicator.type === 'reference')

                return {
                    ...projectLabel,
                    questions,
                    references,
                    projectTemplates,
                    projectTemplateNames: projectTemplates.map(template => template.name).join(', ')
                }
            })
            .filter(label => label)

            projectLabels = projectLabels.sort((a, b) => {
                if(a.order > b.order) return 1
                if(b.order > a.order) return -1
                return 0
            })
            
            this.projectLabels = projectLabels;
            this.projectLabelsLoading = false;
        },
        includesArray(array, includesArray) {
            return includesArray.filter(includes => array.includes(includes)).length > 0
        },
        setOtherLabels() {
            let otherLabels = [];

            this.otherIndicators.forEach(indicator => {
                const context = indicator.context || this.labels.find(label => label.id === indicator.labelIds[0]);

                
                if (context) {
                    const labelIndex = otherLabels.findIndex((label) => label.id === context.id);
                    const labelExists = labelIndex !== -1;
                    
                    if (labelExists) otherLabels[labelIndex].indicators.push(indicator);
                    else {
                        const labelToAdd = {
                            id: context.id,
                            name: context.name,
                            indicators: [indicator]
                        };
                        otherLabels.push(labelToAdd);
                    }
                }
            });

            otherLabels = otherLabels.map(otherLabel => {
                let projectTemplates = [];
                let labelIndicators = otherLabel.indicators;

                if(this.projectTemplateIdsFilter.length > 0) {
                    const labelHasIndicatorsWithFilter = labelIndicators.filter(indicator => this.includesArray(indicator.projecttemplateIds || [], this.projectTemplateIdsFilter)).length > 0;
                    if(!labelHasIndicatorsWithFilter) return null
                }

                labelIndicators.forEach(indicator => {
                    const templatesRelatedToThisIndicator = this.projectTemplates.filter((template) => 
                        template.indicatorIds !== null &&
                        template.indicatorIds.includes(indicator.id)
                    )
                    
                    templatesRelatedToThisIndicator.forEach(template => {
                        if (projectTemplates.map(template_ => template_.id).indexOf(template.id) !== -1) return
                        projectTemplates.push(template)
                    })
                })

                const questions = labelIndicators.filter(indicator => indicator.type === 'question')
                const references = labelIndicators.filter(indicator => indicator.type === 'reference')

                return {
                    ...otherLabel,
                    questions,
                    references,
                    projectTemplates,
                    projectTemplateNames: projectTemplates.map(template => template.name).join(', ')
                }
            })
            .filter(label => label)

            this.otherLabels = otherLabels;
        },
        deleteIndicatorsFromProject() {
            const projectId = this.$route.params.id;
            this.deleteIndicatorsFromProjectLoading = true;

            this.$apollo
                .mutate({
                    mutation: REMOVE_ALL_INDICATORS,
                    variables: {
                        id: projectId,
                        indicatorIds: this.indicatorsToBeDeleted,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    let deletedIndicators = this.projectIndicators.filter((indicator) =>
                        this.indicatorsToBeDeleted.includes(indicator.id)
                    );
                    const projectIndicators = this.projectIndicators.filter(
                        (indicator) => !this.indicatorsToBeDeleted.includes(indicator.id)
                    );
                    const otherIndicators = [...this.otherIndicators, ...deletedIndicators];

                    this.projectIndicators = sortIndicators(projectIndicators);
                    this.otherIndicators = sortIndicators(otherIndicators);

                    this.setProjectLabels();
                    this.setOtherLabels();
                    this.deleteIndicatorsFromProjectLoading = false;
                    this.showLabelDeleteModal = false;
                    this.$store.commit('notify', { type: 'success', message: 'Wijzigingen zijn succesvol doorgevoerd' });
                })
                .catch((error) => {
                    this.deleteIndicatorsFromProjectLoading = false;
                    this.$store.commit('notify', extractError(error));
                });
        },
        addIndicatorsToProject(indicatorIdsToBeAdded) {
            const projectId = this.$route.params.id;
            this.addIndicatorsToProjectLoading = true;

            this.$apollo
                .mutate({
                    mutation: ADD_ALL_INDICATORS,
                    variables: {
                        id: projectId,
                        indicatorIds: indicatorIdsToBeAdded,
                    },
                })
                .then((result) => {
                    let addedIndicators = this.otherIndicators.filter((indicator) =>
                        indicatorIdsToBeAdded.includes(indicator.id)
                    );

                    this.otherIndicators = this.otherIndicators.filter(
                        (indicator) => !indicatorIdsToBeAdded.includes(indicator.id)
                    );

                    this.projectIndicators = [...this.projectIndicators, ...addedIndicators];

                    this.setProjectLabels();
                    this.setOtherLabels();
                    this.addIndicatorsToProjectLoading = false;
                    this.showLabelAddModal = false;
                    this.projectTemplateIdsFilter = [];
                    this.setOtherLabels();
                    this.$store.commit('notify', { type: 'success', message: 'Wijzigingen zijn succesvol doorgevoerd' });
                })
                .catch((error) => {
                    this.addIndicatorsToProjectLoading = false;
                    this.$store.commit('notify', extractError(error));
                });
        },
        toggleShowAllLabels() {
            this.showAllLabels = !this.showAllLabels;
        },
        addLabel() {
            this.showLabelAddModal = true;
            this.addloadingAddLabelModal = false;
        },
        deleteLabel($event, label) {
            this.showLabelDeleteModal = true;

            const indicatorIds = label.indicators.map((indicator) => indicator.id);

            indicatorIds.forEach((id) => {
                if ($event === true) {
                    this.indicatorsToBeDeleted.push(id);
                } else {
                    const index = this.indicatorsToBeDeleted.indexOf(id);
                    this.indicatorsToBeDeleted.splice(index, 1);
                }
            });
            this.indicatorsToBeDeleted = indicatorIds;
        },
        closeLabelAddModal() {
            this.showLabelAddModal = false;
            this.projectTemplateIdsFilter = [];
            this.setOtherLabels();
        },
        closeLabelDeleteModal() {
            this.showLabelDeleteModal = false;
        },
        async getOtherIndicators() {
            try {
    
                const organisationId = this.$store.getters.getCurrentOrganisation.id;
                this.loadingAddLabelModal = true;

                if(!this.whereQuery) this.whereQuery = {
                    AND: [
                        { id__nin: this.indicatorIdsInProject },
                        { organisationId },
                        { status: 'active' }
                    ]
                }

                const indicators = await this.$apollo
                    .query({
                        query: gql`
                            query Indicators($where: JSON) {
                                indicators(where: $where) {
                                    id
                                    name
                                    type
                                    labelIds
                                    projecttemplateIds
                                }
                            }
                        `,
                        variables: {
                            where: this.whereQuery
                        },
                        fetchPolicy: 'no-cache',
                    })
                    .then(result => result.data.indicators );

                const labelIds = indicators.map(indicator => indicator.labelIds[0]);
                const labels = await this.$apollo
                    .query({
                        query: gql`
                            query GetLabels($where: JSON) {
                                labels(where: $where, sort: ["order__ASC"]) {
                                    id
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                AND: [
                                    { id__in: labelIds },
                                    { id__not_contains: 'crow-' },
                                    { status: 'active' },
                                    { name__nin: ['Project_details','Crow_project_details'] }
                                ]
                            },
                        },
                        fetchPolicy: 'no-cache'
                    })
                    .then(result => result.data.labels);
                
                this.labels = labels;
                this.otherIndicators = indicators.map(indicator => {
                    return {
                        ...indicator,
                        context: labels.find(label => label.id === indicator.labelIds[0])
                    }
                });
                
                this.setOtherLabels();
                this.loadingAddLabelModal = false;
            } catch (error) {
                this.$store.commit('notify', extractError(error));
            }
        },
        async getProjectIndicators() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectId = this.$route.params.id;

            const projectJwt = this.getProjectJwt();
            if(!projectJwt) return this.$router.push(`/projects/${this.$route.params.id}`);

            const result = await this.$apollo.query({
                query: GET_PROJECT_REFERENCES,
                variables: {
                    id: projectId,
                    projectJwt
                },
                fetchPolicy: 'no-cache'
            });

            const components = result.data.project.references.components || [];
            const otherContextComponents = components.filter(component => !component.disabled && !['default-context', 'crow-project-details'].includes(component.indicator.labelIds[0]))
            const indicators = otherContextComponents.map(component => component.indicator);
            this.indicatorIdsInProject = indicators.map(indicator => indicator.id);
            this.projectIndicators = indicators;
            
            const result_ = await this.$apollo.query({
                query: gql`
                    query GetTemplates($where: JSON!) {
                        projecttemplates(where: $where) {
                            id
                            name
                            indicatorIds
                        }
                    }
                `,
                variables: {
                    where: {
                        AND: [
                            { organisationId },
                            { status: 'active' }
                        ]
                    }
                },
                fetchPolicy: 'no-cache',
            })
            this.projectTemplates = result_.data.projecttemplates;

            this.setProjectLabels();

            return result;
        },
        getProjectJwt() {
            const project = JSON.parse(localStorage.getItem('projectJwt'));
            if(!project || project.id !== this.$route.params.id) return null
            return project.jwt
        },
        filterUpdated(filter) {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            const activeFilterKeys = Object.keys(filter)
                .filter(field => {
                    switch (field) {
                        case 'projectTemplateNames':
                            return filter[field].filter.length > 0;
                        default:
                            return false;
                    }
                })

            if(activeFilterKeys.length > 0) this.whereQuery = {
                AND: [...activeFilterKeys
                    .map(field => {
                        switch (field) {
                            case 'projectTemplateNames':
                                this.projectTemplateIdsFilter = filter[field].filter;
                                return {
                                    projecttemplateIds__in: filter[field].filter
                                };
                            default:
                                return {}
                        }
                    }),
                    { id__nin: this.indicatorIdsInProject },
                    { organisationId },
                    { type: 'reference' },
                    { status: 'active' }
                ]
            }
            else {
                this.whereQuery = {
                    AND: [
                        { id__nin: this.indicatorIdsInProject },
                        { organisationId },
                        { type: 'reference' },
                        { status: 'active' }
                    ]
                }
                this.projectTemplateIdsFilter = [];
            }
        },
        async projectFilterUpdated(filter) {
            this.filterUpdated(filter);
            this.loadingAddLabelModal = true;
            this.setProjectLabels();
            this.loadingAddLabelModal = false;
        },
        otherFilterUpdated(filter) {
            this.filterUpdated(filter);
            this.setOtherLabels();
        }
    },
    computed: {
        columns: function() {
            return [
                {
                    field: 'label',
                    label: 'Prestatie',
                    width: '150px'
                },
                {
                    field: 'references',
                    label: 'Actieve kenmerken',
                    width: '80px',
                    align: 'center'
                },
                {
                    field: 'questions',
                    label: 'Actieve vragen',
                    width: '60px',
                    align: 'center'
                },
                {
                    field: 'projectTemplateNames',
                    label: 'Projecttemplates',
                    width: '100px',
                    filter: true,
                    showSearchInput: false,
                    filterType: 'checkbox',
                    filterOptions: this.projectTemplates.map(template => { return { label: template.name, value: template.id } })
                }
            ]
        }
    },
    async created() {
        const successfull = await this.getProjectIndicators();
        if (!successfull)
            return this.$store.commit('notify', {
                type: 'danger',
                message: 'Er is iets fout gegaan, probeer het later opnieuw.',
            });
        this.getOtherIndicators();
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 37px;

    .title {
        display: flex;
        flex-direction: row;
    }
}

.table-header {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    min-height: 360px;
    max-height: 360px;
    width: 100%;

    &.hide {
        overflow-y: hidden;
    }
    &.show {
        max-height: unset;
    }
}

tr .templates-with-delete {
    position: relative;
    min-width: 100%;

    .delete-button {
        position: absolute;
        top: 50%;
        right: 0;
        translate: 0 -50%;
        opacity: 0;
        background: white;
    }

}


tr:hover .delete-button {
    opacity: 1;
}

.toggle-show-labels-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-block: 24px;
    border-top: 1px solid $color-grey-3;
    cursor: pointer;

    p {
        font-weight: 500;
    }

    .show-labels-arrow {
        transition: .4s ease;

        &.down {
            rotate: 180deg;
        }
    }
}

.indicator-name {
    max-width: 48ch;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.projecttemplate {
    font-family: 'Gotham';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.9578 1.51353L13.928 0.793962C13.7728 0.333414 13.3184 0 12.7821 0C12.1177 0 11.5791 0.511675 11.5791 1.14286C11.5791 1.19606 11.5829 1.24841 11.5903 1.29967L7.92509 4.78162C7.79821 4.73839 7.66146 4.71484 7.51892 4.71484C7.1814 4.71484 6.87635 4.84689 6.65788 5.05956L3.43707 3.35969C3.4513 3.2894 3.45875 3.21682 3.45875 3.14258C3.45875 2.5114 2.92015 1.99973 2.25574 1.99973C1.59134 1.99973 1.05273 2.5114 1.05273 3.14258C1.05273 3.77376 1.59134 4.28544 2.25574 4.28544C2.45473 4.28544 2.64243 4.23954 2.80776 4.15829L6.32734 6.01584C6.40839 6.57218 6.911 7.00056 7.51892 7.00056C8.18332 7.00056 8.72193 6.48888 8.72193 5.8577C8.72193 5.72198 8.69703 5.59178 8.65132 5.47101L12.1709 2.12744C12.35 2.228 12.5589 2.28571 12.7821 2.28571C13.1955 2.28571 13.5602 2.0876 13.7768 1.78586L16.7267 2.48647C16.7855 2.50043 16.8443 2.50339 16.901 2.49667C17.0578 2.95467 17.5108 3.28571 18.0452 3.28571C18.7096 3.28571 19.2482 2.77404 19.2482 2.14286C19.2482 1.51167 18.7096 1 18.0452 1C17.616 1 17.2392 1.21361 17.0263 1.53504C17.0043 1.52637 16.9814 1.51915 16.9578 1.51353ZM3.21053 8H1V16H3.21053V8ZM1 7C0.447716 7 0 7.44772 0 8V16C0 16.5523 0.447716 17 1 17H3.21053C3.76281 17 4.21053 16.5523 4.21053 16V8C4.21053 7.44772 3.76281 7 3.21053 7H1ZM6.26318 12H8.47371V16H6.26318V12ZM5.26318 12C5.26318 11.4477 5.7109 11 6.26318 11H8.47371C9.026 11 9.47371 11.4477 9.47371 12V16C9.47371 16.5523 9.026 17 8.47371 17H6.26318C5.7109 17 5.26318 16.5523 5.26318 16V12ZM13.7369 10H11.5264V16H13.7369V10ZM11.5264 9C10.9741 9 10.5264 9.44771 10.5264 10V16C10.5264 16.5523 10.9741 17 11.5264 17H13.7369C14.2892 17 14.7369 16.5523 14.7369 16V10C14.7369 9.44771 14.2892 9 13.7369 9H11.5264ZM16.7896 6H19.0001V16H16.7896V6ZM15.7896 6C15.7896 5.44771 16.2373 5 16.7896 5H19.0001C19.5524 5 20.0001 5.44772 20.0001 6V16C20.0001 16.5523 19.5524 17 19.0001 17H16.7896C16.2373 17 15.7896 16.5523 15.7896 16V6Z"
            fill="url(#paint0_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-3.82526e-09"
                y1="17.0834"
                x2="20.0005"
                y2="17.0834"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#672583" />
                <stop offset="0.505208" stop-color="#006B96" />
                <stop offset="1" stop-color="#00A1AE" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'ChartGradient',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 22,
        },
        height: {
            type: [Number, String],
            default: 17,
        },
    },
};
</script>

<style></style>

<template>
    <div class="panel q-card-small">
        <h3>Projectnaam wijzigen</h3>
        <p>Wijzig hier de naam van je project. Deel je dit project met een andere opdrachtgever of opdrachtnemer dan wijzig je alleen de
        naam waarmee dit project in jullie omgeving staat. De originele titel blijft wel zichtbaar in je project zodat projecten
        altijd herleidbaar zijn.</p>
        <div class="input-container" :class="{ hidden: loading || updatingName }">
            <q-clear-input 
                v-model="projectName" 
                size="extralarge"
                placeholder="Projectnaam"></q-clear-input>
        </div>
        <div class="button-container">
            <q-button variation="secondary" :disabled="loading" :loading="updatingName" @click="handleChangeName">Projectnaam wijzigen</q-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'project-change-name',
    props: {
        project: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            projectName: '',
            show: false,
            updatingName: false
        }
    },
    methods: {
        setProjectName() {
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}ProjectName`;
            this.projectName = this.project[key] || '';
        },
        handleChangeName() {
            this.updatingName = true;
            this.$emit('changeName', this.projectName);
        }
    },
    created() {
        this.setProjectName();
    },
    watch: {
        project: function() {
            this.updatingName = false;
            this.setProjectName();
        }
    }
}
</script>

<style lang="scss" scoped>

.panel {
    max-width: 900px;
    width: 100%;

    h3 {
        padding: 32px 40px 0 40px;
    }

    p {
        padding: 20px 40px 0 40px;
    }

    .input-container {
        padding: 20px 40px 0 40px;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .4s ease;

        &.hidden {
            max-height: 0 !important;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        padding: 20px 40px 32px 40px;
    }
    &:hover, 
    &:focus-within {
        .input-container {
            max-height: 60px;
        }
    }
}

</style>
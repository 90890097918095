var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-card",class:{
    'empty-card': _vm.tile.empty,
    'tile-loading': _vm.tile.loading,
    'not-finished': !_vm.tile.name && !_vm.tile.loading,
    disabled: _vm.tile.empty || _vm.tile.loading,
  }},[_c('div',{staticClass:"card-content",on:{"click":_vm.handleReportSelect}},[(!_vm.tile.empty)?_c('div',{staticClass:"head"},[_c('div',[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.tile.label))]),_vm._v(" "),_c('p',{staticClass:"name",class:[
            Boolean(!_vm.tile.name) ? 'default' : '',
            _vm.tile.loading ? 'loading' : 'show',
          ]},[_vm._v("\n          "+_vm._s(_vm.tile.name ? _vm.tile.name : "Rapportage nog niet afgerond")+"\n        ")])]),_vm._v(" "),(_vm.hasCrowReport || _vm.isFormTemplatesReportDefault)?_c('div',{staticClass:"logo"},[_c('img',{staticClass:"folded-img",attrs:{"src":"/statics/img/Qfact-logo-small.png","alt":""},on:{"click":function($event){return _vm.to('/')}}})]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.tile.empty)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"left footer-info",class:_vm.tile.loading ? 'loading' : 'show'},[_c('p',[_vm._v("Laatst gewijzigd")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(new Date(_vm.tile.lastUpdated).toLocaleString("nl-NL", {
              month: "long",
              day: "numeric",
              year: "numeric",
            }))+"\n        ")])]),_vm._v(" "),(_vm.tile.owners && _vm.tile.owners.length == 1)?_c('div',{staticClass:"right owner",class:_vm.tile.loading ? 'loading' : 'show'},[_c('div',{staticClass:"footer-info"},[_c('p',[_vm._v("Eigenaar")]),_vm._v(" "),_c('p',{staticClass:"name"},[_vm._v("\n            "+_vm._s(_vm.tile.owners[0].firstName)+" "+_vm._s(_vm.tile.owners[0].lastName)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"avatars"},_vm._l((_vm.tile.owners),function(owner,index){return _c('q-avatar',{key:index,staticClass:"avatar",style:(`z-index: ${10 - index}`),attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.getUserInitials(owner.firstName, owner.lastName)))])}),1)]):(_vm.tile.owners && _vm.tile.owners.length > 1)?_c('div',{staticClass:"owners-avatars"},_vm._l((_vm.tile.owners),function(owner,index){return _c('q-avatar',{key:index,staticClass:"avatar",style:(`z-index: ${10 - index + 1}`),attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.getUserInitials(owner.firstName, owner.lastName)))])}),1):_vm._e()]):_vm._e()]),_vm._v(" "),(
      !_vm.tile.empty &&
      !_vm.tile.loading &&
      _vm.canEdit &&
      !_vm.hasCrowReport &&
      !_vm.isFormTemplatesReportDefault
    )?_c('q-options',{staticClass:"options",attrs:{"options":_vm.options,"color":"#C4C4C4"},on:{"input":_vm.handleSelectOption}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
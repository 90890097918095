<template>
    <svg :width="width" :height="height" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 1H8V4V5.03078V19H11V13V12H12H13.5H14.5H16H17V13V19H20V1ZM13.5 13H12V19H13.5V13ZM14.5 19V13H16V19H14.5ZM14.5 20H13.5H12H11H8H7H1H0V19V7.56155C0 6.64382 0.624595 5.84385 1.51493 5.62127L7 4.25V1V0H8H20H21V1V19V20H20H17H16H14.5ZM1.75746 6.59141L7 5.28078V19H1V16.5645H3.41935C3.6955 16.5645 3.91935 16.3406 3.91935 16.0645C3.91935 15.7883 3.6955 15.5645 3.41935 15.5645H1V13.5322H3.41935C3.6955 13.5322 3.91935 13.3084 3.91935 13.0322C3.91935 12.7561 3.6955 12.5322 3.41935 12.5322H1V10.5H3.41935C3.6955 10.5 3.91935 10.2761 3.91935 10C3.91935 9.72386 3.6955 9.5 3.41935 9.5H1V7.56155C1 7.10268 1.3123 6.7027 1.75746 6.59141ZM9.5 4C9.5 3.72386 9.72386 3.5 10 3.5H12.4194C12.6955 3.5 12.9194 3.72386 12.9194 4C12.9194 4.27614 12.6955 4.5 12.4194 4.5H10C9.72386 4.5 9.5 4.27614 9.5 4ZM15.8387 3.5C15.5626 3.5 15.3387 3.72386 15.3387 4C15.3387 4.27614 15.5626 4.5 15.8387 4.5H18.2581C18.5342 4.5 18.7581 4.27614 18.7581 4C18.7581 3.72386 18.5342 3.5 18.2581 3.5H15.8387ZM9.5 7C9.5 6.72386 9.72386 6.5 10 6.5H12.4194C12.6955 6.5 12.9194 6.72386 12.9194 7C12.9194 7.27614 12.6955 7.5 12.4194 7.5H10C9.72386 7.5 9.5 7.27614 9.5 7ZM15.8387 6.5C15.5626 6.5 15.3387 6.72386 15.3387 7C15.3387 7.27614 15.5626 7.5 15.8387 7.5H18.2581C18.5342 7.5 18.7581 7.27614 18.7581 7C18.7581 6.72386 18.5342 6.5 18.2581 6.5H15.8387ZM9.5 10C9.5 9.72386 9.72386 9.5 10 9.5H12.4194C12.6955 9.5 12.9194 9.72386 12.9194 10C12.9194 10.2761 12.6955 10.5 12.4194 10.5H10C9.72386 10.5 9.5 10.2761 9.5 10ZM15.8387 9.5C15.5626 9.5 15.3387 9.72386 15.3387 10C15.3387 10.2761 15.5626 10.5 15.8387 10.5H18.2581C18.5342 10.5 18.7581 10.2761 18.7581 10C18.7581 9.72386 18.5342 9.5 18.2581 9.5H15.8387Z"
            fill="url(#paint0_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="-4.0165e-09"
                y1="20.0981"
                x2="21.0004"
                y2="20.0981"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#672583" />
                <stop offset="0.505208" stop-color="#006B96" />
                <stop offset="1" stop-color="#00A1AE" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'BuildingGradient',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 21,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
    },
};
</script>

<style></style>

<template>
    <div class="full-width min-height">
        <div class="justify-center">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)"
                    >Profiel</arrow-button
                >
            </div>
        </div>
        <div class="justify-center">
            <div class="settings-intro">
                <h1>Organisatiebeheer</h1>
                <p>Bekijk en beheer je toegang tot organisaties. Vraag toegang aan en bekijk de status van je aanvragen.</p>
            </div>
        </div>

        <div class="justify-center">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>Ontvangen uitnodigingen</h3>
                    <p>Bekijk en beheer hier de uitnodigingen die je van organisaties hebt ontvangen.</p>
                </div>
                <div class="org-container">
                    <p v-if="invitedOrganisations.length === 0" class="empty">Je hebt geen uitnodigingen voor organisaties.</p>
                    <div v-for="organisation in invitedOrganisations" :key="organisation.id" class="org">
                        <div class="left">
                            <q-avatar size="small" :img="organisation.logo"></q-avatar>
                            <div class="title">
                                <p class="org-name">{{ organisation.name }}</p>
                                <p class="org-address">{{ getAddress(organisation.address) }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <q-tag variation="default" size="small">{{ $t(organisation.user.role.name) }}</q-tag>
                            <q-button
                                class="accept"
                                variation="primary"
                                :loading="acceptInviteLoading === organisation.id"
                                @click="acceptInvite(organisation.id)">Accepteren</q-button>

                            <q-button
                                class="reject"
                                variation="ghost"
                                :loading="cancelInviteLoading === organisation.id"
                                @click="cancelInvite(organisation.id)">Afwijzen</q-button>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

        <div class="justify-center">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>In afwachting van goedkeuring</h3>
                    <p>Bekijk en beheer hier de verzoeken die je hebt gedaan voor toegang tot organisaties.</p>
                </div>
                <div class="org-container">
                    <p v-if="requestedOrganisations.length === 0" class="empty">Je hebt geen toegang aangevraagd voor organisaties.</p>
                    <div v-for="organisation in requestedOrganisations" :key="organisation.id" class="org">
                        <div class="left">
                            <q-avatar size="small" :img="organisation.logo"></q-avatar>
                            <div class="title">
                                <p class="org-name">{{ organisation.name }}</p>
                                <p class="org-address">{{ getAddress(organisation.address) }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <q-button
                                class="reject"
                                variation="secondary"
                                :loading="cancelConfirmLoading === organisation.id"
                                @click="cancelOrganisation(organisation.id)">Verzoek annuleren</q-button>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>


        <div class="justify-center">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>Toegang</h3>
                    <p>Bekijk en beheer hier de organisaties waar je momenteel toegang toe hebt. </p>
                </div>
                <div class="org-container">
                    <p v-if="activeOrganisations.length === 0" class="empty">Je hebt nog geen toegang tot organisaties.</p>
                    <div v-for="organisation in activeOrganisations" :key="organisation.id" class="org">
                        <div class="left">
                            <q-avatar size="small" :img="organisation.logo"></q-avatar>
                            <div class="title">
                                <p class="org-name">{{ organisation.name }}</p>
                                <p class="org-address">{{ getAddress(organisation.address) }}</p>
                            </div>
                        </div>
                        <div class="right">
                            <q-tag variation="default" size="small">{{ $t(organisation.user.role.name) }}</q-tag>
                            <q-icon class="icon" type="lockClosed"></q-icon>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

    </div>
</template>

<script>
import {
    CANCEL_ORGANISATION_ACCESS,
    ACCEPT_ORGANISATION_INVITE,
    DECLINE_ORGANISATION_INVITE
} from '@/graphql/mutations';
import { ORG_MINE } from '@/graphql/queries';

import { getAddress, extractError } from '@/assets/js/utils.js';
import ArrowButton from '@/components/ArrowButton';

export default {
    name: 'OrganisationsSettings',
    components: {
        ArrowButton
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            organisations: [],
            activeOrganisations: [],
            requestedOrganisations: [],
            invitedOrganisations: [],
            cancelConfirmLoading: '',
            acceptInviteLoading: '',
            cancelInviteLoading: '',
            timeout: null
        };
    },
    methods: {
        acceptInvite(id) {
            this.acceptInviteLoading = id;

            this.$apollo
                .mutate({
                    mutation: ACCEPT_ORGANISATION_INVITE,
                    variables: {
                        id: this.user.id,
                        organisationId: id
                    }
                })
                .then(() => {
                    this.$store.commit('notify', {
                        message: 'Uitnodiging geaccepteerd',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getOrganisations()
                        this.acceptInviteLoading = '';
                    }, 1000);
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        cancelInvite(id) {
            this.cancelInviteLoading = id;

            this.$apollo
                .mutate({
                    mutation: DECLINE_ORGANISATION_INVITE,
                    variables: {
                        id: this.user.id,
                        organisationId: id
                    }
                })
                .then(() => {
                    this.$store.commit('notify', {
                        message: 'Uitnodiging afgewezen',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getOrganisations()
                        this.cancelInviteLoading = '';
                    }, 1000);
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        myRole(org) {
            const myUser = org.users.find(user => user.userId === this.$store.getters.getUser.id)
            return myUser.role.name;
        },
        cancelOrganisation(id) {
            this.cancelConfirmLoading = id;

            this.$apollo
                .mutate({
                    mutation: CANCEL_ORGANISATION_ACCESS,
                    variables: {
                        id: this.user.id,
                        organisationId: id
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`
                        }
                    }
                })
                .then(response => {
                    this.$store.commit('notify', {
                        message: 'Verzoek geannuleerd',
                        type: 'success'
                    });
                    
                    setTimeout(() => {
                        this.getOrganisations()
                        this.cancelConfirmLoading = '';
                    }, 1000);
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });

        },
        getAddress(address) {
            return getAddress(address);
        },
        getOrganisations() {
            const vm = this;

            this.$apollo.query({
                query: ORG_MINE,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                if (!(result.data.org_mine instanceof Array)) return;
            
                const userId = vm.$store.getters.getUser.id;
                
                const organisations = result.data.org_mine || [];

                const activeOrganisations = []
                const requestedOrganisations = []
                const invitedOrganisations = [];

                organisations.forEach(organisation => {
                    const user = organisation.users.find(user => user.userId === userId)

                    if (!user) return;

                    organisation.user = user;

                    if (user.status === 'active') {
                        activeOrganisations.push(organisation);
                    } else if (user.status === 'requested') {
                        requestedOrganisations.push(organisation);
                    } else if (user.status === 'invited') {
                        invitedOrganisations.push(organisation);
                    }

                })
                
                vm.activeOrganisations = activeOrganisations;
                vm.requestedOrganisations = requestedOrganisations
                vm.invitedOrganisations = invitedOrganisations;               

            })
            .catch(error => {
                this.$store.commit('notify', extractError(error));
            })
        }
    },
    created() {
        this.getOrganisations();
    }
};

</script>

<style lang="scss" scoped>

@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';

.action {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action > .icon {
    margin-top: 7px;
}

.empty {
    height: 18px;
}

.end {
    display: flex;
    justify-content: flex-end;
}

.head {
    padding: 9px 14px 14px 14px;
}

.org {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;

    padding: 10px 15px;
    border: 1px solid $color-grey-3;
    border-radius: 5px;
    align-items: center;
    margin-bottom: 10px;

    .left {
        display: flex;
        align-items: center;

        .title {
            text-align: left;
        }
    }

    .right {
        display: flex;
        align-items: center;
        
        * {
            margin-left: 10px;
        }

        .accept, .reject {
            width: 180px;
        }

        .icon {
            margin-left: 20px;
        }
    }
}

.org > .top {
    height: 80%;
}

.org > .image {
}

.org-name {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
}

.org-address {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding: 0 10px;
    color: #828282;
}

.org-container {
    display: flex;
    flex-wrap: wrap;
}

.org-block > .intro > h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
}

.org-block > .intro > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 14px;
    margin-bottom: 21px;
}

.intro {
    text-align: left;
}

.org-block {
    width: 800px;
}

.full-width {
    width: 100%;
}

.min-height {
    min-height: 100vh;
}

.settings-intro {
    width: 800px;
    text-align: left;

    h1 {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 31px;
        line-height: 33px;
    }

    p {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-top: 11px;
        margin-bottom: 24px;
    }
}
.settings-back {
    width: 800px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 5px;
}
.org-container-label {
    position: absolute;
    bottom: 18px;
    // left: 18px;
}

.registerButton {
    position: absolute;
    bottom: -10px;
    left: 295px;

    &:disabled{
        pointer-events: none;
    }
}

.empty {
    color: #828282;
    font-style: italic;
}
</style>

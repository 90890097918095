<template>
    <div :id="'question' + number" class="question" :style="'width:' + width">
        <div class="left" v-if="number">
            <div class="number">
                <span class="label">Stap {{ number }}</span>
                <div v-if="previousAgreements.length > 0" class="previousAgreements">
                    <q-icon
                        @click="openAgreements"
                        class="shaking-hands"
                        type="ShakingHands"
                        :width="30"
                        :height="30"
                        color="black"
                    />
                    <q-arrowbox
                        v-if="showAgreements"
                        class="arrowbox"
                        :width="360"
                        :height="310"
                        title="Afspraken"
                        id="previousAgreements"
                    >
                        <q-timeline :items="previousAgreements">
                            <template v-slot:row="{ item }">
                                <div class="timeline-top">
                                    <div>
                                        <q-avatar class="avatar" :img="item.userAvatar" size="small">{{
                                            getInitials(item.userName)
                                        }}</q-avatar>
                                    </div>
                                    <p class="name">
                                        {{ item.userName }}
                                        <span v-if="item.timestamp"> {{ formatDate(item.timestamp) }}</span>
                                    </p>
                                </div>
                                <div class="timeline-content">
                                    <p>{{ item.message }}</p>
                                </div>
                            </template>
                        </q-timeline>
                    </q-arrowbox>
                </div>
            </div>
        </div>

        <div></div>

        <div class="right" :style="'width: calc( ' + width + ' - 93px )'">
            <div v-if="name" class="head">
                <div class="head-left">
                    <div class="title">
                        <h3 v-html="name"></h3>
                    </div>
                    <div class="about">
                        <p v-html="about"></p>
                    </div>
                </div>
                <div v-if="imageHref" class="head-right step-image">
                    <span><img :src="imageHref" alt="Data representatie" /></span>
                </div>
            </div>

            <div class="body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Registering a new questiontype component:
 *
 * - Import module
 * - add to const 'types'
 * - add to components with extension '-question'
 */

import QRadio from './steps/RadioStep';
import QTextQuestion from './questions/TextQuestion';
import QText from '../components/QText';

import _ from 'lodash';
import { getDate } from '../../../assets/js/utils';

const types = ['radio', 'text'];

export default {
    name: 'q-step',
    props: {
        imageHref: {
            type: String, 
            default: null
        },
        /**
         * Width of the component eg. '500px' or '80%'
         */
        width: {
            type: String,
            default: '800px',
        },
        /**
         * number shown in count label on the left side
         */
        number: {
            type: [String, Number],
        },
        /**
         * Name listed at the top of the question
         */
        name: {
            type: String,
        },
        /**
         * About text under name
         */
        about: {
            type: String,
        },
        /**
         * Options which are neccesary for certain questiontypes e.g. choises for radio or checkbox
         */
        options: {
            type: Array,
        },
        /**
         * v-model
         */
        value: {
            type: [Array, Object, String, Number],
        },
        /**
         * Settings about the question. Structure:
         *  - satisfied (boolean: should show satisfied textfield)
         *  - improvement (boolean: should show improvement textfield)
         */
        settings: {
            type: Object,
            default: () => {
                return {
                    satisfied: false,
                    improvement: false,
                };
            },
        },
        /**
         * Amount of characters allowed in settings' textfields
         */
        settingsMaxChars: {
            type: String,
            default: '300',
        },
        /**
         * Unique id, of the question. Some questiontypes require it
         */
        id: {
            type: [String, Number],
            required: true,
        },
        /**
         * Wether the question can be answered or not
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * List of previously made agreements for the question
         */
        previousAgreements: {
            type: Array,
            default: () => [],
        },
        /**
         * List of previously given values to this component
         */
        previousValues: {
            type: Array,
            default: () => [],
        },
        /**
         * Wether the question should use
         */
        useCrowComments: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        'radio-step': QRadio,
        'text-question': QTextQuestion,
        'q-text': QText,
    },
    data() {
        return {
            answer: null,
            satisfied: null,
            improvement: null,
            scrollEventListener: true,
            showAgreements: false,
            inputBlurred: false,
        };
    },
    methods: {
        /**
         * It is the responsibility of the childcomponents (questiontype specific) to return the answer (this.$parent.returnAnswer())
         */
        returnAnswer() {
            this.$emit('input', this.answer);
        },
        openAgreements() {
            this.$store.commit('resetBodyListeners');

            this.showAgreements = !this.showAgreements;

            if (this.showAgreements)
                this.$store.commit('addBodyListener', [
                    'previousAgreements',
                    () => {
                        this.showAgreements = false;
                    },
                ]);
        },
        getInitials(name) {
            return name
                .split(' ')
                .map((subText) => (subText ? subText[0] : ''))
                .join('');
        },
        formatDate(timestamp) {
            return `op ${getDate(timestamp)}`;
        },
    },
    computed: {
        showComment: function () {
            return this.settings && this.settings.comment;
        },
        showAgreement: function () {
            return this.settings && this.settings.agreement;
        },
        previousOption: function () {
            if (!this.options) return null;
            if (!this.previousValues) return null;
            if (this.previousValues.every((value) => !value.timestamp)) return null;

            let highest = 0;
            let value = null;
            this.previousValues.forEach((value_) => {
                if (value_.timestamp > highest) {
                    highest = value_.timestamp;
                    value = value_;
                }
            });

            return value;
        },
        showDefaultMetaInput: function () {
            return this.settings && (this.settings.satisfied || this.settings.improvement) && !this.useCrowComments;
        },
        showCrowMetaInput: function () {
            return this.settings && this.settings.satisfied && this.useCrowComments;
        },
    },
    created() {
        this.answer = this.value;
    },
};
</script>

<style scoped lang="scss">
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

$total: 664px;
$left: 93px;
$right: calc($total - $left);

* {
    color: $color-grey-9;
    font-family: Gotham;
    font-style: normal;
}

.question {
    display: flex;
    flex-direction: row;

    .left {
        width: $left;
        flex: 0 0 $left;

        .number {
            span.label {
                font-family: Gotham;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                background: #f2f2f2;
                border: 1px solid #dee2e6;
                border-radius: 4px;
                color: $color-grey-7;
                padding: 4px;
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        gap: 36px;

        .head {
            display: flex;
            justify-content: space-between;
            width: 100%;
            
            .head-left {
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: 100%;
        
                .title > h3 {
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 24px;
                    color: $color-grey-9;
                }
            }

            .step-image {
                margin-left: 50px;
                margin-top: 10px;
            }
        
        }
    }
}


.body {
    display: flex;
    justify-content: center;

    &:empty {
        display: none;
    }
}

.other {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;

    width: 100%;

    .fields {
        width: 100%;

        .field {
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            width: 100%;
            color: $color-grey-7;

            .required-tag {
                float: right;
                transition: 0.4s ease;

                &.hide {
                    transform: translateY(30px);
                }
            }
        }

        & div:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.comment-label {
    margin: 0 0 10px 0;
}

.previousAgreements {
    position: relative;
    margin: 10px 0 0 8px;
    z-index: 20;
}

.arrowbox {
    position: absolute;
    top: 14px;
    left: -50px;
}

.timeline-top {
    display: flex;

    .avatar {
        margin: -5px 20px 0 0;
    }

    .name {
    }
}

.timeline-content {
    width: 100%;
    margin: 5px 0 0 4px;
    word-wrap: break-word;
}

.shaking-hands {
    cursor: pointer;
}

.comment-label {
    margin: 0 0 10px 0;
}
</style>

<template>
    <auth-base>
        <div class="loader"></div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { REFRESH } from '@/graphql/mutations';
import { handleLoginSuccess, extractError } from '@/assets/js/utils';

export default {
    name: 'SsoCallback',
    components: {
        AuthBase,
    },
    methods: {
        refreshToken(refreshToken, jwt) {
            const vm = this;

            this.$apollo
                .mutate({
                    mutation: REFRESH,
                    variables: { refreshToken },
                    context: {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((res) => {
                    return handleLoginSuccess(vm, res.data.user_refreshJwt);
                })
                .catch((err) => {
                    console.log('SSO inlogpoging mislukt', err);
                    if (err) vm.$router.go(-2);
                });
        },
    },
    created() {
        const appDisabled = process.env.BLOCK_LOGIN || false;
        if (appDisabled) {
            this.$router.push('/login');
        } else {
            const { refresh, jwt } = this.$route.query;
            this.refreshToken(refresh, jwt);
        }
    },
};
</script>

<style lang="scss" scoped>
.loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.28613" y="0.5" width="7.35714" height="9.21429" rx="0.5" fill="white" :stroke="color"/>
        <rect x="1.85742" y="1.85712" width="7.42857" height="9.28571" fill="white"/>
        <rect x="0.5" y="3.28571" width="7.35714" height="9.21429" rx="0.5" fill="white" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Duplicate',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 12
        },
        height: {
            type: [Number, String],
            default: 13
        }
    }
};
</script>
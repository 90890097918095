<template>
    <q-step :number="index" :id="index" :name="step.name" :about="step.about" :value="step.value" class="step" width="90%">
        <div class="logos-container">
            <q-logo-card
                v-for="logo, index in computedLogos"
                :key="index"
                :src="logo.src"
                interactive
                :selected="selectedLogoIndex === index"
                :options="getLogoCardOptions(logo)"
                @click.native="handleSelectLogo(logo, index)"
                @optionSelected="handleOptionSelected(logo, $event)"
            ></q-logo-card>
            <div v-if="!uploadedLogo" class="upload-logo-wrapper">
                <input type="file" id="myFile" name="filename" accept="image/*" @change="uploadFile">
                <label class="add-logo-card upload-button" for="myFile">
                    <span class="upload-button">Selecteer bestand</span>
                </label>
            </div>
        </div>
    </q-step>
</template>

<script>
export default {
    name: 'style-step-logo',
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        selectedStyle: {
            type: Object,
            required: true
        },
        availableLogos: {
            type: Array,
            default() { return [] }
        }
    },
    data() {
        return {
            logoCardOptions: [
                {
                    name: 'Logo verwijderen',
                    color: 'red'
                }
            ],
            selectedLogoIndex: null,
            isUploadingLogo: false,
            uploadedLogo: null,
            logoDeleted: false
        }
    },
    methods: {
        handleSelectLogo(logo, index) {
            if(this.logoDeleted) return this.logoDeleted = false
            this.selectedLogoIndex = index;
            this.selectedStyle.logo = logo.src;
        },
        handleOptionSelected(logo, option) {
            if(option.name === 'Logo verwijderen') {
                // verwijder optie
                this.logoDeleted = true;
                this.uploadedLogo = null;
                this.selectedStyle.logo = null;
            }
        },
        uploadFile(event) {
            const UPLOAD_ENDPOINT = `${process.env.UPLOAD_URL}/logos`;
            const authorizationHeader = `Bearer ${this.$store.getters.getJwt}`;
            let v = this;

            const file = event.target.files[0];
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const userId = this.$store.getters.getUser.id;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('organisationId', organisationId);
            formData.append('userId', userId);

            this.isUploadingLogo = true;

            this.$http
                .post(UPLOAD_ENDPOINT, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: authorizationHeader,
                        'application-context': 'application-context'
                    },
                })
                .then((response) => {
                    const file = response.data;
                    this.uploadedLogo = { src: file.filePath };
                    this.handleSelectLogo(this.uploadedLogo, this.availableLogos.length);

                    this.$store.commit('notify', { type: 'success', message: `Bestand ${file.fileName} is succesvol geupload` })

                    this.isUploadingLogo = false;
                })
                .catch((error) => {
                    this.isUploadingLogo = false;
                });
        },
        getLogoCardOptions(logo) {
            if(!this.uploadedLogo) return []
            if(logo.styleId !== this.uploadedLogo.id) return []
            return [
                {
                    name: 'Logo verwijderen',
                    color: 'red'
                }
            ]
        },
        updateSelection() {
            if(this.selectedStyle.logo) {
                const hasEmpty = Boolean(this.availableLogos.find(option => option.name === 'empty'));
                if(this.selectedStyle.logo !== 'empty') {
                    this.uploadedLogo = { src: this.selectedStyle.logo };
                    const selectedIndex = hasEmpty ? this.availableLogos.length - 1 : this.availableLogos.length;
                    this.selectedLogoIndex = selectedIndex;
                }
                else this.selectedLogoIndex = this.availableLogos.length;
            }
        }
    },
    computed: {
        computedLogos: function() {
            const emptyOption = {
                logo: 'empty',
                src: 'empty'
            }
            let options = [];

            if(this.uploadedLogo && !this.isUploadingLogo) options = [ ...this.availableLogos, this.uploadedLogo ]
            else options = this.availableLogos

            if(!options.find(option => option.src === 'empty')) options.push(emptyOption);

            return options
        }
    },
    created() {
        this.updateSelection();
    },
    watch: {
        availableLogos: function() {
            this.updateSelection();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.step {
    justify-content: center;

    .logos-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        input {
            display: none;
        }
    }
}

.popup-color {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 32px;

    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

.add-logo-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 200px;
    outline: 1px dashed #b6bdc1;
    border-radius: 6px;
    cursor: pointer;

    transition: scale .2s cubic-bezier(.45,1.8,.58,1.21);

    &:active {
        scale: 0.97 !important;
    }

    &:hover {
        scale: 1.02;
    }

    .upload-button {
        background-color: $color-secondary;
        color: #fff;
        border-radius: 4px;
        font-weight: 500;
        font-size: 13px;
        padding: 6px 12px;
    }
}

</style>
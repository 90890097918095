<template>
    <div>
        <div class="filter-box">
            <div class="dropdown">
                <q-tooltip :disabled="!showWarningTooltip">
                    <template #tooltip>Let op, verwisselen van type overschrijft bestaande voorwaarden</template>
                    <q-dropdown :options="dropdownOptions" v-model="value.indicator" placeholder="Kenmerken" @input="handleFilterUpdated" noBorder></q-dropdown>
                </q-tooltip>
            </div>
            <div class="search-box-wrapper">
                <input type="text" v-model="searchValue" @input="_handleSearchUpdated" placeholder="Typ om te zoeken">
                <!-- <div class="query-results">1698 resultaten</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'indicator-filter',
    props: {
        // this is the filter object of the parent (parameters: search & indicator)
        value: {
            type: Object,
            required: true
        },
        dropdownOptions: {
            type: Array, 
            default() { return [] }
        },
        showWarningTooltip: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchValue: '',
        }
    },
    methods: {
        handleFilterUpdated() {
            this.$emit('filterUpdated', this.value);
        },
        _handleSearchUpdated: _.debounce(function() {
            this.$emit('searchUpdated', this.searchValue);
        }, 250)
    },
}
</script>

<style lang="scss" scoped>

    .filter-box {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid rgba(222, 226, 230, 0.5);
        box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.05);
        border-radius: 3px;

        .dropdown {
            display: flex;
            justify-content: space-between;
            padding-inline: 8px;
            width: 200px;
            border-right: 1px solid rgba(222, 226, 230, 0.5);
        }

        .search-box-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            

            input {
                width: calc(100% - 60px);
                border: none;
                outline: none;
                padding: 18px 30px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    color: #ADB5BD;
                }
            }


            .query-results {
                position: absolute;
                transform: translate(0, -50%);
                top: 50%;
                right: 20px;
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: #C4C4C4;
            }
            
        }
    }

</style>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:1.41421;"
  >
    <circle cx="6" cy="6" r="5.5" :stroke="color" fill="transparent"/>
    <path d="M8.82806 7.95691C9.08041 7.84476 9.19406 7.54928 9.08191 7.29694C8.96976 7.04459 8.67428 6.93094 8.42194 7.04309L8.82806 7.95691ZM3.57807 7.04311C3.32572 6.93096 3.03024 7.04461 2.91809 7.29695C2.80594 7.54929 2.91959 7.84477 3.17193 7.95692L3.57807 7.04311ZM8.42194 7.04309C7.96105 7.24792 7.01973 7.37499 6 7.375C4.98026 7.37501 4.03895 7.24794 3.57807 7.04311L3.17193 7.95692C3.83605 8.25208 4.95724 8.37501 6 8.375C7.04277 8.37499 8.16395 8.25206 8.82806 7.95691L8.42194 7.04309Z" :fill="color"/>
    <circle cx="4.3125" cy="4.6875" r="0.9375" :fill="color"/>
    <circle cx="7.6875" cy="4.6875" r="0.9375" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "SlightlyHappyEmoji",
  props: {
    color: {
      type: String,
      default: "#FD7E14",
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
};
</script>

<style></style>

<template>
    <div>
        <div class="modal">
            <div class="vertical-center">
                <h2>Rol toegang geven</h2>
            </div>

            <div class="dropdown">
                <q-dropdown
                    size="medium"
                    :options="roles"
                    placeholder="Zoek op naam of kies uit de lijst"
                    v-model="role"
                    autocomplete
                    >Rol</q-dropdown
                >
            </div>

            <div>
                <p class="vertical-center mt-m">
                    <span style="margin-right: 8px; font-weight: 500">Toegang</span>
                    <q-tooltip position="top">
                        <template v-slot:tooltip>
                            <p>
                                Sleep het rondje naar links en rechts (klikken en vasthouden) om de toegang te
                                veranderen.
                            </p>
                        </template>
                        <div class="question-circle">
                            <q-icon type="QuestionCircle"></q-icon>
                        </div>
                    </q-tooltip>
                </p>

                <q-slider
                    style="margin: 16px -8px 24px -8px"
                    :min="0"
                    :max="reportRoles.length - 1"
                    :steps="1"
                    :stepLabels="sliderStepsAccess"
                    v-model="selectedAccessRole"
                ></q-slider>
                <h4 class="mb-xs">
                    {{ $t(`reportRoles.${reportRoles[selectedAccessRole].name}`) }}
                </h4>
                <p>{{ reportRoles[selectedAccessRole].description }}</p>
            </div>
            <!-- <div>
                <p class="vertical-center mt-m">
                    <span style="margin-right: 8px; font-weight: 500">Filters</span>
                    <q-tooltip position="top">
                        <template v-slot:tooltip>
                            <p>
                                Sleep het rondje naar links en rechts (klikken en vasthouden) om de filters te
                                veranderen.
                            </p>
                        </template>
                        <div class="question-circle">
                            <q-icon type="QuestionCircle"></q-icon>
                        </div>
                    </q-tooltip>
                </p>

                <q-slider
                    style="margin: 16px -8px 24px -8px"
                    :min="0"
                    :max="reportFilters.length - 1"
                    :steps="1"
                    :stepLabels="sliderStepsFilter"
                    v-model="selectedFilterRole"
                ></q-slider>
                <h4 class="mb-xs">
                    {{ $t(`reportFilters.${reportFilters[selectedFilterRole].name}`) }}
                </h4>
                <p>{{ reportFilters[selectedFilterRole].description }}</p>
            </div> -->
        </div>

        <div class="button">
            <q-button size="medium" :disabled="false" variation="primary" @click="handleAccessRole">Geef toegang</q-button>
        </div>
    </div>
</template>

<script>
import { ROLES } from '../../../graphql/queries';
import { userInitials } from '@/assets/js/utils';

const REPORT_ROLES = [
    {
        name: 'view',
        description: 'Alleen inzien van de gemaakte rapportage.',
    },
    {
        name: 'update',
        description: 'Inzien en bewerken van rapportage voorwaarden en gegevens',
    },
    {
        name: 'admin',
        description: 'Inzien, bewerken, verwijderen van rapportage, nieuwe gebruikers toevoegen.',
    },
];

const REPORT_FILTERS = [
    {
        name: 'noFilters',
        description: 'Gebruiker kan in de resultaten van de rapportage niet verder doorfilteren',
    },
    {
        name: 'allFilters',
        description: 'Gebruiker kan in de resultaten van de gemaakte rapportage gebruik maken van filters om zo specifieke gegvens naar voren te halen.',
    },
];

export default {
    data() {
        return {
            role: '',
            roles: [],
            reportRoles: REPORT_ROLES || [],
            reportFilters: REPORT_FILTERS || [],
            selectedAccessRole: 0,
            selectedFilterRole: 0,
        };
    },
    computed: {
        sliderStepsAccess() {
            return this.reportRoles.map((role) => this.$t(`reportRoles.${role.name}`));
        },
        sliderStepsFilter() {
            return this.reportFilters.map((filter) => this.$t(`reportFilters.${filter.name}`));
        },
    },
    methods: {
        handleAccessRole() {
            this.$emit('roleAdded', { role: this.role, accessRole: this.reportRoles[this.selectedAccessRole].name });
            this.$emit('close');
        },
        getRoles() {
            this.$apollo
                .query({
                    query: ROLES,
                    fetchPolicy: 'no-cache'
                })
                .then(res => {
                    this.roles = res.data.roles.map(role => {
                        return {
                            label: this.$t(role.name),
                            value: role.name
                        }
                    });
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        }
    },
    created() {
        this.getRoles();
    }
};
</script>

<style scoped>
.modal {
    width: 550px;
    height: 562px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dropdown {
    width: 452px;
    margin: 30px 0px 30px 0px;
}

.button {
    display: flex;
    justify-content: flex-end;
}
</style>

<template>
  <div :class="['question-radio', isCard() ? 'no-number' :'']">
        <q-radio v-for="option in options"
            :class="['option', {selected: value === option.label}]"
            :key="option.value"
            :value="option.value"
            :label="option.label"
            :name="id"
            @input="answer"
            :disabled="disabled"
            data-testid="radio-option">
            <span>{{ option.label }}</span>
        </q-radio>
        <q-radio v-if="nullOption"
            :value="nullOption.value"
            :label="nullOption.label"
            :name="id"
            @input="answer"
            :disabled="disabled"
            class="null-option"
            :class="{selected: value === nullOption.label}"
            data-testid="radio-option"
            >
            <span>{{ nullOption.label }}</span>
        </q-radio>
  </div>
</template>

<script>

import QRadio from '../QRadio';

export default {
    name: 'RadioStep',
    /**
     * This component needs to be imported and registered here because it is not globally defined in tests.
     */
    components: {
        'q-radio': QRadio
    },
    props: {
        /**
         * Option types. Structure:
         *  - label
         *  - value
         */
        options: {
            type: Array
        },
        /**
         * Null option type. Structure:
         *  - label
         *  - value
         */
        nullOption: {
            type: Object
        },
        /**
         * v-model
         */
        value: [Array, String, Number, Object],
        /**
         * Unique id for name property of radio element
         */
        id: {
            type: [String, Number],
            required: true
        },
        disabled: {
            type: Boolean
        }
    },
    methods: {
        answer(val) {
            this.$emit('input', val)
        },
        isCard() {
            return this.$parent.number == null;
        }
    }
}
</script>

<style scoped lang="scss">

.question-radio {
    // Negative margin because the text needs to align with the title above, not the radio (circle) part
    margin-left: -27px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px 10px;
    width: 340px;

    label {
        margin-bottom: 14px;
    }

    .selected {
        font-weight: 500;
    }

    &.no-number {
        margin-left: 0;
    }
}

.radio div  input {
            margin: 100px !important;
        }

.left {
    width: 97px;
}

.option {
    position: relative;
    width: 140px !important;

    &.null-option {
        margin-top: 20px;
        width: 200px;
    }
}

.last-option {
    position: absolute;
    left: -155px;
    top: 0;
    display: flex;
    align-items:flex-start;

    .arrow {
        height: 30px;
        margin: -7px 0 0 -3px;
    }

    .text {
        font-weight: 500;
        font-size: 10px;
        padding: 0;
        margin:0;
    }
}

.null-option {
    margin-top: 30px;
}

</style>
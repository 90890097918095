<template>
    <q-step :number="index" :id="index" :name="step.name" :about="step.about" :value="step.value" class="step" width="90%">
        <div class="addresses-container">
            <q-branch-card
                v-for="branch, index in computedAddresses"
                :key="index"
                :sender="branch.sender"
                :name="branch.name"
                :line1="branch.line1"
                :line2="branch.line2"
                interactive
                :selected="selectedBranchIndex === index"
                :options="getBranchCardOptions(branch)"
                @click.native="handleSelectBranch(branch, index)"
                @optionSelected="handleOptionSelected(branch, $event)"
            ></q-branch-card>
            <div v-if="(!createdBranch || createNewBranch) && !isEditing" class="add-branch-card" @click="handleNewBranch">
                <q-icon width="100px" height="100px" class="plus-icon" type="plus-icon" color="#00A1AE"></q-icon>
            </div>
        </div>

        <q-popup v-if="createNewBranch" width="400px">
            <div class="popup-branch">
                <div class="popup-header">
                    <h2>Adres {{ isEditing ? 'aanpassen' : 'aanmaken' }}</h2>
                </div>
                <div class="popup-body">
                    <p>Voer hier het adres in van de organisatie die je als afzender boven je brief wilt weergeven.</p>
                    <div class="inputs-container">
                        <q-input labelPosition="outside" v-model="createdBranch.sender" placeholder="t.a.v. L. Valk" size="medium">Naam afzender (optioneel)</q-input>
                        <q-input labelPosition="outside" v-model="createdBranch.name" placeholder="Qfact B.V." size="medium">Naam organisatie</q-input>
                        <q-input labelPosition="outside" v-model="createdBranch.line1" placeholder="Galvanistraat 123a" size="medium">Straatnaam + huisnummer</q-input>
                        <q-input labelPosition="outside" v-model="createdBranch.line2" placeholder="1234AB Ede" size="medium">Postcode + plaats</q-input>
                    </div>
                </div>
                <div class="popup-buttons">
                    <q-button variation="blank" @click="handleCancelBranch">Annuleren</q-button>
                    <q-button @click="handleCreateBranch">Adres {{ isEditing ? 'aanpassen' : 'aanmaken' }}</q-button>
                </div>
            </div>
        </q-popup>
    </q-step>
</template>

<script>
export default {
    name: 'style-step-branch',
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        selectedStyle: {
            type: Object,
            required: true
        },
        availableAddresses: {
            type: Array,
            default() { return [] }
        }
    },
    data() {
        return {
            branchCardOptions: [
                {
                    name: 'Adres verwijderen',
                    color: 'red'
                }
            ],
            selectedBranchIndex: null,
            createNewBranch: false,
            createdBranch: null,
            branchDeleted: false,
            isEditing: false,
            cachedBranch: null
        }
    },
    methods: {
        handleSelectBranch(branch, index) {
            if(this.branchDeleted) return this.branchDeleted = false;
            this.selectedBranchIndex = index;
            this.selectedStyle.address = branch;
            this.$emit('styleUpdated', { ...this.selectedStyle })
        },
        handleNewBranch() {
            this.createdBranch = {
                sender: '',
                name: '',
                line1: '',
                line2: ''
            }
            this.createNewBranch = true;
        },
        handleOptionSelected(branch, option) {
            if(option.name === 'Adres verwijderen') {
                // verwijder optie
                this.branchDeleted = true;
                this.createdBranch = null;
                this.selectedStyle.address = null;
            }
            else if(option.name === 'Adres aanpassen') {
                this.cachedBranch = { ...this.createdBranch };
                this.createNewBranch = true;
                this.isEditing = true;
            }
        },
        getBranchCardOptions(branch) {
            // console.log('branch', branch)
            if(!this.createdBranch) return []
            if(this.createdBranch && branch.styleId !== this.createdBranch.id) return []
            return [
                {
                    name: 'Adres aanpassen'
                },
                {
                    name: 'Adres verwijderen',
                    color: 'red'
                }
            ]
        },
        handleCancelBranch() {
            if(this.isEditing) this.createdBranch = this.cachedBranch;
            else this.createdBranch = null;
            this.createNewBranch = false;
            this.isEditing = false;
        },
        handleCreateBranch() {
            this.createdBranch.line2 = this.createdBranch.line2.replace(/(\d)\s+/g, "$1");

            if (!this.createdBranch.name ||
                !this.createdBranch.line1 ||
                !this.createdBranch.line2) return this.$store.commit('notify', { type: 'info', message: 'Je moet de onderste 3 velden invullen' });
            if (this.createdBranch.line2 === null || this.createdBranch.line2.length < 6) {
                return this.$store.commit('notify', { type: 'danger', message: 'Vul correcte postcode in, bijvoorbeeld: 1234AB'});
            } else {
                this.createdBranch.line2 = this.createdBranch.line2.slice(0, 4) + this.createdBranch.line2[4].toUpperCase() + this.createdBranch.line2[5].toUpperCase() + " " + this.createdBranch.line2.slice(7);
            }
            this.createNewBranch = false;
            this.isEditing = false;
            this.handleSelectBranch(this.createdBranch, this.availableAddresses.length)
        },
        updateSelection() {
            if(this.selectedStyle.address) {
                const hasEmpty = Boolean(this.availableAddresses.find(option => option.name === 'empty'));
                if(this.selectedStyle.address.name !== 'empty') {
                    this.createdBranch = this.selectedStyle.address;
                    const selectedIndex = hasEmpty ? this.availableAddresses.length - 1 : this.availableAddresses.length;
                    this.selectedBranchIndex = selectedIndex;
                }
                else this.selectedBranchIndex = this.availableAddresses.length;
            }
        }
    },
    computed: {
        computedAddresses: function() {
            const emptyOption = {
                name: 'empty',
                sender: '',
                line1: '',
                line2: ''
            }
            let options = [];

            if(this.createdBranch && !this.createNewBranch) options = [ ...this.availableAddresses, this.createdBranch ]
            else options = this.availableAddresses

            if(!options.find(option => option.name === 'empty')) options.push(emptyOption);

            return options
        }
    },
    created() {
        this.updateSelection();
    },
    watch: {
        availableAddresses: function() {
            this.updateSelection();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.step {
    justify-content: center;

    .addresses-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        input {
            display: none;
        }
    }
}

.popup-branch {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 32px;

        .inputs-container {
            display: flex;
            flex-direction: column;
        }
    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;
    }
}

.add-branch-card {
    height: 80px;
    width: 200px;
    outline: 1px dashed #b6bdc1;
    border-radius: 6px;
    cursor: pointer;

    .plus-icon {
        margin: -10px 0 0 50px;
    } 

    transition: scale .2s cubic-bezier(.45,1.8,.58,1.21);

    &:active {
        scale: 0.97 !important;
    }

    &:hover {
        scale: 1.02;
    }
}

</style>
<template>
    <div class="q-token-text">
        <div id="hidden-tokens-container" class="hidden-tokens"></div>
        <portal to="tokens" v-if="searchingToken !== ''">
            <div 
                ref="tokenSearchBox" 
                :class="{ show: selectToken && searchingToken.length > 0 }" 
                class="token-search" 
                :style="`--left: ${caretCoordinates.x}px; --top: ${caretCoordinates.y}px; --width: ${searchMaxWidth}px`">
                <div 
                    v-for="token in tokens" 
                    :key="token.id" 
                    class="token-wrapper" 
                    :class="{ show: searchingToken.length > 0 && token.tokenLabel.includes(searchingToken.toLowerCase()) }" 
                    :style="`--index: ${getTokenIndex(token)}`">
                    <span class="token" :class="{ missing: token.value === 'Niet ingevuld' }" @click="handleSelectToken(token)">
                        {{ token.label }}
                    </span>
                </div>
            </div>
        </portal>

        <div class="text-wrapper">
            <div class="token-helper" v-if="!disabled">
                <span @click="handleTokenHelper">Tokens gebruiken</span>
                <q-tooltip position="bottom">
                    <template #tooltip>
                        Haal door een hashtag (#) te typen ingevulde data op uit je project en zet deze in je brief.<br>
                        Als je een hashtag gebruikt type dan aansluitend (zonder spatie) de naam van<br>
                        het kenmerk in waaruit je de ingevulde data wilt ophalen.<br>
                        <br>
                        Typ bijvoorbeeld: Het gefactureerde bedrag was #gefactureerd_bedrag.<br>
                        <br>
                        Dit komt in je brief te staan als:<br>
                        Het gefactureerde bedrag was €943.785,00.
                    </template>
                    <q-icon type="question-circle" width="14px"></q-icon>
                </q-tooltip>
            </div>
            <div 
                ref="letterPreview" 
                class="text-input text-preview" 
                :class="{ preview: disabled, placeholder: richText.length === 0 }" 
                :style="`--placeholder: '${placeholder}'`"
                v-html="richText">
            </div>
            <div
                ref="hiddenLetterText" 
                class="text-input text-editor hidden" 
                v-html="sanitizedTokenText">
            </div>
            <textarea
                ref="letterText" 
                v-model="tokenText"
                class="text-input text-editor" 
                :class="{ preview: disabled }"
                spellcheck="false"
                :disabled="disabled"
                @keydown="validateInput"
                @keyup="handleKeyUp"
                @input="handleTextAreaInput"
                @click="updateSearchHandler"
                @blur="handleBlur"
            ></textarea>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'q-token-text',
    props: {
        // v-model
        value: {
            type: String,
            default: ''
        },
        /*
        *  Array of tokens with the following attributes
        *  id: String
        *  tokenLabel: String
        *  value: String
        */
        tokens: {
            type: Array,
            required: true
        },
        // if true, the text cannot be updated by the user
        disabled: {
            type: Boolean,
            default: false
        },
        // if true, the text will parse the tokens into their corresponding values
        showValues: {
            type: Boolean,
            default: false
        },
        // the placeholder shown when the text is empty
        placeholder: {
            type: String,
            default: ''
        },
        // if true, the tokens with value 'Niet ingevuld' will be displayed in red (danger)
        showMissingValues: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tokenText: this.value,
            richText: '',
            caretCoordinates: {},
            searchMaxWidth: 0,
            selectToken: false,
            tokenPosition: 0,
            lastFocusedCaretPosition: 0,
            searchingToken: '',
            isMissingValues: false,
            isOnFreshLine: true
        }
    },
    methods: {
        validateInput(event) {
            if(event.key !== 'Tab' || !this.selectToken || this.disabled) return

            event.preventDefault();
            const firstToken = this.tokens.find(token => token.tokenLabel.includes(this.searchingToken.toLowerCase()));
            if(firstToken) this.handleSelectToken(firstToken);
        },
        handleTextAreaInput() {
            this.isSearchingToken();
            this.setTextAreaCoordinates();
            this.parseTextToTokens();
        },
        insertIntoTokenText(text, position) {
            const start = this.tokenText.substr(0, position);
            const end = this.tokenText.substr(position, this.tokenText.length-1);
            return start+text+end
        },
        updateSearchHandler() {
            if(this.disabled) return
            this.isSearchingToken();
            this.setTextAreaCoordinates();
        },
        isSearchingToken() {
            const { start, end } = this.getCaretPosition();
            let tokenLength = null;
            let tokenStart = null;
            const text = this.tokenText;

            let isSearchingToken = false;

            var borderChars = [' ','\n','\r','\t','<br>'];
            for(let i = 0; i < end; i++) {
                const keyIndex = end - i;
                const keyBefore = text.substr(keyIndex-1, 1);
                if(borderChars.includes(keyBefore)) {
                    isSearchingToken = false;
                    break
                }
                if(keyBefore === '#') {
                    if(i === 0) break
                    tokenStart = keyIndex;
                    tokenLength = i;
                    isSearchingToken = true;
                    break
                }
            }
            
            const input = text.substr(tokenStart, tokenLength);
            const indicatorLabels = this.tokens.map(indicator => indicator.tokenLabel);
            const word = this.getWord();
            if(indicatorLabels.includes(input) || indicatorLabels.includes(word)) return this.resetTokenSearch()
            
            if(isSearchingToken) {
                this.tokenPosition = tokenStart;
                this.searchingToken = word;
                this.selectToken = true;
            } else this.resetTokenSearch()
        },
        getCaretPosition() {
            const input = this.$refs.letterText;
            this.lastFocusedCaretPosition = input.selectionStart;
            return { 
                start: input.selectionStart,
                end: input.selectionEnd
            }
        },
        getWord() {
            const { start, end } = this.getCaretPosition();

            var sel, word = "";
            if (window.getSelection && (sel = window.getSelection()).modify) {
                sel.modify("move", "backward", "word");
                sel.modify("extend", "forward", "word");
                word = sel.toString();
            } else if ( (sel = document.selection) && sel.type != "Control") {
                var range = sel.createRange();
                range.collapse(true);
                range.expand("word");
                word = range.text;
            }

            this.focusTextArea(start, end);
            return word
        },
        focusTextArea(start, end) {
            this.$refs.letterText.focus();
            this.$refs.letterText.selectionStart = start;
            this.$refs.letterText.selectionEnd = end;
        },
        async setCaret(element, caretIndex) {
            await new Promise(r => setTimeout(r, 1));
            element.focus();

            var range = document.createRange();
            try {
                range.setStart(element.childNodes[0], caretIndex);
            } catch(e) {
                if(!element.childNodes[0]) return element.focus()
                range.setStart(element.childNodes[0], element.childNodes[0].length)
            }
            var sel = window.getSelection();
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        },
        async setCaretCoordinates(start, end) {
            let x = this.caretCoordinates.x || 0;
            let y = this.caretCoordinates.y || 0;
            const isSupported = typeof window.getSelection !== "undefined";
            if (isSupported) {
                const selection = window.getSelection();
                if (selection.rangeCount !== 0) {
                    await new Promise(r => setTimeout(r, 1));
                    // selection.modify("move", "backward", "word");
                    const range = selection.getRangeAt(0).cloneRange();
                    const rect = range.getClientRects()[0];

                    if(rect) {
                        x = rect.left - 11; 
                        y = rect.top + 16;
                        y += window.scrollY;
                    }
                }
            }

            this.caretCoordinates = { x, y };
            this.searchMaxWidth = window.innerWidth - x - 4;

            this.focusTextArea(start, end);
        },
        async setTextAreaCoordinates() {
            if(!this.selectToken) return
            
            const { start, end } = this.getCaretPosition();
            this.setCaret(this.$refs.hiddenLetterText, start);
            this.setCaretCoordinates(start, end);
        },
        async handleSelectToken(indicator) {
            const tokenText = this.tokenText;
            let newText = tokenText.substr(0, this.tokenPosition-1) + '#'+indicator.tokenLabel + tokenText.substr(this.tokenPosition + this.searchingToken.length, tokenText.length)
            this.tokenText = newText;
            this.parseTextToTokens();

            this.$nextTick(() => {
                this.focusTextArea(this.tokenPosition + indicator.tokenLabel.length, this.tokenPosition + indicator.tokenLabel.length);
                this.resetTokenSearch();
            });
        },
        handleTokenHelper() {
            let { start, end } = this.getCaretPosition();
            if(start === undefined) start = this.lastFocusedCaretPosition;
            if(end === undefined) end = this.lastFocusedCaretPosition;

            const currentKey = this.tokenText.substr(end-1, 1);

            let insert = '';
            var borderChars = [' ','\n','\r','\t','<br>'];

            if(currentKey === '#') return this.focusTextArea(end, end);
            if(!borderChars.includes(currentKey) && end !== 0) insert = ' #';
            else insert = '#';
            this.tokenText = this.insertIntoTokenText(insert, end);
            this.parseTextToTokens();

            this.$nextTick(() => {
                this.focusTextArea(end + insert.length, end + insert.length);
            });
        },
        resetTokenSearch() {
            this.selectToken = false;
            this.searchingToken = '';
            this.tokenPosition = 0;
        },
        getTokenIndex(indicator) {
            const token = this.tokenResults.find(token => token.id === indicator.id);
            if(!token) return 0
            else return token.index
        },
        parseTextToTokens() {
            const text = this.tokenText || '';
            let newText = text;
            const tokens = this.tokens;
            let missingValues = 0;

            const entityReplacers = {
                '<': '&lt;',
                '>': '&gt;',
            };

            Object.keys(entityReplacers).forEach(key => {
                const re = new RegExp(key, "g");
                newText = newText.replace(re, entityReplacers[key]);
            });

            for(let i = 0; i < tokens.length; i++) {
                const token = tokens[i];
                const tokenLabel = token.tokenLabel;

                const replace = `#${tokenLabel}`;
                var re = new RegExp(replace,"g");
                const match = newText.match(re);
                if(match === null) continue

                const tokenSpan = document.getElementById(tokenLabel)
                let width = 0;
                if(tokenSpan) width = tokenSpan.getBoundingClientRect().width - 1;
                else {
                    const text = document.createElement('span')
                    text.innerText = '#'+tokenLabel;
                    text.id = tokenLabel;
                    text.className = 'hidden-token';
                    const container = document.getElementById('hidden-tokens-container');
                    if(!container) width = tokenLabel.length * 8.5;
                    else {
                        container.append(text)
                        width = text.getBoundingClientRect().width - 1;
                    }
                }

                if(this.showMissingValues && token.value === 'Niet ingevuld') missingValues++;

                if(!this.showValues) newText = newText.replace(re, `<span class="token-tag${this.showMissingValues && token.value === 'Niet ingevuld' ? ' missing' : ''}" style="--width: ${width}px">${token.label}</span>`)
                else newText = newText.replace(re, token.value) //resolve voor waarde
            }

            newText = '<div class="text-block">' + newText + '</div>';
            newText = newText.replaceAll('\n','</div><div class="text-block">');

            this.isMissingValues = missingValues > 0;
            this.richText = newText;
        },
        async handleBlur() {
            await new Promise(r => setTimeout(r, 150));
            this.resetTokenSearch();
        },
        handleKeyUp() {
            this.setHeight();
            this.isSearchingToken();
        },
        _setHeight() {
            this.$nextTick(() => this.setHeight())
        },
        setHeight() {
            const element = this.$refs.letterText;
            const computed = getComputedStyle(element);
            const paddingBlock = parseFloat(computed['padding-block'].replace('px',''));
            
            element.style.height = 'auto';
            element.style.height = element.scrollHeight - (paddingBlock*2) + 'px';
        },
        handleScroll() {
            this.setTextAreaCoordinates();
        }
    },
    computed: {
        tokenResults: function() {
            if(!this.selectToken) return []

            const matchingTokens = this.tokens.filter(token => {
                return token.tokenLabel.includes(this.searchingToken.toLowerCase())
            })
            return matchingTokens.map((token, index) => {
                return {
                    ...token,
                    index
                }
            })
        },
        sanitizedTokenText: function() {
            let text = this.tokenText;
            const entityReplacers = {
                '<': '&lt;',
                '>': '&gt;',
            };

            Object.keys(entityReplacers).forEach(key => {
                const re = new RegExp(key, "g");
                text = text.replace(re, entityReplacers[key]);
            });
            return text
        }
    },
    watch: {
        value: function() {
            this.tokenText = this.value;
            this.parseTextToTokens();
            this._setHeight();
        },
        tokens: function() {
            this.parseTextToTokens();
        },
        tokenText: function() {
            this.$emit('input', this.tokenText)
        },
        isMissingValues: function() {
            this.$emit('isMissingValues', this.isMissingValues)
        },
        showValues: function() {
            this.parseTextToTokens();
        },
        disabled: async function() {
            await new Promise(r => setTimeout(r, 300));
            this._setHeight();
        }
    },
    mounted() {
        this.setHeight();
        this.parseTextToTokens();

        this.$root.$on('scroll', this.handleScroll);
    },
    beforeDestroy() {
        this.$root.$off('scroll', this.handleScroll);
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/qds/assets/style/_variables.scss";

.q-token-text {
    --line-height-tokens: 26px;
    --line-height: 20px;
    --font-size: 14px;

    position: relative;
    width: 100%;
}

.hidden-tokens {
    position: absolute; 
    opacity: 0; 
    user-select: none; 
    pointer-events: none; 
    font-size: var(--font-size); 
    font-family:'Gotham'; 
    overflow: hidden; 

    &::v-deep span {
        position: absolute;
        white-space: nowrap;
    }
}

.token-search {
    position: fixed;
    left: calc(var(--left));
    top: calc(var(--top));

    display: flex;
    flex-direction: column;
    gap: 3px;

    border-radius: 4px;
    padding: 2px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    max-height: 0px;
    width: var(--width);
    z-index: 2;

    transition: all .3s ease-in-out, top, opacity 0s;

    &.show {
        opacity: 1;
        max-height: 120px;
        overflow-y: auto;
        transition: top, opacity .2s ease-in-out;
    }

    .token-wrapper {
        margin-top: -25px;
        opacity: 0;
        scale: 0.5;
        transition: .2s ease calc(var(--index) * .05s);
        transform-origin: top left;

        &.show {
            margin-top: 0px;
            z-index: 1;
            opacity: 1;
            scale: 1;
        }

        .token {
            display: inline-block;
            padding: 0px 8px;
            height: 22px;
            line-height: 23px; 
            text-align: center; 
            border-radius: 12px; 
            background-color: $color-grey-3; 
            color: $color-grey-7; 
            font-size: 12px; 
            font-weight: 500;
            font-family: 'Gotham';
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            transition: .1s ease;
            transform-origin: left bottom;
            pointer-events: all;

            &.missing {
                background-color: $color-red-lighter;
                color: $color-red;
            }

            &:hover {
                scale: 1.03;
            }
            &:focus, &:active {
                scale: 0.98;
            }
        }
    }
}

.text-wrapper {
    display: flex;
}

.token-helper {
    position: absolute;
    top: 2px;
    right: 4px;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        color: #212529;
    }
}

.text-preview {
    position: absolute;
    inset: 1px;
    padding: 16px 12px;
    min-height: 200px;
    user-select: none;
    pointer-events: none;
    font-size: var(--font-size);
    white-space: pre-wrap;
    line-height: var(--line-height-tokens);
    transition: .3s ease;

    &.preview {
        position: relative;
        padding: 0;
        min-height: 0;
        user-select: all;
        pointer-events: all;

        &.placeholder:before {
            left: 0;
            top: 0;
        }
    }

    &.placeholder:before {
        content: var(--placeholder);
        position: absolute;
        top: 16px;
        left: 12px;
        color: #6b6b6b;
        transition: .2s ease;
    }

    &::v-deep {
        .token-tag {
            display: inline-block; 
            width: var(--width); 
            margin-block: 2px; 
            margin-right: 1px; 
            height: 22px; 
            line-height: 23px; 
            text-align: center; 
            border-radius: 12px; 
            background-color: $color-grey-3; 
            color: $color-grey-7;
            font-size: 12px; 
            font-weight: 500;
            pointer-events: unset;
            user-select: unset;

            &.missing {
                background-color: $color-red-lighter;
                color: $color-red;
            }

            &:hover {
                scale: 1.02;
            }
        }
        .text-block {
            min-height: var(--line-height-tokens);
        }
    }
}

.text-editor {
    outline: none;
    width: 100%;
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    min-height: 200px;
    padding: 16px 12px;
    color: transparent;
    background: none;
    caret-color: black;
    resize: none;
    overflow: hidden;
    font-size: var(--font-size);
    white-space: pre-wrap;
    line-height: var(--line-height-tokens);
    transition: .3s ease;

    &.preview {
        position: absolute;
        border: 1px solid transparent;
        opacity: 0;
        pointer-events: none !important;
    }

    &.hidden {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 24px - 2px);
        height: calc(100% - 32px - 2px);
        opacity: 0;
        pointer-events: none;
    }
}

.text-input {
    outline: none;
    font-style: normal;
    font-family: 'Gotham';
    font-size: var(--font-size);
    white-space: pre-wrap;
    line-height: var(--line-height-tokens);

    &.preview {
        line-height: var(--line-height);
    }
}

</style>
<template>
    <auth-base>
    </auth-base>

</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { CONFIRM_EMAIL } from '@/graphql/mutations';
import Notifications from '@/components/Notifications';

export default {
    name: 'RegisterValidate',
    components: {
        AuthBase,
        Notifications
    },

    data() {
        return {
            token: this.$route.params.token,
            success: false,
            message: ''
        };
    },
    computed: {
    },
    methods: {
        wait: async (ms) => await new Promise(r => setTimeout(r, ms))
    },
    mounted() {
        this.$apollo
            .mutate({
                mutation: CONFIRM_EMAIL,
                variables: {
                    token: this.token
                }
            })
            .then(response => {
                this.success = true;
                this.$router.push('/emailvalidation');
            })
            .catch(err => {
                this.$store.commit('notify', {type:'warning', message: 'Deze validatielink is niet (meer) geldig. Neem contact op met de helpdesk.'});
                this.wait(2000).then(() => {
                    this.$router.push('/');
                })
            });
    }
};
</script>

<style scoped lang="scss">

</style>
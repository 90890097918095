<template>
    <auth-base>
        <div class="loader"></div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { SSO_LOGIN } from '@/graphql/queries';

export default {
    name: 'Sso',
    components: {
        AuthBase,
    },
    created() {
        const appDisabled = process.env.BLOCK_LOGIN || false;
        if (appDisabled) {
            this.$router.push('/login');
        } else {
            const targetUrl = localStorage.getItem('targetUrl');
            localStorage.clear();
            if (targetUrl) localStorage.setItem('targetUrl', targetUrl);
            const { slug } = this.$route.params;
            window.location.href = `${process.env.API_SSO_URI}/${slug}`;
        }
    },
};
</script>

<style lang="scss" scoped>
.loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}
</style>

<template>
    <div class="page-padding">
        <div class="flex space-between mb-l">
            <h2>Analyse</h2>
            <q-button icon="cog" v-if="!expanded" size="small" variation="ghost" @click="expand"
                >Uitgebreid zoeken</q-button
            >
            <q-button icon="cog" v-else size="small" variation="ghost" @click="expand"
                >Minder uitgebreid zoeken</q-button
            >
        </div>

        <div class="q-card-small filters">
            <q-section class="row between-xs dropdownWrap" vPadding="35" hPadding="35" borderBottom>
                <q-dropdown
                    id="client"
                    class="col-xs-6 gutter"
                    :options="allClientNames"
                    placeholder="Iedereen"
                    v-model="filter.client"
                    @input="get_"
                    multiple
                    addNullValue
                    nullLabel="Iedereen"
                    autocomplete
                >
                    Opdrachtgever
                </q-dropdown>
                <q-dropdown
                    id="contractor"
                    class="col-xs-6 gutter"
                    :options="allContractorNames"
                    placeholder="Iedereen"
                    v-model="filter.contractor"
                    @input="get_"
                    multiple
                    addNullValue
                    nullLabel="Iedereen"
                    autocomplete
                >
                    Opdrachtnemer
                </q-dropdown>

                <q-input
                    id="postcode"
                    class="col-xs-6 gutter"
                    placeholder="bijv. 1234AB"
                    labelPosition="outside"
                    :state="postcodeState"
                    errorMessage="Dit is geen geldige postcode"
                    v-model="filter.postcode"
                    @blur="pickedPostcode = true"
                    @input="get_"
                >
                    Postcode
                </q-input>
                <q-slider
                    id="straal"
                    label="Straal"
                    class="col-xs-6 gutter"
                    :min="0"
                    :max="200"
                    :steps="10"
                    v-model="filter.radius"
                    @input="get_"
                    >>
                </q-slider>
            </q-section>
            <q-section paddingTop="20" paddingBottom="40" hPadding="35" borderBottom>
                <q-section class="row between-xs">
                    <q-dropdown
                        id="type"
                        class="col-xs-8 gutter"
                        :options="projectTypeOptions"
                        v-model="filter.projectType"
                        @input="get_"
                        multiple
                        addNullValue
                        nullLabel="Alles"
                        placeholder="Alles"
                        autocomplete
                    >
                        Type
                    </q-dropdown>

                    <q-dropdown
                        id="aanbesteding"
                        class="col-xs-8 gutter"
                        :options="procedureOptions"
                        v-model="filter.procedure"
                        @input="get_"
                        multiple
                        addNullValue
                        nullLabel="Alles"
                        placeholder="Alles"
                        autocomplete
                    >
                        Aanbesteding
                    </q-dropdown>

                    <q-dropdown
                        id="soort"
                        class="col-xs-8 gutter"
                        :options="typeOptions"
                        v-model="filter.type"
                        @input="get_"
                        multiple
                        addNullValue
                        nullLabel="Alles"
                        placeholder="Alles"
                        autocomplete
                    >
                        Soort
                    </q-dropdown>
                </q-section>
                <div class="row slider-div">
                    <q-slider
                        label="Startjaar"
                        class="col-xs-3 custom-gutter"
                        :min="2013"
                        :max="new Date().getFullYear()"
                        :steps="1"
                        range
                        v-model="filter.startyear"
                        @input="get_"
                    >
                    </q-slider>
                    <q-slider
                        label="Eindjaar"
                        class="col-xs-3 custom-gutter"
                        :min="new Date().getFullYear() - 5"
                        :max="new Date().getFullYear()"
                        :steps="1"
                        range
                        v-model="filter.endyear"
                        @input="get_"
                    >
                    </q-slider>
                    <q-slider
                        label="Budget"
                        class="col-xs-3 custom-gutter"
                        :min="0"
                        :max="5000000"
                        :steps="100000"
                        euro
                        range
                        v-model="filter.budget"
                        @input="get_"
                    >
                    </q-slider>
                    <q-slider
                        label="Beoordeling"
                        class="col-xs-3 custom-gutter"
                        :min="0"
                        :max="10"
                        :steps="1"
                        range
                        v-model="filter.review"
                        @input="get_"
                    >
                    </q-slider>
                </div>
            </q-section>
            <q-section paddingTop="0" paddingBottom="40" hPadding="35" v-if="expanded">
                <q-section>
                    <div class="row slider-div">
                        <q-slider
                            label="Planmatig werken"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.planmatigwerken"
                            @input="get_"
                        >
                        </q-slider>
                        <q-slider
                            label="Samenwerking"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.samenwerking"
                            @input="get_"
                        >
                        </q-slider>
                        <q-slider
                            label="Deskundigheid"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.deskundigheid"
                            @input="get_"
                        >
                        </q-slider>
                        <q-slider
                            label="Documentatie"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.documentatie"
                            @input="get_"
                        >
                        </q-slider>
                    </div>
                    <div class="row slider-div">
                        <q-slider
                            label="Veiligheid"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.veiligheid"
                            @input="get_"
                        >
                        </q-slider>
                        <q-slider
                            label="Omgeving"
                            class="col-xs-3 custom-gutter"
                            :min="0"
                            :max="10"
                            :steps="1"
                            range
                            v-model="averages.omgeving"
                            @input="get_"
                        >
                        </q-slider>
                    </div>
                </q-section>
            </q-section>
        </div>

        <div class="results mt-xl">
            <h2>Resultaten</h2>
            <div class="mt-l">
                <q-table-with-pagination
                    id="table"
                    :data="sortedAndPaginatedAndFilteredData"
                    :columns="columns"
                    clickable
                    :maxRows="paginatedTableLength"
                    :tableLoading="loading"
                    :dataLength="resultAmount"
                    ref="table"
                    @filterUpdated="filterUpdated"
                    @filterSearch="filterSearchChanged"
                    @pageSelection="handleTablePagination"
                    @filterInitialized="filterUpdated"
                >
                    <template v-slot:row="{ row, column }">
                        <span v-if="column == 'norm' && row.norm == 'crow'">
                            <img
                                class="crow-logo"
                                src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png"
                                alt="crow-logo"
                            />
                        </span>
                        <span v-else-if="column == 'average'">
                            <q-tag :emoji="getScore(row.average)" :emojiValue="row.average"></q-tag>
                        </span>
                        <span v-else-if="column === 'endDateTimestamp'">
                            {{ row.endDate }}
                        </span>
                        <span v-else-if="column === 'name'" class="bold">
                            {{ row.name }}
                        </span>
                        <span v-else>{{ row[column] }}</span>
                    </template>
                </q-table-with-pagination>
            </div>
        </div>

        <div class="fixedButton" @click="scrollTo">
            <span v-if="scrolledToTable">Terug naar filteren</span>
            <span v-else-if="resultAmount > 0"
                >Bekijk {{ resultAmount }} {{ resultAmount > 1 ? 'resultaten' : 'resultaat' }}</span
            >
            <span v-else>Er zijn geen resultaten</span>
            <q-icon
                class="arrow"
                :style="
                    scrolledToTable
                        ? 'transform: rotate(180deg)'
                        : '' + data.length > 0
                        ? ''
                        : 'transform: rotate(90deg)'
                "
                type="ArrowDown"
            ></q-icon>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

import { ORGANISATIONS_ANALYIS_PAGE, PROJECT_ANALYSE_QUERY } from '../../../graphql/queries';

import Query from './Query.js';
import { getDate, getSmileyForCrowScore } from '../../../assets/js/utils';
import QTableWithPagination from '../../../components/qds/components/QTableWithPagination.vue';

import gql from 'graphql-tag';

export default {
    components: { QTableWithPagination },
    name: 'Analysis',
    data() {
        return {
            expanded: false,
            loading: true,
            data: [],
            clientOptions: [],
            contractorOptions: [],
            projectTypeOptions: [
                { value: 'works', label: 'Werken' },
                { value: 'services', label: 'Diensten' },
            ],
            procedureOptions: [
                {
                    value: '5bfe373c-9cc7-4d47-83c1-d5bcbde7bf79',
                    label: '1 op 1',
                },
                {
                    value: '1cac8982-1484-4b93-86d2-b39d0bbd843e',
                    label: 'Europees',
                },
                {
                    value: 'a68e96f1-4e61-478d-9017-630b90c613e9',
                    label: 'Meervoudig onderhands',
                },
                {
                    value: 'ad122b89-2fb3-4dba-a1da-1753bbad808d',
                    label: 'Nationaal openbaar',
                },
            ],
            typeOptions: [
                {
                    value: 'b40da8ef-92c6-403c-b0f4-4045ff8b508a',
                    label: 'Afschermingsvoorzieningen',
                    crowType: 'works',
                },
                {
                    value: '685c5bc2-7153-4a67-95a1-268f3fd5ce8f',
                    label: 'Afval- en reinigingsdiensten',
                    crowType: 'works',
                },
                {
                    value: 'bdc1f82b-f13d-432c-9648-51d40e0d8b1b',
                    label: 'Asfalt',
                    crowType: 'works',
                },
                {
                    value: '2e2a8d9b-dcf1-481c-bee7-35f52de98161',
                    label: 'Baggerwerken',
                    crowType: 'works',
                },
                {
                    value: '8d238962-edda-42e2-9fbb-ffb394fc5238',
                    label: 'Bemalingen',
                    crowType: 'works',
                },
                {
                    value: 'f5017396-585c-4665-952e-6393ce296759',
                    label: 'Bestrating en riolering',
                    crowType: 'works',
                },
                {
                    value: '08a8b21a-b5c5-4cf9-b70b-26cbbf8cc599',
                    label: 'Betonconstructies',
                    crowType: 'works',
                },
                {
                    value: '0a3cc22d-1228-4504-b01d-74ddedd2393e',
                    label: 'Betonverhardingen',
                    crowType: 'works',
                },
                {
                    value: '2d53968a-3ef1-49b2-857f-0c72fccde44c',
                    label: 'Bitumineuze verhardingen',
                    crowType: 'works',
                },
                {
                    value: '4fc4d6bd-22ef-49b0-ba35-f15e6966d997',
                    label: 'Bouw- en woonrijp maken',
                    crowType: 'works',
                },
                {
                    value: '960b37ed-ae90-49e0-a342-122ae54e5b74',
                    label: 'Conserveringswerken',
                    crowType: 'works',
                },
                {
                    value: '5f47d30e-c5ac-4eb7-87ba-f32daaa46ef7',
                    label: 'Constructief',
                    crowType: 'works',
                },
                {
                    value: '1f774a4b-37d8-468c-8638-01c3dfe606fb',
                    label: 'Drainage',
                    crowType: 'works',
                },
                {
                    value: '00cf2ec3-d0df-40b9-a7e2-648866491604',
                    label: 'Folieconstructies',
                    crowType: 'works',
                },
                {
                    value: '6eeb7356-9435-4fd5-8937-ba1ab21bfb10',
                    label: 'Funderingsconstructies',
                    crowType: 'works',
                },
                {
                    value: '758e5d45-89b9-44bd-b39a-f309adcd2fe5',
                    label: 'Funderingslagen',
                    crowType: 'works',
                },
                {
                    value: '49c605b4-0b9b-4cfa-a062-30f33f37d6e0',
                    label: 'Geluidbeperkende constructies',
                    crowType: 'works',
                },
                {
                    value: '7abb9ef4-3328-4e8a-9fd8-764009cc1d5c',
                    label: 'Gemalen',
                    crowType: 'works',
                },
                {
                    value: 'fe899532-c7e6-4183-b3b0-d0e563f30663',
                    label: 'Groen',
                    crowType: 'works',
                },
                {
                    value: '56b3988a-4205-4444-93dd-443facb35a18',
                    label: 'Groenvoorzieningen',
                    crowType: 'works',
                },
                {
                    value: 'd1f5419f-1218-4e3a-aa54-c286637f14b8',
                    label: 'Grondwerken',
                    crowType: 'works',
                },
                {
                    value: '6af23a20-c818-4d4f-84c2-914bad203ca0',
                    label: 'Halteplaatsen',
                    crowType: 'works',
                },
                {
                    value: '45b94a98-ccdc-4b74-a00f-c85ef090bf54',
                    label: 'Houtconstructies',
                    crowType: 'works',
                },
                {
                    value: 'd25d819d-fde6-4328-9649-382dfd4bee4b',
                    label: 'Kabelwerk',
                    crowType: 'works',
                },
                {
                    value: '949cca2e-dbab-451d-b0ec-86c9bd57878c',
                    label: 'Kleine kunstwerken en gemalen',
                    crowType: 'works',
                },
                {
                    value: '31fac353-72d2-409f-a6c0-4c6e36e499b0',
                    label: 'Kust- en oeverwerken',
                    crowType: 'works',
                },
                {
                    value: 'e36a0114-7b4c-4c91-811e-5291dfc6e804',
                    label: 'Leidingwerk',
                    crowType: 'works',
                },
                {
                    value: '3af32c7a-e535-462c-9d8a-a9d846f81264',
                    label: 'Nieuwbouw',
                    crowType: 'works',
                },
                {
                    value: '2853d216-bb33-48c3-85f7-9de55a271c72',
                    label: 'Parkeervoorzieningen',
                    crowType: 'works',
                },
                {
                    value: 'fad7fea1-cd42-4d18-b7ba-e8749ca7b533',
                    label: 'Remming-, aanleg- en geleidewerken',
                    crowType: 'works',
                },
                {
                    value: '6696d4f3-fbc5-4692-a0af-48ca0c9d883f',
                    label: 'Renovatie',
                    crowType: 'works',
                },
                {
                    value: '348ca552-a398-41f3-88af-ea0f61ac51ba',
                    label: 'Sleuf- en sleufloze technieken',
                    crowType: 'works',
                },
                {
                    value: '1761055a-2c7c-49fa-a28f-4956ada2b19a',
                    label: 'Sloop',
                    crowType: 'works',
                },
                {
                    value: 'ecefc6a2-3fc8-459e-94a2-61370d189d43',
                    label: 'Sloopwerk',
                    crowType: 'works',
                },
                {
                    value: '3d53b916-27b7-47b5-8bbc-5a7d8714831a',
                    label: 'Spoor- en tramwerken',
                    crowType: 'works',
                },
                {
                    value: 'bf2709cb-b093-44fe-b899-b11743f9d48c',
                    label: 'Sport- en spelvoorzieningen',
                    crowType: 'works',
                },
                {
                    value: '9c28452c-7df5-49af-b274-a1163fa6657a',
                    label: 'Staalconstructies',
                    crowType: 'works',
                },
                {
                    value: '615f5b5a-43f0-4e58-b5f3-86ccbfc26695',
                    label: 'Verkeersmaatregelen bij werk in uitvoering',
                    crowType: 'works',
                },
                {
                    value: '4ccc5aff-13e6-4ad6-9e0d-66e59bd7dbcb',
                    label: 'Verkeersregelinstallaties',
                    crowType: 'works',
                },
                {
                    value: '6a6575ac-04bb-4481-b832-2ce23db47cda',
                    label: 'Verlichting',
                    crowType: 'works',
                },
                {
                    value: '93f110ef-705c-4e66-97e8-131acb84aaca',
                    label: 'Verontreinigde grond en verontreinigd water',
                    crowType: 'works',
                },
                {
                    value: 'f5c6d687-3012-492a-bfbf-08e6f9350cfc',
                    label: 'VRI,OV',
                    crowType: 'works',
                },
                {
                    value: '85a51039-ccca-4948-b148-ee37344cd7fe',
                    label: 'Waterputten',
                    crowType: 'works',
                },
                {
                    value: '07e2c75c-8b38-45de-817c-2b242b206498',
                    label: 'Wegbebakening',
                    crowType: 'works',
                },
                {
                    value: '96e32f0d-8ac4-4413-bb25-182b8eebce74',
                    label: 'Werk algemene aard',
                    crowType: 'works',
                },
                {
                    value: '9d041861-98da-4e43-bb15-b4291a05f6dc',
                    label: 'Directievoering',
                    crowType: 'services',
                },
                {
                    value: '47d08d9a-08e0-4ca5-a78f-6696bd4a3230',
                    label: 'Groen onderhoud',
                    crowType: 'services',
                },
                {
                    value: '3bca975b-56f1-4b0f-94cb-4e4219c6a408',
                    label: 'Onderzoek',
                    crowType: 'services',
                },
                {
                    value: '6f39e144-d803-4e86-a135-de4da0806a05',
                    label: 'Ontwerpwerkzaamheden',
                    crowType: 'services',
                },
                {
                    value: '62afaef5-3763-4850-b867-870b048dea64',
                    label: 'Opstellen contract',
                    crowType: 'services',
                },
                {
                    value: '6ecc7e59-1abe-4384-adf8-74006e0973a3',
                    label: 'Weg-riool onderhoud',
                    crowType: 'services',
                },
            ],
            filter: {
                client: null,
                contractor: null,
                projectType: null,
                procedure: null,
                type: null,
                startyear: [2000, new Date().getFullYear()],
                endyear: [2000, new Date().getFullYear()],
                review: [0, 10],
                budget: [0, 5000000],
                postcode: '',
                radius: 200,
            },
            averages: {
                planmatigwerken: [0, 10],
                samenwerking: [0, 10],
                deskundigheid: [0, 10],
                documentatie: [0, 10],
                veiligheid: [0, 10],
                omgeving: [0, 10],
            },
            currentQuery: null,
            paginatedTableLength: 10,
            skip: 0,
            masterIndicators: [],
            pickedPostcode: false,
            scrolledToTable: false,
            analysisMinimal: [],
            allClientNames: [],
            allContractorNames: [],
            filterSearches: {
                project: '', //name of project
                contractor: '', // name of contractor
                client: '', // name of client
            },
            filters: {
                project: [], // array of project ids
                client: [], // array of organisation ids
                contractor: [], // array of organisation ids,
                endDate: {
                    inactive: true,
                },
                score: [],
            },
            sorting: {},
            paginationIndex: 1,
            resultAmount: 0,
        };
    },
    methods: {
        filterUpdated(filter) {
            this.filters = {};
            this.sorting = [];

            Object.keys(filter).forEach((field) => {
                this.filters[field] = filter[field].filter;

                if (filter[field].latest)
                    this.sorting = {
                        ...filter[field],
                        field,
                    };
            });

            this.paginationIndex = 1;
        },
        filterSearchChanged({ column, filterData }) {
            const { search } = filterData
            this.filterSearches[column] = search.toLowerCase();
        },
        getScore(score) {
            return getSmileyForCrowScore(score);
        },
        get() {
            let filter = this.getFilterObject(this.filter);

            const averages = this.getLabelAveragesObject();

            this.currentQuery = new Query().addFilter(filter).addLabelAverage(averages);

            const query = this.currentQuery.get();

            this.loading = true;

            this.$apollo
                .query({
                    query: PROJECT_ANALYSE_QUERY,
                    variables: query,
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    this.data = result.data.projectanalyse.map((readmodel) => {
                        const contactPerson = readmodel.project.members[0] ? readmodel.project.members[0].user : {};

                        const endDate = this.getReferenceValue('project-end-date', readmodel);

                        return {
                            id: readmodel.id,
                            norm: readmodel.project.norm,
                            project: readmodel.project.name,
                            projectId: readmodel.project.id,
                            clientId: readmodel.project.clientId,
                            client: readmodel.project.client ? readmodel.project.client.name : '',
                            contractorId: readmodel.project.contractorId,
                            contractor: readmodel.project.contractor ? readmodel.project.contractor.name : '',
                            endDate: getDate(endDate),
                            endDateTimestamp: endDate,
                            average: readmodel.clientAverage
                                ? parseFloat(readmodel.clientAverage.toFixed(1), 10)
                                : undefined,
                            contactPerson: `${contactPerson.firstName} ${contactPerson.lastName}`,
                            to: `/projects/${readmodel.id}`
                        };
                    });

                    this.resultAmount = this.data.length;
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        /**
         * Gets value of a specific indicator in the readmodel
         * @returns value of the indicator or undefined
         */
        getReferenceValue(masterIndicatorId, readmodel) {
            const referencesForm = readmodel.forms.find((form) => form.formType === 'reference');

            if (!referencesForm) return undefined;

            const component = referencesForm.components.find(
                (component) => component.masterIndicatorId === masterIndicatorId
            );

            if (!component) return undefined;

            const firstValue = component.responses
                ? component.responses[0]
                    ? component.responses[0].value || undefined
                    : undefined
                : undefined;

            return firstValue;
        },
        /**
         * Removes null values from object (not-set filters)
         * Maps data fields to the indicatorIds from database.
         */
        getFilterObject(filter) {
            let newFilter = {};
            const keyMasterIdMap = {
                client: 'client',
                contractor: 'contractor',
                projectType: 'crow-type',
                type: 'crow-project-type',
                procedure: 'crow-procedure',
                startyear: 'project-start-date',
                endyear: 'project-end-date',
            };

            Object.keys(filter)
                .filter((key) => filter[key])
                .forEach((key) => {
                    const masterIdKey = keyMasterIdMap[key] || key;

                    newFilter[masterIdKey] = filter[key];
                });

            return newFilter;
        },
        /**
         * Maps data fields to labelIds from database
         */
        getLabelAveragesObject() {
            const keyLabelIdMap = {
                planmatigwerken: 'crow-planmatig-werken',
                deskundigheid: 'crow-deskundigheid',
                veiligheid: 'crow-veiligheid',
                kwaliteitsmanagement: 'crow-kwaliteitsmanagement',
                documentatie: 'crow-documentatie',
                omgeving: 'crow-omgeving',
                samenwerking: 'crow-samenwerking-en-communicatie',
            };

            let averages = {};

            Object.keys(this.averages).forEach((key) => {
                if (this.averages[key][0] === 0 && this.averages[key][1] === 10) return;

                averages[keyLabelIdMap[key]] = this.averages[key];
            });

            return averages;
        },
        get_: _.debounce(function () {
            this.get();
        }, 500),
        expand() {
            this.expanded = !this.expanded;
        },
        getOptions() {
            console.log('%cgetting options', 'color:red');

            this.$apollo
                .query({
                    query: ORGANISATIONS_ANALYIS_PAGE,
                    variables: {
                        where: {
                            type: 'main',
                        },
                        sort: 'name',
                    },
                    fetchPolicy: 'cache-first',
                })
                .then((result) => {
                    const { organisations } = result.data;

                    this.allClientNames = organisations
                        .filter((organisation) => {
                            return (
                                organisation.products &&
                                organisation.products.find(
                                    (product) => product.slug === 'crow_client' && product.enabled
                                )
                            );
                        })
                        .map((organisation) => {
                            return {
                                value: organisation.id,
                                label: organisation.name,
                            };
                        });

                    this.allContractorNames = organisations
                        .filter((organisation) => {
                            return (
                                organisation.products &&
                                organisation.products.find(
                                    (product) => product.slug === 'contractor' && product.enabled
                                )
                            );
                        })
                        .map((organisation) => {
                            return {
                                value: organisation.id,
                                label: organisation.name,
                            };
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        scrollTo() {
            if (this.data.length === 0) return;
            if (this.scrolledToTable) {
                if (this.expanded) window.scrollTo({ top: 110, behavior: 'smooth' });
                else window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                var table = document.getElementById('table');
                table.scrollIntoView({ behavior: 'smooth' });
            }
        },
        isInViewport(element) {
            if (element) {
                const rect = element.getBoundingClientRect();
                return rect.top <= 650;
            }
        },
        handleScroll() {
            const table = document.getElementById('table');
            this.scrolledToTable = this.isInViewport(table);
        },
        handleTablePagination(paginationIndex) {
            this.paginationIndex = paginationIndex;
        },
    },
    computed: {
        filteredData: function () {
            const filters = Object.keys(this.filters).map((field) => {
                return {
                    field,
                    filter: this.filters[field],
                };
            });

            const dataWithFilters = this.data.filter((project) => {
                return filters.every((filter) => {
                    switch (filter.field) {
                        case 'project':
                            return filter.filter.length === 0 || filter.filter.includes(project.id);
                        case 'contractor':
                            return filter.filter.length === 0 || filter.filter.includes(project.contractorId);
                        case 'client':
                            return filter.filter.length === 0 || filter.filter.includes(project.clientId);
                        case 'endDateTimestamp':
                            if (filter.filter.inactive) return true;
                            return (
                                (!filter.filter.from && !filter.filter.to) ||
                                (project.endDateTimestamp >= filter.filter.from &&
                                    project.endDateTimestamp <= filter.filter.to)
                            );
                        case 'average':
                            if (filter.filter.length !== 2) return true;

                            return (
                                filter.filter[0] <= parseFloat(project.average) &&
                                filter.filter[1] >= parseFloat(project.average)
                            );
                        default:
                            return true;
                    }
                });
            });

            this.resultAmount = dataWithFilters.length;

            return dataWithFilters;
        },

        sortedAndPaginatedAndFilteredData: function () {
            const { field, sorting } = this.sorting;

            const sortedData = this.filteredData.filter((_, index) => {
                const startIndex = (this.paginationIndex - 1) * this.paginatedTableLength;
                const endIndex = this.paginationIndex * this.paginatedTableLength;

                return index >= startIndex && index < endIndex;
            });

            if (!field) return sortedData;

            return sortedData.sort((a, b) =>
                a[field] < b[field] ? (sorting === 'DESC' ? 1 : -1) : sorting === 'DESC' ? -1 : 1
            );
        },
        postcodeState: function () {
            if (!this.filter.postcode || !this.pickedPostcode) return '';
            return this.filter.postcode.length === 4 || this.filter.postcode.length === 6 ? '' : 'error';
        },
        columns: function () {
            return [
                {
                    field: 'norm',
                    label: 'Norm',
                    width: '75px',
                    loadingWidth: '60%',
                },
                {
                    field: 'project',
                    label: 'Project',
                    width: '250px',
                    filter: true,
                    filterType: 'checkbox',
                    filterOptions: this.projectNames,
                },
                {
                    field: 'contractor',
                    label: 'Opdrachtnemer',
                    width: '200px',
                    filter: true,
                    filterType: 'checkbox',
                    filterOptions: this.contractorNames,
                },
                {
                    field: 'client',
                    label: 'Opdrachtgever',
                    filter: true,
                    filterType: 'checkbox',
                    filterOptions: this.clientNames,
                },
                {
                    field: 'endDateTimestamp',
                    label: 'Einddatum',
                    filter: true,
                    filterType: 'datePicker',
                },
                {
                    field: 'average',
                    label: 'Eindcijfer',
                    loadingStyle: 'badge',
                    filter: true,
                    filterType: 'score',
                },
            ];
        },
        projectNames: function () {
            const projects = [];
            const projectIds = [];

            this.data.forEach((project) => {
                if (projectIds.includes(project.id)) return;
                if (!project.project.toLowerCase().includes(this.filterSearches.project.toLowerCase())) return;

                projects.push({
                    value: project.id,
                    label: project.project,
                });
            });

            return projects;
        },
        clientNames: function () {
            const clients = [];
            const clientIds = [];

            this.data.forEach((project) => {
                if (!project.clientId || clientIds.includes(project.clientId)) return;
                if (!project.client.toLowerCase().includes(this.filterSearches.client.toLowerCase())) return;

                clients.push({
                    value: project.clientId,
                    label: project.client,
                });
                clientIds.push(project.clientId);
            });

            return clients;
        },
        contractorNames: function () {
            const contractors = [];
            const contractorIds = [];

            this.data.forEach((project) => {
                if (!project.contractorId || contractorIds.includes(project.contractorId)) return;
                if (!project.contractor.toLowerCase().includes(this.filterSearches.contractor.toLowerCase())) return;

                contractors.push({
                    value: project.contractorId,
                    label: project.contractor,
                });
                contractorIds.push(project.contractorId);
            });

            return contractors;
        },
    },
    created() {
        this.getOptions();
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll);
    },
};
</script>

<style lang="scss" scoped>
.filters {
    width: 100%;
    height: auto;
}

.crow-logo {
    height: 16px;
}
.custom-gutter {
    padding: 0 20px;
}

#straal {
    margin-top: -11.5px;
}

.slider-div {
    padding-top: 32px;

    div:first-child {
        padding-left: 0;
    }
}

.dropdownWrap {
    align-items: center;
}

.fixedButton {
    position: sticky;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: auto;
    margin-left: 0px;
    bottom: 0;
    width: 200px;

    font-family: 'Gotham';
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #672583, #00a1ae, #672583);
    background-size: 200%;
    background-repeat: repeat-x;
    animation: animateGradient 4s linear infinite;
    box-shadow: 0 5px 10px 1px #72727270;
    color: white;
    padding: 8px 14px 8px 14px;
    cursor: pointer;
    z-index: 10;
    user-select: none;

    .arrow {
        margin-left: 6px;
        transition: 0.4s ease;
    }
}

@keyframes animateGradient {
    from {
        background-position: 0;
    }
    to {
        background-position: 200%;
    }
}
</style>

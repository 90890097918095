<template>
    <div class="modal">
        <div class="body">
            <h2 class="pb-s">Prestatie verwijderen</h2>
            <p>
                Weet je zeker dat je deze prestatie uit je project wilt verwijderen? Hiermee verwijder je ook de
                eventueel ingevulde antwoorden. Je kunt op een later moment altijd de prestatie weer toevoegen aan je
                project om deze opnieuw in te vullen.
            </p>
        </div>
        <div class="footer">
            <q-button variation="danger-light" @click="closePopup">Annuleren</q-button>
            <q-button
                variation="danger"
                @click="deleteIndicatorsFromProject"
                :loading="deleteIndicatorsLoading"
                >Ja, verwijderen</q-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'label-delete-modal',
    props: {
        deleteIndicatorsLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        deleteIndicatorsFromProject() {
            this.$emit('deleteIndicator');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    max-width: 600px;
    .body {
        padding-bottom: 30px;
        margin: 0 0 50px 20px;

        .title {
            margin-bottom: 30px;
        }
        .about {
            margin-bottom: 30px;
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M58 27C58 41.9117 45.9117 54 31 54C17.4474 54 6.22703 44.0148 4.29425 31C4.10042 29.6948 0 28.3591 0 27C0 25.6409 4.10042 24.3052 4.29425 23C6.22703 9.98516 17.4474 0 31 0C45.9117 0 58 12.0883 58 27Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'NotificationBubble',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 58
        },
        height: {
            type: [Number, String],
            default: 54
        }
    }
};
</script>

<template>
    <div class="letter-settings" :style="`--active-step: ${index}; --steps: 2`">
        <div class="steps-wrapper">
            <div class="step-container">
                <div class="step">
                    <div class="step-header">
                        <span class="label">Stap 1</span>
                        <p>Controleer formulier</p>
                    </div>
                    <div class="step-content">
                        <p>Stel in deze stap je referentiebrief op. Je kunt de volgorde van de prestaties en kenmerken handmatig aanpassen.</p>
                    </div>
                </div>

                <div class="settings">
                    <p class="header">Actieve projectkenmerken: {{ showingComponents }} van {{ totalComponents }}</p>
                    <div class="setting">
                        <q-switch id="filledComponentsOnlySwitch" v-model="letter.showAllProjectReferences" @input="emitInput"></q-switch>
                        <label for="filledComponentsOnlySwitch">Toon alleen ingevulde projectkenmerken</label>
                    </div>
                    <div class="setting">
                        <q-switch id="exportAsLetterSwitch" v-model="letter.exportAsLetter" @input="emitInput"></q-switch>
                        <label for="exportAsLetterSwitch">Exporteren als brief</label>
                    </div>
                </div>

                <div v-if="letter.exportAsLetter" class="settings">
                    <p class="header">Brief instellingen</p>
                    <div class="setting">
                        <q-dropdown 
                            size="small" 
                            placeholder="Kies een stijl"
                            v-model="letterStyleId"
                            :options="letterStyleOptions"
                            :avatars="true"
                            @input="handleSelectStyle"
                            autocomplete
                        >Stijl</q-dropdown>
                    </div>
                    <div class="setting">
                        <q-tooltip position="top" :disabled="Boolean(project.client)">
                            <template #tooltip>
                                Selecteer eerst een opdrachtgever voor je<br>project voordat je het adres kan selecteren
                            </template>
                            <q-dropdown 
                                size="small" 
                                v-model="letterReceiverBranchId"
                                placeholder="Kies hier het ontvangst adres"
                                :options="letterReceiverAddressOptions"
                                :disabled="!Boolean(project.client)"
                                @input="handleSelectAddress"
                                autocomplete
                            >Adres ontvanger</q-dropdown>
                        </q-tooltip>
                    </div>
                    <div class="setting">
                        <q-dropdown 
                            size="small" 
                            placeholder="Kies de tekst opzet van je brief"
                            :options="letterIntroTextTemplates"
                            v-model="letterIntroTextTemplateId"
                            @input="handleSelectIntroTemplate"
                            autocomplete
                        >Tekst brief</q-dropdown>
                    </div>
                    <div class="setting">
                        <q-dropdown 
                            size="small" 
                            placeholder="Kies hier een afsluiting voor je brief"
                            :options="letterClosingTextTemplates"
                            v-model="letterClosingTextTemplateId"
                            @input="handleSelectClosingTemplate"
                            autocomplete
                        >Afsluiting</q-dropdown>
                    </div>
                </div>
            </div>

            <div class="step-container">
                <div class="step">
                    <div class="step-header">
                        <span class="label">Stap 2</span>
                        <p>Versturen</p>
                    </div>
                    <div class="step-content">
                        <p>Je hebt je referentiebrief nu opgesteld. Download hier het document om deze naar je opdrachtgever te versturen of voeg een bijlage toe. Let op! Toegevoegde bijlagen zijn alleen voor je eigen administratie. Deze worden bij het downloaden van het formulier niet meegenomen.</p>
                    </div>
                </div>
                <reference-letter-documents></reference-letter-documents>
            </div>
        </div>
    </div>
</template>

<script>
import { isValidAnswer, getDate, getMoney, extractError } from '@/assets/js/utils.js';

import ReferenceLetterDocuments from '../ReferenceLetterDocuments.vue';

export default {
    name: 'letter-settings',
    components: {
        ReferenceLetterDocuments
    },
    props: {
        /*
        *   the index of the step to show
        */
        index: {
            type: Number,
            default: 0
        },
        referenceLetter: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        configurations: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            letter: this.referenceLetter,
            letterIntroTextTemplates: [],
            letterClosingTextTemplates: [],
            letterStyleOptions: [],
            receiverAddresses: [],
            letterReceiverAddressOptions: [],
            letterStyleId: '',
            letterReceiverBranchId: '',
            letterIntroTextTemplateId: '',
            letterClosingTextTemplateId: '',
            totalComponents: 0,
            showingComponents: 0
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.letter);
        },
        setOptions() {
            let styleTemplates = this.configurations.filter(configuration => configuration.type === 'style');
            if(styleTemplates.length === 0) styleTemplates = [{
                name: '+ Maak een stijl aan',
                id: 'create-new'
            }]
            let introTextTemplates = this.configurations.filter(configuration => configuration.type === 'introtext');
            if(introTextTemplates.length === 0) introTextTemplates = [{
                name: '+ Maak een tekst aan',
                id: 'create-new'
            }]
            let closingTextTemplates = this.configurations.filter(configuration => configuration.type === 'closingtext');
            if(closingTextTemplates.length === 0) closingTextTemplates = [{
                name: '+ Maak een afsluiting aan',
                id: 'create-new'
            }]
            this.styleTemplates = styleTemplates;
            this.introTextTemplates = introTextTemplates;
            this.closingTextTemplates = closingTextTemplates;

            this.letterStyleOptions = styleTemplates.map(configuration => {
                const mainConfigurationId = this.$store.getters.getCurrentOrganisation.mainConfigurationId;
                const isMainConfiguration = configuration.id === mainConfigurationId;
                const option = {
                    label: configuration.name,
                    value: configuration.id,
                    avatar: configuration.logo
                }
                if(isMainConfiguration) option.tag = 'Standaard';
                return option
            });

            this.letterIntroTextTemplates = introTextTemplates.map(configuration => {
                return {
                    label: configuration.name,
                    value: configuration.id,
                    text: configuration.text
                }
            })

            this.letterClosingTextTemplates = closingTextTemplates.map(configuration => {
                return {
                    label: configuration.name,
                    value: configuration.id,
                    text: configuration.text
                }
            })

            const clientChilds = this.project.client?.childs || []
            this.receiverAddresses = clientChilds;
            this.letterReceiverAddressOptions = clientChilds.map(child => {
                return {
                    label: `${child.name}`,
                    value: child.id
                }
            });
            const selectedAddress = this.letterReceiverAddressOptions.find(option => option.label === this.referenceLetter.addressReceiver?.name)
            if(selectedAddress) this.letterReceiverBranchId = selectedAddress.value;
        },
        setComponents() {
            const allReferenceIndicators = this.project.references.components.map(c => c.indicator);

            const answeredComponents = this.project.referencesData.filter(component => {
                const componentIndicator = allReferenceIndicators.find(indicator => indicator.masterId === component.indicatorId );
                if(componentIndicator?.answerType === 'date') {
                    return component.value > 0
                }
                return isValidAnswer(component.value)
            });

            let componentsToShow = [];

            const answeredComponentsOnly = this.referenceLetter.showAllProjectReferences || false;
            const components = this.project.references.components;

            if(answeredComponentsOnly) {
                const answeredComponentIds = answeredComponents.map(component => component.componentId)
                componentsToShow = components.filter(component => answeredComponentIds.includes(component.id))
            } else {
                componentsToShow = components
            }

            this.totalComponents = components.length;
            this.showingComponents = componentsToShow.length;
        },
        handleSelectStyle(templateId) {
            this.$emit('selectStyle', templateId);
        },
        handleSelectAddress(branchId) {
            this.$emit('selectAddress', branchId);
        },
        handleSelectIntroTemplate(templateId) {
            this.$emit('selectIntroTemplate', templateId);
        },
        handleSelectClosingTemplate(templateId) {
            this.$emit('selectClosingTemplate', templateId);
        },
        updateTemplateDropdowns() {
            const introTextTemplate = this.letterIntroTextTemplates.find(template => template.text === this.referenceLetter.introText)
            if(introTextTemplate) this.letterIntroTextTemplateId = introTextTemplate.value;
            else this.letterIntroTextTemplateId = ''

            const closingTextTemplate = this.letterClosingTextTemplates.find(template => template.text === this.referenceLetter.closingText)
            if(closingTextTemplate) this.letterClosingTextTemplateId = closingTextTemplate.value;
            else this.letterClosingTextTemplateId = ''
        }
    },
    watch: {
        referenceLetter: {
            handler() {
                this.letter = this.referenceLetter;
                this.updateTemplateDropdowns();
                this.setComponents();
            },
            deep: true
        }
    },
    created() {
        this.setOptions();
        this.setComponents();
        this.updateTemplateDropdowns();
        this.letterStyleId = this.referenceLetter.styleId;
    }

}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.letter-settings {
    position: relative;
    max-height: calc(100vh - 81px - 125px);
    min-height: calc(100vh - 81px - 125px);
    overflow-y: auto;
    width: calc(25vw - 1px);
    border-right: 1px solid #DEE2E6;
    overflow: hidden;
    background-color: white;

    .steps-wrapper {
        display: flex;
        width: calc(100% * var(--steps));
        translate: calc(calc(-100% / var(--steps)) * var(--active-step)) 0;
        transition: .3s ease;
        
        .step-container {
            padding: 36px 28px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .step {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .step-header {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    width: 100%;
                    
                    .label {
                        font-family: 'Gotham';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 16px;
                        background: #f2f2f2;
                        border: 1px solid #dee2e6;
                        border-radius: 4px;
                        color: $color-grey-7;
                        padding: 4px;
                    }
                    p {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        
        
            .settings {
                display: flex;
                flex-direction: column;
                gap: 10px;
        
                .header {
                    font-weight: 500;
                    margin-bottom: 4px;
                }
        
                .setting {
                    display: flex;
                    gap: 6px;

                    label {
                        font-family: "Gotham", sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        margin: 0;
                    }

                    .tooltip {
                        width: 100%;
                    }
                }
            }
        }
        
    }

}

</style>
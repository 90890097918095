<template>
    <svg :width="width" :height="height" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5.24724 5.23803L9.48988 0.995393" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'SmallArrowDown',
    props: {
        color: {
            type: String,
            default: '#ADB5BD',
        },
        width: {
            type: [Number, String],
            default: 10,
        },
        height: {
            type: [Number, String],
            default: 6,
        },
    },
}
</script>

<style>

</style>

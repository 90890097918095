<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section paddingBottom="10" paddingTop="40" hPadding="40" borderBottom>
                <h2>Wachtwoord gewijzigd</h2>
                <p class="py-s">Je wachtwoord is succesvol gewijzigd. Druk op de knop hieronder om in te loggen. </p>
            </q-section>
            <q-section class="center" vPadding="20" hPadding="40">
                <q-button to="/" >Inloggen</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';

export default {
    name: 'ResetPasswordSuccess',
    components: {
        AuthBase
    },
};
</script>
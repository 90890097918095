<template>
    <div class="wrapper">
        <div :class="['nav-' + size]" id="navbar">
            <div :class="['text', 'pointer', variation === 'top' ? 'center-text' : null]" @click="handleClick">
                <div>
                    <p class="fullName">{{ fullName }}</p>
                    <p class="role">{{ role }}</p>
                </div>
            </div>

            <div class="avatar-image" @click="handleClick">
                <span v-if="notifications > 0" class="notifications" :class="size">{{ notifications }}</span>
                <q-avatar :key="image" :size="size" :img="image" class="pointer"> {{ initials }}</q-avatar>
            </div>

            <div v-if="dropdown" @click="handleClick" class="actions" style="width:14px;">
                <q-icon class="pointer" :class="{ 'rotate': opened }" type="chevronDown" height="14"  />
            </div>
        </div>

        <slot v-if="opened" class="content" />
    </div>
</template>

<script>
import QAvatar from '../QAvatar.vue';

export default {
    name: 'q-profile-info',
    components: {
        QAvatar,
    },
    data() {
        return {
            opened: false,
            initials: '',
            count: 0
        };
    },
    /**
     * This profile info template is used in the top right corner of the page.
     *
     */

    props: {
        /**
         * The size of the profile block. Defaults to medium.
         * `small, medium, large, xlarge`
         */
        size: {
            type: String,
            default: 'medium', //small/medium/large/xlarge
        },

        /**
         * The variation of the template. Alters alignment. Top is only used in large|xlarge. Left|right is only used in medium|small.
         * Defaults to top (vertical alignment).
         * `top, left, right`
         */
        variation: {
            type: String,
            default: 'top', // top / left / right
        },

        /**
         * Possibility to add a dropdown icon to the template. Only used in size medium. Defaults to false.
         * `true, false`
         */
        dropdown: {
            type: Boolean,
            default: false,
        },

        /**
         * Adds an image to the avatar. Without image the avatar displays the initials of the user. Defaults to null.
         *
         */
        image: {
            type: String,
            default: null,
        },

        /**
         * Specifies first name of user.
         *
         */
        firstName: {
            type: String,
        },

        /**
         * Specifies last name of user.
         */
        lastName: {
            type: String,
        },

        /**
         * Specifies role of user. Only displayed in size medium|large|xlarge.
         */
        role: {
            type: String,
        },

        /**
         * Specifies the amount of active notifications for users' settings
         */
        notifications: {
            type: Number
        }
    },

    methods: {
        handleClick(event) {
            this.$emit('click', event);

            if (this.dropdown) {
                this.opened = !this.opened;
            }
        },
    },
    computed: {
        fullName() {
            let first = this.firstName;
            let last = this.lastName;

            if (!first) first = '';
            if (!last) last = '';

            return `${first} ${last}`;
        }
    },
    created() {
        this.initials = '';

        if (this.firstName) this.initials += this.firstName.charAt(0).toUpperCase();
        if (this.lastName) {
            const pieces = this.lastName.split(' ');
            this.initials += pieces[pieces.length - 1].charAt(0).toUpperCase();
        }
    },
};
</script>

<style lang="scss" scoped>
.actions,
.avatar-image,
.text {
    padding: 0 14px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions,
.avatar-image {
    user-select: none;
}

.nav-large {
    display: flex;
    flex-direction: column-reverse;
}

.nav-large > div {
    padding: 5px 0;
}

.nav-medium {
    display: flex;
    flex-direction: row;
    height: 80px;
    padding-right: 8px;
}

.wrapper {
    position: relative;
}

.text p {
    text-align: right;
}

.center-text > div > p {
    text-align: center;
}

p.fullName {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

p.role {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

@import '../../assets/style/_variables.scss';
@import '../../assets/style/style.scss';
@import '../../assets/style/fonts/fonts.css';

.profile-info {
    padding: 0px;
    display: flex;
    justify-content: center;
    line-height: 24px;
    color: $color-grey-9;

    &.left {
        flex-direction: row-reverse;
        .text {
            text-align: right;
            padding-right: 16px;
            padding-top: 3px;
        }
    }

    &.right {
        flex-direction: row;
        vertical-align: center;
        .text {
            text-align: left;
            padding-left: 16px;
            padding-top: 3px;
        }
    }

    &.top {
        flex-direction: column;
        align-content: center;
        .avatar {
            margin: auto auto 10px auto;
        }

        .text {
            align-content: center;
            text-align: center;
        }
    }

    &.small {
        .text {
            padding-top: 5px;
            padding-left: 13px;
            padding-right: 13px;
        }
        .fullName {
            font-size: 10px;
        }

        .role {
            display: none;
        }
    }

    &.large,
    &.medium {
        .fullName {
            font-size: 14px;
        }

        .role {
            font-size: 12px;
        }
    }

    &.xlarge {
        .fullName {
            font-size: 19px;
        }

        .role {
            font-size: 14px;
        }
    }

    .fullName {
        font-weight: $weight-semi-bold;
    }

    .user-icon {
        color: $color-grey-7;
        display: inline-block;
        padding: 17px 13px 17px 17px;
    }

    .avatar,
    .icon,
    .text {
        cursor: pointer;
    }
}

.avatar-image {
    .notifications {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-orange;
        color: #ffffff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 12px;
        font-family: 'Gotham';

        &.medium {
            transform: translate(140%, -90%);
        }

        &.large {
            transform: translate(160%, -110%);
            width: 20px;
            height: 20px;
        }
    }
}

.pointer {
    transition: transform 400ms cubic-bezier(.7,.05,.31,.95);
    -webkit-transition: transform 400ms cubic-bezier(.7,.05,.31,.95);

    &.rotate {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
}
</style>

<docs>
  ```jsx
  <div>
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="xlarge"></q-profile-info>
    
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="large" variation="top"></q-profile-info>
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="medium" variation="left"></q-profile-info>
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="medium" variation="right"></q-profile-info>
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="medium" variation="left" dropdown></q-profile-info>
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="medium" variation="right" dropdown></q-profile-info>
    <br />
    <q-profile-info firstName="Frank" lastName="den Heijer" role="Beheerder" size="small" variation="right"></q-profile-info>
    
    
  </div>
  ```
</docs>

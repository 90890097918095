<template>
    <div class="q-zerostate">
        <q-icon v-if="type" :type="type" :height="iconHeight" :width="iconWidth"></q-icon>
        <img v-if="img" :src="img" />
        <h2 v-if="title">{{ title }}</h2>
        <p v-if="description">{{ description }}</p>
        <div class="slot">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-zerostate',
    props: {
        type: {
            type: String
        },
        img: {
            type: String
        },
        title: {
            type: String
        },
        description: {
            type: String
        },
        iconHeight: {
            type: Number,
            default: 160
        },
        iconWidth: {
            type: Number,
            default: 160
        },
        width: {
            type: String,
            default: '300px'
        }
    }
};
</script>

<style lang="scss" scoped>
.q-zerostate {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.q-zerostate img:first-child {
    margin-bottom: 24px;
}

h2,
p {
    max-width: 390px;
    text-align: center;
    margin-top: 15px;
}

.slot {
    margin-top: 16px;
}
</style>

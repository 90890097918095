<template>
    <Portal to="popup" class="q-popup">
        <Transition name="popup" appear>
            <div class="content">
                <div class="panel" :style="panelStyle" ref="panel">
                    <q-icon-button v-if="showCloseButton" class="close" icon="close" iconWidth="20" iconHeight="20" @click="close"></q-icon-button>
                    <slot />
                </div>
            </div>
        </Transition>
    </Portal>
</template>

<script>
export default {
    name: 'q-popup',
    props: {
        /**
         * Width of the panel in CSS
         */
        width: {
            type: String,
            default: 'auto'
        },
        /**
         * Height of the panel in CSS
         */
        height: {
            type: String,
            default: 'auto'
        },
        /**
         * show / hide close button
         */
        showCloseButton: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        async close() {
            this.$emit('close')
        }
    },
    computed: {
        panelStyle: function() {
            return `width: ${this.width}; height: ${this.height}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.q-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    color: #212529;
    font-family: Gotham;
    font-style: normal;
}

.content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);

    .panel {
        padding: 40px;
        background: #ffffff;
        box-shadow: 0px 4px 18px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 15px 15px 0 0;
    }
}

.popup-enter-active {
    background-color: rgba(0, 0, 0, 0);
    animation: fadeIn 1s cubic-bezier(.15,.75,.2,1);

    .panel {
        opacity: 0;
        scale: 0.85;
        animation: jumpIn .2s ease forwards;
    }
}
.popup-leave-active {
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeOut .3s ease;
    pointer-events: none;
    user-select: none;

    .panel {
        scale: 1;
        opacity: 1;
        animation: jumpOut .15s ease forwards;
    }
}

@keyframes fadeIn {
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
@keyframes fadeOut {
    to {
        background-color: rgba(0, 0, 0, 0);
    }
}

@keyframes jumpIn {
    to {
        opacity: 1;
        scale: 1;
    }
}
@keyframes jumpOut {
    to {
        opacity: 0;
        scale: 0.85;
    }
}
</style>

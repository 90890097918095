<template>
    <svg :width="width" :height="height" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 9.5V4.5M7.5 7H12.5" :stroke="color" stroke-linecap="round"/>
        <path d="M3.96236 12.1066L1.89519 14.9989L6.61987 12.9533C6.61987 12.9533 18.32 14.392 18.9517 8.32381C19.4109 3.91263 16.5603 1.46897 11.935 1.1006C6.3654 0.657021 1.00004 1.37851 1 6.99878C0.999972 10.6399 3.96236 12.1066 3.96236 12.1066Z" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: "AddTextBubble",
    props: {
        color: {
            type: String,
            default: "#495057",
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 17,
        },
    },
};
</script>

<style></style>

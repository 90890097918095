import { render, staticRenderFns } from "./ReportDashboard.vue?vue&type=template&id=2d9b0c8a&scoped=true"
import script from "./ReportDashboard.vue?vue&type=script&lang=js"
export * from "./ReportDashboard.vue?vue&type=script&lang=js"
import style0 from "./ReportDashboard.vue?vue&type=style&index=0&id=2d9b0c8a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d9b0c8a",
  null
  
)

export default component.exports
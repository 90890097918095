<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:1.41421;"
  >
    <circle cx="12" cy="12" r="11" :stroke="color" fill="transparent" stroke-width="2"/>
    <path d="M18.1216 15.1154C18.3923 14.634 18.2216 14.0243 17.7403 13.7535C17.2589 13.4828 16.6492 13.6535 16.3784 14.1348L18.1216 15.1154ZM7.62157 14.135C7.35079 13.6536 6.74107 13.4829 6.25972 13.7537C5.77837 14.0245 5.60766 14.6342 5.87843 15.1155L7.62157 14.135ZM16.3784 14.1348C14.5107 17.455 9.48922 17.4551 7.62157 14.135L5.87843 15.1155C8.51078 19.795 15.4893 19.7947 18.1216 15.1154L16.3784 14.1348Z" :fill="color"/>
    <circle cx="8.625" cy="9.375" r="1" :fill="color" :stroke="color" stroke-width="1.75"/>
    <circle cx="15.375" cy="9.375" r="1" :fill="color" :stroke="color" stroke-width="1.75"/>
  </svg>
</template>

<script>
export default {
  name: "HappyEmoji",
  props: {
    color: {
      type: String,
      default: "#37B24D",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>

<style></style>

<template>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="13" height="13" rx="7.5" fill="#FD7E14"/>
    <path d="M7.22109 4.645V3.43H5.78109V4.645H6.22109ZM7.18509 10V5.176H5.81709V10H6.18509Z" fill="white"/>
</svg>

</template>

<script>
export default {
    name: 'InitiatorBadge',
    props: {
        color: {
            type: String,
            default: '#ADB5BD',
        },
        width: {
            type: [Number, String],
            default: 11,
        },
        height: {
            type: [Number, String],
            default: 11,
        },
    },
};
</script>

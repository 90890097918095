<template>
  <div :class="['question-radio', isCard() ? 'no-number' :'']">
        <q-radio 
            v-for="option in options"
            :class="['option', {selected: value === option.value}]"
            :key="option.value"
            :value="value"
            :label="option.value"
            :name="id"
            @input="answer"
            :disabled="disabled"
            data-testid="radio-option">
            <span v-if="isLastOption(option)" class="last-option" >
                <span class="text">Antwoord laatste meting</span>
                <svg
                    class="arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32">
                    <g fill="none" stroke="black" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10">
                        <path class="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                    </g>
                </svg>
            </span>
            <span v-html="option.label"></span>
        </q-radio>
  </div>
</template>

<script>

import QRadio from '../QRadio';

export default {
    name: 'RadioQuestion',
    /**
     * This component needs to be imported and registered here because it is not globally defined in tests.
     */
    components: {
        'q-radio': QRadio
    },
    props: {
        /**
         * Option types. Structure:
         *  - label
         *  - value
         */
        options: {
            type: Array
        },
        /**
         * v-model
         */
        value: {
            type: [Array, String, Number, Object]
        },
        /**
         * Unique id for name property of radio element
         */
        id: {
            type: [String, Number],
            required: true
        },
        disabled: {
            type: Boolean
        },
        previousOption: {
            type: Object
        }
    },
    methods: {
        answer(label) {
            this.$emit('input', label)
        },
        isCard() {
            return this.$parent.number == null;
        },
        isLastOption(option) {
            return this.previousOption && this.previousOption.value === option.value;
        }
    }
}
</script>

<style scoped lang="scss">

.question-radio {
    // Negative margin because the text needs to align with the title above, not the radio (circle) part
    margin-left: -27px;

    label {
        margin-bottom: 14px;
    }

    .selected {
        font-weight: 500;
    }

    &.no-number {
        margin-left: 0;
    }
}

.radio div  input {
            margin: 100px !important;
        }

.left {
    width: 97px;
}

.option {
    position: relative
}

.last-option {
    position: absolute;
    left: -155px;
    top: 0;
    display: flex;
    align-items:flex-start;

    .arrow {
        height: 30px;
        margin: -7px 0 0 -3px;
    }

    .text {
        font-weight: 500;
        font-size: 10px;
        padding: 0;
        margin:0;
    }
}

</style>
<template>
    <Transition name="slide" appear @after-enter="$emit('after-enter', $event)">
        <slot></slot>
    </Transition>
</template>

<script>
export default {
    name: 'slide-transition'
}
</script>

<style lang="scss" scoped>

.slide-enter-active {
    opacity: 0.5;
    scale: 0.8;
    translate: 0 -20%;
    animation: slideIn .2s ease forwards;
}
.slide-leave-active {
    opacity: 1;
    scale: 1;
    translate: 0 0;
    animation: slideOut .15s ease forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        scale: 1;
        translate: 0 0;
    }
}
@keyframes slideOut {
    to {
        opacity: 0;
        scale: 0.8;
        translate: 0 -20%;
    }
}
    
</style>
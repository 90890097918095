<template>
  <div class="q-timeline">
      <ul>
        <li v-for="(item, key) in items" :key="key" :class="[
            'filled', 
            'connection',
            key === 0 ? 'first' : '',
            key === items.length - 1 ? 'last' : ''
        ]">
            <div class="bullet">
                <svg  class="has-form" aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                    <circle stroke="none" cx="16" cy="16" r="10"></circle>
                </svg>
            </div>
            <div>
                <slot name="row" :item="item" :key_="key"></slot>                
            </div>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
    name: 'q-timeline',
    props: {
        /**
         * Array of items, for each item an dot in the timeline will be created
         * Template should be overwritten when using this component like so:
         * 
         * <template v-slot:row="{ item, key_ }">
         *     ... do stuff with data as {{ item }} and key in the array as {{ key_ }}
         * </template>
         */
        items: {
            type: Array
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

li {
    position: relative;
    margin: 0;
    padding: 10px 12px 10px 30px;
    border-radius: 5px;

    &.filled.connection.selectable {
        padding: 16px 12px 16px 30px;
    }

    &.finalize {
        padding: 26px 12px 16px 30px;
    }

    &.connection:before {
        background-color: $color-grey-5;
        width: 1px;
        content: '';
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 16px;
        transform: translate(0, 0px);
    }

    &.filled:first-child:before {
        top: 15px;
        z-index: 1000;
    }
}

.first::before {
    top: 25px !important;
}

.last::before {
    bottom: calc(100% - 20px)  !important
}

.bullet {
    margin-top: -4px;
    margin-left: -20px;
    width: 13px;
    fill: $color-grey-5;
    float: left;
    padding-right: 10px;
    transform: translate(0, 5px);
}

.bullet.add {
    width: 16px;
    margin-left: -22px;
    padding-right: 8px;
    color: black;
}

</style>
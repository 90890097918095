<template>
    <svg :width="width" :height="height" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2.5" cy="2.5" r="2.5" :fill="color"/>
        <circle cx="2.5" cy="16.5" r="2.5" :fill="color"/>
        <circle cx="2.5" cy="9.5" r="2.5" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Options',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 5
        },
        height: {
            type: [Number, String],
            default: 19
        }
    }
};
</script>
<template>
    <div>
        <input id="input" :disabled="uploading" @input="uploadFile" type="file" class="hidden" />
        <q-button :loading="uploading" variation="secondary" @click="clickFileInput">Voeg bijlage toe</q-button>
    </div>
</template>

<script>
export default {
    name: 'UploadInput',
    props: {
        align: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            uploading: false
        };
    },
    methods: {
        uploadFile(event) {
            const UPLOAD_ENDPOINT = `${process.env.UPLOAD_URL}/letter_documents`;
            const authorizationHeader = `Bearer ${this.$store.getters.getJwt}`;
            let v = this;

            const file = event.target.files[0];
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const userId = this.$store.getters.getUser.id;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('organisationId', organisationId);
            formData.append('userId', userId);

            this.uploading = true;

            this.$http
                .post(UPLOAD_ENDPOINT, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: authorizationHeader,
                        'application-context': 'application-context'
                    },
                })
                .then((response) => {
                    this.uploading = false;

                    this.$emit('onSuccess', response.data)
                })
                .catch((error) => {
                    this.$emit('onError', error)
                });
        },
        clickFileInput() {
            const input = document.getElementById('input');
            if(!input) return this.$store.commit('message', { type: 'danger', message: 'Er ging iets fout tijdens het klaarzetten van je upload, probeer te verversen' })
            input.value = "";
            input.click();
        }
    }
};
</script>

<style lang="scss" scoped>

.hidden {
    display: none;
}

.upload-button {
    background: #1d2443;
    color: #f9fafb;
    box-shadow: none;
    padding: 9px 24px;
    cursor: pointer;
    border-radius: 5px;

    transition: 200ms;

    font-weight: 500;
}

.upload-button:hover {
    color: white;
}

.uploading {
    background: #1d244388;
}

</style>

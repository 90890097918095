<template>
    <div class="pageflow-container" :class="{ 'with-title': title }">
        <portal to="pageflow">

        <div class="q-pageflow" :class="[menuFolded ? 'menu-closed' : 'menu-opened', { 'topbar-visible': topbarVisible }]">
            <div v-if="title" class="title" :style="'padding-left:' + paddingLeft">
                <div class="back" v-if="back">
                    <arrow-button color="black" direction="left" :href="back"></arrow-button>
                </div>
                <h2>{{ title }}</h2>
            </div>
            <div class="content" :style="getPageflowBackground">
                <!-- Slot 'items' are multiple <q-pageitem></q-pageitem> elements -->
                <div :class="['page-items', clickable ? 'pointer' : '']">
                    <slot name="items"></slot>
                </div>
                <!-- Slot 'end' is all the content on the right side of the component: buttons, etc -->
                <div class="page-end">
                    <slot name="end"></slot>
                    <div class="fade"></div>
                </div>
            </div>
        </div>

        </portal>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';

export default {
    name: 'q-pageflow',
    components: {
        ArrowButton
    },
    props: {
        /**
         * Title showed above the page flow items
         */
        title: {
            type: String
        },
        /**
         * v-model
         */
        value: {
            type: [Number, String, Object, Array]
        },
        /**
         * Optional back button location. If not set, the button won't show
         */
        back: {
            type: String
        },
        /**
         * distance the first pageflow item and title start from the left side
         */
        paddingLeft: {
            type: String
        },
        /**
         * decides if the items are clickable or not
         */
        clickable: {
            type: Boolean
        },
        /**
         * The backgroundcolor of the pageflow part of the component.
         * Not including title area
         */
        pageflowBackground: {
            type: String
        }
    },
    data() {
        return {
            menuFolded: JSON.parse(localStorage.getItem('navbar_folded')),
            topbarVisible: true
        }
    },
    methods: {
        /**
         * This method is called by child slots (q-pageitem) and sets the v-model
         */
        select(value) {
            if (this.clickable) this.$emit('input', value);
        },
        handleMenuFolded(folded) {
            this.menuFolded = folded;
        },
        handleTopbarToggled(visible) {
            this.topbarVisible = visible;
        }
    },
    computed: {
        getPageflowBackground: function() {
            return `background-color:${this.pageflowBackground}`;
        }
    },
    created() {
        this.$root.$on('menuFolded', this.handleMenuFolded)
        this.$root.$on('topbarToggle', this.handleTopbarToggled);
    },
    beforeDestroy() {
        this.$root.$off('menuFolded', this.handleMenuFolded)
        this.$root.$off('topbarToggle', this.handleTopbarToggled);
    }
};
</script>

<style lang="scss" scoped>
* {
    cursor: default;
}

.fade {
    position: absolute;
    // background: linear-gradient(90deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    left: -50px;
    top: 0;
    bottom: 1px;
    width: 50px;
}

.page-items {
    display: flex;
    width: 100%;
}

.page-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.content {
    display: flex;
    justify-content: space-between;
    height: 64px;
    position: relative;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}

.q-pageflow {
    background-color: #fff;
    z-index: 80;
    width: 100%;
    transition: width .3s ease, top .3s ease;

    &.topbar-visible {
        top: 81px;
    }

    .title {
        height: 59px;
        display: flex;
        align-items: center;

        h2 {
            font-weight: 500;
            font-size: 19px;
            line-height: 19px;
        }
    }
}

.pageflow-container {
    height: 66px;
    
    &.with-title {
        height: 125px;
    }
}

.page-items::-webkit-scrollbar {
    display: none;
}

.page-items {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.back {
    transform: translate(0, 1px);
}
</style>

<docs>
  ```jsx
  <div>
  
    <q-pageflow v-model="currentPage" :title="title">
        <template v-slot:items>
            <q-pageitem value="data">Gegevens</q-pageitem>
            <q-pageitem value="share">Delen</q-pageitem>
            <q-pageitem value="review">Overzicht</q-pageitem>
        </template>
        <template v-slot:end>
            <q-button size="small">Start</q-button>
        </template>
    </q-pageflow>

  </div>
  ``` 
</docs>

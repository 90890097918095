<template>
	<label :class="['checkbox']" :style="cursorStyle" tabindex="0" @keydown.enter="handleEnter">
		<span v-if="labelPosition === 'left'" class="left">
			<slot />
		</span>
		<div>
			<input
				ref="checkboxInput"
				type="checkbox"
				:class="['checkbox', state]"
				:disabled="disabled"
				:id="id"
				:name="name"
				:checked="value"
				@input.stop="handleInput"
			/>
		</div>
		<span v-if="labelPosition === 'right'" class="right">
			<slot />
		</span>
	</label>
</template>

<script>

/**
 * Checkboxes are generally used for interface actions. Suitable for all-purpose use.
 */
export default {
		name: "q-checkbox",
		status: "prototype",
		release: "0.0.1",
		props: {
		/**
		 * Give checkbox a value
		 */
		value: {
			type: [String, Number, Boolean],
			default: false
		},
		/**
		 * Give checkbox a name
		 */
		name: {
			type: String
		},
		/**
		 * Give checkbox an id
		 */
		id: {
		    type: String
		},
		/**
		 *Disable checkbox
		*/
		disabled: {
			type: Boolean,
			default: false
		},

		/**
		 * Manually change various states of checkbox
		 * `hover, disabled, checked`
		 */
		state: {
			type: String,
			default: null,
			validator: value => {
				return value.match(/(hover|disabled|checked|none)/);
			}
		},
		readonly: {
			type: Boolean
		},
		/**
		 * Position of label (left, right)
		 */
		labelPosition: {
			type: String,
			default: 'right'
		}
	},
	data() {
		return {
			cursorStyle: {
				cursor: this.disabled ? "default" : "pointer"

			}
		};
  	},
  	methods: {
		handleInput(event) {
			if (this.disabled) return
			
			this.$emit("input", event.target.checked);
		},
		handleEnter() {
			if (this.disabled) return

			this.$refs.checkboxInput.checked = !this.$refs.checkboxInput.checked;

			this.$emit("input", this.$refs.checkboxInput.checked);
		}
  	}
};
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";
@import "../assets/style/fonts/fonts.css";

.checkbox {
	position: relative;
	display: flex;

	font-family: $font-text;
	font-weight: $weight-normal;
	font-size: $size-m;
	line-height: 16px;
	cursor: pointer;
	transition: all .2s ease, font-weight 0;

	&:has(input[type="checkbox"]:checked) {
		font-weight: 500;
	}

	input[type="checkbox"],
	input[type="radio"],
	label {
		user-select: none;
	}

	span {
		overflow-x: hidden;
		text-overflow: ellipsis;

		&.left {
			margin-right: 10px;
		}
		&.right {
			margin-left: 10px;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		position: relative;
		border: 1px solid $color-grey-5;
		margin: 0;
		padding: 0;
		text-align: center;
		min-width: 16px;
		min-height: 16px;
		background: transparent;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: 3px;
		color: rgba(white, 0);
		transition: background-color 0.2s ease;
		// Better Font Rendering
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&:focus {
			outline: 0;
			border: 1px solid darken($color-grey-5, 5%);
		}
		&:active {
			outline: none;
		}
		&:hover,
		&.hover {
			outline: none;
			cursor: pointer;
			background-image: url("data:image/svg+xml,%3Csvg width='100%' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11' %3E%3Cg fill='none'%3E%3Cpath d='M2 5.0625L5.75 9L12 2' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/g%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 12px 18px;
		}

		&:disabled,
		&.disabled {
			cursor: default;
			opacity: 0.5;
		}

		&:checked,
		&.checked {
			border: 1px solid $color-primary;
			background-color: $color-primary;
			color: white;

			&:before{
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			}

			&:disabled {
				cursor: default;
				opacity: 0.5;
			}
		}
	}

	&:before{
		content:"";
		background-image: url("data:image/svg+xml,%3Csvg width='100%' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11' %3E%3Cg fill='none'%3E%3Cpath d='M2 5.0625L5.75 9L12 2' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/g%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 12px 18px;
		position: absolute;
		inset: 0;
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
		transition: clip-path .3s ease;
	}
}
</style>

<docs>
  ```jsx
  <div>
    <q-checkbox>Checkbox</q-checkbox>
    <q-checkbox state="hover">Hover</q-checkbox>
    <q-checkbox state="disabled">Disabled</q-checkbox>
    <q-checkbox state="checked">Checked</q-checkbox>
  </div>
  ```
</docs>

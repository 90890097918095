<template>
    <div class="loadingState">
        <div class="head">
            <div class="left title">
                <h2 v-if="title">{{ title }}</h2>
                <div v-else class="title blank-row"></div>
                <div class="title blank-badge"></div>
            </div>
            <div class="right"></div>
        </div>
        <div class="project">
            <div class="left">
                <div class="panel q-card-small default">
                    <h4>Algemene projectgegevens</h4>
                    <div class="indicator" v-for="n in 6" :key="n">
                        <div class="name blank-row"></div>
                        <div class="answer blank-row"></div>
                    </div>
                </div>

                <div class="panel q-card-small default">
                    <div class="blank-title"></div>
                    <div class="indicator" v-for="n in 5" :key="n">
                        <div class="name blank-row"></div>
                        <div class="answer blank-row"></div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="mt-xxl">
                    <q-zerostate style="filter: opacity(0.4);" img="/statics/img/building_gradient.svg">
                        <div>
                            <span>Het project wordt opgehaald, dit kan even duren.</span>
                            <div class="loader-wrapper">
                                <div class="loader"></div>
                            </div>
                        </div>
                    </q-zerostate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectLoadingState',
    computed: {
        title() {
            return this.$route.query.title || null;
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
        background-position: -150px;
    }

    100% {
        background-position: 350px;
    }
}

.loadingState {
    .blank-row:not(.title),
    .blank-title,
    .blank-badge {
        @include background-gradient;
        animation: shine-lines $animation-duration infinite ease-out;
        .skeleton-line ~ .skeleton-line {
            background-color: #ddd;
        }
    }

    .project {
        display: flex;
        justify-content: space-between;

        .left {
            margin-right: 30px;
        }

        .right {
            width: 100%;
            margin-left: 30px;
            display: flex;
            justify-content: center;
        }
    }

    .panel {
        padding: 24px 24px 16px 24px;
        width: 500px;
        margin-bottom: 40px;

        h4 {
            margin-bottom: 24px;
            color: $color-grey-3;
        }

        .blank-row,
        .blank-title {
            background-color: lighten($color-grey-3, 5%);
            border-radius: 4px;
        }

        .blank-title {
            width: 100%;
            height: 24px;
            width: 60%;
            margin-bottom: 20px;
        }

        .blank-row {
            width: 50%;
            height: 20px;
            margin: 10px 0;
        }

        .blank-row:first-child {
            margin-right: 10%;
            width: 100px;
        }

        .blank-row:last-child {
            margin: 0;
        }

        .indicator {
            display: flex;
            align-items: center;
            // transition: 200ms;
            height: 35px;
            color: $color-grey-9;
            border-radius: 4px;
            margin: -4px -12px;
            padding: 4px 12px;

            &.selected {
                background-color: lighten($color-grey-3, 5%);
            }

            .name {
                flex: 0 0 35%;
            }
            .answer {
                flex: 0 0 55%;
            }

            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }

            .answer {
                font-weight: normal;
                font-size: 13px;
                line-height: 22px;

                .default {
                    display: flex;
                    justify-content: space-between;

                    .edit {
                        display: none;
                    }
                }
            }
        }
    }

    .options {
        width: 500px;
        padding: 16px 0 16px 0;
        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;

            font-weight: 500;
            font-size: 14px;

            .title {
                line-height: 24px;
                margin: 0 16px 0 24px;
                color: $color-grey-3;
            }

            &.search {
                color: $color-grey-3;
                padding-right: 24px;
                transition: all 200ms ease;

                &:hover {
                    color: $color-grey-3;
                }

                span {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 12px;
                    cursor: default;
                }

                .input {
                    width: 108px;
                    border: none;
                    outline: none;
                    padding: 0;
                    color: $color-grey-5;
                    font-size: $size-s;

                    &:focus {
                        color: $color-grey-7;
                    }

                    &::placeholder {
                        color: $color-grey-5;
                    }
                }

                .not-searching {
                    cursor: pointer;
                }
            }

            .icon {
                transform: translate(-3px, 2px);
            }
        }
    }

    .head {
        margin-bottom: 48px;
        display: flex;
        justify-content: space-between;

        .title .blank-row {
            padding: 2px;
            height: 36px;
            width: 400px;
            border-radius: 7px;
            background-color: lighten($color-grey-3, 5%);
        }
        .title .blank-badge {
            height: 28px;
            width: 140px;
            border-radius: 100px;
            margin-left: 24px;
            background-color: lighten($color-grey-3, 5%);
        }
        .left {
            display: flex;
            align-items: center;

            .status {
                padding-top: 8px;
                margin-left: 24px;
                margin-right: 24px;
                margin-bottom: -3px;
            }

            h2 {
                line-height: 2.6rem;
            }
        }

        .right {
            display: flex;
            align-items: flex-start;
            padding-top: 8px;

            .share {
                display: flex;
                align-items: center;

                .avatars {
                    height: 30px;
                }
            }
        }
    }
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    padding: 18px 60px;
    margin-bottom: -4px !important;
}
.loader {
    $loader-color: $color-primary;
    $loader-size: 7px;
    $loader-height: 16px;
    $loader-border-size: 6px;
    $loader-gap: 18px;
    $loader-animation-duration: 1.2s;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader12;
}

* {
    font-family: Gotham;
}
</style>

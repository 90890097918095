<template>
    <div class="register-organisation">
        <registration-navbar v-if="$route.query.steps" :step="step" :steps="steps"></registration-navbar>
        <div class="q-card-medium">
            <q-section vPadding="40" hPadding="40">
                <h2 id='v-tour-0'>{{title}}</h2>
                <p v-if="toggleRegion === `Nederland`" class="pt-m">
                    {{instructionText}}
                </p>
                <p v-else class="pt-m">
                    Als bedrijf buiten Nederland kun je hieronder de naam en het adres van het bedrijf invullen.
                </p>
            </q-section>

            <q-section paddingBottom="24" hPadding="40" v-if="toggleRegion === `Nederland`">
                <q-section id="v-tour-1">
                    <q-input labelPosition="inside"
                        v-if="this.mainChoice == ``"
                        v-model="orgInput"
                        placeholder="Zoek op naam, adres of KvK-nummer"
                        @input="fetchKvk">
                        Naam / adres / KvK-nummer
                    </q-input>
                    <div v-if="loading" class="loader"></div>

                    <div v-if="orgInput.length > 0 && this.mainChoice == ``" class="wrapperDiv">
                        <div
                            v-for="branch in mains"
                            v-bind:value="branch.value"
                            :key="branch.number"
                            @click="clickMain(branch)"
                            class="branchDiv">
                            <p class="nameLine">{{ branch.name }}</p>
                            <p class="numberLine">
                                Kvk-nummer: {{ branch.legal.registrationId }}
                            </p>
                            <div class="space-between">
                                <p class="addressLine">
                                    {{ getAddressLine(branch) }}
                                </p>
                                <div>
                                    <q-tag v-if="branch.mainExists && branch.mainExists.exists" size="small" variation="success">Actieve organisatie</q-tag>
                                    
                                    <q-tooltip position="top">
                                        <template v-slot:tooltip>
                                            <p v-html="couldBeClientMessage"></p>
                                        </template>
                                        <div class="vertical-center">
                                            <q-tag v-if="branch.couldBeClient" size="small" variation="danger">Opdrachtgever</q-tag>
                                        </div>
                                    </q-tooltip>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div v-else-if="this.mainChoice" class="selectedDiv">
                        <div>
                            <p class="nameLine">{{ mainChoice.name }}</p>
                            
                            <div class="space-between" style="padding: 10px 0">
                                <p class="numberLine">
                                    Kvk-nummer: {{ mainChoice.legal.registrationId }}
                                </p>
                                <div>
                                    <q-tag v-if="mainChoice.mainExists && mainChoice.mainExists.exists" size="small" variation="success">Actieve organisatie</q-tag>
                                    
                                    <q-tooltip position="top">
                                        <template v-slot:tooltip>
                                            <p v-html="couldBeClientMessage"></p>
                                        </template>
                                        <div class="vertical-center">
                                            <q-tag v-if="mainChoice.couldBeClient" size="small" variation="danger">Opdrachtgever</q-tag>
                                        </div>
                                    </q-tooltip>
                                </div>
                            </div>
                        </div>

                        <q-icon class="check-icon" type="check" color='#37b34e' width='20' height='20' />

                        <div class="branches">
                            <div v-for="branch in branches"
                             :key="branch.legal.branchId" 
                             :class="[branchChoice && branch.legal.branchId === branchChoice.legal.branchId ? 'selected' : '', 'branch-item']"
                             @click="clickBranch(branch)">
                                <div class="left">
                                    <div :class="[branchChoice && branch.legal.branchId === branchChoice.legal.branchId ? 'selected-radio' : '', 'radio']">
                                        <div v-if="branchChoice && branch.legal.branchId === branchChoice.legal.branchId" class="radio-fill"></div>
                                    </div>
                                    <div class="info">
                                        <p class="name">{{ branch.name }}</p>
                                        <p class="branch-id">{{ branch.legal.branchId }}</p>
                                        <p class="address">{{ getAddressLine(branch) }}</p>
                                    </div>
                                </div>
                                <div class="right">
                                    <q-tag v-if="branch.branchExists && branch.branchExists.exists" size="small" variation="success">Actieve vestiging</q-tag>
                                </div>
                            </div>
                        </div>

                    </div>


                </q-section>
                
                <p class="pt-m" v-if="mainChoice.mainExists && mainChoice.mainExists.exists && confirmedOrganisation">
                    <span style="font-weight: 500;">Duurt het lang voordat je aanvraag wordt behandeld?</span><br/>
                    <span>Neem dan contact op met de organisatie waar je toegang tot hebt gevraagd. Kom je er niet uit? Neem dan contact op met onze helpdesk.</span>
                </p>
            </q-section>

            <q-section paddingBottom="24" hPadding="40" v-if="toggleRegion === `Europa`">
                <q-input labelPosition="inside" v-model="europeName" placeholder="Naam organisatie">
                    Naam organisatie
                </q-input>
                <q-input labelPosition="inside" v-model="address.line1" placeholder="Straatnaam en nummer">
                    Straatnaam en nummer
                </q-input>
                <div class="address-form row between-xs">
                    <q-input labelPosition="inside" v-model="address.zip" class="col-xs-6 gutter" placeholder="Postcode">Postcode</q-input>
                    <q-input labelPosition="inside" v-model="address.city" class="col-xs-6 gutter" placeholder="Stad">Stad</q-input>
                </div>
            </q-section>

            <q-section v-if="mainChoice || organisationExists || !$route.query.steps" class="space-between" vPadding="24" hPadding="40" borderTop>
                <q-button v-if="mainChoice && !confirmedOrganisation" variation="blank" @click="mainChoice = ''; branchChoice = ''; branches = []">
                    Terug
                </q-button>
                <q-button v-else-if="!$route.query.steps" variation="blank" go="-1">
                    Terug
                </q-button>
                <div v-else></div>
                <q-button to="/access" v-if="confirmedOrganisation">
                    Begrepen
                </q-button>
                <q-button @click="handleRegistration" :loading="loadingOrganisationAction" v-else-if="mainChoice && branchChoice && !(branchChoice.branchExists && branchChoice.branchExists.exists)">
                    Koppel account aan deze organisatie
                </q-button>
                <q-button variation="primary" :loading="loadingOrganisationAction" @click="requestAccess" v-else-if="mainChoice && branchChoice">
                    Vraag toegang tot deze organisatie
                </q-button>
            </q-section>

            <q-section class="footer" hPadding="40" vPadding="20" borderTop>
                <div class="logout" @click="logout">
                    <q-icon type="power" width="18" height="18" class="icon" />
                    <p>Uitloggen</p>
                </div>
            </q-section>
        </div>
        <p v-if="$route.query.steps && mainChoice && !organisationExists" class="note" @click="toNote">Waarom moet mijn account gevalideerd worden?</p>
    </div>
</template>

<script>
import Notifications from '@/components/Notifications';
import AccessBase from '@/pages/access/AccessBase';
import RegisterNavbar from '../auth/RegistrationNavbar.vue';
import { REGISTER_ORGANISATION, REQUEST_ORGANISATION_ACCESS } from '@/graphql/mutations';
import { CHECK_LEGAL_ORG, KVK_COMPANIES } from '@/graphql/queries';
import _ from 'lodash';
import { extractError } from '@/assets/js/utils';

export default {
    name: 'RegisterOrg',
    components: {
        Notifications,
        AccessBase,
        'registration-navbar': RegisterNavbar,
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            orgInput: '',
            nlName: '',
            europeName: '',
            address: {
                line1: '',
                zip: '',
                city: '',
                country: ''
            },
            legal: {
                registrationId: '',
                branchId: ''
            },
            mains: [],
            branches: [],
            branchesLoading: undefined,
            mainChoice: '',
            branchChoice: undefined,
            motivation: '',
            toggleRegion: 'Nederland',
            choicesRegion: [
                { text: `Nederland`, label: `Nederland` },
                { text: `Europa`, label: `Europa` }
            ],
            kvkLoading: false,
            checkingBranch: false,
            organisationExists: false,
            requestOrganisation: '',
            couldBeClientMessage: 'Het is niet mogelijk om jezelf aan te melden bij een </br>opdrachtgever. Als je wel een account voor deze </br>organisatie wilt moet je via de software uitgenodigd </br>worden door een collega die een actief account heeft.',
            confirmedOrganisation: false,
            step: 3,
            steps: [
                { id: 1, name: 'Account aanmaken' },
                { id: 2, name: 'E-mailadres valideren' },
                { id: 3, name: 'Organisatie koppelen' }
            ],
            loadingOrganisationAction: false
        };
    },
    methods: {
        getAddressLine(branch) {
            let line = '';

            if (branch.address.line1) line += branch.address.line1;
            if (branch.address.zip) line += ` ${branch.address.zip}`;
            if (branch.address.city) line += ` ${branch.address.city}`;

            return line;
        },
        requestAccess() {
            let v = this;
            this.loadingOrganisationAction = true;

            this.$apollo
                .mutate({
                    mutation: REQUEST_ORGANISATION_ACCESS,
                    variables: {
                        id: this.user.id,
                        organisationId: this.branchChoice.branchExists.id
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`
                        }
                    }
                })
                .then(response => {
                    this.confirmedOrganisation = true;
                    this.step = 4;
                    this.loadingOrganisationAction = false;
                })
                .catch(err => {
                    this.loadingOrganisationAction = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        handleRegistration() {
            this.loadingOrganisationAction = true;
            
            if (this.toggleRegion === 'Europa') {
                this.$apollo
                    .mutate({
                        mutation: REGISTER_ORGANISATION,
                        variables: {
                            name: this.europeName,
                            address: this.address
                        }
                    })
                    .then(response => {
                        this.confirmedOrganisation = true;
                        this.step = 4;
                        this.loadingOrganisationAction = false;
                    })
                    .catch(err => {
                        this.loadingOrganisationAction = false;
                        this.$store.commit('notify', extractError(err));
                    });
            } else if (this.toggleRegion === 'Nederland') {
                if (!this.mainChoice && !this.branchChoice)
                    return this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Kies eerst een organisatie en vestiging.'
                    });

                this.$apollo
                    .mutate({
                        mutation: REGISTER_ORGANISATION,
                        variables: {
                            name: this.mainChoice.name,
                            address: this.branchChoice.address,
                            registrationId: this.mainChoice.legal.registrationId,
                            branchId: this.branchChoice.legal.branchId,
                            organisationType: 'contractor'
                        },
                    })
                    .then(response => {
                        this.confirmedOrganisation = true;
                        this.loadingOrganisationAction = false;
                    })
                    .catch(err => {
                        this.loadingOrganisationAction = false;
                        this.$store.commit('notify', extractError(err));
                    });
            }
        },
        clickMain(main) {
            if (main.couldBeClient) {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: `Het is niet mogelijk om jezelf aan te melden bij een opdrachtgever.`
                });
                return;
            }

            let vm = this;
            this.mainChoice = main;
            this.organisationExists = false;

            this.getBranchOrganisations();
        },
        clickBranch(branch) {
            if (this.confirmedOrganisation) return;
            
            this.branchChoice = branch;
        },
        getBranchOrganisations() {
            const vm = this;

            if (!this.mainChoice) return;

            this.branchesLoading = this.mainChoice.legal.registrationId;

            this.$apollo
                .query({
                    query: KVK_COMPANIES,
                    variables: {
                        term: this.mainChoice.legal.registrationId,
                        includeBranches: true
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(response => {
                    const items = response.data.kvk_companies;

                    const mainBranches = items.filter(item => (item.isMainBranch || item.isBranch) && item.branchNumber);

                    vm.branches = mainBranches.map(item => {
                        return {
                            value: item.branchNumber,
                            name: item.tradeNames.businessName,
                            address: {
                                line1: `${item.addresses[0].street || ''} ${item.addresses[0].houseNumber || ''}`,
                                zip: item.addresses[0].postalCode || '',
                                city: item.addresses[0].city || '',
                                country: item.addresses[0].country || ''
                            },
                            legal: {
                                registrationId: item.kvkNumber,
                                branchId: item.branchNumber,
                                country: item.addresses[0].country || ''
                            },
                            number: item.branchNumber,
                            couldBeClient: false,
                            mainExists: item.mainExists,
                            branchExists: item.branchExists
                        };
                    });

                    this.branchesLoading = false;
                })
                .catch(err => {
                    vm.kvkLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        async checkKvkExists(kvk) {
            return this.$apollo
                .query({
                    query: CHECK_LEGAL_ORG,
                    variables: {
                        registrationId: kvk,
                        type: 'main'
                    },
                })
                .then(response => response.data.org_legalExists)
                .catch(err => this.$store.commit('notify', extractError(err)));
        },
        emptyBranches() {
            this.mainChoice = '';
            this.orgInput = '';
            this.nlName = '';
            this.organisationExists = false;
        },
        debounceKvkCall: _.debounce(function() {
            this.kvkCall();
        }, 500),
        kvkCall() {

            const vm = this;

            this.$apollo
                .query({
                    query: KVK_COMPANIES,
                    variables: {
                        term: this.orgInput,
                        includeBranches: false
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(response => {
                    const items = response.data.kvk_companies;
                    const mainBranches = items.filter(item => item.isMainBranch === true);
                    vm.mains = mainBranches.map(item => {
                        return {
                            value: item.branchNumber,
                            name: item.tradeNames.businessName,
                            address: {
                                line1: `${item.addresses[0].street || ''} ${item.addresses[0].houseNumber || ''}`,
                                zip: item.addresses[0].postalCode || '',
                                city: item.addresses[0].city || '',
                                country: item.addresses[0].country || ''
                            },
                            legal: {
                                registrationId: item.kvkNumber,
                                branchId: item.branchNumber,
                                country: item.addresses[0].country || ''
                            },
                            number: item.branchNumber,
                            couldBeClient: vm.couldBeClient(item.tradeNames.businessName),
                            mainExists: item.mainExists
                        };
                    });

                    vm.kvkLoading = false;
                })
                .catch(err => {
                    vm.kvkLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        couldBeClient(name) {
            const keywords = ['gemeente', 'provincie', 'waterschap', 'overheid'];
            const lowercaseName = name.toLowerCase();

            const containsKeyword = keywords.some(keyword => lowercaseName.includes(keyword));

            return containsKeyword;
        },
        fetchKvk() {
            if (this.orgInput != '') {
                this.kvkLoading = true;
            }
            this.debounceKvkCall();
        },
        toNote() {
            window.open('https://kennisbank.qfact.nl/nl/article/e-mailadres-validatie', '_blank');
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        }
    },

    computed: {
        loading() {
            return this.kvkLoading && this.orgInput;
        },
        title() {
            if (this.confirmedOrganisation) return 'Aanvraag verstuurd';

            return this.$route.query.steps ? 'Organisatie koppelen' : 'Organisatie aanmelden';
        },
        instructionText() {
            if (!this.mainChoice)
                return 'Kies hier de organisatie die je wilt koppelen aan je account. Als Nederlands bedrijf kun je hieronder zoeken op naam, adres of kvk-nummer.';
            else if (!this.confirmedOrganisation && this.mainChoice.mainExists && this.mainChoice.mainExists.exists)
                return 'Omdat je niet de eerste gebruiker van deze organisatie bent zal je aanvraag eerst goedgekeurd moeten worden en zal een beheerder je de juiste rechten moeten geven voordat je aan de slag kunt. ';
            else if (!this.confirmedOrganisation)
                return 'Omdat dit nog geen actieve organisatie is ben je de eerste gebruiker die toegang krijgt en ontvang je automatisch beheerdersrechten zodra we je account hebben gevalideerd.'
            else if (this.confirmedOrganisation && this.mainChoice.mainExists && this.mainChoice.mainExists.exists)
                return 'Je hebt succesvol toegang aangevraagd tot onderstaande organisatie. Je aanvraag zal beoordeeld worden door een beheerder van de organisatie. Zodra je aanvraag beoordeeld is ontvang je een e-mail van ons. '
            else
                return 'Je hebt succesvol toegang aangevraagd tot onderstaande organisatie. Je aanvraag zal binnen 48 uur door ons beoordeeld worden. Zodra je aanvraag beoordeeld is ontvang je een e-mail van ons.'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';

.infotext {
    margin-top: 8px;
    font-size: 0.6rem;
    line-height: 0.8rem;
    width: 80%;
    color: #6a737c;
}

.wrapperDiv {
    max-height: 250px;
    margin: 5px 0;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
}
.dropdownDiv div:first-child {
    border-radius: 4px 4px 0 0;
}

.dropdownDiv div:last-child {
    border: none;
}

.branchDiv {
    border-bottom: 1px solid $color-grey-3;
    padding: 15px;
    padding-left: 0;
    cursor: pointer;

    &:last-child {
        border: none;
    }
}
.nameLine {
    font-weight: 500;
    font-size: 20px;
}

.numberLine {
    font-size: 12px;
    color: $color-grey-5;
}

.selectedDiv {
    padding: 20px;
    border: 1px solid $color-grey-3;
    border-radius: 5px;
    position: relative;

    & .check-icon {
        position: absolute;
        top: 20px;
        right: 12px;
    }
}

.loader {
    $loader-color: $color-primary;
    $loader-size: 8px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1.2s;
    @import '../../components/qds/assets/loaders/loaders.scss';
    @include loader12;
    margin: auto;
    margin-top: 12px;
}

.register-organisation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 0 0;
}

p.note {
    font-size: 14px;
    color: $color-grey-5;
    margin-top: 20px;
    margin-bottom: 0;
    text-decoration: underline;
    cursor: pointer;
}

.branches {
    max-height: 200px;
    overflow-y: scroll;
}

.branch-item {
    display: flex;
    justify-content: space-between;

    cursor: pointer;
}

.selected-radio {
    border: 1px solid #00A1AE!important;
}

.left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #ADB5BD;

    margin: 0 20px 0 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-fill {
    background-color: #00A1AE;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.info {

    max-width: 250px;
    overflow-x: hidden;

    .name {
        font-size: 14px;
        font-weight: 500;
    }

    .branch-id, .address {
        font-size: 11px;
        line-height: 18px;

    }

}

.logout {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    cursor: pointer;

    .icon {
        margin: 3px 16px 0px 8px;
    }

    &:hover {
        color: rgb(187, 0, 0);
        transition: color 0.5s;
    }
}

</style>

<template>
    <div class="dynamic-alignment-icon" :style="`--width: ${width}px; --height: ${height}px; --line-height: ${lineHeight}px; --color: ${color}`" :class="variation">
        <div class="stroke-1"></div>
        <div class="stroke-2"></div>
        <div class="stroke-3"></div>
    </div>
</template>

<script>
export default {
    name: 'Camera',
    props: {
        color: {
            type: String,
            default: '#672583',
        },
        width: {
            type: [Number, String],
            default: 16,
        },
        height: {
            type: [Number, String],
            default: 13,
        },
        variation: {
            type: String,
            default: 'left'
        }
    },
    computed: {
        lineHeight: function() {
            const height = this.height / 10;
            return Math.floor(this.height / 10) + 1
        }
    }
}
</script>

<style lang="scss" scoped>

.dynamic-alignment-icon {
    width: var(--width);
    height: var(--height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
        width: 100%;
        height: var(--line-height);
        background: var(--color);
        border-radius: 2px;
    }

    &.left {
        .stroke-2 {
            --width: 85%;
            width: var(--width);
            margin-left: auto;
            animation: right-to-left 500ms cubic-bezier(.8,0,.2,1) forwards;
        }
        .stroke-3 {
            --width: 60%;
            width: var(--width);
            margin-left: auto;
            animation: right-to-left 500ms cubic-bezier(.8,0,.2,1) 100ms forwards;
        }
    }
    &.right {
        .stroke-2 {
            --width: 85%;
            width: var(--width);
            animation: left-to-right 500ms cubic-bezier(.8,0,.2,1) forwards;
        }
        .stroke-3 {
            --width: 60%;
            width: var(--width);
            animation: left-to-right 500ms cubic-bezier(.8,0,.2,1) 100ms forwards;
        }
    }
}

@keyframes left-to-right {
    0% {
        width: var(--width);
    } 50% {
        width: 100%;
    } 100% {
        width: var(--width);
        margin-left: auto;
    }
}

@keyframes right-to-left {
    0% {
        width: var(--width);
        margin-left: auto;
    } 50% {
        width: 100%;
    } 100% {
        width: var(--width);
        margin-left: unset;
    }
}

</style>
<template>
    <div class="q-score-options">
        <div v-if="configurable" class="configurable-scores-container">
            <div 
                v-for="option in configurableOptions"
                :key="option.value"
                class="configurable-score-wrapper">
                <q-checkbox :value="option.checked" @input="handleCheckboxInput(option, $event)"></q-checkbox>
                <q-input
                    v-model="option.label" 
                    :unit="option.value" 
                    unitPosition="prefix" 
                    placeholder="Typ hier de beoordelingswaarde"
                    @input="handleOptionsUpdated">
                </q-input>
            </div>
        </div>
        <div v-else class="scores-container" :class="{ 'has-selected': Boolean(value), disabled }">
            <div
                v-for="option in options"
                :key="option.value"
                class="score-wrapper"
                :class="{ selected: option.value === value, disabled }"
                @click="handleScoreInput(option.value)"
                @keydown.enter="handleScoreInput(option.value)"
                tabindex="0">
                <div class="score">{{ option.value }}</div>
                <p class="label">{{ option.label }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-score-options',
    data() {
        return {
            configurableOptions: [
            {
                    label: '',
                    value: 10,
                    checked: false
                },
                {
                    label: '',
                    value: 9,
                    checked: false
                },
                {
                    label: '',
                    value: 8,
                    checked: false
                },
                {
                    label: '',
                    value: 7,
                    checked: false
                },
                {
                    label: '',
                    value: 6,
                    checked: false
                },
                {
                    label: '',
                    value: 5,
                    checked: false
                },
                {
                    label: '',
                    value: 4,
                    checked: false
                },
                {
                    label: '',
                    value: 3,
                    checked: false
                },
                {
                    label: '',
                    value: 2,
                    checked: false
                },
                {
                    label: '',
                    value: 1,
                    checked: false
                },
            ],
            validOptions: true
        }
    },
    props: {
        // v-model
        value: {
            type: [String, Number],
            default: ''
        },
        /*  
        /*  [Option]
        /*  label: String
        /*  value: String
        */
        options: {
            type: Array,
            default() { return [] }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        configurable: {
            type: Boolean,
            default: false
        },
        // boolean to show the value of the score (true) or only the label (false)
        showScore: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleScoreInput(value) {
            this.$emit('input', value);
        },
        handleCheckboxInput(option, checked) {
            const index = this.configurableOptions.findIndex(_option => _option.value === option.value);
            this.configurableOptions[index].checked = checked;
            this.configurableOptions[index].status = checked ? 'active' : 'disabled';
            this.handleOptionsUpdated();
        },
        handleOptionsUpdated() {
            const activeOptions = this.configurableOptions.filter(option => {
                if(!option.checked && option.status !== 'active' && !Boolean(option.label)) return false
                
                return true
            })
            this.$emit('optionsUpdated', activeOptions);
            this.checkValidOptions();
        },
        checkValidOptions() {
            const answerValues = this.configurableOptions;
            const labels = answerValues.map(value => value.label);
            const allEmptyValues = answerValues.filter(value => value.label === '').length === answerValues.length;
            if(allEmptyValues) return this.validOptions = false
            const hasDoubleLabels = answerValues.filter((value, index) => labels.indexOf(value.label) !== index && value.label !== '').length > 0;
            if(hasDoubleLabels) return this.validOptions = false
            const hasEmptyValue = answerValues.filter(value => !value.value).length > 0;
            if(hasEmptyValue && this.displayType !== 'score') return this.validOptions = false
            return this.validOptions = true
        },
        parseOptions() {
            if(!this.options) return
            const optionValues = this.options.map(option => parseInt(option.value))
            this.configurableOptions = this.configurableOptions.map(option => {
                const index = optionValues.indexOf(option.value)
                if(index === -1) return option

                option = this.options[index];
                let checked = false;
                if(!option.status && Boolean(option.label)) checked = true;
                if(option.status === 'active') checked = true;

                option.checked = checked;
                return option
            })
        }
    },
    created() {
        this.parseOptions();
        this.checkValidOptions();
    },
    watch: {
        validOptions: function() {
            this.$emit('validationUpdated', this.validOptions);
        },
        options: function() {
            console.log(this.options)
            this.parseOptions();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.configurable-scores-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .configurable-score-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .inputGroup {
            padding: 0;
        }
    }
}

.scores-container {
    display: flex;
    flex-direction: column;

    .score-wrapper {
        display: flex;
        align-items: center;
        padding-block: 4px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        transition: all .2s ease, scale .2s cubic-bezier(.51,2,.45,1.08);

        &:focus-visible {
            outline: none;
        }

        &.disabled {
            .score {
                scale: 1 !important;
            }
            cursor: default;
        }

        &:hover,
        &:focus-visible {
            .label {
                background-color: #e0eeee99;
            }
            .score {
                scale: 1.07;
            }
        }

        &:active,
        &:focus {
            .score {
                scale: 1;
            }
        }

        .score {
            min-width: 44px;
            min-height: 44px;
            border: 1px solid $color-blue;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 13px;
            color: $color-blue;
            transition: all .2s ease, scale .3s cubic-bezier(.51,2,.45,1.08);
        }

        .label {
            padding: 10px 14px;
            min-height: 22px;
            border-radius: 0 6px 6px 0;
            flex-grow: 1;
            transition: background-color .2s ease;
        }

        &.selected {
            .label {
                background-color: #e0eeee99;
                color: $color-blue;
                font-weight: 500;
            }

            .score {
                background-color: white;
                scale: 1.07;
                font-size: 16px;
            }
        }
    }

    &:not(.disabled) {
        &:hover, &.has-selected, &:focus-within {
            .score-wrapper:not(.selected) .score {
                background-color: #e0eeee;
            }
            .score-wrapper:hover,
            .score-wrapper:focus-visible { 
                .score {
                    background-color: white;
                }
            }
        }
    }
}

</style>
<template>
    <div class="wrapper" ref="form">
        <div class="head">
            <h2>{{ form.name }}</h2>
            <img src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png" alt="crow-image" />
        </div>
        <div
            class="component"
            v-for="component in filteredComponents"
            :key="component.id"
            :class="component.type === 'content' ? 'sticky' : ''"
        >
            <div v-if="component.type == 'question'" class="question-container">
                <q-question
                    :ref="`question_${component.id}`"
                    :id="component.indicator.id"
                    :number="getNumber(component)"
                    :name="component.indicator.name"
                    :type="component.indicator.answerType"
                    :options="getAnswerValues(component.indicator.answerValues)"
                    :settings="component.indicator.settings"
                    settingsMaxChars="1500"
                    :value="component.indicator"
                    :previousAgreements="component.previousAgreements"
                    :previousValues="component.previousValues"
                    :disabled="!canEditForm"
                    :useCrowComments="isCrowForm"
                    width="600px"
                    class="question"
                    @answerInput="handleAnswerInput(component, $event)"
                    @satisfiedInput="handleSatisfiedInput(component, $event)"
                    @improvementInput="handleImprovementInput(component, $event)"
                >
                </q-question>

            </div>
            <div v-else-if="component.type == 'content'">
                <!-- Displaying content blocks is delegated to the ContentBlock component -->
                <content-block :component="component" :form="form" class="content"></content-block>
            </div>
        </div>
        <div class="justify-center">
            <div class="progress-button">
                <div>
                    <div class="save-value loader" v-if="savingIndicator"></div>
                    <div class="save-value flex" v-else>
                        <p class="ml-s">{{ saveValueMessage }}</p>
                    </div>
                </div>
                <div class="flex-end save-container" v-project-can="'fill__Form__send'">
                    <p class="reason" v-if="nvtAnswers > 2">
                        <q-icon
                            class="reason-icon"
                            type="danger"
                            width="28px"
                            height="20px"
                        ></q-icon>
                        {{ invalidMessage }}
                    </p>
                    <q-button
                        :disabled="nvtAnswers > 2 || buttonText !== 'Vaststellen' || savingIndicator"
                        variation="secondary"
                        icon="check"
                        size="medium"
                        iconSize="18"
                        class="ml-m save-button"
                        :loading="submitting"
                        @click="handleDone"
                    >
                        {{ buttonText }}
                    </q-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentBlock from './ContentBlock';

import { SEND_FORM, LOGIN, SAVE_VALUE_VALUE, SAVE_VALUE_SATISFIED, SAVE_VALUE_IMPROVEMENT } from '../../../graphql/mutations';
import { extractError } from '@/assets/js/utils';
import gql from 'graphql-tag';
import _ from 'lodash';

import getSentence from './crowAnswersBoldMapping'

export default {
    name: 'Fill',
    components: {
        'content-block': ContentBlock
    },
    props: {
        /**
         * form contains components array. documentation in README
         */
        form: {
            type: Object
        },
        isCrowForm: {
            type: Boolean,
            default: () => false
        },
        projectJwt: {
            type: String
        },
        nvtAnswers: {
            type: Array,
            default() { return [] }
        },
        incompleteAnswers: {
            type: Array,
            default() { return [] }
        },
        invalidMessage: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showProgressButton: false,
            submitting: false,
            saveValueMessage: 'Wijzigingen opgeslagen',
            leftToFill: 0,
            lastScroll: 1,
            answers: [],
            previousAnswers: [],
            confirmButtonLoading: false,
            previousAnswers: [],
            components: [],
            cachedComponents: [],
            componentChanges: [],
            savingQue: [],
            savingIndicator: false
        };
    },
    methods: {
        getAnswerValues(answerValues) {
            return answerValues.map(answerValue => {
                return {
                    value: answerValue.value,
                    label: getSentence(answerValue.label)
                }
            })
        },
        stopLoadingAnimation(delay = 500, customMessage = 'Wijzigingen opgeslagen') {
            const vm = this;
            this.saveValueMessage = customMessage;
            this.savingIndicator = false;
            setTimeout(() => (vm.saveValueLoading = false), delay);
        },
        handleDone() {
            if (!this.invalidMessage) this.done();

            else {
                this.$store.commit('notify', {
                    type: 'info',
                    message: this.invalidMessage
                });
                const firstIncompleteQuestion = document.getElementById('question' + this.incompleteAnswers[0].questionIndex);
                firstIncompleteQuestion.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
        },
        done() {
            const id = this.$route.params.id;
            this.submitting = true;
            this.$apollo
                .mutate({
                    mutation: SEND_FORM,
                    variables: { id }
                })
                .then(response => {
                    if (response.data.form_send) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Formulier is opgeslagen'
                        });

                        this.$emit('reload');
                    }
                })
                .catch(err => {
                    this.submitting = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        async handleAnswerInput(component, answer) {
            this.savingIndicator = true;
            component.indicator.answer = answer;

            const responseId = this.form.responseId;
            const componentId = component.id;
            const indicatorId = component.indicator.id;
            const formId = this.form.id;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            if(!this.projectJwt) await new Promise(r => setTimeout(r, 2000));

            this.$apollo.mutate({
                mutation: SAVE_VALUE_VALUE,
                variables: {
                        componentId,
                        responseId,
                        indicatorId,
                        formId,
                        value: answer,
                        organisationId,
                        projectJwt: this.projectJwt
                }
            })
            .then(response => {
                this.answers = this.answers.map(answer_ => {
                    if (answer_.id === indicatorId) answer_.answer = answer;
                    return answer_;
                });

                this.cachedComponents = this.cachedComponents.map(cachedComponent => {
                    if(cachedComponent.id === component.id) cachedComponent.indicator.answer = answer;
                    return cachedComponent
                })

                this.stopLoadingAnimation();
                if (response.data.value_set) {
                    this.form.responseId = response.data.value_set.responseId;
                }

                /**
                 * Vue observes the sort method and updates the v-for. The content components
                 * showing averages will be updated
                 */
                this.components.sort((a, b) => 0);

                this.$emit('componentUpdated', component);
            })
            .catch(error => {
                this.stopLoadingAnimation(500, 'Wijziging niet opgeslagen');
                this.components = this.components.map(component_ => {
                    if(component_.id === component.id) {
                        const cachedComponent = this.cachedComponents.find(cachedComponent => cachedComponent.id === component.id);
                        component_.indicator = cachedComponent.indicator;
                    }
                    return component_
                })
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout bij het opslaan' });
            })
        },
        async handleSatisfiedInput(component, satisfied) {
            this.savingIndicator = true;
            component.indicator.satisfied = satisfied;

            const responseId = this.form.responseId;
            const componentId = component.id;
            const indicatorId = component.indicator.id;
            const formId = this.form.id;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            if(!this.projectJwt) await new Promise(r => setTimeout(r, 2000));

            this.$apollo.mutate({
                mutation: SAVE_VALUE_SATISFIED,
                variables: {
                        componentId,
                        responseId,
                        indicatorId,
                        formId,
                        satisfied,
                        organisationId,
                        projectJwt: this.projectJwt
                }
            })
            .then(response => {
                this.answers = this.answers.map(answer => {
                    if (answer.id === indicatorId) answer.satisfied = satisfied;
                    return answer;
                });

                this.cachedComponents = this.cachedComponents.map(cachedComponent => {
                    if(cachedComponent.id === component.id) cachedComponent.indicator.satisfied = satisfied;
                    return cachedComponent
                })

                this.stopLoadingAnimation();
                if (response.data.value_set) {
                    this.form.responseId = response.data.value_set.responseId;
                }

                /**
                 * Vue observes the sort method and updates the v-for. The content components
                 * showing averages will be updated
                 */
                this.components.sort((a, b) => 0);

                this.$emit('componentUpdated', component);
            })
            .catch(error => {
                this.stopLoadingAnimation(500, 'Wijziging niet opgeslagen');
                this.components = this.components.map(component_ => {
                    if(component_.id === component.id) {
                        const cachedComponent = this.cachedComponents.find(cachedComponent => cachedComponent.id === component.id);
                        component_.indicator = cachedComponent.indicator;
                    }
                    return component_
                })
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout bij het opslaan' });
            })
        },
        async handleImprovementInput(component, improvement) {
            this.savingIndicator = true;
            component.indicator.improvement = improvement;

            const responseId = this.form.responseId;
            const componentId = component.id;
            const indicatorId = component.indicator.id;
            const formId = this.form.id;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
    
            if(!this.projectJwt) await new Promise(r => setTimeout(r, 2000));
    
            this.$apollo.mutate({
                mutation: SAVE_VALUE_IMPROVEMENT,
                variables: {
                        componentId,
                        responseId,
                        indicatorId,
                        formId,
                        improvement,
                        organisationId,
                        projectJwt: this.projectJwt
                }
            })
            .then(response => {
                this.answers = this.answers.map(answer => {
                    if (answer.id === indicatorId) answer.improvement = improvement;
                    return answer;
                });

                this.cachedComponents = this.cachedComponents.map(cachedComponent => {
                    if(cachedComponent.id === component.id) cachedComponent.indicator.improvement = improvement;
                    return cachedComponent
                })

                this.stopLoadingAnimation();
                if (response.data.value_set) {
                    this.form.responseId = response.data.value_set.responseId;
                }

                /**
                 * Vue observes the sort method and updates the v-for. The content components
                 * showing averages will be updated
                 */
                this.components.sort((a, b) => 0);

                this.$emit('componentUpdated', component);
            })
            .catch(error => {
                this.stopLoadingAnimation(500, 'Wijziging niet opgeslagen');
                this.components = this.components.map(component_ => {
                    if(component_.id === component.id) {
                        const cachedComponent = this.cachedComponents.find(cachedComponent => cachedComponent.id === component.id);
                        component_.indicator = cachedComponent.indicator;
                    }
                    return component_
                })
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout bij het opslaan' });
            })
        },
        getAgreements() {
            const responseIds = this.form.responses.map(response => response.id)
            this.$apollo
                .query({
                    query: gql`
                        query getAgreements($where: JSON) {
                            values(where: $where) {
                                agreement
                                agreementUserName
                                agreementUserAvatar
                                agreementTimestamp
                                value
                                valueTimestamp
                                indicatorId
                                componentId
                                responseId
                            }
                        }
                    `,
                    variables: {
                        where: {
                            AND: [
                                {
                                    responseId__nin: responseIds
                                },
                                {
                                    projectId: this.form.projectId
                                },
                                {
                                    type: 'form'
                                }
                            ]
                        }
                    }
                })
                .then(result => {
                    this.previousAnswers = this.buildAgreementList(result.data.values);
                })
                .catch(() => {})
        },
        buildAgreementList(values) {
            const previousAnswers = [];

            values
                .filter(value => value.agreement || value.value)
                .forEach(value => {
                    const answerIndex = previousAnswers.map(agreement => agreement.componentId).indexOf(value.componentId);
                    const hasAnswer = answerIndex !== -1;

                    if (hasAnswer) {
                        if (value.agreement)
                            previousAnswers[answerIndex].agreements.push({
                                message: value.agreement,
                                userName: value.agreementUserName,
                                userAvatar: value.agreementUserAvatar,
                                timestamp: value.agreementTimestamp
                            })
                        if (value.value)
                            previousAnswers[answerIndex].values.push({
                                value: value.value,
                                timestamp: value.valueTimestamp
                            })
                    } else {
                        const answerToAdd = {
                            componentId: value.componentId,
                            indicatorId: value.indicatorId,
                            agreements: [],
                            values: []
                        }
                        
                        if (value.agreement)
                            answerToAdd.agreements.push({
                                message: value.agreement,
                                userName: value.agreementUserName,
                                userAvatar: value.agreementUserAvatar,
                                timestamp: value.agreementTimestamp
                            })

                        if (value.value)
                            answerToAdd.values.push({
                                value: value.value,
                                timestamp: value.valueTimestamp
                            })

                        previousAnswers.push(answerToAdd)
                    }
                });

            return previousAnswers;
        },
        getQuestionValue(indicator) {
            return {
                answer: indicator.answer,
                satisfied: indicator.satisfied,
                improvement: indicator.improvement
            };
        },
        getNumber(component) {
            const index = this.components.map(component => component.indicator.id).indexOf(component.indicator.id);

            return index > -1 ? index + 1 : '';
        }
    },
    computed: {
        filteredComponents: function() {
            return this.form.components.map(component => {
                if (component.indicator) {
                    component.indicator.answerValues = component.indicator.answerValues.sort((a, b) => b.value - a.value); // sort answer negative to positive
                }
                
                const answer = this.previousAnswers.find(answer => answer.componentId === component.id)

                if (!answer) return component;
                else return {
                    previousAgreements: answer.agreements,
                    previousValues: answer.values,
                    ...component
                }
            })
        },
        buttonText: function() {
            const answers = this.answers.map(answer => answer.answer);
            const filledAmount = answers.filter(answer => answer != null && !isNaN(answer)).length;
            const totalAmount = answers.length;

            if (filledAmount < totalAmount) return `nog ${totalAmount - filledAmount} ${totalAmount - filledAmount > 1 ? 'vragen' : 'vraag'}`
            return 'Vaststellen'
        },
        canEditForm: function() {            
            return this.projectAbility.can('fill__Value__set_value');
        }
    },
    mounted() {
        const clonedComponents = _.cloneDeep(this.form.components);
        this.components = clonedComponents.filter(component => component.type === 'question');
        this.cachedComponents = _.cloneDeep(this.components);
        this.answers = this.components.map(component => component.indicator);
    },
    created() { 
        this.getAgreements();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;

    // min-height: calc(100vh - 81px - 125px - 100px);
    // max-height: calc(100vh - 81px - 125px - 100px);
    // overflow-y: scroll;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 125px;
    z-index: 70;
}

.content {
    background-color: white;
}

.component {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: calc(-93px * 0.5);

    .question-container,
    .content {
        width: 600px;
    }

    .question-container {
        padding-top: 20px;

        > div {
            scroll-margin-top: 260px;
        }
    }
}

.progress-button {
    pointer-events: none;
    user-select: none;
    position: fixed !important;
    max-width: 1400px;
    width: calc(100% - 420px);
    bottom: 16px;
    z-index: 99;
    animation: popUp 200ms 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    .reason {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $color-grey-9;
        background-color: #ffffff;
        padding: 0 6px;
        border-radius: 8px;

        .reason-icon {
            color: $color-grey-9;
            margin-bottom: -4px;
            margin-right: 4px;
        }
    }

    .save-value {
        height: 28px;
        color: lighten($color-grey-5, 10%);
        p {
            visibility: visible;
            font-weight: 500;
        }
    }

    .save-button {
        pointer-events: all;
    }
}

.save-value.loader {
    $loader-color: lighten($color-grey-5, 10%);
    $loader-size: 6px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1s;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader12;

    margin-left: 40px;
    margin-bottom: 4px;
}

@keyframes popUp {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0);
    }
}

.head {
    display: flex;
    justify-content: space-between;

    img {
        width: 120px;
    }
}

* {
    font-family: 'Gotham', sans-serif;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2H13M0 7.5H13M0 13H13M8.5 1V3H10.5V1H8.5ZM2 7V8.5H4V6.5H2V7ZM6.5 12V14H8.5V12H6.5Z" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'filter-icon',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 13
        },
        height: {
            type: [Number, String],
            default: 15
        }
    }

}
</script>

<style>

</style>
<template>
    <div class="logo-card-wrapper" :class="[size, { selected: selected, interactive: interactive }]">
        <img v-if="src !== 'empty'" class="logo" :src="src" :alt="alt">
        <div v-else class="empty">
            <span>Geen logo</span>
        </div>
        <q-icon class="check-icon" type="check" color="#00A1AE"></q-icon>
        <q-options v-if="options.length > 0 && src !== 'empty'" :options="options" color="#C4C4C4" class="logo-options" @input="handleOptionSelected"></q-options>
    </div>
</template>

<script>
export default {
    name: 'q-logo-card',
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: 'Logo'
        },
        size: {
            type: String,
            default: 'medium',
            validator: value => {
                return value.match(/(small|medium)/);
            }
        },
        interactive: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default() { return [] }
        }
    },
    methods: {
        handleOptionSelected(option) {
            this.$emit('optionSelected', option)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/_variables.scss";

.logo-card-wrapper {
    position: relative;
    border-radius: 6px;
    outline: 1px solid #DEE2E6;
    background-color: #ffffff;
    width: 100%;
    transition: scale .2s cubic-bezier(.45,1.8,.58,1.21), outline .15s ease;
    user-select: none;

    &.small {
        height: 64px;
        width: 150px;

        .logo-options {
            right: 2px;
            bottom: 6px;
        }
    }

    &.medium {
        height: 80px;
        width: 200px;

        .logo-options {
            right: 4px;
            bottom: 10px;
        }
    }

    .logo {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .empty {
        display: grid;
        place-items: center;
        height: 100%;

        span {
            font-size: 14px;
            font-weight: 500;
            color: $color-grey-5;
        }
    }

    // interactions
    &.interactive {
        cursor: pointer;

        &:active {
            scale: 0.97 !important;
            z-index: 1;
        }

        &:hover {
            scale: 1.02;
            z-index: 1;
        }
    }

    &.selected {
        outline: 2px solid #00A1AE;

        .check-icon {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }

    .check-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        transition: clip-path .3s ease;
    }

    .logo-options {
        position: absolute;
    }
}

</style>
<template>
    <div class="indicator-delete-modal">
        <div class="body">
            <h2 class="title">Kenmerk verwijderen</h2>
            <p class="about">
                Weet je zeker dat je dit kenmerk uit je project wilt verwijderen? Hiermee verwijder je ook het ingevulde
                antwoord. Je kunt op een later moment altijd het kenmerk weer toevoegen aan je project om deze opnieuw
                in te vullen.
            </p>
        </div>
        <div class="footer">
            <q-button variation="blank" @click="closePopup">Annuleren</q-button>
            <q-button variation="danger" @click="deleteIndicatorFromCategory" :loading="loadingDeleteIndicator"
                >Ja, verwijderen</q-button
            >
        </div>
    </div>
</template>

<script>

export default {
    name: 'indicator-delete-modal',
    props: {
        loadingDeleteIndicator: {
            type: Boolean, 
            default: false
        }
    },
    methods: {
        closePopup() {
            return this.$emit('close');
        },
        deleteIndicatorFromCategory() {
            return this.$emit('deleteIndicator');
        }
    },
};
</script>

<style lang="scss" scoped>
.indicator-delete-modal {
    max-width: 800px;
    .body {
        padding-bottom: 30px;
        margin-bottom: 10px;

        .title {
            margin-bottom: 30px;
        }
        .about {
            margin-bottom: 30px;
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}
</style>

<template>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="10"
        y1="16"
        x2="22"
        y2="16"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="16"
        y1="22"
        x2="16"
        y2="10"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "PlusIcon",
    props: {
      color: {
        type: String,
        default: "#495057",
      },
      width: {
        type: [Number, String],
        default: 18,
      },
      height: {
        type: [Number, String],
        default: 18,
      },
    },
  };
  </script>
  
  <style></style>
  
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8C15 11.866 11.866 15 7.99999 15C4.13401 15 1.00001 11.866 1.00001 8C1.00001 4.134 4.13401 1 7.99999 1C11.866 1 15 4.134 15 8Z"
      :stroke="color"
      stroke-width="2"
    />
    <path
      d="M8.01294 4.5V9.00001"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9.01318 11.5C9.01318 12.0523 8.56547 12.5 8.01318 12.5C7.4609 12.5 7.01318 12.0523 7.01318 11.5C7.01318 10.9477 7.4609 10.5 8.01318 10.5C8.56547 10.5 9.01318 10.9477 9.01318 11.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Danger",
  props: {
    color: {
      type: String,
      default: "#F03E3E",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>

<style></style>

<template>
    <div class="document-search">
        <div class="search-bar">
            <div class="search-input">
                <q-icon color="#C4C4C4" type="search"></q-icon>
                <input
                    v-model="search"
                    @input="handleInput"
                    type="text"
                    placeholder="Doorzoek documenten"
                />
                <span v-if="search && !loadingSuggestions" class="results">{{ suggestions.length }} {{ suggestions.length === 1 ? 'Resultaat' : 'Resultaten' }}</span>
            </div>
        </div>
        <transition-group name="suggestions-list" class="documents-container">
            <div v-for="(suggestion, index) in suggestions" :key="suggestion.id" class="q-card-small document-card" :style="`--length: ${suggestions.length - 1};--index: ${index}`">
                <p class="rich-text" v-html="suggestion.markedText"></p>
                <div class="bottom flex align-center space-between mt-xs">
                    <p class="document-name weight-500">{{ suggestion.documentName }}</p>
                    <!-- <q-button class="open-button" size="xsmall" variation="ghost">Open bron</q-button> -->
                </div>
            </div>
            <div v-if="suggestions.length == 0 && !loadingSuggestions" key="no-result" class="no-results">
                <p v-if="search">Je zoekopdracht heeft geen resultaat geleverd</p>
                <p v-else>Zoek naar overeenkomsten in bestanden die je hebt geüpload</p>
            </div>
        </transition-group>
    </div>
</template>

<script>
import _ from 'lodash';

import { GET_PROJECT_DOCUMENTS } from '@/graphql/queries.js';

export default {
    name: 'document-search',
    props: {
        documentFilterId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            search: '',
            loadingSuggestions: false,
            suggestions: []
        }
    },
    methods: {
        handleInput() {
            this.loadingSuggestions = true;
            this.markSuggestions();
            this._getSuggestions();
        },
        _getSuggestions: _.debounce(function() {
            this.getSuggestions();
        }, 300),
        getSuggestions() {
            const projectJwtString = localStorage.getItem('projectJwt')
            const projectJwt = JSON.parse(projectJwtString) || {};

            const fields = ['documentId', 'name', 'size', 'documentUrl', 'uploadTimestamp', 'uploadedUser', 'text'];

            this.$apollo.query({
                query: GET_PROJECT_DOCUMENTS,
                variables: {
                    projectId: this.$route.params.id,
                    fields,
                    search: this.search,
                    documentId: this.documentFilterId,
                    projectJwt: projectJwt.jwt
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                let suggestions = [];
                result.data.documents_getProjectDocuments.forEach(document => {
                    const texts = document.highlight?.text || [];
                    texts.forEach((text, index) => {
                        suggestions.push({
                            id: `${document.fields.documentId}-${index}`,
                            documentId: document.fields.documentId,
                            documentName: document.fields.name,
                            text
                        })
                    })
                })
                this.suggestions = suggestions;
                this.markSuggestions();
                this.loadingSuggestions = false;
            })
        },
        markSuggestions() {
            this.suggestions = this.suggestions.map(document => {
                if(!this.search) return document

                document.markedText = this.markSearch(document.text);
                return document
            })
        },
        markSearch(text) {
            const replacePrefix = '<span style="background-color: #F4B976;border-radius: 3px; padding: 1px 2px; margin: -1px 0;">';
            const replaceSuffix = '</span>';

            return text.replaceAll('<em>', replacePrefix).replaceAll('</em>', replaceSuffix)
        }
    },
    watch: {
        documentFilterId: function() {
            this.getSuggestions();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.document-search {
    display: flex;
    flex-direction: column;

    .search-bar {
        width: 100%;
        z-index: 1;

        .search-input {
            display: flex;
            align-items: center;
            gap: 8px;
            border: 1px solid #e0e3e5;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.15);
            padding: 12px 18px;

            input {
                flex-grow: 1;
                border: none;
                outline: none;

                &::placeholder {
                    color: $color-grey-5;
                }
            }

            .results {
                color: $color-grey-5;
                font-size: 14px;
                pointer-events: none;
                user-select: none;
            }
        }

    }
    .documents-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-height: calc(100vh - 81px - 125px - 50px - 39px - 30px - 50px - 16px - 80px);
        max-height: calc(100vh - 81px - 125px - 50px - 39px - 30px - 50px - 16px - 80px);
        margin-top: -4px;
        padding-top: 20px;
        padding-bottom: 80px;
        overflow-y: auto;

        .document-card {
            padding: 18px;
            width: 100%;

            .rich-text {
                white-space: pre-wrap;
            }

            .bottom {
                width: 100%;
            }
        }

        .no-results {
            width: 100%;
            text-align: center;
            margin-top: 8px;

            p {
                color: $color-grey-5;
            }
        }
    }
}

.suggestions-list-move, .suggestions-list-enter-active, .suggestions-list-leave-active {
    transition: .2s ease;
    -webkit-transition: .2s ease;
}
.suggestions-list-enter {
    opacity: 0;
    scale: 0.6;
    translate: 0 -50px;
}
.suggestions-list-enter-active {
    transition-delay: calc(var(--index) * 20ms);
    -webkit-transition-delay: calc(var(--index) * 20ms);
}
.suggestions-list-leave-to {
    translate: 0 -50px;
    opacity: 0;
}

</style>